import { AbstractSearchableSelector } from 'common/components/Input/AbstractSearchableSelector';
import { ITeamCollectionFilter, ITeamPureModel } from 'entities/Team/Team.models';
import { teamTransport } from 'entities/Team/Team.transport';

interface IComponentProps {
  academyId?: string | string[];
  onChange?: (value: ITeamPureModel | ITeamPureModel[]) => void;
}

export class TeamSelector extends AbstractSearchableSelector<ITeamPureModel, IComponentProps> {
  getItemLabel = (item: ITeamPureModel): string => {
    return item.name;
  };

  loadData = (value?: string): Promise<{ data: ITeamPureModel[] }> => {
    const { academyId } = this.props;
    const filter: ITeamCollectionFilter = { orderField: 'byAcademy' };
    if (value) {
      filter.name = value;
    }
    if (academyId) {
      filter.academies = Array.isArray(academyId) ? academyId : [academyId];
    }

    return teamTransport.getCollection(filter);
  };
}
