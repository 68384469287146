import React, { useContext } from 'react';
import Row from 'antd/es/row';
import Card from 'antd/es/card';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import Col from 'antd/es/col';
import { PlayerVisibility } from 'entities/Player/components/PlayerVisibility';
import { PlayerUnlink } from 'entities/Player/components/ActionButtons/PlayerUnlink';
import { PlayerProviderContext } from 'entities/Player/components/PlayerProvider';

interface IProps {
  isDesktop: boolean;
}

type AllProps = IProps;

const PlayerSettingTabStripeProfileComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { player } = useContext(PlayerProviderContext);
  const { isDesktop } = props;
  const mentorRequestId = player?.data?.mentorRequest?.id;

  return (
    <Row>
      <PlayerVisibility isDesktop={isDesktop} />
      <Col xs={24} className="mt-5">
        <Card className="border-none px-3">
          <Typography.Title className="mb-3" level={4}>
            {t('Unlink player')}
          </Typography.Title>
          <Typography.Title level={5} className="mt-0">
            {t('unlink-description')}
          </Typography.Title>
          <PlayerUnlink mentorRequestId={mentorRequestId as string} />
        </Card>
      </Col>
    </Row>
  );
};

export const PlayerSettingTabStripeProfile = PlayerSettingTabStripeProfileComponent;
