import React, { useEffect, useMemo } from 'react';
import { parse, stringify } from 'query-string';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import Form from 'antd/es/form';
import { Link } from 'react-router-dom';
import { useFormMapper } from '@axmit/antd4-helpers';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { RFSSocialLink } from 'common/const/config.const';
import { ERoutesPublic } from 'common/models/routesModel';
import { requiredField } from 'common/helpers/filed-rules';
import { getCurrentLang } from 'common/helpers/i18n';
import { ReactComponent as BallIcon } from 'app/assets/icons/ball.svg';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EAuthRoles, IAuthLoginParams } from 'entities/Auth/Auth.models';

interface IComponentProps {
  role?: EAuthRoles;
}
type AllProps = IAuthConnectedProps & IComponentProps;

const LoginFormComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { addAuthLogin, authLogin, clearAuthLogin, role } = props;
  const { params, errors, loading } = authLogin;
  const { fields } = useFormMapper(['email', 'password'], null, params, errors);
  const isAcademyRole = useMemo(() => role === EAuthRoles.Academy, [role]);
  const lang = getCurrentLang();
  const isLangRu = useMemo(() => lang === 'ru', [lang]);

  const searchQuery = useMemo(() => {
    const searchObject = parse(search);
    const email = fields.find(item => item.name === 'email')?.value;
    return stringify({
      ...searchObject,
      email
    });
  }, [fields, search]);

  const login = (values: any) => {
    const { email, password } = values;
    const body: IAuthLoginParams = {
      email,
      password
    };

    addAuthLogin(body);
  };
  useEffect(() => {
    return () => {
      clearAuthLogin();
    };
  }, [clearAuthLogin]);

  return (
    <>
      <Form onFinish={login} fields={fields} layout="vertical">
        <Form.Item rules={[requiredField]} name="email" label={t('Email')} className="mb-5">
          <Input name="email" type="email" size="large" disabled={loading} />
        </Form.Item>

        <Form.Item rules={[requiredField]} name="password" label={t('Password')} className="mb-5">
          <Input.Password type="password" size="large" disabled={loading} />
        </Form.Item>
        <Form.Item className="mb-5">
          <Button htmlType="submit" block type="primary" size="large">
            {t('Enter')}
          </Button>
        </Form.Item>
        {isAcademyRole && isLangRu && (
          <a href={RFSSocialLink}>
            <Button block size="large" className="mb-5">
              <Row justify="center" align="middle" wrap={false}>
                <BallIcon className="mr-3" />
                {t('Enter with RFS CP')}
              </Row>
            </Button>
          </a>
        )}
      </Form>
      <Row justify="center" align="middle">
        <Link to={`${ERoutesPublic.PasswordForgot}?${searchQuery}`}>
          <Typography.Text className="color-link text-underline">{t('Restore password')}</Typography.Text>
        </Link>
      </Row>
    </>
  );
};

export const LoginPage = communicationAuth.injector(LoginFormComponent);
