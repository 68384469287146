import React, { useCallback } from 'react';
import { Col, Row, Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { ERoutesPublic } from 'common/models/routesModel';
import { Logo } from 'common/components/Header/Logo';
import { LanguageSelect } from 'entities/Language/components/LanguageSelect';
import { communicationUser, IUserConnectedProps } from 'entities/User/User.communication';
import { TrainerSmallCard } from 'entities/Trainer/components/Card/TrainerSmallCard';

const getRoutePathname = (url: string) => url.split(/[#?]/)[0];

const HeaderWithoutAuthComponent = ({ userCheckTokenSignUpWithPlayer }: IUserConnectedProps) => {
  const { loading, data } = userCheckTokenSignUpWithPlayer;
  const { coachFirstName, coachLastName, coachAvatar } = data || {};
  const { t } = useTranslation();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const onLogin = useCallback(() => {
    push(ERoutesPublic.Welcome);
  }, []);
  const hideHeader = pathname.match(ERoutesPublic.PlayerView);

  return !hideHeader ? (
    <header className="ant-menu basic__header width-full px-5">
      <Row className="flex-noWrap height-full width-full basic__header_content" justify="space-between" align="middle">
        <Col>
          {loading ? null : coachFirstName && coachLastName && coachAvatar ? (
            <TrainerSmallCard firstName={coachFirstName} lastName={coachLastName} image={coachAvatar} />
          ) : (
            <Logo onlyLogo />
          )}
        </Col>
        <Col>
          <Space direction="horizontal">
            <LanguageSelect size="middle" className="width-full" />
            {pathname !== getRoutePathname(ERoutesPublic.LoginMentor) &&
              pathname !== getRoutePathname(ERoutesPublic.SignUpMentor) &&
              pathname !== getRoutePathname(ERoutesPublic.Welcome) && <Button onClick={onLogin}>{t('Login')}</Button>}
          </Space>
        </Col>
      </Row>
    </header>
  ) : null;
};

export const HeaderWithoutAuth = communicationUser.injector(HeaderWithoutAuthComponent);
