import { AbstractSearchableSelector } from 'common/components/Input/AbstractSearchableSelector';
import { getPlayerName } from 'common/helpers/name.helper';
import { IPlayerCollectionFilter, IPlayerModel } from 'entities/Player/Player.models';
import { playerTransport } from 'entities/Player/Player.transport';

interface IComponentProps {
  isForMentor: boolean;
  academyId?: string;
  excludePlayers?: string[] | null;
}

type AllProps = IComponentProps;

export class PlayerSelector extends AbstractSearchableSelector<IPlayerModel, AllProps> {
  getItemLabel = (item: IPlayerModel): string => {
    return getPlayerName(item);
  };

  loadData = (value?: string): Promise<{ data: IPlayerModel[] }> => {
    const { excludePlayers } = this.props;
    const filter: Partial<IPlayerCollectionFilter> = {
      visibleOnly: true
    };

    if (value) {
      filter.name = value;
    }

    if (excludePlayers?.length) {
      filter.exclude = excludePlayers;
    }

    return playerTransport.getCollection(filter);
  };
}
