import Typography from 'antd/es/typography';
import Card from 'antd/es/card';
import moment, { Moment } from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'antd/es/date-picker';
import { clientDateFormat } from 'common/models/dateModels';
import { getUTCEndOfDay, getUTCStartOfDay } from 'common/helpers/date.helper';
import { ITestTeamHistoryCollectionFilter } from 'entities/Test/Test.models';

interface IComponentProps {
  onFilterChange: (filter: Partial<ITestTeamHistoryCollectionFilter>) => void;
  filter?: Partial<ITestTeamHistoryCollectionFilter>;
}
const { RangePicker } = DatePicker;

export const TestTeamHistoryFilterCard: React.FC<IComponentProps> = props => {
  const { t } = useTranslation();
  const { onFilterChange, filter } = props;
  const { from, to } = filter || {};

  const onChangeRangePicker = (value: [Moment | null, Moment | null] | null) => {
    const fromRaw = value?.[0];
    const toRaw = value?.[1];

    onFilterChange({
      from: fromRaw ? getUTCStartOfDay(fromRaw) : undefined,
      to: toRaw ? getUTCEndOfDay(toRaw) : undefined
    });
  };

  const rangeValue = useMemo<[Moment | undefined, Moment | undefined]>(() => {
    const fromValue = from ? moment(from) : undefined;
    const toValue = to ? moment(to).utc() : undefined;

    return [fromValue, toValue];
  }, [from, to]);

  return (
    <Card className="border-none width-full px-3">
      <Typography.Text>{t('Test date')}</Typography.Text>
      <RangePicker
        // @ts-ignore
        value={rangeValue}
        className="width-full mt-3"
        size="large"
        allowEmpty={[true, true]}
        showNow={false}
        showTime={false}
        placeholder={[t('Date from'), t('Date to')]}
        format={clientDateFormat}
        onChange={onChangeRangePicker}
      />
    </Card>
  );
};
