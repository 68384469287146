import React, { useMemo } from 'react';
import { IPlayerProfileMicroSkillModel, IPlayerProfileSkillModel } from 'entities/Player/Player.models';
import { PlayerTestMicroSkillBar } from 'entities/PlayerTest/components/PlayerTestMicroSkillBar';

interface IProps {
  skill: IPlayerProfileSkillModel;
  microSkillTag: string;
  isBest: boolean;
}

const Component: React.FC<IProps> = props => {
  const { skill, microSkillTag, isBest } = props;

  const microSkill = useMemo(() => {
    return skill.microSkills.find(microSkillEl => microSkillEl.tag === microSkillTag) as IPlayerProfileMicroSkillModel;
  }, [skill, microSkillTag]);

  return (
    <PlayerTestMicroSkillBar
      wrapperClassName={isBest ? 'opacity-100' : 'opacity-50'}
      showValue
      data={microSkill}
      className="width-full"
    />
  );
};

export const PlayersCompareSkillsElement = Component;
