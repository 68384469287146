import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  buildCollectionResponseFormatter,
  buildCollectionPreRequestDataMapper,
  getSuccessType,
  getStartType
} from '@axmit/redux-communications';
import { put, select, takeLatest } from 'redux-saga/effects';
import message from 'antd/es/message';
import { history } from 'common/helpers/axios.helper';
import { ERoutesCommon } from 'common/models/routesModel';
import { translateToasts } from 'common/helpers/translate.helper';
import { IApplicationState } from 'app/store/reducers';
import { getSuccessTypePartialPlayerProfileModel } from 'entities/Player/Player.communication';
import { privatePlayerTransport } from 'entities/PrivatePlayer/PrivatePlayer.transport';
import {
  IPrivatePlayerUpdateParams,
  IPrivatePlayerParamsModel,
  IPrivatePlayerCreateParams,
  IPrivatePlayerCollectionFilter,
  EPrivatePlayersErrorCodes,
  EPrivatePlayersSuccessMessage,
  ITransferPlayerParams,
  ITransferPlayerParamsApi
} from 'entities/PrivatePlayer/PrivatePlayer.models';
import { closeAcademyPlayerCreateModal, closeTransferPlayerModal } from 'entities/UI/UI.communication';
import { ITrainerModel } from 'entities/Trainer/Trainer.models';
import { ITeamModel } from 'entities/Team/Team.models';
import { IPlayerModel, IPlayerCollection, IPlayerChangeAcademyParams } from 'entities/Player/Player.models';
import { EMentorRequestStatus } from 'entities/MentorRequest/MentorRequest.models';
import { playerTransport } from 'entities/Player/Player.transport';

export const namespace = 'privatePlayer';

export interface IPrivatePlayerStoreProps {
  collection: StoreBranch<IPlayerCollection, IPrivatePlayerCollectionFilter>;
}

export interface IPrivatePlayerConnectedProps {
  privatePlayerModel: StoreBranch<IPlayerModel, IPrivatePlayerParamsModel>;
  privatePlayerCollection: StoreBranch<IPlayerCollection, IPrivatePlayerCollectionFilter>;
  privatePlayerAcademy: StoreBranch<void, IPlayerChangeAcademyParams>;

  getPrivatePlayerModel(id: string): void;
  changePrivatePlayerAcademy(params: IPlayerChangeAcademyParams): void;
  addPrivatePlayerModel(params: IPrivatePlayerCreateParams): void;
  updatePrivatePlayerModel(params: IPrivatePlayerUpdateParams): void;
  transferPrivatePlayerModel(params: ITransferPlayerParams): void;

  getPrivatePlayerCollection(filter: IPrivatePlayerCollectionFilter): void;

  clearPrivatePlayerModel(): void;
  clearPrivatePlayerCollection(): void;
}

const PrivatePlayerModelAPIProviders = [
  new APIProvider(EActionsTypes.get, privatePlayerTransport.get, {
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    },
    onFail: response => {
      if (response?.data?.code === EPrivatePlayersErrorCodes.Forbidden) {
        history.push(ERoutesCommon.Root);
      }
    }
  }),
  new APIProvider(EActionsTypes.update, privatePlayerTransport.update, {
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    },
    mapParams: (originalParams: any) => ({
      ...originalParams,
      trainers: originalParams?.trainers?.map((trainer: ITrainerModel) => trainer.id),
      teams: originalParams?.teams?.map((team: ITeamModel) => team.id || team.name)
    }),
    onSuccess: function*(response) {
      message.success(translateToasts(EPrivatePlayersSuccessMessage.UpdateSuccess));

      yield getSuccessTypePartialPlayerProfileModel({
        weight: response?.weight,
        height: response?.height,
        positions: response?.positions,
        firstName: response?.firstName,
        lastName: response?.lastName,
        leadingLeg: response?.leadingLeg,
        imageId: response?.image?.id,
        ageGroup: response?.ageGroup
      });
    },
    onFail: function*(response, originalParams) {
      if (response?.data?.code === EPrivatePlayersErrorCodes.TeamLinkExist && originalParams?.id) {
        yield getPrivatePlayerModelStartType(originalParams.id);
      }
    }
  }),
  new APIProvider(EActionsTypes.add, privatePlayerTransport.add, {
    mapParams: originalParams => ({
      ...originalParams,
      trainers: originalParams?.trainers?.map(trainer => trainer.id),
      teams: originalParams?.teams?.map(team => team.id || team.name)
    }),
    onSuccess: function*() {
      yield closeAcademyPlayerCreateModal();
      yield getPrivatePlayerCollectionStartType();
    }
  }),
  new APIProvider('transfer', privatePlayerTransport.transferPrivatePlayer, {
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    },
    mapParams: (originalParams: ITransferPlayerParams | undefined): ITransferPlayerParamsApi | undefined =>
      originalParams
        ? {
            ...originalParams,
            trainers: originalParams.trainers?.map((trainer: ITrainerModel) => trainer.id),
            teams: originalParams.teams?.map((team: ITeamModel) => team.id || team.name),
            academy: originalParams.academy.id
          }
        : undefined,
    clearParams: true,
    onSuccess: function*() {
      yield closeTransferPlayerModal();
      message.success(translateToasts(EPrivatePlayersSuccessMessage.TransferSuccess));
    }
  })
];

const PrivatePlayerCollectionAPIProviders = [
  new APIProvider(
    EActionsTypes.get,
    privatePlayerTransport.getCollection,
    {
      mapSuccess: buildCollectionResponseFormatter<any, any>(),
      preRequestDataMapper: buildCollectionPreRequestDataMapper<IPlayerCollection, IPrivatePlayerCollectionFilter>()
    },
    takeLatest
  )
];

const PrivatePlayerChangeAcademyAPIProviders = [
  new APIProvider('change', playerTransport.changePlayerAcademy, {
    onSuccess: function() {
      message.success(translateToasts('Successfully deleted'));
      history.push(ERoutesCommon.Root);
    }
  })
];

const branches = [
  new Branch('model', PrivatePlayerModelAPIProviders),
  new Branch('collection', PrivatePlayerCollectionAPIProviders),
  new Branch('academy', PrivatePlayerChangeAcademyAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationPrivatePlayer = buildCommunication<IPrivatePlayerConnectedProps>(strategy);

export function* removePrivatePlayerCollectionItem(playerId: string) {
  const privatePlayerCollection: IPlayerCollection = yield select(
    (state: IApplicationState) => state[namespace].collection?.data
  );
  const privatePlayerCollectionData = privatePlayerCollection?.data;
  const existingItemIndex = privatePlayerCollectionData?.findIndex(item => item?.id === playerId);

  if (existingItemIndex > -1) {
    privatePlayerCollectionData?.splice(existingItemIndex, 1);
  }

  const meta = { count: existingItemIndex > -1 ? privatePlayerCollection.meta.count - 1 : privatePlayerCollection.meta.count };

  const newCollection: IPlayerCollection = {
    meta: { ...meta, unseenRequestCount: 0 },
    data: privatePlayerCollectionData
  };
  yield getPrivatePlayerCollectionSuccessType(newCollection);
}

export function* updateMentorRequestStatusPlayerCollection(playerId: string) {
  const playerCollection: IPlayerCollection | null = yield select(
    (state: IApplicationState) => state.privatePlayer.collection.data
  );
  if (playerCollection) {
    const resultCollection = playerCollection.data.map(
      (item: IPlayerModel): IPlayerModel =>
        item?.id === playerId
          ? {
              ...item,
              mentorRequest: {
                ...(item?.mentorRequest as any),
                status: EMentorRequestStatus.New
              }
            }
          : item
    );

    const newCollection: IPlayerCollection = {
      ...playerCollection,
      data: resultCollection
    };
    yield getPrivatePlayerCollectionSuccessType(newCollection);
  }
}

function* getPrivatePlayerCollectionSuccessType(payload: IPlayerCollection) {
  yield put({ type: getSuccessType(namespace, 'collection', EActionsTypes.get), payload });
}

export function* getPrivatePlayerCollectionStartType() {
  const payload: IPrivatePlayerCollectionFilter = yield select((state: IApplicationState) => state[namespace].collection?.params);

  payload.offset = 0;
  payload.limit = 20;

  yield put({ type: getStartType(namespace, 'collection', EActionsTypes.get), payload });
}

export function* getPrivatePlayerModelStartType(payload: string) {
  yield put({ type: getStartType(namespace, 'model', EActionsTypes.get), payload });
}
