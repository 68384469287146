import React, { useCallback } from 'react';
import Button from 'antd/es/button';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import Icon from '@ant-design/icons/es/components/Icon';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoDataIcon } from 'app/assets/icons/nodata-magnifier.svg';
import { EPlayerAcademyTabKeys, EPlayerTabKeys } from 'entities/Player/Player.models';

interface IComponentProps {
  showClearFilterButton: boolean;
  clearFilter: (tab: EPlayerAcademyTabKeys) => void;
}

const PlayerAcademyTabListNoDataComponent: React.FC<IComponentProps> = props => {
  const { showClearFilterButton, clearFilter } = props;
  const history = useHistory();
  const listType = queryString.parse(history.location.search).tab as any;
  const isSearch = listType === EPlayerAcademyTabKeys.Search;
  const isMyRequests = listType === EPlayerAcademyTabKeys.MyRequests;
  const isFollow = listType === EPlayerAcademyTabKeys.Follow;
  const { t } = useTranslation();

  const redirectFromList = useCallback(
    (needClear?: boolean) => {
      if (needClear) {
        clearFilter && clearFilter(listType);
        // window.location.replace(`${EPlayerTabKeys.Academy}?tab=${listType}`);
      } else {
        if (listType === EPlayerAcademyTabKeys.Follow) {
          // window.location.replace(EPlayerTabKeys.Tests);
          history.push(EPlayerTabKeys.Tests);
        } else {
          clearFilter && clearFilter(EPlayerAcademyTabKeys.Search);
          // window.location.replace(`${EPlayerTabKeys.Academy}?tab=${EPlayerAcademyTabKeys.Search}`);
        }
      }
    },
    [listType, clearFilter, history]
  );

  return (
    <Row justify="center" align="middle">
      <Col xs={24} sm={12}>
        <Col span={24}>
          <Icon className="players-nodata__icon" component={NoDataIcon} />
        </Col>
        <Typography.Title level={3}>{t('No academies found')}</Typography.Title>
        <Typography.Text className="t-align-c">
          {isSearch && t('empty-search-description')}
          {isFollow && t('empty-follow-description')}
          {isMyRequests && t('empty-request-description')}
        </Typography.Text>
        <Col span={24}>
          {showClearFilterButton || isSearch ? (
            <Button onClick={() => redirectFromList(true)} className="mt-9" size="large" type="primary">
              {t('Clear filter')}
            </Button>
          ) : (
            <Button onClick={() => redirectFromList(false)} className="mt-9" size="large" type="primary">
              {isMyRequests && t('Back to academy list')}
              {isFollow && t('Do tests')}
            </Button>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export const PlayerAcademyTabListNoData = PlayerAcademyTabListNoDataComponent;
