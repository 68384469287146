import React, { useContext, useMemo } from 'react';
import { extractPlayerFromProfile } from 'entities/Player/helpers/player.helper';
import { PlayerCardSmall } from 'entities/Player/components/Card/PlayerCardSmall';
import { PlayerProfileProviderContext } from 'entities/Player/components/PlayerProvider';
import { IPlayerProfilePlayerModel } from 'entities/Player/Player.models';

interface IComponentProps {
  onClick?(itemModel: IPlayerProfilePlayerModel): void;
}

type AllProps = IComponentProps;

const PlayerProfileCardInfoComponent: React.FC<AllProps> = props => {
  const { onClick } = props;
  const { profile } = useContext(PlayerProfileProviderContext);
  const { data } = profile ?? {};

  if (!data) {
    return null;
  }

  const player = useMemo(() => extractPlayerFromProfile(data), [data]);

  return <PlayerCardSmall onClick={onClick} player={player} showPlayerData />;
};

export const PlayerProfileCardInfo = PlayerProfileCardInfoComponent;
