import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'antd/es/card';
import Typography from 'antd/es/typography';
import { useHistory } from 'react-router';
import { ERoutesAcademy } from 'common/models/routesModel';
import { LayoutContent } from 'common/components/Layouts/ContentLayout';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps;

const AcademyModerationCardComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { authAcademy } = props;
  const { data } = authAcademy;
  const isPlayersListPage = history.location.pathname.includes(ERoutesAcademy.AcademyView);
  const isTestListPage = history.location.pathname === ERoutesAcademy.AcademyTestsList;
  const textType = isPlayersListPage ? 'players-list' : isTestListPage ? 'tests-list' : 'page';

  return (
    <LayoutContent isSmall id="academy-moderation">
      <Card className="border-none px-3 py-3 t-align-c">
        <Typography.Title level={5}>{t(`${data?.status}-${textType}`)}</Typography.Title>
        <br />
        <Typography.Title level={5} className="m-0">
          {t(`support-text`)}
        </Typography.Title>
      </Card>
    </LayoutContent>
  );
};

export const AcademyModerationCard = communicationAuth.injector(AcademyModerationCardComponent);
