import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Spin from 'antd/es/spin';
import { CenterLayout } from 'common/components/Layouts/CenterLayout';
import { queryToObject } from 'common/helpers/filters.helper';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

interface IQuery {
  token?: string;
}

type AllProps = IAuthConnectedProps & RouteComponentProps;

const UserLoginComponent: React.FC<AllProps> = props => {
  useEffect(() => {
    const { addTokenAuthLogin } = props;
    const { token } = queryToObject<IQuery>({ token: undefined }) as IQuery;

    if (token) {
      addTokenAuthLogin({ loginToken: token });
    }
  }, []);

  return (
    <CenterLayout>
      <Spin spinning />
    </CenterLayout>
  );
};

export const UserLoginPage = communicationAuth.injector(withRouter(UserLoginComponent));
