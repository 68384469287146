import React, { useEffect, useMemo, useState } from 'react';
import Typography from 'antd/es/typography';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import Spin from 'antd/lib/spin';
import { useDebouncedCallback } from 'use-debounce';
import Col from 'antd/es/col';
import { DownloadOutlined } from '@ant-design/icons';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { getBaseUrl } from 'common/helpers/axios.helper';
import { downloadFile } from 'common/helpers/loader.helper';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { AcademyPrivatePlayerCreateModal } from 'entities/Academy/components/View/Tabs/Players/AcademyPrivatePlayerCreateModal';
import { EPrivatePlayerActionTypes, IPrivatePlayerCollectionFilter } from 'entities/PrivatePlayer/PrivatePlayer.models';
import { PrivatePlayerListFilterCard } from 'entities/PrivatePlayer/components/List/PrivatePlayerListFilterCard';
import { DEFAULT_PRIVATE_PLAYER_LIST_GRID, PrivatePlayerList } from 'entities/PrivatePlayer/components/List/PrivatePlayersList';
import { IAcademyModel } from 'entities/Academy/Academy.models';
import { communicationPrivatePlayer, IPrivatePlayerConnectedProps } from 'entities/PrivatePlayer/PrivatePlayer.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EPublicPlayerOrder } from 'entities/PublicPlayer/PublicPlayer.models';

interface IProps {
  isDesktop: boolean;
  franchisorModel?: IAcademyModel | null;
}

type AllProps = IProps & IUIConnectedProps & IPrivatePlayerConnectedProps & IAuthConnectedProps;

const initPlayerCollectionFilterState: IPrivatePlayerCollectionFilter = {
  location: undefined,
  name: undefined,
  ageGroup: undefined,
  year: undefined,
  gender: undefined,
  hasTrainer: undefined,
  hasTeam: undefined,
  hideArchived: true,
  trainerModel: undefined,
  teamModel: undefined,
  academyFilter: undefined,
  orderField: undefined,
  orderDirection: undefined,
  onlyMy: true
};

const clearedState: IPrivatePlayerCollectionFilter = {
  ...initPlayerCollectionFilterState
};

const PRIVATE_PLAYERS_LIST_ACTION_TYPES = [EPrivatePlayerActionTypes.Share, EPrivatePlayerActionTypes.InviteMentor];

const AcademyPrivatePlayersTabComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { academyId: urlAcademyId } = useParams();
  const { isDesktop, openUIAcademyPlayerCreateModal, franchisorModel, privatePlayerCollection, authModel } = props;
  const [renderIndex, setRenderIndex] = useState(Math.random());
  const playersCount = privatePlayerCollection?.data?.meta?.count;
  const token = useMemo(() => authModel?.data?.access?.token, [authModel]);
  const host = getBaseUrl();

  const [state, setState] = useState<Partial<IPrivatePlayerCollectionFilter>>({
    ...initPlayerCollectionFilterState,
    ...queryToObject<Partial<IPrivatePlayerCollectionFilter>>(initPlayerCollectionFilterState)
  });
  const academyId = franchisorModel?.id === urlAcademyId ? undefined : urlAcademyId || undefined;

  const debPush = useDebouncedCallback((val: any) => {
    history.replace(val);
  }, 300);

  useEffect(() => {
    debPush.callback({ search: objectToQuery(state) });
  }, [state]);

  const onOpenPlayerCreateModal = () => {
    openUIAcademyPlayerCreateModal();
  };
  const createPlayerButton = (
    <Button size={isDesktop ? 'large' : 'middle'} type="primary" onClick={onOpenPlayerCreateModal}>
      {t('Create player')}
    </Button>
  );
  const csvFilters = useMemo(() => {
    const { location, ageGroup, name, trainerModel, teamModel, orderField, academyFilter, ...clearedFilter } = state || {};

    const filterParams: IPrivatePlayerCollectionFilter = {
      ...clearedFilter,
      ...location
    };

    if (orderField) {
      filterParams.orderField = orderField;
      filterParams.orderDirection = orderField === 'name' ? EPublicPlayerOrder.Asc : EPublicPlayerOrder.Desc;
    }

    if (academyId) {
      filterParams.academy = academyId;
    }
    if (academyFilter) {
      filterParams.academy = academyFilter?.id;
    }

    if (teamModel?.id) {
      filterParams.team = teamModel?.id;
    }

    if (name) {
      filterParams.name = name;
    }

    if (ageGroup) {
      filterParams.ageGroups = [ageGroup];
    }

    if (trainerModel?.id) {
      filterParams.trainer = trainerModel.id;
    }
    return filterParams;
  }, [state]);

  return (
    <>
      <Row className={`pb-5 ${!isDesktop ? 'px-3' : ''}`} justify="space-between" align="middle">
        <Row wrap={false} align="middle">
          <Typography.Title className="mb-0" level={isDesktop ? 2 : 3}>
            {t('Players')}
          </Typography.Title>
          {playersCount !== undefined ? (
            <Row wrap={false} align="middle" className="height-full">
              <Typography.Title className="mb-0 ml-3" level={isDesktop ? 2 : 3}>
                {`(${playersCount})`}
              </Typography.Title>
              <Button
                block
                type="default"
                title={t('Download')}
                className="ml-3 p-3"
                icon={<DownloadOutlined />}
                onClick={() => downloadFile(`${host}/trainers/players/csv${objectToQuery({ ...csvFilters, token })}`)}
              />
            </Row>
          ) : (
            <Spin className="ml-4 align-self__end" />
          )}
        </Row>
        <Col span={!isDesktop ? 24 : 12} className={!isDesktop ? 'mt-3' : ''}>
          <Row align="middle" justify={!isDesktop ? 'start' : 'end'}>
            {createPlayerButton}
          </Row>
        </Col>
      </Row>
      <Col xs={24} className="mt-5">
        <Row className="height-full mb-5" key={renderIndex}>
          <PrivatePlayerListFilterCard
            academyId={academyId}
            isFranshisor={!!franchisorModel}
            onFilterChange={partialFilter => setState({ ...state, ...partialFilter })}
            filter={state}
          />
        </Row>
        <Row className={`height-full ${isDesktop ? '' : 'px-5'}`}>
          <PrivatePlayerList
            grid={DEFAULT_PRIVATE_PLAYER_LIST_GRID}
            actionType={PRIVATE_PLAYERS_LIST_ACTION_TYPES}
            elementId="basicLayout"
            academyId={academyId}
            filter={state}
            initFilter={initPlayerCollectionFilterState}
            actionNoDataButton={createPlayerButton}
            clearFilter={() => {
              setState({
                ...clearedState
              });
              setRenderIndex(Math.random());
            }}
          />
        </Row>
      </Col>
      <AcademyPrivatePlayerCreateModal academyId={academyId} />
    </>
  );
};

export const AcademyPrivatePlayersTab = communicationAuth.injector(
  communicationPrivatePlayer.injector(communicationUI.injector(AcademyPrivatePlayersTabComponent))
);
