import React from 'react';
import Row from 'antd/es/row';
import { useTranslation } from 'react-i18next';
import JsLabelIcon from 'app/assets/images/js-label.svg';
import NotFoundIcon from 'app/assets/images/pp-404.svg';

const PlayerProfileNotFoundPageComponent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row className="player-profile-not-found-page" justify="center" align="middle">
      <Row className="width-full" justify="center">
        <object className="player-profile-not-found-page__icon" data={NotFoundIcon} type="image/svg+xml" />
        <Row className="width-full my-6" justify="center">
          <span className="player-profile-not-found-page__title">{t('Oops')}!</span>
        </Row>
        <Row className="width-full" justify="center">
          <span className="player-profile-not-found-page__description">{t('Page not found')}</span>
        </Row>
      </Row>
      <img className="player-profile-not-found-page__logo" src={JsLabelIcon} width={46} height="auto" />
    </Row>
  );
};

export const PlayerProfileNotFoundPage = PlayerProfileNotFoundPageComponent;
