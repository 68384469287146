import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppsMarket } from 'common/components/AppsMarket';
import { PublicPlayerBaseModal } from 'entities/PublicPlayer/components/Modals/PublicPlayerBaseModal';
import { getAppleLink, getGoogleLink } from 'entities/ReferralCode/ReferralCode.helper';

interface IProps {
  visible: boolean;
  onCancel: () => void;
}

export const PublicPlayerPlayersModal: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const { visible, onCancel } = props;

  return (
    <PublicPlayerBaseModal visible={visible} onCancel={onCancel}>
      <span>
        {t('players-modal-text-1')}
        <a href={getAppleLink()} target="_blank" rel="noopener noreferrer">
          App Store
        </a>
        {t('players-modal-text-2')}
        <a href={getGoogleLink()} target="_blank" rel="noopener noreferrer">
          Google Play
        </a>
        {t('players-modal-text-3')}
      </span>
      <AppsMarket className="width-full mt-13" justify="center" />
    </PublicPlayerBaseModal>
  );
};
