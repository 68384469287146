import { options } from 'options';
import window from 'global/window';
import * as moment from 'moment-timezone';
// eslint-disable-next-line no-restricted-imports
import { version } from '../../../package.json';

export const MEDIA_DESKTOP = '992px';
export const SCREEN_XXS = '380px';
export const SCREEN_XS = '480px';
export const SCREEN_SM = '576px';
export const SCREEN_MD = '768px';
export const SCREEN_LG = '856px';
export const SCREEN_XL = '1200px';
// export const SCREEN_XXL = '1600px';
export const PROMO_SCREEN = '1170px';
export const CLIENT_VERSION = version;
export const SOURCE_SERVICE = 'front';
export const SENTRY_AUTH_TOKEN = '83d523a0ebe34c9eafd44f2bbba565b8154ca9db6e6d47c5b571699df8dec873';
export const SENTRY_DSN = window.location.origin?.match(/https?:\/\/localhost:\d{4}/g)
  ? ''
  : 'https://0840ea9ac96a4a9295359ab51d6abf33@o516636.ingest.sentry.io/5648697';
export const SENTRY_ENV = window.location.origin === options.fullAppUrl ? 'FE_production' : 'FE_dev';

export const CLIENT_TIMEZONE = moment.tz.guess();

export const HOST =
  window.location?.hostname === 'dev.junistat.com' || window.location?.hostname === 'localhost'
    ? 'https://dev.junistat.com'
    : 'https://app.junistat.com';

export const RFSSocialLink = 'https://platform.rfs.ru/sso/auth?appId=6c37d5ec6fe54f8ba58269a946663c2c';
