import { options } from 'options';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import moment from 'moment';
import { Locale } from 'antd/lib/locale-provider';

export const defaultLng = 'en';
export const systemLng = navigator.language ? navigator.language.split('-')[0] : defaultLng;
let currentLang = systemLng;

const config = [
  'common',
  'common-units',
  'common-test-reject-reasons',
  'common-skills',
  'common-errors',
  'common-errors-from-server-by-code',
  'common-toasts',
  'common-service-documents-page',
  'common-base-results-label',
  'common-base-results-tags',
  'common-base-results-tags-values',
  'common-test-data',
  'common-fields-rules',
  'common-avatar-uploader',
  'common-test',
  'common-maintenance',
  'common-tariffs',
  'common-subscription',
  'web-shared-view',
  'web-complexity',
  'web-notifications',
  'web-subscription-status',
  'web-tests-list',
  'web-player-contacts',
  'web-auth',
  'web-academy',
  'web-header',
  'web-invite-player-dialog',
  'web-add-player-dialog',
  'web-players-list',
  'web-player-card',
  'web-private-player-card',
  'web-private-player-page',
  'web-invite-mentor-dialog',
  'web-mentor-player-invite-page',
  'web-enter-page',
  'web-trainer-invite-page',
  'web-recover-password-page',
  'web-restore-password-page',
  'web-player-page',
  'web-player-page-academy',
  'web-sign-up-success-page',
  'web-player-test-item',
  'web-init-academy-info-page',
  'web-test-list-page',
  'web-academy-list',
  'web-player-list',
  'web-academy-edit-tests-page',
  'web-referrals-page',
  'web-referral-code-subscriptions',
  'web-referral-code-subscription-activities',
  'web-academy-edit-page',
  'web-user-edit-page',
  'web-image-loader',
  'web-video-loader',
  'web-avatar-editor',
  'web-settings-page',
  'web-user-representation-types',
  'web-player-videos-tab',
  'web-video-private',
  'web-payment-and-subscription-page',
  'web-payment-statuses',
  'web-player-pass-rate',
  'web-subscription-button',
  'web-tariffs',
  'web-change-tariff-modal',
  'web-subscriptions-compare',
  'web-rating-page',
  'web-public-player-sorter',
  'web-franchise-invite-page',
  'web-franchise-academies-page',
  'web-add-franchise-child-dialog',
  'web-academy-view-page',
  'web-academy-trainers-tab',
  'web-academy-players-tab',
  'web-private-player-form',
  'web-academy-tests-tab',
  'web-tests-balance-editor',
  'web-add-trainer-child-dialog',
  'web-trainer-card',
  'web-trainer-academy-page',
  'web-private-player-action-buttons',
  'web-private-player-archived-badge',
  'web-profile-page',
  'player-profile',
  'web-academy-teams',
  'payment-errors',
  'web-compare'
];

const cacheLifeTime = process.env.NODE_ENV === 'development' ? 1000 * 60 * 5 : 24 * 60 * 60 * 1000;
const jsCachePlugin = {
  type: 'backend' as any,
  read: async function(language: string, namespace: string, callback: any) {
    try {
      const requestLanguage = language;
      const cachedValue = await AsyncStorage.getItem(requestLanguage);

      if (cachedValue) {
        const parsed = JSON.parse(cachedValue);
        if (Date.now() - parsed.date < cacheLifeTime) {
          callback(null, parsed.value);
          return;
        }
      }
      const res: any = await axios.get(`/languages/${requestLanguage}/json/js`);
      const resValue = config.reduce((acc, val) => ({ ...acc, ...res?.[val] }), {});

      await AsyncStorage.setItem(requestLanguage, JSON.stringify({ date: Date.now(), value: resValue }));

      callback(null, resValue);
    } catch (e) {
      callback(e, null);
    }
  }
};

i18n
  .use(jsCachePlugin)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    ns: ['common'],
    defaultNS: 'common',
    load: 'all',
    lng: systemLng,
    react: {
      useSuspense: true
    },
    interpolation: {
      escapeValue: false
    },
    fallbackLng: defaultLng
  })
  .then(() => {
    setAPILanguage(i18n.language);
  });

export default i18n;

export const changeSystemLang = async (lang: string, antdLocaleCallback?: (locale: Locale) => void) => {
  await changeMomentLocales(lang);
  await changeI18nLanguage(lang);
  await changeAntdLocale(lang, antdLocaleCallback);
};

export const changeI18nLanguage = async (lang: string) => {
  if (lang === currentLang) {
    return;
  }
  console.log(`Changing system language to ${lang}`);
  currentLang = lang;
  setAPILanguage(lang);
  await i18n.changeLanguage(lang);
};

export function getCurrentLang() {
  return currentLang;
}

export const setAPILanguage = (lang: string, libraries: string[] = ['places'], key: string = options.googleMapsApiKey) => {
  // Destroy old API
  document.querySelectorAll('script[src^="https://maps.googleapis.com"]').forEach(script => {
    script.remove();
  });
  // @ts-ignore
  if (window.google) {
    // @ts-ignore
    delete window.google.maps;
  }

  // Generate new Google Maps API script
  const newAPI = document.createElement('script');
  newAPI.src =
    'https://maps.googleapis.com/maps/api/js?libraries=' +
    libraries.join(',') +
    '&key=' +
    key +
    '&language=' +
    lang +
    '&callback=googleMapsAPILoaded';

  // Callback for the Google Maps API src
  // @ts-ignore
  window.googleMapsAPILoaded = () => {
    const event = new CustomEvent('googleMapsAPILoaded');
    window.dispatchEvent(event);
  };

  // Wait for the callback to be executed
  const apiLoaded = new Promise(resolve => {
    window.addEventListener('googleMapsAPILoaded', () => {
      resolve();
    });
  });

  // Start the script
  // @ts-ignore
  document.querySelector('head').appendChild(newAPI);

  return apiLoaded;
};

export async function changeMomentLocales(lang: string) {
  try {
    const importFile = Object.keys(MomentLocalesFileNamesMap).includes(lang) ? MomentLocalesFileNamesMap[lang] : lang;
    await import(`moment/locale/${importFile}`);

    moment.locale(lang);
  } catch (error) {
    console.error(`Error while trying to import moment locale for ${lang}`, error);
  }
}

let setAntdLocaleFileCallback: (locale: Locale) => void = () => undefined;
export async function changeAntdLocale(lang: string, setAntdLocaleFile?: (locale: Locale) => void) {
  try {
    if (setAntdLocaleFile) {
      setAntdLocaleFileCallback = setAntdLocaleFile;
    }
    const importFile = Object.keys(AntdLocalesFilesNamesMap).includes(lang)
      ? AntdLocalesFilesNamesMap[lang]
      : `${lang}_${lang.toUpperCase()}`;
    const localeFile = await import(`antd/es/locale/${importFile}.js`);

    if (localeFile?.default) {
      (setAntdLocaleFile || setAntdLocaleFileCallback)(localeFile?.default);
    }
  } catch (error) {
    console.error(`Error while trying to import antd locale for ${lang}`, error);
  }
}

export const MomentLocalesFileNamesMap: { [key: string]: string } = {
  en: 'en-gb',
  hy: 'hy-am',
  oc: 'oc-lnc',
  pa: 'pa-in',
  tl: 'tl-ph',
  ug: 'ug-cn',
  zh: 'zh-cn'
};

export const AntdLocalesFilesNamesMap: { [key: string]: string } = {
  en: 'en_US',
  ar: 'ar_EG',
  ca: 'ca_ES',
  da: 'da_DK',
  el: 'el_GR',
  et: 'et_EE',
  fa: 'fa_IR',
  ga: 'ga_IE',
  gl: 'gl_ES',
  he: 'he_IL',
  hi: 'hi_IN',
  hy: 'hy_AM',
  ja: 'ja_JP',
  kk: 'kk_KZ',
  kn: 'kn_IN',
  ko: 'ko_KR',
  ku: 'ku_IQ',
  nb: 'nb_NO',
  ne: 'ne_NP',
  nl: 'nl_BE',
  sv: 'sv_SE',
  uk: 'uk_UA',
  vi: 'vi_VN',
  zh: 'zh_CN'
};
