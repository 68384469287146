import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Player, ControlBar } from 'video-react';
import { useTranslation } from 'react-i18next';
import YouTubePlayer from 'react-youtube';
import Skeleton from 'antd/es/skeleton';
import message from 'antd/es/message';
import { getVideoLink } from 'common/helpers/loader.helper';

interface IModalProps {
  videoId?: string | null;
  youtubeId?: string | null;
  isPublic?: boolean;
}

type AllProps = IModalProps;

enum EAutoPLay {
  'Yes' = 1
}

const playerOptions = {
  width: '100%',
  playerVars: {
    autoplay: EAutoPLay.Yes // Хак, на просто цифру ругается ts
  }
};

export const VideoPrivateModalBody: React.FC<AllProps> = props => {
  const { videoId, youtubeId, isPublic } = props;
  const { t } = useTranslation();
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [useFallbackImg, setUseFallbackImg] = useState<boolean>(false);
  const videoPlayerRef = useRef(null);

  const enableFallback = useCallback(() => {
    setUseFallbackImg(true);
  }, []);

  const onVideoPlayerStateChange = useCallback(state => {
    const { error } = state;
    if (error) {
      console.error('Error while playing video', error);
      message.error(t('An error occurred during playing video'));
      enableFallback();
    }
  }, []);

  useEffect(() => {
    if (videoId && !youtubeId) {
      const videoPlayer = videoPlayerRef.current;
      if (videoPlayer) {
        // @ts-ignore
        videoPlayer.subscribeToStateChange(onVideoPlayerStateChange);
      }
    }
  }, []);

  useEffect(() => {
    const getVideoUrl = async (id: string) => {
      const data = await getVideoLink(id, isPublic);
      setVideoUrl(data);
    };

    if (videoId && !youtubeId) {
      getVideoUrl(videoId);
    }
  }, [videoId]);

  return (
    <div className="video-private__modal__body">
      {youtubeId ? (
        <YouTubePlayer className="youtube-player" videoId={youtubeId} opts={playerOptions} />
      ) : useFallbackImg ? (
        <Skeleton.Image />
      ) : (
        <Player autoPlay ref={videoPlayerRef} fluid={false} width={playerOptions.width}>
          <source src={videoUrl} type="video/mp4" />
          <ControlBar autoHide={false} />
        </Player>
      )}
    </div>
  );
};
