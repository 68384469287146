import * as React from 'react';
import Select, { SelectProps } from 'antd/es/select';
import { useTranslation } from 'react-i18next';

export const orderFieldArray = [
  { title: 'Full name', key: 'name' },
  { title: 'rating', key: 'rating' },
  { title: 'ageGroup', key: 'ageGroup' },
  { title: 'Registration date', key: 'createdAt' },
  { title: 'pace', key: 'pace' },
  { title: 'agility', key: 'agility' },
  { title: 'physical', key: 'physical' },
  { title: 'dribbling', key: 'dribbling' },
  { title: 'passing', key: 'passing' },
  { title: 'shooting', key: 'shooting' }
];

export const PlayerOrderFieldSelector: React.FC<SelectProps<any>> = props => {
  const { t } = useTranslation();
  return (
    <Select placeholder={t('Sort')} getPopupContainer={triggerNode => triggerNode as HTMLElement} {...props}>
      {orderFieldArray.map(item => (
        <Select.Option key={item?.key} value={item?.key}>
          <div className="text-capitalize">{t(item?.title)}</div>
        </Select.Option>
      ))}
    </Select>
  );
};
