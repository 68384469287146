import React, { useEffect, useMemo } from 'react';
import Row from 'antd/es/row';
import Tabs from 'antd/es/tabs';
import { useTranslation } from 'react-i18next';
import Button from 'antd/es/button';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import { useLocation, useHistory, Redirect } from 'react-router';
import { useMediaPredicate } from 'react-media-hook';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { queryToObject } from 'common/helpers/filters.helper';
import { ERoutesPublic } from 'common/models/routesModel';
import { setHeaders } from 'common/helpers/axios.helper';
import { getCurrentLang } from 'common/helpers/i18n';
import { LoginPage } from 'entities/Auth/components/LoginPage';
import { SignUpPage } from 'entities/Auth/components/SignUpPage';
import { EAuthRoles, EAuthRolesTitle } from 'entities/Auth/Auth.models';
import { JuniStatAppLinkPage } from 'entities/Auth/components/JuniStatAppLinkPage';
import { JuniCoachAppLinkPage } from 'entities/Auth/components/JuniCoachAppLinkPage';

interface IQuery {
  role?: string;
}
export const AuthPage: React.FC = () => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const lang = getCurrentLang();

  const { role } = queryToObject<IQuery>({ role: undefined }) as IQuery;
  const onChangeTab = (key: string) => {
    history.push(`${key}${search}`);
  };

  useEffect(() => {
    if (role && (role === EAuthRoles.Academy || role === EAuthRoles.Mentor)) {
      setHeaders(role);
    }
  }, [role, setHeaders]);

  const title = useMemo(() => {
    switch (role) {
      case EAuthRoles.Academy: {
        return EAuthRolesTitle.Academy;
      }
      case EAuthRoles.Mentor: {
        return EAuthRolesTitle.Mentor;
      }
      case EAuthRoles.Coach: {
        return EAuthRolesTitle.Coach;
      }
      case EAuthRoles.Player: {
        return EAuthRolesTitle.Player;
      }
      default: {
        return '';
      }
    }
  }, [role]);

  const renderForm = useMemo(() => {
    switch (role) {
      case EAuthRoles.Academy:
      case EAuthRoles.Mentor: {
        return (
          <div className="p-7 auth-page__form-wrapper">
            <Row justify="center">
              <Tabs activeKey={pathname} onChange={onChangeTab} centered className="width-full">
                <Tabs.TabPane tab={t('Entry')} key={ERoutesPublic.Login}>
                  <LoginPage role={role} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('Registration')} key={ERoutesPublic.SignUp}>
                  <SignUpPage />
                </Tabs.TabPane>
              </Tabs>
            </Row>
          </div>
        );
      }
      case EAuthRoles.Coach: {
        return <JuniCoachAppLinkPage />;
      }
      case EAuthRoles.Player: {
        return <JuniStatAppLinkPage />;
      }
      default: {
        return '';
      }
    }
  }, [pathname, lang]);

  return role ? (
    <div className="auth-page width-full">
      <span className="d-block pp-font-title-2 font-weight-5 t-align-c mb-7">{t(title)}</span>
      {renderForm}
      <Row justify="start" className={`auth-page mt-5 mb-5 ${!isDesktop ? 'pl-7 pr-7' : ''}`}>
        <Button onClick={() => history.push(ERoutesPublic.Welcome)} block size={isDesktop ? 'middle' : 'large'}>
          <ArrowLeftOutlined /> {t('Back')}
        </Button>
      </Row>
    </div>
  ) : (
    <Redirect to={ERoutesPublic.Welcome} />
  );
};
