import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  getStartType
} from '@axmit/redux-communications';
import { message } from 'antd';
import { put } from 'redux-saga/effects';
import {
  ILanguageModel,
  TLanguageParamsModel,
  ILanguageCollection,
  ILanguageCollectionFilter,
  ICreateLanguageParams,
  IUpdateLanguageParams
} from 'entities/Language/Language.models';
import { languageTransport } from 'entities/Language/Language.transport';

const namespace = 'language';

export interface ILanguageConnectedProps {
  languageModel: StoreBranch<ILanguageModel, TLanguageParamsModel>;
  languageCollection: StoreBranch<ILanguageCollection, ILanguageCollectionFilter>;

  getLanguageModel(locale: string): void;
  addLanguageModel(params: ICreateLanguageParams): void;
  updateLanguageModel(params: IUpdateLanguageParams): void;
  deleteLanguageModel(locale: string): void;

  getLanguageCollection(filter?: ILanguageCollectionFilter): void;

  clearLanguageModel(): void;
  clearLanguageCollection(): void;
}

function* updateCollection() {
  yield put({ type: getStartType(namespace, 'collection', EActionsTypes.get) });
}

const LanguageModelAPIProviders = [
  new APIProvider(EActionsTypes.get, languageTransport.get),
  new APIProvider(EActionsTypes.update, languageTransport.update, {
    onSuccess: function*() {
      message.success('Locale successfully updated');
      yield updateCollection();
    },
    onFail: () => {
      message.error('An error occurred while updating locale');
    },
    clearParams: true
  }),
  new APIProvider(EActionsTypes.add, languageTransport.add, {
    onSuccess: function*() {
      message.success('Locale successfully created');
      yield updateCollection();
    },
    onFail: () => {
      message.error('An error occurred while creating locale');
    },
    clearParams: true
  }),
  new APIProvider(EActionsTypes.delete, languageTransport.delete, {
    onSuccess: function*() {
      message.success('Locale successfully deleted');
      yield updateCollection();
    },
    onFail: () => {
      message.error('An error occurred while deleting locale');
    }
  })
];
const LanguageCollectionAPIProviders = [new APIProvider(EActionsTypes.get, languageTransport.getCollection)];

const branches = [new Branch('model', LanguageModelAPIProviders), new Branch('collection', LanguageCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationLanguage = buildCommunication<ILanguageConnectedProps>(strategy);
