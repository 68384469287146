import i18n from 'i18next';
import { EResultTypes } from 'entities/PlayerTest/PlayerTest.models';

export const measurementConverterValue = (value?: number | null, unit?: EResultTypes | string, system?: EMeasurementSystem) => {
  if (system !== EMeasurementSystem.US) {
    return value;
  }
  if (value) {
    switch (unit) {
      case EResultTypes.KG:
        return value * 2.2; // кг в фунт

      case EResultTypes.Meter:
        return value * 3.2808; // м в футы

      case EResultTypes.CMT:
      case EResultTypes.CM:
        return value * 0.393701; // см в футы

      // eslint-disable-next-line sonarjs/no-duplicated-branches
      case EResultTypes.MeterPerSecond:
        return value * 3.2808; // метр/сек в фут/секунду

      case EResultTypes.KilometerPerHour:
        return value * 0.621371; // км в час в мили/час

      default:
        return value;
    }
  }
  return null;
};

const roundValue = (val: number) => Math.round(val * 10) / 10;
const roundValueHundred = (val: number) => Math.round(val * 100) / 100;

const convertAndCheck = (val: number, x: number) => {
  let originalValue = val;
  let count = 0;
  let found = false;

  while (!found) {
    const convertedValue = originalValue / x;
    const roundedConvertedValue = roundValueHundred(convertedValue);
    const backConvertedValue = roundedConvertedValue * x;

    if (roundValueHundred(originalValue) === roundValueHundred(backConvertedValue)) {
      found = true;
      return roundedConvertedValue;
    }
    count++;

    if (count > 100) {
      return roundValueHundred(val / x);
    }
    originalValue = roundValueHundred(originalValue - (count > 50 ? 0.01 : -0.01));

    if (originalValue <= 0) {
      return roundValueHundred(val / x);
    }
  }
  return roundValueHundred(val / x);
};

export const converterFromBeMeasurementValue = (
  value?: number | null,
  unit?: EResultTypes | string,
  system?: EMeasurementSystem
) => {
  if (system === EMeasurementSystem.US && value) {
    switch (unit) {
      case EResultTypes.CM:
        return roundValue(value * 0.0328084); // см в футы

      case EResultTypes.KG:
        return roundValue(value * 2.2); // кг в фунт

      default:
        return value;
    }
  }
  return value;
};

export const converterForBeMeasurementValue = (
  value?: number | null,
  unit?: EResultTypes | string,
  system?: EMeasurementSystem
) => {
  if (system === EMeasurementSystem.US && value) {
    switch (unit) {
      case EResultTypes.FT:
        return convertAndCheck(value, 0.0328084); // см в футы

      case EResultTypes.LB:
        return convertAndCheck(value, 2.2); // кг в фунт

      default:
        return value;
    }
  }
  return value;
};

export const convertHeight = (value?: number, system?: EMeasurementSystem) => {
  if (system === EMeasurementSystem.US && value) {
    const totalInches = value / 2.54;
    const ft = Math.floor(totalInches / 12);
    const inch = Math.round(totalInches % 12);
    return `${ft}${i18n.t('ft')}${inch ? `${inch}${i18n.t('in')}` : ''}`;
  }

  return converterFromBeMeasurementValue(value, EResultTypes.CM, system);
};

export const measurementConverterUnit = (unit?: EResultTypes | string, system?: EMeasurementSystem) => {
  if (system !== EMeasurementSystem.US) {
    return unit;
  }
  switch (unit) {
    case EResultTypes.KG:
      return EResultTypes.LB;
    case EResultTypes.Meter:
      return EResultTypes.FT;
    case EResultTypes.CMT:
    case EResultTypes.CM:
      return EResultTypes.Inch;
    case EResultTypes.MeterPerSecond:
      return EResultTypes.FootPreSecond;
    case EResultTypes.KilometerPerHour:
      return EResultTypes.MilePerHour;

    default:
      return unit;
  }
};

export enum EMeasurementSystem {
  Metric = 'Metric',
  US = 'US'
}
