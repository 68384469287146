import React, { useCallback, useEffect, useMemo } from 'react';
import Card from 'antd/es/card';
import Spin from 'antd/es/spin';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import Button from 'antd/es/button';
import CameraOutlined from '@ant-design/icons/CameraOutlined';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { nameBuilder } from 'common/helpers/name.helper';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';
import { PrivatePlayerProviderContext } from 'entities/PrivatePlayer/components/PrivatePlayerProvider';
import { PrivatePlayerForm } from 'entities/PrivatePlayer/components/PrivatePlayerForm';
import { EMentorRequestAcceptSide, EMentorRequestStatus } from 'entities/MentorRequest/MentorRequest.models';
import { PlayerTransferModal } from 'entities/PrivatePlayer/components/TransferModal/PlayerTransferModal';
import { MentorRequestButton } from 'entities/MentorRequest/components/MentorRequestButton';
import { PlayerVideoUploadCard } from 'entities/Player/components/Card/PlayerVideoUploadCard';

interface IComponentProps {}

type AllProps = IComponentProps & IMentorRequestConnectedProps;

const avatarSize = 70;
const defaultAvatarStyle = { width: avatarSize, height: avatarSize, fontSize: avatarSize / 2 };

const PrivatePlayerInfoTabComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { getMentorRequestCollection, mentorRequestCollection, updateMentorRequestModel, clearMentorRequestCollection } = props;
  const { player } = React.useContext(PrivatePlayerProviderContext);
  const { loading, data } = player ?? {};
  const academyId = data?.academyLink?.academy?.id;
  const { archived: isPlayerArchived, id: playerId } = data || {};

  useEffect(() => {
    if (playerId && !loading) {
      getMentorRequestCollection({
        playerId
      });
    }
    return () => {
      clearMentorRequestCollection();
    };
  }, [player]);

  const mentorRequest = useMemo(() => mentorRequestCollection.data?.data?.[0], [mentorRequestCollection]);

  const onRemoveRequest = useCallback(() => {
    if (mentorRequest?.id) {
      updateMentorRequestModel({
        id: mentorRequest.id,
        status: EMentorRequestStatus.Aborted
      });
    }
  }, [mentorRequest, updateMentorRequestModel]);

  const mentorRequestExist =
    mentorRequest &&
    !mentorRequest?.isVirtual &&
    (mentorRequest?.status === EMentorRequestStatus.New || mentorRequest?.status === EMentorRequestStatus.Accepted);
  const mentor = !mentorRequest?.isVirtual ? mentorRequest?.mentor : undefined;
  const mentorName = nameBuilder(mentor?.firstName, mentor?.lastName, mentor?.email);
  const mentorRequestStatus = t(`${mentorRequest?.subscription?.status}`);
  const isWithMentorRequest = mentorRequest?.status === EMentorRequestStatus.New;

  const mentorRequestAction = useMemo(() => {
    switch (true) {
      case mentorRequest?.status !== EMentorRequestStatus.New: {
        return (
          <Row>
            <Typography.Text title={t('Subscription')} className="mr-3">
              {t('Subscription')}:
            </Typography.Text>
            <Typography.Text title={mentorRequestStatus}>{mentorRequestStatus}</Typography.Text>
          </Row>
        );
      }
      case mentorRequest?.acceptSide === EMentorRequestAcceptSide.Mentor: {
        return (
          <Row className="py-3">
            <Button danger onClick={onRemoveRequest}>
              {t('Cancel a mentor invitation')}
            </Button>
          </Row>
        );
      }
      default:
        return null;
    }
  }, [mentorRequest, onRemoveRequest, mentorRequestStatus]);

  return (
    <Col span={24}>
      <Spin spinning={loading} className="width-full">
        <Card bordered={false} className="radius-default mb-7">
          <PrivatePlayerForm academyId={academyId} />
        </Card>
        <PlayerVideoUploadCard />
        <Card bordered={false} className="px-3 pb-0 radius-default private-player-mentor">
          <Spin spinning={mentorRequestCollection?.loading} size="large">
            <Typography.Title level={4}>{t('Mentor')}</Typography.Title>
            {mentorRequestExist ? (
              <Row className="width-full pt-3" align="middle" gutter={[16, 0]}>
                <Col>
                  <Row>
                    {mentor?.image?.id ? (
                      <ImagePrivate
                        alt={t('Mentor avatar')}
                        width={avatarSize}
                        height={avatarSize}
                        className="border-circle"
                        imgId={mentor?.image?.id}
                      />
                    ) : (
                      <Row justify="center" align="middle" className="bg-component-third radius-round" style={defaultAvatarStyle}>
                        <CameraOutlined />
                      </Row>
                    )}
                  </Row>
                </Col>
                <Col>
                  <Row className="mb-2">
                    <Typography.Text ellipsis strong title={mentorName}>
                      {mentorName}
                    </Typography.Text>
                  </Row>
                  <Row className="mb-2">
                    <Typography.Text ellipsis title={mentor?.email} className="color-text-secondary">
                      {mentor?.email}
                    </Typography.Text>
                  </Row>
                  {mentorRequestAction}
                </Col>
              </Row>
            ) : (
              <Row justify="center" className="p-7">
                <MentorRequestButton playerId={playerId} sendAgain={isWithMentorRequest} disabled={isPlayerArchived} />
              </Row>
            )}
          </Spin>
        </Card>
      </Spin>
      <PlayerTransferModal />
    </Col>
  );
};

export const PrivatePlayerInfoTab = communicationMentorRequest.injector(PrivatePlayerInfoTabComponent);
