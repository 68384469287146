import React, { useMemo } from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import Button from 'antd/es/button';
import classNames from 'classnames';
import { getCurrencySign, getTariffAmountAndCurrency, translateTariffData } from 'common/helpers/tariff-helper';
import { normalize } from 'common/helpers/normalize.helper';
import { ITariffModel } from 'entities/Tariff/Tariff.models';

interface IProps {
  item: Partial<ITariffModel>;
  selectedTariffId?: string;
  onSelect(tariffId: string): void;
  disabled?: boolean;
  tariffEnd?: string;
  nextTariff?: string;
  nextTariffStart?: string;
}

type AllProps = IProps;

const Component: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { item, selectedTariffId, onSelect, disabled, tariffEnd, nextTariff, nextTariffStart } = props;
  const { period } = item;
  const { title, saleDescription1, saleDescription2 } = translateTariffData(item);
  const { tariffCurrency, tariffAmount } = getTariffAmountAndCurrency(item);

  const isTariffSelected = useMemo(() => item.id === selectedTariffId, [item.id, selectedTariffId]);
  const isNextTariff = useMemo(() => item.id === nextTariff, [item.id, nextTariff]);
  const isFree = useMemo(() => Number(tariffAmount) === 0, [tariffAmount]);

  const periodText = useMemo(() => {
    switch (true) {
      case period === 1:
        return t('mon');
      case period === 12:
        return t('year');
      default:
        return `${period}\u00A0${t('mon')}`;
    }
  }, [period]);

  const onTariffSelectCallback = () => {
    if (item.id) {
      onSelect(item.id);
    }
  };

  return (
    <Col flex={1} className="p-5 border-light radius-small height-full">
      <Row className="mb-7" wrap={false} justify="space-between" gutter={[8, 8]}>
        <Col>
          <Typography.Title ellipsis={{ rows: 2 }} level={5} className="color-text-additional mb-3">
            {title}
          </Typography.Title>

          <Button
            type={isFree ? 'default' : 'primary'}
            onClick={onTariffSelectCallback}
            disabled={isTariffSelected || isNextTariff || disabled}
            size="small"
          >
            {t(isTariffSelected ? 'Active' : 'Connect')}
          </Button>
        </Col>
        <Col>
          <Row align="bottom" wrap={false}>
            <Typography.Title level={3} className="my-0 font-weight-4">
              {isFree ? t('Free') : `${getCurrencySign(tariffCurrency as string)}\u00A0${normalize(Number(tariffAmount))}`}
            </Typography.Title>
            {!isFree && <Typography.Text className="t-nowrap">/{periodText}</Typography.Text>}
          </Row>
        </Col>
      </Row>
      {isTariffSelected && tariffEnd && (
        <Row className="mb-5">
          <Typography.Text>{t('Valid until', { date: tariffEnd })}</Typography.Text>
        </Row>
      )}
      {isNextTariff && nextTariffStart && (
        <Row className="mb-5">
          <Typography.Text>{t('Activated from', { date: nextTariffStart })}</Typography.Text>
        </Row>
      )}
      {saleDescription1 && (
        <Row className="mb-5">
          <Typography.Text className={classNames('width-full', { 'color-disable': isFree })} title={saleDescription1}>
            {saleDescription1}
          </Typography.Text>
        </Row>
      )}
      {saleDescription2 && (
        <Row className="mb-5">
          <Typography.Text className={classNames('width-full', { 'color-disable': isFree })} title={saleDescription2}>
            {saleDescription2}
          </Typography.Text>
        </Row>
      )}
    </Col>
  );
};

export const TariffStripeComponentItem = Component;
