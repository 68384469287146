import React from 'react';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { communicationTrainer, ITrainerConnectedProps } from 'entities/Trainer/Trainer.communication';
import { TrainerListItem } from 'entities/Trainer/components/List/TrainerListItem';
import { TrainerListNoData } from 'entities/Trainer/components/List/TrainerNoData';
import {
  ITrainerModel,
  ITrainerCollectionFilter,
  ITrainerCollection,
  ETrainerActionTypes
} from 'entities/Trainer/Trainer.models';

interface IComponentProps {
  academyId?: string;
  teamId?: string;
  clearFilter: () => void;
  actionType?: ETrainerActionTypes;
}
type AllProps = ITrainerConnectedProps & IComponentProps;

class TrainerListComponent extends InfiniteList<ITrainerCollection, ITrainerModel, AllProps, Partial<ITrainerCollectionFilter>> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getTrainerCollection, filter, academyId, teamId } = this.props;
    const { teamModel } = filter || {};

    const filterParams: ITrainerCollectionFilter = {
      ...params,
      ...filter
    };

    if (academyId) {
      filterParams.academy = academyId;
    }

    if (teamId || teamModel?.id) {
      filterParams.team = teamId || teamModel?.id;
    }

    const clearedFilterParams = Object.keys(filterParams)?.reduce((acc, key) => {
      const value: any = (filterParams as any)[key];
      return value !== undefined ? { ...acc, [key]: value } : acc;
    }, {});

    return getTrainerCollection(clearedFilterParams);
  };

  renderListItem = (item: ITrainerModel) => {
    const { teamId, actionType, academyId } = this.props;
    return <TrainerListItem item={item} teamId={teamId} actionType={actionType} academyId={academyId} />;
  };

  getCollection = () => {
    return this.props.trainerCollection;
  };
  clearCollection = () => {
    return this.props.clearTrainerCollection();
  };
  renderNoData = () => {
    const { trainerCollection, filter, clearFilter } = this.props;
    const { loading, data } = trainerCollection;

    const showClearFilterButton = !!filter?.search || filter?.hasTeam !== undefined || filter?.team !== undefined;

    return !loading && data?.data.length === 0 ? (
      <TrainerListNoData showClearFilterButton={showClearFilterButton} clearFilter={clearFilter} />
    ) : (
      <LoadingSpin />
    );
  };
}

export const TrainerList = communicationTrainer.injector(TrainerListComponent);
