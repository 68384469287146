import React, { useEffect, useMemo } from 'react';
import Radio from 'antd/es/radio';
import Spin from 'antd/es/spin';
import { RadioChangeEvent } from 'antd/es/radio/interface';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { communicationTariff, ITariffConnectedProps } from 'entities/Tariff/Tariff.communication';
import { ITariffCollectionFilter, ETariffStatus } from 'entities/Tariff/Tariff.models';
import { TariffsItemComponent } from 'entities/Tariff/components/Tariffs/TariffsItemComponent';

interface IProps {
  chosenTariff: string | undefined;
  onChoseTariff: (tariffId: string | undefined) => void;
  mentorId?: string;
  playerId?: string;
  subscriptionId?: string;
}

type AllProps = IProps & ITariffConnectedProps;

const Component: React.FC<AllProps> = props => {
  const {
    chosenTariff,
    onChoseTariff,
    tariffCollection,
    getTariffCollection,
    clearTariffCollection,
    mentorId,
    playerId,
    subscriptionId
  } = props;
  const { data, loading } = tariffCollection;
  const { t } = useTranslation();
  const tariffs = data?.data;

  useEffect(() => {
    const filter: ITariffCollectionFilter = {
      limit: 100,
      orderField: 'status',
      orderDirection: 'ASC',
      statuses: [ETariffStatus.Active]
    };

    if (!mentorId && !playerId && !subscriptionId) {
      return;
    }

    if (mentorId) {
      filter.forMentor = mentorId;
    }

    if (playerId) {
      filter.forPlayer = playerId;
    }

    if (subscriptionId) {
      filter.forSubscription = subscriptionId;
    }

    getTariffCollection(filter);
    return clearTariffCollection;
  }, [mentorId, playerId, subscriptionId]);

  const onChange = (e: RadioChangeEvent) => {
    onChoseTariff(e.target.value);
  };

  const chosenTariffCurrency = useMemo(() => {
    const chosenTariffValue = tariffs?.find(tariff => tariff.id === chosenTariff);

    return chosenTariffValue?.currentPrice?.currency || chosenTariffValue?.currency;
  }, [chosenTariff, tariffs]);

  useEffect(() => {
    const tariffsIds = tariffs?.length ? tariffs.map(tariff => tariff.id) : [];

    if ((!chosenTariff || !tariffsIds.includes(chosenTariff)) && tariffs?.length) {
      const yearTariff = tariffs.find(({ period }) => period === 12);
      onChoseTariff(yearTariff?.id);
    }
  }, [chosenTariff, tariffs]);

  return (
    <Spin spinning={loading} size="large" wrapperClassName="width-full">
      <Radio.Group className="tariffs-component" value={chosenTariff} onChange={onChange}>
        {tariffs?.map(tariff => (
          <TariffsItemComponent key={tariff.id} tariff={tariff} />
        ))}
      </Radio.Group>
      {chosenTariffCurrency && (
        <Row className="mt-5 px-3">
          <Typography.Paragraph className="mb-0">
            {t('tariffs-currency-disclaimer', { currency: chosenTariffCurrency })}
          </Typography.Paragraph>
        </Row>
      )}
    </Spin>
  );
};

export const TariffsComponent = communicationTariff.injector(Component);
