import React, { useContext, useMemo } from 'react';
import Typography from 'antd/es/typography';
import classNames from 'classnames';
import { Bar } from 'common/components/Bar/Bar';
import { valueToPercent } from 'common/helpers/number.helper';
import { normalizeTestValue } from 'common/helpers/normalize.helper';
import { MeasurementContext } from 'common/components/Provider/MeasurementContext';
import { notExistTranslationWrapper } from 'common/helpers/translate.helper';
import { IPlayerProfileMicroSkillModel } from 'entities/Player/Player.models';

interface IProps {
  data: Partial<IPlayerProfileMicroSkillModel>;
  color?: string;
  bgColor?: string;
  className?: string;
  showValue?: boolean;
  wrapperClassName?: string;
}

const Component: React.FC<IProps> = props => {
  const { data, color, bgColor, className, showValue, wrapperClassName } = props;
  const { value, units, maxValue, minValue, ranging, valuePercent, tag } = data;
  const { measurementSystem } = useContext(MeasurementContext);

  const barValue = useMemo(() => {
    const valuePercentData = valueToPercent(value, Number(minValue), Number(maxValue), ranging);
    return valuePercent !== null && valuePercent !== undefined ? valuePercent * 100 : valuePercentData;
  }, [data]);
  const normalizedValue = normalizeTestValue(tag, value, units as any, measurementSystem);

  return (
    <div className={classNames('width-full', { [wrapperClassName as string]: Boolean(wrapperClassName) })}>
      {showValue && (
        <Typography.Title level={5} className="mb-5">
          {normalizedValue} {notExistTranslationWrapper(units, { count: normalizedValue - 0 }, measurementSystem)}
        </Typography.Title>
      )}
      <Bar className={className} value={barValue} color={color} bgColor={bgColor} />
    </div>
  );
};

export const PlayerTestMicroSkillBar = Component;
