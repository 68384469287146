import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import message from 'antd/es/message';
import Typography from 'antd/es/typography';
import Radio from 'antd/es/radio';
import Grid from 'antd/es/grid';
import LinkOutlined from '@ant-design/icons/LinkOutlined';
import { useMediaPredicate } from 'react-media-hook';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router';
import { LayoutContent } from 'common/components/Layouts/ContentLayout';
import { copyToBuffer } from 'common/helpers/buffer.helper';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { ERoutesAcademy } from 'common/models/routesModel';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { getReferralLinkByCode } from 'entities/ReferralCode/ReferralCode.helper';
import { ReferralCodeSubscriptionActivities } from 'entities/ReferralCode/components/ReferralCodeSubscriptionActivities';
import { ReferralCodeSubscriptions } from 'entities/ReferralCode/components/ReferralCodeSubscriptions';

type AllProps = IAuthConnectedProps;

enum EReferralTabs {
  SubscriptionActivities = 'activities',
  Subscriptions = 'subscriptions'
}

const getRouteByType = (type: string) => ERoutesAcademy.AcademyReferrals.replace(':type?', type);

const AcademyReferral: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { authAcademy } = props;

  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const { push } = useHistory();
  const { type = EReferralTabs.SubscriptionActivities } = useParams();
  const { data } = authAcademy;
  const screens = Grid.useBreakpoint();

  const code = data?.ownReferralCode?.number;

  const referralLink = React.useMemo(() => {
    if (code) {
      return getReferralLinkByCode(code);
    }
    return undefined;
  }, [code]);

  const onCopy = (link?: string) => {
    if (link) {
      copyToBuffer(link).then(() => {
        message.success(t('Link has been copied'));
      });
    }
  };

  const onChange = React.useCallback(item => {
    push(getRouteByType(item.target.value));
  }, []);

  return (
    <LayoutContent className="academy-players-page">
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Row gutter={[8, 8]} justify="space-between" align="middle" className={`${isDesktop ? '' : 'px-5'}`}>
            <Col>
              <Typography.Title level={3} className="mb-0">
                {t('Referrals')}
              </Typography.Title>
            </Col>
            <Col xs={24}>
              <Row align="middle" justify="start" gutter={[16, 8]} wrap={!isDesktop}>
                <Col>
                  <Typography.Text className="t-nowrap">{t('Referral code')}</Typography.Text>
                </Col>
                <Col>
                  <Row align="middle" wrap={false} gutter={8}>
                    <Col>
                      <Input disabled className="color-text-secondary" value={code} />
                    </Col>
                    <Col>
                      <Button title={referralLink} icon={<LinkOutlined />} onClick={() => onCopy(referralLink)}>
                        {!screens.xs && t('Referral link')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row justify="center">
            <Radio.Group buttonStyle="solid" value={type} className={`width-full ${isDesktop ? '' : 'px-5'}`} onChange={onChange}>
              <Radio.Button value={EReferralTabs.Subscriptions}>{t('Referrals')}</Radio.Button>
              <Radio.Button value={EReferralTabs.SubscriptionActivities}>{t('Statistic')}</Radio.Button>
            </Radio.Group>
          </Row>
        </Col>
        <Col xs={24}>
          <Switch>
            <Route exact path={getRouteByType(EReferralTabs.Subscriptions)} component={ReferralCodeSubscriptions} />
            <Route
              exact
              path={getRouteByType(EReferralTabs.SubscriptionActivities)}
              component={ReferralCodeSubscriptionActivities}
            />
            <Redirect to={getRouteByType(EReferralTabs.Subscriptions)} />
          </Switch>
        </Col>
      </Row>
    </LayoutContent>
  );
};

export const AcademyReferralPage = communicationAuth.injector(AcademyReferral);
