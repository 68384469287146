import React from 'react';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';

export const EmptyTable: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Row justify="center" align="middle" className="t-align-c" style={{ height: '60vh' }}>
      {t('No Data')}
    </Row>
  );
};
