import { options } from 'options';
import React, { useEffect } from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Paragraph from 'antd/es/typography/Paragraph';
import window from 'global/window';
import { useHistory, useParams } from 'react-router';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { changeI18nLanguage } from 'common/helpers/i18n';
import { queryToObject } from 'common/helpers/filters.helper';
import { CenteredWithLogoLayout } from 'common/components/Layouts/CenteredWithLogoLayout';
import { ReactComponent as CopyIcon } from 'app/assets/icons/copy-icon.svg';
import { ReactComponent as OkIcon } from 'app/assets/icons/ok.svg';
import { getCoachGoogleLink } from 'entities/ReferralCode/ReferralCode.helper';

interface IQuery {
  token?: string;
  lang?: string;
  request?: string;
}

export const CoachInvitePage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{ academyId?: string; trainerId?: string }>();
  const { academyId, trainerId } = params;
  const { pathname } = history?.location;
  const { token, request, lang } = queryToObject<IQuery>({ token: undefined, lang: undefined, request: undefined }) as IQuery;

  const linkType = pathname.includes('invite-new')
    ? 'invite-new'
    : pathname.includes('invite-exists')
    ? 'invite-exists'
    : undefined;
  const langPart = lang ? `%26lang%3D${lang}` : '';

  useEffect(() => {
    if (lang) {
      changeI18nLanguage(lang);
    }
  }, []);

  const link =
    academyId && trainerId && linkType && token && request
      ? `${window.location?.origin}/redirect?efr=1&ibi=${options.coachGooglePlayId}&isi=${options.coachAppStoreId}&apn=${options.coachGooglePlayId}&link=https%3A%2F%2F${window.location.host}%2Facademy%2F${academyId}%2Ftrainer%2F${trainerId}%2F${linkType}%3Frequest%3D${request}%26token%3D${token}${langPart}`
      : getCoachGoogleLink();

  return (
    <CenteredWithLogoLayout>
      <div className="restore-password-page width-full p-7">
        <Row justify="center" align="middle">
          <span className="t-align-c fs-sm width-full mb-4">{t('Register')}</span>
          <span className="t-align-c fs-xxs width-full mb-4">{t('Scan qr for sign up')}</span>
          <Row className="flex-column" justify="center" align="middle">
            <Col>
              <QRCode value={link} size={200} includeMargin={true} />
            </Col>
            <span className="t-align-c fs-xxs width-full mb-4 mt-4">{t('Or')}</span>
            <span className="t-align-c fs-xxs width-full mb-4">{t('Click to icon for copy')}</span>

            <Paragraph
              copyable={{
                icon: [<CopyIcon key="copy-icon" />, <OkIcon key="copy-icon" />],
                tooltips: [t('Click for copy'), t('Copied')],
                text: link
              }}
            />
          </Row>
        </Row>
      </div>
    </CenteredWithLogoLayout>
  );
};
