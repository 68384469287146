import React from 'react';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { useTranslation } from 'react-i18next';
import { BarChart } from 'common/components/BarChart/BarChart';
import { EPlayerPhysicalSkills, EPlayerTechSkills } from 'entities/Player/Player.models';
import { ISkills } from 'entities/Share/Share.models';
import { ESkillTagName, TSkillName } from 'entities/Skill/Skill.models';

interface INormalizeSkills {
  key: string;
  value: number;
}

const normalizeSkills = (skills: (EPlayerPhysicalSkills | EPlayerTechSkills)[], values: ISkills): INormalizeSkills[] =>
  skills.map(skillName => {
    const currentSkill = values[skillName];
    return {
      key: skillName,
      value: currentSkill?.value ?? 0
    };
  });

interface IComponentProps {
  skillsList: (EPlayerTechSkills | EPlayerPhysicalSkills)[];
  skills?: ISkills;
  title: string;
  color: string;
}

const ShareSkillsComponent: React.FC<IComponentProps> = props => {
  const { t } = useTranslation();
  const { skills, skillsList, title, color } = props;

  const datasets = React.useMemo(() => (skills ? normalizeSkills(skillsList, skills) : []), [skills]);

  return (
    <Card bordered={false} className="px-4 height-full pb-3 promo-page__card">
      <span className="promo-page__header">{title}</span>
      <Row gutter={[0, 32]}>
        {datasets.map(({ key, value }) => (
          <Col key={key} span={24}>
            <BarChart color={color} label={t(ESkillTagName[key as TSkillName])} value={value} />
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export const ShareSkills = ShareSkillsComponent;
