import classNames from 'classnames';
import React, { FC } from 'react';

interface IPaperProps {
  className?: string;
  onClick?(): void;
}

export const Paper: FC<IPaperProps> = props => {
  const { children, onClick = () => undefined, className = '' } = props;

  return (
    <div onClick={onClick} className={classNames('paper p-5 bg-component radius-default', { [className]: Boolean(className) })}>
      {children}
    </div>
  );
};
