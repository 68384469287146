import { options } from 'options';

export const getCoachGoogleLink = () => `https://play.google.com/store/apps/details?id=${options.coachGooglePlayId}`;
export const getCoachAppleLink = () => `https://apps.apple.com/ru/app/junistat-football-hub/id${options.coachAppStoreId}`;

export const getGoogleLink = () => `https://play.google.com/store/apps/details?id=${options.googlePlayId}`;
export const getAppleLink = () => `https://apps.apple.com/ru/app/junistat-football-hub/id${options.appStoreId}`;

export const getGoogleReferralLinkByCode = (code: string) =>
  `https://play.google.com/store/apps/details?id=${options.googlePlayId}&referrer=utm_source%3Dsharing%26utm_content%3D${code}`;
export const getSiteReferralLinkByCode = (code?: string) => `${options.siteUrl}?referral=${code}`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getAppleReferralLinkByCode = (code?: string) =>
  `https://apps.apple.com/ru/app/junistat-football-hub/id${options.appStoreId}`;

export const getReferralLinkByCode = (code?: string) =>
  `https://junistatapp.page.link/?efr=1&ibi=com.junistat.jsplayer&isi=1554737071&apn=com.junistat.player&link=${options.siteUrl}%3Freferrer%3D${code}%26utm_source%3Dsharing%26utm_campaign%3D${code}`;
