import { Col } from 'antd';
import React, { useMemo } from 'react';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { VideoPrivate } from 'common/components/Video/VideoPrivate';
import { IPlayerProfileVideoModel } from 'entities/Player/Player.models';

interface IComponentProps {
  item: IPlayerProfileVideoModel;
}

type AllProps = IComponentProps;

const previewWidth = '100%';
const previewHeight = 144;

export const PlayerVideosBlockItem: React.FC<AllProps> = props => {
  const { item } = props;
  const { description, youtubeId, previewId, videoId, youtubePreviewUrl } = item;

  const videoPreview = useMemo(() => {
    if (youtubeId) {
      return youtubePreviewUrl;
    }
    return previewId;
  }, []);

  return (
    <Row gutter={[8, 8]} className="overflow-hidden word-break-all">
      <Col xs={24}>
        <VideoPrivate
          className="width-full"
          preview={videoPreview}
          youtubePreviewUrl={youtubePreviewUrl}
          width={previewWidth}
          height={previewHeight}
          videoId={videoId}
          youtubeId={youtubeId}
          previewClass="radius-default"
          isPublic
        />
      </Col>
      <Col xs={24}>
        <Typography.Paragraph ellipsis={{ rows: 5 }} className="mb-0">
          {description}
        </Typography.Paragraph>
      </Col>
    </Row>
  );
};
