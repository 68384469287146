import { Divider, message } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Col from 'antd/es/col';
import { useTranslation } from 'react-i18next';
import Input from 'antd/es/input';
import Typography from 'antd/es/typography';
import Row from 'antd/es/row';
import { useFormMapper } from '@axmit/antd4-helpers';
import { StripeCardElement } from 'common/components/Stripe/StripeCardElement/StripeCardElement';
import { getCurrencySign, getTariffAmountAndCurrency, translateTariffData } from 'common/helpers/tariff-helper';
import { normalize } from 'common/helpers/normalize.helper';
import { StripeContext } from 'common/components/Stripe/StripeProvider';
import { EModalStripePaymentType } from 'entities/UI/UI.models';
import {
  ESubscriptionsEngines,
  ESubscriptionStatus,
  ISubscriptionChangeTariffParams,
  ISubscriptionCreate,
  ISubscriptionPromoCodeInfo
} from 'entities/Subscription/Subscription.models';
import { communicationTariff, ITariffConnectedProps } from 'entities/Tariff/Tariff.communication';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { communicationSubscription, ISubscriptionConnectedProps } from 'entities/Subscription/Subscription.communication';
import { subscriptionTransport } from 'entities/Subscription/Subscription.transport';

type AllProps = IUIConnectedProps & ITariffConnectedProps & ISubscriptionConnectedProps;

const FORM_NAME = 'change-payment-method';
const COUPON_FORM_NAME = 'coupon-form';

interface IClosureFields {
  tariff?: string;
  mentorRequest?: string;
  subscriptionId?: string;
  type?: EModalStripePaymentType;
}

const Component: React.FC<AllProps> = props => {
  const {
    UIStripePaymentModal,
    tariffCollection,
    subscriptionModel,
    addSubscriptionModel,
    changeTariffSubscriptionModel,
    updateSubscriptionModel
  } = props;

  const withCoupon = UIStripePaymentModal?.data?.withCoupon;
  const withCardDetails = UIStripePaymentModal?.data?.withCardDetails;
  const cardDetailBtnText = UIStripePaymentModal?.data?.cardDetailBtnText;
  const loading = UIStripePaymentModal?.data?.loading;
  const tariffRaw = UIStripePaymentModal?.data?.tariff;
  const mentorRequestRaw = UIStripePaymentModal?.data?.mentorRequest;
  const subscriptionIdRaw = UIStripePaymentModal?.data?.subscription;
  const typeRaw = UIStripePaymentModal?.data?.type;

  const { addListener, loading: stripeLoading, clearSavedPaymentMethod } = useContext(StripeContext);
  const {
    data: subscription,
    params: subscriptionParams,
    errors: subscriptionErrors,
    loading: subscriptionLoading
  } = subscriptionModel;
  const { t } = useTranslation();
  const [couponForm] = Form.useForm();
  const [cardElementReady, setCardElementReady] = useState<boolean>(false);
  const [cardComplete, setCardComplete] = useState<boolean>(false);
  const [couponDiscount, setCouponDiscount] = useState<number>();
  const [, setClosureFields] = useState<IClosureFields>({
    tariff: tariffRaw,
    mentorRequest: mentorRequestRaw,
    subscriptionId: subscriptionIdRaw,
    type: typeRaw
  });
  const tariffs = tariffCollection?.data?.data || [];

  // TODO Stripe: save this in ref
  useEffect(() => {
    setClosureFields({
      mentorRequest: mentorRequestRaw,
      tariff: tariffRaw,
      subscriptionId: subscriptionIdRaw,
      type: typeRaw
    });
  }, [mentorRequestRaw, tariffRaw, subscriptionIdRaw, typeRaw]);

  const commonLoading = useMemo(() => stripeLoading || subscriptionLoading || loading, [
    stripeLoading,
    subscriptionLoading,
    loading
  ]);

  const couponFormErrors = useMemo(() => {
    const errorsData: any = subscriptionErrors;

    if (errorsData?.data?.errors?.['.coupon']) {
      errorsData.validation.coupon = errorsData?.data?.errors?.['.coupon']
        .map((error: any) => t(error?.message?.replace(/^Stripe./, '')))
        .filter((el: any) => !!el);
    }

    return subscriptionErrors;
  }, [subscriptionErrors]);

  const { fields } = useFormMapper(['coupon'], subscription, subscriptionParams, couponFormErrors);

  const submitBtnDisabled = useMemo(() => !cardElementReady || !cardComplete, [cardElementReady, cardComplete]);

  const selectedTariff = useMemo(() => {
    if (tariffRaw) {
      return tariffs.find(item => item.id === tariffRaw);
    }

    return undefined;
  }, [tariffRaw, tariffs]);

  const { title: selectedTariffTitle } = translateTariffData(selectedTariff);
  const { tariffCurrency, tariffAmount } = getTariffAmountAndCurrency(selectedTariff);

  const onChangeTariffCallback = useCallback(
    async (values: { coupon?: string }, paymentMethodToken?: string) => {
      const { subscriptionId, tariff, mentorRequest }: IClosureFields = await new Promise<IClosureFields>(resolve =>
        setClosureFields(upToDateClosureFields => {
          resolve(upToDateClosureFields);
          return upToDateClosureFields;
        })
      );

      if (tariff) {
        const body: Omit<ISubscriptionChangeTariffParams, 'subscriptionId'> = {
          tariff,
          onFail: clearSavedPaymentMethod,
          engine: ESubscriptionsEngines.Stripe
        };

        if (values?.coupon) {
          body.coupon = values?.coupon;
        }

        if (paymentMethodToken) {
          body.paymentMethodToken = paymentMethodToken;
        }

        if (subscriptionId) {
          changeTariffSubscriptionModel({ subscriptionId, ...body });
        } else if (mentorRequest) {
          addSubscriptionModel({ mentorRequest, engine: ESubscriptionsEngines.Stripe, ...body });
        }
      }
    },
    [changeTariffSubscriptionModel, clearSavedPaymentMethod]
  );

  const onChangeLastPaymentListener = useCallback(
    async lastSavedPaymentMethod => {
      if (lastSavedPaymentMethod?.paymentId) {
        const values = couponForm.getFieldsValue();

        // eslint-disable-next-line sonarjs/no-identical-functions
        const { mentorRequest, tariff, subscriptionId, type }: IClosureFields = await new Promise<IClosureFields>(resolve =>
          setClosureFields(upToDateClosureFields => {
            resolve(upToDateClosureFields);
            return upToDateClosureFields;
          })
        );

        const commonBody = {
          onFail: clearSavedPaymentMethod
        };

        // TODO Stripe: change everything to type
        switch (true) {
          case type === EModalStripePaymentType.ReactivateSubscription: {
            if (subscriptionId) {
              updateSubscriptionModel({
                id: subscriptionId,
                status: ESubscriptionStatus.Active,
                engine: ESubscriptionsEngines.Stripe,
                paymentMethodToken: lastSavedPaymentMethod.paymentId
              });
            }
            break;
          }
          case !subscriptionId: {
            if (mentorRequest) {
              const createBody: ISubscriptionCreate = {
                ...commonBody,
                engine: ESubscriptionsEngines.Stripe,
                mentorRequest,
                tariff,
                paymentMethodToken: lastSavedPaymentMethod.paymentId
              };

              if (values.coupon) {
                createBody.coupon = values.coupon;
              }

              addSubscriptionModel(createBody);
            }
            break;
          }
          case !tariff: {
            if (subscriptionId) {
              updateSubscriptionModel({
                ...commonBody,
                id: subscriptionId,
                paymentMethodToken: lastSavedPaymentMethod.paymentId
              });
            }
            break;
          }
          default: {
            void onChangeTariffCallback(values, lastSavedPaymentMethod.paymentId);
          }
        }
      }
    },
    [couponForm, setClosureFields, onChangeTariffCallback, updateSubscriptionModel]
  );

  const onValuesChange = useDebouncedCallback(() => {
    const couponValue = couponForm.getFieldValue('coupon');
    if (couponValue) {
      subscriptionTransport
        .getSubscriptionPromoCodeInfo({ code: couponValue })
        .then((r: ISubscriptionPromoCodeInfo) => {
          if (r.percentOff) {
            message.success(t('Promo code successfully applied'));
            setCouponDiscount(r.percentOff);
          } else {
            message.error(t('Coupon does not exist'));
          }
        })
        .catch(() => {
          setCouponDiscount(undefined);
          message.error(t('Coupon does not exist'));
        });
    } else {
      setCouponDiscount(undefined);
    }
  }, 1000);

  const tariffAmountValue: number = useMemo(() => Number(tariffAmount), [tariffAmount]);
  const price = useMemo(
    () => (couponDiscount ? tariffAmountValue - (tariffAmountValue * couponDiscount) / 100 : tariffAmountValue),
    [tariffAmountValue, couponDiscount]
  );
  useEffect(() => {
    addListener?.(onChangeLastPaymentListener);
  }, []);

  return (
    <Row gutter={[12, 24]}>
      {withCardDetails && (
        <Col xs={24} sm={withCoupon ? 12 : 24} md={withCoupon ? 12 : 24}>
          <StripeCardElement formName={FORM_NAME} onCardComplete={setCardComplete} onReady={setCardElementReady} />
          {!withCoupon && (
            <Button
              loading={!cardElementReady || commonLoading}
              disabled={submitBtnDisabled}
              block
              type="primary"
              form={FORM_NAME}
              htmlType="submit"
              size="large"
            >
              {cardDetailBtnText}
            </Button>
          )}
        </Col>
      )}
      {withCoupon && (
        <Col className="border-light p-7" xs={24} sm={withCardDetails ? 12 : 24} md={withCardDetails ? 12 : 24}>
          <Form
            name={COUPON_FORM_NAME}
            layout="vertical"
            form={couponForm}
            fields={fields}
            onFinish={onChangeTariffCallback}
            onValuesChange={() => onValuesChange.callback()}
          >
            <Form.Item name="coupon" label={t('Coupon')}>
              <Input disabled={commonLoading} placeholder={t('Enter coupon')} />
            </Form.Item>
          </Form>
          <Row wrap={false}>
            <Col flex={1}>
              <Typography.Text>
                {t('Subscription')} ({selectedTariffTitle})
              </Typography.Text>
            </Col>
            <Col className="mb-5">
              {!!tariffAmountValue && (
                <Typography.Text className={couponDiscount ? 'text-line-through' : undefined}>
                  {getCurrencySign(tariffCurrency as string)}&nbsp;{normalize(Number(tariffAmountValue))}
                </Typography.Text>
              )}
              {couponDiscount && !!price && (
                <Row justify="end" align="middle">
                  <Typography.Text>
                    {getCurrencySign(tariffCurrency as string)}&nbsp;{normalize(Number(price))}
                  </Typography.Text>
                </Row>
              )}
            </Col>
          </Row>

          {couponDiscount && (
            <Row justify="end" align="middle">
              <Typography.Paragraph className="color-gray mb-0">{t('Price is approximate')}</Typography.Paragraph>
            </Row>
          )}
          <Divider className="my-5" />
          <Typography.Paragraph className="color-gray">
            {t('We will write off the price of a new subscription when the current one ends')}
          </Typography.Paragraph>
          <Typography.Paragraph className="color-gray">
            {t('We will write off the funds taking into account the tax of the specified region')}
          </Typography.Paragraph>
          <Button
            loading={commonLoading}
            disabled={commonLoading}
            block
            type="primary"
            form={withCardDetails ? FORM_NAME : COUPON_FORM_NAME}
            htmlType="submit"
            size="large"
          >
            {t('Pay')}
          </Button>
        </Col>
      )}
    </Row>
  );
};

export const StripePaymentModalBody = communicationSubscription.injector(
  communicationTariff.injector(communicationUI.injector(Component))
);
