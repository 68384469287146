import { ButtonProps } from 'antd';
import React from 'react';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import { useTranslation } from 'react-i18next';
import { communicationTrainer, ITrainerConnectedProps } from 'entities/Trainer/Trainer.communication';

interface IProps {
  playerId?: string;
  trainerId?: string;
}

type AllProps = IProps & ITrainerConnectedProps & ButtonProps;

const UnlinkActionButtonComponent: React.FC<AllProps> = props => {
  const { playerId, trainerId, trainerLinkModel, deleteTrainerLinkModel, ...other } = props;
  const { loading } = trainerLinkModel;
  const { t } = useTranslation();

  const onStopPropagation = (e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
  };

  const onUnlinkPlayer = (e?: React.MouseEvent<HTMLElement>) => {
    onStopPropagation(e);
    if (trainerId && playerId) {
      deleteTrainerLinkModel({ id: trainerId, playerId });
    }
  };

  return (
    <Popconfirm
      okButtonProps={{
        type: 'text',
        size: 'middle',
        danger: true
      }}
      cancelButtonProps={{
        size: 'middle'
      }}
      okText={t('Unlink')}
      cancelText={t('Cancel')}
      title={t('Unlink player from trainer?')}
      onConfirm={onUnlinkPlayer}
      onCancel={onStopPropagation}
    >
      <Button type="primary" block danger disabled={loading} loading={loading} {...other}>
        {t('Unlink')}
      </Button>
    </Popconfirm>
  );
};

export const UnlinkActionButton = communicationTrainer.injector(UnlinkActionButtonComponent);
