import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  buildCollectionPreRequestDataMapper
} from '@axmit/redux-communications';
import { takeLatest } from 'redux-saga/effects';
import { tariffTransport } from 'entities/Tariff/Tariff.transport';
import { ITariffCollection, ITariffCollectionFilter, ITariffModel, TTariffParamsModel } from 'entities/Tariff/Tariff.models';

const namespace = 'tariff';

export interface ITariffConnectedProps {
  tariffModel: StoreBranch<ITariffModel, TTariffParamsModel>;
  tariffCollection: StoreBranch<ITariffCollection, ITariffCollectionFilter>;

  getTariffModel(id: string): void;
  getTariffCollection(filter?: ITariffCollectionFilter): void;

  clearTariffModel(): void;
  clearTariffCollection(): void;
}

const TariffModelAPIProviders = [new APIProvider(EActionsTypes.get, tariffTransport.get)];
const TariffCollectionAPIProviders = [
  new APIProvider(
    EActionsTypes.get,
    tariffTransport.getCollection,
    {
      preRequestDataMapper: buildCollectionPreRequestDataMapper<ITariffCollection, ITariffCollectionFilter>()
    },
    takeLatest
  )
];

const branches = [new Branch('model', TariffModelAPIProviders), new Branch('collection', TariffCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationTariff = buildCommunication<ITariffConnectedProps>(strategy);
