import React, { SyntheticEvent, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import Col from 'antd/es/col';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import HeartTwoTone from '@ant-design/icons/HeartTwoTone';
import HeartOutlined from '@ant-design/icons/HeartOutlined';
import { getPlayerRoute } from 'common/models/routesModel';
import { EUserRole } from 'entities/User/User.models';
import { PrivateContent } from 'entities/Auth/components/PrivateContent';
import { IPlayerProfileModel, IPlayerProfilePlayerModel } from 'entities/Player/Player.models';
import { PlayerCardFull } from 'entities/Player/components/Card/PlayerCardFull';
import { extractPlayerFromProfile } from 'entities/Player/helpers/player.helper';
import { IPlayerConnectedProps, communicationPlayer } from 'entities/Player/Player.communication';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';
import { PlayerFavorite } from 'entities/Player/components/ActionButtons/PlayerFavorite';
import { EAcademyRequestSuccessUpdateModels } from 'entities/AcademyRequest/AcademyRequest.models';

interface IComponentProps {
  profile: IPlayerProfileModel;
}

type AllProps = IComponentProps & IPlayerConnectedProps & IAuthConnectedProps;

const Component: React.FC<AllProps> = props => {
  const { profile, deletePlayerCompare, authUser } = props;
  const { academyToPlayerRequest, academy } = profile;
  const history = useHistory();

  const isAcademyPlayer = useMemo(() => academy?.academyId === authUser.data?.academyWorker?.academy.id, [academy, authUser]);

  const player = useMemo(() => extractPlayerFromProfile(profile), [profile]);

  const onClickPlayerCallback = useCallback(
    (selectedPlayer: IPlayerProfilePlayerModel) => {
      history.push(getPlayerRoute(selectedPlayer.id as string));
    },
    [history]
  );

  const onClickRemovePlayer = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();

      if (player?.id) {
        deletePlayerCompare(player.id);
      }
    },
    [player, deletePlayerCompare]
  );

  return (
    <Col className="width-full players-compare__player-card">
      <PlayerCardFull
        player={player}
        onClick={onClickPlayerCallback}
        actionElements={
          <Col>
            <PrivateContent availableFor={EUserRole.AcademyWorker}>
              <PlayerFavorite
                optimisticUpdate
                modelsToUpdate={[EAcademyRequestSuccessUpdateModels.Profiles]}
                id={player.id}
                academyToPlayerRequest={academyToPlayerRequest}
                isAcademyPlayer={isAcademyPlayer}
                addElement={<HeartOutlined className="mr-2" />}
                removeElement={<HeartTwoTone twoToneColor="#f00" className="mr-2" />}
              />
            </PrivateContent>
            <div>
              <DeleteOutlined onClick={onClickRemovePlayer} />
            </div>
          </Col>
        }
      />
    </Col>
  );
};

export const PlayersCompareCardElement = communicationAuth.injector(communicationPlayer.injector(Component));
