import { IBasePathParams } from 'common/models/requestModels';
import { ILocationModel } from 'common/helpers/location.helper';

export interface IFranchiseModel extends Components.Schemas.FranchiseDto {}
export interface IFranchiseUpdateParams extends Components.Schemas.UpdateFranchiseDto, IBasePathParams {}
export interface IFranchiseParamsModel extends IFranchiseUpdateParams, IBasePathParams {}

export interface IFranchiseRequestModel extends Components.Schemas.FranchiseLinkDto {}
export interface IFranchiseRequestModelUpdateStatusParams
  extends Components.Schemas.UpdateFranchiseLinkStatusDto,
    IBasePathParams {}
export interface IFranchiseRequestParamsModel extends IFranchiseRequestModelUpdateStatusParams, IBasePathParams {}

export interface IFranchiseRequestCollection extends Components.Schemas.FranchiseLinkCollectionDto {}
export interface IFranchiseRequestCollectionFilter extends Paths.FranchiseLinkControllerList.QueryParameters {
  location?: ILocationModel;
}

export interface IFranchiseRequestCreate extends Components.Schemas.InviteChildDto, IBasePathParams {}

export interface IFranchiseConfirmInviteNewParams extends Components.Schemas.ConfirmNewChildDto {}
export interface IFranchiseConfirmInviteExistParams extends Components.Schemas.ConfirmExistsChildDto {
  franchiseName: string;
}

export interface IFranchiseInviteCheckParams extends Components.Schemas.CheckChildInviteDto {}

export enum EFranchiseSuccessMessage {
  AcademyCreated = 'Academy successfully created',
  ResendInvitation = 'Invitation was successfully resend'
}

export enum IFranchiseConfirmInviteActions {
  Accept = 'accept',
  Declined = 'decline'
}

export enum EFranchiseTypes {
  Main = 'main',
  Child = 'child'
}

export enum EFranchiseStatuses {
  New = 'new',
  Active = 'active',
  Canceled = 'canceled'
}
