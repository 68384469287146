import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useFormMapper } from '@axmit/antd4-helpers';
import { CenteredWithLogoLayout } from 'common/components/Layouts/CenteredWithLogoLayout';
import { requiredField } from 'common/helpers/filed-rules';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { IUserUpdateModelForm } from 'entities/User/User.models';
import { communicationUser, IUserConnectedProps } from 'entities/User/User.communication';

interface IFormValues {
  firstName: string;
  lastName: string;
}

type AllProps = IAuthConnectedProps & IUserConnectedProps;

const SignUpFinishComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { deleteAuthModel, authUser, updateUserModel } = props;
  const { data: authUserData, loading: authUserLoading, params, errors } = authUser;
  const isLoading = authUserLoading;
  const { fields } = useFormMapper(['firstName', 'lastName'], authUserData, params, errors);

  const onFinish = (values: IFormValues) => {
    if (authUserData?.id) {
      const { firstName, lastName } = values;
      const body: IUserUpdateModelForm = {
        id: authUserData.id,
        firstName,
        lastName
      };

      updateUserModel(body);
    }
  };

  const onLogout = () => {
    deleteAuthModel();
  };

  return (
    <CenteredWithLogoLayout>
      <Card className="p-5">
        <Row justify="start" className="mb-5">
          <Typography.Title level={3} className="mb-0">
            {t('Complete registration')}
          </Typography.Title>
        </Row>
        <Form onFinish={onFinish} fields={fields} layout="vertical">
          <Form.Item rules={[requiredField]} name="firstName" className="mb-5 width-full" label={t('First name')}>
            <Input disabled={isLoading} size="large" placeholder={t('First name')} />
          </Form.Item>

          <Form.Item rules={[requiredField]} name="lastName" className="mb-5 width-full" label={t('Last name')}>
            <Input disabled={isLoading} size="large" placeholder={t('Last name')} />
          </Form.Item>
          <Form.Item className="mb-5">
            <Button htmlType="submit" block type="primary" size="large" disabled={isLoading}>
              {t('Register')}
            </Button>
          </Form.Item>
        </Form>
        <Row justify="start">
          <Button block onClick={onLogout}>
            {t('Logout')}
          </Button>
        </Row>
      </Card>
    </CenteredWithLogoLayout>
  );
};

export const SignUpFinishPage = communicationUser.injector(communicationAuth.injector(SignUpFinishComponent));
