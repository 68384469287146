import moment from 'moment';
import { formatDateFromServer } from 'common/helpers/date.helper';
import { clientDateFullMonthFormat } from 'common/models/dateModels';
import { normalize } from 'common/helpers/normalize.helper';
import {
  ESubscriptionInvoiceType,
  ESubscriptionStatus,
  IStripeNextTariff,
  IStripeNextWithdrawal,
  ISubscriptionModel,
  ISubscriptionPhaseModel
} from 'entities/Subscription/Subscription.models';
import { CANCEL_SUBSCRIPTION_TARIFF_ID } from 'entities/Tariff/Tariff.models';
import { IPlayerModel } from 'entities/Player/Player.models';

export const isPrepaymentDisabled = (subscription: ISubscriptionModel | undefined) =>
  subscription?.tariff &&
  subscription?.tariff?.restrictPrepayment &&
  subscription?.prepaidTo &&
  !moment()
    .add(subscription?.tariff?.period, 'M')
    .isSameOrAfter(moment(subscription?.prepaidTo));

export const getSubscriptionInfoTextClass = (size: 'large' | 'middle' | 'small' = 'middle', block?: boolean) => {
  const className = ['color-gray', 'd-block'];

  switch (size) {
    case 'large':
      className.push('py-3 fs-xs');
      break;
    case 'middle':
      className.push('py-2 fs-xxs subscription-button__check-payment__middle');
      break;
    case 'small':
      className.push('py-1 fs-xxs');
      break;
  }

  if (block) {
    className.push('width-full t-align-c');
  }

  return className.join(' ');
};

export const getStripeSubscriptionTariffId = (subscription?: ISubscriptionModel | null): string => {
  return subscription?.currentPhase?.tariffId || CANCEL_SUBSCRIPTION_TARIFF_ID;
};

export const getStripeSubscriptionNextPayablePhase = (
  subscription?: ISubscriptionModel | null
): ISubscriptionPhaseModel | undefined => {
  const currentPhase = subscription?.currentPhase;

  return subscription?.phases?.find(phase => {
    return (
      moment(phase.periodStart).isSameOrAfter(currentPhase?.periodEnd) &&
      Boolean(phase?.tariffId) &&
      phase?.type === ESubscriptionInvoiceType.Payment
    );
  });
};

export const getStripeSubscriptionNextTariffExists = (subscription?: ISubscriptionModel | null): boolean => {
  const selectedTariffId = getStripeSubscriptionTariffId(subscription);
  const nextPhase = getStripeSubscriptionNextPayablePhase(subscription);

  return Boolean(nextPhase?.tariffId) && selectedTariffId !== nextPhase?.tariffId;
};

export const getStripeSubscriptionCurrentTariffEndDate = (subscription?: ISubscriptionModel | null): string | undefined => {
  let periodEnd = subscription?.currentPhase?.periodEnd;
  const lastGiftcardPhase = getStripeLastGiftcardPhaseFromCurrent(subscription);

  if (lastGiftcardPhase?.periodEnd) {
    periodEnd = lastGiftcardPhase.periodEnd;
  }

  return periodEnd;
};

export const getStripeLastGiftcardPhaseFromCurrent = (
  subscription?: ISubscriptionModel | null
): ISubscriptionPhaseModel | undefined => {
  const currentPhase = subscription?.currentPhase;
  const nextPhase = getStripeSubscriptionNextPayablePhase(subscription);
  const nextTariffStartDate = nextPhase?.periodStart;

  const giftcardPhasesBeforeNextTariff: ISubscriptionPhaseModel[] = [];

  subscription?.phases?.forEach(phase => {
    if (
      phase?.type === ESubscriptionInvoiceType.Giftcard &&
      moment(phase.periodStart).isSameOrAfter(currentPhase?.periodEnd) &&
      (nextTariffStartDate ? moment(phase.periodStart).isBefore(nextTariffStartDate) : true)
    ) {
      giftcardPhasesBeforeNextTariff.push(phase);
    }
  });

  giftcardPhasesBeforeNextTariff.sort((phaseA, phaseB) => (moment(phaseA.periodEnd).isBefore(phaseB.periodEnd) ? 1 : -1));

  return giftcardPhasesBeforeNextTariff[0];
};

export const getStripeSubscriptionNextTariff = (subscription?: ISubscriptionModel | null): IStripeNextTariff | undefined => {
  const delayedCancellation = subscription?.delayedCancellation;
  const nextPhase = getStripeSubscriptionNextPayablePhase(subscription);
  const isNextTariffExists = getStripeSubscriptionNextTariffExists(subscription);
  const currentTariffEndDate = getStripeSubscriptionCurrentTariffEndDate(subscription);
  const isGiftcardPhase = isStripeCurrentPhaseGiftcard(subscription);

  if (delayedCancellation) {
    if (isGiftcardPhase) {
      return undefined;
    }

    return {
      id: CANCEL_SUBSCRIPTION_TARIFF_ID,
      periodStart: currentTariffEndDate ? formatDateFromServer(currentTariffEndDate, clientDateFullMonthFormat) : undefined
    };
  }

  if (isNextTariffExists && nextPhase && nextPhase.tariffId) {
    return {
      id: nextPhase.tariffId,
      periodStart: formatDateFromServer(nextPhase.periodStart, clientDateFullMonthFormat)
    };
  }

  return undefined;
};

export const getStripeNextWithdrawal = (subscription?: ISubscriptionModel | null): IStripeNextWithdrawal | undefined => {
  const nextInvoice = subscription?.nextInvoice;
  const nextPhase = getStripeSubscriptionNextPayablePhase(subscription);

  if (nextInvoice?.type === ESubscriptionInvoiceType.Payment) {
    const amount = (nextInvoice?.amount || 0) - (nextInvoice.discountAmount || 0);

    return {
      date: formatDateFromServer(nextInvoice.date, clientDateFullMonthFormat),
      amount: amount >= 0 ? amount : 0,
      currency: nextInvoice.currency
    };
  } else if (nextPhase) {
    return {
      date: formatDateFromServer(nextPhase?.periodStart, clientDateFullMonthFormat)
    };
  }

  return undefined;
};

export const getStripeSubscriptionAllGiftcards = (
  subscription?: ISubscriptionModel | null
): ISubscriptionPhaseModel[] | undefined => {
  return subscription?.phases?.filter(phase => phase?.type === ESubscriptionInvoiceType.Giftcard);
};

export const isStripeCurrentPhaseGiftcard = (subscription?: ISubscriptionModel | null): boolean => {
  return (
    subscription?.status === ESubscriptionStatus.Active && subscription?.currentPhase?.type === ESubscriptionInvoiceType.Giftcard
  );
};

export const isStripeTrialVersion = (subscription?: ISubscriptionModel | null): boolean => {
  return (
    subscription?.status === ESubscriptionStatus.Active &&
    subscription?.currentPhase?.type === ESubscriptionInvoiceType.Giftcard &&
    !subscription?.currentPhase?.tariffId
  );
};

export const stripeTrialSubscriptionLeft = (subscription?: ISubscriptionModel | null): number | undefined => {
  const isTrialVersion = isStripeTrialVersion(subscription);
  const currentPhaseDateEnd = getStripeSubscriptionCurrentTariffEndDate(subscription);

  if (!isTrialVersion) {
    return undefined;
  }

  return moment(currentPhaseDateEnd).diff(moment(), 'days');
};

export const isSubscriptionActive = (subscription?: ISubscriptionModel | null): boolean => {
  return subscription?.status === ESubscriptionStatus.Active;
};

export const getStripePaymentIncludingDiscount = (amount?: number, discountAmount?: number): undefined | string => {
  if (amount) {
    if (discountAmount) {
      return normalize(amount - discountAmount);
    } else {
      return normalize(amount);
    }
  }

  return undefined;
};

export const isAcademyHideTariffs = (player?: Partial<IPlayerModel> | null): boolean =>
  player?.academyLink?.academy?.hideTariffs ||
  !!player?.academyLinks?.find(item => (item?.status === 'active' ? item?.academy?.hideTariffs : false));
