import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport, objectToQuery } from '@axmit/transport';
import {
  EStatisticTypeValue,
  TStatisticCollection,
  IStatisticCollectionFilter,
  IStatisticModel
} from 'entities/Statistic/Statistic.models';

const basePath = '/statistics';

class StatisticTransport extends BaseHttpTransport<AxiosStatic, IStatisticModel, TStatisticCollection, null, void, null> {
  public getStatisticCollection = ({
    id,
    statisticType,
    isFranchise,
    ...params
  }: IStatisticCollectionFilter): Promise<TStatisticCollection> => {
    const isBilling = statisticType === EStatisticTypeValue.Billing;
    const statisticEntity = isFranchise ? 'franchise' : 'academy';
    return axios.get(`${basePath}/${statisticEntity}/${id}${isBilling ? '/billing' : ''}${objectToQuery(params)}`);
  };
}

export const getStatisticFileUrl = ({ id, statisticType, isFranchise, ...params }: Partial<IStatisticCollectionFilter>) => {
  const isBilling = statisticType === EStatisticTypeValue.Billing;
  const statisticEntity = isFranchise ? 'franchise' : 'academy';

  return `${basePath}/${statisticEntity}/${id}${isBilling ? '/billing' : ''}/csv${objectToQuery(params)}`;
};

export const statisticTransport = new StatisticTransport(basePath, axios);
