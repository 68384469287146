import React, { useContext, useEffect } from 'react';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { PlayerProviderContext } from 'entities/Player/components/PlayerProvider';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';
import { PlayerSettingTabYookassa } from 'entities/Player/components/Tabs/PlayerSettingTab/PlayerSettingTabYookassa';
import { PlayerSettingTabStripeTabs } from 'entities/Player/components/Tabs/PlayerSettingTab/PlayerSettingTabStripe/PlayerSettingTabStripeTabs';
import { ESubscriptionsEngines } from 'entities/Subscription/Subscription.models';

interface IProps {
  isDesktop: boolean;
}

type AllProps = IProps & IMentorRequestConnectedProps;

const Component: React.FC<AllProps> = props => {
  const { player } = useContext(PlayerProviderContext);
  const { getMentorRequestModel, mentorRequestModel, isDesktop } = props;
  const { data: mentorRequest, loading } = mentorRequestModel;
  const mentorRequestId = player?.data?.mentorRequest?.id;

  useEffect(() => {
    if (mentorRequestId && mentorRequestId !== mentorRequest?.id) {
      getMentorRequestModel(mentorRequestId);
    }
  }, [mentorRequestId, mentorRequest]);

  if (loading || !mentorRequest) {
    return <LoadingSpin className="height-full" />;
  }

  if (mentorRequest?.subscriptionEngine === ESubscriptionsEngines.Stripe) {
    return <PlayerSettingTabStripeTabs isDesktop={isDesktop} />;
  }

  return <PlayerSettingTabYookassa isDesktop={isDesktop} />;
};

export const PlayerSettingTab = communicationMentorRequest.injector(Component);
