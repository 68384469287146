import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  IAcademyCollection,
  IAcademyCollectionFilter,
  IAcademyCreateParams,
  IAcademyModel,
  IAcademyUpdateParams,
  IAcademyTestCollection,
  IAcademyTestCollectionFilter,
  IAcademyTestUpdateParams,
  IAcademyPlayerToAcademyParams,
  IAcademyUpdateTestsBalance,
  IAcademySearchCollectionFilter,
  IAcademySearchCollection
} from 'entities/Academy/Academy.models';

const basePath = '/academies';

class AcademyTransport extends BaseHttpTransport<
  AxiosStatic,
  IAcademyModel,
  IAcademyCollection,
  IAcademyCreateParams,
  IAcademyUpdateParams,
  IAcademyCollectionFilter
> {
  public getAcademySearchCollection = (filter: IAcademySearchCollectionFilter): Promise<IAcademySearchCollection> =>
    axios.get(`${basePath}/search${objectToQuery(filter)}`);
  public getAcademyPlayer = ({ academy, player }: IAcademyPlayerToAcademyParams): Promise<IAcademyTestCollection> =>
    axios.get(`${basePath}/${academy}/players/${player}`);
  public getAcademyBaseResults = ({ id, ...filters }: IAcademyTestCollectionFilter): Promise<IAcademyTestCollection> =>
    axios.get(`${basePath}/${id}/base-results${objectToQuery(filters)}`);
  public updateAcademyBaseResults = ({ id, ageGroup, ...body }: IAcademyTestUpdateParams): Promise<IAcademyTestCollection> =>
    axios.put(`${basePath}/${id}/base-results${objectToQuery({ ageGroup })}`, body);
  public updateAcademyTestsBalance = ({ id, ...body }: IAcademyUpdateTestsBalance): Promise<IAcademyModel> =>
    axios.put(`${basePath}/${id}/balance`, body);
}

export const academyTransport = new AcademyTransport(basePath, axios);
