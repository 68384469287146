import React from 'react';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import {
  EMentorRequestStatus,
  IMentorRequestCollection,
  IMentorRequestCollectionFilter,
  IMentorRequestModel
} from 'entities/MentorRequest/MentorRequest.models';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';
import { MentorRequestListItem, MentorRequestListNoData } from 'entities/MentorRequest/components';

interface IComponentProps {
  onItemClick: (itemModel: IMentorRequestModel) => void;
}

type AllProps = IComponentProps & IMentorRequestConnectedProps;

class MentorRequestListComponent extends InfiniteList<
  IMentorRequestCollection,
  IMentorRequestModel,
  AllProps,
  IMentorRequestCollectionFilter
> {
  loadCollection = async (params: IBaseFilterModel) => {
    const { getMentorRequestCollection } = this.props;
    const filters = {
      ...params,
      regComplete: true
    };
    return getMentorRequestCollection({
      ...filters,
      statuses: [EMentorRequestStatus.New, EMentorRequestStatus.Accepted]
    });
  };

  renderListItem = (item: IMentorRequestModel) => {
    const { onItemClick } = this.props;
    return <MentorRequestListItem item={item} onClick={onItemClick} />;
  };

  renderNoData = () => {
    return <MentorRequestListNoData />;
  };

  getCollection = () => {
    return this.props.mentorRequestCollection;
  };
}

export const MentorRequestList = communicationMentorRequest.injector(MentorRequestListComponent);
