import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Typography from 'antd/es/typography';
import DatePicker from 'antd/es/date-picker';
import { normalizeDate, normalize, normalizeMoney } from 'common/helpers/normalize.helper';
import { translateTariffData } from 'common/helpers/tariff-helper';
import { getUTCEndOfDay, getUTCStartOfDay } from 'common/helpers/date.helper';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationReferralCode, IReferralCodeConnectedProps } from 'entities/ReferralCode/ReferralCode.communication';
import { IReferralCodeSubscriptionActivitiesStatisticModel } from 'entities/ReferralCode/ReferralCode.models';

type AllProps = IAuthConnectedProps & IReferralCodeConnectedProps;

interface IPageSearchParams {
  current?: number;
  pageSize?: number;
  total?: number;
  from?: string;
  to?: string;
}

const ReferralCodeSubscriptionActivitiesComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { authAcademy, getReferralCodeSubscriptionActivitiesStatistic, referralCodeSubscriptionActivitiesStatistic } = props;

  const { search: searchStr } = useLocation();
  const { push, location } = useHistory();

  const { data: authData } = authAcademy;

  const { data, loading } = referralCodeSubscriptionActivitiesStatistic;

  const { data: datasets, meta } = data || {};

  const { totalSums, regCount, count } = meta || {};

  const search = queryString.parse(searchStr) as IPageSearchParams;

  const { current = 1, pageSize = 10, from = undefined, to = undefined } = search;

  const code = useMemo(() => authData?.ownReferralCode?.id, [authData]);

  React.useEffect(() => {
    if (code) {
      getReferralCodeSubscriptionActivitiesStatistic({
        id: code,
        limit: pageSize,
        offset: (current - 1) * pageSize,
        from,
        to
      });
    }
  }, [code, searchStr]);

  const columns = useMemo(
    () => [
      {
        title: t('Player'),
        dataIndex: 'playerFio',
        key: 'playerFio'
      },
      {
        title: t('Registration date'),
        dataIndex: 'playerRegDate',
        key: 'playerRegDate',
        render: normalizeDate
      },
      {
        title: t('Total'),
        dataIndex: 'activityAmountValue',
        key: 'activityAmountValue',
        render: (value: number | null | undefined, row: IReferralCodeSubscriptionActivitiesStatisticModel) =>
          normalizeMoney(value, row.activityAmountCurrency)
      },
      {
        title: t('Activity date'),
        dataIndex: 'activityDate',
        key: 'activityDate',
        render: normalizeDate
      },
      {
        title: t('Tariff name'),
        dataIndex: 'tariffName',
        key: 'tariffName',
        render: (value: string) => {
          const { title } = translateTariffData({ localizationTag: value });
          return title;
        }
      }
    ],
    []
  );

  const setSearch = useCallback(
    (params: IPageSearchParams) => {
      const body = { ...search, ...params };
      push(`${location.pathname}?${queryString.stringify(body)}`);
    },
    [searchStr, code, search]
  );

  const onDateChange = useCallback(
    values => {
      const [dateFrom, dateTo] = values ?? [];
      setSearch({
        from: dateFrom && getUTCStartOfDay(dateFrom),
        to: dateTo && getUTCEndOfDay(dateTo)
      });
    },
    [searchStr, code]
  );

  const onFilterChange = useCallback(
    page => {
      setSearch(page);
    },
    [searchStr, code]
  );
  const renderSums = useCallback(
    () =>
      totalSums?.map((item, index) => (
        <Typography.Title level={4} key={index} className="m-0">
          {normalizeMoney(item.amount, item.currency)}
          {index !== totalSums.length - 1 ? <span className="ml-4 mr-4"> / </span> : ''}
        </Typography.Title>
      )),
    [totalSums]
  );

  return (
    <Spin spinning={loading}>
      <Card className="border-none width-full px-2">
        <Col xs={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <Row gutter={[32, 16]}>
                <Col>
                  <Row className="pb-3">
                    <Typography.Text>{t('count')}</Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Title level={4}>{normalize(regCount)}</Typography.Title>
                  </Row>
                </Col>
                <Col>
                  <Row className="pb-3">
                    <Typography.Text>{t('totalSum')}</Typography.Text>
                  </Row>
                  <Row wrap={false}>{renderSums()}</Row>
                </Col>
              </Row>
            </Col>
            <Col className="pb-3">
              <DatePicker.RangePicker
                allowClear
                onChange={onDateChange}
                placeholder={[t('Date from'), t('Date to')]}
                format="DD.MM.YYYY"
                value={[from && (moment(from) as any), to && (moment(to).utc() as any)]}
                ranges={
                  {
                    [t('Last month')]: [
                      moment()
                        .subtract(1, 'months')
                        .startOf('months'),
                      moment()
                        .subtract(1, 'months')
                        .endOf('months')
                    ],
                    [t('This month')]: [moment().startOf('months'), moment().endOf('months')],
                    [t('This week')]: [moment().startOf('weeks'), moment().endOf('weeks')],
                    [t('Yesterday')]: [
                      moment()
                        .subtract(1, 'days')
                        .startOf('days'),
                      moment()
                        .subtract(1, 'days')
                        .endOf('days')
                    ],
                    [t('Today')]: [moment().startOf('days'), moment().endOf('days')]
                  } as any
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Table
                scroll={{ x: true }}
                rowKey="activityId"
                onChange={onFilterChange}
                dataSource={datasets}
                columns={columns}
                pagination={{ total: Number(count), current: Number(current), pageSize: pageSize }}
              />
            </Col>
          </Row>
        </Col>
      </Card>
    </Spin>
  );
};

export const ReferralCodeSubscriptionActivities = communicationReferralCode.injector(
  communicationAuth.injector(ReferralCodeSubscriptionActivitiesComponent)
);
