import React, { useEffect } from 'react';
import Spin from 'antd/es/spin';
import { CenterLayout } from 'common/components/Layouts/CenterLayout';
import { queryToObject } from 'common/helpers/filters.helper';
import { setHeaders } from 'common/helpers/axios.helper';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EAuthRoles } from 'entities/Auth/Auth.models';

interface IQuery {
  token?: string;
}

type AllProps = IAuthConnectedProps;

const RfsAuthPageComponent: React.FC<AllProps> = props => {
  const { addSocialAuthLogin } = props;
  const { token } = queryToObject<IQuery>({ token: undefined }) as IQuery;

  useEffect(() => {
    if (token) {
      setHeaders(EAuthRoles.Academy);
      addSocialAuthLogin({ type: 'rfs', token, lang: 'ru' });
    }
  }, [token, addSocialAuthLogin]);

  return (
    <CenterLayout>
      <Spin spinning />
    </CenterLayout>
  );
};

export const RfsAuthPagePage = communicationAuth.injector(RfsAuthPageComponent);
