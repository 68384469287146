import React from 'react';
import { useTranslation } from 'react-i18next';
import message from 'antd/es/message';
import { options } from 'options';

interface IProps {
  translateKey: string;
}

const Component: React.FC<IProps> = props => {
  const { translateKey } = props;
  const { t } = useTranslation();

  return (
    <span>
      {t(translateKey)}{' '}
      <a className="cursor-pointer color-link" href={`mailto:${options.supportMail}`}>
        {options.supportMail}
      </a>
    </span>
  );
};

export const supportMessage = (key: string, type: 'success' | 'warn' | 'error' = 'success', duration?: number) => {
  message[type](<Component translateKey={key} />, duration);
};
