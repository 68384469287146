import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { ERoutesPublic } from 'common/models/routesModel';
import { getPageWithQuery } from 'common/helpers/routes.helper';
import { useQuery } from 'common/helpers/use-query.helper';
import { communicationUser, IUserConnectedProps } from 'entities/User/User.communication';
import { communicationAcademy, IAcademyConnectedProps } from 'entities/Academy/Academy.communication';
import { IUserCreateParams } from 'entities/User/User.models';
import { IAcademyCreateParams } from 'entities/Academy/Academy.models';
import { EAuthRoles } from 'entities/Auth/Auth.models';

type AllProps = IUserConnectedProps & IAcademyConnectedProps;

const SignUpSuccessComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const query = useQuery();
  const history = useHistory();
  const currentRole = query.get('role') || EAuthRoles.Mentor;
  const {
    addUserModel,
    userModel,
    addAcademyModel,
    academyModel,
    clearAcademyModel,
    clearUserModel,
    userSignUpWithPlayer
  } = props;

  const isUser = currentRole === EAuthRoles.Mentor;
  const clearModel = isUser ? clearUserModel : clearAcademyModel;
  const params = isUser ? userModel.params || userSignUpWithPlayer.params?.user : academyModel.params;
  let email = t('Unknown email');
  if (params && 'email' in params) {
    email = params.email;
  }

  useEffect(() => {
    if (!params) {
      const path = getPageWithQuery(ERoutesPublic.SignUp, { role: currentRole });
      history.push(path);
    }
    return () => clearModel();
  }, []);

  const sendAgain = () => {
    if (params) {
      if (isUser) {
        const { firstName, lastName, password } = params as IUserCreateParams;
        const body: IUserCreateParams = {
          firstName,
          lastName,
          email,
          password
        };

        addUserModel(body);
      } else {
        const { firstName, lastName, password, name } = params as IAcademyCreateParams;
        const body: IAcademyCreateParams = {
          firstName,
          lastName,
          email,
          password,
          name
        };

        addAcademyModel(body);
      }
    }
  };

  return (
    <div className="sign-up-success-page width-full p-7">
      <Row justify="center" className="mb-2">
        <Typography.Title level={3} className="mb-0">
          {t('Confirm your email')}
        </Typography.Title>
      </Row>
      <Row justify="center" className="mb-2 mt-2">
        <Typography.Title level={5} className="color-text-secondary">
          {email}
        </Typography.Title>
      </Row>
      <Row justify="center" className="mb-7">
        <Typography.Text className="color-text-secondary">{t('Check email for registration message')}</Typography.Text>
      </Row>
      <Row justify="center" className="mb-5">
        <Typography.Text className="color-text-secondary">
          {t('Check spam box if you have not received the letter')}
        </Typography.Text>
      </Row>
      <Row justify="center">
        <Button type="primary" block size="large" onClick={sendAgain}>
          {t('Send email again')}
        </Button>
      </Row>
    </div>
  );
};

export const SignUpSuccessPage = communicationAcademy.injector(communicationUser.injector(SignUpSuccessComponent));
