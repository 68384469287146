import React, { ReactNode } from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

interface IComponentProps {
  privateRoutes: ReactNode;
  publicRoutes: ReactNode;
}
type AllProps = IAuthConnectedProps & IComponentProps;

class PermissionLayoutComponent extends React.Component<AllProps> {
  constructor(props: AllProps) {
    super(props);
    this.props.initAuthModel();
  }

  render() {
    const { authModel, privateRoutes, publicRoutes } = this.props;
    const { data, loading } = authModel;
    const authorized = data && Object.keys(data).length !== 0;

    if (loading) {
      return (
        <Row className="height-half width-full" justify="center" align="middle">
          <Col span={12}>
            <LoadingSpin />
          </Col>
        </Row>
      );
    }

    if (!authorized) {
      return publicRoutes;
    } else {
      return privateRoutes;
    }
  }
}

export const PermissionLayout = communicationAuth.injector(PermissionLayoutComponent);
