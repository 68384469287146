import classNames from 'classnames';
import React from 'react';
import { Tabs, TabsProps } from 'antd';

export const TagTabs: React.FC<TabsProps> = props => {
  const { className, ...rest } = props;

  return <Tabs className={classNames('component-tag-tabs', { [className as string]: Boolean(className) })} {...rest} />;
};

export const TagTabsPane = Tabs.TabPane;
