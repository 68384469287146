import React, { useState, useEffect } from 'react';
import Typography from 'antd/es/typography';
import Tabs from 'antd/es/tabs';
import { useHistory, useParams, Switch, Route } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { LayoutContent } from 'common/components/Layouts/ContentLayout';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { ERoutesMentor } from 'common/models/routesModel';
import { EPaymentAndSubscriptionsTabKeys, EPaymentAndSubscriptionsTabName } from 'entities/Subscription/Subscription.models';
import { SubscriptionsTab } from 'entities/Subscription/components/PaymentAndSubscriptions/SubscriptionsTab';
import { PaymentsTab } from 'entities/Subscription/components/PaymentAndSubscriptions/PaymentsTab';

const PaymentAndSubscriptionComponent: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { tab: urlTab } = useParams();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const [tabValue, setTabValue] = useState<EPaymentAndSubscriptionsTabKeys>(EPaymentAndSubscriptionsTabKeys.Subscriptions);

  useEffect(() => {
    setTabValue(selectTab(urlTab));
  }, [urlTab]);

  const onTabChange = (tab: string) => {
    const selectedTab: EPaymentAndSubscriptionsTabKeys = selectTab(tab);
    setTabValue(selectedTab);
    history.push(`${ERoutesMentor.PaymentAndSubscription}/${selectedTab}`);
  };

  const selectTab = (key: string): EPaymentAndSubscriptionsTabKeys => {
    switch (key) {
      case EPaymentAndSubscriptionsTabKeys.Subscriptions:
        return EPaymentAndSubscriptionsTabKeys.Subscriptions;
      case EPaymentAndSubscriptionsTabKeys.PaymentHistory:
        return EPaymentAndSubscriptionsTabKeys.PaymentHistory;

      default:
        return EPaymentAndSubscriptionsTabKeys.Subscriptions;
    }
  };

  return (
    <LayoutContent rowClassName={`payment-and-subscription-page ${isDesktop ? '' : 'mobile'}`}>
      <Typography.Title level={3} className="mb-5">
        {t('Payment and subscriptions')}
      </Typography.Title>
      <Tabs className="payment-and-subscription-page__tabs mb-5" tabPosition="top" onChange={onTabChange} activeKey={tabValue}>
        <Tabs.TabPane
          tab={t(EPaymentAndSubscriptionsTabName.Subscriptions)}
          key={EPaymentAndSubscriptionsTabKeys.Subscriptions}
        />
        <Tabs.TabPane
          tab={t(EPaymentAndSubscriptionsTabName.PaymentHistory)}
          key={EPaymentAndSubscriptionsTabKeys.PaymentHistory}
        />
      </Tabs>
      <Switch>
        <Route path={`${ERoutesMentor.PaymentAndSubscription}/${EPaymentAndSubscriptionsTabKeys.Subscriptions}`} exact>
          <SubscriptionsTab />
        </Route>
        <Route path={`${ERoutesMentor.PaymentAndSubscription}/${EPaymentAndSubscriptionsTabKeys.PaymentHistory}`} exact>
          <PaymentsTab />
        </Route>
        <Route path={`${ERoutesMentor.PaymentAndSubscription}`}>
          <SubscriptionsTab />
        </Route>
      </Switch>
    </LayoutContent>
  );
};

export const PaymentAndSubscriptionPage = PaymentAndSubscriptionComponent;
