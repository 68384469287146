import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const printWindow = document.getElementById('printWindow');
const rootWindow = document.getElementById('root');

let beforeTitle: string | undefined;

const onPrintFunction = (title?: string) => {
  if (title) {
    beforeTitle = document.title;
    document.title = title;
  }
  if (rootWindow && printWindow) {
    rootWindow.style.display = 'none';
    printWindow.style.display = 'block';
  }
};
const onAfterPrint = () => {
  if (rootWindow && printWindow && beforeTitle) {
    document.title = beforeTitle;
    beforeTitle = undefined;

    rootWindow.style.display = 'flex';
    printWindow.style.display = 'none';
  }
};

export interface IPrintProps {
  isDev?: boolean;
  title?: string;
}

export const Print: React.FC<IPrintProps> = props => {
  const { children, isDev, title } = props;

  useEffect(() => {
    if (isDev) {
      onPrintFunction(title);
    }
  }, []);

  useEffect(() => {
    const onPrint = () => {
      onPrintFunction(title);
    };

    addEventListener('beforeprint', onPrint);
    addEventListener('afterprint', onAfterPrint);

    return () => {
      removeEventListener('beforeprint', onPrint);
      removeEventListener('afterprint', onAfterPrint);
    };
  }, [title]);
  return printWindow && ReactDOM.createPortal(<div className="print-container">{children}</div>, printWindow);
};
