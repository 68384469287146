import React from 'react';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import List from 'antd/es/list';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined';
import { locationFormatter } from 'common/helpers/location.helper';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { IAcademyModel } from 'entities/Academy/Academy.models';

interface IComponentProps {
  item: IAcademyModel;
}

type AllProps = IComponentProps;

const avatarWidth = 56;
const avatarHeight = 56;

export const AcademyListItemComponent: React.FC<AllProps> = props => {
  const { item } = props;
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { address, name = t('Unknown name'), id, image } = item;
  // const { address, name = t('Unknown name'), id, image, passedResultsCount, totalResultsCount } = item;
  // const count = `${passedResultsCount}/${totalResultsCount}`;
  const location = locationFormatter(address) || t('Unknown address');
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const imgId = image?.id;

  return (
    <List.Item key={id}>
      <Link to={`${url}/${id}`}>
        <Card hoverable bordered={false} className="players__academy_card-item">
          <Row className="flex-noWrap" align={isDesktop ? 'middle' : 'top'}>
            <ImagePrivate
              preview={false}
              imgId={imgId}
              height={avatarHeight}
              width={avatarWidth}
              alt="academy img"
              className="border-circle mr-5"
            />
            <Row justify="space-between" align="middle" className="width-full">
              <Col xs={24} lg={17}>
                <div className="players__academy_card-item-body">
                  <Row align="middle">
                    <div
                      className="ant-typography-ellipsis ant-typography-ellipsis-multiple-line fs-xxs font-weight-7"
                      title={name}
                    >
                      {name}
                    </div>
                  </Row>
                  <Typography.Text type="secondary" className="mt-3">
                    <EnvironmentOutlined className="mr-2" />
                    {location}
                  </Typography.Text>
                </div>
              </Col>
              <Col xs={24} lg={5} />
              {/*  <Row className={isDesktop ? 'mt-0' : 'mt-3'} justify={isDesktop ? 'end' : 'start'} align="middle">*/}
              {/*    <Typography.Text>{count}</Typography.Text>*/}
              {/*    <Badge*/}
              {/*      className={`ml-4 players__academy_badge players__academy_badge-${resultColor(passedResultsCount)}`}*/}
              {/*      color=""*/}
              {/*    />*/}
              {/*  </Row>*/}
              {/* </Col>*/}
            </Row>
          </Row>
        </Card>
      </Link>
    </List.Item>
  );
};

export const AcademyListItem = AcademyListItemComponent;
