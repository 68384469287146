import React from 'react';
import Select from 'antd/es/select';
import Typography from 'antd/es/typography';

const { Title } = Typography;
const { Option } = Select;

export const SelectComponent: React.FC = () => (
  <div>
    <Title>Select</Title>
    <div>
      <Select placeholder="Select option" size="large">
        <Option key={1} value={1}>
          Option 1
        </Option>
        <Option key={2} value={2}>
          Option 2
        </Option>
      </Select>
    </div>
  </div>
);
