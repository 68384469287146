import { Col, Row, Switch } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CartesianGrid from 'recharts/es6/cartesian/CartesianGrid';
import AreaChart from 'recharts/es6/chart/AreaChart';
import Area from 'recharts/es6/cartesian/Area';
import XAxis from 'recharts/es6/cartesian/XAxis';
import YAxis from 'recharts/es6/cartesian/YAxis';
import Tooltip from 'recharts/es6/component/Tooltip';
import Spin from 'antd/es/spin';
import { ResponsiveContainer } from 'recharts';
import { ILineChartProps } from 'common/models/lineChartModel';
import { useElementSize } from 'common/helpers/dom.helper';
import { DateTick, ITickItem } from 'entities/Player/components/Profile_old/Draft/LineChart/DateTick';
import { LineChartTooltip } from 'entities/Player/components/Profile_old/Draft/LineChart/LineChartTooltip';
import { LineChartLegend } from 'entities/Player/components/Profile_old/Draft/LineChart/LineChartLegend';
import { INumberTickItem, NumberTick } from 'entities/Player/components/Profile_old/Draft/LineChart/NumberTick';
import { Dot, IDotItem } from 'entities/Player/components/Profile_old/Draft/LineChart/Dot';

export const DEFAULT_PALETTE = {
  yellow: '#ffe83f',
  blue: '#0082FC',
  second: '#000000',
  bg: '#232323',
  label: '#ABB4BF',
  yearLabel: '#ABB4BF',
  labelFontSize: 12,
  lines: '#343434',
  dotBorder: '#FFFFFF',
  mainDotBorder: '#9dca65',
  alternativeDotBorder: 'rgb(128,195,255)'
};

type AllProps = ILineChartProps;

const invert = (value?: number, isInvert?: boolean) => (value === undefined ? undefined : value * (isInvert ? -1 : 1));

const IS_SHOW_PLAYER_RESULTS_KEY = 'IS_SHOW_PLAYER_RESULTS_KEY';

export default (props: AllProps) => {
  const {
    datasets,
    palette,
    legends,
    tooltipLabel,
    height = 250,
    yMin = 0,
    yMax = 100,
    isInvert = false,
    isLoading = false,
    id = '',
    tag,
    yLabel,
    units
  } = props;

  const { t } = useTranslation();

  const [isShowPlayerResults, setShowPlayerResult] = useState<boolean>(
    Boolean(JSON.parse(localStorage.getItem(IS_SHOW_PLAYER_RESULTS_KEY) as string))
  );

  const onPlayerResultVisibleChange = useCallback(() => {
    setShowPlayerResult(!isShowPlayerResults);
    localStorage.setItem(IS_SHOW_PLAYER_RESULTS_KEY, JSON.stringify(!isShowPlayerResults));
  }, [isShowPlayerResults]);

  const isHasDoubleValues = useMemo(
    () =>
      datasets?.some(data => data.uploadedByPlayer && data.value !== null && data.value !== undefined) &&
      datasets?.some(data => !data.uploadedByPlayer && data.value !== null && data.value !== undefined),
    [datasets]
  );

  const showAlternativeValue = useMemo(() => !isHasDoubleValues || isShowPlayerResults, [isHasDoubleValues, isShowPlayerResults]);

  const min = Number(
    moment()
      .subtract(5, 'months')
      .startOf('months')
      .format('x')
  );
  const max = Number(moment().format('x'));

  const colors = React.useMemo(() => ({ ...DEFAULT_PALETTE, ...palette }), [palette]);
  const data = React.useMemo(() => {
    const formatedDatasets = datasets // eslint-disable-line
      .map(dataset => ({
        ...dataset,
        value: invert(dataset.value ?? undefined, isInvert),
        secondValue: invert(dataset.secondValue ?? undefined, isInvert),
        date: Number(moment(dataset.date).format('x')),
        updateDate: Number(moment(dataset.updateDate).format('x'))
      }))
      .sort((a, b) => a.date - b.date)
      .filter(dataset => {
        return Number(moment(dataset?.date).format('x')) >= min;
      })
      .map(item => {
        const { uploadedByPlayer } = item;
        if (showAlternativeValue || !uploadedByPlayer) {
          return item;
        } else {
          return {
            ...item,
            value: undefined
          };
        }
      });
    // Adding last point twice: at create time and update time to show idle result
    if (formatedDatasets?.length) {
      const lastElement = formatedDatasets[formatedDatasets.length - 1];
      formatedDatasets.push({ ...lastElement, date: lastElement.updateDate });
    }
    return formatedDatasets;
  }, [datasets, isInvert, showAlternativeValue]);

  const minDate = useMemo(() => Math.min(...data.map(({ date }) => date)), [data]);

  const { ticks } = React.useMemo(() => {
    const ticksList = new Array(6).fill(undefined).map((emtpy, index, array) => {
      const start = Number(
        moment()
          .subtract(index, 'months')
          .startOf('months')
          .format('x')
      );

      const end = Number(
        moment()
          .subtract(index, 'months')
          .endOf('months')
          .format('x')
      );

      switch (true) {
        case index === 0: {
          return Number(moment().format('x'));
        }
        case index === array.length - 1: {
          return start;
        }
        default: {
          return start + (end - start) / 2;
        }
      }
    });

    return {
      max,
      min,
      ticks: ticksList.filter(tick => tick >= minDate)
    };
  }, [data]);

  const paletteId = React.useMemo(() => `lineChartId${colors.yellow}${id}`, []);
  const ref = useRef<HTMLDivElement>(null);
  const { width: elementWidth = 1 } = useElementSize(ref) ?? {};

  return (
    <div ref={ref}>
      <Spin spinning={isLoading}>
        {isHasDoubleValues && (
          <Row justify="end" align="middle" gutter={8}>
            <Col>{t('Self passage')}</Col>
            <Col>
              <Switch onClick={onPlayerResultVisibleChange} checked={isShowPlayerResults} />
            </Col>
          </Row>
        )}
        <ResponsiveContainer width="100%" height={height}>
          <AreaChart
            height={height}
            data={data}
            baseValue="dataMin"
            margin={{
              top: 10,
              right: 30,
              left: -12,
              bottom: 0
            }}
          >
            <CartesianGrid vertical={false} stroke={colors.lines} />
            {data?.length && (
              <Tooltip content={<LineChartTooltip tooltipLabel={tooltipLabel} isInvert={isInvert} tag={tag} units={units} />} />
            )}
            <XAxis
              stroke={colors.label}
              height={45}
              width={30}
              tickMargin={16}
              minTickGap={4}
              interval={0}
              tickLine={true}
              axisLine={true}
              dataKey="date"
              ticks={480 < elementWidth ? ticks : ticks.filter((tick, index) => index % 2 === 0)}
              tickCount={ticks.length}
              type="number"
              domain={[min > minDate ? min : minDate, max || min]}
              tick={(item: ITickItem) => <DateTick palette={colors} item={item} beforeDate={ticks[item.index - 1]} />}
            />

            <YAxis
              label={{ value: yLabel, angle: -90, position: 'insideLeft', offset: 16 }}
              interval={0}
              allowDecimals={false}
              tickLine={false}
              axisLine={false}
              type="number"
              domain={[Math.floor(Number(invert(yMin, isInvert))), Math.ceil(Number(invert(yMax, isInvert)))]}
              tickCount={11}
              tick={(item: INumberTickItem) => <NumberTick units={units} isInvert={isInvert} item={item} />}
              stroke={colors.label}
              fontSize={colors.labelFontSize}
            />
            <defs>
              <linearGradient id={paletteId} x1="49%" y1="100%" x2="51%" y2="0%">
                <stop offset="0%" stopColor={colors.yellow} stopOpacity={0.16} />
                {/* <stop offset="60%" stopColor={colors.main} stopOpacity={0.6} />*/}
                <stop offset="100%" stopColor={colors.yellow} stopOpacity={0.42} />
              </linearGradient>
              <linearGradient id={`${paletteId}-second`} x1="49%" y1="100%" x2="51%" y2="0%">
                <stop offset="0%" stopColor={colors.second} stopOpacity={0.25} />
                {/* <stop offset="36%" stopColor={colors.second} stopOpacity={0.43} />*/}
                <stop offset="100%" stopColor={colors.second} stopOpacity={0.16} />
              </linearGradient>
            </defs>
            <Area
              connectNulls
              isAnimationActive={false}
              type="monotone"
              fill={`url(#${paletteId})`}
              activeDot={(item: IDotItem) => (
                <Dot
                  item={{
                    ...item,
                    fill: isHasDoubleValues ? (item.payload.uploadedByPlayer ? colors.blue : colors.yellow) : colors.yellow
                  }}
                  palette={colors}
                />
              )}
              dot={(item: IDotItem) => (
                <Dot
                  palette={colors}
                  item={{
                    ...item,
                    fill: isHasDoubleValues ? (item.payload.uploadedByPlayer ? colors.blue : colors.yellow) : colors.yellow
                  }}
                />
              )}
              dataKey="value"
              stroke={colors?.yellow}
              strokeWidth={4}
            />
            <Area
              connectNulls
              isAnimationActive={false}
              type="monotone"
              fill={`url(#${paletteId}-second)`}
              dataKey="secondValue"
              activeDot={(item: IDotItem) =>
                datasets?.length > 1 ? undefined : <Dot item={{ ...item, fill: colors?.second }} isSecond palette={colors} />
              }
              dot={(item: IDotItem) =>
                datasets?.length > 1 ? undefined : <Dot item={{ ...item, fill: colors?.second }} palette={colors} isSecond />
              }
              stroke={colors?.second}
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
        {legends && <LineChartLegend palette={colors} legends={legends} />}
      </Spin>
    </div>
  );
};
