import React, { useCallback, useMemo } from 'react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Typography from 'antd/es/typography';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import { normalize, normalizeDate } from 'common/helpers/normalize.helper';
import { getSubscriptionStatus } from 'common/helpers/location.helper';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationReferralCode, IReferralCodeConnectedProps } from 'entities/ReferralCode/ReferralCode.communication';
import {
  IReferralCodeSubscriptionsMeta,
  IReferralCodeSubscriptionsStatisticModel
} from 'entities/ReferralCode/ReferralCode.models';
import { ESubscriptionStatus } from 'entities/Subscription/Subscription.models';

type AllProps = IAuthConnectedProps & IReferralCodeConnectedProps;

interface IPageSearchParams {
  current?: number;
  pageSize?: number;
  total?: number;
}

const ReferralCodeSubscriptionsComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { authAcademy, getReferralCodeSubscriptionsStatistic, referralCodeSubscriptionsStatistic } = props;

  const normalizeFio = useCallback(
    (name: string) => (
      <Typography.Text style={{ maxWidth: 150 }} title={name} ellipsis>
        {name ?? '-'}
      </Typography.Text>
    ),
    []
  );

  const { search } = useLocation();
  const { push, location } = useHistory();

  const { data: authData } = authAcademy;
  const { data, loading } = referralCodeSubscriptionsStatistic;

  const { data: datasets, meta = {} } = data || {};
  const { activeCount, regCount, count } = meta as IReferralCodeSubscriptionsMeta;
  const { current = 1, pageSize = 10 } = queryString.parse(search) as IPageSearchParams;

  const code = useMemo(() => authData?.ownReferralCode?.id, [authData]);

  React.useEffect(() => {
    if (code) {
      getReferralCodeSubscriptionsStatistic({
        id: code,
        limit: pageSize,
        offset: (current - 1) * pageSize
      });
    }
  }, [code, search]);

  const columns = useMemo(
    () => [
      {
        title: t('Player'),
        dataIndex: 'playerFio',
        key: 'playerFio',
        render: normalizeFio
      },
      {
        title: t('Registration date'),
        dataIndex: 'playerRegDate',
        key: 'playerRegDate',
        render: normalizeDate
      },
      {
        title: t('Mentor'),
        dataIndex: 'mentorFio',
        key: 'mentorFio',
        render: normalizeFio
      },
      {
        title: t('Subscription status'),
        dataIndex: 'subscriptionStatus',
        key: 'subscriptionStatus',
        render: (status: ESubscriptionStatus, row: IReferralCodeSubscriptionsStatisticModel) =>
          getSubscriptionStatus(status, row?.subscriptionExpiredAt)
      },
      {
        title: t('Subscription expired at'),
        dataIndex: 'subscriptionExpiredAt',
        key: 'subscriptionExpiredAt',
        render: (val: any) => (moment(val).year() !== 9999 ? normalizeDate(val) : '-')
      }
    ],
    []
  );

  const onFilterChange = useCallback(
    page => {
      push(`${location.pathname}?${queryString.stringify(page)}`);
    },
    [code]
  );

  return (
    <Spin spinning={loading}>
      <Card className="border-none width-full px-2">
        <Col xs={24}>
          <Row gutter={[32, 16]}>
            <Col>
              <Row className="pb-3">
                <Typography.Text>{t('count')}</Typography.Text>
              </Row>
              <Row>
                <Typography.Title level={4}>{normalize(regCount)}</Typography.Title>
              </Row>
            </Col>
            <Col>
              <Row className="pb-3">
                <Typography.Text>{t('Subscriptions')}</Typography.Text>
              </Row>
              <Row>
                <Typography.Title level={4}>{normalize(activeCount)}</Typography.Title>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Table
                scroll={{ x: true }}
                rowKey="playerId"
                onChange={onFilterChange}
                dataSource={datasets}
                columns={columns}
                pagination={{ total: count, current: Number(current), pageSize: pageSize }}
              />
            </Col>
          </Row>
        </Col>
      </Card>
    </Spin>
  );
};

export const ReferralCodeSubscriptions = communicationReferralCode.injector(
  communicationAuth.injector(ReferralCodeSubscriptionsComponent)
);
