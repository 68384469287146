import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import Row from 'antd/es/row';
import Checkbox from 'antd/es/checkbox';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Space from 'antd/es/space';
import { useFormMapper } from '@axmit/antd4-helpers';
import { useMediaPredicate } from 'react-media-hook';
import { Skeleton } from 'antd';
import LinkOutlined from '@ant-design/icons/LinkOutlined';
import Grid from 'antd/es/grid';
import message from 'antd/es/message';
import { DownloadOutlined } from '@ant-design/icons';
import { downloadFile } from 'common/helpers/loader.helper';
import { LayoutContent } from 'common/components/Layouts/ContentLayout';
import { AvatarEditor } from 'common/components/AvatarEditor/AvatarEditor';
import { Phone } from 'common/components/Phone/Phone';
import { requiredField, phoneRule, passwordRules } from 'common/helpers/filed-rules';
import { HOST, SCREEN_MD } from 'common/const/config.const';
import { LocationInput } from 'common/components/Input/LocationInput';
import { ELocationType } from 'common/helpers/location.helper';
import { copyToBuffer } from 'common/helpers/buffer.helper';
import { objectToQuery } from 'common/helpers/filters.helper';
import { getBaseUrl } from 'common/helpers/axios.helper';
import { MeasurementSystemSelector } from 'common/components/Selector/MeasurementSystemSelector';
import { EMeasurementSystem } from 'common/helpers/units-converter.helper';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationUser, IUserConnectedProps } from 'entities/User/User.communication';
import { EUserRepresentationType, EUserGender, IUserUpdatePasswordParams, IUserUpdateModelForm } from 'entities/User/User.models';
import { communicationAcademy, IAcademyConnectedProps } from 'entities/Academy/Academy.communication';
import { EAcademyStatus, IAcademyUpdateParams } from 'entities/Academy/Academy.models';
import { LanguageSelect } from 'entities/Language/components/LanguageSelect';
import { playerTransport } from 'entities/Player/Player.transport';

type AllProps = IAuthConnectedProps & IUserConnectedProps & IAcademyConnectedProps;

interface IFormUserUpdateInfoValues {
  firstName: string;
  lastName: string;
  gender: EUserGender;
  birthday: string;
  representationType: EUserRepresentationType;
  contactPhone: string;
  description: string;
  contactPosition?: string;
  reportEmails?: string[];
  site?: string;
  hideTariffs?: boolean;
  hidePlayersByDefault?: boolean;
}

interface IFormUserUpdateValues {
  oldPassword: string;
  password: string;
  lang: string;
  firstName: string;
  lastName: string;
  measurementSystem: EMeasurementSystem;
}

const AcademyEditPageComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${SCREEN_MD})`);
  const [avatarId, setAvatarId] = useState<string | null>(null);
  const [workerAvatarId, setWorkerAvatarId] = useState<string | null>(null);
  const [mentorInviteLink, setMentorInviteLink] = useState<string | undefined>(undefined);
  const {
    authUser,
    authAcademy,
    updateAcademyModel,
    academyModel,
    userModel,
    getAcademyModel,
    getUserModel,
    updatePasswordUserPasswordModel,
    updateUserModel,
    authModel,
    userPasswordModel
  } = props;
  const { data: authUserData, loading } = authUser;
  const { loading: authAcademyLoading } = authAcademy;
  const { data: userData, loading: userLoading, params, errors } = userModel ?? {};
  const { data: academyData, loading: academyLoading, params: academyParams, errors: academyErrors } = academyModel ?? {};
  const { loading: passwordLoading, params: passwordParams, errors: passwordErrors } = userPasswordModel ?? {};
  const commonLoading = userLoading || academyLoading || loading;
  const screens = Grid.useBreakpoint();
  const isAcademyActive = useMemo(() => academyData?.status === EAcademyStatus.Active, [academyData]);
  const authToken = useMemo(() => authModel?.data?.access?.token, [authModel]);
  const host = getBaseUrl();
  const userForm = useFormMapper(['firstName', 'lastName', 'email', 'lang', 'measurementSystem'], userData, params, errors);

  const passwordForm = useFormMapper(['oldPassword', 'password'], null, passwordParams, passwordErrors);

  const academyDataForm = useMemo(
    () => ({
      ...academyData,
      address: academyLoading ? null : academyData?.address
    }),
    [academyData, academyLoading]
  );

  const { fields: academyFields } = useFormMapper(
    [
      'name',
      'address',
      'description',
      'contactName',
      'contactPhone',
      'contactPosition',
      'reportEmails',
      'site',
      'hideTariffs',
      'measurementSystem',
      'hidePlayersByDefault'
    ],
    academyDataForm,
    academyParams,
    academyErrors
  );

  useEffect(() => {
    const authAcademyId = authUserData?.academyWorker?.academy.id;

    if (authAcademyId && (!authAcademyLoading || (academyData && authAcademyId !== academyData.id))) {
      getAcademyModel(authAcademyId);
    }
  }, [authUserData, authAcademyLoading]);

  useEffect(() => {
    if (authUserData?.id && authUserData.id !== userData?.id) {
      getUserModel(authUserData.id);
    }
  }, []);
  useEffect(() => {
    if (isAcademyActive) {
      createMentorsInviteLink();
    }
  }, [isAcademyActive]);

  useEffect(() => {
    if (academyData?.image) {
      setAvatarId(academyData?.image?.id);
    }
  }, [academyData]);

  useEffect(() => {
    if (userData?.image) {
      setWorkerAvatarId(userData?.image?.id);
    }
  }, [userData]);

  const createMentorsInviteLink = useCallback(async () => {
    try {
      const { token } = await playerTransport.createInviteMentorsToken();
      const link = `${HOST}/invite-mentors?token=${token}`;
      setMentorInviteLink(link);
    } catch (e) {
      message.error('Fail');
    }
  }, []);

  const onCopy = (link?: string) => {
    if (link) {
      copyToBuffer(link).then(() => {
        message.success(t('Link has been copied'));
      });
    }
  };

  const onFinishAcademyUpdate = (values: IFormUserUpdateInfoValues) => {
    if (academyData?.id) {
      const { id, image } = academyData;
      const {
        contactPosition,
        reportEmails,
        site,
        contactPhone,
        description,
        hideTariffs,
        hidePlayersByDefault,
        ...rest
      } = values;
      const imgValue = avatarId === null ? null : avatarId === image?.id ? image?.id : avatarId;

      const body: IAcademyUpdateParams = {
        id,
        image: imgValue,
        contactPhone: contactPhone || null,
        description: description || null,
        ...rest
      };

      if (contactPosition !== undefined) {
        body.contactPosition = contactPosition === '' ? null : contactPosition;
      }
      if (reportEmails !== undefined) {
        body.reportEmails = reportEmails === [] ? null : reportEmails;
      }

      if (site !== undefined) {
        body.site = site === '' ? null : site;
      }

      if (hideTariffs !== undefined) {
        body.hideTariffs = hideTariffs;
      }

      if (hidePlayersByDefault !== undefined) {
        body.hidePlayersByDefault = hidePlayersByDefault;
      }

      updateAcademyModel(body);
    }
  };

  const onFinishUserUpdate = (values: IFormUserUpdateValues) => {
    if (authUserData?.id) {
      const { lang, firstName, lastName, measurementSystem } = values;
      const imgValue = workerAvatarId === null ? null : avatarId === userData?.image?.id ? userData?.image?.id : workerAvatarId;
      const body: IUserUpdateModelForm = {
        lang,
        id: authUserData.id,
        firstName,
        lastName,
        image: imgValue,
        measurementSystem
      };

      updateUserModel(body);
    }
  };

  const onFinishUserPasswordUpdate = (values: IFormUserUpdateValues) => {
    if (authUserData?.id) {
      const { oldPassword, password } = values;

      if (oldPassword && password) {
        const updatePasswordBody: IUserUpdatePasswordParams = {
          id: authUserData.id,
          oldPassword,
          password
        };

        updatePasswordUserPasswordModel(updatePasswordBody);
      }
    }
  };

  return (
    <LayoutContent rowClassName="academy-edit-page">
      <Typography.Title level={3} className="academy-edit-page__title">
        {t('Academy settings')}
      </Typography.Title>
      <Space size={16} direction="vertical" className="width-full">
        <Card className="border-none width-full px-3">
          <Form onFinish={onFinishAcademyUpdate} fields={academyFields} name="update-academy-info" layout="vertical">
            <Row wrap={false} gutter={16}>
              <Col className="t-align-c academy-edit-page__avatar">
                <AvatarEditor onChange={setAvatarId} avatarId={avatarId} />
              </Col>
              <Col className="width-full academy-edit-page__base-content">
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <Form.Item rules={[requiredField]} name="name" label={t('Academy name')} className="mb-3">
                      <Input disabled={commonLoading} size="large" placeholder={t('Academy name')} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item rules={[requiredField]} name="address" label={t('City')}>
                      {!commonLoading ? (
                        <LocationInput types={[ELocationType.Cities]} placeholder={t('Address')} />
                      ) : (
                        <Skeleton.Input size="large" active={true} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={16} className="academy-edit-page__content mb-5">
              <Col xs={24} sm={12}>
                <Form.Item name="site" label={t('Academy site (optional)')} className="mb-3">
                  <Input disabled={commonLoading} size="large" placeholder="https://www.academy-site.com" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                {isAcademyActive && (
                  <Form.Item name="site" label={t('Mentor invitation')} className="mb-3">
                    <Col>
                      <Row align="middle" wrap={false} gutter={8}>
                        <Col xs={20} sm={16}>
                          <Input disabled className="color-text-secondary" value={mentorInviteLink} size="large" />
                        </Col>
                        <Col xs={4} sm={8}>
                          <Button
                            title={mentorInviteLink}
                            icon={<LinkOutlined />}
                            onClick={() => onCopy(mentorInviteLink)}
                            size="large"
                            className="ellipsis"
                            block
                          >
                            {!screens.xs && t('Copy link')}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Form.Item>
                )}
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item className="mb-0">
                  <Row wrap={false} align="middle" className="academy-edit-page__hide-tariffs-row">
                    <Form.Item valuePropName="checked" name="hideTariffs" className="mb-0 mr-3" noStyle>
                      <Checkbox />
                    </Form.Item>
                    <Typography.Text className="color-bw-white d-flex ml-3">
                      {t('Hide tariffs for representatives')}
                    </Typography.Text>
                  </Row>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item className="mb-0">
                  <Row wrap={false} align="middle" className="academy-edit-page__hide-tariffs-row">
                    <Form.Item valuePropName="checked" name="hidePlayersByDefault" className="mb-0 mr-3" noStyle>
                      <Checkbox />
                    </Form.Item>
                    <Typography.Text className="color-bw-white d-flex ml-3">{t('Hide players by default')}</Typography.Text>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            {/* <Row className="mb-5 academy-edit-page__content">*/}
            {/*  <Typography.Text>*/}
            {/*    {t('Set academy age groups')}{' '}*/}
            {/*    <Link className="classic-link" to={ERoutesAcademy.AcademyEditTests}>*/}
            {/*      {t('Do not forget to set requirements')}*/}
            {/*    </Link>*/}
            {/*  </Typography.Text>*/}
            {/* </Row>*/}
            <Col className="academy-edit-page__content">
              {/* <Row> */}
              {/* <Form.Item rules={[requiredField]} name="ageGroups" className="mb-3"> */}
              {/* <AcademyAgesSelect disabled={commonLoading} />*/}
              {/* </Form.Item>*/}
              {/* </Row>*/}
              <Form.Item name="description" label={`${t('Description')} ${t('Optional')}`} className="mb-7 width-full">
                <Input.TextArea
                  showCount
                  maxLength={1024}
                  disabled={commonLoading}
                  autoSize={{ minRows: 4 }}
                  placeholder={t('Academy information')}
                />
              </Form.Item>
              <Row justify="space-between">
                <Button
                  block={!isDesktop}
                  htmlType="submit"
                  type="primary"
                  size="large"
                  loading={commonLoading}
                  disabled={commonLoading}
                >
                  {t('Save')}
                </Button>
                <Button
                  className={`${!isDesktop ? 'mt-4 width-full' : ''}`}
                  type="default"
                  size="large"
                  title={t('Download players')}
                  onClick={() => downloadFile(`${host}/trainers/players/csv${objectToQuery({ token: authToken })}`)}
                >
                  <DownloadOutlined /> {t('Download players')}
                </Button>
              </Row>
            </Col>
          </Form>
        </Card>

        <Card className="border-none width-full px-3">
          <Form onFinish={onFinishAcademyUpdate} fields={academyFields} name="update-academy-info" layout="vertical">
            <Col className="academy-edit-page__content">
              <Row className="mb-5">
                <Typography.Title level={5} className="mb-0">
                  {t('academy-private-info')}
                </Typography.Title>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12} className="mb-3">
                  <Form.Item rules={[requiredField]} name="contactName" label={t('The contact person')} className="mb-3">
                    <Input disabled={commonLoading} size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    className="mb-3"
                    rules={[phoneRule]}
                    name="contactPhone"
                    label={`${t('The contact phone')} ${t('Optional')}`}
                  >
                    <Phone disabled={commonLoading} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12} className="mb-3">
                  <Form.Item name="contactPosition" label={t('Contact person position (optional)')} className="mb-3">
                    <Input disabled={commonLoading} size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} className="mb-3">
                  <Form.Item name="reportEmails" label={`${t('Emails for reporting')} ${t('Optional')}`} className="mb-3">
                    <Select tokenSeparators={[' ', ',', ', ']} mode="tags" size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Button
                block={!isDesktop}
                htmlType="submit"
                type="primary"
                size="large"
                loading={commonLoading}
                disabled={commonLoading}
              >
                {t('Save')}
              </Button>
            </Col>
          </Form>
        </Card>

        <Card className="border-none width-full px-3">
          <Form
            onFinish={onFinishUserUpdate}
            fields={userForm.fields}
            name="update-academy-info"
            layout="vertical"
            className="mb-5"
          >
            <Row wrap={false} gutter={16}>
              <Col className="t-align-c academy-edit-page__avatar">
                <AvatarEditor onChange={setWorkerAvatarId} avatarId={workerAvatarId} />
              </Col>
              <Col className="academy-edit-page__base-content" flex={1}>
                <Row gutter={16}>
                  <Col xs={24} sm={12} className="mb-3">
                    <Form.Item rules={[requiredField]} name="firstName" label={t('First name')} className="mb-3">
                      <Input disabled={userLoading} size="large" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="mb-3">
                    <Form.Item rules={[requiredField]} name="lastName" label={t('Last name')} className="mb-3">
                      <Input disabled={userLoading} size="large" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Col className="academy-edit-page__content">
              <Row gutter={16} className="mb-3">
                <Col xs={24} sm={12}>
                  <Form.Item rules={[requiredField]} name="email" label={t('Email')}>
                    <Input disabled size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item rules={[requiredField]} name="lang" label={t('Language')}>
                    <LanguageSelect disabled={userLoading} size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item name="measurementSystem" label={t('Measurement system')}>
                    <MeasurementSystemSelector className="width-full" />
                  </Form.Item>
                </Col>
              </Row>
              <Button block={!isDesktop} htmlType="submit" type="primary" size="large" loading={userLoading}>
                {t('Save')}
              </Button>
            </Col>
          </Form>
          <Form onFinish={onFinishUserPasswordUpdate} fields={passwordForm.fields} name="update-user-password" layout="vertical">
            <Col className="academy-edit-page__content">
              <Row gutter={16} className="mb-3">
                <Col xs={24} sm={12}>
                  <Form.Item rules={[passwordRules]} name="oldPassword" label={t('Password')}>
                    <Input.Password type="password" size="large" disabled={passwordLoading} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item rules={[passwordRules]} name="password" label={t('New complex password')}>
                    <Input.Password type="password" size="large" disabled={passwordLoading} />
                  </Form.Item>
                </Col>
              </Row>
              <Button block={!isDesktop} htmlType="submit" type="primary" size="large" loading={passwordLoading}>
                {t('Save')}
              </Button>
            </Col>
          </Form>
        </Card>
      </Space>
    </LayoutContent>
  );
};

export const AcademyEditPage = communicationAcademy.injector(
  communicationUser.injector(communicationAuth.injector(AcademyEditPageComponent))
);
