import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';

interface IComponentProps {
  label: string;
  value: number;
  color?: string;
}

type AllProps = IComponentProps;

const DEFAULT_COLOR = '#0082FC';

const getShadow = (color: string) => `
            -0   -1px 0   ${color},
            0   -1px 0   ${color},
            -0    1px 0   ${color},
            0    1px 0   ${color},
            -1px -0   0   ${color},
            1px -0   0   ${color},
            -1px  0   0   ${color},
            1px  0   0   ${color},
            -1px -1px 0   ${color},
            1px -1px 0   ${color},
            -1px  1px 0   ${color},
            1px  1px 0   ${color},
            -1px -1px 0   ${color},
            1px -1px 0   ${color},
            -1px  1px 0   ${color},
            1px  1px 0   ${color}`;

export const BarChart = ({ label, value, color = DEFAULT_COLOR }: AllProps) => {
  return (
    <Row justify="space-between" className="width-full" gutter={8}>
      <Col title={label} xs={12} className="t-align-s height-min-content ellipsis">
        <span className="bar-chart-component__label">{label}</span>
      </Col>
      <Col xs={12}>
        <div className="bar-chart-component width-full mt-0">
          <Row style={{ borderColor: color }} className="bar-chart-component__content">
            {value !== 0 && (
              <Col flex={value} className="bar-chart-component__value" style={{ background: color }}>
                <span
                  style={{ textShadow: getShadow(color), background: color }}
                  className="bar-chart-component__text pr-2 t-align-e"
                >
                  {value}
                </span>
              </Col>
            )}
            {value < 100 && (
              <Col flex={100 - value} className="bar-chart-component__bg" style={{ background: undefined }}>
                {value === 0 && (
                  <span style={{ textShadow: getShadow(color) }} className="bar-chart-component__text pl-3 t-align-s">
                    {value}
                  </span>
                )}
              </Col>
            )}
          </Row>
        </div>
      </Col>
    </Row>
  );
};
