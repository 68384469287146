import React, { SyntheticEvent, useCallback, useMemo } from 'react';
import Button from 'antd/es/button';
import notification from 'antd/es/notification';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import BlockOutlined from '@ant-design/icons/BlockOutlined';
import { useMediaPredicate } from 'react-media-hook';
import classNames from 'classnames';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { playersCompareRoute } from 'common/models/routesModel';
import { IPlayerConnectedProps, communicationPlayer } from 'entities/Player/Player.communication';

interface IProps {
  playerId: string;
  className?: string;
  size?: 'small' | 'middle' | 'large';
  adaptive?: boolean;
}

type AllProps = IProps & IPlayerConnectedProps;

const Component: React.FC<AllProps> = props => {
  const { playerId, playerCompare, addPlayerCompare, deletePlayerCompare, size, className, adaptive = true } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const isDesktopMedia: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);

  const isInCompare = useMemo(() => playerCompare?.data?.includes(playerId), [playerCompare, playerId]);

  const onClickCallback = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (isInCompare) {
        deletePlayerCompare(playerId);
      } else {
        addPlayerCompare(playerId);

        notification.success({
          message: t('Player added to compare!'),
          className: 'cursor-pointer',
          description: t('Click on the notification to see compare list'),
          onClick: () => {
            history.push(playersCompareRoute());
          },
          duration: 3
        });
      }
    },
    [isInCompare, playerId, addPlayerCompare, deletePlayerCompare]
  );

  return (
    <Button
      type="default"
      size={size}
      onClickCapture={onClickCallback}
      danger={isInCompare}
      className={classNames({ [className as string]: Boolean(className) })}
    >
      <BlockOutlined />
      {isDesktopMedia || !adaptive ? t('Compare') : null}
    </Button>
  );
};

export const PlayerCompareAction = communicationPlayer.injector(Component);
