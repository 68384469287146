import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { ResourceLanguage } from 'i18next';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  ILanguageModel,
  ILanguageCollection,
  ICreateLanguageParams,
  IUpdateLanguageParams,
  ILanguageCollectionFilter
} from 'entities/Language/Language.models';

const basePath = '/languages';

class LanguageTransport extends BaseHttpTransport<
  AxiosStatic,
  ILanguageModel,
  ILanguageCollection,
  ICreateLanguageParams,
  IUpdateLanguageParams,
  ILanguageCollectionFilter
> {
  public getLanguages = (params?: ICreateLanguageParams): Promise<ILanguageCollection> =>
    axios.get(`${basePath}${objectToQuery(params)}`);

  public getTranslations = (language: string): Promise<ResourceLanguage> => axios.get(`${basePath}/${language}/json/js`);
}

export const languageTransport = new LanguageTransport(basePath, axios);
