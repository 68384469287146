import React from 'react';
import Typography from 'antd/es/typography';
import Popconfirm from 'antd/es/popconfirm';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import Row from 'antd/es/row';
import { useMediaPredicate } from 'react-media-hook';
import { useTranslation } from 'react-i18next';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { ETrainerAcademyRequestStatus, ITrainerAcademyRequestModel } from 'entities/Trainer/Trainer.models';
import { communicationTrainer, ITrainerConnectedProps } from 'entities/Trainer/Trainer.communication';

interface IProps {
  trainerAcademyRequest?: Partial<ITrainerAcademyRequestModel>;
}

type AllProps = IProps & ITrainerConnectedProps;

const AbortTrainerRequestComponent: React.FC<AllProps> = props => {
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const { updateTrainerAcademyRequestModel, trainerAcademyRequest } = props;
  const { t } = useTranslation();
  const trainerAcademyRequestStatus = trainerAcademyRequest?.status;
  const trainerAcademyRequestId = trainerAcademyRequest?.id;

  const onAbortRequest = (e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    if (trainerAcademyRequestId) {
      updateTrainerAcademyRequestModel({
        id: trainerAcademyRequestId,
        status: ETrainerAcademyRequestStatus.Canceled
      });
    }
  };

  return (
    <Row className={isDesktop ? 'mt-0' : 'mt-3'} wrap={false} justify={isDesktop ? 'end' : 'start'} align="middle">
      <Typography.Text type="secondary" className="t-align-e mr-3">
        {t(`status-trainer-card.${trainerAcademyRequestStatus}`)}
      </Typography.Text>
      <Popconfirm
        placement="top"
        okButtonProps={{
          type: 'text',
          size: 'middle',
          danger: true
        }}
        cancelButtonProps={{
          size: 'middle'
        }}
        okText={t('Yes')}
        cancelText={t('Leave')}
        title={t('Cancel the add request?')}
        onConfirm={onAbortRequest}
        onCancel={e => e?.stopPropagation()}
      >
        <InfoCircleOutlined />
      </Popconfirm>
    </Row>
  );
};

export const AbortTrainerRequest = communicationTrainer.injector(AbortTrainerRequestComponent);
