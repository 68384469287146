import React from 'react';
import i18n from 'i18next';
import Typography from 'antd/es/typography';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import {
  EMentorRequestStatus,
  IMentorRequestCollection,
  IMentorRequestCollectionFilter,
  IMentorRequestModel
} from 'entities/MentorRequest/MentorRequest.models';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';
import { SubscriptionsTabListElement } from 'entities/Subscription/components/PaymentAndSubscriptions/SubscriptionsTabListElement';

interface IProps {
  isDesktop: boolean;
}

type AllProps = IMentorRequestConnectedProps & IProps;

class SubscriptionsTabListComponent extends InfiniteList<
  IMentorRequestCollection,
  IMentorRequestModel,
  AllProps,
  IMentorRequestCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getMentorRequestCollection } = this.props;
    return getMentorRequestCollection({
      ...params,
      statuses: [EMentorRequestStatus.New, EMentorRequestStatus.Accepted]
    });
  };

  renderListItem = (item: IMentorRequestModel) => {
    const { isDesktop } = this.props;

    return <SubscriptionsTabListElement item={item} isDesktop={isDesktop} />;
  };

  renderNoData = () => {
    return <Typography.Title level={5}>{i18n.t('No players')}</Typography.Title>;
  };

  getCollection = () => {
    return this.props.mentorRequestCollection;
  };
}

export const SubscriptionsTabList = communicationMentorRequest.injector(SubscriptionsTabListComponent);
