import React from 'react';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import { EPermissionType } from 'common/helpers/permission.helper';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { Paper } from 'common/components/Paper/Paper';
import { PrivateContent } from 'entities/Auth/components/PrivateContent';

const IMAGE_SIZE = 56;

interface IProps {
  recommendation?: string | null;
  loading?: boolean;
  className?: string;
  coachImg?: string | null;
  coachName?: string;
}

type AllProps = IProps;

const PlayerRecommendationBlockComponent: React.FC<AllProps> = props => {
  const { recommendation, loading, className, coachName, coachImg } = props;
  const { t } = useTranslation();

  return recommendation ? (
    <PrivateContent availableFor={EPermissionType.ShowDataForMentor}>
      <Paper className={`${className} width-full`}>
        <Spin spinning={loading}>
          <Typography.Title level={4} className="mb-3">
            {t('Recommendations')}
          </Typography.Title>
          <Typography.Text className="word-break fs-xxs">{recommendation || t('No data')}</Typography.Text>
          <Row wrap={false} gutter={16} className="mt-5">
            <Col>
              <ImagePrivate
                alt={t('Coach')}
                width={IMAGE_SIZE}
                height={IMAGE_SIZE}
                className="border-circle"
                imgId={coachImg || undefined}
              />
            </Col>
            <Col>
              <Row>
                <Typography.Title level={5} className="font-weight-5 mb-0">
                  {coachName}
                </Typography.Title>
              </Row>
              <Row>
                <span className="color-text-secondary t-pre-wrap word-break fs-xxs font-weight-4">{t('Coach')}</span>
              </Row>
            </Col>
          </Row>
        </Spin>
      </Paper>
    </PrivateContent>
  ) : null;
};

export const PlayerRecommendationBlock = PlayerRecommendationBlockComponent;
