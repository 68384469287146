import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  buildCollectionResponseFormatter,
  buildCollectionPreRequestDataMapper
} from '@axmit/redux-communications';
import { takeLatest } from 'redux-saga/effects';
import { publicPlayerTransport } from 'entities/PublicPlayer/PublicPlayer.transport';
import {
  IPublicPlayerCollection,
  IPublicPlayerCollectionFilter,
  IPublicPlayerModel,
  IPublicPlayerParamsModel
} from 'entities/PublicPlayer/PublicPlayer.models';

export const namespace = 'publicPlayer';

export interface IPublicPlayerConnectedProps {
  publicPlayerModel: StoreBranch<IPublicPlayerModel, IPublicPlayerParamsModel>;
  publicPlayerCollection: StoreBranch<IPublicPlayerCollection, IPublicPlayerCollectionFilter>;

  getPublicPlayerModel(id: string): void;
  getPublicPlayerCollection(filter?: IPublicPlayerCollectionFilter): Promise<void>;

  clearPublicPlayerModel(): void;
  clearPublicPlayerCollection(): void;
}

const PublicPlayerModelAPIProviders = [
  new APIProvider(EActionsTypes.get, publicPlayerTransport.get, {
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    }
  })
];

const PublicPlayerCollectionAPIProviders = [
  new APIProvider(
    EActionsTypes.get,
    publicPlayerTransport.getCollection,
    {
      mapSuccess: buildCollectionResponseFormatter<any, any>(),
      preRequestDataMapper: buildCollectionPreRequestDataMapper<IPublicPlayerCollection, IPublicPlayerCollectionFilter>()
    },
    takeLatest
  )
];

const branches = [
  new Branch('model', PublicPlayerModelAPIProviders),
  new Branch('collection', PublicPlayerCollectionAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationPublicPlayer = buildCommunication<IPublicPlayerConnectedProps>(strategy);
