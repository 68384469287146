import React from 'react';
import Typography from 'antd/es/typography';

const { Title, Text, Paragraph, Link } = Typography;

export const TypographyComponent: React.FC = () => (
  <div>
    <Title className="mt-6">Typography</Title>
    <Title>Title Level 1 (38px)</Title>
    <Title level={2}>Title Level 2 (30px)</Title>
    <Title level={3}>Title Level 3 (24px)</Title>
    <Title level={4}>Title Level 4 (20px)</Title>
    <Title level={5}>Title Level 5 (16px)</Title>
    <Text className="mb-4 d-block">Text (14px)</Text>
    <Text strong className="mb-4 d-block">
      Text strong (14px)
    </Text>
    <Text underline className="mb-4  d-block">
      Text underline (14px)
    </Text>
    <Text type="secondary" className="mb-4 d-block">
      Text type secondary (Medium) (14px)
    </Text>
    <Link href="#">Link 14px underline</Link>
    <Paragraph>Paragraph (Description) (12px)</Paragraph>
  </div>
);
