import React, { useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import Button from 'antd/es/button';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { EFranchiseInviteModalType } from 'entities/UI/UI.models';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { FranchiseInviteModal } from 'entities/Franchise/components/FranchiseInviteModal';
import { FranchiseAcademyList } from 'entities/Academy/components/FranchiseList/FranchiseAcademyList';
import { IFranchiseRequestCollectionFilter } from 'entities/Franchise/Franchise.models';
import { FranchiseAcademyListFilterCard } from 'entities/Academy/components/FranchiseList/FranchiseAcademyListFilterCard';

interface IComponentProps {
  isDesktop: boolean;
}
type AllProps = IComponentProps & IUIConnectedProps;

const FranchiseAcademiesTabComponent: React.FC<AllProps> = props => {
  const history = useHistory();
  const { t } = useTranslation();
  const { openUIFranchiseInviteModal, isDesktop } = props;
  const [renderIndex, setRenderIndex] = useState(Math.random());

  const initState = {
    location: undefined,
    name: undefined
  };

  const [state, setState] = useState<Partial<IFranchiseRequestCollectionFilter>>({
    ...initState,
    ...queryToObject<Partial<IFranchiseRequestCollectionFilter>>(initState)
  });

  const debPush = useDebouncedCallback((val: any) => {
    history.replace(val);
  }, 300);

  useEffect(() => {
    debPush.callback({ search: objectToQuery(state) });
  }, [state]);

  const showAddAcademiesModal = () => {
    openUIFranchiseInviteModal({ modalType: EFranchiseInviteModalType.AddAcademy });
  };

  return (
    <>
      <Row className={`pb-5 ${!isDesktop ? 'px-3' : ''}`} justify="space-between" align="middle">
        <Typography.Title className="mb-0" level={isDesktop ? 2 : 3}>
          {t('Branches')}
        </Typography.Title>
        <Button size={isDesktop ? 'large' : 'middle'} type="primary" onClick={showAddAcademiesModal}>
          {t('Invite academy')}
        </Button>
      </Row>
      <Fragment key={renderIndex}>
        <FranchiseAcademyListFilterCard
          filter={state}
          onFilterChange={partialFilter => setState({ ...state, ...partialFilter })}
        />
      </Fragment>
      <FranchiseAcademyList
        className="mt-5 franchise-academies-page__list"
        clearFilter={() => {
          setState({ ...initState });
          setRenderIndex(Math.random());
        }}
        elementId="basicLayout"
        filter={state}
      />
      <FranchiseInviteModal />
    </>
  );
};

export const FranchiseAcademiesTab = communicationUI.injector(FranchiseAcademiesTabComponent);
