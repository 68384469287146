import React from 'react';
import Row from 'antd/es/row';
import classNames from 'classnames';
import LogoIcon from 'app/assets/icons/logo.svg';
import { IPlayerShieldSponsorModel } from 'entities/Player/Player.models';

interface IProps {
  sponsor?: IPlayerShieldSponsorModel;
}

const Component: React.FC<IProps> = props => {
  const { sponsor } = props;

  return (
    <Row className={classNames('player-shield__logo width-full', { 'custom-logo': Boolean(sponsor?.imageUrl) })} justify="center">
      <a
        href={sponsor?.linkUrl || ''}
        rel="noreferrer noopener"
        target="_blank"
        className={classNames({ 'player-shield__logo__link_disabled': !sponsor?.linkUrl })}
      >
        <img alt="" src={sponsor?.imageUrl || LogoIcon} />
      </a>
    </Row>
  );
};

export const PlayerShieldLogo = Component;
