import React, { useCallback, useContext, useMemo } from 'react';
import Typography from 'antd/es/typography';
import Popover from 'antd/es/popover';
import Row from 'antd/es/row';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { formatDateFromServer } from 'common/helpers/date.helper';
import { clientDateFullMonthFormat } from 'common/models/dateModels';
import { StripeContext } from 'common/components/Stripe/StripeProvider';
import {
  EProcessings,
  ESubscriptionsEngines,
  ESubscriptionStatus,
  ISubscriptionModel
} from 'entities/Subscription/Subscription.models';
import { communicationSubscription, ISubscriptionConnectedProps } from 'entities/Subscription/Subscription.communication';
import { getSubscriptionInfoTextClass } from 'entities/Subscription/helpers/subscription.helper';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';
import { IUIConnectedProps, communicationUI } from 'entities/UI/UI.communication';
import { EModalStripePaymentType } from 'entities/UI/UI.models';

interface IProps {
  subscription?: ISubscriptionModel | null;
  size?: 'large' | 'middle' | 'small';
  block?: boolean;
}

type AllProps = IProps & ISubscriptionConnectedProps & IAuthConnectedProps & IUIConnectedProps;

const POPOVER_TEXT_STYLE = { maxWidth: 400 };
const Z_INDEX = 9997;

const Component: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { lastSavedPaymentMethod } = useContext(StripeContext);
  const {
    subscription,
    subscriptionModel,
    updateSubscriptionModel,
    size,
    block,
    addSubscriptionActivityModel,
    authUser,
    openUIStripePaymentModal,
    closeUIStripePaymentModal
  } = props;
  const { loading: subscriptionLoading } = subscriptionModel;
  const userStripePaymentMethod = authUser?.data?.stripePaymentMethod;

  const isStripe = useMemo(() => subscription?.engine === ESubscriptionsEngines.Stripe, [subscription?.engine]);

  const paidTill = useMemo(() => formatDateFromServer(subscription?.prepaidTo, clientDateFullMonthFormat), [subscription]);

  const hasPaymentMethod = useMemo(() => {
    if (!isStripe) {
      return subscription?.hasSavedPaymentMethod;
    }

    return Boolean(userStripePaymentMethod || lastSavedPaymentMethod?.card);
  }, [userStripePaymentMethod, isStripe, lastSavedPaymentMethod]);

  const onCancelDelayedCancellation = useCallback(() => {
    if (subscription?.id) {
      updateSubscriptionModel({
        id: subscription.id,
        status: ESubscriptionStatus.Active,
        engine: subscription.engine as ESubscriptionsEngines
      });
    }
  }, [subscription]);

  const infoTextClass = useMemo(() => getSubscriptionInfoTextClass(size, block), [size, block]);

  const onTestPaymentClick = useCallback(() => {
    if (subscription?.id) {
      if (isStripe) {
        openUIStripePaymentModal({
          withCardDetails: true,
          withCoupon: false,
          title: t('Card adding'),
          cardDetailBtnText: t('Add'),
          subscription: subscription.id,
          type: EModalStripePaymentType.ReactivateSubscription
        });
      } else {
        onCancelDelayedCancellation();
        addSubscriptionActivityModel({
          isTest: true,
          subscriptionId: subscription.id,
          processing: EProcessings.Yookassa
        });
      }
    }
  }, [subscription, isStripe, closeUIStripePaymentModal, onCancelDelayedCancellation]);

  const preventDefault = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  }, []);

  return (
    <Row className={classNames({ 'width-full': block })} justify="center" onClick={preventDefault}>
      <Typography.Text className={infoTextClass}>
        {t('Subscription cancelled')}
        <Popover
          zIndex={Z_INDEX}
          placement="top"
          trigger={['click']}
          content={
            <>
              <Typography.Text className="mb-0 d-block">
                {t('subscription-delay-info', { date: paidTill })}
                {hasPaymentMethod && (
                  <Typography.Link
                    disabled={subscriptionLoading}
                    onClick={onCancelDelayedCancellation}
                    className="color-link ml-2 t-decoration-none"
                  >
                    {t('Restore')}
                  </Typography.Link>
                )}
              </Typography.Text>
              {!hasPaymentMethod && (
                <Typography.Text className="mb-0 d-block" style={POPOVER_TEXT_STYLE}>
                  {t(`need-pay-first${isStripe ? '-stripe' : ''}`)}
                  <Typography.Link
                    disabled={subscriptionLoading}
                    onClick={onTestPaymentClick}
                    className="color-link ml-2 t-decoration-none"
                  >
                    {t('Restore')}
                  </Typography.Link>
                </Typography.Text>
              )}
            </>
          }
        >
          <InfoCircleOutlined className="ml-2 color-link cursor-pointer" />
        </Popover>
      </Typography.Text>
    </Row>
  );
};

export const SubscriptionDelayedCancellation = communicationUI.injector(
  communicationAuth.injector(communicationSubscription.injector(Component))
);
