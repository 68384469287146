import { ButtonProps } from 'antd';
import React from 'react';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import { useTranslation } from 'react-i18next';
import { communicationTeam, ITeamConnectedProps } from 'entities/Team/Team.communication';

interface IProps {
  playerId?: string;
  teamId?: string;
}

type AllProps = IProps & ITeamConnectedProps & ButtonProps;

const UnlinkFromTeamActionButtonComponent: React.FC<AllProps> = props => {
  const { playerId, teamId, teamModel, removePlayerFromTeamAction, ...other } = props;
  const { loading } = teamModel;
  const { t } = useTranslation();

  const onStopPropagation = (e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
  };

  const onUnlinkPlayer = (e?: React.MouseEvent<HTMLElement>) => {
    onStopPropagation(e);
    if (teamId && playerId) {
      removePlayerFromTeamAction({ id: teamId, player: playerId });
    }
  };

  return (
    <Popconfirm
      okButtonProps={{
        type: 'text',
        size: 'middle',
        danger: true
      }}
      cancelButtonProps={{
        size: 'middle'
      }}
      okText={t('Unlink')}
      cancelText={t('Cancel')}
      title={t('Unlink player from team?')}
      onConfirm={onUnlinkPlayer}
      onCancel={onStopPropagation}
    >
      <Button type="primary" block danger disabled={loading} loading={loading} {...other}>
        {t('Unlink')}
      </Button>
    </Popconfirm>
  );
};

export const UnlinkFromTeamActionButton = communicationTeam.injector(UnlinkFromTeamActionButtonComponent);
