import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  INotificationCollection,
  INotificationCollectionFilter,
  INotificationModel
} from 'entities/Notification/Notification.models';

const basePath = '/notifications';

class NotificationTransport extends BaseHttpTransport<
  AxiosStatic,
  INotificationModel,
  INotificationCollection,
  void,
  void,
  INotificationCollectionFilter
> {}

export const notificationsTransport = new NotificationTransport(basePath, axios);
