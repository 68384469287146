import { Typography } from 'antd';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeDate, normalizeUnitResult } from 'common/helpers/normalize.helper';
import { notExistTranslationWrapper } from 'common/helpers/translate.helper';
import { MeasurementContext } from 'common/components/Provider/MeasurementContext';
import { EResultTypes } from 'entities/PlayerTest/PlayerTest.models';

interface IProps {
  active?: boolean;
  payload?: { value: number; name: string; color: string; payload: { date: number } }[];
  tag?: string;
  units?: EResultTypes;
  tooltipLabel?: string;
  isInvert?: boolean;
}

export const LineChartTooltip: React.FC<IProps> = props => {
  const { active, payload, isInvert, units, tooltipLabel } = props;
  const { t } = useTranslation();
  const { measurementSystem } = useContext(MeasurementContext);
  const getNormalizeValue = useCallback(value => (isInvert ? (value ?? 0) * -1 : value), [isInvert]);

  const item = payload?.find(({ name }) => name === 'value');
  const secondItem = payload?.find(({ name }) => name === 'secondValue');

  const normalizeValue = getNormalizeValue(item?.value);
  const normalizeSecondValue = getNormalizeValue(secondItem?.value);
  const date = useMemo(() => {
    const value = item || secondItem;
    return value?.payload?.date && new Date(value?.payload?.date);
  }, [item, secondItem]);

  if (!active || !payload?.length) {
    return null;
  }

  if (item === undefined && secondItem === undefined) {
    return null;
  }

  return (
    <div className="bg-component p-3 radius-default box-shadowed">
      {date && <Typography.Text type="secondary"> {normalizeDate(date.toISOString())} </Typography.Text>}
      {item && (
        <div className="t-nowrap" style={{ color: '#D0A71F' }}>
          {tooltipLabel}: {normalizeUnitResult(normalizeValue, units, measurementSystem)}{' '}
          {notExistTranslationWrapper(units, {}, measurementSystem)}
        </div>
      )}
      {secondItem && (
        <div className="t-nowrap">
          {t('Average')}: {normalizeUnitResult(normalizeSecondValue, units, measurementSystem)}{' '}
          {notExistTranslationWrapper(units, {}, measurementSystem)}
        </div>
      )}
    </div>
  );
};
