import React from 'react';
import List from 'antd/es/list';
import { useMediaPredicate } from 'react-media-hook';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { TPrivatePlayerActionTypes } from 'entities/PrivatePlayer/PrivatePlayer.models';
import { PrivatePlayerCard } from 'entities/PrivatePlayer/components/Card/PrivatePlayerCard';
import { IPlayerModel } from 'entities/Player/Player.models';

type AllProps = IComponentProps;

interface IComponentProps {
  item: IPlayerModel;
  actionType?: TPrivatePlayerActionTypes;
  trainerId?: string;
  teamId?: string;
}

const PrivatePlayersListItemComponent = ({ item, actionType, trainerId, teamId }: AllProps) => {
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const { id } = item;

  return (
    <List.Item key={id} className={`height-full ${isDesktop ? 'pb-3' : 'pb-5'} pt-0 border-none`}>
      <PrivatePlayerCard
        fromAcademy
        actionType={actionType}
        player={item}
        previewAvatar={false}
        trainerId={trainerId}
        teamId={teamId}
      />
    </List.Item>
  );
};

export const PrivatePlayersListItem = PrivatePlayersListItemComponent;
