import { getCreds, ICreds } from 'axios-patch-jwt';
import * as socketIO from 'socket.io-client';
import { AnyAction, Store } from 'redux';
import window from 'global/window';
import Logger from 'common/helpers/logger';
import {
  ESocketEventType,
  IChangeSubscriptionNotificationModel,
  INotificationModel
} from 'entities/Notification/Notification.models';

const labels = ['WS', 'SERVICE'];

export enum ESocketActions {
  NewNotification = 'NEW_NOTIFICATION',
  HideNotification = 'HIDE_NOTIFICATION',
  SubscriptionChange = 'SUBSCRIPTION_CHANGE',
  ChangePaymentMethod = 'CHANGE_PAYMENT_METHOD',
  SocketError = 'SOCKET_FAIL',
  Connected = 'SOCKET_CONNECTED',
  Disconnected = 'SOCKET_DISCONNECTED'
}
export class SocketIOService {
  private static socket?: SocketIOClient.Socket;
  private static store?: Store<unknown, AnyAction>;
  private static urlForConnect: string =
    window.location?.hostname === 'localhost' ? 'https://dev.junistat.com' : window.location?.origin;

  public static setStore(store: Store<unknown, AnyAction>) {
    this.store = store;
  }
  public static async connect(authToken: string) {
    console.log('Trying to connect');
    try {
      await this.disconnect();
    } catch (e) {
      Logger.err(labels, `Error while trying to disconnect at start: ${e?.message}`, e);
    }

    console.log('Creating new socket');
    try {
      // todo need add global var for url
      this.socket = socketIO.connect(this.urlForConnect, {
        query: `auth_token=${authToken}`,
        transports: ['websocket'],
        path: '/api/ws'
      });
    } catch (e) {
      Logger.err(labels, `Error while trying to create connection: ${e?.message}`, e);
    }

    this.socket?.on('connect', async (e: any) => {
      console.log('connected', e);

      try {
        this.join();
        this.listenNewEventType();
      } catch (error) {
        Logger.err(labels, `Error while trying to connect: ${error?.message}`, error);
      }
    });

    this.socket?.on('connect_error', (e: any) => {
      console.error(`connect_error`, e);
      // Logger.err(labels, `Connect error: ${e?.message}`, e);
    });
    this.socket?.on('connect_timeout', (e: any) => {
      Logger.err(labels, `Socket connect_timeout: ${e?.message}`, e);
    });
    this.socket?.on('error', (e: any) => {
      // try to see original error event in console
      console.error(`SOCKET ERROR event`, e);
      // Maybe because it occurs if the port wasn't available
      // Logger.err(labels, `SOCKET ERROR cause: ${e?.cause}`, e);
      // Logger.err(labels, `SOCKET ERROR: ${e?.message}`, e);
    });
    this.socket?.on('disconnect', (e: any) => {
      console.log('Socket disconnect', e);
    });
    this.socket?.on('reconnect', async (e: any) => {
      console.log('Socket reconnect', e);

      try {
        // await refreshToken(axiosConfig);
        const creds: ICreds = await getCreds();
        const token = creds?.access?.token || '';
        await this.connect(token);
      } catch (error) {
        Logger.err(labels, `Error while trying to reconnect: ${error?.message}`, error);
      }
    });
    // this.socket?.on('reconnect_attempt', (e: any) => {
    //   //   // Looks like not an error
    //   Logger.err(labels, `Socket reconnect_attempt: ${e}`, e);
    // });
    // this.socket?.on('reconnecting', (e: any) => {
    //   Logger.err(labels, `Socket reconnecting: ${e?.message}`, e);
    // });
    this.socket?.on('reconnect_error', (e: any) => {
      Logger.err(labels, `Reconnect error: ${e?.message}`, e);
    });
    this.socket?.on('reconnect_failed', (e: any) => {
      Logger.err(labels, `Socket reconnect failed: ${e?.message}`, e);
    });
  }
  public static async disconnect() {
    console.log('disconnect');

    try {
      await this.leave();
      this.socket?.removeAllListeners();
      this.socket?.close();
      this.socket = undefined;
    } catch (e) {
      Logger.err(labels, `Error while trying to disconnect: ${e?.message}`, e);
    }
  }

  public static join() {
    console.log('try to join');
    try {
      this.socket?.emit('join', {}, {}, (data: string) => {
        console.log('Success join', data);
      });
    } catch (e) {
      Logger.err(labels, `Error while trying to emit join: ${e?.message}`, e);
    }
  }

  public static leave() {
    return new Promise(resolve => {
      if (this.socket && this.socket?.connected) {
        console.log('Try to leave');

        const timeout = setTimeout(() => {
          console.log('Timeout');
          resolve();
        }, 1000);

        this.socket?.emit('leave', {}, (data: string) => {
          try {
            console.log('Success leave', data);
            clearTimeout(timeout);
            resolve();
          } catch (e) {
            Logger.err(labels, `Error while trying to emit leave: ${e?.message}`, e);
          }
        });
      } else {
        resolve();
      }
    }).catch(error => {
      Logger.err(labels, `Error in leave Promise: ${error?.message}`, error);
    });
  }

  public static listenNewEventType() {
    console.log('Start listen New event');
    this.socket?.on('hideNotification', (notificationData: INotificationModel) => {
      try {
        console.log('New hide notification', notificationData);
        if (this.store) {
          switch (true) {
            case (notificationData?.event as ESocketEventType) === ESocketEventType.SubscriptionChangePaymentMethod: {
              this.store?.dispatch({
                type: ESocketActions.ChangePaymentMethod,
                payload: (notificationData as unknown) as IChangeSubscriptionNotificationModel
              });
              break;
            }
            case notificationData?.event.includes(ESocketEventType.SubscriptionChange): {
              this.store?.dispatch({
                type: ESocketActions.SubscriptionChange,
                payload: (notificationData as unknown) as IChangeSubscriptionNotificationModel
              });
              break;
            }
            default: {
              this.store?.dispatch({
                type: ESocketActions.HideNotification,
                payload: notificationData
              });
            }
          }
        }
      } catch (e) {
        Logger.err(labels, `Error while trying to on hide notification: ${e?.message}`, e);
      }
    });
    this.socket?.on(ESocketEventType.NewNotification, (newNotificationData: INotificationModel) => {
      try {
        console.log('New event', newNotificationData);
        if (this.store) {
          console.log('New event with store', newNotificationData);
          this.store?.dispatch({ type: ESocketActions.NewNotification, payload: newNotificationData });
        }
      } catch (e) {
        Logger.err(labels, `Error while trying to on newNotification: ${e?.message}`, e);
      }
    });
  }
}
