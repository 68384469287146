import React from 'react';
import Row from 'antd/es/row';
import Spin from 'antd/es/spin';
import classNames from 'classnames';

interface IProps {
  className?: string;
}

export const LoadingSpin: React.FC<IProps> = props => {
  const { className } = props;

  return (
    <Row className={classNames('mt-5 width-full', { [className as string]: Boolean(className) })} justify="center" align="middle">
      <Spin size="large" />
    </Row>
  );
};
