import React, { useCallback } from 'react';
import Popconfirm from 'antd/es/popconfirm';
import Button from 'antd/es/button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ERoutesCommon } from 'common/models/routesModel';
import { EMentorRequestStatus } from 'entities/MentorRequest/MentorRequest.models';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';

interface IProps {
  mentorRequestId: string;
  disabled?: boolean;
}

type AllProps = IProps & IMentorRequestConnectedProps;

const Component: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { mentorRequestId, updateMentorRequestModel, mentorRequestModel, disabled } = props;
  const { loading } = mentorRequestModel;

  const onUnlink = useCallback(async () => {
    if (mentorRequestId) {
      await updateMentorRequestModel({
        id: mentorRequestId,
        status: EMentorRequestStatus.Aborted
      });
      history.push(ERoutesCommon.Root);
    }
  }, [mentorRequestId, history]);

  return (
    <Popconfirm
      okButtonProps={{
        type: 'text',
        size: 'middle',
        danger: true
      }}
      cancelButtonProps={{
        size: 'middle'
      }}
      okText={t('Unlink')}
      cancelText={t('Cancel')}
      title={t('Are you sure you want to unlink the player?')}
      onConfirm={onUnlink}
    >
      <Button loading={loading} disabled={disabled}>
        {t('Unlink player')}
      </Button>
    </Popconfirm>
  );
};

export const PlayerUnlink = communicationMentorRequest.injector(Component);
