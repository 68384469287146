import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  buildCollectionResponseFormatter,
  buildCollectionPreRequestDataMapper
} from '@axmit/redux-communications';
import { playerTestTransport } from 'entities/PlayerTest/PlayerTest.transport';
import { IPlayerTestCollectionFilter, IPlayerTestCollection } from 'entities/PlayerTest/PlayerTest.models';

const namespace = 'playerTest';

export interface IPlayerTestConnectedProps {
  playerTestCollection: StoreBranch<IPlayerTestCollection, IPlayerTestCollectionFilter>;
  getPlayerTestCollection(filter?: IPlayerTestCollectionFilter): Promise<void>;

  clearPlayerTestCollection(): void;
}

const PlayerTestCollectionAPIProviders = [
  new APIProvider(EActionsTypes.get, playerTestTransport.getPureCollection, {
    mapSuccess: buildCollectionResponseFormatter<any, any>(),
    preRequestDataMapper: buildCollectionPreRequestDataMapper<IPlayerTestCollection, IPlayerTestCollectionFilter>()
  })
];

const branches = [new Branch('collection', PlayerTestCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationPlayerTest = buildCommunication<IPlayerTestConnectedProps>(strategy);
