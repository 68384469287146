import React from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { AuthStatusBar } from 'entities/Auth/components/AuthStatusBar';
import { PlayerBanner } from 'entities/Player/components/PlayerBanner';

interface IComponentProps {
  id?: string;
  className?: string;
}

export const LayoutBasic: React.FC<IComponentProps> = props => {
  const { id, children, className = '' } = props;

  return (
    <Row id={id} justify="center" align="top" className={`basic__layout ${className}`}>
      <AuthStatusBar />
      <PlayerBanner />
      <Col span={24} className="basic__layout__col height-full">
        {children}
      </Col>
    </Row>
  );
};
