import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  IPlayerTestCollection,
  IPlayerTestCollectionFilter,
  IPlayerTestModel,
  IPlayerTestUpdateData
} from 'entities/PlayerTest/PlayerTest.models';

const basePath = '/player-to-tests';

class PlayerTestTransport extends BaseHttpTransport<
  AxiosStatic,
  IPlayerTestModel,
  IPlayerTestCollection,
  void,
  IPlayerTestUpdateData,
  IPlayerTestCollectionFilter
> {
  public getPureCollection = (filter?: IPlayerTestCollectionFilter): Promise<IPlayerTestCollection> =>
    axios.get(`${basePath}${objectToQuery(filter)}`);
}

export const playerTestTransport = new PlayerTestTransport(basePath, axios);
