export interface IPlayerTestModel extends Components.Schemas.PlayerToTestPureDto {}
export interface IPlayerTestCollection extends Components.Schemas.PlayerToTestPureCollectionDto {}

export interface IPlayerTestCollectionFilter extends Paths.PlayerToTestControllerList.QueryParameters {}

export interface IPlayerTestResultModel extends Components.Schemas.PlayerTestResultDto {}
export interface IPlayerTestResultPureModel extends Components.Schemas.PlayerToTestPurePlayerTestResultDto {}

export interface IPlayerTestUpdateData extends Components.Schemas.UpdatePlayerToTestDto, IPlayerTestUpdateParams {}
export interface IPlayerTestUpdateParams extends Paths.PlayerToTestControllerUpdate.PathParameters {}

export enum EResultTypes {
  Default = '',
  KG = 'kgs',
  Meter = 'm',
  CM = 'cm',
  CMT = 'cmt',
  Second = 'sec',
  MeterPerSecond = 'm/s',
  KilometerPerHour = 'km/h',
  Piece = 'pcs',
  Percent = '%',
  Bool = 't/f',
  Likes = 'likes',

  FT = 'ft',
  LB = 'lb',
  FootPreSecond = 'fps',
  Inch = 'in',
  MilePerHour = 'mph'
}

export const INTEGER_TYPES = [EResultTypes.Likes, EResultTypes.Piece];
