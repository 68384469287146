import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { skillTransport } from 'entities/Skill/Skill.transport';
import { ISkillModel, ISkillCollection, TSkillParamsModel, ISkillCollectionFilter } from 'entities/Skill/Skill.models';

const namespace = 'skill';

export interface ISkillConnectedProps {
  skillModel: StoreBranch<ISkillModel, TSkillParamsModel>;
  skillCollection: StoreBranch<ISkillCollection, ISkillCollectionFilter>;

  getSkillModel(id: string): void;
  getSkillCollection(filter?: ISkillCollectionFilter): void;

  clearSkillModel(): void;
  clearSkillCollection(): void;
}

const skillModelAPIProviders = [
  new APIProvider(EActionsTypes.get, skillTransport.get),
  new APIProvider(EActionsTypes.add, skillTransport.add)
];

const skillCollectionAPIProviders = [new APIProvider(EActionsTypes.get, skillTransport.getCollection)];

const branches = [new Branch('model', skillModelAPIProviders), new Branch('collection', skillCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationSkill = buildCommunication<ISkillConnectedProps>(strategy);
