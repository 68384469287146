import React from 'react';
import { useTranslation } from 'react-i18next';
import message from 'antd/es/message';

interface IProps {
  keys: string[];
}

const Component: React.FC<IProps> = props => {
  const { keys } = props;
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {keys.map((key, index) => {
        return (
          <span key={key}>
            {index > 0 && <br />}
            <span key={key}>{t(key)}</span>
          </span>
        );
      })}
    </>
  );
};

export const multilineMessage = (keys: string[], type: 'success' | 'warn' | 'error' = 'success', duration: number = 10) => {
  message[type](<Component keys={keys} />, duration);
};
