import Logger from 'common/helpers/logger';
import { openShareLinkModalAction } from 'entities/UI/UI.communication';

export const shareLink = async (url: string, title?: string, description?: string) => {
  if (navigator.share !== undefined) {
    try {
      await navigator.share({
        url,
        title,
        text: description
      });
    } catch (e) {
      return;
    }
  } else {
    try {
      openShareLinkModalAction({
        url,
        title,
        description
      });
    } catch (e) {
      Logger.err(['SHARE LINK', 'CLIPBOARD'], `Share link error: ${url}`, e);
    }
  }
};
