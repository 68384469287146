import React, { useCallback } from 'react';
import Modal from 'antd/es/modal';
import Typography from 'antd/es/typography';
import Button from 'antd/es/button';
import { useTranslation } from 'react-i18next';
import { ESubscriptionsEngines, ESubscriptionStatus } from 'entities/Subscription/Subscription.models';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { communicationSubscription, ISubscriptionConnectedProps } from 'entities/Subscription/Subscription.communication';

type AllProps = IUIConnectedProps & ISubscriptionConnectedProps;

const Component: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const {
    UIStripeCancelSubscriptionModal,
    closeUIStripeCancelSubscriptionModal,
    subscriptionModel,
    updateSubscriptionModel
  } = props;
  const { loading } = subscriptionModel;

  const isVisible = UIStripeCancelSubscriptionModal?.data?.isVisible;
  const subscription = UIStripeCancelSubscriptionModal?.data?.subscription;

  const onUnsubscribeCallback = useCallback(() => {
    if (subscription) {
      updateSubscriptionModel({
        id: subscription,
        status: ESubscriptionStatus.Canceled,
        engine: ESubscriptionsEngines.Stripe
      });
    }
  }, [subscription, updateSubscriptionModel]);

  return (
    <Modal
      title={t('Subscription cancellation')}
      width={360}
      footer={null}
      centered
      destroyOnClose
      visible={isVisible}
      onCancel={closeUIStripeCancelSubscriptionModal}
    >
      <Typography.Title level={5} className="mb-5">
        {t('Are you sure you want to cancel the subscription for the player?')}
      </Typography.Title>
      <Typography.Title level={5} className="mb-3 mt-0">
        {t('The player will lose the following features')}:
      </Typography.Title>
      <Typography.Paragraph className="mb-3">{t('Pass tests without restrictions')}</Typography.Paragraph>
      <Typography.Paragraph className="mb-3">{t('Take into account in the international ranking')}</Typography.Paragraph>
      <Typography.Paragraph className="mb-7">{t('Access to clubs and scouts')}</Typography.Paragraph>
      <Button block size="large" type="primary" className="mb-3" onClick={closeUIStripeCancelSubscriptionModal}>
        {t('Leave')}
      </Button>
      <Button block ghost danger size="large" type="primary" className="mb-3" onClick={onUnsubscribeCallback} loading={loading}>
        {t('Unsubscribe')}
      </Button>
      <Typography.Paragraph className="color-disable">
        {t('The subscription will be canceled at the end of the billing period')}.{' '}
        {t('We will no longer send invoices for payment')}.
      </Typography.Paragraph>
    </Modal>
  );
};

export const StripeCancelSubscriptionModal = communicationSubscription.injector(communicationUI.injector(Component));
