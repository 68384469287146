import React, { useEffect, useState, Fragment } from 'react';
import Col from 'antd/es/col';
import Radio from 'antd/es/radio';
import Row from 'antd/es/row';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import { useMediaPredicate } from 'react-media-hook';
import { ERequestType } from 'common/models/commonModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { EPlayerAcademyTabKeys, IPlayerAcademyCollectionFilter } from 'entities/Player/Player.models';
import { AcademyList } from 'entities/Academy/components/List/AcademyList';
import { AcademyListFilterCard } from 'entities/Academy/components/List/AcademyListFilterCard';

const selectTab = (key?: EPlayerAcademyTabKeys | string): EPlayerAcademyTabKeys => {
  switch (key) {
    case EPlayerAcademyTabKeys.Search:
      return EPlayerAcademyTabKeys.Search;

    case EPlayerAcademyTabKeys.MyRequests:
      return EPlayerAcademyTabKeys.MyRequests;

    case EPlayerAcademyTabKeys.Follow:
      return EPlayerAcademyTabKeys.Follow;

    default:
      return EPlayerAcademyTabKeys.Search;
  }
};

const PlayerAcademyTabComponent: React.FC = () => {
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const { id: playerId } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [renderIndex, setRenderIndex] = useState(Math.random());

  const initState = {
    tab: EPlayerAcademyTabKeys.Search,
    location: undefined,
    name: undefined,
    ageGroup: undefined,
    resultRangesValue: undefined
  };

  const [state, setState] = useState<Partial<IPlayerAcademyCollectionFilter>>({
    ...initState,
    ...queryToObject<Partial<IPlayerAcademyCollectionFilter>>(initState)
  });

  const debPush = useDebouncedCallback((val: any) => {
    history.replace(val);
  }, 300);

  useEffect(() => {
    debPush.callback({ search: objectToQuery(state) });
  }, [state]);

  const onTabChange = (tab: string) => {
    setState({ ...state, tab: selectTab(tab) });
  };

  const { tab: activeTab, ...filter } = state;

  const isRequestTab = activeTab === EPlayerAcademyTabKeys.MyRequests;
  const isFollowTab = activeTab === EPlayerAcademyTabKeys.Follow;
  const requestType = isRequestTab ? ERequestType.PlayerToAcademy : isFollowTab ? ERequestType.AcademyToPlayer : undefined;

  return (
    <>
      <Col span={24}>
        <Row className="width-full" align="middle" justify="center">
          <Radio.Group
            className={`d-flex ellipsis width-full ${isDesktop ? '' : 'px-3'}`}
            value={activeTab}
            buttonStyle="solid"
            onChange={key => onTabChange(key.target.value)}
          >
            <Radio.Button className="px-3" value={EPlayerAcademyTabKeys.Search}>
              {t('Search')}
            </Radio.Button>
            <Radio.Button className="px-3" value={EPlayerAcademyTabKeys.MyRequests}>
              {t('My requests')}
            </Radio.Button>
            <Radio.Button className="px-3" value={EPlayerAcademyTabKeys.Follow}>
              {t('Follow on me')}
            </Radio.Button>
          </Radio.Group>
        </Row>
      </Col>
      <Fragment key={renderIndex}>
        <AcademyListFilterCard filter={state} onFilterChange={partialFilter => setState({ ...state, ...partialFilter })} />
      </Fragment>
      <AcademyList
        className="mt-5 players__list"
        elementId="basicLayout"
        filter={{ ...filter, playerId, requestType }}
        clearFilter={(tabNew: EPlayerAcademyTabKeys) => {
          setState({ ...initState, tab: tabNew });
          setRenderIndex(Math.random());
        }}
      />
    </>
  );
};

export const PlayerAcademyTab = PlayerAcademyTabComponent;
