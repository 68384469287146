import React, { useContext } from 'react';
import Modal from 'antd/es/modal';
import Tabs from 'antd/es/tabs';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { translateTestData } from 'common/helpers/test.helper';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { MeasurementContext } from 'common/components/Provider/MeasurementContext';
import { ITestModel } from 'entities/Test/Test.models';
import { communicationPlayerTest, IPlayerTestConnectedProps } from 'entities/PlayerTest/PlayerTest.communication';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import { isAvailable } from 'entities/Auth/components/PrivateContent';
import { EUserRole } from 'entities/User/User.models';
import { TestModalDescription } from 'entities/Test/components/Modal/TestModalDescription';
import { PlayerTestResults } from 'entities/PlayerTest/components/PlayerTestResults';
import { TestModalEvaluationList } from 'entities/Test/components/List/TestModalEvaluationList';

interface IComponentProps {
  item: ITestModel;
  visible: boolean;
  onCancel: () => void;
  playerId?: string;
  testId?: string;
}

type AllProps = IComponentProps & IPlayerTestConnectedProps & IPlayerConnectedProps;

const TestModalComponent: React.FC<AllProps> = props => {
  const { item, visible, onCancel, playerId, testId, playerTestCollection } = props;
  const { t } = useTranslation();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const { id: testItemId } = item;
  const { data } = playerTestCollection;
  const resultsArray = data?.data ?? [];
  const { measurementSystem } = useContext(MeasurementContext);
  const { title, skills } = translateTestData(item, measurementSystem);

  return (
    <Modal
      className={`border-none ${isDesktop ? '' : 'modal-mobile'} test-modal`}
      width={376}
      wrapProps={{
        id: 'test-modal-body'
      }}
      centered
      visible={visible}
      onCancel={onCancel}
      footer={false}
      title={
        <>
          <Typography.Title className="m-0 mr-5" level={3} ellipsis={{ rows: 2 }} title={title}>
            {title}
          </Typography.Title>
          {skills && (
            <Typography.Text className="ellipsis d-block mt-3 p-0" type="secondary" title={skills}>
              {skills}
            </Typography.Text>
          )}
        </>
      }
    >
      <Tabs defaultActiveKey="1">
        {isAvailable(EUserRole.AcademyWorker) ? (
          <>
            <Tabs.TabPane tab={t('Evaluations')} key="1">
              <div className="player-test-results-modal__body">
                <TestModalEvaluationList bordered={false} testId={testId} playerId={playerId} elementId="test-modal-body" />
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('Graphs')} key="2">
              <PlayerTestResults playerId={playerId || resultsArray[0]?.id} testId={testId || testItemId} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('Description')} key="3">
              <TestModalDescription item={item} />
            </Tabs.TabPane>
          </>
        ) : (
          <>
            <Tabs.TabPane tab={t('Description')} key="1">
              <TestModalDescription item={item} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('Evaluations')} key="2">
              <div className="player-test-results-modal__body">
                <TestModalEvaluationList bordered={false} testId={testId} playerId={playerId} elementId="test-modal-body" />
              </div>
            </Tabs.TabPane>
          </>
        )}
      </Tabs>
    </Modal>
  );
};

export const TestModal = communicationPlayerTest.injector(communicationPlayer.injector(TestModalComponent));
