import React from 'react';
import Select, { SelectProps } from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import { ETestUploaderType, ETestUploaderTypeText } from 'entities/Test/Test.models';

export const TestUploaderTypeSelector: React.FC<SelectProps<any>> = props => {
  const { t } = useTranslation();

  return (
    <Select placeholder={t('Testing')} getPopupContainer={triggerNode => triggerNode as HTMLElement} {...props}>
      <Select.Option
        key={ETestUploaderType.AtTheAcademy}
        value={ETestUploaderType.AtTheAcademy}
        title={ETestUploaderTypeText.AtTheAcademy}
      >
        {t(ETestUploaderTypeText.AtTheAcademy)}
      </Select.Option>
      <Select.Option key={ETestUploaderType.Self} value={ETestUploaderType.Self} title={ETestUploaderTypeText.Self}>
        {t(ETestUploaderTypeText.Self)}
      </Select.Option>
    </Select>
  );
};
