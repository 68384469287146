import React from 'react';
import List from 'antd/es/list';
import { Link } from 'react-router-dom';
import { ERoutesAcademy } from 'common/models/routesModel';
import { ITrainerModel, ETrainerActionTypes } from 'entities/Trainer/Trainer.models';
import { TrainerCard } from 'entities/Trainer/components/Card/TrainerCard';
import { EAcademyViewTabKeys } from 'entities/Academy/Academy.models';

interface IComponentProps {
  item: ITrainerModel;
  teamId?: string;
  academyId?: string;
  actionType?: ETrainerActionTypes;
}

type AllProps = IComponentProps;

const TrainerListItemComponent: React.FC<AllProps> = props => {
  const { item, teamId, actionType, academyId } = props;

  return (
    <List.Item key={item.id}>
      <Link to={`${ERoutesAcademy.AcademyView}/${academyId}/${EAcademyViewTabKeys.Trainers}/${item.id}`}>
        <TrainerCard trainer={item} teamId={teamId} actionType={actionType} />
      </Link>
    </List.Item>
  );
};

export const TrainerListItem = TrainerListItemComponent;
