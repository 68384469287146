import React, { useState, useEffect } from 'react';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import Form from 'antd/es/form';
import { useHistory, useLocation } from 'react-router';
import { useFormMapper } from '@axmit/antd4-helpers';
import { useTranslation } from 'react-i18next';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import { ERoutesPublic } from 'common/models/routesModel';
import { requiredField } from 'common/helpers/filed-rules';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { IPasswordForgotParams } from 'entities/Auth/Auth.models';

type AllProps = IAuthConnectedProps;

const PasswordForgotFormComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const [isRequestSend, setIsRequestSend] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const { forgotAuthPasswordRestore, authPasswordRestore, authModel } = props;
  const { params, loading, errors } = authPasswordRestore;
  const { params: authModelParams } = authModel;
  const { fields } = useFormMapper(['email'], authModelParams, params, errors);

  const forgotPassword = (values: any) => {
    const { email } = values;
    const body: IPasswordForgotParams = {
      email
    };
    forgotAuthPasswordRestore(body);
    setIsRequestSend(true);
  };

  const toLogin = () => {
    history.push(`${ERoutesPublic.Login}${search}`);
  };

  useEffect(() => {
    if (isRequestSend) {
      if (!errors) {
        setShowForm(false);
      }
      setIsRequestSend(false);
    }
  }, [loading]);

  return (
    <div className="recover-password-page width-full">
      {showForm ? (
        <div className="recover-password-page__form width-full p-7">
          <Row justify="start" className="mb-5">
            <Button onClick={toLogin}>
              <ArrowLeftOutlined /> {t('Back')}
            </Button>
          </Row>
          <Row justify="start">
            <Typography.Title level={3} className="mb-2">
              {t('Recover')}
            </Typography.Title>
          </Row>
          <Row justify="start" className="mb-5">
            <Typography.Text className="color-text-secondary">{t('description')}</Typography.Text>
          </Row>
          <Form onFinish={forgotPassword} fields={fields} layout="vertical">
            <Form.Item rules={[requiredField]} name="email" label={t('Email upon registration')} className="mb-5">
              <Input type="email" name="email" size="large" disabled={loading} />
            </Form.Item>
            <Form.Item className="mb-0">
              <Button htmlType="submit" block type="primary" size="large" disabled={loading}>
                {t('Reestablish')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <div className="recover-password-page__info width-full px-7">
          <Row justify="start">
            <Typography.Title level={3} className="mb-2">
              {t('Message sended')}
            </Typography.Title>
            <Row justify="start" className="mb-5">
              <Typography.Text className="color-text-secondary">{t('Check Email')}</Typography.Text>
            </Row>
            <Button block type="primary" size="large" onClick={toLogin}>
              {t('Back to login')}
            </Button>
          </Row>
        </div>
      )}
    </div>
  );
};

export const PasswordForgotPage = communicationAuth.injector(PasswordForgotFormComponent);
