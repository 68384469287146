import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Tabs from 'antd/es/tabs';
import { Switch, Route, useHistory, useLocation, useParams } from 'react-router';
import SkinOutlined from '@ant-design/icons/SkinOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import LineChartOutlined from '@ant-design/icons/LineChartOutlined';
import ApartmentOutlined from '@ant-design/icons/ApartmentOutlined';
import SolutionOutlined from '@ant-design/icons/SolutionOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import BlockOutlined from '@ant-design/icons/BlockOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import BarChartOutlined from '@ant-design/icons/BarChartOutlined';
import { isAcademyFranchisor, isAcademyFranchiseActive } from 'common/helpers/franchise.helper';
import { getAcademyViewRoute, playersCompareRoute } from 'common/models/routesModel';
import { AcademySelectionTab } from 'entities/Academy/components/View/Tabs/AcademySelectionTab';
import { AcademyViewCardInfo } from 'entities/Academy/components/View/Card/AcademyViewCardInfo';
import { AcademyTrainersTab } from 'entities/Academy/components/View/Tabs/Trainers/AcademyTrainersTab';
import { AcademyPrivatePlayersTab } from 'entities/Academy/components/View/Tabs/Players/AcademyPrivatePlayersTab';
import { AcademyTeamsTab } from 'entities/Academy/components/View/Tabs/Teams/AcademyTeamsTab';
import { AcademyTestsTab } from 'entities/Academy/components/View/Tabs/AcademyTestsTab';
import { AcademyTestListTab } from 'entities/Academy/components/View/Tabs/AcademyTestListTab';
import { EAcademyViewTabKeys } from 'entities/Academy/Academy.models';
import { TrainerAcademyPage } from 'entities/Trainer/components/TrainerAcademyPage';
import { TeamAcademyPage } from 'entities/Team/components/Page/TeamAcademyPage';
import { AcademyModelProviderContext } from 'entities/Academy/components/View/Card/AcademyViewCardProvider';
import { FranchiseAcademiesTab } from 'entities/Academy/components/View/Tabs/FranchiseAcademiesTab';
import { PrivatePlayerView } from 'entities/PrivatePlayer/components/View/PrivatePlayerView';
import { PlayersCompare } from 'entities/Player/components/PlayersCompare/PlayersCompare';

interface IComponentProps {
  isDesktop: boolean;
}

type AllProps = IComponentProps;

const FALLBACK_TAB = EAcademyViewTabKeys.Players;

const AcademyViewTabsComponent: React.FC<AllProps> = props => {
  const { isDesktop } = props;
  const { t } = useTranslation();
  const tabPosition = isDesktop ? 'left' : 'top';
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { academyId: urlAcademyId } = useParams();
  const { academyModel, isUserAcademy } = React.useContext(AcademyModelProviderContext);
  const academyModelData = academyModel?.data;
  const isAvailableRfsTests = academyModel?.data?.isAvailableRfsTests;
  const academyId = urlAcademyId || academyModelData?.id;
  const urlRaw = getAcademyViewRoute().replace(/:academyId/, academyId);
  const urlWithoutId = '';
  const url = urlAcademyId ? pathname.split(urlRaw)[1] : pathname;
  const tabKey = url.split('/')[1];
  const secondId = url.split('/')[2];
  const thirdId = url.split('/')[3];
  const [searchValue, setSearchValue] = useState<string | undefined>(search);
  const isFranchisor = isAcademyFranchisor(academyModelData);
  const isFranchiseActive = isAcademyFranchiseActive(academyModelData);
  const franchisorModel = isFranchisor ? academyModelData : undefined;
  const [tabValue, setTabValue] = useState<EAcademyViewTabKeys>(EAcademyViewTabKeys.Players);

  const getUrlForNewTab = (newTabKey: EAcademyViewTabKeys) => {
    if (newTabKey === EAcademyViewTabKeys.Compare) {
      return urlWithoutId;
    }

    return urlRaw;
  };

  const selectTab = (key: string): EAcademyViewTabKeys => {
    if (isFranchiseActive) {
      switch (key) {
        case EAcademyViewTabKeys.MyAcademies:
          return isFranchisor ? EAcademyViewTabKeys.MyAcademies : EAcademyViewTabKeys.Players;
        case EAcademyViewTabKeys.Players:
          return EAcademyViewTabKeys.Players;
        case EAcademyViewTabKeys.Trainers:
          return EAcademyViewTabKeys.Trainers;
        case EAcademyViewTabKeys.Teams:
          return EAcademyViewTabKeys.Teams;
        case EAcademyViewTabKeys.TestResults:
          return EAcademyViewTabKeys.TestResults;
        case EAcademyViewTabKeys.RFS:
          return isAvailableRfsTests ? EAcademyViewTabKeys.RFS : EAcademyViewTabKeys.TestResults;
        case EAcademyViewTabKeys.TestsBalance:
          return EAcademyViewTabKeys.TestsBalance;
        case EAcademyViewTabKeys.Compare:
          if (isUserAcademy) {
            return EAcademyViewTabKeys.Compare;
          }
          return FALLBACK_TAB;
        case EAcademyViewTabKeys.Selection:
          return EAcademyViewTabKeys.Selection;
        default:
          return FALLBACK_TAB;
      }
    }

    switch (key) {
      case EAcademyViewTabKeys.Compare:
        return EAcademyViewTabKeys.Compare;
      case EAcademyViewTabKeys.Selection:
        return EAcademyViewTabKeys.Selection;
      default:
        return EAcademyViewTabKeys.Selection;
    }
  };

  const onTabChange = (tab: string) => {
    const selectedTab = selectTab(tab);

    setSearchValue(undefined);
    setTabValue(selectedTab);
    history.push({ pathname: `${getUrlForNewTab(selectedTab)}/${selectedTab}`, search: undefined });
  };

  const replace = (tab: string) => {
    const selectedTab = selectTab(tab);

    history.replace({
      pathname: `${getUrlForNewTab(selectedTab)}/${selectedTab}${secondId ? `/${secondId}` : ''}${thirdId ? `/${thirdId}` : ''}`,
      search: searchValue
    });
  };

  useEffect(() => {
    replace(selectTab(tabKey));
    setTabValue(selectTab(tabKey));
  }, [tabKey]);

  return (
    <Row className={`${isDesktop ? 'flex-row' : 'flex-column mt-6 mobile'} academy-view-page`}>
      <Col className="academy-view-page__tabs">
        <Tabs
          className="academy-view-page__tabs__component"
          tabPosition={tabPosition}
          onChange={onTabChange}
          activeKey={tabValue}
        >
          {isFranchiseActive && (
            <>
              <Tabs.TabPane
                tab={
                  <>
                    <SkinOutlined /> {t('Players')}
                  </>
                }
                key={EAcademyViewTabKeys.Players}
              />
              <Tabs.TabPane
                tab={
                  <>
                    <LineChartOutlined /> {t('Test results')}
                  </>
                }
                key={EAcademyViewTabKeys.TestResults}
              />
              {isAvailableRfsTests && (
                <Tabs.TabPane
                  tab={
                    <>
                      <BarChartOutlined /> {t('RFS')}
                    </>
                  }
                  key={EAcademyViewTabKeys.RFS}
                />
              )}
              <Tabs.TabPane
                tab={
                  <>
                    <SolutionOutlined /> {t('Trainers')}
                  </>
                }
                key={EAcademyViewTabKeys.Trainers}
              />
              <Tabs.TabPane
                tab={
                  <>
                    <TeamOutlined /> {t('Teams tab')}
                  </>
                }
                key={EAcademyViewTabKeys.Teams}
              />
              {isFranchisor && (
                <Tabs.TabPane
                  tab={
                    <>
                      <ApartmentOutlined /> {t('Branches')}
                    </>
                  }
                  key={EAcademyViewTabKeys.MyAcademies}
                />
              )}
            </>
          )}
          <Tabs.TabPane
            tab={
              <>
                <SearchOutlined /> {t('Selection')}
              </>
            }
            key={EAcademyViewTabKeys.Selection}
          />
          {isFranchiseActive && (
            <Tabs.TabPane
              tab={
                <>
                  <SettingOutlined /> {t('Tests balance')}
                </>
              }
              key={EAcademyViewTabKeys.TestsBalance}
            />
          )}
          {isUserAcademy && (
            <Tabs.TabPane
              tab={
                <>
                  <BlockOutlined /> {t('Compare')}
                </>
              }
              key={EAcademyViewTabKeys.Compare}
            />
          )}
        </Tabs>
      </Col>
      <Col className="academy-view-page__content">
        {isDesktop && <div className="mb-7">{<AcademyViewCardInfo />}</div>}
        <Switch>
          {isUserAcademy && (
            <Route path={playersCompareRoute()} exact>
              <PlayersCompare />
            </Route>
          )}
          <Route path={getAcademyViewRoute(EAcademyViewTabKeys.Selection)} exact>
            <AcademySelectionTab />
          </Route>
          {isFranchiseActive && (
            <>
              <Route path={getAcademyViewRoute(EAcademyViewTabKeys.Players)} exact>
                <AcademyPrivatePlayersTab isDesktop={isDesktop} franchisorModel={franchisorModel} />
              </Route>
              {isFranchisor && (
                <Route path={getAcademyViewRoute(EAcademyViewTabKeys.MyAcademies)} exact>
                  <FranchiseAcademiesTab isDesktop={isDesktop} />
                </Route>
              )}
              <Route path={getAcademyViewRoute(EAcademyViewTabKeys.Trainers)} exact>
                <AcademyTrainersTab isDesktop={isDesktop} franchisorModel={franchisorModel} />
              </Route>
              <Route path={`${getAcademyViewRoute(EAcademyViewTabKeys.Trainers)}/:trainerId`} exact>
                <TrainerAcademyPage isDesktop={isDesktop} />
              </Route>
              <Route path={`${getAcademyViewRoute(EAcademyViewTabKeys.Players)}/:id`}>
                <PrivatePlayerView />
              </Route>
              <Route path={getAcademyViewRoute(EAcademyViewTabKeys.Teams)} exact>
                <AcademyTeamsTab isDesktop={isDesktop} franchisorModel={franchisorModel} />
              </Route>
              <Route path={`${getAcademyViewRoute(EAcademyViewTabKeys.Teams)}/:teamId/:activeTab?`} exact>
                <TeamAcademyPage isDesktop={isDesktop} />
              </Route>
              <Route path={getAcademyViewRoute(EAcademyViewTabKeys.TestResults)} exact>
                <AcademyTestListTab isDesktop={isDesktop} />
              </Route>
              {isAvailableRfsTests && (
                <Route path={getAcademyViewRoute(EAcademyViewTabKeys.RFS)} exact>
                  <AcademyTestListTab isDesktop={isDesktop} isRFS />
                </Route>
              )}
              <Route path={getAcademyViewRoute(EAcademyViewTabKeys.TestsBalance)} exact>
                <AcademyTestsTab />
              </Route>
            </>
          )}
        </Switch>
      </Col>
    </Row>
  );
};

export const AcademyViewTabs = AcademyViewTabsComponent;
