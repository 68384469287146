import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { referralCodeTransport } from 'entities/ReferralCode/ReferralCode.transport';
import {
  IReferralCodeSubscriptionsStatisticCollectionParams,
  IReferralCodeSubscriptionActivitiesStatisticCollectionParams,
  IReferralCodeSubscriptionsStatisticCollection,
  IReferralCodeSubscriptionActivitiesStatisticCollection
} from 'entities/ReferralCode/ReferralCode.models';

const namespace = 'referralCode';

export interface IReferralCodeConnectedProps {
  referralCodeSubscriptionsStatistic: StoreBranch<
    IReferralCodeSubscriptionsStatisticCollection,
    IReferralCodeSubscriptionsStatisticCollectionParams
  >;
  referralCodeSubscriptionActivitiesStatistic: StoreBranch<
    IReferralCodeSubscriptionActivitiesStatisticCollection,
    IReferralCodeSubscriptionActivitiesStatisticCollectionParams
  >;

  getReferralCodeSubscriptionsStatistic(filter: IReferralCodeSubscriptionsStatisticCollectionParams): void;
  getReferralCodeSubscriptionActivitiesStatistic(filter: IReferralCodeSubscriptionActivitiesStatisticCollectionParams): void;
}

const referralCodeSubscriptionsStatisticAPIProviders = [
  new APIProvider(EActionsTypes.get, referralCodeTransport.getReferralCodeSubscriptionsStatistic)
];

const referralSubscriptionActivitiesStatisticAPIProviders = [
  new APIProvider(EActionsTypes.get, referralCodeTransport.getReferralCodeSubscriptionActivitiesStatistic)
];

const branches = [
  new Branch('subscriptionsStatistic', referralCodeSubscriptionsStatisticAPIProviders),
  new Branch('subscriptionActivitiesStatistic', referralSubscriptionActivitiesStatisticAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationReferralCode = buildCommunication<IReferralCodeConnectedProps>(strategy);
