import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  IReferralCodeSubscriptionActivitiesStatisticCollection,
  IReferralCodeSubscriptionActivitiesStatisticCollectionParams,
  IReferralCodeSubscriptionsStatisticCollection,
  IReferralCodeSubscriptionsStatisticCollectionParams
} from 'entities/ReferralCode/ReferralCode.models';

const basePath = '/referral-codes';

class ReferralCodeTransport extends BaseHttpTransport<AxiosStatic, void, void, void, void, void> {
  public getReferralCodeSubscriptionActivitiesStatistic = ({
    id,
    ...filters
  }: IReferralCodeSubscriptionActivitiesStatisticCollectionParams): Promise<
    IReferralCodeSubscriptionActivitiesStatisticCollection
  > => axios.get(`${basePath}/${id}/subscription-activities-statistic${objectToQuery(filters)}`);
  public getReferralCodeSubscriptionsStatistic = ({
    id,
    ...filters
  }: IReferralCodeSubscriptionsStatisticCollectionParams): Promise<IReferralCodeSubscriptionsStatisticCollection> =>
    axios.get(`${basePath}/${id}/subscriptions-statistic${objectToQuery(filters)}`);
}

export const referralCodeTransport = new ReferralCodeTransport(basePath, axios);
