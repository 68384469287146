import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useFormMapper } from '@axmit/antd4-helpers';
import { Button, Card, Form, Input, Row, Typography } from 'antd';
import Col from 'antd/es/col';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import classNames from 'classnames';
import { EPermissionType, permissionHelper } from 'common/helpers/permission.helper';
import { maxArrayLengthRule, maxLengthRule, maxNumberRule, minNumberRule } from 'common/helpers/filed-rules';
import { InputNumber } from 'common/components/Input/InputNumber';
import { FieldPositionSelector } from 'common/components/Selector/FieldPositionSelector';
import { AvatarEditor } from 'common/components/AvatarEditor/AvatarEditor';
import {
  converterForBeMeasurementValue,
  converterFromBeMeasurementValue,
  EMeasurementSystem
} from 'common/helpers/units-converter.helper';
import { MeasurementContext } from 'common/components/Provider/MeasurementContext';
import { notExistTranslationWrapper } from 'common/helpers/translate.helper';
import {
  EFieldPositions,
  FIELD_POSITIONS_BY_AGE,
  MAX_PLAYER_FIELD_POSITIONS,
  MAX_PLAYER_HEIGHT,
  MAX_PLAYER_HEIGHT_US,
  MAX_PLAYER_WEIGHT,
  MAX_PLAYER_WEIGHT_US,
  MIN_PLAYER_HEIGHT,
  MIN_PLAYER_HEIGHT_US,
  MIN_PLAYER_WEIGHT,
  MIN_PLAYER_WEIGHT_US
} from 'entities/Player/Player.models';
import { PlayerVideosModal } from 'entities/Player/components/PlayerVideos/PlayerVideosModal';
import { PrivateContent } from 'entities/Auth/components/PrivateContent';
import { EUserRole } from 'entities/User/User.models';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import { PlayerVideosList } from 'entities/Player/components/PlayerVideos/PlayerVideosList';
import { communicationAcademy, IAcademyConnectedProps } from 'entities/Academy/Academy.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EResultTypes } from 'entities/PlayerTest/PlayerTest.models';

interface IComponentProps {
  isDesktop: boolean;
}

interface IParams {
  id: string;
}

type AllProps = IComponentProps & IPlayerConnectedProps & IAuthConnectedProps & IAcademyConnectedProps;

const PlayerAboutTabComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { id: playerId } = useParams<IParams>();
  const { md } = useBreakpoint();
  const { isDesktop, playerVideosCollection, academyPlayerModel, authUser, playerModel, updateByMentorPlayerModel } = props;
  const { data: authUserData } = authUser;
  const { data: academyPlayerModelData, loading: academyPlayerModelLoading } = academyPlayerModel;
  const { data, loading: playerModelLoading, params, errors: playerModelErrors } = playerModel;
  const showPlayerData =
    (permissionHelper(EPermissionType.ShowPlayerDataForAcademy, authUserData, academyPlayerModelData) ||
      permissionHelper(EPermissionType.ShowDataForMentor, authUserData, data)) &&
    !academyPlayerModelLoading;
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>('');
  const { data: dataVideosCollection, loading } = playerVideosCollection;
  const videos = dataVideosCollection?.data || [];
  const playerAgeGroup = data?.ageGroup;
  const { measurementSystem } = useContext(MeasurementContext);

  const [avatarId, setAvatarId] = useState(data?.image?.id || null);

  const formData: any = useMemo(() => {
    return {
      ...data,
      weight: Number(data?.weight)
        ? converterFromBeMeasurementValue(data?.weight, EResultTypes.KG, measurementSystem)
        : undefined,
      height: Number(data?.height) ? converterFromBeMeasurementValue(data?.height, EResultTypes.CM, measurementSystem) : undefined
    };
  }, [data, measurementSystem]);

  const paramsData = useMemo(() => {
    return {
      ...params,
      weight: Number(params?.weight)
        ? converterFromBeMeasurementValue(params?.weight, EResultTypes.KG, measurementSystem)
        : Number(data?.weight)
        ? converterFromBeMeasurementValue(data?.weight, EResultTypes.KG, measurementSystem)
        : undefined,
      height: Number(params?.height)
        ? converterFromBeMeasurementValue(params?.height, EResultTypes.CM, measurementSystem)
        : Number(data?.height)
        ? converterFromBeMeasurementValue(data?.height, EResultTypes.CM, measurementSystem)
        : undefined
    };
  }, [params, data]);

  const { fields } = useFormMapper(['about', 'positions', 'weight', 'height'], formData, paramsData, playerModelErrors);

  const openUploadModal = useCallback(() => {
    setShowUploadModal(true);
  }, []);

  const isUploadDisabled = useMemo(() => Object.keys(videos).length >= 3, [videos]) || loading;

  const closeUploadModal = useCallback(() => {
    setShowUploadModal(false);
    setSelectedItem(undefined);
  }, []);

  const onOpenElement = useCallback(id => {
    setSelectedItem(id);
    openUploadModal();
  }, []);

  const onSaveAbout = useCallback(
    (values: { about: string; weight?: number; height?: number; positions?: EFieldPositions[] }) => {
      const { about, weight, height, positions } = values;

      const body: any = {
        ...values,
        positions,
        about: about || '',
        id: playerId,
        image: avatarId
      };

      if (height) {
        body.height = converterForBeMeasurementValue(height, EResultTypes.FT, measurementSystem);
      }
      if (weight) {
        body.weight = converterForBeMeasurementValue(weight, EResultTypes.LB, measurementSystem);
      }
      updateByMentorPlayerModel(body);
    },
    [avatarId, measurementSystem]
  );

  const availableFieldPositions = useMemo(() => {
    if (playerAgeGroup) {
      return FIELD_POSITIONS_BY_AGE[playerAgeGroup];
    }

    return undefined;
  }, [playerAgeGroup]);

  return (
    <div className="players__videos-tab width-full">
      <PrivateContent availableFor={EUserRole.Mentor}>
        <Typography.Title level={4} className="mb-5 mobile-header-padding">
          {t('About me')}
        </Typography.Title>
      </PrivateContent>
      <Card className="border-none width-full px-3 mb-5">
        <PrivateContent availableFor={EUserRole.Mentor}>
          <Form onFinish={onSaveAbout} fields={fields} name="update-player-about" layout="vertical">
            <Row gutter={[16, 16]} className={classNames('width-full', { 'mx-0': !md })}>
              <Col flex="100px">
                <AvatarEditor onChange={setAvatarId} avatarId={avatarId} />
              </Col>
              <Col flex="auto">
                <Form.Item name="about" rules={[maxLengthRule(1024)]} className="mb-3">
                  <Input.TextArea disabled={playerModelLoading} autoSize={{ minRows: 4 }} placeholder={t('Fill information')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className={classNames('width-full', { 'mx-0': !md })}>
              <Col xs={12} md={5} className={classNames({ 'pl-0': !md })}>
                <Form.Item
                  label={`${t('Height')}, ${t(
                    measurementSystem === EMeasurementSystem.US ? EResultTypes.FT : EResultTypes.CMT
                  )} ${t('Optional')}`}
                  name="height"
                  rules={[
                    maxNumberRule(measurementSystem === EMeasurementSystem.US ? MAX_PLAYER_HEIGHT_US : MAX_PLAYER_HEIGHT),
                    minNumberRule(measurementSystem === EMeasurementSystem.US ? MIN_PLAYER_HEIGHT_US : MIN_PLAYER_HEIGHT),
                    { required: false }
                  ]}
                >
                  <InputNumber precision={1} size="large" className="width-full" placeholder={t('Player height')} />
                </Form.Item>
              </Col>
              <Col xs={12} md={5} className={classNames({ 'pr-0': !md })}>
                <Form.Item
                  label={`${t('Weight')}, ${notExistTranslationWrapper(EResultTypes.KG, {}, measurementSystem)} ${t('Optional')}`}
                  name="weight"
                  rules={[
                    maxNumberRule(measurementSystem === EMeasurementSystem.US ? MAX_PLAYER_WEIGHT_US : MAX_PLAYER_WEIGHT),
                    minNumberRule(measurementSystem === EMeasurementSystem.US ? MIN_PLAYER_WEIGHT_US : MIN_PLAYER_WEIGHT),
                    { required: false }
                  ]}
                >
                  <InputNumber precision={1} size="large" className="width-full" placeholder={t('Player weight')} />
                </Form.Item>
              </Col>
              {md && (
                <Col md={9} xl={8} xxl={7}>
                  <Form.Item label={t('Positions')} name="positions" rules={[maxArrayLengthRule(MAX_PLAYER_FIELD_POSITIONS)]}>
                    <FieldPositionSelector size="large" mode="multiple" availablePositions={availableFieldPositions} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            {!md && (
              <Form.Item label={t('Positions')} name="positions" rules={[maxArrayLengthRule(MAX_PLAYER_FIELD_POSITIONS)]}>
                <FieldPositionSelector size="large" mode="multiple" availablePositions={availableFieldPositions} />
              </Form.Item>
            )}
            <Row className="width-full" justify="start">
              <Button htmlType="submit" type="primary" size="large" loading={playerModelLoading} disabled={playerModelLoading}>
                {t('Save')}
              </Button>
            </Row>
          </Form>
        </PrivateContent>
        <PrivateContent availableFor={EUserRole.AcademyWorker}>
          {showPlayerData ? (
            <Typography.Text className="t-pre-wrap">{academyPlayerModelData?.about || t('No data')}</Typography.Text>
          ) : (
            <Row justify="center">
              <Typography.Text>{t('Player info are hidden by privacy settings')}</Typography.Text>
            </Row>
          )}
        </PrivateContent>
      </Card>
      <PrivateContent availableFor={EUserRole.Mentor}>
        {isDesktop ? (
          <Row justify="space-between" align="middle" className="mb-5">
            <Typography.Title level={4} className="mb-0">
              {t('My videos')}
            </Typography.Title>
            <Button size="large" onClick={openUploadModal} loading={playerModelLoading} disabled={isUploadDisabled}>
              {t('Upload video')}
            </Button>
          </Row>
        ) : (
          <Row className="mb-5 px-5">
            <Button block onClick={openUploadModal} loading={playerModelLoading} disabled={isUploadDisabled}>
              {t('Upload video')}
            </Button>
          </Row>
        )}
      </PrivateContent>
      <div className={`players__videos-tab__list ${isDesktop ? '' : 'mobile'} p-5 radius-default`}>
        {showPlayerData ? (
          <PlayerVideosList elementId="basicLayout" onOpenElement={onOpenElement} isDesktop={isDesktop} playerId={playerId} />
        ) : (
          <Row className="width-full" justify="center" align="middle">
            <Typography.Text>{t('Videos are hidden by privacy settings')}</Typography.Text>
          </Row>
        )}
      </div>
      <PlayerVideosModal visible={showUploadModal} onCancel={closeUploadModal} id={selectedItem} isDesktop={isDesktop} />
    </div>
  );
};

export const PlayerAboutTab = communicationAuth.injector(
  communicationAcademy.injector(communicationPlayer.injector(PlayerAboutTabComponent))
);
