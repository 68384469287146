import { Row } from 'antd';
import Typography from 'antd/es/typography';
import React from 'react';
import { ITeamLinkBaseModel } from 'entities/Team/Team.models';

interface IComponentProps {
  teamLinkBase?: ITeamLinkBaseModel[];
  teamLinks?: ITeamItem[];
}

interface ITeamItem {
  id: string;
  name: string;
}
const ARRAY_LENGTH = 3;

export const TeamsBadge = ({ teamLinks, teamLinkBase }: IComponentProps) => {
  const teams: ITeamItem[] | undefined = teamLinkBase
    ? teamLinkBase?.reduce((acc: ITeamItem[], teamLink: ITeamLinkBaseModel) => {
        return [...acc, teamLink.team];
      }, [])
    : teamLinks ?? [];
  const slicedTeamArray = teams?.slice(0, ARRAY_LENGTH);
  const teamsOverflow = (teams?.length || 0) - ARRAY_LENGTH;

  return (
    <Row className="teams-badge" gutter={[8, 8]} align="middle" wrap={false}>
      {slicedTeamArray?.map(({ name, id }) => (
        <Typography.Text key={id} ellipsis title={name} className="teams-badge__element">
          {name}
        </Typography.Text>
      )) || null}
      {teamsOverflow > 0 && (
        <Typography.Text ellipsis title={name} className="teams-badge__element flex-shrink">
          +{teamsOverflow}
        </Typography.Text>
      )}
    </Row>
  );
};
