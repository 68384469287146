import React from 'react';
import Radio from 'antd/es/radio';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { translateTariffData } from 'common/helpers/tariff-helper';
import { ITariffModel } from 'entities/Tariff/Tariff.models';

interface IProps {
  tariff: ITariffModel;
}

type AllProps = IProps;

const Component: React.FC<AllProps> = props => {
  const { tariff } = props;
  const { currentPrice, amount, currency } = tariff;
  const { t } = useTranslation();
  const { title, saleDescription1, saleDescription2 } = translateTariffData(tariff);
  const tariffAmount = currentPrice?.amount || amount;
  const tariffCurrency = currentPrice?.currency || currency;

  return (
    <Radio.Button className="tariffs-component__item" value={tariff.id}>
      <Typography.Title level={4} className="mb-2 tariffs-component__item__title">
        <span className="tariffs-component__item__title__name" title={title}>
          {title}
        </span>
        <span>{t('title-sum', { amount: tariffAmount, currency: tariffCurrency })}</span>
      </Typography.Title>
      {currentPrice?.localCurrency && tariffCurrency !== currentPrice.localCurrency && (
        <Row className="mb-2">
          <Typography.Text>
            {t('What is roughly equal', { amount: currentPrice?.localAmount, currency: currentPrice.localCurrency })}
          </Typography.Text>
        </Row>
      )}
      {!tariff.isHidePeriod && (
        <Row className="mb-2">
          <Typography.Text>{t('Months amount', { months: tariff.period })}</Typography.Text>
        </Row>
      )}
      {saleDescription1 && (
        <Row className="mb-2">
          <Typography.Text className="width-full">
            <span className="tariffs-component__item__sale-description" title={saleDescription1}>
              {saleDescription1}
            </span>
          </Typography.Text>
        </Row>
      )}
      {saleDescription2 && (
        <Row>
          <Typography.Text className="width-full">
            <span className="tariffs-component__item__sale-description" title={saleDescription2}>
              {saleDescription2}
            </span>
          </Typography.Text>
        </Row>
      )}
    </Radio.Button>
  );
};

export const TariffsItemComponent = Component;
