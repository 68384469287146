import { IBasePathParams } from 'common/models/requestModels';

export interface ISkillModel extends Components.Schemas.SkillDto {}
export interface ISkillCollection extends Components.Schemas.SkillCollectionDto {}
export interface ISkillCollectionFilter extends Paths.SkillControllerList.QueryParameters {}

export type TSkillParamsModel = IBasePathParams;

export type TSkillName =
  | 'speed'
  | 'acceleration'
  | 'coordination'
  | 'reaction'
  | 'agility'
  | 'balance'
  | 'jumpingAbility'
  | 'power'
  | 'stop'
  | 'control'
  | 'maintaining'
  | 'shortPass'
  | 'longPass'
  | 'hitPrecision'
  | 'impactForce'
  | 'pace'
  | 'physical'
  | 'dribbling'
  | 'passing'
  | 'shooting'
  | 'playingWithWeakFoot';

export enum ESkillTag {
  control = 'control',
  maintaining = 'maintaining',
  hitPrecision = 'hitPrecision',
  impactForce = 'impactForce',
  shortPass = 'shortPass',
  longPass = 'longPass',
  stop = 'stop',
  coordination = 'coordination',
  reaction = 'reaction',
  agility = 'agility',
  balance = 'balance',
  jumpingAbility = 'jumpingAbility',
  power = 'power',
  speed = 'speed',
  acceleration = 'acceleration',
  playingWithWeakFoot = 'playingWithWeakFoot'
}

export enum ESkillTagName {
  control = 'Control',
  maintaining = 'Maintaining',
  hitPrecision = 'Hit precision',
  impactForce = 'Impact force',
  shortPass = 'Short pass',
  longPass = 'Long pass',
  stop = 'Stop',
  coordination = 'Coordination',
  reaction = 'Reaction',
  agility = 'Agility',
  balance = 'Balance',
  jumpingAbility = 'Jumping ability',
  power = 'Power',
  speed = 'Speed',
  pace = 'Pace',
  physical = 'Physical',
  dribbling = 'Dribbling',
  passing = 'Passing',
  shooting = 'Shooting',
  acceleration = 'Acceleration',
  playingWithWeakFoot = 'Playing with a weak foot'
}
