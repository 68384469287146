import React, { useCallback, useMemo } from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import GlobalOutlined from '@ant-design/icons/GlobalOutlined';
import BlockOutlined from '@ant-design/icons/BlockOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import { useLocation, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { scrollToTop } from 'common/helpers/dom.helper';
import { ERoutesCommon, ERoutesMentor, playersCompareRoute } from 'common/models/routesModel';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import logo from 'app/assets/icons/logo.svg';
import { EUserRole } from 'entities/User/User.models';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';

interface IProps {
  onlyLogo?: boolean;
}

type AllProps = IProps & IAuthConnectedProps;

const LogoComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const { authUser, onlyLogo } = props;
  const { search, pathname } = useLocation();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const isMyFootballersPage = useRouteMatch(ERoutesMentor.RequestList)?.isExact;
  const isAllFootballersPage = useRouteMatch(ERoutesMentor.Players)?.isExact;
  const isPlayersComparePage = useRouteMatch(playersCompareRoute())?.isExact;

  const ButtonIconStyle = useMemo(() => (isDesktop ? {} : { fontSize: 24 }), [isDesktop]);
  const ButtonIconClass = useCallback(isActive => `header-btn-icon ${isActive ? 'active' : ''}`, []);

  const actionBlock = React.useMemo(() => {
    const { data: authUserModel } = authUser;
    const isAcademy = authUserModel?.role === EUserRole.AcademyWorker;
    const getBtnType = (isCurrentPage?: boolean) => (isDesktop ? (isCurrentPage ? 'primary' : 'default') : 'text');

    if (isAcademy) {
      return null;
    }

    return (
      <Row
        justify={isDesktop ? 'center' : 'start'}
        gutter={[isDesktop ? 16 : 8, 0]}
        className="flex-noWrap header__logo"
        align="middle"
      >
        <Col>
          <Link to={ERoutesCommon.Root} onClick={() => scrollToTop('basicLayout')}>
            <Button
              size={isDesktop ? 'middle' : 'large'}
              icon={<TeamOutlined style={ButtonIconStyle} className={ButtonIconClass(isMyFootballersPage)} />}
              ghost={isDesktop && isMyFootballersPage}
              type={getBtnType(isMyFootballersPage)}
            >
              {isDesktop && t('My footballers')}
            </Button>
          </Link>
        </Col>
        <Col>
          <Link to={ERoutesMentor.Players} onClick={() => scrollToTop('basicLayout')}>
            <Button
              size={isDesktop ? 'middle' : 'large'}
              icon={<GlobalOutlined style={ButtonIconStyle} className={ButtonIconClass(isAllFootballersPage)} />}
              ghost={isDesktop && isAllFootballersPage}
              type={getBtnType(isAllFootballersPage)}
            >
              {isDesktop && t('All footballers')}
            </Button>
          </Link>
        </Col>
        <Col>
          <Link to={playersCompareRoute()} onClick={() => scrollToTop('basicLayout')}>
            <Button
              size={isDesktop ? 'middle' : 'large'}
              icon={<BlockOutlined style={ButtonIconStyle} className={ButtonIconClass(isPlayersComparePage)} />}
              ghost={isDesktop && isPlayersComparePage}
              type={getBtnType(isPlayersComparePage)}
            >
              {isDesktop && t('Compare')}
            </Button>
          </Link>
        </Col>
      </Row>
    );
  }, [authUser, search, pathname, t, isDesktop, isPlayersComparePage, isAllFootballersPage, isMyFootballersPage]);

  return (
    <Row justify="space-between" className="flex-noWrap" align="middle" gutter={{ xs: 4, sm: 8 }}>
      <Col flex={1}>
        <Link to={ERoutesCommon.Root} onClick={() => scrollToTop('basicLayout')}>
          <img className="basic__header_logo" height={30} width="auto" src={logo} alt="logo" />
        </Link>
      </Col>
      {!onlyLogo && <Col flex={1}>{actionBlock}</Col>}
    </Row>
  );
};

export const Logo = communicationAuth.injector(LogoComponent);
