import React from 'react';
import i18n from 'i18next';
import Typography from 'antd/es/typography';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import { IPlayerTestCollection, IPlayerTestModel, IPlayerTestCollectionFilter } from 'entities/PlayerTest/PlayerTest.models';
import { TestModalEvaluationItem } from 'entities/Test/components/List/TestModalEvaluationItem';
import { communicationPlayerTest, IPlayerTestConnectedProps } from 'entities/PlayerTest/PlayerTest.communication';

interface IComponentProps {
  testId?: string;
  playerId?: string;
}

type AllProps = IComponentProps & IPlayerTestConnectedProps;

class TestModalEvaluationListComponent extends InfiniteList<
  IPlayerTestCollection,
  IPlayerTestModel,
  AllProps,
  IPlayerTestCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getPlayerTestCollection, playerId, testId } = this.props;
    const body: IPlayerTestCollectionFilter = {
      ...params
    };

    if (playerId) {
      body.player = playerId;
    }

    if (testId) {
      body.test = testId;
    }

    return getPlayerTestCollection(body);
  };

  renderNoData = () => {
    return <Typography.Title level={5}>{i18n.t('The player has no grades for this test')}</Typography.Title>;
  };

  renderListItem = (item: IPlayerTestModel) => {
    return <TestModalEvaluationItem item={item} />;
  };

  getCollection = () => {
    return this.props.playerTestCollection;
  };

  clearCollection = () => {
    return this.props.clearPlayerTestCollection();
  };
}

export const TestModalEvaluationList = communicationPlayerTest.injector(TestModalEvaluationListComponent);
