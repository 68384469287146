import React from 'react';
import {
  StripeCardElementComponent,
  IStripeCardElementProps
} from 'common/components/Stripe/StripeCardElement/StripeCardElementComponent';
import { StripeConsumer } from 'common/components/Stripe/StripeConsumer';

export const StripeCardElement = (props: IStripeCardElementProps) => (
  <StripeConsumer>
    <StripeCardElementComponent {...props} />
  </StripeConsumer>
);
