import { options } from 'options';
import React from 'react';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import { Logo } from 'common/components/Header/Logo';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

interface IComponentProps {}

type AllProps = IComponentProps & IAuthConnectedProps;

const BanInfoPageComponent: React.FC<AllProps> = (props: AllProps) => {
  const { deleteAuthModel, authModel } = props;
  const { loading } = authModel;
  const { t } = useTranslation();

  const onLogout = () => {
    deleteAuthModel();
  };

  return (
    <Row className="width-full height-full" align="middle" justify="center">
      <div className="ban-page">
        <Row gutter={[0, 16]} className="p-7 ban-page__card" justify="center">
          <Logo onlyLogo />
          <Typography.Title level={5} className="t-align-c mt-4 width-full" title={t('Profile is blocked')}>
            {t('Profile is blocked')}
          </Typography.Title>
          <Typography.Text className="t-align-c width-full" title={t('Contact support')}>
            {t('Contact support')}
          </Typography.Text>
          <Typography.Link underline={false} className="color-link width-full t-align-c" href={`mailto:${options.supportMail}`}>
            {options.supportMail}
          </Typography.Link>
          <Button block onClick={onLogout} disabled={loading} type="primary" title={t('Logout')}>
            {t('Logout')}
          </Button>
        </Row>
      </div>
    </Row>
  );
};

export const BanInfoPage = communicationAuth.injector(BanInfoPageComponent);
