import Typography from 'antd/es/typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IComponentProps {
  rating?: string | number | null;
}

export const PlayerRatingSmall = ({ rating }: IComponentProps) => {
  const { t } = useTranslation();

  return (
    <Typography.Title
      level={5}
      title={t('Rating')}
      className="player-rating-small px-3 py-1 mb-0 bg-primary radius-round t-align-c"
    >
      {rating === null || rating === undefined ? '-' : rating}
    </Typography.Title>
  );
};
