import React from 'react';
import { AcademyViewCard } from 'entities/Academy/components/View/Card/AcademyViewCard';
import { AcademyModelProviderContext } from 'entities/Academy/components/View/Card/AcademyViewCardProvider';

interface IComponentProps {}

type AllProps = IComponentProps;

const AcademyViewInfoComponent: React.FC<AllProps> = () => {
  const { academyModel } = React.useContext(AcademyModelProviderContext);
  const { data } = academyModel ?? {};
  const franchiseRequest = data?.franchiseRequest;

  if (!data) {
    return null;
  }

  return <AcademyViewCard academy={data} franchiseRequest={franchiseRequest} />;
};

export const AcademyViewCardInfo = AcademyViewInfoComponent;
