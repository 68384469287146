import { ISubscriptionModel } from 'entities/Subscription/Subscription.models';
import { IUserPaymentMethod } from 'entities/User/User.models';

export interface INotificationModel extends Components.Schemas.NotificationDto {}
export interface INotificationModelParams extends Paths.NotificationControllerGet.PathParameters {}
export interface INotificationParams extends Components.Schemas.UnseenCountNotificationDto {}
export interface INotificationCollection extends Components.Schemas.NotificationCollectionDto {}
export interface INotificationCollectionFilter extends Paths.NotificationControllerList.QueryParameters {}

export interface IChangeSubscriptionNotificationModel {
  data?: {
    paymentMethod?: null | IUserPaymentMethod;
  };
  subscription: Partial<ISubscriptionModel>;
}

export interface INotificationUnseenCount extends Components.Schemas.UnseenCountNotificationDto {}

export enum ENotificationTypes {
  AcademyRequestPlayer = 'academyRequestPlayer',
  PlayerRequestAcademy = 'playerRequestAcademy',
  MentorRequestNew = 'mentorRequestNew',
  MentorRequestAccept = 'mentorRequestAccept',
  MentorRequestDecline = 'mentorRequestDecline',
  MentorRequestAbort = 'mentorRequestAbort',
  PlayerToTestAccept = 'playerToTestAccept',
  PlayerToTestReject = 'playerToTestReject'
}

export enum ESocketEventType {
  NewNotification = 'newNotification',
  SubscriptionChange = 'subscriptionChange',
  SubscriptionChangePaymentMethod = 'subscriptionChangePaymentMethod'
}
