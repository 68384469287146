import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import axios from 'axios';
import { StoreBranch } from '@axmit/redux-communications';
import { IError } from 'fe-error-helper';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { queryToObject } from 'common/helpers/filters.helper';
import { EErrorStatus } from 'common/models/requestModels';
import { ERoutesCommon, ERoutesPublic } from 'common/models/routesModel';
import { setHeaders } from 'common/helpers/axios.helper';
import { IPlayerModel, IPlayerParamsModel, IPlayerProfileModel } from 'entities/Player/Player.models';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import { playerTransport } from 'entities/Player/Player.transport';
import { EUserRole } from 'entities/User/User.models';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

export interface IPlayerProfileProviderContext {
  profile?: StoreBranch<IPlayerProfileModel, null, IError> | null;
  playerId?: string;
  isUserAcademyPlayer?: boolean;
}

export interface IPlayerProviderContext {
  player?: StoreBranch<IPlayerModel, IPlayerParamsModel, IError> | null;
}

interface IProps {
  children?: React.ReactNode;
}

const ACCESS_PROFILE_TOKEN = 'profile_token';
export const PlayerProviderContext = React.createContext<IPlayerProviderContext>({});
export const PlayerProfileProviderContext = React.createContext<IPlayerProfileProviderContext>({});

type AllProps = IPlayerConnectedProps & IProps & IAuthConnectedProps;

const PlayerProfileProviderComponent: React.FC<AllProps> = (props: AllProps) => {
  const {
    children,
    getPlayerProfile,
    playerProfile,
    clearPlayerProfile,
    playerModel,
    getPlayerModel,
    clearPlayerModel,
    authUser
  } = props;
  const { id } = useParams();
  const history = useHistory();
  const { data, errors } = playerProfile;
  const playerModelValue = useMemo(() => playerModel, [playerModel]);
  const playerMentorId = data?.mentor?.mentorId;
  const playerAcademyId = data?.academy?.academyId;
  const authUserId = authUser?.data?.id;
  const isAuthUserAcademy = authUser?.data?.role === EUserRole.AcademyWorker;
  const isAuthUserMentor = authUser?.data?.role === EUserRole.Mentor;
  const authUserAcademyId = authUser?.data?.academyWorker?.academy?.id;
  const isAcademyPlayer = !!authUserAcademyId && !!playerAcademyId && authUserAcademyId === playerAcademyId;
  const isMentorPlayer = !!authUserId && !!playerMentorId && authUserId === playerMentorId;
  const canGetPlayerModel = useMemo(
    () => !!data && !!authUser && (isAuthUserAcademy ? isAcademyPlayer : isAuthUserMentor ? isMentorPlayer : false),
    [data, authUser, isAuthUserAcademy, isAcademyPlayer, isMentorPlayer]
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [authProfileToken, setAuthProfileToken] = useState<string>();
  const { token, auth: authToken } = queryToObject<{ token?: string; auth?: string }>({ token: undefined, auth: undefined }) as {
    token: string | undefined;
    auth: string | undefined;
  };
  const isPlayerViewPage = history.location.pathname.includes(ERoutesPublic.PlayerView);

  useEffect(() => {
    if (authToken && isPlayerViewPage) {
      localStorage.setItem(ACCESS_PROFILE_TOKEN, authToken);
    }
  }, [authToken, isPlayerViewPage]);

  useEffect(() => {
    if (isPlayerViewPage) {
      const authProfileTokenStorage = localStorage.getItem(ACCESS_PROFILE_TOKEN);
      if (authProfileTokenStorage) {
        setAuthProfileToken(authProfileTokenStorage);
      }
    }
  }, [isPlayerViewPage]);

  useEffect(() => {
    if (authProfileToken && isPlayerViewPage) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${authProfileToken}`;
      setHeaders('coachWebView');
      getPlayerProfile({ playerId: id });
    }
    return () => clearPlayerProfile();
  }, [id, authProfileToken, isPlayerViewPage]);

  useEffect(() => {
    const checkToken = async () => {
      if (token) {
        try {
          await playerTransport.checkShareToken({ playerId: id, token });

          await getPlayerProfile({ playerId: id, token });
        } catch (e) {
          await getPlayerProfile({ playerId: id });
        }
      } else {
        await getPlayerProfile({ playerId: id });
      }
    };

    if (!isPlayerViewPage) {
      checkToken();
    }

    return () => clearPlayerProfile();
  }, [id, token]);

  useEffect(() => {
    (async () => {
      if (canGetPlayerModel) {
        await getPlayerModel(id);
      }
      data && setLoading(false);
    })();

    return () => clearPlayerModel();
  }, [canGetPlayerModel, data, id]);

  useEffect(() => {
    const status = errors?.status;

    if (status && [EErrorStatus.NotFound, EErrorStatus.Forbidden].includes(status) && !isPlayerViewPage) {
      history.replace(ERoutesCommon.Root);
    }
  }, [errors]);

  if (loading) {
    return <LoadingSpin />;
  }

  return (
    <PlayerProfileProviderContext.Provider value={{ profile: playerProfile, playerId: id, isUserAcademyPlayer: isAcademyPlayer }}>
      <PlayerProviderContext.Provider value={{ player: playerModelValue }}>{children}</PlayerProviderContext.Provider>
    </PlayerProfileProviderContext.Provider>
  );
};

export const PlayerProvider = communicationAuth.injector(communicationPlayer.injector(PlayerProfileProviderComponent));
