export enum ERequestType {
  PlayerToAcademy = 'playerToAcademy',
  AcademyToPlayer = 'academyToPlayer'
}

export interface IBaseUserDto {
  id: string;
  firstName?: string;
  lastName?: string;
}
