import React from 'react';
import Typography from 'antd/lib/typography';
import Row from 'antd/es/row';
import Modal from 'antd/es/modal/Modal';
import Button from 'antd/es/button';
import Col from 'antd/es/col';
import message from 'antd/es/message';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { EActionsTypes, getStartType } from '@axmit/redux-communications';
import { store } from 'common/helpers/axios.helper';
import { ReactComponent as LogoSmallIcon } from 'app/assets/images/LogoSmall.svg';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { SignUpPage } from 'entities/Auth/components/SignUpPage';
import { EModalMentorSignUpType } from 'entities/UI/UI.models';
import { mentorRequestTransport } from 'entities/MentorRequest/MentorRequest.transport';
import { IMentorRequestModel } from 'entities/MentorRequest/MentorRequest.models';

type AllProps = IUIConnectedProps;

const MentorSignUpModalComponent: React.FC<AllProps> = (props: AllProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { UIMentorSignUpModal, closeUIMentorSignUpModal } = props;
  const { isVisible, tokenExpired, token, modalType } = UIMentorSignUpModal.data || {};
  const isModalClosable = !tokenExpired;
  const isSignUpModal = modalType === EModalMentorSignUpType.SignUp;
  const isMentorAcceptModal = modalType === EModalMentorSignUpType.Accept;

  const acceptRequest = async () => {
    if (token) {
      try {
        const res: IMentorRequestModel = await mentorRequestTransport.acceptMentorRequest(token);
        message.success(t('Success'), 5);

        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('token');
        history.replace({
          search: queryParams.toString()
        });

        // @ts-ignore
        store.dispatch({ type: getStartType('player', 'profile', EActionsTypes.get), payload: { id: res.player.id } });
        // @ts-ignore
        store.dispatch({ type: getStartType('player', 'model', EActionsTypes.get), payload: res.player.id });

        closeUIMentorSignUpModal();
      } catch {
        message.success(t('Fail'), 5);
        closeUIMentorSignUpModal();
      }
    }
  };

  return (
    <Modal
      forceRender
      closable={isModalClosable}
      bodyStyle={{ border: 'none' }}
      width={340}
      visible={isVisible}
      onCancel={isModalClosable ? closeUIMentorSignUpModal : undefined}
      footer={false}
      title={false}
    >
      <>
        {!isMentorAcceptModal && (
          <>
            <Row justify="center" className="mb-3">
              {!isModalClosable ? (
                <LogoSmallIcon />
              ) : (
                <Typography.Title className="t-align-c font-weight-5" level={5}>
                  {t('Is this your player')}
                </Typography.Title>
              )}
            </Row>
            {isSignUpModal && (
              <Typography.Title className="t-align-c font-weight-5 mb-5" level={4}>
                {t('Sign up to save access to players digital profile')}
              </Typography.Title>
            )}
          </>
        )}

        {isMentorAcceptModal && (
          <>
            <Col span={24}>
              <Row justify="center" className="mb-3">
                <LogoSmallIcon />
              </Row>
            </Col>

            <Typography.Title className="t-align-c font-weight-5" level={4}>
              {t('Is this your player')}
            </Typography.Title>
            <Row className="mt-4" justify="center" wrap={false}>
              <Col span={16}>
                <Row justify="space-around" wrap={false}>
                  <Col span={10}>
                    <Button block onClick={closeUIMentorSignUpModal}>
                      {t('No')}
                    </Button>
                  </Col>
                  <Col span={10}>
                    <Button block onClick={acceptRequest} htmlType="submit" type="primary">
                      {t('Yes')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
        {isSignUpModal && <SignUpPage mentorRequestToken={token} closeSignUpModal={closeUIMentorSignUpModal} />}
      </>
    </Modal>
  );
};

export const MentorSignUpModal = communicationUI.injector(MentorSignUpModalComponent);
