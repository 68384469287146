import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import MessageOutlined from '@ant-design/icons/MessageOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import Button from 'antd/es/button';
import { options } from 'options';
import Typography from 'antd/es/typography';
import { ERoutesPublic } from 'common/models/routesModel';
import TgIcon from 'app/assets/icons/telegram.svg';
import WaIcon from 'app/assets/icons/whatsapp.svg';

const ICON_SIZE = 26;
const TRIGGER = ['click'] as ['click'];
const ITEM_NAME = 'junistat-help-button-closed';
const HIDDEN_VALUE = JSON.stringify(true);
const isButtonHidden = localStorage.getItem(ITEM_NAME) === HIDDEN_VALUE;

const Component: React.FC = () => {
  const [isHidden, setIsHidden] = useState<boolean>(isButtonHidden);
  const history = useHistory();
  const isPlayerViewPage = history.location.pathname.includes(ERoutesPublic.PlayerView);

  const menu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="tg">
          <img alt="Telegram Icon" src={TgIcon} height={ICON_SIZE} width={ICON_SIZE} className="mr-3" />
          <Typography.Link target="_blank" rel="noopener noreferrer" href={options.tgSupport}>
            Telegram
          </Typography.Link>
        </Menu.Item>
        <Menu.Item key="wa">
          <img alt="WhatsApp Icon" src={WaIcon} height={ICON_SIZE} width={ICON_SIZE} className="mr-3" />
          <Typography.Link target="_blank" rel="noopener noreferrer" href={options.waSupport}>
            WhatsApp
          </Typography.Link>
        </Menu.Item>
      </Menu>
    ),
    []
  );

  const onRemoveButton = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    localStorage.setItem(ITEM_NAME, HIDDEN_VALUE);
    setIsHidden(true);
  }, []);

  if (isHidden || isPlayerViewPage) {
    return null;
  }

  return (
    <Dropdown overlay={menu} trigger={TRIGGER} placement="topRight">
      <div className="help-button">
        <div className="help-button__close-icon border-circle" onClick={onRemoveButton}>
          <CloseOutlined />
        </div>
        <Button className="help-button__button border-circle" type="primary">
          <MessageOutlined />
        </Button>
      </div>
    </Dropdown>
  );
};

export const HelpButton = Component;
