import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import classNames from 'classnames';
import { useParams } from 'react-router';
import Space from 'antd/es/space';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';
import { useMediaPredicate } from 'react-media-hook';
import { Paper } from 'common/components/Paper/Paper';
import { SCREEN_LG } from 'common/const/config.const';
import { IPlayerProfileMicroSkillModel, IPlayerProfileMicroSkillTestsModel } from 'entities/Player/Player.models';
import { PlayerSkillsData } from 'entities/PlayerTest/components/PlayerSkillsData';
import { PlayerSkillsBlock } from 'entities/PlayerTest/components/PlayerSkillsBlock/PlayerSkillsBlock';
import { IPlayerConnectedProps, communicationPlayer } from 'entities/Player/Player.communication';
import { PlayerSkillsDetail } from 'entities/PlayerTest/components/PlayerSkillsDetail';
import { PlayerSkillsDevelopment } from 'entities/PlayerTest/components/PlayerSkillsDevelopment';
import { PlayerProfileProviderContext } from 'entities/Player/components/PlayerProvider';

interface IProps {
  onSkillSelectCallback(skillId: string): void;
  skillId?: string;
}

type AllProps = IProps & IPlayerConnectedProps;

const PlayerProfileSkillViewComponent: React.FC<AllProps> = props => {
  const { profile, playerId } = React.useContext(PlayerProfileProviderContext);
  const isLg: boolean = useMediaPredicate(`(min-width: ${SCREEN_LG})`);
  const { playerProfileTestResult, getPlayerProfileTestResult, onSkillSelectCallback, skillId: propsSkillId } = props;
  const { skillId: skillIdRaw } = useParams();
  const { t } = useTranslation();
  const [selectedTestId, setSelectedTestId] = useState<string | undefined>();
  const { data: player, loading } = profile ?? {};
  const { data: playerTest, loading: playerTestLoading } = playerProfileTestResult;
  const { skills } = player || {};

  const skillId = useMemo(() => skillIdRaw || propsSkillId || skills?.[0]?.skillId, [skillIdRaw, skills, propsSkillId]);
  const selectedSkill = useMemo(() => skills?.find(skill => skill?.skillId === skillId), [skillId, skills]);

  const microSkills = useMemo(() => {
    if (selectedSkill) {
      return selectedSkill?.microSkills?.reduce((acc, microSkill) => [...acc, microSkill], [] as IPlayerProfileMicroSkillModel[]);
    }

    return [];
  }, [selectedSkill]);

  const selectedTest = useMemo(
    () =>
      microSkills
        ?.reduce((acc, microSkill) => [...acc, ...microSkill.tests], [] as IPlayerProfileMicroSkillTestsModel[])
        ?.find(test => test.testId === selectedTestId),
    [microSkills, selectedTestId]
  );

  useEffect(() => {
    if (playerId && selectedTestId) {
      getPlayerProfileTestResult({
        playerId,
        testId: selectedTestId
      });
    }
  }, [playerId, selectedTestId]);

  useEffect(() => {
    if (!selectedTest?.tag && selectedSkill) {
      setSelectedTestId(selectedSkill?.microSkills?.[0]?.tests?.[0]?.testId);
    }
  }, [skills, selectedTestId, selectedSkill]);

  const onChangeSkillCallback = useCallback(
    (selectedSkillId: string) => {
      onSkillSelectCallback(selectedSkillId);
      setSelectedTestId(undefined);
    },
    [onSkillSelectCallback]
  );

  return (
    <Col xs={24} className={classNames('player-profile__skill-view', { 'px-5': !isLg })}>
      <Row className="width-full">
        <Col xs={24}>
          <Row className="player-profile__skill-view__skills">
            <PlayerSkillsBlock
              data={skills}
              selected={skillId}
              onSkillSelectCallback={onChangeSkillCallback}
              loading={loading}
              className="mb-5"
            />
          </Row>
          <Row gutter={[8, 16]}>
            <Col xs={24} md={9}>
              <Paper>
                <PlayerSkillsData
                  value={selectedTestId}
                  onSelectTest={setSelectedTestId}
                  datasets={microSkills}
                  loading={loading}
                />
              </Paper>
            </Col>
            <Col xs={24} md={15}>
              <Paper>
                <Spin spinning={playerTestLoading || loading}>
                  <Space direction="vertical" size="large" className="width-full">
                    <PlayerSkillsDetail
                      data={playerTest?.last}
                      testTag={selectedTest?.tag}
                      previewId={selectedTest?.iconId || selectedTest?.iconJcId}
                    />
                    <div>
                      <Typography.Title level={4}>{t('Player development')}</Typography.Title>
                      <PlayerSkillsDevelopment player={player} datasets={playerTest?.history} />
                    </div>
                  </Space>
                </Spin>
              </Paper>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export const PlayerProfileSkillView = communicationPlayer.injector(PlayerProfileSkillViewComponent);
