import React from 'react';
import Typography from 'antd/es/typography';
import Row from 'antd/es/row';
import Card from 'antd/es/card';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { LayoutContent } from 'common/components/Layouts/ContentLayout';
import { SCREEN_MD } from 'common/const/config.const';
import { NotificationList } from 'entities/Notification/components/List/NotificationList';

const CommonNotificationsComponent: React.FC = () => {
  const { t } = useTranslation();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${SCREEN_MD})`);

  return (
    <LayoutContent>
      <Typography.Title level={3} className={`mb-5 ${isDesktop ? '' : 'px-5'}`}>
        {t('Notifications')}
      </Typography.Title>
      <Row>
        <Card bordered={false} className="px-3 radius-default width-full card-small-py">
          <NotificationList elementId="basicLayout" />
        </Card>
      </Row>
    </LayoutContent>
  );
};

export const CommonNotificationsPage = CommonNotificationsComponent;
