import React, { useMemo } from 'react';
import classNames from 'classnames';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { Bar } from 'common/components/Bar/Bar';
import { getResultBarColor } from 'common/helpers/result-bar.helper';

interface IProps {
  value?: number | null;
  height?: number;
  bgColor?: string;
  className?: string;
  wrapperClassName?: string;
  showValue?: boolean;
  titleLevel?: 1 | 2 | 3 | 4 | 5;
}

const Component: React.FC<IProps> = props => {
  const { value, height = 12, bgColor = 'transparent', className, showValue, titleLevel = 4, wrapperClassName } = props;

  const colorClassName = useMemo(() => (value === 0 ? 'color-text-primary' : `color-${getResultBarColor(value || 0)}`), [value]);

  return (
    <Row
      className={classNames('width-full', { [wrapperClassName as string]: Boolean(wrapperClassName) })}
      justify="space-between"
      wrap={false}
      align="middle"
    >
      <Bar
        className={classNames('width-full radius-none border-none', {
          [className as string]: Boolean(className)
        })}
        value={value || 0}
        height={height}
        bgColor={bgColor}
      />
      {showValue && (
        <Typography.Title level={titleLevel} className={classNames('mb-0 ml-5', { [colorClassName]: true })}>
          {value}
        </Typography.Title>
      )}
    </Row>
  );
};

export const PlayerTestMacroSkillBar = Component;
