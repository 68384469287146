import { useFormMapper } from '@axmit/antd4-helpers';
import React from 'react';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import Input from 'antd/es/input';
import { useParams } from 'react-router';
import { requiredField } from 'common/helpers/filed-rules';
import { communicationTrainer, ITrainerConnectedProps } from 'entities/Trainer/Trainer.communication';
import { ITrainerAcademyRequestCreateParams } from 'entities/Trainer/Trainer.models';

interface IComponentProps {
  onClose: () => void;
}
type AllProps = ITrainerConnectedProps & IComponentProps;

const AcademyTrainerInviteCreateFormComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { trainerAcademyRequestModel, addTrainerAcademyRequestModel } = props;
  const { params, errors, loading } = trainerAcademyRequestModel;
  const { academyId } = useParams();

  const { fields } = useFormMapper(['email'], null, params, errors);

  const addRequest = (values: any) => {
    const { email } = values;
    if (academyId) {
      const body: ITrainerAcademyRequestCreateParams = {
        id: academyId,
        email
      };

      addTrainerAcademyRequestModel(body);
    }
  };

  return (
    <Form layout="vertical" onFinish={addRequest} fields={fields}>
      <Form.Item className="mb-5" rules={[requiredField]} name="email" label={t('Trainer email')}>
        <Input disabled={loading} type="email" size="large" />
      </Form.Item>
      <Typography.Text className="d-block mb-5">{t('Coach must accept the invitation by email')}</Typography.Text>
      <Form.Item className="mb-0">
        <Button block size="large" htmlType="submit" type="primary" disabled={loading} loading={loading}>
          {t('Send request')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export const AcademyTrainerInviteCreateForm = communicationTrainer.injector(AcademyTrainerInviteCreateFormComponent);
