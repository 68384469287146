import React, { useMemo } from 'react';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import List from 'antd/es/list';
import Col from 'antd/es/col';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { VideoPrivate } from 'common/components/Video/VideoPrivate';
import { IPlayerVideoModel } from 'entities/Player/Player.models';
import { PrivateContent } from 'entities/Auth/components/PrivateContent';
import { EUserRole } from 'entities/User/User.models';

interface IComponentProps {
  onOpenElement: (elementId: string) => void;
  isDesktop: boolean;
  item: IPlayerVideoModel;
}

type AllProps = IComponentProps;

const previewWidth = 136;
const previewWidthMobile = '100%';
const previewHeight = 116;
const previewHeightMobile = 210;

export const PlayerVideosListItem: React.FC<AllProps> = props => {
  const { onOpenElement, isDesktop, item } = props;
  const { id, title, description, preview, video, youtubeId } = item;

  const videoPreview = useMemo(() => {
    if (youtubeId) {
      return preview;
    }
    return video?.image?.id;
  }, []);

  return (
    <List.Item key={id} className={`height-full ${isDesktop ? 'pb-3' : 'pb-5'} pt-0 border-none`}>
      <Row className={`players__videos-tab__element ${isDesktop ? 'flex-noWrap' : ''}`}>
        <div
          className={`players__videos-tab__element__img-container ${isDesktop ? '' : 'mobile'} ${isDesktop ? '' : 'width-full'}`}
        >
          <VideoPrivate
            preview={videoPreview}
            width={isDesktop ? previewWidth : previewWidthMobile}
            height={isDesktop ? previewHeight : previewHeightMobile}
            videoId={video?.id}
            youtubeId={youtubeId}
            previewClass="radius-default"
          />
        </div>
        {isDesktop ? (
          <div className="players__videos-tab__element__data ml-5 pt-2 width-full">
            <Row justify="space-between" wrap={false} className="mb-2">
              <Col flex={1}>
                <Typography.Title level={5} className="mb-0 mr-2 width-full word-break-all">
                  {title}
                </Typography.Title>
              </Col>
              <PrivateContent availableFor={EUserRole.Mentor}>
                <Col>
                  <EditOutlined className="color-gray cursor-pointer" onClick={() => onOpenElement(id)} />
                </Col>
              </PrivateContent>
              <PrivateContent availableFor={EUserRole.AcademyWorker}>
                <Col>
                  <EditOutlined className="color-gray cursor-pointer" onClick={() => onOpenElement(id)} />
                </Col>
              </PrivateContent>
            </Row>
            <Typography.Paragraph className="mb-0 color-text-secondary">{description}</Typography.Paragraph>
          </div>
        ) : (
          <div className="players__videos-tab__element__data mt-3 width-full">
            <Typography.Text type="secondary" className="color-text-primary width-full word-break-all">
              {title}
            </Typography.Text>
            <Typography.Paragraph className="mb-3 color-text-secondary">{description}</Typography.Paragraph>
            <PrivateContent availableFor={EUserRole.Mentor}>
              <EditOutlined className="color-gray cursor-pointer fs-md" onClick={() => onOpenElement(id)} />
            </PrivateContent>
            <PrivateContent availableFor={EUserRole.AcademyWorker}>
              <EditOutlined className="color-gray cursor-pointer fs-md" onClick={() => onOpenElement(id)} />
            </PrivateContent>
          </div>
        )}
      </Row>
    </List.Item>
  );
};
