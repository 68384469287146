import React, { createContext, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stripe } from '@stripe/stripe-js';
import { PaymentMethod } from '@stripe/stripe-js/types/api/payment-methods';

export interface IStripeLastSavedPaymentMethod {
  paymentId: string;
  card: PaymentMethod.Card;
}

export type IStripeLastSavedPaymentListener = (lastPayment: IStripeLastSavedPaymentMethod | null) => void;

export interface IStripeContext {
  promise?: Promise<Stripe | null>;
  lang?: string;
  setLastSavedPaymentMethod?(lastSavedPaymentMethod: IStripeLastSavedPaymentMethod): void;
  lastSavedPaymentMethod: IStripeLastSavedPaymentMethod | null;
  setLoading?(loading: boolean): void;
  loading: boolean;
  addListener?(listener: IStripeLastSavedPaymentListener): void;
  clearSavedPaymentMethod?(): void;
}

interface IProps {
  promise?: Promise<Stripe | null>;
  children: ReactElement;
}

export const StripeContext = createContext<IStripeContext>({
  lastSavedPaymentMethod: null,
  loading: false
});

export const StripeProvider: React.FC<IProps> = props => {
  const [lastSavedPaymentMethod, setLastSavedPaymentMethod] = useState<IStripeLastSavedPaymentMethod | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [lastPaymentListeners, setLastPaymentListeners] = useState<IStripeLastSavedPaymentListener[]>([]);
  const { promise, children } = props;
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const onAddListenerCallback = useCallback(newListener => {
    setLastPaymentListeners(listeners => [...listeners, newListener]);
  }, []);

  const onClearSavedPaymentMethodCallback = useCallback(() => {
    setLastSavedPaymentMethod(null);
  }, []);

  useEffect(() => {
    lastPaymentListeners.forEach(listener => listener(lastSavedPaymentMethod));
  }, [lastSavedPaymentMethod]);

  const value = useMemo(
    () => ({
      promise,
      lang,
      setLastSavedPaymentMethod,
      lastSavedPaymentMethod,
      setLoading,
      loading,
      addListener: onAddListenerCallback,
      clearSavedPaymentMethod: onClearSavedPaymentMethodCallback
    }),
    [promise, lang, loading, lastSavedPaymentMethod, onAddListenerCallback, onClearSavedPaymentMethodCallback]
  );

  return <StripeContext.Provider value={value}>{children}</StripeContext.Provider>;
};
