import React from 'react';
import classNames from 'classnames';
import AntdInputNumber, { InputNumberProps } from 'antd/es/input-number';

interface IProps extends InputNumberProps {}

const DEFAULT_PRECISION = 2;
const DEFAULT_STEP = 1;
const DEFAULT_MIN = 0;
const DEFAULT_MAX = 9999;

const Component: React.FC<IProps> = props => {
  const { className, min = DEFAULT_MIN, max = DEFAULT_MAX, step = DEFAULT_STEP, precision = DEFAULT_PRECISION, ...rest } = props;

  return (
    <AntdInputNumber
      {...rest}
      step={step}
      precision={precision}
      min={min}
      max={max}
      className={classNames({ [className as string]: Boolean(className) })}
    />
  );
};

export const InputNumber = Component;
