import React, { useEffect, useState, useContext, useMemo } from 'react';
import Row from 'antd/es/row';
import Tabs from 'antd/es/tabs';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useHistory, useLocation, useRouteMatch, useParams } from 'react-router';
import Col from 'antd/es/col';
import { EPlayerSettingsTabKeys } from 'entities/Player/Player.models';
import { PlayerProviderContext } from 'entities/Player/components/PlayerProvider';
import { PlayerSettingTabStripeProfile } from 'entities/Player/components/Tabs/PlayerSettingTab/PlayerSettingTabStripe/PlayerSettingTabStripeProfile';
import { PlayerSettingTabStripeSubscription } from 'entities/Player/components/Tabs/PlayerSettingTab/PlayerSettingTabStripe/PlayerSettingTabStripeSubscription';
import { isAcademyHideTariffs } from 'entities/Subscription/helpers/subscription.helper';

interface IProps {
  isDesktop: boolean;
}

type AllProps = IProps;

const PlayerSettingTabStripeTabsComponent: React.FC<AllProps> = props => {
  const { isDesktop } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { player } = useContext(PlayerProviderContext);
  const { stripeTab: tabKey } = useParams();
  const { search } = useLocation();
  const { path, url } = useRouteMatch();
  const [searchValue, setSearchValue] = useState<string | undefined>(search);
  const [tabValue, setTabValue] = useState<EPlayerSettingsTabKeys>(tabKey || EPlayerSettingsTabKeys.Profile);
  const { isPaid } = player?.data || {};
  const hideTariffs = useMemo(() => isAcademyHideTariffs(player?.data), [player]);

  const selectTab = (key: string): EPlayerSettingsTabKeys => {
    if (isPaid || hideTariffs) {
      return EPlayerSettingsTabKeys.Profile;
    }

    switch (key) {
      case EPlayerSettingsTabKeys.Profile:
        return EPlayerSettingsTabKeys.Profile;
      case EPlayerSettingsTabKeys.Subscription:
        return EPlayerSettingsTabKeys.Subscription;

      default:
        return EPlayerSettingsTabKeys.Profile;
    }
  };

  useEffect(() => {
    replace(selectTab(tabKey));
    setTabValue(selectTab(tabKey));
  }, [tabKey]);

  const changeUrl = (newTab: string, saveSearch?: boolean) => {
    const newPathname = tabKey ? url?.replace(tabKey, newTab) : `${url}/${newTab}`;
    history.push({ pathname: newPathname, search: saveSearch ? searchValue : undefined });
  };

  const onTabChange = (tab: string) => {
    const selectedTab = selectTab(tab);
    setSearchValue(undefined);
    setTabValue(selectedTab);
    changeUrl(tab);
  };

  const replace = (tab: string) => {
    changeUrl(selectTab(tab), true);
  };

  return (
    <Row className={`${isDesktop ? 'flex-row' : 'flex-column mt-6 mobile'} player-settings-tab-stripe width-full`}>
      <Col span={24}>
        <Tabs
          className="player-settings-tab-stripe__tabs-container"
          tabPosition="top"
          onChange={onTabChange}
          activeKey={tabValue}
        >
          <Tabs.TabPane tab={t('Profile')} key={EPlayerSettingsTabKeys.Profile} />
          {!isPaid && !hideTariffs && <Tabs.TabPane tab={t('Subscription')} key={EPlayerSettingsTabKeys.Subscription} />}
        </Tabs>
        <Switch>
          <Route path={`${path}/${EPlayerSettingsTabKeys.Profile}`} exact>
            <PlayerSettingTabStripeProfile isDesktop={isDesktop} />
          </Route>
          {!isPaid && !hideTariffs && (
            <Route path={`${path}/${EPlayerSettingsTabKeys.Subscription}`} exact>
              <PlayerSettingTabStripeSubscription isDesktop={isDesktop} />
            </Route>
          )}
          <Route path={path}>
            <PlayerSettingTabStripeProfile isDesktop={isDesktop} />
          </Route>
        </Switch>
      </Col>
    </Row>
  );
};

export const PlayerSettingTabStripeTabs = PlayerSettingTabStripeTabsComponent;
