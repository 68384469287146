import i18n from 'common/helpers/i18n';

export const nameBuilder = (firstName?: string | null, lastName?: string | null, replace?: string): string =>
  firstName && lastName ? `${lastName} ${firstName}` : replace || i18n.t('Unknown name');

export const getPlayerName = (player?: { firstName?: string; lastName?: string } | null): string => {
  if (!player) {
    return i18n.t('Unknown name');
  }
  return nameBuilder(player.firstName, player.lastName);
};
