import { Moment } from 'moment';
import { IBasePathParams } from 'common/models/requestModels';
import { ITeamModel } from 'entities/Team/Team.models';
import { IAcademySearchModel } from 'entities/Academy/Academy.models';
import { EUserRole } from 'entities/User/User.models';

export interface ITrainerModel extends Components.Schemas.UserDto {}
export interface ITrainerTeamModel extends Components.Schemas.TrainerForTeamDto {}
export interface ITrainerUpdateModel extends Components.Schemas.UpdateUserDto, IBasePathParams {}
export interface ITrainerUpdateTestsBalance extends Components.Schemas.UpdateBalanceDto, IBasePathParams {}
export interface ITrainerUpdateModelForm extends Omit<ITrainerUpdateModel, 'birthday' | 'teams' | 'phone'> {
  birthday: Moment | null;
  teams?: ITeamModel[];
  phone?: string | null;
}

export interface ITrainerAcademyRequestModel extends Components.Schemas.AcademyToTrainerLinkPureDto {}
export interface ITrainerAcademyRequestCreateParams extends Components.Schemas.InviteTrainerDto, IBasePathParams {}
export interface ITrainerAcademyUpdateParams extends Components.Schemas.UpdateAcademyToTrainerLinkStatusDto, IBasePathParams {}

export interface ITrainerAcademyRequestCollection extends Components.Schemas.AcademyToTrainerLinkPureCollectionDto {}
export interface ITrainerAcademyRequestCollectionFilter extends Paths.AcademyToTrainerLinkControllerList.QueryParameters {
  teamModel?: Partial<ITeamModel>;
  academyFilter?: Partial<IAcademySearchModel>;
}
export interface ITrainerCollection extends Components.Schemas.UserCollectionDto {}
export interface ITrainerCollectionFilter extends Paths.TrainerControllerListTrainers.QueryParameters {
  teamModel?: Partial<ITeamModel>;
  roles?: EUserRole[];
}
export interface ITrainerSearchCollectionFilter extends Paths.UserControllerSearch.QueryParameters {
  teamModel?: Partial<ITeamModel>;
  roles?: EUserRole[];
}

export interface ITrainerLinkModel extends Components.Schemas.TrainerLinkDto {}
export interface ITrainerLinkDeleteParams extends IBasePathParams {
  playerId: string;
}
export interface ITrainerLinkCreateParams extends Components.Schemas.CreateTrainerLinkDto, IBasePathParams {
  player: string;
}

export type ITrainerParamsModel = ITrainerUpdateModel | ITrainerUpdateTestsBalance | ITrainerUpdateModelForm;
export type ITrainerLinkParamsModel = ITrainerLinkCreateParams;

export type ITrainerAcademyRequestParamsModel =
  | ITrainerAcademyRequestCreateParams
  | ITrainerAcademyUpdateParams
  | ITrainerUpdateTestsBalance
  | IBasePathParams;

export enum ETrainerAcademyRequestStatus {
  New = 'new',
  Active = 'active',
  Canceled = 'canceled'
}

export enum ETrainerLinkStatus {
  Active = 'active',
  Canceled = 'canceled'
}

export enum ETrainerSuccessMessage {
  Update = 'Trainer information has been successfully updated',
  ResendInvitation = 'Invitation was successfully resend'
}

export enum ETrainerActionTypes {
  AbortRequest = 'abortRequest',
  UnlinkFromTeam = 'unlinkFromTeam'
}
