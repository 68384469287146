import { IBasePathParams } from 'common/models/requestModels';

export interface ISubscriptionModel extends Components.Schemas.SubscriptionDto {}
export interface ISubscriptionCollection extends Components.Schemas.SubscriptionCollectionDto {}
export interface ISubscriptionCollectionFilter extends Paths.SubscriptionControllerList.QueryParameters {}

export interface ISubscriptionPhaseModel extends Components.Schemas.SubscriptionPhaseDto {}

export interface ISubscriptionCreate extends Components.Schemas.CreateSubscriptionDto {
  isTest?: boolean;
  onFail?(): void;
  onSuccess?(): void;
}
export interface ISubscriptionUpdate extends Components.Schemas.UpdateSubscriptionDto, IBasePathParams {
  onFail?(): void;
  engine?: ESubscriptionsEngines;
}

export interface IPaymentRequestParams extends Omit<Components.Schemas.CreateSubscriptionActivityPaymentDto, 'returnUrl'> {
  subscriptionId: string;
  tariffId?: string;
  returnUrl?: string;
}
export interface ISubscriptionActivityModel extends Components.Schemas.SubscriptionActivityDto {}
export interface ISubscriptionActivityApplyGiftcard extends Components.Schemas.CreateSubscriptionActivityGiftcardDto {
  subscriptionId: string;
  engine?: ESubscriptionsEngines;
  onSuccess?(): void;
}
export interface ISubscriptionPromoCodeInfo extends Components.Schemas.StripeCouponDto {}
export interface ISubscriptionPromoCodeInfoParams extends Paths.SubscriptionControllerGetStripePromotionCode.PathParameters {}

export interface ISubscriptionActivityCollection extends Components.Schemas.SubscriptionActivityCollectionDto {}
export interface ISubscriptionActivityCollectionFilter extends Paths.SubscriptionActivityControllerList.QueryParameters {}

export interface ISubscriptionChangeTariffParams extends Components.Schemas.UpdateSubscriptionTariffDto {
  subscriptionId: string;
  paymentMethodToken?: string;
  onFail?(): void;
  engine?: ESubscriptionsEngines;
  isSameTariff?: boolean;
}

export type TSubscriptionParamsModel = ISubscriptionCreate | ISubscriptionUpdate | IBasePathParams;
export type TSubscriptionActivityParamsModel = IPaymentRequestParams | ISubscriptionActivityApplyGiftcard | IBasePathParams;

export interface ISubscriptionPaymentMethod extends Components.Schemas.SubscriptionPaymentMethodDto {}

export interface IStripeNextTariff {
  id: string;
  periodStart?: string;
}

export interface IStripeNextWithdrawal {
  amount?: number;
  currency?: string;
  date: string;
}

export enum ESubscriptionInvoiceType {
  Giftcard = 'giftcard',
  Payment = 'payment'
}

export enum ESubscriptionStatus {
  New = 'new',
  Active = 'active',
  Expired = 'expired',
  Canceled = 'canceled'
}

export enum ESubscriptionFailMessages {
  ErrorProcessingPayment = 'Error processing payment',
  ErrorApplyGiftcard = 'Error while activating giftcard'
}

export enum ESubscriptionActivityStatus {
  New = 'new',
  Confirmed = 'confirmed',
  Canceled = 'canceled'
}

export enum EProcessings {
  Yookassa = 'yookassa',
  Internal = 'internal'
}

export enum ESubscriptionsEngines {
  Yookassa = 'yookassa',
  Stripe = 'stripe'
}

export type TProcessings = 'yookassa' | 'internal' | 'stripe';

export enum EPaymentActivitiesStatus {
  New = 'new',
  Confirmed = 'confirmed',
  Canceled = 'canceled'
}

export enum EPaymentActivitiesTypes {
  Giftcard = 'giftcard',
  Payment = 'payment'
}

export enum EPaymentAndSubscriptionsTabKeys {
  Subscriptions = 'subscriptions',
  PaymentHistory = 'payment-history'
}
export enum EPaymentAndSubscriptionsTabName {
  Subscriptions = 'subscriptions',
  PaymentHistory = 'paymentHistory'
}

export enum EPaymentActivityErrorCodes {
  YookassaExternal = 'error.yookassaExternalException'
}

export enum EStripeErrors {
  SubscriptionIsBusy = 'error.stripeSubscriptionScheduleIsBusyException',
  PromotionCodeNotFound = 'error.stripePromotionCodeNotFoundException',
  PromotionCodeInactive = 'error.stripePromotionCodeInactiveException',
  CouponInactive = 'error.stripeCouponInactiveException',
  CurrencyMismatch = 'error.stripeCurrencyOfCustomerAndSubscriptionMismatchException'
}

export const STRIPE_ERRORS = {
  promotionCodeNotFound: EStripeErrors.PromotionCodeNotFound,
  promotionCodeInactive: EStripeErrors.PromotionCodeInactive,
  couponInactive: EStripeErrors.CouponInactive,
  currencyMismatch: EStripeErrors.CurrencyMismatch
};
