import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import { useMediaPredicate } from 'react-media-hook';
import { queryToObject } from 'common/helpers/filters.helper';
import { SCREEN_MD } from 'common/const/config.const';
import { ESubtitleTypes } from 'entities/Test/components/List/TestListItem';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { TestResultModal } from 'entities/Academy/components/View/Modal/TestResultModal';
import { initTestResultFilter, ITestModel, ITestResultsHistoryCollectionFilter } from 'entities/Test/Test.models';
import { AcademyModelProviderContext } from 'entities/Academy/components/View/Card/AcademyViewCardProvider';
import { TestSkillCardList } from 'entities/Test/components/List/TestSkillCardList';

interface IComponentProps {
  isDesktop: boolean;
  isRFS?: boolean;
}

type AllProps = IComponentProps & IUIConnectedProps;

const AcademyTestListTabComponent: React.FC<AllProps> = props => {
  const { openUITestResultModal, isRFS } = props;
  const { t } = useTranslation();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${SCREEN_MD})`);
  const { academyModel } = useContext(AcademyModelProviderContext);
  const academyModelData = academyModel?.data;

  useEffect(() => {
    const params = queryToObject<Partial<ITestResultsHistoryCollectionFilter>>(initTestResultFilter);
    if (params) {
      openUITestResultModal({ params });
    }
  }, []);

  const openTestResultModal = ({ id, tag, baseResultsForView }: ITestModel) => {
    openUITestResultModal({
      params: {
        id,
        onlyMy: true,
        testTag: tag,
        tags: baseResultsForView,
        academy: { id: academyModelData?.id, name: academyModelData?.name }
      }
    });
  };

  return (
    <>
      <Row className={`pb-5 ${!isDesktop ? 'px-3' : ''}`} justify="space-between" align="middle">
        <Typography.Title className="mb-0" level={isDesktop ? 2 : 3}>
          {t('Test results')} {isRFS && t('RFS')}
        </Typography.Title>
      </Row>
      <Col xs={24}>
        <Row className={`height-full ${isDesktop ? '' : 'px-5'}`}>
          <TestSkillCardList
            hideComplexity
            onItemClick={openTestResultModal}
            subtitle={ESubtitleTypes.ShortDescription}
            isRFS={isRFS}
          />
        </Row>
      </Col>
      <TestResultModal />
    </>
  );
};

export const AcademyTestListTab = communicationUI.injector(AcademyTestListTabComponent);
