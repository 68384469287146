import { AbstractSearchableSelector } from 'common/components/Input/AbstractSearchableSelector';
import { IAcademySearchModel, IAcademySearchCollectionFilter, EAcademyStatus } from 'entities/Academy/Academy.models';
import { academyTransport } from 'entities/Academy/Academy.transport';

interface IComponentProps {
  filters?: IAcademySearchCollectionFilter;
}

export class AcademySelector extends AbstractSearchableSelector<IAcademySearchModel, IComponentProps> {
  getItemLabel = (item: IAcademySearchModel): string => {
    return item.name || item.id;
  };

  loadData = (value?: string): Promise<{ data: IAcademySearchModel[] }> => {
    const { filters = {} } = this.props;
    const filter: IAcademySearchCollectionFilter = { status: EAcademyStatus.Active, ...filters };
    if (value) {
      filter.name = value;
    }
    return academyTransport.getAcademySearchCollection(filter);
  };
}
