import React, { useCallback } from 'react';
import List from 'antd/es/list';
import { Link } from 'react-router-dom';
import { ETrainerAcademyRequestStatus, ITrainerAcademyRequestModel } from 'entities/Trainer/Trainer.models';
import { TrainerCard } from 'entities/Trainer/components/Card/TrainerCard';
import { EAcademyViewTabKeys } from 'entities/Academy/Academy.models';

interface IComponentProps {
  item: ITrainerAcademyRequestModel;
}

type AllProps = IComponentProps;

const TrainerAcademyListItemComponent: React.FC<AllProps> = props => {
  const { item } = props;
  const { trainer, ...trainerAcademyRequest } = item || {};

  const checkIfHaveAccess = useCallback(
    e => {
      if (trainerAcademyRequest?.status !== ETrainerAcademyRequestStatus.Active) {
        e?.preventDefault();
      }
    },
    [trainerAcademyRequest]
  );

  return (
    <List.Item key={trainer?.id}>
      <Link onClick={checkIfHaveAccess} to={`${EAcademyViewTabKeys.Trainers}/${trainer?.id}`}>
        <TrainerCard trainer={trainer} trainerAcademyRequest={trainerAcademyRequest} />
      </Link>
    </List.Item>
  );
};

export const TrainerAcademyListItem = TrainerAcademyListItemComponent;
