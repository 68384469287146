import { all, takeEvery } from 'redux-saga/effects';
import message from 'antd/es/message';
import { EActionsTypes, getFailType } from '@axmit/redux-communications';
import { translateErrors, getErrorMessageByCode } from 'common/helpers/translate.helper';
import { EErrorStatus } from 'common/models/requestModels';
import i18n from 'common/helpers/i18n';
import { ECommonMessages } from 'common/const/i18n.const';
import { history } from 'common/helpers/axios.helper';
import { ERoutesCommon } from 'common/models/routesModel';
import { clearAuth, communicationAuth, refreshAuthAcademy, refreshAuthUser } from 'entities/Auth/Auth.communication';
import { communicationUser } from 'entities/User/User.communication';
import { communicationAcademy } from 'entities/Academy/Academy.communication';
import { communicationMentorRequest } from 'entities/MentorRequest/MentorRequest.communication';
import { communicationUI } from 'entities/UI/UI.communication';
import { communicationPlayer } from 'entities/Player/Player.communication';
import { communicationPublicPlayer } from 'entities/PublicPlayer/PublicPlayer.communication';
import { communicationPlayerTest } from 'entities/PlayerTest/PlayerTest.communication';
import { communicationSubscription, subscriptionNamespace } from 'entities/Subscription/Subscription.communication';
import { communicationTest } from 'entities/Test/Test.communication';
import { communicationSkill } from 'entities/Skill/Skill.communication';
import { communicationAcademyRequest } from 'entities/AcademyRequest/AcademyRequest.communication';
import { communicationTariff } from 'entities/Tariff/Tariff.communication';
import { communicationReferralCode } from 'entities/ReferralCode/ReferralCode.communication';
import { communicationNotification } from 'entities/Notification/Notification.communication';
import { communicationShare } from 'entities/Share/Share.communication';
import { communicationCommon } from 'entities/Common/Common.communication';
import { communicationFranchise } from 'entities/Franchise/Franchise.communication';
import { communicationTrainer } from 'entities/Trainer/Trainer.communication';
import { communicationPrivatePlayer } from 'entities/PrivatePlayer/PrivatePlayer.communication';
import { communicationLanguage } from 'entities/Language/Language.communication';
import { communicationInfluencer } from 'entities/Influencer/Influencer.communication';
import { communicationTeam } from 'entities/Team/Team.communication';
import { EStripeErrors, STRIPE_ERRORS } from 'entities/Subscription/Subscription.models';
import { communicationStatistic } from 'entities/Statistic/Statistic.communication';

const EXCLUDED_ERRORS_CODE: string[] = [...Object.values(STRIPE_ERRORS)];

function* errorWatcher() {
  yield takeEvery('*', function* logger(action: any) {
    if (action.type.match('FAIL')) {
      const { status, data } = action.payload || {};
      const code = data?.code;

      if (!EXCLUDED_ERRORS_CODE.includes(data && data.code)) {
        switch (status) {
          case EErrorStatus.BadRequest:
            if (code === 'error.stripeExternalException' || code === 'error.yookassaExternalException') {
              message.error(translateErrors('yookassaExternalException'));
              break;
            }
            break;
          case EErrorStatus.Validation:
            break;
          case EErrorStatus.Forbidden:
            if (action.type === getFailType(subscriptionNamespace, 'model', EActionsTypes.update)) {
              break;
            }
            if (data.message === 'User is blocked' || data.message === 'User is banned') {
              message.error(translateErrors('User is banned'));
            }
            if (code === 'error.playerToTestNotEnoughForAcademyRequestException') {
              message.error(translateErrors('playerToTestNotEnoughForAcademyRequestException'));
              break;
            }
            if (code === 'error.rfsAcademyNotFoundException') {
              message.error(translateErrors('rfsAcademyNotFoundException'));
              break;
            }
            if (code === 'error.playerAlreadyExistsException' || code === 'error.playerAlreadyExistsInAcademyException') {
              message.error(translateErrors('playerAlreadyExistsException'));
              break;
            }
            if (code === 'error.tooManySmsException') {
              message.error(translateErrors('tooManySmsException'));
              break;
            }
            if (code === 'error.userIsNotAcademyWorkerException') {
              message.error(translateErrors(status));
              history.push(ERoutesCommon.Root);
              break;
            }
            if (data.message === 'Academy is blocked') {
              message.error(translateErrors('Academy is blocked'));
              yield refreshAuthAcademy();
              yield refreshAuthUser();
            }
            message.error(getErrorMessageByCode(data.code) || translateErrors(`${status}`));
            break;
          case EErrorStatus.NotFound:
            message.error(translateErrors('The requested resource was not found'));
            break;
          case EErrorStatus.BadGateway:
          case EErrorStatus.ServiceUnavailable:
            message.error(translateErrors('Service is temporarily unavailable, please try again later'));
            break;
          case EErrorStatus.Unauthorized:
            yield clearAuth();
            break;
          case EErrorStatus.PaymentRequired:
            const translateKey = data?.message?.split?.('.')?.[0] || data?.message || data;
            message.error(translateErrors(translateKey));
            break;
          case EErrorStatus.NotConfirmedEmail:
            message.warning(translateErrors('Please check your email and confirm account!'));
            break;
          case EErrorStatus.InternalServerError:
            message.warning(translateErrors('Something went wrong'));
            break;
          case EErrorStatus.Conflict:
            if (data?.code === EStripeErrors.SubscriptionIsBusy) {
              message.warning(i18n.t(ECommonMessages.PreviousRequestExecuting));
              break;
            }

            const errorText = i18n.exists(data?.message) ? data.message : 'An accepted similar request';

            message.warning(translateErrors(errorText));
            break;
          default:
            message.warning(JSON.stringify(action.payload));
            break;
        }
      }
      console.log('ERROR', action.payload);
    }
  });
}

export default function* rootSaga(): any {
  yield all([
    errorWatcher(),
    ...communicationAuth.sagas,
    ...communicationUser.sagas,
    ...communicationMentorRequest.sagas,
    ...communicationUI.sagas,
    ...communicationPlayer.sagas,
    ...communicationPlayerTest.sagas,
    ...communicationAcademy.sagas,
    ...communicationSubscription.sagas,
    ...communicationSkill.sagas,
    ...communicationTest.sagas,
    ...communicationAcademyRequest.sagas,
    ...communicationTariff.sagas,
    ...communicationNotification.sagas,
    ...communicationShare.sagas,
    ...communicationReferralCode.sagas,
    ...communicationPublicPlayer.sagas,
    ...communicationCommon.sagas,
    ...communicationFranchise.sagas,
    ...communicationTrainer.sagas,
    ...communicationPrivatePlayer.sagas,
    ...communicationLanguage.sagas,
    ...communicationInfluencer.sagas,
    ...communicationTeam.sagas,
    ...communicationStatistic.sagas
  ]);
}
