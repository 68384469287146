import { AnyAction, Reducer } from 'redux';
import { RouterState } from 'react-router-redux';
import { communicationAuth, IAuthStoreProps } from 'entities/Auth/Auth.communication';
import { communicationUser } from 'entities/User/User.communication';
import { communicationAcademy } from 'entities/Academy/Academy.communication';
import { communicationMentorRequest } from 'entities/MentorRequest/MentorRequest.communication';
import { communicationUI } from 'entities/UI/UI.communication';
import { communicationPlayer } from 'entities/Player/Player.communication';
import { communicationPublicPlayer } from 'entities/PublicPlayer/PublicPlayer.communication';
import { communicationPlayerTest } from 'entities/PlayerTest/PlayerTest.communication';
import { communicationTest } from 'entities/Test/Test.communication';
import { communicationSubscription } from 'entities/Subscription/Subscription.communication';
import { communicationSkill } from 'entities/Skill/Skill.communication';
import { communicationAcademyRequest } from 'entities/AcademyRequest/AcademyRequest.communication';
import { communicationTariff } from 'entities/Tariff/Tariff.communication';
import { communicationReferralCode } from 'entities/ReferralCode/ReferralCode.communication';
import { communicationShare } from 'entities/Share/Share.communication';
import { communicationNotification, INotificationStoreProps } from 'entities/Notification/Notification.communication';
import { communicationCommon } from 'entities/Common/Common.communication';
import { communicationFranchise } from 'entities/Franchise/Franchise.communication';
import { communicationPrivatePlayer, IPrivatePlayerStoreProps } from 'entities/PrivatePlayer/PrivatePlayer.communication';
import { communicationTrainer, ITrainerStoreProps } from 'entities/Trainer/Trainer.communication';
import { communicationLanguage } from 'entities/Language/Language.communication';
import { communicationInfluencer } from 'entities/Influencer/Influencer.communication';
import { communicationTeam } from 'entities/Team/Team.communication';
import { communicationStatistic } from 'entities/Statistic/Statistic.communication';

type RoutingReducer = Reducer<RouterState, AnyAction>;

export interface IApplicationState {
  routing?: RoutingReducer | null;
  auth: IAuthStoreProps;
  notification: INotificationStoreProps;
  trainer: ITrainerStoreProps;
  privatePlayer: IPrivatePlayerStoreProps;
  [key: string]: any;
}

const reducers = {
  ...communicationNotification.reducers,
  ...communicationAuth.reducers,
  ...communicationUser.reducers,
  ...communicationMentorRequest.reducers,
  ...communicationUI.reducers,
  ...communicationPlayer.reducers,
  ...communicationPlayerTest.reducers,
  ...communicationAcademy.reducers,
  ...communicationSubscription.reducers,
  ...communicationSkill.reducers,
  ...communicationTest.reducers,
  ...communicationAcademyRequest.reducers,
  ...communicationTariff.reducers,
  ...communicationShare.reducers,
  ...communicationReferralCode.reducers,
  ...communicationPublicPlayer.reducers,
  ...communicationFranchise.reducers,
  ...communicationTrainer.reducers,
  ...communicationPrivatePlayer.reducers,
  ...communicationCommon.reducers,
  ...communicationLanguage.reducers,
  ...communicationInfluencer.reducers,
  ...communicationStatistic.reducers,
  ...communicationTeam.reducers
};

export default reducers;
