import AsyncStorage from '@react-native-async-storage/async-storage';

export const randomHashString = (i: number) => {
  let rnd = '';
  while (rnd.length < i) {
    rnd += Math.random()
      .toString(36)
      .substring(2);
  }
  return rnd.substring(0, i);
};

export const getSessionId = async () => {
  let sessionId = await AsyncStorage.getItem('session_id');

  if (!sessionId) {
    sessionId = randomHashString(8);
    await AsyncStorage.setItem('session_id', sessionId);
  }
  return sessionId;
};
