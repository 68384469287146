/* eslint-disable no-bitwise */

export interface IDecodeGiftcardInterface {
  period?: number;
  isGiftcardOk: boolean;
}

const crc4 = (data: string): number => {
  const POLY = 0xc;
  const INIT = 0;
  const XOROUT = 0;
  let crc = INIT;

  for (let i = 0; i < data.length; i++) {
    crc = crc ^ data.charCodeAt(i);
    for (let j = 0; j < 8; j++) {
      crc = crc & 1 ? (crc >>> 1) ^ POLY : crc >>> 1;
    }
  }
  return (crc ^ XOROUT) & 0xf;
};

function decodeGiftcardNumberDig16(number: string): IDecodeGiftcardInterface {
  const rand = number.substr(0, 13);
  const suffix = number.substr(13);
  const amount = ((+suffix & 0b111110000) >>> 4) + 1;
  const crc = crc4(`${rand}${amount}`);

  return {
    isGiftcardOk: crc === (+suffix & 0b1111),
    period: amount
  };
}

function decodeGiftcardNumberDig6(number: string): IDecodeGiftcardInterface {
  const _number = Number.parseInt(number, 36);
  const amount = (_number & 0b1111) + 1;
  return {
    isGiftcardOk: true,
    period: amount
  };
}

export const decodeGiftcardNumber = (number: string): IDecodeGiftcardInterface => {
  if (!number) {
    return { isGiftcardOk: true };
  }

  if (number.length === 16) {
    return decodeGiftcardNumberDig16(number);
  } else if (number.length === 6) {
    return decodeGiftcardNumberDig6(number);
  }

  return { isGiftcardOk: false };
};
