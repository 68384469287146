import React from 'react';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Spin from 'antd/es/spin';
import Typography from 'antd/es/typography';
import CameraOutlined from '@ant-design/icons/CameraOutlined';
import { useTranslation } from 'react-i18next';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { nameBuilder } from 'common/helpers/name.helper';
import { PlayerProfileProviderContext } from 'entities/Player/components/PlayerProvider';

interface IComponentProps {}

type AllProps = IComponentProps;

const avatarSize = 64;
const defaultAvatarStyle = { width: avatarSize, height: avatarSize, fontSize: avatarSize / 2 };

const PlayerContactTabComponent: React.FC<AllProps> = () => {
  const { t } = useTranslation();
  const { profile } = React.useContext(PlayerProfileProviderContext);
  const { data, loading } = profile ?? {};
  const { mentor } = data || {};
  const { image, email, phone, firstName, lastName } = mentor ?? {};
  const mentorName = nameBuilder(firstName, lastName);

  return (
    <Card bordered={false} className="px-3 radius-default">
      <Spin spinning={loading}>
        {mentor ? (
          <Col>
            <Row>
              <Typography.Title level={4}> {t('Representative contacts')} </Typography.Title>
            </Row>
            <Row gutter={16}>
              <Col>
                {image?.id ? (
                  <ImagePrivate
                    alt={t('Mentor avatar')}
                    width={avatarSize}
                    height={avatarSize}
                    className="border-circle"
                    imgId={image?.id}
                  />
                ) : (
                  <Row justify="center" align="middle" className="bg-component-third radius-round" style={defaultAvatarStyle}>
                    <CameraOutlined />
                  </Row>
                )}
              </Col>
              <Col>
                <Row>
                  <Typography.Text strong className="mb-0">
                    {mentorName}
                  </Typography.Text>
                </Row>
                <Row>
                  <Typography.Paragraph type="secondary" className="mb-0">
                    {phone}
                  </Typography.Paragraph>
                </Row>
                <Row>
                  <Typography.Paragraph type="secondary" className="mb-0">
                    {email}
                  </Typography.Paragraph>
                </Row>
              </Col>
            </Row>
          </Col>
        ) : (
          <Row className="width-full" justify="center" align="middle">
            <Typography.Text>{t('Contacts are hidden by privacy settings')}</Typography.Text>
          </Row>
        )}
      </Spin>
    </Card>
  );
};

export const PlayerContactTab = PlayerContactTabComponent;
