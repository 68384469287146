import React, { useMemo } from 'react';
import Row from 'antd/es/row';
import List, { ListGridType } from 'antd/es/list';
import { useMediaPredicate } from 'react-media-hook';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { PrivatePlayerListItemSkeleton } from 'entities/PrivatePlayer/components/List/PrivatePlayersListItemSkeleton';

interface IProps {
  grid?: ListGridType;
}

type AllProps = IProps;

const PrivatePlayersListSkeletonComponent: React.FC<AllProps> = ({ grid }) => {
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const playersPlaceholderArray = useMemo(() => {
    const array = [];
    for (let i = 0; i < 3; i++) {
      array.push({ index: i });
    }
    return array;
  }, []);

  return (
    <Row justify="center" align="middle" className="width-full">
      <List
        grid={grid}
        itemLayout="vertical"
        className="width-full"
        dataSource={playersPlaceholderArray}
        renderItem={({ index }: { index: number }) => (
          <List.Item key={index} className={`height-full ${isDesktop ? 'pb-3' : 'pb-5'} pt-0 border-none`}>
            <PrivatePlayerListItemSkeleton />
          </List.Item>
        )}
      />
    </Row>
  );
};

export const PrivatePlayersListSkeleton = PrivatePlayersListSkeletonComponent;
