import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  ITrainerAcademyRequestCreateParams,
  ITrainerAcademyRequestModel,
  ITrainerAcademyRequestCollection,
  ITrainerAcademyRequestCollectionFilter,
  ITrainerAcademyUpdateParams,
  ITrainerUpdateTestsBalance,
  ITrainerLinkCreateParams,
  ITrainerLinkDeleteParams,
  ITrainerCollectionFilter,
  ITrainerCollection,
  ITrainerModel,
  ITrainerSearchCollectionFilter
} from 'entities/Trainer/Trainer.models';

const basePath = '/trainers';

class TrainerTransport extends BaseHttpTransport<
  AxiosStatic,
  ITrainerModel,
  ITrainerCollection,
  null,
  null,
  ITrainerCollectionFilter
> {
  public addTrainerAcademyRequestModel = ({
    id,
    ...body
  }: ITrainerAcademyRequestCreateParams): Promise<ITrainerAcademyRequestModel> => axios.put(`academies/${id}/trainers`, body);

  public getTrainerAcademyRequestModel = (id: string): Promise<void> => axios.get(`/academy-to-trainer-requests/${id}`);

  public getTrainerAcademyRequestCollection = (
    filter?: ITrainerAcademyRequestCollectionFilter
  ): Promise<ITrainerAcademyRequestCollection> => axios.get(`/academy-to-trainer-requests${objectToQuery(filter)}`);

  public updateStatusTrainerAcademyRequest = ({ id, ...rest }: ITrainerAcademyUpdateParams): Promise<void> =>
    axios.put(`/academy-to-trainer-requests/${id}`, rest);

  public updateBalanceTrainer = ({ id, ...rest }: ITrainerUpdateTestsBalance): Promise<void> =>
    axios.put(`${basePath}/${id}/balance`, rest);

  public addTrainerLink = ({ id, ...rest }: ITrainerLinkCreateParams): Promise<void> =>
    axios.put(`${basePath}/${id}/players`, rest);

  public deleteTrainerLink = ({ id, playerId }: ITrainerLinkDeleteParams): Promise<void> =>
    axios.delete(`${basePath}/${id}/players/${playerId}`);

  public getTrainerSearch = (params: ITrainerSearchCollectionFilter): Promise<ITrainerCollection> =>
    axios.get(`users/search${objectToQuery(params)}`);
}

export const trainerTransport = new TrainerTransport(basePath, axios);
