import { Moment } from 'moment';
import { IBasePathParams } from 'common/models/requestModels';

export interface IUserModel extends Components.Schemas.UserDto {}
export interface IUserPureModel extends Components.Schemas.UserLiteDto {}
export interface IUserUpdateModel extends Components.Schemas.UpdateUserDto, IBasePathParams {}
export interface IUserCreateParams extends Components.Schemas.CreateUserDto {}
export interface IUserConfirmParams extends Components.Schemas.JoinUserDto {
  silent?: boolean;
  playerIdRedirect?: string;
}

export interface IUserWithPlayerSignUpUserModel extends Components.Schemas.CreateUserWithPlayerUserItem {}
export interface IUserWithPlayerSignUpPlayerModel extends Components.Schemas.CreateUserWithPlayerPlayerItem {}

export interface IUserWithPlayerSignUpParams extends Components.Schemas.CreateUserWithPlayerDto {
  ac?: any;
}
// Partial<Paths.UserControllerCreateMentorWithPlayer.QueryParameters>
export interface IUserWithPlayerSignUpModel extends Components.Schemas.CheckInviteMentorsTokenDto {}
export interface IUserPaymentMethod extends Components.Schemas.SubscriptionPaymentMethodDto {}
export interface IUserUpdatePasswordParams extends Components.Schemas.UpdatePasswordDto, IBasePathParams {}
export type TUserParamsModel =
  | IUserCreateParams
  | IUserUpdateModel
  | IUserConfirmParams
  | IUserUpdatePasswordParams
  | IBasePathParams;

export interface IUserCollection extends Components.Schemas.UserCollectionDto {}
export interface IUserCollectionFilter extends Paths.AdminControllerList.QueryParameters {
  pageIndex?: number;
}

export interface IUserUpdateModelForm extends Omit<IUserUpdateModel, 'birthday' | 'phone'> {
  birthday?: Moment | null;
  phone?: string | null;
}

export type TUserRole = 'admin' | 'dataTagger' | 'mentor' | 'academyWorker' | 'trainer' | undefined;
export enum EUserRole {
  Mentor = 'mentor',
  AcademyWorker = 'academyWorker',
  Trainer = 'trainer',
  DataTagger = 'dataTagger',
  Admin = 'admin'
}
export enum EUserStatus {
  Active = 'active',
  Banned = 'banned'
}

export enum EUserSuccessMessage {
  Add = 'Check email and confirm account',
  EditPassword = 'Password has been successfully changed',
  Confirm = 'Account has been successfully confirmed',
  Update = 'Profile information has been successfully updated'
}

export enum EUserFailMessage {
  Update = 'Error while updating profile information',
  EditPassword = 'Error while updating password'
}

export enum EUserGender {
  Male = 'male',
  Female = 'female'
}

export enum EUserGenderText {
  Male = 'Male',
  Female = 'Female'
}

export enum EUserRepresentationType {
  Parent = 'parent'
}

export enum EUserActionsTypes {
  updatePassword = 'updatePassword'
}
