import { useFormMapper } from '@axmit/antd4-helpers';
import React from 'react';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import Input from 'antd/es/input';
import { requiredField } from 'common/helpers/filed-rules';
import { communicationFranchise, IFranchiseConnectedProps } from 'entities/Franchise/Franchise.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { IFranchiseRequestCreate } from 'entities/Franchise/Franchise.models';

interface IComponentProps {
  onClose: () => void;
}
type AllProps = IFranchiseConnectedProps & IAuthConnectedProps & IComponentProps;

const FranchiseInviteCreateFormComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { franchiseRequestModel, addFranchiseRequestModel, authAcademy } = props;
  const { params, errors, loading } = franchiseRequestModel;
  const franchiseId = authAcademy?.data?.franchiseRequest?.franchise?.id;

  const { fields } = useFormMapper(['email'], null, params, errors);

  const addMentorRequest = (values: any) => {
    const { email } = values;
    if (franchiseId) {
      const body: IFranchiseRequestCreate = {
        id: franchiseId,
        email
      };

      addFranchiseRequestModel(body);
    }
  };

  return (
    <Form layout="vertical" onFinish={addMentorRequest} fields={fields}>
      <Form.Item className="mb-5" rules={[requiredField]} name="email" label={t('Academy worker email')}>
        <Input disabled={loading} type="email" size="large" />
      </Form.Item>
      <Typography.Text className="d-block mb-5">{t('Academy worker must accept the invitation by email')}</Typography.Text>
      <Form.Item className="mb-0">
        <Button block size="large" htmlType="submit" type="primary" disabled={loading} loading={loading}>
          {t('Send request')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export const FranchiseInviteCreateForm = communicationAuth.injector(
  communicationFranchise.injector(FranchiseInviteCreateFormComponent)
);
