import { IBasePathParams } from 'common/models/requestModels';

export interface IAcademyRequestModel extends Components.Schemas.AcademyRequestDto {}
export interface IAcademyRequestLiteModel extends Components.Schemas.AcademyRequestForPlayerLiteDto {}
export interface IAcademyRequestUpdateParams
  extends Components.Schemas.UpdateAcademyRequestStatusDto,
    Paths.AcademyRequestControllerSetStatus.PathParameters {
  modelsToUpdate?: EAcademyRequestSuccessUpdateModels[];
  playerId?: string;
  optimisticUpdate?: boolean;
}

export interface IAcademyRequestAcademyToPlayerParams extends Components.Schemas.CreateAcademyRequestFromAcademyToPlayerDto {
  modelsToUpdate?: EAcademyRequestSuccessUpdateModels[];
  optimisticUpdate?: boolean;
}
export interface IAcademyRequestPlayerToAcademyParams extends Components.Schemas.CreateAcademyRequestFromPlayerToAcademyDto {}

export interface IAcademyRequestCollection extends Components.Schemas.AcademyRequestCollectionDto {}
export interface IAcademyRequestCollectionFilter extends Paths.AcademyRequestControllerList.QueryParameters {
  address?: string;
  name?: string;
  ageGroup?: string;
}

export interface IAcademyRequestModelParams
  extends IAcademyRequestUpdateParams,
    // IAcademyRequestAcademyToPlayerParams, // conflicts with IAcademyRequestPlayerToAcademyParams
    IAcademyRequestPlayerToAcademyParams,
    IBasePathParams {}

export enum EAcademyRequestStatus {
  New = 'new',
  Declined = 'declined',
  Aborted = 'aborted',
  Accepted = 'accepted'
}

export enum EAcademyRequestSuccessUpdateModels {
  Profile = 'profile',
  Profiles = 'profiles',
  Player = 'player',
  Players = 'players'
}
