import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  IPublicPlayerCollection,
  IPublicPlayerCollectionFilter,
  IPublicPlayerCreateParams,
  IPublicPlayerModel,
  IPublicPlayerUpdateParams
} from 'entities/PublicPlayer/PublicPlayer.models';

const basePath = '/public/players';

class PublicPlayerTransport extends BaseHttpTransport<
  AxiosStatic,
  IPublicPlayerModel,
  IPublicPlayerCollection,
  IPublicPlayerCreateParams,
  IPublicPlayerUpdateParams,
  IPublicPlayerCollectionFilter
> {}

export const publicPlayerTransport = new PublicPlayerTransport(basePath, axios);
