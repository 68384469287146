import { clearCreds, getCreds, saveCreds } from 'axios-patch-jwt';
import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  getStartType,
  StoreBranch,
  getSuccessType,
  IAction
} from '@axmit/redux-communications';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import message from 'antd/es/message';
import { EErrorStatus, IError } from 'fe-error-helper';
import { translateErrors, translateToasts } from 'common/helpers/translate.helper';
import { ERoutesCommon, ERoutesMentor, ERoutesPublic } from 'common/models/routesModel';
import { roleHelper } from 'common/helpers/role.helper';
import { SentryTagsHelper } from 'common/helpers/sentry.helper';
import { history, setHeaders } from 'common/helpers/axios.helper';
import { objectToQuery } from 'common/helpers/filters.helper';
import { ESocketActions, SocketIOService } from 'common/ws/SocketIo';
import { IApplicationState } from 'app/store/reducers';
import { IChangeSubscriptionNotificationModel } from 'entities/Notification/Notification.models';
import { setAcademyModel } from 'entities/Academy/Academy.communication';
import { authTransport, passwordRestoreTransport } from 'entities/Auth/Auth.transport';
import {
  EAuthErrorMessage,
  IAuthModel,
  ITokenModel,
  EAuthSuccessMessage,
  IPasswordRestoreTokenCheckParams,
  IPasswordForgotParams,
  IPasswordRestoreParams,
  IAuthLoginParams,
  IAuthDeleteParams,
  ISocialAuthParams,
  ISocialUnlinkParams,
  ITokenLoginAuthParams
} from 'entities/Auth/Auth.models';
import { EUserRole, EUserSuccessMessage, IUserConfirmParams, IUserModel } from 'entities/User/User.models';
import { userTransport } from 'entities/User/User.transport';
import { academyTransport } from 'entities/Academy/Academy.transport';
import { IAcademyModel } from 'entities/Academy/Academy.models';
import { setUserModel } from 'entities/User/User.communication';

const namespace = 'auth';

export interface IAuthStoreProps {
  user: StoreBranch<IUserModel>;
  model: StoreBranch<IAuthModel>;
  academy: StoreBranch<IAcademyModel>;
}

export interface IAuthConnectedProps {
  authModel: StoreBranch<IAuthModel>;
  authLogin: StoreBranch<IAuthModel>;
  authUser: StoreBranch<IUserModel, null, any>;
  authAcademy: StoreBranch<IAcademyModel>;
  authRefreshAcademy: StoreBranch<IAcademyModel>;
  authPasswordRestore: StoreBranch<void>;
  authUserConfirm: StoreBranch<void>;
  authTokenCheck: StoreBranch<void>;

  getAuthUser(id: string): void;
  getAuthAcademy(id: string): void;
  addAuthModel(params: IAuthLoginParams): void;
  deleteAuthModel(params?: IAuthDeleteParams): void;
  addAuthLogin(params: IAuthLoginParams): void;
  addSocialAuthLogin(params: ISocialAuthParams): void;
  addTokenAuthLogin(params: ITokenLoginAuthParams): void;

  checkAuthUserConfirm(params: IUserConfirmParams): void;
  passwordRestoreAuthTokenCheck(params: IPasswordRestoreTokenCheckParams): void;

  forgotAuthPasswordRestore(params: IPasswordForgotParams): void;
  updateAuthPasswordRestore(params: IPasswordRestoreParams): void;

  getAuthRefreshAcademy: StoreBranch<IAcademyModel>;

  addAuthSocialLink(params: ISocialAuthParams): void;
  deleteAuthSocialLink(params: ISocialUnlinkParams): void;
  clearAuthSocialLink(): void;

  clearAuthLogin(): void;
  initAuthModel(): void;
}

export const successAuth = function*(response: IAuthModel) {
  const userId = response?.access?.userId;
  const token = response?.access?.token;

  if (userId && token) {
    SentryTagsHelper.setUser(userId);
    yield socketConnect(token);
    yield getAuthUser(userId);
  }
};

const modelApiProvider = [
  new APIProvider(EActionsTypes.add, authTransport.add),
  new APIProvider(EActionsTypes.delete, authTransport.logout, {
    onSuccess: function*() {
      yield clearAuth();
    },
    onFail: function*() {
      yield clearAuth();
    }
  }),
  new APIProvider(EActionsTypes.init, (): Promise<ITokenModel> => getCreds(), { onSuccess: successAuth })
];
const loginApiProvider = [
  new APIProvider(EActionsTypes.add, authTransport.add, {
    onSuccess: function*(response: IAuthModel) {
      yield addAuthModelSuccessType(response);
      yield call(saveCreds, response);
      yield successAuth(response);
      history.push(ERoutesCommon.Root);
    }
  }),
  new APIProvider('addToken', authTransport.tokenLoginAuth, {
    onSuccess: function*(response: IAuthModel) {
      yield successAuth(response);
      yield call(saveCreds, response);
    },
    onFail: function() {
      history.push(ERoutesCommon.Root);
      message.error(translateToasts('The link is invalid or has already been used'));
    }
  }),
  new APIProvider('addSocial', authTransport.socialAuth, {
    onSuccess: function*(response: IAuthModel) {
      yield successAuth(response);
      yield call(saveCreds, response);
    },
    throwOnFail: true
  })
];

const authUserConfirmApiProvider = [
  new APIProvider('check', userTransport.confirm, {
    onSuccess: function*(response: IAuthModel, originalParams) {
      yield clearAuth();
      yield call(saveCreds, response);
      yield successAuth(response);
      history.push(
        originalParams?.playerIdRedirect
          ? `${ERoutesMentor.RequestList}${objectToQuery({ playerIdRedirect: originalParams?.playerIdRedirect })}`
          : ERoutesCommon.Root
      );

      if (!originalParams?.silent) {
        setTimeout(() => {
          message.success(translateToasts(EUserSuccessMessage.Confirm));
        }, 1000);
      }
    },
    onFail: response => {
      if ('.token' in response?.data?.errors) {
        message.error(translateErrors('The link is invalid or has already been used'));
        history.push(ERoutesCommon.Root);
      }
    }
  })
];

const passwordRestoreApiProvider = [
  new APIProvider('forgot', passwordRestoreTransport.add, {
    onSuccess: function() {
      message.success(translateToasts(EAuthSuccessMessage.PasswordForgot));
    }
  }),
  new APIProvider(EActionsTypes.update, passwordRestoreTransport.passwordRestore, {
    onSuccess: function*(response: IAuthModel) {
      message.success(translateToasts(EAuthSuccessMessage.ChangePasswordSuccess));
      yield call(saveCreds, response);
      yield setAuthUser(response);
      yield successAuth(response);
    },
    onFail: function(e: IError) {
      if (e.status === EErrorStatus.NotFound) {
        message.error(translateErrors(EAuthErrorMessage.InvalidRestoreCode));
      }
    }
  })
];

const authUserAPIProvider = [
  new APIProvider(EActionsTypes.get, userTransport.get, {
    onSuccess: function*(response: IUserModel) {
      yield setUserModel(response);
      if (response?.role === EUserRole.AcademyWorker && response?.academyWorker?.academy?.id) {
        yield getAuthAcademy(response.academyWorker.academy.id);
      }
      if (response?.role === EUserRole.Trainer) {
        history.push(ERoutesPublic.PasswordRestoreSuccess);
        yield clearAuth();
      }
      const role = roleHelper(response?.role);
      setHeaders(role);
    }
  })
];
const authAcademyAPIProvider = [
  new APIProvider(EActionsTypes.get, academyTransport.get, {
    onSuccess: setAcademyModel
  })
];
const refreshAuthAcademyAPIProvider = [
  new APIProvider(EActionsTypes.get, academyTransport.get, {
    onSuccess: function*(response) {
      yield updateAuthAcademy(response);
    }
  })
];

const socialLinkApiProvider = [
  new APIProvider(EActionsTypes.add, authTransport.socialLink),
  new APIProvider(EActionsTypes.delete, authTransport.socialUnlink)
];

const tokenCheckAPIProviders = [new APIProvider('passwordRestore', passwordRestoreTransport.checkRestoreToken)];

const branches = [
  new Branch('model', modelApiProvider, new StoreBranch<IAuthModel, null, any>(null, null, null, true)),
  new Branch('socialLink', socialLinkApiProvider),
  new Branch('login', loginApiProvider, new StoreBranch<IAuthModel, null, any>(null, null, null, false)),
  new Branch('user', authUserAPIProvider, new StoreBranch<IUserModel, null, any>(null, null, null, true)),
  new Branch('academy', authAcademyAPIProvider, new StoreBranch<IAcademyModel>(null, null, null, true)),
  new Branch('refreshAcademy', refreshAuthAcademyAPIProvider, new StoreBranch<IAcademyModel>(null, null, null, false)),
  new Branch('passwordRestore', passwordRestoreApiProvider),
  new Branch('userConfirm', authUserConfirmApiProvider),
  new Branch('tokenCheck', tokenCheckAPIProviders, new StoreBranch<void>(null, null, null, true))
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export function* clearAuth() {
  SentryTagsHelper.clearContext();
  yield clearAllBranch();
  yield socketDisconnect();
  yield call(clearCreds);
}

function* clearAuthModel() {
  yield put({ type: getStartType(namespace, 'model', EActionsTypes.clear) });
}

export function* getAuthUser(id: string) {
  yield put({ type: getStartType(namespace, 'user', EActionsTypes.get), payload: id });
}

export function* getAuthAcademy(id: string) {
  yield put({ type: getStartType(namespace, 'academy', EActionsTypes.get), payload: id });
}

export function* getAuthAcademyId() {
  const academyModel: IAcademyModel = yield select((state: IApplicationState) => state.auth.academy?.data);
  return academyModel?.id;
}

export function* refreshAuthAcademy() {
  const academyId = yield getAuthAcademyId();
  if (academyId) {
    yield put({ type: getStartType(namespace, 'academy', EActionsTypes.get), payload: academyId });
  }
}
export function* refreshAuthAcademySilent() {
  const academyId = yield getAuthAcademyId();
  if (academyId) {
    yield put({ type: getStartType(namespace, 'refreshAcademy', EActionsTypes.get), payload: academyId });
  }
}
export function* refreshAuthUser() {
  const userModel: IUserModel = yield select((state: IApplicationState) => state.auth.user?.data);
  const userId = userModel?.id;
  if (userId) {
    yield getAuthUser(userId);
  }
}

export function* addAuthModelSuccessType(response: IAuthModel) {
  yield put({ type: getSuccessType(namespace, 'model', EActionsTypes.add), payload: response });
}

export function* updateAuthUser(response: IUserModel) {
  yield put({ type: getSuccessType(namespace, 'user', EActionsTypes.get), payload: response });
}

export function* updateAuthAcademy(response: IAuthModel) {
  yield put({ type: getSuccessType(namespace, 'academy', EActionsTypes.get), payload: response });
}

export function* setAuthUser(response: IAuthModel) {
  yield put({ type: getSuccessType(namespace, 'model', EActionsTypes.add), payload: response });
}

export function* clearAuthUser() {
  yield put({ type: getStartType(namespace, 'user', EActionsTypes.clear) });
}
export function* clearAuthAcademy() {
  yield put({ type: getStartType(namespace, 'academy', EActionsTypes.clear) });
}

function* socketConnect(token: string) {
  yield SocketIOService.connect(token);
}

function* socketDisconnect() {
  yield SocketIOService.disconnect();
}

export function* clearAllBranch() {
  yield clearAuthModel();
  yield clearAuthUser();
  yield clearAuthAcademy();
  yield put({ type: getStartType(namespace, 'passwordRestore', EActionsTypes.clear) });
  yield put({ type: getStartType(namespace, 'userConfirm', EActionsTypes.clear) });
  yield put({ type: getStartType(namespace, 'tokenCheck', EActionsTypes.clear) });

  yield put({ type: getStartType('user', 'model', EActionsTypes.clear) });

  yield put({ type: getStartType('mentorRequest', 'model', EActionsTypes.clear) });
  yield put({ type: getStartType('mentorRequest', 'collection', EActionsTypes.clear) });

  yield put({ type: getStartType('player', 'model', EActionsTypes.clear) });
  yield put({ type: getStartType('player', 'collection', EActionsTypes.clear) });
  yield put({ type: getStartType('player', 'ratingHistory', EActionsTypes.clear) });
  yield put({ type: getStartType('player', 'physicalHistory', EActionsTypes.clear) });
  yield put({ type: getStartType('player', 'technicalHistory', EActionsTypes.clear) });
  yield put({ type: getStartType('player', 'setting', EActionsTypes.clear) });
  yield put({ type: getStartType('player', 'testHistory', EActionsTypes.clear) });

  yield put({ type: getStartType('playerTest', 'collection', EActionsTypes.clear) });

  yield put({ type: getStartType('academy', 'model', EActionsTypes.clear) });
  yield put({ type: getStartType('academy', 'baseResults', EActionsTypes.clear) });
  yield put({ type: getStartType('academy', 'collection', EActionsTypes.clear) });
  yield put({ type: getStartType('academy', 'collectionForPlayer', EActionsTypes.clear) });
  yield put({ type: getStartType('academy', 'playerCollection', EActionsTypes.clear) });
  yield put({ type: getStartType('academy', 'playerModel', EActionsTypes.clear) });

  yield put({ type: getStartType('subscription', 'model', EActionsTypes.clear) });
  yield put({ type: getStartType('subscription', 'collection', EActionsTypes.clear) });

  yield put({ type: getStartType('skill', 'model', EActionsTypes.clear) });
  yield put({ type: getStartType('skill', 'collection', EActionsTypes.clear) });

  yield put({ type: getStartType('test', 'model', EActionsTypes.clear) });
  yield put({ type: getStartType('test', 'collection', EActionsTypes.clear) });

  yield put({ type: getStartType('academyRequest', 'model', EActionsTypes.clear) });
  yield put({ type: getStartType('academyRequest', 'collection', EActionsTypes.clear) });
}

export const communicationAuth = buildCommunication<IAuthConnectedProps>(strategy);

function* onChangePaymentMethod() {
  yield takeLatest(ESocketActions.ChangePaymentMethod, function*({ payload }: IAction<IChangeSubscriptionNotificationModel>) {
    yield delay(5000);
    const userModel: IUserModel = yield select((state: IApplicationState) => state.auth.user?.data);
    const updateUserId = payload?.subscription?.mentorRequest?.mentor?.id;

    if (updateUserId === userModel.id && payload?.data && 'paymentMethod' in payload.data) {
      userModel.stripePaymentMethod = payload.data.paymentMethod ?? undefined;
      yield updateAuthUser(userModel);
    }
  });
}

communicationAuth.sagas.push(onChangePaymentMethod());
