import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import message from 'antd/es/message';
import Spin from 'antd/es/spin';
import { StoreBranch } from '@axmit/redux-communications';
import { useTranslation } from 'react-i18next';
import { ERoutesAcademy } from 'common/models/routesModel';
import { isAcademyFranchisor } from 'common/helpers/franchise.helper';
import { IAcademyModel, TAcademyParamsModel } from 'entities/Academy/Academy.models';
import { communicationAcademy, IAcademyConnectedProps } from 'entities/Academy/Academy.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

export interface IAcademyModelProviderContext {
  academyModel?: StoreBranch<IAcademyModel, TAcademyParamsModel> | null;
  isUserAcademy?: boolean;
}

export const AcademyModelProviderContext = React.createContext<IAcademyModelProviderContext>({});

type AllProps = IAcademyConnectedProps & IAuthConnectedProps;

const AcademyModelProviderComponent: React.FC<AllProps> = props => {
  const history = useHistory();
  const { t } = useTranslation();
  const { academyModel, getAcademyModel, children, authAcademy } = props;
  const { data: authAcademyData } = authAcademy;
  const { data: academyData, loading } = academyModel;
  const { academyId: urlAcademyId } = useParams();

  const academyId = useMemo(() => urlAcademyId || authAcademyData?.id, [urlAcademyId, authAcademyData]);
  const isUserAcademy = useMemo(() => authAcademyData?.id === academyId, [academyId, authAcademyData]);
  const isCurrentAcademyAuth = useMemo(() => authAcademyData && academyId === authAcademyData?.id, [authAcademyData]);
  const isUserFranchisorForAcademy = useMemo(
    () =>
      isAcademyFranchisor(authAcademy?.data) &&
      academyData?.franchiseRequest?.franchise?.id === authAcademyData?.franchiseRequest?.franchise?.id,
    [academyData, authAcademyData]
  );

  useEffect(() => {
    if (academyId && academyData?.id !== academyId) {
      getAcademyModel(academyId);
    }
  }, [academyId, getAcademyModel]);

  useEffect(() => {
    if (authAcademyData && academyData && !(isCurrentAcademyAuth || isUserFranchisorForAcademy)) {
      message.error(t('You do not have permission to open this page'));
      history.push(ERoutesAcademy.AcademyEdit);
    }
  }, [academyData, authAcademyData]);

  return (
    <AcademyModelProviderContext.Provider value={{ academyModel, isUserAcademy }}>
      <Spin spinning={Boolean(academyId) && (loading || !academyData)} size="large" className="mt-10">
        {!academyId || (!loading && academyData) ? children : null}
      </Spin>
    </AcademyModelProviderContext.Provider>
  );
};

export const AcademyModelProvider = communicationAuth.injector(communicationAcademy.injector(AcademyModelProviderComponent));
