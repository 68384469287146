import React from 'react';
import i18n from 'i18next';
import moment from 'moment';
import { serverDateFormat } from 'common/models/dateModels';
import { dateFormatter, getUTCEndOfDay, getUTCStartOfDay } from 'common/helpers/date.helper';
import { IAcademySearchModel } from 'entities/Academy/Academy.models';

export interface IStatisticModel extends Components.Schemas.TrainerStatisticDto {}
export interface IAmountModel extends Components.Schemas.AmountDto {}
export interface IStatisticCoachCollection extends Components.Schemas.TrainerStatisticCollectionDto {}
export interface IStatisticBillingCollection extends Components.Schemas.BillingStatisticCollectionDto {}
export type TStatisticCollection = IStatisticCoachCollection & IStatisticBillingCollection;
export interface IStatisticCollectionFilter extends Paths.StatisticControllerAcademyStatistic.QueryParameters {
  statisticType: EStatisticTypeValue;
  academyFilter?: Partial<IAcademySearchModel>;
  isFranchise?: boolean;
  pageIndex?: number;
  id?: string;
  token?: string;
}

export enum EStatisticTypeValue {
  Test = 'test',
  Billing = 'billing'
}
export enum EStatisticType {
  Test = 'Passing tests',
  Billing = 'Billing'
}

export const initStatisticState: Partial<IStatisticCollectionFilter> = {
  statisticType: EStatisticTypeValue.Test,
  pageIndex: 1,
  from: dateFormatter(getUTCStartOfDay(moment().subtract(1, 'months')), serverDateFormat),
  to: dateFormatter(getUTCEndOfDay(moment()), serverDateFormat),
  id: undefined,
  academyFilter: undefined
};

export const translateStatisticValue: any = {
  academyName: i18n.t('Academy'),
  coachName: i18n.t('Trainer'),
  academyCity: i18n.t('City'),
  paidPlayer: i18n.t('Players paid'),
  // eslint-disable-next-line sonarjs/no-duplicate-string
  totalPlayer: i18n.t('Players count'),
  playerWithTest: i18n.t('Players tested'),
  maxAge: i18n.t('Maximum age'),
  minAge: i18n.t('Minimum age'),
  totalTest: i18n.t('Tests count'),
  acceptTest: i18n.t('Accepted tests'),
  totalAmount: i18n.t('Payment amounts'),
  amountCount: i18n.t('Payment counts'),
  avgPercent: i18n.t('Profile completed')
};

export const statisticTableConfig = (isBilling?: boolean) => {
  return isBilling
    ? [
        {
          title: i18n.t('Academy'),
          dataIndex: 'academyName',
          key: 'academyName',
          ellipsis: true
        },
        {
          title: i18n.t('Payment counts'),
          dataIndex: 'amounts',
          key: 'amountCount',
          ellipsis: true,
          render: (item: IAmountModel[]) =>
            item?.length
              ? item?.map((amount, index) => {
                  const { currency, amountCount = 0 } = amount || {};
                  return currency ? (
                    <div key={index}>
                      {amountCount}
                      <br />
                    </div>
                  ) : null;
                })
              : '-'
        },
        {
          title: i18n.t('Payment amounts'),
          dataIndex: 'amounts',
          key: 'totalAmount',
          ellipsis: true,
          render: (item: IAmountModel[]) =>
            item?.length
              ? item?.map((amount, index) => {
                  const { currency, totalAmount = 0 } = amount || {};
                  return currency ? (
                    <div key={index}>
                      {`${totalAmount} ${i18n.t(currency)}`}
                      <br />
                    </div>
                  ) : null;
                })
              : '-'
        },

        {
          title: i18n.t('Players count'),
          dataIndex: 'totalPlayer',
          key: 'totalPlayer',
          ellipsis: true
        },
        {
          title: i18n.t('Players paid'),
          dataIndex: 'paidPlayer',
          key: 'paidPlayer',
          ellipsis: true
        }
      ]
    : [
        {
          title: i18n.t('Academy'),
          dataIndex: 'academyName',
          key: 'academyName',
          ellipsis: true
        },
        {
          title: i18n.t('Trainer'),
          dataIndex: 'coachName',
          key: 'coachName',
          ellipsis: true
        },
        {
          title: i18n.t('City'),
          dataIndex: 'academyCity',
          key: 'academyCity',
          ellipsis: true
        },
        {
          title: i18n.t('Players count'),
          dataIndex: 'totalPlayer',
          key: 'totalPlayer'
        },
        {
          title: i18n.t('Players tested'),
          dataIndex: 'playerWithTest',
          key: 'playerWithTest'
        },
        {
          title: i18n.t('Maximum age'),
          dataIndex: 'maxAge',
          key: 'maxAge'
        },
        {
          title: i18n.t('Minimum age'),
          dataIndex: 'minAge',
          key: 'minAge'
        },
        {
          title: i18n.t('Tests count'),
          dataIndex: 'totalTest',
          key: 'totalTest'
        },
        {
          title: i18n.t('Accepted tests'),
          dataIndex: 'acceptTest',
          key: 'acceptTest'
        },
        {
          title: i18n.t('Profile completed'),
          dataIndex: 'avgPercent',
          key: 'avgPercent'
        }
      ];
};
