import React from 'react';
import Col from 'antd/es/col';
import Collapse from 'antd/es/collapse';
import List from 'antd/es/list';
import Typography from 'antd/es/typography';
import Row from 'antd/es/row';
import { useTranslation } from 'react-i18next';
import { dateFormatter } from 'common/helpers/date.helper';
import { VideoPrivate } from 'common/components/Video/VideoPrivate';
import { PlayerTestResultAverage } from 'entities/PlayerTest/components/PlayerTestResultAverage';
import { EPlayerTestRejectReasonText, ETestStatus } from 'entities/Test/Test.models';
import { IPlayerTestModel, IPlayerTestResultPureModel } from 'entities/PlayerTest/PlayerTest.models';

interface IComponentProps {
  item: IPlayerTestModel;
}
type AllProps = IComponentProps;

const TestModalEvaluationItemComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { item } = props;

  const testStatus = (
    status: ETestStatus,
    testResults?: IPlayerTestResultPureModel[],
    rejectCode?: number | null,
    rejectDetails?: string | null
  ) => {
    switch (status) {
      case ETestStatus.New:
      case ETestStatus.AI:
      case ETestStatus.Moderation:
        return <Typography.Text>{t('Waiting for evaluation')}</Typography.Text>;

      case ETestStatus.Accept: {
        return testResults?.length ? (
          <Row gutter={[8, 8]}>
            {testResults.map((testItem, index) => {
              return (
                <Col xs={24} key={index}>
                  <PlayerTestResultAverage isWrap result={{ ...testItem, ...testItem.baseResult }} />
                </Col>
              );
            })}
          </Row>
        ) : (
          <Typography.Text className="color-success">{t('Test passed')}</Typography.Text>
        );
      }

      case ETestStatus.Reject:
        return (
          <>
            <Typography.Text className="color-danger mb-2">{t('Failed to evaluate test')}</Typography.Text> <br />
            {rejectCode && (
              <Typography.Text className="color-error">{t(`${EPlayerTestRejectReasonText[rejectCode]}`)}</Typography.Text>
            )}
            <br />
            {rejectDetails && <Typography.Text className="color-text-secondary pre-line-space">{rejectDetails}</Typography.Text>}
          </>
        );
      default:
        return;
    }
  };

  const { id, createdAt, video, testResults, rejectCode, status, rejectDetails } = item;
  const videoId = video?.id;

  return (
    <List.Item key={id}>
      <Collapse>
        <Collapse.Panel header={dateFormatter(createdAt)} key={id}>
          <Row justify="space-between" gutter={[0, 8]}>
            {!!videoId && (
              <Col span={24}>
                <VideoPrivate height={211} videoId={videoId} preview={video?.image?.id} />
              </Col>
            )}
            <Col span={24}>{testStatus(status as ETestStatus, testResults, rejectCode, rejectDetails)}</Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </List.Item>
  );
};

export const TestModalEvaluationItem = TestModalEvaluationItemComponent;
