import React, { useMemo, useCallback, ReactElement, useState } from 'react';
import Button from 'antd/es/button';
import HeartOutlined from '@ant-design/icons/HeartOutlined';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { ButtonType } from 'antd/es/button/button';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import {
  EAcademyRequestStatus,
  EAcademyRequestSuccessUpdateModels,
  IAcademyRequestLiteModel
} from 'entities/AcademyRequest/AcademyRequest.models';
import { communicationAcademyRequest, IAcademyRequestConnectedProps } from 'entities/AcademyRequest/AcademyRequest.communication';
import { communicationAcademy, IAcademyConnectedProps } from 'entities/Academy/Academy.communication';

interface IProps {
  id?: string;
  size?: 'small' | 'middle' | 'large';
  type?: ButtonType;
  block?: boolean;
  isAcademyPlayer?: boolean;
  adaptive?: boolean;
  academyToPlayerRequest?: IAcademyRequestLiteModel;
  addElement?: ReactElement;
  removeElement?: ReactElement;
  modelsToUpdate?: EAcademyRequestSuccessUpdateModels[];
  optimisticUpdate?: boolean;
  showIcon?: boolean;
  className?: string;
}

type AllProps = IAcademyRequestConnectedProps & IAcademyConnectedProps & IProps;

const PlayerFavoriteComponent: React.FC<AllProps> = props => {
  const {
    id,
    size,
    academyToPlayerAcademyRequestModel,
    academyToPlayerRequest,
    updateAcademyRequestModel,
    academyRequestModel,
    isAcademyPlayer,
    block = true,
    adaptive = true,
    addElement,
    removeElement,
    modelsToUpdate,
    optimisticUpdate,
    showIcon,
    type = 'primary',
    className
  } = props;
  const { loading: academyRequestLoading } = academyRequestModel;
  const { t } = useTranslation();
  const isDesktopMedia: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);

  const isInFavorites = useMemo(
    () => academyToPlayerRequest && academyToPlayerRequest.status === EAcademyRequestStatus.Accepted,
    [academyToPlayerRequest]
  );

  const [favorite, setFavorite] = useState(isInFavorites);
  const [favoriteId, setFavoriteId] = useState(academyToPlayerRequest?.id);

  const [loading, setLoading] = useState(academyRequestLoading);

  const onAddInFavourites = useCallback(
    async e => {
      e.stopPropagation();
      e.preventDefault();
      if (id && !loading) {
        setFavorite(true);
        setLoading(true);
        const res = await academyToPlayerAcademyRequestModel({ player: id, modelsToUpdate, optimisticUpdate });
        if (res?.id) {
          setFavoriteId(res?.id);
        }
        setLoading(false);
      }
    },
    [academyToPlayerRequest, id, loading, optimisticUpdate, favorite, favoriteId]
  );

  const onRemoveFromFavourites = useCallback(
    async e => {
      e.stopPropagation();
      e.preventDefault();

      if (!loading) {
        setFavorite(false);
        setLoading(true);
        if (favoriteId) {
          await updateAcademyRequestModel({
            id: favoriteId,
            status: EAcademyRequestStatus.Aborted,
            modelsToUpdate,
            optimisticUpdate,
            playerId: id
          });
        }
        setFavoriteId(undefined);
        setLoading(false);
      }
    },
    [academyToPlayerRequest, loading, optimisticUpdate, modelsToUpdate, favorite, favoriteId]
  );

  return useMemo(() => {
    if (favorite) {
      return (
        <div onClick={onRemoveFromFavourites} className={className}>
          {removeElement ?? (
            <Button size={size} type={type} disabled={loading} block={block} danger>
              {showIcon && <HeartOutlined />}
              {!adaptive || isDesktopMedia ? t('To unsubscribe') : null}
            </Button>
          )}
        </div>
      );
    }
    return !isAcademyPlayer ? (
      <div onClick={onAddInFavourites} className={className}>
        {addElement ?? (
          <Button size={size} type={type} disabled={loading} block={block}>
            {showIcon && <HeartOutlined />}
            {!adaptive || isDesktopMedia ? t('To favorites') : null}
          </Button>
        )}
      </div>
    ) : null;
  }, [isInFavorites, loading, onRemoveFromFavourites, onAddInFavourites, isDesktopMedia, favorite, favoriteId]);
};

export const PlayerFavorite = communicationAcademy.injector(communicationAcademyRequest.injector(PlayerFavoriteComponent));
