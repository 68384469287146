export const options = {
  googleApiKey: 'AIzaSyAHDawlxaD8-3LENM75C9XO7CmM-4yRFn4',
  googleMapsApiKey: 'AIzaSyBXGNLAWcBsH-6r8QDT8vZMpocDcyARwvs',
  stripeToken: !location.origin.includes('dev')
    ? 'pk_live_51KheHuBbe4MmSCPNjBhirUJdMqJkcXW1nLiaerk6VKXm1GtMY1W3n4sCeck0GWpcye3eXENhdgVK8rsiuU1jsbjN00fJBaXjoN'
    : 'pk_test_51KheHuBbe4MmSCPNJpYyVj3EDuNjVdFpKKTM2YkzndgiOmmlDPyvnUSa1nnasvogBzxu2CfmjA3eNt2yO9wrhSqA00bMbaibbz',
  supportMail: 'support@junistat.com',
  googlePlayId: 'com.junistat.player',
  coachGooglePlayId: 'com.junistat.coach',
  coachAppStoreId: '1597370545',
  appStoreId: '1554737071',
  siteUrl: 'https://junistat.com/',
  appUrl: 'app.junistat.com',
  fullAppUrl: 'https://app.junistat.com',
  registerMentorPath: '/sign-up?role=mentor',
  registerAcademiesPath: '/sign-up?role=academy',
  documents: {
    paymentMethod: 'https://junistatrating.notion.site/82783244445944dcbdff53e191c3024b',
    personalDataPolicy: 'https://junistatrating.notion.site/JuniStat-Privacy-Policy-cdabd544b7bf4a7d83628a173525247b',
    termsOfUse: 'https://junistatrating.notion.site/JuniStat-6f0e4e89f3fd4842a1cffb56c892960d'
  },
  socialLinks: {
    vkontakte: 'https://vk.com/junistat',
    instagram: 'https://www.instagram.com/junistat.rating/',
    telegram: 'https://t.me/junistat',
    facebook: 'https://www.facebook.com/junistat.rating/'
  },
  isProd: !location.origin.includes('dev'),
  env: !location.origin.includes('dev') ? 'prod' : 'dev',
  supportTeam: [
    {
      name: 'Gleb',
      avatarUrl: 'https://junistat.com/images/gleb_1gleb.jpg',
      link: 'https://wa.me/56948541496'
    },
    {
      name: 'Victor',
      avatarUrl: 'https://junistat.com/images/victor_1victor.jpg',
      link: 'https://wa.me/79175884950'
    }
  ],
  tgSupport: 'https://t.me/junistat_support_bot',
  waSupport: 'https://wa.me/message/CNJHQFF7KWCZP1'
};

console.log('ENV', !location.origin.includes('dev') ? 'prod' : 'dev', options.isProd, options.stripeToken.slice(3, 7));
