import React from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { useMediaPredicate } from 'react-media-hook';
import Typography from 'antd/es/typography';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';

const { Title } = Typography;

interface IComponentProps {
  firstName: string;
  lastName: string;
  image: { id: string };
}

type AllProps = IComponentProps;

const avatarWidth = 56;
const avatarHeight = 56;

const TrainerSmallCardComponent: React.FC<AllProps> = props => {
  const { firstName, lastName, image } = props;
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const imgId = image?.id;

  return (
    <Row className="flex-noWrap" align="middle">
      <ImagePrivate
        preview={false}
        imgId={imgId}
        height={avatarHeight}
        width={avatarWidth}
        alt="trainer img"
        className="border-circle mr-5"
      />
      {isDesktop ? (
        <Row justify="space-around" align="middle" className="full-width">
          <Col>
            <Title level={5}>
              {firstName}
              <br />
              {lastName}
            </Title>
          </Col>
        </Row>
      ) : null}
    </Row>
  );
};

export const TrainerSmallCard = TrainerSmallCardComponent;
