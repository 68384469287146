import React, { useEffect, useState } from 'react';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import Typography from 'antd/es/typography';
import Row from 'antd/es/row';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce/esm';
import { useHistory } from 'react-router';
import { requiredField } from 'common/helpers/filed-rules';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import {
  EPrivatePlayerActionTypes,
  EPrivatePlayerSearchCollectionFilterSorters,
  IPrivatePlayerCollectionFilter
} from 'entities/PrivatePlayer/PrivatePlayer.models';
import { PrivatePlayerSelector } from 'entities/PrivatePlayer/components/Selectors/PrivatePlayerSelector';
import {
  PrivatePlayerFilterCardsFilters,
  PrivatePlayerListFilterCard
} from 'entities/PrivatePlayer/components/List/PrivatePlayerListFilterCard';
import { communicationTeam, ITeamConnectedProps } from 'entities/Team/Team.communication';
import { DEFAULT_PRIVATE_PLAYER_LIST_GRID, PrivatePlayerList } from 'entities/PrivatePlayer/components/List/PrivatePlayersList';
import { IPlayerModel } from 'entities/Player/Player.models';

interface IProps {
  academyId?: string;
  teamId: string;
  isDesktop: boolean;
}

const initState: IPrivatePlayerCollectionFilter = {
  name: undefined,
  ageGroup: undefined,
  hideArchived: true,
  onlyMy: true,
  year: undefined
};

const clearedState: IPrivatePlayerCollectionFilter = {
  ...initState,
  hideArchived: false
};

type AllProps = IProps & ITeamConnectedProps;

const TeamAcademyPagePlayersComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { addPlayerToTeamAction, academyId, isDesktop, teamId, teamAction } = props;
  const [addPlayerForm] = Form.useForm();
  const [renderIndex, setRenderIndex] = useState(Math.random());
  const [state, setState] = useState<Partial<IPrivatePlayerCollectionFilter> | undefined>({
    ...initState,
    ...queryToObject<Partial<IPrivatePlayerCollectionFilter>>(initState)
  });

  const debPush = useDebouncedCallback((val: any) => {
    history.replace(val);
  }, 300);

  useEffect(() => {
    debPush.callback({ search: objectToQuery(state) });
  }, [state]);

  const onAddPlayer = (form: { players: IPlayerModel[] }) => {
    if (teamId) {
      addPlayerToTeamAction({ id: teamId, playerModels: form.players });
      addPlayerForm.resetFields();
    }
  };

  return (
    <>
      {academyId && (
        <Col span={24}>
          <Card className="px-3 mt-5" bordered={false}>
            <Typography.Title className="mb-5" level={4}>
              {t('Add player')}
            </Typography.Title>
            <Row>
              <Form
                form={addPlayerForm}
                onFinish={onAddPlayer}
                name="add-player-to-team"
                layout="horizontal"
                className="width-full"
              >
                <Row className="width-full" justify="space-between">
                  <Col xs={24} md={17}>
                    <Form.Item name="players" rules={[requiredField]}>
                      <PrivatePlayerSelector
                        mode="multiple"
                        excludeTeam={teamId}
                        size="large"
                        disabled={teamAction?.loading}
                        orderField={EPrivatePlayerSearchCollectionFilterSorters.ByAcademy}
                        academyId={academyId}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Button
                      block
                      htmlType="submit"
                      type="primary"
                      size="large"
                      disabled={teamAction?.loading}
                      loading={teamAction?.loading}
                    >
                      {t('Link')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Row>
          </Card>
        </Col>
      )}
      <Col xs={24} className="mt-5">
        <Row className="height-full mb-5" key={renderIndex}>
          <PrivatePlayerListFilterCard
            enabledFilters={[
              PrivatePlayerFilterCardsFilters.Name,
              PrivatePlayerFilterCardsFilters.AgeGroup,
              PrivatePlayerFilterCardsFilters.Year,
              PrivatePlayerFilterCardsFilters.PlayerCount
            ]}
            academyId={academyId}
            onFilterChange={partialFilter => setState({ ...state, ...partialFilter })}
            filter={state}
          />
        </Row>
        <Row className={`height-full mt-5 ${isDesktop ? '' : 'px-5'}`}>
          <PrivatePlayerList
            grid={DEFAULT_PRIVATE_PLAYER_LIST_GRID}
            actionType={EPrivatePlayerActionTypes.UnlinkFromTeam}
            hasPlaceholder
            elementId="basicLayout"
            teamId={teamId}
            filter={state}
            clearFilter={() => {
              setState({ ...clearedState });
              setRenderIndex(Math.random());
            }}
          />
        </Row>
      </Col>
    </>
  );
};

export const TeamAcademyPagePlayers = communicationTeam.injector(TeamAcademyPagePlayersComponent);
