const nav = window.navigator;
const ua = nav.userAgent;

export function isiOsSafari(a: string) {
  return ua.indexOf(a) !== -1;
  // return (
  // 'standalone' in nav && // There's a thing called standalone in nav
  // !(nav as any).standalone && // It is not running in standalone mode
  // ua.indexOf(a) !== -1 // && // iPhone is in the UA string (could be Opera)
  // ua.indexOf('Mac OS') !== -1 && // There's Mac in the UA string (not Opera)
  // ua.indexOf('Safari') !== -1
  // );
  /* if all the above are true this probably means this is
  the Safari browser,
  not a webview in an app,
  not a page in standalone mode */
}
