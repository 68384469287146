/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import Input from 'antd/es/input';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useFormMapper } from '@axmit/antd4-helpers';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'common/helpers/use-query.helper';
import { ERoutesPublic } from 'common/models/routesModel';
import { getPageWithQuery } from 'common/helpers/routes.helper';
import { requiredField, passwordRules, commonStringRules, phoneRule } from 'common/helpers/filed-rules';
import { roleHelper } from 'common/helpers/role.helper';
import { MeasurementSystemSelector } from 'common/components/Selector/MeasurementSystemSelector';
import { Phone } from 'common/components/Phone/Phone';
import { getCurrentLang } from 'common/helpers/i18n';
import { getUrlSearchAsObject } from 'common/helpers/filters.helper';
import { communicationUser, IUserConnectedProps } from 'entities/User/User.communication';
import { communicationAcademy, IAcademyConnectedProps } from 'entities/Academy/Academy.communication';
import { IUserCreateParams, IUserWithPlayerSignUpUserModel } from 'entities/User/User.models';
import { EAuthRoles } from 'entities/Auth/Auth.models';

interface IComponentProps {
  mentorRequestToken?: string;
  closeSignUpModal?: () => void;
  onSubmit?: (value?: any) => void;
  submitButtonText?: string;
  disabled?: boolean;
  showPhone?: boolean;
  data?: Partial<IUserWithPlayerSignUpUserModel>;
}
type AllProps = IUserConnectedProps & IAcademyConnectedProps & IComponentProps;

const FormFields: { [key in EAuthRoles]: string[] } = {
  [EAuthRoles.Mentor]: ['firstName', 'lastName', 'email', 'phone', 'password, ', 'measurementSystem'],
  [EAuthRoles.Academy]: ['firstName', 'lastName', 'email', 'password', 'name'],
  [EAuthRoles.Coach]: [],
  [EAuthRoles.Player]: []
};

const SignUpFormComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const [isRequestSend, setIsRequestSend] = useState(false);
  const currentRole = roleHelper(query.get('role'));
  const {
    addUserModel,
    userModel,
    addAcademyModel,
    academyModel,
    mentorRequestToken,
    userSignUpWithPlayer,
    closeSignUpModal,
    onSubmit,
    submitButtonText,
    data,
    disabled,
    showPhone
  } = props;
  const isUser = currentRole === EAuthRoles.Mentor;
  const model = isUser ? userModel : academyModel;
  const { data: modelData, loading, errors, params } = model;
  const { params: userSignUpWithPlayerParams } = userSignUpWithPlayer;

  const { fields } = useFormMapper(
    FormFields[currentRole],
    userSignUpWithPlayerParams?.user || data || modelData,
    params,
    errors
  );

  const signUp = (values: any) => {
    const { firstName, lastName, email, password, name, phone, measurementSystem } = values;
    const lang = getCurrentLang();
    const queries = getUrlSearchAsObject();

    const body: IUserCreateParams = {
      firstName,
      lastName,
      email,
      password,
      lang,
      measurementSystem
    };

    delete queries?.role;

    if (queries && Object.keys(queries).length) {
      body.metadata = { ...queries };
    }

    if (isUser) {
      if (showPhone && phone.length > 4) {
        body.phone = phone;
      }
      if (measurementSystem) {
        body.measurementSystem = measurementSystem;
      }
      body.token = mentorRequestToken;
      addUserModel(body);
    } else {
      addAcademyModel({ ...body, name });
    }
    setIsRequestSend(true);
  };

  useEffect(() => {
    if (isRequestSend) {
      if (!errors) {
        closeSignUpModal && closeSignUpModal();
        const path = getPageWithQuery(ERoutesPublic.SignUpSuccess, { role: currentRole });
        history.push(path);
      }
      setIsRequestSend(false);
    }
  }, [loading]);

  return (
    <Form onFinish={onSubmit || signUp} fields={fields} layout="vertical">
      {!isUser && (
        <>
          <Form.Item rules={[requiredField, ...commonStringRules()]} name="name" label={t('Academy name')} className="mb-7">
            <Input name="name" type="text" size="large" placeholder={t('Academy name')} />
          </Form.Item>
          <Typography.Title level={5} className="d-block mb-5">
            {t('Contact person info')}
          </Typography.Title>
        </>
      )}
      <Form.Item
        normalize={value => value.trimStart()}
        rules={[requiredField, ...commonStringRules()]}
        name="firstName"
        label={t('First Name')}
        className="mb-5"
      >
        <Input name="firstName" type="text" size="large" />
      </Form.Item>
      <Form.Item
        normalize={value => value.trimStart()}
        rules={[requiredField, ...commonStringRules()]}
        name="lastName"
        label={t('Last Name')}
        className="mb-5"
      >
        <Input name="lastName" type="text" size="large" />
      </Form.Item>
      <Form.Item normalize={value => value.trimStart()} rules={[requiredField]} name="email" label={t('Email')} className="mb-5">
        <Input name="email" type="email" size="large" allowClear />
      </Form.Item>
      {showPhone && (
        <Form.Item rules={[phoneRule]} name="phone" label={t('Phone')}>
          <Phone disabled={loading} />
        </Form.Item>
      )}
      {isUser && (
        <Form.Item name="measurementSystem" label={t('Measurement system')}>
          <MeasurementSystemSelector className="width-full" />
        </Form.Item>
      )}
      <Form.Item rules={[requiredField, passwordRules]} name="password" label={t('Password')} className="mb-5">
        <Input.Password type="password" size="large" />
      </Form.Item>
      <Row className="mb-5">
        <Typography.Text className="color-text-secondary">
          {t('rules')}&nbsp;
          <Link to={`${ERoutesPublic.ServiceDocuments}?role=${currentRole}`} target="_blank">
            <Typography.Text className="color-link text-underline">{t('Service rules')}</Typography.Text>
          </Link>
        </Typography.Text>
      </Row>
      <Form.Item className="mb-0">
        <Button htmlType="submit" block type="primary" size="large" disabled={loading || disabled} loading={loading || disabled}>
          {submitButtonText || t('Create account')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export const SignUpPage = communicationAcademy.injector(communicationUser.injector(SignUpFormComponent));
