export interface ITokenModel extends Components.Schemas.TokenPayloadDto {}
export interface IAuthModel extends Components.Schemas.TokenPairPayloadDto {}
export interface IAuthLoginParams extends Components.Schemas.CredentialsDto {}
export interface IPasswordForgotParams extends Components.Schemas.PasswordForgotDto {}
export interface IPasswordRestoreParams extends Components.Schemas.PasswordRecoveryDto {}
export interface IPasswordRestoreTokenCheckParams extends Components.Schemas.CheckTokenDto {}
export interface IAuthDeleteParams extends Paths.AuthControllerUserLogout.QueryParameters {}
export interface ISocialAuthParams extends Components.Schemas.CreateSocialAccountDto {}
export interface ITokenLoginAuthParams extends Components.Schemas.LoginTokenDto {}
export interface ISocialUnlinkParams extends Paths.SocialAccountControllerDelete.QueryParameters {}

export enum EAuthSuccessMessage {
  CheckEmailForConfirmedLink = 'Please check your email',
  PasswordForgot = 'Check email for password restore link',
  AccountConfirmed = 'Account successfully confirmed',
  ChangePasswordSuccess = 'Password changed'
}

export enum EAuthErrorMessage {
  InvalidRestoreCode = 'Invalid code. Try again.',
  InvalidCreds = 'Invalid credentials. Check and try again.'
}

export enum EAuthRoles {
  Mentor = 'mentor',
  Academy = 'academy',
  Coach = 'coach',
  Player = 'player'
}

export enum EAuthRolesTitle {
  Mentor = 'Mentor or parent',
  Academy = 'Academy manager',
  Coach = 'Trainer',
  Player = 'Player'
}
