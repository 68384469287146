import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  buildCollectionPreRequestDataMapper
} from '@axmit/redux-communications';
import { takeLatest } from 'redux-saga/effects';
import { testTransport } from 'entities/Test/Test.transport';
import {
  ITestModel,
  ITestUpdateData,
  ITestCollectionFilter,
  ITestCollection,
  TTestParamsModel,
  ITestResultsHistoryCollectionFilter,
  ITestResultsHistoryCollection,
  ITestTeamHistoryCollectionFilter,
  ITestTeamHistoryCollection
} from 'entities/Test/Test.models';

const namespace = 'test';

export interface ITestConnectedProps {
  testModel: StoreBranch<ITestModel, TTestParamsModel>;
  testCollection: StoreBranch<ITestCollection, ITestCollectionFilter>;
  testResultHistoryCollection: StoreBranch<ITestResultsHistoryCollection, ITestResultsHistoryCollectionFilter>;
  testTeamHistoryCollection: StoreBranch<ITestTeamHistoryCollection, ITestTeamHistoryCollectionFilter>;

  getTestModel(id: string): void;
  updateTestModel(params: ITestUpdateData): void;
  deleteTestModel(id: string): void;
  getTestCollection(filter?: ITestCollectionFilter): Promise<void>;
  getTestResultHistoryCollection(filter?: ITestResultsHistoryCollectionFilter): Promise<void>;
  getTestTeamHistoryCollection(filter?: ITestTeamHistoryCollectionFilter): Promise<void>;

  clearTestModel(): void;
  clearTestCollection(): void;
  clearTestResultHistoryCollection(): void;
  clearTestTeamHistoryCollection(): void;
}

const TestModelAPIProviders = [
  new APIProvider(EActionsTypes.get, testTransport.get),
  new APIProvider(EActionsTypes.update, testTransport.update),
  new APIProvider(EActionsTypes.delete, testTransport.delete)
];

const TestCollectionAPIProviders = [new APIProvider(EActionsTypes.get, testTransport.getCollection)];

const TestResultHistoryCollectionAPIProviders = [
  new APIProvider(
    EActionsTypes.get,
    testTransport.getTestResultHistoryCollection,
    {
      preRequestDataMapper: buildCollectionPreRequestDataMapper<
        ITestResultsHistoryCollection,
        ITestResultsHistoryCollectionFilter
      >()
    },
    takeLatest
  )
];

const TestTeamHistoryCollectionAPIProviders = [new APIProvider(EActionsTypes.get, testTransport.getTestTeamHistoryCollection)];

const branches = [
  new Branch('model', TestModelAPIProviders),
  new Branch('collection', TestCollectionAPIProviders),
  new Branch('resultHistoryCollection', TestResultHistoryCollectionAPIProviders),
  new Branch('teamHistoryCollection', TestTeamHistoryCollectionAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationTest = buildCommunication<ITestConnectedProps>(strategy);
