import React from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';

const PublicPlayersListNoDataComponent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row justify="center" align="middle">
      <Col xs={24} sm={12}>
        <Typography.Title level={3}>{t('Players not found')}</Typography.Title>
      </Col>
    </Row>
  );
};

export const PublicPlayersListNoData = PublicPlayersListNoDataComponent;
