import * as React from 'react';
import Select, { SelectProps } from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import { PlayerAgeGroup } from 'entities/Player/Player.models';

export const AgeGroupSelector: React.FC<SelectProps<any>> = props => {
  const { t } = useTranslation();
  return (
    <Select placeholder={t('Group')} getPopupContainer={triggerNode => triggerNode as HTMLElement} {...props}>
      {PlayerAgeGroup.map(age => (
        <Select.Option key={age} value={age}>
          {t(`${age}`)}
        </Select.Option>
      ))}
    </Select>
  );
};
