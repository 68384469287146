import { options } from 'options';
import message from 'antd/es/message';
import { getCreds, ICreds } from 'axios-patch-jwt';
import { RcFile } from 'antd/es/upload';
import moment from 'moment';
import Logger from 'common/helpers/logger';
import i18n from 'common/helpers/i18n';
import { videoApiRoute } from 'common/models/routesModel';
import { maxVideoTitleLength, maxVideoDescriptionLength } from 'entities/Video/Video.models';

const maxVideoDuration = 180; // seconds

export function getBase64(img: Blob, callback: { (): void; (arg0: string | ArrayBuffer | null): any }) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export async function downloadFile(linkUrl: string, token?: string) {
  const response = await fetch(
    linkUrl,
    token
      ? {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      : undefined
  );

  if (response.status === 200) {
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}

export function checkImage(file: { type: string; size: number }) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error(i18n.t('web-image-loader:Only JPG/PNG'));
  }
  const isLt2M = file.size / 1024 / 1024 < 15;
  if (!isLt2M) {
    message.error(i18n.t('web-image-loader:Size should be less than 15MB'));
  }
  return isJpgOrPng && isLt2M;
}

export const getVideoLink = async (videoId: string, isPublic?: boolean): Promise<string> => {
  const creds: ICreds = await getCreds();
  const token = creds?.access?.token || '';

  return `${videoApiRoute(videoId, isPublic)}${isPublic ? '' : `&token=${token}`}`;
};

export const checkVideoDuration = async (source: RcFile, duration: number = maxVideoDuration): Promise<boolean> =>
  new Promise(resolve => {
    window.URL = window.URL || window.webkitURL;

    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      const videoDuration = video.duration;
      const isVideoOk = videoDuration <= duration;
      if (!isVideoOk) {
        message.error(i18n.t('Video duration should be less than 3 minutes'));
      }
      resolve(isVideoOk);
    };

    video.onerror = (event: Event | string, sourceData?: string, lineno?: number, colno?: number, error?: Error) => {
      message.error(i18n.t('This format not accepted, try mp4'));
      console.error('An error occurred while processing video. Error: ', video?.error);
      Logger.err(
        ['VIDEOERROR', 'ONERROR'],
        `Video: ${JSON.stringify(video)} / Event: ${JSON.stringify(event)} / Source: ${JSON.stringify(
          sourceData
        )} / Lineno: ${JSON.stringify(lineno)} / Colno: ${JSON.stringify(colno)}`,
        JSON.stringify(error)
      );

      resolve(false);
    };

    video.src = URL.createObjectURL(source);
  });

export const checkVideo = async (file: RcFile) => {
  const { type, size } = file;
  const isVideo = type.search('video/') > -1;
  if (!isVideo) {
    message.error(i18n.t('Only video files'));
  }
  const isLt100M = size / 1024 / 1024 < 100;
  if (!isLt100M) {
    message.error(i18n.t('Size should be less than 100MB'));
  }

  return isVideo && isLt100M;
};

const getYouTubeId = (url: string): string => {
  const arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return arr[2] !== undefined ? arr[2].split(/[^\w-]/i)[0] : arr[0];
};

interface IGetYoutubeDataValue {
  title: string;
  description: string;
  youtubeId: string;
  preview: string;
}

export const getYouTubeData = (url: string): Promise<IGetYoutubeDataValue | boolean> => {
  const youtubeId = getYouTubeId(url);
  return fetch(
    `https://www.googleapis.com/youtube/v3/videos?id=${youtubeId}&key=${options.googleApiKey}&part=snippet,contentDetails`
  )
    .then(res => res.json())
    .then((res = {}) => {
      const { items } = res;

      if (items && items.length) {
        const [videoDetails] = items;
        const duration = videoDetails?.contentDetails?.duration;
        const title = videoDetails?.snippet?.title || '';
        const description = videoDetails?.snippet?.description || '';
        const preview = videoDetails?.snippet?.thumbnails?.medium?.url || '';

        if (duration) {
          const durationInSeconds = moment.duration(duration).asSeconds();

          if (durationInSeconds > maxVideoDuration) {
            message.error(i18n.t('Video duration should be less than 3 minutes'));
            return false;
          }

          return {
            description: description.replace(/↵/g, '\n').substr(0, maxVideoDescriptionLength),
            title: title.substr(0, maxVideoTitleLength),
            youtubeId,
            preview
          };
        }
      }
      message.error(i18n.t('Error while getting video information'));
      return false;
    })
    .catch(err => {
      console.error('Error while getting youtube video Info: ', err);
      message.error(i18n.t('Error while getting video information'));
      return false;
    });
};
