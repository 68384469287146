import React, { FC } from 'react';
import { Row, Col, Space } from 'antd';
import classnames from 'classnames';
import Spin from 'antd/es/spin';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { PlayerTestResultChart } from 'entities/PlayerTest/components/PlayerTestResutChart';
import { IPlayerProfileMicroSkillModel } from 'entities/Player/Player.models';

interface IComponentProps {
  datasets: IPlayerProfileMicroSkillModel[];
  value?: string;
  onSelectTest: (id: string) => void;
  loading?: boolean;
}

const IMAGE_SIZE = 48;
const ROW_GUTTER_VERTICAL = 16;
const ICONS_COL_WIDTH = IMAGE_SIZE * 2 + ROW_GUTTER_VERTICAL;
const TITLE_COL_WIDTH = `calc(100% - ${ICONS_COL_WIDTH}px)`;

const ICONS_COL_STYLE = { minWidth: ICONS_COL_WIDTH, width: ICONS_COL_WIDTH, maxWidth: ICONS_COL_WIDTH };
const TITLE_COL_STYLE = { minWidth: TITLE_COL_WIDTH, width: TITLE_COL_WIDTH, maxWidth: TITLE_COL_WIDTH };

type AllProps = IComponentProps;

export const PlayerSkillsData: FC<AllProps> = props => {
  const { datasets, onSelectTest, value, loading } = props;

  return (
    <Spin spinning={loading}>
      <Space direction="vertical" size="large" className="width-full">
        {datasets.map(data => (
          <Row key={data.tag} gutter={ROW_GUTTER_VERTICAL} justify="space-between" wrap={false}>
            <Col style={TITLE_COL_STYLE}>
              <PlayerTestResultChart data={data} />
            </Col>
            <Col className="pr-0" style={ICONS_COL_STYLE}>
              <Row gutter={[4, 4]}>
                {data.tests.map(({ iconId, testId, iconJcId }) => (
                  <Col
                    className={classnames('cursor-pointer', { 'opacity-50': testId === value })}
                    key={testId}
                    onClick={() => onSelectTest(testId)}
                  >
                    <ImagePrivate
                      preview={false}
                      height={IMAGE_SIZE}
                      width={IMAGE_SIZE}
                      imgId={iconId || iconJcId}
                      alt="Image-test"
                      className="mr-0 radius-default d-block"
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        ))}
      </Space>
    </Spin>
  );
};
