import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { ModalUrls } from 'common/const/rating-page.const';
import { PublicPlayerParentsModal } from 'entities/PublicPlayer/components/Modals/PublicPlayerParentsModal';
import { PublicPlayerAcademiesModal } from 'entities/PublicPlayer/components/Modals/PublicPlayerAcademiesModal';
import { PublicPlayerPlayersModal } from 'entities/PublicPlayer/components/Modals/PublicPlayerPlayersModal';
import { PublicPlayerPlayerModal } from 'entities/PublicPlayer/components/Modals/PublicPlayerPlayerModal';

export const PublicPlayerModals: React.FC = () => {
  const { hash, search } = useLocation();
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (hash) {
      setModalVisible(true);
    }
  }, [hash]);

  const isModalVisible = (modalUrl: ModalUrls) => {
    return modalVisible && hash === modalUrl;
  };

  const onCloseModal = () => {
    setModalVisible(false);
    history.push({ search, hash: '' });
  };

  return (
    <>
      <PublicPlayerParentsModal onCancel={onCloseModal} visible={isModalVisible(ModalUrls.Parents)} />
      <PublicPlayerAcademiesModal onCancel={onCloseModal} visible={isModalVisible(ModalUrls.Academies)} />
      <PublicPlayerPlayersModal onCancel={onCloseModal} visible={isModalVisible(ModalUrls.Players)} />
      <PublicPlayerPlayerModal onCancel={onCloseModal} visible={isModalVisible(ModalUrls.Player)} />
    </>
  );
};
