import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import message from 'antd/es/message';
import Popconfirm from 'antd/es/popconfirm';
import { useFormMapper } from '@axmit/antd4-helpers';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { SCREEN_MD } from 'common/const/config.const';
import { ERoutesAcademy } from 'common/models/routesModel';
import { externalIdRules, requiredField } from 'common/helpers/filed-rules';
import { isAcademyFranchisor } from 'common/helpers/franchise.helper';
import { communicationTeam, ITeamConnectedProps } from 'entities/Team/Team.communication';
import { teamTransport } from 'entities/Team/Team.transport';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

interface IFormTeamUpdateInfoValues {
  name: string;
}

type AllProps = ITeamConnectedProps & IAuthConnectedProps;

const TeamFormComponent: React.FC<AllProps> = props => {
  const { teamModel, addTeamModel, updateTeamModel, authAcademy } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { academyId } = useParams();
  const isDesktopMedia: boolean = useMediaPredicate(`(min-width: ${SCREEN_MD})`);
  const [isLoading, setLoading] = useState(false);
  const { data: team, loading: teamLoading, params: teamParams, errors: teamErrors } = teamModel;
  const { fields } = useFormMapper(['name', 'externalId'], team, teamParams, teamErrors);
  const isEditForm = !!team?.id;
  const loading = isLoading || teamLoading;
  const isAuthAcademyFranchisor = isAcademyFranchisor(authAcademy?.data);
  const isAvailableRfsTests = authAcademy?.data?.isAvailableRfsTests;
  const webhookUrl = authAcademy?.data?.franchiseRequest?.franchise?.webhookUrl;
  const showExternalId = isAuthAcademyFranchisor && webhookUrl && isAvailableRfsTests;

  const onFinishTeamInfoUpdate = (values: IFormTeamUpdateInfoValues) => {
    if (team?.id) {
      updateTeamModel({ id: team.id, ...values });
    } else {
      addTeamModel({ academy: academyId, ...values });
    }
  };
  const removeTeam = async () => {
    if (team?.id) {
      try {
        setLoading(true);
        await teamTransport.removeTeam({ id: team.id });
        history.push(`${ERoutesAcademy.AcademyView}/${academyId}/teams`);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
        if (e?.data?.code === 'error.teamNotEmptyException') {
          message.error(t('Team must be empty'));
        }
      }
    }
  };

  return (
    <Form onFinish={onFinishTeamInfoUpdate} fields={fields} name="update-team-info" layout="vertical" className="width-full">
      <Row>
        <Col flex={1}>
          <Row justify="space-between" gutter={[0, 16]}>
            <Col xs={24} md={isEditForm ? (showExternalId ? 6 : 12) : showExternalId ? 8 : 17}>
              <Form.Item rules={[requiredField]} name="name" label={t('Title')} className="mb-0">
                <Input disabled={loading} size="large" />
              </Form.Item>
            </Col>
            {showExternalId && (
              <Col xs={24} md={isEditForm ? (showExternalId ? 6 : 12) : showExternalId ? 9 : 17}>
                <Form.Item
                  rules={[externalIdRules]}
                  name="externalId"
                  label={`${t('External Id')}${t('Optional')}`}
                  className="mb-0"
                >
                  <Input disabled={loading} size="large" />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} md={6}>
              <Row className={isDesktopMedia ? 'mt-8 pt-1' : ''}>
                <Button htmlType="submit" block type="primary" size="large" disabled={loading} loading={loading}>
                  {t('Save')}
                </Button>
              </Row>
            </Col>
            {isEditForm && (
              <Col xs={24} md={5}>
                <Row className={isDesktopMedia ? 'mt-8 pt-1' : ''}>
                  <Popconfirm
                    okButtonProps={{
                      type: 'text',
                      size: 'middle',
                      danger: true
                    }}
                    cancelButtonProps={{
                      size: 'middle'
                    }}
                    okText={t('Delete')}
                    cancelText={t('Cancel')}
                    title={t('Are you sure you want to delete the team?')}
                    onConfirm={removeTeam}
                  >
                    <Button block size="large" disabled={loading} loading={loading}>
                      {t('Remove')}
                    </Button>
                  </Popconfirm>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export const TeamForm = communicationAuth.injector(communicationTeam.injector(TeamFormComponent));
