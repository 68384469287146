import React from 'react';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import Col from 'antd/es/col';
import { useTranslation } from 'react-i18next';
import AircraftIcon from 'app/assets/icons/aircraft.svg';

interface IComponentProps {
  onClose: () => void;
}
type AllProps = IComponentProps;

const FranchiseInviteModalFinalComponent: React.FC<AllProps> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <Row className="t-align-c" align="middle" justify="center">
      <Col span={24} className="mb-5">
        <img height={128} src={AircraftIcon} alt="AircraftIcon" />
      </Col>
      <Col span={24} className="mb-5">
        <Typography.Title className="mb-0" level={2}>
          {t('Request has been sent')}
        </Typography.Title>
      </Col>
      <Col span={24} className="mb-5">
        <Typography.Text className="mb-0"> {t('Wait for an academy worker to accept your offer')}</Typography.Text>
      </Col>
      <Col span={24}>
        <Button block onClick={onClose} size="large">
          {t('Close')}
        </Button>
      </Col>
    </Row>
  );
};

export const FranchiseInviteModalFinal = FranchiseInviteModalFinalComponent;
