import React, { useEffect } from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Spin from 'antd/es/spin';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { LayoutContent } from 'common/components/Layouts/ContentLayout';
import { ShareProfile } from 'entities/Share/components/ShareProfile';
import { communicationPublicPlayer, IPublicPlayerConnectedProps } from 'entities/PublicPlayer/PublicPlayer.communication';
import { PHYSICAL_SKILLS, TECH_SKILLS } from 'entities/Player/Player.models';
import { ShareSkills } from 'entities/Share/components/ShareSkills';
import { getImageUrl } from 'entities/Image/Image.helper';

const PlayerPublicRatingPageComponent: React.FC<IPublicPlayerConnectedProps> = props => {
  const { t } = useTranslation();
  const { getPublicPlayerModel, publicPlayerModel } = props;
  const { id } = useParams();
  const { loading, data } = publicPlayerModel;
  const { firstName, lastName, rating, image, ageGroup, skills } = data || {};
  const imgUrl = getImageUrl(image?.id);

  useEffect(() => {
    getPublicPlayerModel(id);
  }, [id]);

  return (
    <LayoutContent rowClassName="promo-container">
      <Spin spinning={loading}>
        <Row gutter={[16, 16]} className="promo-container__content">
          <>
            <Col lg={8} md={24} sm={24} xs={24}>
              <ShareProfile
                firstName={firstName}
                lastName={lastName}
                rating={rating}
                ageGroup={ageGroup}
                imageUrl={imgUrl}
                skills={skills}
              />
            </Col>
            <Col lg={8} md={12} sm={12} xs={24}>
              <ShareSkills color="#006FF2" title={t('Physical training')} skillsList={PHYSICAL_SKILLS} skills={skills} />
            </Col>
            <Col lg={8} md={12} sm={12} xs={24}>
              <ShareSkills color="#00C1FF" title={t('Technical training')} skillsList={TECH_SKILLS} skills={skills} />
            </Col>
          </>
        </Row>
      </Spin>
      <div className="promo-background"> </div>
    </LayoutContent>
  );
};

export const PlayerPublicRatingPage = communicationPublicPlayer.injector(PlayerPublicRatingPageComponent);
