import React, { useMemo } from 'react';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { SubscriptionsTabList } from 'entities/Subscription/components/PaymentAndSubscriptions/SubscriptionsTabList';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';

type AllProps = IMentorRequestConnectedProps;

const SubscriptionsTabComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const { mentorRequestCollection } = props;

  const { count, activeSubscriptions } = useMemo(
    () => ({
      activeSubscriptions: mentorRequestCollection?.data?.meta?.activeSubscriptions ?? 0,
      count: mentorRequestCollection?.data?.meta?.count ?? 0
    }),
    [mentorRequestCollection?.data]
  );

  return (
    <div
      className={`payment-and-subscription-page__subscriptions-tab p-5 radius-default bg-component ${isDesktop ? '' : 'mobile'}`}
    >
      <Typography.Title level={4} className="mb-5">
        {t('Active for players', { withSubscription: activeSubscriptions, count })}
      </Typography.Title>
      <SubscriptionsTabList elementId="basicLayout" isDesktop={isDesktop} />
    </div>
  );
};

export const SubscriptionsTab = communicationMentorRequest.injector(SubscriptionsTabComponent);
