import { useFormMapper } from '@axmit/antd4-helpers';
import React from 'react';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import Input from 'antd/es/input';
import { useParams } from 'react-router';
import { requiredField } from 'common/helpers/filed-rules';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';
import { IInviteMentorParams } from 'entities/MentorRequest/MentorRequest.models';

interface IComponentProps {
  onClose: () => void;
  playerId?: string;
  inviteFromList?: boolean;
}
type AllProps = IMentorRequestConnectedProps & IComponentProps;

const MentorInviteCreateFormComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { mentorRequestInviteModel, addMentorRequestInviteModel, playerId, inviteFromList } = props;
  const { params, errors, loading } = mentorRequestInviteModel;
  const { id } = useParams();
  const player = id || playerId;

  const { fields } = useFormMapper(['email'], null, params, errors);

  const addRequest = (values: any) => {
    const { email } = values;
    if (player) {
      const body: IInviteMentorParams = {
        player,
        email,
        inviteFromList
      };

      addMentorRequestInviteModel(body);
    }
  };

  return (
    <Form layout="vertical" onFinish={addRequest} fields={fields}>
      <Form.Item className="mb-5" rules={[requiredField]} name="email" label={t('Mentor email')}>
        <Input disabled={loading} type="email" size="large" />
      </Form.Item>
      <Typography.Text className="d-block mb-5">{t('Mentor must accept the invitation in the personal account')}</Typography.Text>
      <Form.Item className="mb-0">
        <Button block size="large" htmlType="submit" type="primary" disabled={loading} loading={loading}>
          {t('Send request')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export const MentorInviteCreateForm = communicationMentorRequest.injector(MentorInviteCreateFormComponent);
