import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';
import Typography from 'antd/es/typography';
import Row from 'antd/es/row';
import SuccessIcon from 'app/assets/images/payment-success.png';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';

type AllProps = IUIConnectedProps;

const IconSize = 128;

const FranchiseInvitationAcceptSuccessModalComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { closeUIFranchiseInvitationAcceptModal, UIFranchiseInvitationAcceptModal } = props;
  const isVisible = UIFranchiseInvitationAcceptModal?.data?.isVisible;
  const franchiseName = UIFranchiseInvitationAcceptModal?.data?.franchiseName;

  const onCancel = () => {
    closeUIFranchiseInvitationAcceptModal();
  };

  return (
    <Modal
      visible={isVisible}
      width={375}
      footer={null}
      onCancel={onCancel}
      centered
      destroyOnClose
      className="payment-check-modal"
    >
      <Row justify="center" className="mt-9 mb-5">
        <img alt={t('franchise-success-text', { franchiseName })} src={SuccessIcon} width={IconSize} height={IconSize} />
      </Row>

      <Typography.Title level={3} className="width-full t-align-c mb-3">
        {t('Successful')}
      </Typography.Title>
    </Modal>
  );
};

export const FranchiseInvitationAcceptSuccessModal = communicationUI.injector(FranchiseInvitationAcceptSuccessModalComponent);
