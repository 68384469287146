import { IBasePathParams } from 'common/models/requestModels';
import { ILocationModel } from 'common/helpers/location.helper';
import { ITrainerModel } from 'entities/Trainer/Trainer.models';
import { ITeamModel } from 'entities/Team/Team.models';
import { IAcademyModel, IAcademySearchModel } from 'entities/Academy/Academy.models';

export interface IPlayerFileCollection extends Components.Schemas.FileCollectionDto {}
export interface IPlayerFileModel extends Components.Schemas.FileDto {}

export interface IPrivatePlayerCreateParams
  extends Omit<Components.Schemas.CreatePrivatePlayerDto, 'trainers' | 'teams'>,
    IBasePathParams {
  trainers?: ITrainerModel[];
  teams?: ITeamModel[];
}
export interface IPrivatePlayerUpdateParams
  extends Omit<Components.Schemas.UpdatePrivatePlayerDto, 'trainers' | 'teams'>,
    IBasePathParams {
  trainers?: ITrainerModel[];
  teams?: ITeamModel[];
}
export interface IPrivatePlayerParamsModel extends IPrivatePlayerUpdateParams, IBasePathParams {}

export interface IPrivatePlayerCollectionFilter extends Paths.TrainerControllerListPlayers.QueryParameters {
  location?: ILocationModel;
  playerName?: string;
  academyFilter?: Partial<IAcademySearchModel> | null;
  ageGroup?: Paths.PlayerControllerGetAcademyListWithPlayer.Parameters.AgeGroup;
  trainerModel?: Partial<ITrainerModel>;
  teamModel?: Partial<ITeamModel>;
}

export enum EPrivatePlayerSearchCollectionFilterSorters {
  ByAcademy = 'byAcademy'
}
export type TPlayerFilterOrderField =
  | 'createdAt'
  | 'updatedAt'
  | 'ageGroup'
  | 'rating'
  | 'name'
  | 'pace'
  | 'agility'
  | 'physical'
  | 'dribbling'
  | 'passing'
  | 'shooting';

export interface IPrivatePlayerSearchCollectionFilter extends Paths.PlayerControllerSearch.QueryParameters {
  location?: ILocationModel;
  playerName?: string;
  academyFilter?: Partial<IAcademySearchModel> | null;
  ageGroup?: Paths.PlayerControllerGetAcademyListWithPlayer.Parameters.AgeGroup;
  trainerModel?: Partial<ITrainerModel>;
  teamModel?: Partial<ITeamModel>;
  orderField?: EPrivatePlayerSearchCollectionFilterSorters; // Убрать когда бек добавит поддержку
}

export interface ITransferPlayerParams
  extends Omit<Components.Schemas.MovePrivatePlayerDto, 'trainers' | 'academy' | 'teams'>,
    IBasePathParams {
  academy: IAcademyModel;
  trainers?: ITrainerModel[];
  teams?: ITeamModel[];
}

export interface IMagicTokenModel extends Components.Schemas.MagicTokenDto {}
export interface ITransferPlayerParamsApi extends Components.Schemas.MovePrivatePlayerDto, IBasePathParams {}

export interface ITransferModalForm {
  academy: IAcademyModel;
  trainers?: ITrainerModel[];
  teams?: ITeamModel[];
}

export enum ELeadingLeg {
  Left = 'left',
  Right = 'right'
}

export enum EPrivatePlayerTabKeys {
  Profile = 'profile',
  Progress = 'progress',
  Tests = 'tests',
  TestResults = 'test-results',
  Info = 'info',
  Documents = 'documents'
}

export enum EPrivatePlayersErrorCodes {
  Forbidden = 'error.forbiddenException',
  TeamLinkExist = 'error.teamLinkExistException'
}

export enum EPrivatePlayersSuccessMessage {
  UpdateSuccess = 'Information about player is updated',
  TransferSuccess = 'Player was successfully transferred'
}

export enum EPrivatePlayerActionTypes {
  InviteMentor = 'inviteMentor',
  Share = 'Share',
  Unlink = 'unlink',
  UnlinkFromTeam = 'unlinkFromTeam',
  Archive = 'archive',
  Compare = 'compare',
  Favorite = 'favorite'
}

export type TPrivatePlayerActionTypes = EPrivatePlayerActionTypes | EPrivatePlayerActionTypes[];
