import React from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';

interface IComponentProps {
  actionButton?: React.ReactNode;
  startText: string;
}

type AllProps = IComponentProps;

const ListNoDataComponent: React.FC<AllProps> = props => {
  const { actionButton, startText } = props;
  const { t } = useTranslation();

  return (
    <Row justify="center" align="middle" className="players-nodata-list">
      <Col xs={24} sm={12}>
        <Typography.Title className="mb-4" level={2}>
          {t('Welcome to JuniStat')}
        </Typography.Title>
        <Typography.Paragraph className="mb-3 mt-0">
          {t('Develop and identify world class talent with a data driven approach')}
        </Typography.Paragraph>
        <Typography.Paragraph className="mb-5 mt-0">{t(startText)}</Typography.Paragraph>
        <Col span={24}>{actionButton}</Col>
      </Col>
    </Row>
  );
};

export const ListNoData = ListNoDataComponent;
