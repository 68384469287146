import { Col, Row } from 'antd';
import React from 'react';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { ISubscriptionConnectedProps, communicationSubscription } from 'entities/Subscription/Subscription.communication';
import { GiftcardForm } from 'entities/Subscription/components/GiftcardForm';
import { ESubscriptionsEngines } from 'entities/Subscription/Subscription.models';

interface IProps {
  subscriptionId?: string;
  mentorRequestId?: string;
}

type AllProps = IProps & ISubscriptionConnectedProps;

const Component: React.FC<AllProps> = props => {
  const { subscriptionModel, subscriptionId, mentorRequestId } = props;
  const { t } = useTranslation();
  const { errors, loading } = subscriptionModel;

  return (
    <Row className="stripe-giftcard-card p-5 border-light radius-small height-full">
      <Col xs={24}>
        <Typography.Title level={5} className="mb-3 color-disable">
          {t('giftcard')}
        </Typography.Title>
      </Col>
      <Col xs={24}>
        <GiftcardForm
          engine={ESubscriptionsEngines.Stripe}
          errors={errors}
          loading={loading}
          subscriptionId={subscriptionId}
          mentorRequest={mentorRequestId}
          showApplyButton
        />
      </Col>
    </Row>
  );
};

export const StripeGiftcardCard = communicationSubscription.injector(Component);
