import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import Row from 'antd/es/row';
import { useMediaPredicate } from 'react-media-hook';
import { SortOrder } from 'antd/lib/table/interface';
import Button from 'antd/es/button';
import ControlOutlined from '@ant-design/icons/ControlOutlined';
import Modal from 'antd/es/modal/Modal';
import { normalizeUnitResult } from 'common/helpers/normalize.helper';
import { SCREEN_XL } from 'common/const/config.const';
import { dateFormatter, formatDateFromServer } from 'common/helpers/date.helper';
import { notExistTranslationWrapper } from 'common/helpers/translate.helper';
import { serverDateFormat, clientDateFullMonthFormat } from 'common/models/dateModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { parseOrderField, translateTestData } from 'common/helpers/test.helper';
import { getAcademyPlayerRoute, getPlayerRoute } from 'common/models/routesModel';
import { MeasurementContext } from 'common/components/Provider/MeasurementContext';
import { TestResultTableCommon } from 'entities/Test/components/Table/TestResultTableCommon';
import { IModalTestResultModel } from 'entities/UI/UI.models';
import {
  tableTestResultConfig,
  initTestResultFilter,
  ITestResultsHistoryCollectionFilter,
  ETestResultFilter,
  ITestResultsHistoryModel,
  ITestResultsHistoryPlayerTestResultModel
} from 'entities/Test/Test.models';
import { TestResultTableFilterCard } from 'entities/Test/components/Table/TestResultTableFilterCard';
import { EUserRole, IUserModel } from 'entities/User/User.models';
import { PlayerProfileProviderContext } from 'entities/Player/components/PlayerProvider';

interface IComponentProps {
  modalModel: IModalTestResultModel | null;
  disableFilters?: boolean;
  filterConfig?: ETestResultFilter[];
  user?: IUserModel | null;
}

type AllProps = IComponentProps;

const TestResultTableComponent: React.FC<AllProps> = ({ modalModel, disableFilters, filterConfig, user }: AllProps) => {
  const { t } = useTranslation();
  const params = modalModel?.params;
  const { profile } = React.useContext(PlayerProfileProviderContext);
  const playerAcademyId = profile?.data?.academy?.academyId;
  const { testTag, tags, id } = params || {};
  const [tableConfig, setTableConfig] = useState<ColumnsType<any>>();
  const { measurementSystem } = useContext(MeasurementContext);
  const { title } = translateTestData({ tag: testTag }, measurementSystem);
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${SCREEN_XL})`);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const [state, setState] = useState<Partial<ITestResultsHistoryCollectionFilter>>({
    ...initTestResultFilter,
    playerAcademyId,
    ...queryToObject<Partial<ITestResultsHistoryCollectionFilter>>(initTestResultFilter),
    ...params
  });

  const debPush = useDebouncedCallback((val: any) => {
    history.replace(val);
  }, 300);

  useEffect(() => {
    debPush.callback({ search: objectToQuery(state) });
  }, [state]);

  // eslint-disable-next-line react/no-multi-comp
  const renderItem = (item?: Partial<ITestResultsHistoryPlayerTestResultModel>) => {
    const { value, isBest, units, isHigh } = item || {};

    //  todo check after JS-4569
    return (
      <Row
        justify="center"
        title={isBest && isHigh ? `${t('Top 5%')} / ${t('Best result')}` : isBest ? t('Best result') : isHigh ? t('Top 5%') : ''}
      >
        <div
          className={`${
            isBest && isHigh
              ? 'test-result-history-table__together'
              : isBest
              ? 'test-result-history-table__high'
              : isHigh
              ? 'test-result-history-table__high'
              : ''
          }`}
        >
          {normalizeUnitResult(value, units, measurementSystem)} {notExistTranslationWrapper(units, {}, measurementSystem)}
        </div>
      </Row>
    );
  };

  const buildConfig = () => {
    let parsedConfig: any[];
    try {
      parsedConfig = tags
        ? tags.map(item => {
            return {
              title: <span className="pre-wrap">{t(item)}</span>,
              dataIndex: item,
              key: item,
              render: renderItem,
              ellipsis: true,
              sorter: true,
              width: 200
            };
          })
        : [];
    } catch {
      parsedConfig = [];
    }

    const configArray = tableTestResultConfig(isDesktop, user?.role).filter(item => item !== undefined);
    const sliceFirst = configArray.slice(0, 5);
    const sliceSecond = configArray.slice(5);
    let newConfig = [...sliceFirst, ...parsedConfig, ...sliceSecond];
    const orderField = state?.orderField;
    const orderDirection = state?.orderDirection;
    if (parseOrderField(orderField, tags)) {
      const defaultSortOrder = (orderDirection === 'ASC' ? 'ascend' : 'descend') as SortOrder;
      newConfig = newConfig?.map(item => {
        if (item?.dataIndex === orderField) {
          return { ...item, defaultSortOrder };
        }
        return item;
      });
    }

    if (disableFilters) {
      newConfig = newConfig.map(item => (item.key === 'team' ? { ...item, sorter: false } : item));
    }
    setTableConfig(newConfig);
  };

  useEffect(() => {
    buildConfig();
  }, [tags]);

  const onResultClickCallback = useCallback(
    (testResult: ITestResultsHistoryModel) => {
      const isMyAcademyPlayer =
        user?.role === EUserRole.AcademyWorker && user?.academyWorker?.academy?.id && testResult?.player?.linkedWithMe;
      const playerRoute = getPlayerRoute(testResult.player.id);
      const path = isMyAcademyPlayer
        ? user?.academyWorker?.academy?.id
          ? getAcademyPlayerRoute(testResult.player.id, user.academyWorker.academy.id)
          : playerRoute
        : playerRoute;

      history.push(path);
    },
    [user, history]
  );

  return (
    <div className="test-result-history-table">
      <Row className="test-result-history-table__header pr-8" justify="space-between" align="middle">
        <span>{title}</span>
        {disableFilters && state.from && (
          <span>{dateFormatter(formatDateFromServer(state.from, serverDateFormat), clientDateFullMonthFormat)}</span>
        )}
      </Row>
      {isDesktop ? (
        <TestResultTableFilterCard
          onFilterChange={partialFilter => setState({ ...state, ...partialFilter })}
          filter={state}
          filterConfig={filterConfig}
          disableFilters={disableFilters}
        />
      ) : (
        <div>
          <Button
            className="my-4 test-result-history-table__button width-full"
            size="middle"
            type="primary"
            icon={<ControlOutlined />}
            onClick={() => {
              setShowModal(true);
            }}
          >
            {t('Filter')}
          </Button>
          <Modal
            style={{ maxWidth: '100vw', minHeight: '100vh', maxHeight: '100vh', margin: 0, top: 0 }}
            bodyStyle={{ border: 'none' }}
            width="auto"
            visible={showModal}
            onCancel={() => setShowModal(false)}
            footer={false}
          >
            <TestResultTableFilterCard
              onFilterChange={partialFilter => setState({ ...state, ...partialFilter })}
              filter={state}
              filterConfig={filterConfig}
              disableFilters={disableFilters}
            />
          </Modal>
        </div>
      )}
      {tableConfig && id && (
        <TestResultTableCommon
          props={{
            showHeader: true,
            scroll: { x: 1300, y: 750 },
            rowKey: 'id',
            bordered: false,
            size: 'middle'
          }}
          filter={{ ...state, id }}
          config={tableConfig}
          onResultClick={onResultClickCallback}
        />
      )}
    </div>
  );
};

export const TestResultTable = TestResultTableComponent;
