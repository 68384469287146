import React, { useCallback, useMemo } from 'react';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import Space from 'antd/es/space';
import Modal from 'antd/es/modal';
import Popconfirm from 'antd/es/popconfirm';
import message from 'antd/es/message';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { formatDateFromServer } from 'common/helpers/date.helper';
import { clientDateFormat, clientDateFullMonthFormat } from 'common/models/dateModels';
import { translateTariffData } from 'common/helpers/tariff-helper';
import StopIcon from 'app/assets/icons/stop.svg';
import { PlayerUnlink } from 'entities/Player/components/ActionButtons/PlayerUnlink';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import { communicationSubscription, ISubscriptionConnectedProps } from 'entities/Subscription/Subscription.communication';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';
import { EPaymentActivitiesStatus, ESubscriptionsEngines, ESubscriptionStatus } from 'entities/Subscription/Subscription.models';
import { SubscriptionButton } from 'entities/Subscription/components/SubscriptionButton';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { ETariffStatus } from 'entities/Tariff/Tariff.models';
import { isAcademyHideTariffs, isPrepaymentDisabled } from 'entities/Subscription/helpers/subscription.helper';
import { PlayerVisibility } from 'entities/Player/components/PlayerVisibility';

interface IComponentProps {
  isDesktop: boolean;
}

type AllProps = IComponentProps &
  IPlayerConnectedProps &
  ISubscriptionConnectedProps &
  IMentorRequestConnectedProps &
  IUIConnectedProps;

export const PlayerSettingTabComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const [isOpenDialog, setOpenDialog] = React.useState(false);
  const {
    playerModel,
    updateSubscriptionModel,
    openUIPaymentRequirementsModal,
    openUIChangeTariffModal,
    subscriptionModel,
    deleteSubscriptionActivityModel,
    isDesktop
  } = props;
  const { data: playerData } = playerModel;
  const { loading: subscriptionLoading } = subscriptionModel;
  const mentorRequestId = playerData?.mentorRequest?.id;
  const subscription = playerData?.mentorRequest?.subscription;
  const tariffId = subscription?.tariff?.id;
  const tariffData = translateTariffData(subscription?.tariff);
  const isPaid = playerData?.isPaid;
  const hideTariffs = useMemo(() => isAcademyHideTariffs(playerData), [playerData]);

  const paidTill = useMemo(() => formatDateFromServer(subscription?.prepaidTo, clientDateFullMonthFormat), [subscription]);

  const isSubscriptionActive = useMemo(() => subscription && subscription.status === ESubscriptionStatus.Active, [subscription]);

  const isSubscriptionExpired = useMemo(() => subscription && subscription.status === ESubscriptionStatus.Expired, [
    subscription
  ]);

  const isCheckingLastPayment = useMemo(() => {
    if (subscription && subscription.lastActivity) {
      return (
        subscription.lastActivity.status === EPaymentActivitiesStatus.New || subscription.lastActivity.isApplied === undefined
      );
    }

    return false;
  }, [subscription]);

  const onUnsubscribe = useCallback(() => {
    if (subscription?.id) {
      updateSubscriptionModel({
        id: subscription.id,
        status: ESubscriptionStatus.Canceled
      });
      setOpenDialog(false);
    }
  }, [subscription]);

  const isNewSubscription = useMemo(() => {
    return isSubscriptionExpired && !subscription?.prepaidTo;
  }, [subscription]);

  const subscriptionStatusLabel = useMemo(() => {
    if (isNewSubscription) {
      return t('New');
    }
    if (isSubscriptionExpired && subscription?.lastActivity?.status === EPaymentActivitiesStatus.New) {
      return t('Not active');
    }
    return t(`status-player-card.${subscription?.status || 'not-active'}`);
  }, [subscription]);

  const onPayClick = useCallback(() => {
    if (isPrepaymentDisabled(subscription)) {
      message.error(t('prepaymentRestrictedException'));
    } else if (subscription?.id) {
      openUIPaymentRequirementsModal({
        subscriptionId: subscription.id
      });
    }
  }, [subscription]);

  const isAbleToRemoveCardData = useMemo(
    () => subscription?.hasSavedPaymentMethod && (subscription?.delayedCancellation || isSubscriptionExpired),
    [subscription]
  );

  const isTariffArchived = useMemo(() => subscription?.tariff?.status === ETariffStatus.Archived, [subscription]);

  const deletePaymentsData = useCallback(() => {
    if (subscription?.id) {
      deleteSubscriptionActivityModel(subscription?.id);
    }
  }, [subscription]);

  const onChangeTariff = useCallback(() => {
    if (subscription?.id) {
      openUIChangeTariffModal({
        subscriptionId: subscription?.id,
        tariffId
      });
    }
  }, [subscription]);

  const whenArchiveText = useMemo(
    () =>
      (!isTariffArchived &&
        subscription?.tariff?.activeTo &&
        t('archive-will-be', { date: moment(subscription.tariff.activeTo).format(clientDateFormat) })) ||
      '',
    [isTariffArchived, subscription]
  );

  return (
    <Row gutter={[16, 16]} className="player-settings">
      <PlayerVisibility isDesktop={isDesktop} />
      {!isPaid && !hideTariffs && (
        <Col xs={24}>
          <Card className="border-none px-3">
            <Typography.Title level={4} className="mb-3">
              {t('Subscription')}
            </Typography.Title>
            <Typography.Title level={5} className="mt-0">
              {subscriptionStatusLabel} <br />
              {isSubscriptionActive && !subscription?.delayedCancellation && (
                <>
                  <Row>
                    {t('next-payment', {
                      date: paidTill,
                      cost: `${subscription?.tariff?.currentPrice?.amount ||
                        subscription?.tariffAmount?.amount ||
                        0} ${subscription?.tariff?.currentPrice?.currency || subscription?.tariffAmount?.currency || ''}`
                    })}
                  </Row>
                  <Row>
                    {t('tariff-info', {
                      tariff: tariffData.title,
                      isArchived: isTariffArchived ? t('Archived') : '',
                      whenArchive: whenArchiveText
                    })}
                  </Row>
                </>
              )}
              {(isNewSubscription || isSubscriptionExpired || subscription?.delayedCancellation) &&
                tariffData.title &&
                t('Chosen tariff', {
                  name: tariffData.title,
                  cost: `${subscription?.tariff?.currentPrice?.amount || subscription?.tariffAmount?.amount || 0} ${subscription
                    ?.tariff?.currentPrice?.currency ||
                    subscription?.tariffAmount?.currency ||
                    ''}`,
                  isArchived: isTariffArchived ? t('Archived') : '',
                  whenArchive: whenArchiveText
                })}
            </Typography.Title>
            <Row>
              <Col span={isDesktop ? undefined : 12}>
                <SubscriptionButton
                  playerId={playerData?.id}
                  hideTariffs={hideTariffs}
                  isPaid={isPaid}
                  mentorRequestSubscriptionEngine={ESubscriptionsEngines.Yookassa}
                  subscription={subscription}
                  mentorRequestId={mentorRequestId}
                  unsubscribeElement={
                    <Button disabled={subscriptionLoading} onClick={() => setOpenDialog(true)}>
                      {t('Unsubscribe')}
                    </Button>
                  }
                />
              </Col>
              {subscription && (
                <Button type="primary" className="ml-5" onClick={onChangeTariff}>
                  {t('Change tariff')}
                </Button>
              )}
            </Row>
          </Card>
        </Col>
      )}
      {isSubscriptionActive &&
        !subscription?.delayedCancellation &&
        !isCheckingLastPayment &&
        !isTariffArchived &&
        !isPaid &&
        !hideTariffs && (
          <Col xs={24}>
            <Card className="border-none px-3">
              <Typography.Title level={4} className="mb-3">
                {t('Payment')}
              </Typography.Title>
              <Typography.Title level={5} className="mt-0">
                {t('payment-description')}
              </Typography.Title>
              <Button type="primary" onClick={onPayClick}>
                {t('Pay for a Subscription')}
              </Button>
            </Card>
          </Col>
        )}
      <Col xs={24}>
        <Card className="border-none px-3">
          <Typography.Title className="mb-3" level={4}>
            {t('Unlink player')}
          </Typography.Title>
          <Typography.Title level={5} className="mt-0">
            {t('unlink-description')}
          </Typography.Title>
          <PlayerUnlink mentorRequestId={mentorRequestId as string} disabled={subscriptionLoading} />
        </Card>
      </Col>
      {isAbleToRemoveCardData && (
        <Col xs={24}>
          <Card className="border-none px-3">
            <Typography.Title level={4} className="mb-3">
              {t('Payment data')}
            </Typography.Title>
            <Typography.Title level={5} className="mt-0">
              {t('payments-data-remove-description')}
            </Typography.Title>
            <Popconfirm
              okButtonProps={{
                type: 'text',
                size: 'middle',
                danger: true
              }}
              cancelButtonProps={{
                size: 'middle'
              }}
              okText={t('Delete')}
              cancelText={t('Cancel')}
              title={t('Are you sure you want to delete payment data?')}
              onConfirm={deletePaymentsData}
            >
              <Button>{t('Delete')}</Button>
            </Popconfirm>
          </Card>
        </Col>
      )}
      <Modal
        centered
        bodyStyle={{ border: 'none' }}
        width={376}
        onCancel={() => setOpenDialog(false)}
        footer={false}
        title={<div />}
        visible={isOpenDialog}
      >
        <Row className="px-3" justify="center" align="middle">
          <Space className="t-align-c" direction="vertical" size={16}>
            <img alt="stop" src={StopIcon} />
            <Typography.Title level={3}>{t('Unsubscribe?')}</Typography.Title>
            <Typography.Text className="width-full d-block t-align-c">
              {t('unsubscribe-description', { date: paidTill })}
            </Typography.Text>
            <Button onClick={onUnsubscribe} className="width-full" block type="text" size="large" danger>
              {t('Unsubscribe')}
            </Button>
            <Button onClick={() => setOpenDialog(false)} className="width-full" block type="primary" size="large">
              {t('Leave')}
            </Button>
          </Space>
        </Row>
      </Modal>
    </Row>
  );
};

export const PlayerSettingTabYookassa = communicationUI.injector(
  communicationMentorRequest.injector(communicationSubscription.injector(communicationPlayer.injector(PlayerSettingTabComponent)))
);
