// Axios initialization
import { configureAxiosJWTInterseptors, ICreds } from 'axios-patch-jwt';
import { IAsyncStorage, WebStorageDecorator } from 'universal-storage';
import axios from 'axios';
import { EActionsTypes, getSuccessType } from '@axmit/redux-communications';
import { createBrowserHistory } from 'history';
import window from 'global/window';
import { errorMapper } from 'fe-error-helper';
import i18n from 'common/helpers/i18n';
import { getErrorMessageByCode } from 'common/helpers/translate.helper';
import { getSessionId } from 'common/helpers/hash.helper';
import { SocketIOService } from 'common/ws/SocketIo';
import { CLIENT_TIMEZONE } from 'common/const/config.const';
import createStore from 'app/store/createStore';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initialState = (window as any).___INITIAL_STATE__;
export const history = createBrowserHistory();
export const store = createStore(initialState, history);
SocketIOService.setStore(store);

// Axios initialization

const storage: IAsyncStorage = new WebStorageDecorator(localStorage);

export const axiosConfig = {
  storage,
  axios,
  refreshTokenEndpoint: '/auth',
  onSaveCreds: (creds: ICreds) => {
    store.dispatch({ type: getSuccessType('auth', 'model', EActionsTypes.add), payload: creds });
  }
};

configureAxiosJWTInterseptors(axiosConfig);

const translate = (error: [string, string, object]) => {
  const [code = '', message, params] = error;
  const codeMessage = getErrorMessageByCode(code, params);
  return codeMessage || i18n.t(`${message}`);
};

export const initAxios = () => {
  axios.defaults.baseURL = `/api`;
  axios.interceptors.request.use(async config => {
    const state = store.getState();
    const sessionId = await getSessionId();
    const id = state?.auth?.model?.data?.access?.userId;
    const clientReqId = `${id ? id + '_' : ''}${sessionId ? sessionId + '_' : 'no_session_id'}${Date.now()}_${Math.round(
      Math.random() * 10000000000
    )}`;
    const params = { ...(config.params || {}) };
    return {
      ...config,
      params,
      headers: {
        'request-time': Date.now(),
        'client-req-id': clientReqId,
        ...config.headers
      }
    };
  });
  axios.interceptors.response.use(
    response => {
      if (!response?.config) {
        return response;
      }
      return response?.data;
    },
    error => errorMapper(error, undefined, undefined, translate)
  );
};

export const setHeaders = (roleType: string) => {
  axios.defaults.headers.common['Source-Service'] = roleType;
  axios.defaults.headers.common['junistat-timezone'] = CLIENT_TIMEZONE;
};

export const getBaseUrl = () => {
  return axios.defaults.baseURL;
};
export const getRoleTypeFromHeaders = () => {
  return axios.defaults.headers.common['Source-Service'];
};
