import React, { useEffect } from 'react';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { queryToObject } from 'common/helpers/filters.helper';
import { LayoutContent } from 'common/components/Layouts/ContentLayout';
import { ERoutesMentor } from 'common/models/routesModel';
import { IMentorRequestModel, gridMentorRequestConfig, EMentorRequestStatus } from 'entities/MentorRequest/MentorRequest.models';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { MentorRequestList } from 'entities/MentorRequest/components/MentorRequestList';
import { MentorRequestModal } from 'entities/MentorRequest/components/MentorRequestModal';
import { EMentorRequestModalType } from 'entities/UI/UI.models';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';
import { PlayerMergeModal } from 'entities/Player/components/Modal/PlayerMergeModal';
import { PlayerEmailEditModal } from 'entities/Player/components/Modal/PlayerEmailEditModal';
import { EUserRole } from 'entities/User/User.models';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
// Hidden in JS-4319
// import { SubscriptionsCompare } from 'common/components/SubsriptionsCompare';

interface IQuery {
  playerIdRedirect?: string;
}

type AllProps = IUIConnectedProps & IMentorRequestConnectedProps & IAuthConnectedProps & RouteComponentProps;

const MentorRequestPageComponent: React.FC<AllProps> = props => {
  const { mentorRequestCollection, openUIPlayerMergeModal, openUIMentorRequestModal, authUser } = props;
  const { data, loading } = mentorRequestCollection;
  const { t } = useTranslation();
  const history = useHistory();
  const { playerIdRedirect } = queryToObject<IQuery>({ playerIdRedirect: undefined }) as IQuery;

  const showPlayerPage = (itemModel: IMentorRequestModel) => {
    const playerId = itemModel.player.id;

    history.push(`${ERoutesMentor.Player}/${playerId}`);
  };

  useEffect(() => {
    if (playerIdRedirect && !!data?.data.length) {
      const mentorRequest = data?.data.find(item => item?.player?.id === playerIdRedirect);

      if (mentorRequest) {
        showPlayerPage(mentorRequest);
      }
    }
  }, [playerIdRedirect, data]);

  useEffect(() => {
    const redirectMentor = async () => {
      const firstMentorRequest = data?.data[0];

      if (data?.meta.count === 1 && firstMentorRequest?.status === EMentorRequestStatus.Accepted) {
        const authUserId = authUser?.data?.id;
        const authUserRole = authUser?.data?.role;
        const mentorId = await AsyncStorage.getItem('mentorIdOpenPlayer');

        if ((!mentorId || mentorId !== authUserId) && authUserRole === EUserRole.Mentor) {
          await AsyncStorage.setItem('mentorIdOpenPlayer', authUserId || '');
          showPlayerPage(firstMentorRequest);
        }
      }
    };
    redirectMentor();
  }, [data, loading]);

  const showAddPlayerModal = () => {
    openUIMentorRequestModal({ modalType: EMentorRequestModalType.AddPlayer });
  };
  return (
    <LayoutContent className="mentor-request-page">
      <Row className="instruments-panel pb-5" justify="space-between" align="middle">
        <Col>
          <Typography.Title className="mb-0" level={2}>
            {t('My footballers')}
          </Typography.Title>
        </Col>
        {!!data?.data?.length && (
          <Col xs={24} md={14} lg={12} xl={8}>
            <Row gutter={[16, 16]} align="middle">
              <Col xs={24} sm={12}>
                <Button block size="large" onClick={() => openUIPlayerMergeModal()}>
                  {t('Merge profiles')}
                </Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button block size="large" type="primary" onClick={showAddPlayerModal}>
                  {t('Add player')}
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row>
        <MentorRequestList elementId="basicLayout" onItemClick={showPlayerPage} grid={gridMentorRequestConfig} />
        {/* Hidden in JS-4319 */}
        {/* {!!data?.data?.length && <SubscriptionsCompare />}*/}
      </Row>
      <PlayerMergeModal />
      <PlayerEmailEditModal />
      <MentorRequestModal />
    </LayoutContent>
  );
};

export const MentorRequestPage = communicationAuth.injector(
  communicationUI.injector(communicationMentorRequest.injector(withRouter(MentorRequestPageComponent)))
);
