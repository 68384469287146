import { options } from 'options';
import React, { useEffect } from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Paragraph from 'antd/es/typography/Paragraph';
import window from 'global/window';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { queryToObject } from 'common/helpers/filters.helper';
import { changeI18nLanguage } from 'common/helpers/i18n';
import { CenteredWithLogoLayout } from 'common/components/Layouts/CenteredWithLogoLayout';
import { ReactComponent as CopyIcon } from 'app/assets/icons/copy-icon.svg';
import { ReactComponent as OkIcon } from 'app/assets/icons/ok.svg';
import { getCoachGoogleLink } from 'entities/ReferralCode/ReferralCode.helper';

interface IQuery {
  token?: string;
  lang?: string;
}
export const CoachPasswordRestorePage: React.FC = () => {
  const { t } = useTranslation();
  const { token, lang } = queryToObject<IQuery>({ token: undefined, lang: undefined }) as IQuery;
  const langPart = lang ? `%26lang%3D${lang}` : '';

  useEffect(() => {
    if (lang) {
      changeI18nLanguage(lang);
    }
  }, []);

  const link = token
    ? `${window.location?.origin}/redirect?efr=1&ibi=${options.coachGooglePlayId}&isi=${options.coachAppStoreId}&apn=${options.coachGooglePlayId}&link=https%3A%2F%2F${window.location.host}%2Fpassword-restore%2Fcoach%26token%3D${token}${langPart}`
    : getCoachGoogleLink();

  return (
    <CenteredWithLogoLayout>
      <div className="restore-password-page width-full p-7">
        <Row justify="center" align="middle">
          <span className="t-align-c fs-sm width-full mb-4">{t('Restore password')}</span>
          <span className="t-align-c fs-xxs width-full mb-4">{t('Scan qr for restore password')}</span>
          <Row className="flex-column" justify="center" align="middle">
            <Col>
              <QRCode value={link} size={200} includeMargin={true} />
            </Col>
            <span className="t-align-c fs-xxs width-full mb-4 mt-4">{t('Or')}</span>
            <span className="t-align-c fs-xxs width-full mb-4">{t('Click to icon for copy')}</span>

            <Paragraph
              copyable={{
                icon: [<CopyIcon key="copy-icon" />, <OkIcon key="copy-icon" />],
                tooltips: [t('Click for copy'), t('Copied')],
                text: link
              }}
            />
          </Row>
        </Row>
      </div>
    </CenteredWithLogoLayout>
  );
};
