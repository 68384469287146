import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  buildCollectionResponseFormatter,
  buildCollectionPreRequestDataMapper
} from '@axmit/redux-communications';
import { takeLatest } from 'redux-saga/effects';
import { academyRequestTransport } from 'entities/AcademyRequest/AcademyRequest.transport';
import {
  IAcademyRequestCollectionFilter,
  IAcademyRequestCollection,
  IAcademyRequestUpdateParams,
  IAcademyRequestModelParams,
  IAcademyRequestModel,
  IAcademyRequestPlayerToAcademyParams,
  IAcademyRequestAcademyToPlayerParams
} from 'entities/AcademyRequest/AcademyRequest.models';
import { openMentorRequestToAcademySuccessModal } from 'entities/UI/UI.communication';
import { getForPlayerAcademyModel } from 'entities/Academy/Academy.communication';

const namespace = 'academyRequest';

export interface IAcademyRequestConnectedProps {
  academyRequestModel: StoreBranch<IAcademyRequestModel, IAcademyRequestModelParams>;
  academyRequestCollection: StoreBranch<IAcademyRequestCollection, IAcademyRequestCollectionFilter>;
  getAcademyRequestModel(id: string): void;
  updateAcademyRequestModel(params: IAcademyRequestUpdateParams): void;
  playerToAcademyRequestModel(params: IAcademyRequestPlayerToAcademyParams): void;
  academyToPlayerAcademyRequestModel(params: IAcademyRequestAcademyToPlayerParams): Promise<IAcademyRequestModel>;

  getAcademyRequestCollection(filter?: IAcademyRequestCollectionFilter): void;

  clearAcademyRequestModel(): void;
  clearAcademyRequestCollection(): void;
}

const AcademyRequestModelAPIProviders = [
  new APIProvider(EActionsTypes.get, academyRequestTransport.get),
  new APIProvider(
    EActionsTypes.update,
    academyRequestTransport.update,
    {
      onSuccess: function*(response: IAcademyRequestModel) {
        yield getForPlayerAcademyModel({ playerId: response.player.id, academyId: response.academy.id });
      }
    },
    takeLatest
  ),
  new APIProvider('academyToPlayer', academyRequestTransport.requestAcademyToPlayer, {}, takeLatest),
  new APIProvider('playerTo', academyRequestTransport.requestPlayerToAcademy, {
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    },
    onSuccess: function*(response: IAcademyRequestModel) {
      yield openMentorRequestToAcademySuccessModal();
      yield getForPlayerAcademyModel({ playerId: response.player.id, academyId: response.academy.id });
    }
  })
];
const AcademyRequestCollectionAPIProviders = [
  new APIProvider(
    EActionsTypes.get,
    academyRequestTransport.getCollection,
    {
      mapSuccess: buildCollectionResponseFormatter<any, any>(),
      preRequestDataMapper: buildCollectionPreRequestDataMapper<IAcademyRequestCollection, IAcademyRequestCollectionFilter>()
    },
    takeLatest
  )
];

const branches = [
  new Branch('model', AcademyRequestModelAPIProviders),
  new Branch('collection', AcademyRequestCollectionAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationAcademyRequest = buildCommunication<IAcademyRequestConnectedProps>(strategy);
