import { options } from 'options';
import React from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { useMediaPredicate } from 'react-media-hook';
import { scrollToTop } from 'common/helpers/dom.helper';
import { SCREEN_MD, SCREEN_XS } from 'common/const/config.const';
import logo from 'app/assets/images/logo-monochrome.svg';
import logoSmall from 'app/assets/images/logo-monochrome-small.svg';
import AndroidPlayEn from 'app/assets/images/android-play-en.svg';
import AppStoreEn from 'app/assets/images/app-store-en.svg';
import { getAppleLink, getGoogleLink } from 'entities/ReferralCode/ReferralCode.helper';
import { LanguageSelect } from 'entities/Language/components/LanguageSelect';

const HeaderComponent = () => {
  const isXs: boolean = useMediaPredicate(`(max-width: ${SCREEN_XS})`);
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${SCREEN_MD})`);

  return (
    <header className="ant-menu basic__header width-full px-5 promo-header">
      <Row
        className="flex-noWrap height-full width-full basic__header_content promo-container"
        justify="space-around"
        align="middle"
      >
        <Col flex={1}>
          <a href={options.siteUrl} onClick={() => scrollToTop('basicLayout')}>
            <img className="basic__header_logo" src={isXs ? logoSmall : logo} alt="logo" />
          </a>
        </Col>
        <Col flex={1}>
          <Row gutter={[8, 8]} justify="end" align="middle" wrap={false}>
            <Col>
              <a href={getAppleLink()} target="_blank" rel="noopener noreferrer">
                <img src={AppStoreEn} alt="app-store" />
              </a>
            </Col>
            <Col>
              <a href={getGoogleLink()} target="_blank" rel="noopener noreferrer">
                <img src={AndroidPlayEn} alt="app-store" />
              </a>
            </Col>
            {isDesktop && (
              <Col md={6} lg={5}>
                <LanguageSelect className="width-full" />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </header>
  );
};

export const HeaderPromo = HeaderComponent;
