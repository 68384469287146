import React, { useEffect } from 'react';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Skeleton from 'antd/es/skeleton';
import Typography from 'antd/es/typography';
import Spin from 'antd/es/spin';
import { useParams } from 'react-router';
import { useMediaPredicate } from 'react-media-hook';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined';
import classNames from 'classnames';
import { locationFormatter } from 'common/helpers/location.helper';
import { ERoutesMentor } from 'common/models/routesModel';
import { MEDIA_DESKTOP, SCREEN_LG } from 'common/const/config.const';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
// import { AgeGroupSelector } from 'common/components/Selector/AgeGroupSelector';
import { communicationAcademy, IAcademyConnectedProps } from 'entities/Academy/Academy.communication';
import { MentorRequestToAcademyModal } from 'entities/AcademyRequest/components/Modal/MentorToAcademyRequestModal';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { EMentorRequestToAcademyModalType } from 'entities/UI/UI.models';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import { communicationAcademyRequest, IAcademyRequestConnectedProps } from 'entities/AcademyRequest/AcademyRequest.communication';
import { ESubscriptionStatus } from 'entities/Subscription/Subscription.models';
import { EAcademyStatus } from 'entities/Academy/Academy.models';
import { EAcademyRequestStatus } from 'entities/AcademyRequest/AcademyRequest.models';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EPlayerTabKeys } from 'entities/Player/Player.models';

const AVATAR_DESKTOP_SIZE = 144;
const AVATAR_MOBILE_SIZE = 112;

type AllProps = IAcademyConnectedProps &
  IUIConnectedProps &
  IPlayerConnectedProps &
  IAcademyRequestConnectedProps &
  IAuthConnectedProps;

const MentorAcademyPageComponent: React.FC<AllProps> = props => {
  const {
    getForPlayerAcademyModel,
    academyModel,
    clearAcademyModel,
    openUIMentorRequestToAcademyModal,
    playerModel,
    playerToAcademyRequestModel,
    updateAcademyRequestModel,
    // authUser,
    academyRequestModel
  } = props;
  const { academyId } = useParams();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const { t } = useTranslation();
  const { data: academyData, loading: academyLoading } = academyModel;
  const academyName = academyData?.name || t('Unknown name');
  const location = locationFormatter(academyData?.address) || t('Unknown address');
  const descriptionValue = academyData?.description || t('No description');
  const imageId = academyData?.image?.id;
  // const totalResultsCount = academyData?.totalResultsCount;
  const academyStatusActive = academyData?.status === EAcademyStatus.Active;
  const playerId = playerModel.data?.id;
  // const ageGroups = playerModel.data?.ageGroup;
  const playerToAcademyRequest = academyData?.playerToAcademyRequest;
  // const academyAgeGroups = academyData?.ageGroups;
  const isSubscriptionActive = playerModel.data?.mentorRequest?.subscription?.status === ESubscriptionStatus.Active;
  // const [ageGroupValue, setAgeGroupValue] = useState(ageGroups);
  // const [testList, setTestList] = useState<IPlayerTestBaseResult[] | undefined>(
  //   academyData?.tests?.find(item => item.ageGroup === ageGroups)?.tests
  // );
  // const [passedResultCount, setPassedResultCount] = useState<number | undefined>(
  //   academyData?.tests?.find(item => item.ageGroup === ageGroups)?.passedResultCount
  // );

  const { loading } = academyRequestModel;
  // const { data: authUserData } = authUser;
  // const isMentorSubActive = permissionHelper(
  //   EPermissionType.ShowDataForMentorSubActive,
  //   authUserData,
  //   playerModel?.data,
  //   academyData
  // );
  // const showTestConfig = permissionHelper(
  //   EPermissionType.ShowDataForMentorAcademyTestConfig,
  //   authUserData,
  //   playerModel?.data,
  //   academyData
  // );

  useEffect(() => {
    if (!!playerId && !!academyId) {
      getForPlayerAcademyModel({ academyId, playerId });
    }
    return () => clearAcademyModel();
  }, [academyId, playerId]);

  // useEffect(() => {
  // const findAgeGroupTests = academyData?.tests?.find(item => item.ageGroup === ageGroupValue);
  // setTestList(findAgeGroupTests?.tests);
  // setPassedResultCount(findAgeGroupTests?.passedResultCount);
  // }, [ageGroupValue, academyData]);

  const onClickAcademyButton = () => {
    if (playerId && isSubscriptionActive) {
      playerToAcademyRequestModel({ player: playerId, academy: academyId });
    } else {
      openUIMentorRequestToAcademyModal({ modalType: EMentorRequestToAcademyModalType.AddSub });
    }
  };
  const cancelAcademyRequest = () => {
    if (playerToAcademyRequest) {
      const requestId = playerToAcademyRequest?.id;
      if (requestId) {
        updateAcademyRequestModel({ id: requestId, status: EAcademyRequestStatus.Aborted });
      }
    }
  };

  const isLg: boolean = useMediaPredicate(`(min-width: ${SCREEN_LG})`);

  // const getPassedPlayerTest = (academyBaseResultsArray: IAcademyBaseResultWithPlayerValue[]) => {
  //   const academyTestsCount = academyBaseResultsArray?.length || 0;
  //   const playersPassedTestsCount =
  //     academyBaseResultsArray?.reduce((acc: number, item: IAcademyBaseResultWithPlayerValue) => {
  //       if (item.isPassed) {
  //         return acc + 1;
  //       }
  //       return acc;
  //     }, 0) || 0;
  //   return { academyTests: academyTestsCount, playerTestsResult: playersPassedTestsCount };
  // };

  // const isAcademyAcceptsThatAge = useMemo(() => ageGroupValue && academyAgeGroups?.includes(ageGroupValue), [ageGroupValue]);

  // const renderBaseResult = (academyBaseResultsArray: IAcademyBaseResultWithPlayerValue[]) =>
  //   academyBaseResultsArray.map(item => {
  //     const { playerValue, value, baseResult, id, isPassed } = item;
  //
  //     const title = t(baseResult.tag);
  //     const unit = notExistTranslationWrapper(baseResult.units);
  //     const label = unit.length ? `${title}, ${unit}` : title;
  //
  //     return (
  //       <Col xs={24} key={id}>
  //         <Row className="px-4 py-3 radius-default bg-page">
  //           <Col xs={24}>
  //             <Typography.Text className="mb-2 d-block" type="secondary">
  //               {label}
  //             </Typography.Text>
  //           </Col>
  //           <Col xs={24}>
  //             <Row>
  //               <Col xs={12} sm={6} md={6}>
  //                 <Typography.Title className={`mb-0 ${isPassed ? '' : 'color-light-danger'}`} level={5}>
  //                   {normalizeTestValue(baseResult.tag, playerValue)}
  //                 </Typography.Title>
  //               </Col>
  //               <Col xs={12} sm={6} md={6}>
  //                 <Typography.Title type={showTestConfig ? undefined : 'secondary'} className="mb-0" level={5}>
  //                   {showTestConfig ? `${t(`${baseResult.ranging}`)} ${normalize(value)}` : <LockFilled />}
  //                 </Typography.Title>
  //               </Col>
  //             </Row>
  //             <Row>
  //               <Col xs={12} sm={6} md={6}>
  //                 <Typography.Paragraph className="mb-0" type="secondary">
  //                   {t('Result')}
  //                 </Typography.Paragraph>
  //               </Col>
  //               <Col xs={12} sm={6} md={6}>
  //                 <Typography.Paragraph className="mb-0" type="secondary">
  //                   {t('Requirement')}
  //                 </Typography.Paragraph>
  //               </Col>
  //             </Row>
  //           </Col>
  //         </Row>
  //       </Col>
  //     );
  //   });

  // const renderPanelItems = (baseResults: IPlayerTestBaseResult[]) =>
  //   baseResults?.map(resultItem => {
  //     const { id, video, academyBaseResults, firstImage, secondImage } = resultItem;
  //     const videoId = video?.id;
  //     const videoPreview = video?.image?.id;
  //     const imagePreview = firstImage?.id || secondImage?.id;
  //     const { academyTests, playerTestsResult } = getPassedPlayerTest(academyBaseResults);
  //     const { title } = translateTestData(resultItem);
  //
  //     return (
  //       <Collapse.Panel
  //         className="players__academy__page__tests__item bg-component-secondary radius-default"
  //         header={
  //           <Row className="width-full" justify="space-between" wrap={false}>
  //             <Typography.Text title={title} className="mr-1">
  //               {title}
  //             </Typography.Text>
  //             <Typography.Paragraph className="mb-0 color-text-secondary">
  //               {playerTestsResult}/{academyTests}
  //             </Typography.Paragraph>
  //           </Row>
  //         }
  //         key={id}
  //       >
  //         <Row className="flex-noWrap">
  //           {videoId ? (
  //             <VideoPrivate width={88} height={88} videoId={videoId} preview={videoPreview || imagePreview} className="mr-4" />
  //           ) : (
  //             <ImagePrivate height={88} width={88} imgId={imagePreview} alt={t('Test preview')} className="mr-4 radius-default" />
  //           )}
  //           <Col className="width-full">
  //             <Row gutter={[16, 16]}>{renderBaseResult(academyBaseResults)}</Row>
  //           </Col>
  //         </Row>
  //       </Collapse.Panel>
  //     );
  //   });

  return (
    <Spin spinning={academyLoading}>
      <Link to={`${ERoutesMentor.Player}/${playerId}/${EPlayerTabKeys.Academy}`}>
        <Button
          className={classNames('mb-5', { 'ml-5': !isLg })}
          type="default"
          icon={<ArrowLeftOutlined />}
          title={t('Back to academy list')}
        >
          {t('Back to academy list')}
        </Button>
      </Link>
      <Card bordered={false} className="px-3 radius-default">
        <Col span={24}>
          <Row justify="center" align="middle">
            {imageId ? (
              <ImagePrivate
                height={isDesktop ? AVATAR_DESKTOP_SIZE : AVATAR_MOBILE_SIZE}
                width={isDesktop ? AVATAR_DESKTOP_SIZE : AVATAR_MOBILE_SIZE}
                imgId={imageId}
                alt={t('Academy avatar')}
                className="border-circle"
              />
            ) : (
              <Row justify="center">
                <Skeleton.Image
                  className="border-circle"
                  style={{
                    width: isDesktop ? AVATAR_DESKTOP_SIZE : AVATAR_MOBILE_SIZE,
                    height: isDesktop ? AVATAR_DESKTOP_SIZE : AVATAR_MOBILE_SIZE
                  }}
                />
              </Row>
            )}
          </Row>
          <Row justify="center" className="mt-5" align="middle">
            <Typography.Title level={4} title={academyName} className="t-align-c m-0">
              {academyName}
            </Typography.Title>
          </Row>
          <Row justify="center" className="mt-3" align="middle">
            <Typography.Paragraph type="secondary" className="t-align-c mb-0" title={location}>
              <EnvironmentOutlined className="mr-2" />
              {location}
            </Typography.Paragraph>
          </Row>
          <Row justify="center" className="width-full mt-3">
            <Col xs={24} sm={22}>
              <Row gutter={[8, 8]} justify="center">
                {/* <Col xs={24} sm={14}>*/}
                {/*  <Row gutter={[8, 0]}>*/}
                {/* <Col xs={24} sm={6}>*/}
                {/*  <AgeGroupSelector*/}
                {/*    defaultValue={ageGroups}*/}
                {/*    className="width-full t-align-c players__academy__page__age-selector"*/}
                {/*    size="large"*/}
                {/*    // onChange={setAgeGroupValue}*/}
                {/*  />*/}
                {/* </Col>*/}
                {/* <Col span={16} />*/}
                {/* <Row*/}
                {/*  justify="center"*/}
                {/*  align="middle"*/}
                {/*  className="height-full flex-noWrap players__academy__page__result-card"*/}
                {/* >*/}
                {/*  <Typography.Text*/}
                {/*    ellipsis*/}
                {/*    title={t(`Points out of points`, {*/}
                {/*      result: passedResultCount || 0,*/}
                {/*      count: totalResultsCount || 0*/}
                {/*    })}*/}
                {/*  >*/}
                {/*    {t(`Points out of points`, {*/}
                {/*      result: passedResultCount || 0,*/}
                {/*      count: totalResultsCount || 0*/}
                {/*    })}*/}
                {/*  </Typography.Text>*/}
                {/*  <Badge*/}
                {/*    className={`ml-4 players__academy_badge players__academy_badge-${resultColor(passedResultCount)}`}*/}
                {/*    color=""*/}
                {/*  />*/}
                {/* </Row>*/}
                {/* </Col>*/}
                {/* </Row>*/}
                {/* </Col>*/}
                <Col xs={24} sm={10}>
                  {playerToAcademyRequest ? (
                    <Button
                      className="ellipsis"
                      block
                      size="large"
                      loading={loading}
                      onClick={cancelAcademyRequest}
                      disabled={!academyStatusActive}
                      title={t('Cancel request')}
                    >
                      {t('Cancel request')}
                    </Button>
                  ) : (
                    <Button
                      className="ellipsis"
                      block
                      type="primary"
                      size="large"
                      loading={loading}
                      onClick={onClickAcademyButton}
                      disabled={!academyStatusActive}
                      title={t('Want to academy')}
                    >
                      {t('Want to academy')}
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="center" className="mt-4 width-full">
            {/* <Tabs defaultActiveKey="1" centered className="width-full players__academy__page__tabs">*/}
            {/* <Tabs.TabPane tab={t('Requirements')} key="1">*/}
            {/*  {isMentorSubActive ? (*/}
            {/*    testList?.length ? (*/}
            {/*      <>*/}
            {/*        {!showTestConfig && (*/}
            {/*          <Row className="width-full mb-5" justify="center" align="middle">*/}
            {/*            <Typography.Text title={t('Academy hid test requirements')}>*/}
            {/*              {t('Academy hid test requirements')}*/}
            {/*            </Typography.Text>*/}
            {/*          </Row>*/}
            {/*        )}*/}
            {/*        <Collapse className="players__academy__page__tests border-none bg-component">*/}
            {/*          {renderPanelItems(testList)}*/}
            {/*        </Collapse>*/}
            {/*      </>*/}
            {/*    ) : isAcademyAcceptsThatAge ? (*/}
            {/*      <Typography.Text title={t('Requirements list empty')}>{t('Requirements list empty')}</Typography.Text>*/}
            {/*    ) : (*/}
            {/*      <Typography.Text title={t('The Academy does not accept children of this age category')}>*/}
            {/*        {t('The Academy does not accept children of this age category')}*/}
            {/*      </Typography.Text>*/}
            {/*    )*/}
            {/*  ) : (*/}
            {/*    <MentorRequestToAcademyWithoutSub />*/}
            {/*  )}*/}
            {/* </Tabs.TabPane>*/}
            {/* <Tabs.TabPane tab={t('Description')} key="2">*/}
            <Typography.Text title={descriptionValue}>{descriptionValue}</Typography.Text>
            {/* </Tabs.TabPane>*/}
            {/* </Tabs>*/}
          </Row>
        </Col>
        <MentorRequestToAcademyModal />
      </Card>
    </Spin>
  );
};

export const MentorAcademyPage = communicationAuth.injector(
  communicationAcademyRequest.injector(
    communicationPlayer.injector(communicationUI.injector(communicationAcademy.injector(MentorAcademyPageComponent)))
  )
);
