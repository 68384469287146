import React, { useMemo } from 'react';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Popconfirm from 'antd/es/popconfirm';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { locationFormatter } from 'common/helpers/location.helper';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { isAcademyFranchisor as isAcademyFranchisorHelper } from 'common/helpers/franchise.helper';
import { EFranchiseStatuses, IFranchiseRequestModel } from 'entities/Franchise/Franchise.models';
import { IFranchiseConnectedProps, communicationFranchise } from 'entities/Franchise/Franchise.communication';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';
import { EAcademyStatus, IAcademyModel } from 'entities/Academy/Academy.models';

interface IComponentProps {
  franchiseRequest?: IFranchiseRequestModel;
  academy: IAcademyModel;
  showFranchiseInfo?: boolean;
}

type AllProps = IComponentProps & IFranchiseConnectedProps & IAuthConnectedProps;

const avatarWidth = 56;
const avatarHeight = 56;

export const AcademyViewCardComponent: React.FC<AllProps> = props => {
  const { franchiseRequest, academy, updateStatusFranchiseRequestModel, authAcademy, showFranchiseInfo = true } = props;
  const { t } = useTranslation();
  const { address, name, image } = academy;
  const location = locationFormatter(address) || t('Unknown address');
  const formattedName = name || franchiseRequest?.inviteEmail || t('Unknown name');
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const imgId = image?.id;
  const franchiseRequestStatus = franchiseRequest?.status;
  const franchiseRequestId = franchiseRequest?.id;
  const franchiseRequestType = franchiseRequest?.type;
  const franchiseName = franchiseRequest?.franchise?.name;

  const isCurrentAcademy = useMemo(() => academy?.id === authAcademy?.data?.id, [authAcademy, academy]);
  const isAcademyFranchisor = useMemo(() => academy && isAcademyFranchisorHelper({ ...academy, franchiseRequest }), [academy]);
  const isRequestActive = useMemo(
    () => franchiseRequest?.status === EFranchiseStatuses.Active && academy?.status === EAcademyStatus.Active,
    [franchiseRequest, academy]
  );

  const statusText = useMemo(() => {
    switch (true) {
      case franchiseRequest?.status === EFranchiseStatuses.New:
        return 'waiting-apply';
      case franchiseRequest?.status === EFranchiseStatuses.Active && academy?.status === EAcademyStatus.New:
        return 'waiting-registration';
      case isRequestActive:
        return 'active';
      case franchiseRequest?.status === EFranchiseStatuses.Active && academy?.status === EAcademyStatus.Moderation:
        return 'moderation';
    }

    return 'not-active';
  }, [academy, franchiseRequest]);

  const onAbortRequest = (e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    if (franchiseRequestId) {
      updateStatusFranchiseRequestModel({
        id: franchiseRequestId,
        status: EFranchiseStatuses.Canceled
      });
    }
  };

  const isInFranchise = franchiseRequestId && franchiseRequestStatus && franchiseRequestType;

  return (
    <Card hoverable bordered={false} className={`franchise-academy-card ${isRequestActive ? '' : 'cursor-not-allowed'}`}>
      <Row className="flex-noWrap" align="top">
        <ImagePrivate
          preview={false}
          imgId={imgId}
          height={avatarHeight}
          width={avatarWidth}
          alt="academy img"
          className="border-circle mr-5"
        />
        <Row justify="space-between" align="middle" className="width-full">
          <Col xs={24} lg={15}>
            <div className="franchise-academy-card__body">
              <Row align="middle">
                <div
                  className="ant-typography-ellipsis ant-typography-ellipsis-multiple-line fs-xxs font-weight-7"
                  title={formattedName}
                >
                  {formattedName}
                </div>
              </Row>
              <Typography.Text type="secondary" className="mt-2">
                <EnvironmentOutlined className="mr-2" />
                {location}
              </Typography.Text>
              {showFranchiseInfo && isInFranchise && franchiseName && (
                <Typography.Text type="secondary" className="mt-2">
                  {t('Franchise name', { franchiseName })}
                </Typography.Text>
              )}
            </div>
          </Col>
          <Col xs={24} lg={7}>
            {isInFranchise && (
              <Row className={isDesktop ? 'mt-0' : 'mt-3'} wrap={false} justify={isDesktop ? 'end' : 'start'} align="middle">
                <Typography.Text type="secondary" className={`${isDesktop ? 't-align-e' : 't-align-s'} mr-3`}>
                  {t(`status-translate.${statusText}`)}
                </Typography.Text>
                {(!isCurrentAcademy || isAcademyFranchisor) && (
                  <Popconfirm
                    placement="top"
                    okButtonProps={{
                      type: 'text',
                      size: 'middle',
                      danger: true,
                      style: isAcademyFranchisor ? { display: 'none' } : {}
                    }}
                    cancelButtonProps={{
                      size: 'middle'
                    }}
                    okText={t('Revoke')}
                    cancelText={t(isAcademyFranchisor ? 'Ok' : 'Leave')}
                    title={t(
                      `${isAcademyFranchisor ? "This is main academy, you can't cancel this request" : 'Cancel the add request?'}`
                    )}
                    onConfirm={onAbortRequest}
                    onCancel={e => e?.stopPropagation()}
                  >
                    <InfoCircleOutlined />
                  </Popconfirm>
                )}
              </Row>
            )}
          </Col>
        </Row>
      </Row>
    </Card>
  );
};

export const AcademyViewCard = communicationAuth.injector(communicationFranchise.injector(AcademyViewCardComponent));
