import * as Sentry from '@sentry/react';

export const setUser = (id: string) => {
  Sentry.setTag('Env', 'FRONT');
  Sentry.setTag('userId', id);
};

const clearContext = () => {
  Sentry.setTag('userId', 'no data');
};

export const SentryTagsHelper = {
  setUser,
  clearContext
};
