import moment, { Moment } from 'moment';
import { serverDateFormat, clientDateFormat, clientDateFullMonthFormat } from 'common/models/dateModels';

export const dateFormatter = (date: string, dateFormat: string = `${clientDateFullMonthFormat}, HH:mm`) =>
  moment(date).format(dateFormat);

export const dateFormatterToUTC = (date: string, dateFormat: string = 'DD MMM YYYY, HH:mm') =>
  moment.utc(date, dateFormat).format();

export const dateDifference = (firstDate: string, secondDate: string) => moment(firstDate).diff(secondDate);
export const dateToMomentFormat = (date: string, format: string) => moment(date, format);

export const formatDateFromServer = (date: string | undefined | null, format: string = clientDateFormat) => {
  if (date) {
    return moment(date, serverDateFormat).format(format);
  }
  return moment().format(format);
};

export function getBirthdayAgeGroup(birthday?: string) {
  return birthday ? moment(birthday).format('YYYY') : '';
}

export const getUTCStartOfDayFromString = (date: string): Moment => {
  const pureDate = moment(date)
    .clone()
    .format(clientDateFormat);

  return moment(`${pureDate}Z`, `${clientDateFormat}Z`)
    .utc()
    .startOf('day');
};

export const disabledFutureDates = (current: Moment) => current && current > moment().endOf('day');

export const getUTCStartOfDay = (day: Moment): string => {
  return day
    .clone()
    .endOf('day')
    .utc()
    .startOf('day')
    .toISOString();
};

export const getUTCEndOfDay = (day: Moment): string => {
  return day
    .clone()
    .endOf('day')
    .utc()
    .endOf('day')
    .toISOString();
};

// hours = "+03:00" | "-03:00"
export const addHours = (day: Moment, hours: string): Moment => {
  return day.clone().add(moment.duration(hours));
};

export const getISOStartOfDays = (day: Moment): Moment => {
  return day
    .clone()
    .utc()
    .startOf('days');
};
