import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  buildCollectionResponseFormatter,
  buildCollectionPreRequestDataMapper,
  getStartType
} from '@axmit/redux-communications';
import { put, select, takeLatest } from 'redux-saga/effects';
import message from 'antd/es/message';
import { history } from 'common/helpers/axios.helper';
import { ERoutesAcademy } from 'common/models/routesModel';
import i18n from 'common/helpers/i18n';
import { IApplicationState } from 'app/store/reducers';
import {
  getPrivatePlayerCollectionStartType,
  removePrivatePlayerCollectionItem
} from 'entities/PrivatePlayer/PrivatePlayer.communication';
import { teamTransport } from 'entities/Team/Team.transport';
import {
  ITeamCollection,
  ITeamCollectionFilter,
  ITeamModel,
  TTeamParamsModel,
  ITeamCreateParams,
  ITeamUpdateParams,
  ITeamUpdatePlayerParams,
  ITeamUpdateTrainerParams,
  IDeleteTeamParams,
  ITeamRemovePlayerParams,
  ITeamRemoveTrainerParams
} from 'entities/Team/Team.models';
import { closeTeamCreateModal } from 'entities/UI/UI.communication';
import { getTrainerCollectionStartType, removeTrainerCollectionItem } from 'entities/Trainer/Trainer.communication';

export const teamNamespace = 'team';

export interface ITeamConnectedProps {
  teamModel: StoreBranch<ITeamModel, TTeamParamsModel>;
  teamAction: StoreBranch<void>;
  teamCollection: StoreBranch<ITeamCollection, ITeamCollectionFilter>;

  getTeamModel(id: string): void;
  addTeamModel(params: ITeamCreateParams): void;
  deleteTeamModel(params: IDeleteTeamParams): void;
  updateTeamModel(params: ITeamUpdateParams): void;
  getTeamCollection(filter?: ITeamCollectionFilter): Promise<void>;

  addPlayerToTeamAction(params: ITeamUpdatePlayerParams): void;
  removePlayerFromTeamAction(params: ITeamRemovePlayerParams): void;
  addTrainerToTeamAction(params: ITeamUpdateTrainerParams): void;
  removeTrainerFromTeamAction(params: ITeamRemoveTrainerParams): void;

  clearTeamModel(): void;
  clearTeamCollection(): void;
}

const TeamModelAPIProviders = [
  new APIProvider(EActionsTypes.get, teamTransport.get),
  new APIProvider(EActionsTypes.delete, teamTransport.removeTeam, {
    onSuccess: (response, originalParams) => {
      if (originalParams?.academyId) {
        history.push(`${ERoutesAcademy.AcademyView}/${originalParams.academyId}/teams`);
      }
    }
  }),
  new APIProvider(EActionsTypes.add, teamTransport.add, {
    onSuccess: function*() {
      yield closeTeamCreateModal();
      yield getTeamCollectionStartType();
    }
  }),
  new APIProvider(EActionsTypes.update, teamTransport.update, {
    onSuccess: function() {
      message.success(i18n.t('Successfully updated'));
    },
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    }
  })
];
const TeamCollectionAPIProviders = [
  new APIProvider(
    EActionsTypes.get,
    teamTransport.getCollection,
    {
      mapParams: originalParams => ({
        ...originalParams,
        orderField: 'name',
        orderDirection: 'ASC'
      }),
      mapSuccess: buildCollectionResponseFormatter<any, any>(),
      preRequestDataMapper: buildCollectionPreRequestDataMapper<ITeamCollection, ITeamCollectionFilter>()
    },
    takeLatest
  )
];

const TeamPlayersAndTrainersAPIProviders = [
  new APIProvider('addPlayerTo', teamTransport.addPlayerToTeam, {
    mapParams: originalParams => ({
      ...originalParams,
      players: originalParams?.playerModels?.map(playerModel => playerModel.id)
    }),
    onSuccess: function*() {
      message.success(i18n.t('Players have been successfully added to the team'));
      yield getPrivatePlayerCollectionStartType();
    }
  }),
  new APIProvider('addTrainerTo', teamTransport.addTrainerToTeam, {
    mapParams: originalParams => ({
      ...originalParams,
      trainers: originalParams?.trainerModels?.map(trainerModel => trainerModel.id)
    }),
    onSuccess: function*() {
      message.success(i18n.t('Trainers have been successfully added to the team'));
      yield getTrainerCollectionStartType();
    }
  }),
  new APIProvider('removePlayerFrom', teamTransport.removePlayerFromTeam, {
    onSuccess: function*(response, originalParams) {
      if (originalParams?.player) {
        yield removePrivatePlayerCollectionItem(originalParams.player);
      }
    }
  }),
  new APIProvider('removeTrainerFrom', teamTransport.removeTrainerFromTeam, {
    onSuccess: function*(response, originalParams) {
      if (originalParams?.trainer) {
        yield removeTrainerCollectionItem(originalParams.trainer);
      }
    }
  })
];

const branches = [
  new Branch('model', TeamModelAPIProviders),
  new Branch('collection', TeamCollectionAPIProviders),
  new Branch('action', TeamPlayersAndTrainersAPIProviders)
];

const strategy = new BaseStrategy({
  namespace: teamNamespace,
  branches
});

export const communicationTeam = buildCommunication<ITeamConnectedProps>(strategy);

export function* getTeamCollectionStartType() {
  const payload: ITeamCollectionFilter = yield select((state: IApplicationState) => state[teamNamespace].collection?.params);

  payload.offset = 0;
  payload.limit = 20;

  yield put({ type: getStartType(teamNamespace, 'collection', EActionsTypes.get), payload });
}
