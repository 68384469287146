import React, { useEffect } from 'react';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import Form from 'antd/es/form';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useFormMapper } from '@axmit/antd4-helpers';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { ERoutesPublic } from 'common/models/routesModel';
import { requiredField, passwordRules } from 'common/helpers/filed-rules';
import { IPasswordRestoreParams } from 'entities/Auth/Auth.models';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps & RouteComponentProps;

const PASSWORD_FIELD_NAME = 'password';

const PasswordRestoreFormComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { updateAuthPasswordRestore, authPasswordRestore, authTokenCheck, history, passwordRestoreAuthTokenCheck } = props;
  const { loading: tokenCheckLoading, errors: tokenCheckError } = authTokenCheck;
  const { errors, params } = authPasswordRestore;
  const { loading } = authPasswordRestore;
  const isTokenNotExpired = !tokenCheckLoading && !tokenCheckError;
  const token = (queryString.parse(history.location.search).token as string) || '';

  const { fields } = useFormMapper(['password'], null, params, errors);

  useEffect(() => {
    passwordRestoreAuthTokenCheck({ token });
  }, [passwordRestoreAuthTokenCheck, token]);

  if (tokenCheckLoading) {
    return <LoadingSpin />;
  }

  const newPassword = (values: any) => {
    const { password } = values;
    const body: IPasswordRestoreParams = {
      password,
      token
    };
    updateAuthPasswordRestore(body);
  };

  return (
    <div className="restore-password-page width-full p-7">
      {isTokenNotExpired ? (
        <>
          <Row justify="start" className="mb-5">
            <Typography.Title level={3}>{t('New password')}</Typography.Title>
          </Row>
          <Form onFinish={newPassword} fields={fields} layout="vertical">
            <Form.Item
              rules={[requiredField, passwordRules]}
              name={PASSWORD_FIELD_NAME}
              label={t('Create complex password')}
              className="mb-5"
            >
              <Input.Password size="large" type="password" />
            </Form.Item>
            <Form.Item
              rules={[
                requiredField,
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue(PASSWORD_FIELD_NAME) === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t('Passwords must be equal'));
                  }
                })
              ]}
              name="repeatPassword"
              label={t('Repeat password')}
              className="mb-5"
            >
              <Input.Password size="large" type="password" />
            </Form.Item>
            <Form.Item className="mb-0">
              <Button
                htmlType="submit"
                type="primary"
                block
                size="large"
                disabled={loading}
                loading={loading}
                title={t('Submit')}
              >
                {t('Submit')}
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : (
        <Row justify="center" align="middle">
          <span className="t-align-c fs-sm width-full">{t('This link is expired')}</span>
          <span className="t-align-c">
            {t('Click')} <Link to={ERoutesPublic.PasswordForgot}>{t('here')}</Link> {t('to request new')}
          </span>
        </Row>
      )}
    </div>
  );
};

export const PasswordRestorePage = communicationAuth.injector(withRouter(PasswordRestoreFormComponent));
