import React from 'react';
import Modal from 'antd/es/modal/Modal';
import { useTranslation } from 'react-i18next';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { EMentorInviteModalType } from 'entities/UI/UI.models';
import { MentorInviteCreateForm } from 'entities/PrivatePlayer/components/View/InviteMentor/MentorInviteCreateForm';
import { MentorInviteModalFinal } from 'entities/PrivatePlayer/components/View/InviteMentor/MentorInviteModalFinal';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';

type AllProps = IUIConnectedProps & IMentorRequestConnectedProps;

const MentorInviteModalComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { UIMentorInviteModal, closeUIMentorInviteModal, clearMentorRequestInviteModel } = props;
  const playerId = UIMentorInviteModal.data?.playerId;
  const isVisible = UIMentorInviteModal.data?.isVisible;
  const modalType = UIMentorInviteModal.data?.modalType;
  const inviteFromList = UIMentorInviteModal.data?.inviteFromList;
  const isAddModal = modalType === EMentorInviteModalType.AddMentor;
  const isAfterAddModal = modalType === EMentorInviteModalType.AfterAddMentor;

  const closeModal = () => {
    clearMentorRequestInviteModel();
    closeUIMentorInviteModal();
  };

  return (
    <Modal
      bodyStyle={{ border: 'none' }}
      width={376}
      visible={isVisible}
      onCancel={closeModal}
      footer={false}
      title={t('Mentor invitation')}
    >
      {isAddModal && <MentorInviteCreateForm onClose={closeModal} playerId={playerId} inviteFromList={inviteFromList} />}
      {isAfterAddModal && <MentorInviteModalFinal onClose={closeModal} />}
    </Modal>
  );
};

export const MentorInviteModal = communicationMentorRequest.injector(communicationUI.injector(MentorInviteModalComponent));
