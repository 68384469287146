import axios from 'axios';
import { RcFile } from 'antd/es/upload';
import { IVideoModel } from 'entities/Video/Video.models';

const basePath = '/videos/for-test';

export const uploadVideo = (video: RcFile, fileName: string): Promise<IVideoModel> => {
  const form = new FormData();
  form.append('video', video, fileName);

  return axios.post(basePath, form);
};
