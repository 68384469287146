import React, { useEffect, useState } from 'react';
import Badge from 'antd/es/badge';
import Button from 'antd/es/button';
import Col from 'antd/es/col';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BellOutlined from '@ant-design/icons/BellOutlined';
import MenuOutlined from '@ant-design/icons/MenuOutlined';
import { useHistory } from 'react-router';
import { ERoutesAcademy, ERoutesCommon, ERoutesMentor } from 'common/models/routesModel';
import { Logo } from 'common/components/Header/Logo';
import { isAcademyFranchiseActive, isAcademyFranchisor } from 'common/helpers/franchise.helper';
import { getImageUrl } from 'entities/Image/Image.helper';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EUserRole } from 'entities/User/User.models';
import { EImageSize } from 'entities/Image/Image.models';

type AllProps = IAuthConnectedProps;

const HeaderComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const [avatar, setAvatar] = useState<string | undefined>(undefined);
  const { push } = useHistory();
  const { authUser, authAcademy } = props;
  const avatarId = authUser?.data?.image?.id || authAcademy?.data?.image?.id;
  const academyId = authAcademy?.data?.id;
  const isAcademyInFranchise = isAcademyFranchiseActive(authAcademy?.data);
  const isFranchisor = isAcademyFranchisor(authAcademy?.data);

  const onLogout = () => {
    const { deleteAuthModel } = props;
    deleteAuthModel();
  };

  useEffect(() => {
    setAvatar(getImageUrl(avatarId, EImageSize.X56));
  }, [authUser, authAcademy]);

  const menu = React.useMemo(() => {
    const { data } = props.authUser;
    switch (data?.role) {
      case EUserRole.AcademyWorker: {
        return (
          <Menu>
            <Menu.Item key="players-and-trainers">
              <Link to={`${ERoutesAcademy.AcademyView}/${academyId}`}>{t('My academy')}</Link>
            </Menu.Item>
            <Menu.Item key="academy-settings">
              <Link to={ERoutesAcademy.AcademyEdit}>{t('Academy settings')}</Link>
            </Menu.Item>
            <Menu.Item key="refferals">
              <Link to={ERoutesAcademy.AcademyReferrals}>{t('Referrals')}</Link>
            </Menu.Item>
            {isFranchisor && (
              <Menu.Item key="statistic">
                <Link to={ERoutesAcademy.Statistic}>{t('Statistic')}</Link>
              </Menu.Item>
            )}
            {/* {!isAcademyIsFranchisee && (*/}
            {/*  <Menu.Item key="tests-requirements">*/}
            {/*    <Link to={ERoutesAcademy.AcademyEditTests}>{t('Tests Requirements')}</Link>*/}
            {/*  </Menu.Item>*/}
            {/* )}*/}
            <Menu.Divider />
            <Menu.Item key="logout" onClick={onLogout}>
              {t('Exit')}
            </Menu.Item>
          </Menu>
        );
      }
      case EUserRole.Mentor:
      default: {
        return (
          <Menu>
            <Menu.Item key="my-settings">
              <Link to={ERoutesMentor.UsersEdit}>{t('My settings')}</Link>
            </Menu.Item>
            <Menu.Item key="payment-and-subscription">
              <Link to={ERoutesMentor.PaymentAndSubscription}>{t('Payment and subscription')}</Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" onClick={onLogout}>
              {t('Exit')}
            </Menu.Item>
          </Menu>
        );
      }
    }
  }, [academyId, isAcademyInFranchise, t]);

  return (
    <header className="ant-menu basic__header width-full px-5">
      <Row className="flex-noWrap height-full width-full basic__header_content" justify="space-around" align="middle">
        <Col flex={1}>
          <Logo />
        </Col>
        <Col flex={1}>
          <Row gutter={24} justify="end" align="middle" className="flex-noWrap">
            <Col>
              <Button shape="circle" type="text" onClick={() => push(ERoutesCommon.Notifications)}>
                <Badge count={0} dot>
                  <BellOutlined className="font-size-lg" />
                </Badge>
              </Button>
            </Col>
            <Col>
              <Dropdown overlay={menu} trigger={['click']}>
                {avatar ? (
                  <img alt={t('User avatar')} height={32} width={32} src={avatar} className="cursor-pointer radius-round" />
                ) : (
                  <Button shape="circle" type="text">
                    <Typography.Text>
                      <MenuOutlined className="color-text-primary fs-lg" />
                    </Typography.Text>
                  </Button>
                )}
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
    </header>
  );
};

export const Header = communicationAuth.injector(HeaderComponent);
