import React from 'react';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import {
  INotificationCollection,
  INotificationCollectionFilter,
  INotificationModel
} from 'entities/Notification/Notification.models';
import { communicationNotification, INotificationConnectedProps } from 'entities/Notification/Notification.communication';
import { NotificationListItem } from 'entities/Notification/components/List/NotificationListItem';
import { NotificationListNoData } from 'entities/Notification/components/List/NotificationListNoData';

interface IComponentProps {}

type AllProps = IComponentProps & INotificationConnectedProps;

class NotificationListComponent extends InfiniteList<
  INotificationCollection,
  INotificationModel,
  AllProps,
  INotificationCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getNotificationCollection } = this.props;
    return getNotificationCollection({
      ...params
    });
  };

  renderListItem = (item: INotificationModel) => {
    return <NotificationListItem item={item} />;
  };

  renderNoData = () => {
    return <NotificationListNoData />;
  };

  getCollection = () => {
    return this.props.notificationCollection;
  };
}

export const NotificationList = communicationNotification.injector(NotificationListComponent);
