import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import { EErrorStatus } from 'common/models/requestModels';
import { EAcademyStatus } from 'entities/Academy/Academy.models';
import { EUserRole } from 'entities/User/User.models';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps;

const AuthStatusBarComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { authUser, authAcademy } = props;

  const { data, errors } = authUser;
  const { data: authAcademyData } = authAcademy;
  const classList = 'width-full t-align-c p-4 pre-line-space text-white zIndex-100';

  if (errors?.data?.statusCode === EErrorStatus.Forbidden) {
    const { message } = errors?.data;
    return (
      <div className={`${classList} bg-danger`}>
        <Typography.Title level={5} className="m-0">
          {t(`${message}`)}
        </Typography.Title>
      </div>
    );
  }

  if (data?.role === EUserRole.AcademyWorker) {
    const status = authAcademyData?.status;
    switch (status) {
      case EAcademyStatus.Banned: {
        return (
          <div className={`${classList} bg-danger`}>
            <Typography.Title level={5} className="m-0">
              {t(`${EAcademyStatus.Banned}`, { message: authAcademyData?.rejectReason })}
            </Typography.Title>
          </div>
        );
      }
      case EAcademyStatus.Moderation: {
        return (
          <div className={`${classList} bg-primary`}>
            <Typography.Title level={5} className="m-0">
              {t(EAcademyStatus.Moderation)}
            </Typography.Title>
          </div>
        );
      }
    }
  }

  return null;
};

export const AuthStatusBar = communicationAuth.injector(AuthStatusBarComponent);
