import React from 'react';
import Row from 'antd/es/row';

export const CenterLayout: React.FC = props => {
  return (
    <Row className="height-full" justify="center" align="middle">
      {props.children}
    </Row>
  );
};
