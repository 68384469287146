import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Tabs from 'antd/es/tabs';
import { TableOutlined, FileTextOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import UserOutlined from '@ant-design/icons/UserOutlined';
import LineChartOutlined from '@ant-design/icons/LineChartOutlined';
import DashboardOutlined from '@ant-design/icons/DashboardOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import classNames from 'classnames';
import { useMediaPredicate } from 'react-media-hook';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { SCREEN_LG } from 'common/const/config.const';
import { EPrivatePlayerTabKeys } from 'entities/PrivatePlayer/PrivatePlayer.models';
import { PlayerTestTab } from 'entities/Player/components/Tabs/PlayerTestTab';
import { PrivatePlayerInfoTab } from 'entities/PrivatePlayer/components/View/Tabs/PrivatePlayerInfoTab';
import { PrivatePlayerProviderContext } from 'entities/PrivatePlayer/components/PrivatePlayerProvider';
import { PlayerProfileCommonView } from 'entities/Player/components/Profile/PlayerProfileCommonView';
import { PlayerProfileSkillView } from 'entities/Player/components/Profile/PlayerProfileSkillView';
import { PlayerTestResultTab } from 'entities/Player/components/Tabs/PlayerTestResultTab';
import { ETestResultFilter } from 'entities/Test/Test.models';
import { PlayerProfileCardInfo } from 'entities/Player/components/Card/PlayerProfileInfo';
import { PrivatePlayerDocumentTab } from 'entities/PrivatePlayer/components/View/Tabs/PrivatePlayerDocumentTab';

interface IComponentProps {
  isDesktop: boolean;
}

interface IQueryState {
  skillId: string | undefined;
}

type AllProps = IComponentProps;

const PrivatePlayerViewComponent: React.FC<AllProps> = props => {
  const { isDesktop } = props;
  const { player } = React.useContext(PrivatePlayerProviderContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { path, url } = useRouteMatch();
  const tabKey = pathname.split(url)[1].split('/')[1];
  const secondId = pathname.split(url)[1].split('/')[2];
  const [searchValue, setSearchValue] = useState<string | undefined>(search);
  const [tabValue, setTabValue] = useState<EPrivatePlayerTabKeys>();
  const { skillId } = queryToObject<IQueryState>({ skillId: undefined }) as IQueryState;

  const isPlayerArchived = useMemo(() => player?.data?.archived, [player]);

  const selectTab = (key: string): EPrivatePlayerTabKeys => {
    if (isPlayerArchived) {
      return EPrivatePlayerTabKeys.Info;
    }

    switch (key) {
      case EPrivatePlayerTabKeys.Profile:
        return EPrivatePlayerTabKeys.Profile;
      case EPrivatePlayerTabKeys.Progress:
        return EPrivatePlayerTabKeys.Progress;
      case EPrivatePlayerTabKeys.TestResults:
        return EPrivatePlayerTabKeys.TestResults;
      case EPrivatePlayerTabKeys.Tests:
        return EPrivatePlayerTabKeys.Tests;
      case EPrivatePlayerTabKeys.Info:
        return EPrivatePlayerTabKeys.Info;
      case EPrivatePlayerTabKeys.Documents:
        return EPrivatePlayerTabKeys.Documents;

      default:
        return EPrivatePlayerTabKeys.Profile;
    }
  };

  useEffect(() => {
    replace(selectTab(tabKey));
    setTabValue(selectTab(tabKey));
  }, [tabKey]);

  const onTabChange = (tab: string) => {
    const selectedTab = selectTab(tab);
    setSearchValue(undefined);
    setTabValue(selectedTab);
    history.push({ pathname: `${url}/${selectedTab}`, search: undefined });
  };

  const replace = (tab: string) => {
    secondId
      ? history.replace({ pathname: `${url}/${selectTab(tab)}/${secondId}`, search: searchValue })
      : history.replace({ pathname: `${url}/${selectTab(tab)}`, search: searchValue });
  };

  const onSkillSelectCallback = useCallback(
    (selectedSkillId: string) => {
      const newSearch = objectToQuery({ skillId: selectedSkillId });

      history.push({ pathname: `${url}/${EPrivatePlayerTabKeys.Progress}`, search: newSearch });
      setSearchValue(newSearch);
    },
    [searchValue, url]
  );

  const isLg: boolean = useMediaPredicate(`(min-width: ${SCREEN_LG})`);

  return (
    <Row className={`${isDesktop ? 'flex-row' : 'flex-column mt-3 mobile'} private-player-view width-full`}>
      <Col span={24}>
        <Col xs={24} sm={10} md={8} className={classNames('mb-7', { 'px-5': !isLg })}>
          <PlayerProfileCardInfo onClick={() => onTabChange(EPrivatePlayerTabKeys.Profile)} />
        </Col>
        <Col>
          <Tabs className="private-player-view__tabs-container" tabPosition="top" onChange={onTabChange} activeKey={tabValue}>
            {!isPlayerArchived && (
              <>
                <Tabs.TabPane
                  tab={
                    <>
                      <UserOutlined /> {t('Profile')}
                    </>
                  }
                  key={EPrivatePlayerTabKeys.Profile}
                />
                <Tabs.TabPane
                  tab={
                    <>
                      <LineChartOutlined /> {t('Progress')}
                    </>
                  }
                  key={EPrivatePlayerTabKeys.Progress}
                />
                <Tabs.TabPane
                  tab={
                    <>
                      <TableOutlined /> {t('Test results')}
                    </>
                  }
                  key={EPrivatePlayerTabKeys.TestResults}
                />
                <Tabs.TabPane
                  tab={
                    <>
                      <DashboardOutlined /> {t('Tests')}
                    </>
                  }
                  key={EPrivatePlayerTabKeys.Tests}
                />
              </>
            )}
            <Tabs.TabPane
              tab={
                <>
                  <SettingOutlined /> {t('Info')}
                </>
              }
              key={EPrivatePlayerTabKeys.Info}
            />
            <Tabs.TabPane
              tab={
                <>
                  <FileTextOutlined /> {t('Documents')}
                </>
              }
              key={EPrivatePlayerTabKeys.Documents}
            />
          </Tabs>
        </Col>
        {isPlayerArchived ? (
          <Route path={`${path}/*`}>
            <PrivatePlayerInfoTab />
          </Route>
        ) : (
          <Switch>
            <Route path={`${path}/${EPrivatePlayerTabKeys.Profile}`} exact>
              <PlayerProfileCommonView onSkillSelectCallback={onSkillSelectCallback} showMentorRequestButton />
            </Route>
            <Route path={`${path}/${EPrivatePlayerTabKeys.Progress}`} exact>
              <PlayerProfileSkillView skillId={skillId} onSkillSelectCallback={onSkillSelectCallback} />
            </Route>
            <Route path={`${path}/${EPrivatePlayerTabKeys.TestResults}`} exact>
              <PlayerTestResultTab
                filterConfig={[
                  ETestResultFilter.PlayerName,
                  ETestResultFilter.AgeGroup,
                  ETestResultFilter.UploaderType,
                  ETestResultFilter.BirthYear,
                  ETestResultFilter.TestDateRange
                ]}
              />
            </Route>
            <Route path={`${path}/${EPrivatePlayerTabKeys.Tests}`} exact>
              <PlayerTestTab />
            </Route>
            <Route path={`${path}/${EPrivatePlayerTabKeys.Info}`} exact>
              <PrivatePlayerInfoTab />
            </Route>
            <Route path={`${path}/${EPrivatePlayerTabKeys.Documents}`} exact>
              <PrivatePlayerDocumentTab />
            </Route>
            <Route path={`${path}/*`}>
              <PlayerProfileCommonView onSkillSelectCallback={onSkillSelectCallback} showMentorRequestButton />
            </Route>
          </Switch>
        )}
      </Col>
    </Row>
  );
};

export const PrivatePlayerViewTabs = PrivatePlayerViewComponent;
