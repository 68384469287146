import React from 'react';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';

const NotificationListNoDataComponent = () => {
  const { t } = useTranslation();

  return (
    <Row justify="center" align="middle">
      <Typography.Text className="t-align-c">{t('You have no notifications yet')}</Typography.Text>
    </Row>
  );
};

export const NotificationListNoData = NotificationListNoDataComponent;
