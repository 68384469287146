import React, { useCallback } from 'react';
import Select from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import { changeSystemLang } from 'common/helpers/i18n';
import { ILanguageModel } from 'entities/Language/Language.models';
import { ILanguageConnectedProps, communicationLanguage } from 'entities/Language/Language.communication';

interface IProps {
  className?: string;
  onChange?: (locale: string) => void;
  value?: string;
  disabled?: boolean;
  size?: 'middle' | 'large' | 'small';
}

type AllProps = IProps & ILanguageConnectedProps;

const LanguageSelectComponent: React.FC<AllProps> = props => {
  const { onChange = changeSystemLang, value, className, languageCollection, disabled, size = 'middle' } = props;
  const languages = languageCollection?.data?.data;
  const { i18n } = useTranslation(undefined, { useSuspense: false });
  const lang = value || i18n.language;

  const changeLang = useCallback(lng => {
    onChange && onChange(lng);
  }, []);

  const onClick = useCallback((event: any) => {
    event.stopPropagation();
  }, []);

  return (
    <Select className={className} onClick={onClick} onChange={changeLang} value={lang} disabled={disabled} size={size}>
      {languages?.map((language: ILanguageModel) => (
        <Select.Option key={language.locale} value={language.locale}>
          {language.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export const LanguageSelect = communicationLanguage.injector(LanguageSelectComponent);
