import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'antd/es/modal';
import { useHistory } from 'react-router';
import { useMediaPredicate } from 'react-media-hook';
import { SCREEN_XL } from 'common/const/config.const';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { TestResultTable } from 'entities/Test/components/Table/TestResultTable';
import { ITestResultsHistoryCollectionFilter, initTestResultFilter, ETestResultFilter } from 'entities/Test/Test.models';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

interface IComponentProps {
  disableFilters?: boolean;
  filterConfig?: ETestResultFilter[];
}

type AllProps = IUIConnectedProps & IComponentProps & IAuthConnectedProps;

const TestResultModalComponent: React.FC<AllProps> = props => {
  const { closeUITestResultModal, UITestResultModal, disableFilters, filterConfig, authUser } = props;
  const modalModel = UITestResultModal.data;
  const userData = authUser?.data;
  const history = useHistory();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${SCREEN_XL})`);
  const { isVisible, params } = modalModel || {};
  const { id, testTag, tags, academy } = params || {};
  const urlQuery = queryToObject<Partial<ITestResultsHistoryCollectionFilter>>(initTestResultFilter) as any;

  const isVisibleTable = useMemo(() => Boolean(isVisible && id && testTag), [isVisible, id, testTag]);
  const [modalVisible, setModalVisible] = useState<boolean>(isVisibleTable);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setTimeout(() => closeUITestResultModal(), 500);
    clearQueryParams();
  }, []);

  useEffect(() => {
    return closeModal;
  }, []);

  useEffect(() => {
    setModalVisible(isVisibleTable);
    if (testTag && id) {
      addQueryParams({
        ...initTestResultFilter,
        ...urlQuery,
        testTag,
        id,
        academy,
        tags
      });
    }
  }, [isVisibleTable]);

  const addQueryParams = useCallback((query: ITestResultsHistoryCollectionFilter) => {
    history.replace(objectToQuery(query));
  }, []);
  const clearQueryParams = useCallback(() => {
    history.replace(
      objectToQuery({
        ...initTestResultFilter,
        from: undefined,
        to: undefined,
        fromPlayerAcademy: undefined,
        pageIndex: undefined,
        orderDirection: undefined,
        orderField: undefined
      })
    );
  }, []);

  return isVisibleTable ? (
    <Modal
      style={{ maxWidth: isDesktop ? '90vw' : '100vw', minHeight: '80vh', overflow: isDesktop ? 'hidden' : 'scroll', padding: 0 }}
      bodyStyle={{ border: 'none' }}
      width="auto"
      centered
      destroyOnClose
      visible={modalVisible}
      onCancel={closeModal}
      footer={false}
    >
      {isVisibleTable ? (
        <TestResultTable modalModel={modalModel} disableFilters={disableFilters} filterConfig={filterConfig} user={userData} />
      ) : null}
    </Modal>
  ) : null;
};

export const TestResultModal = communicationAuth.injector(communicationUI.injector(TestResultModalComponent));
