import React from 'react';
import Modal from 'antd/es/modal/Modal';
import { useTranslation } from 'react-i18next';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { communicationTeam, ITeamConnectedProps } from 'entities/Team/Team.communication';
import { TeamForm } from 'entities/Team/components/TeamForm';

type AllProps = IUIConnectedProps & ITeamConnectedProps;

const AcademyTeamCreateModalComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { UITeamCreateModal, closeUITeamCreateModal, clearTeamModel } = props;
  const isVisible = UITeamCreateModal.data?.isVisible;

  const closeModal = () => {
    clearTeamModel();
    closeUITeamCreateModal();
  };

  return (
    <Modal
      className="create-team-modal"
      destroyOnClose
      centered
      maskClosable={false}
      bodyStyle={{ border: 'none' }}
      width={600}
      visible={isVisible}
      onCancel={closeModal}
      footer={false}
      title={t('Team creating')}
    >
      <TeamForm />
    </Modal>
  );
};

export const AcademyTeamCreateModal = communicationTeam.injector(communicationUI.injector(AcademyTeamCreateModalComponent));
