import React, { useEffect, useMemo, useState } from 'react';
import Spin from 'antd/es/spin';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import Row from 'antd/es/row';
import Tabs from 'antd/es/tabs';
import { communicationTeam, ITeamConnectedProps } from 'entities/Team/Team.communication';
import { TeamForm } from 'entities/Team/components/TeamForm';
import { TeamAcademyPagePlayers } from 'entities/Team/components/Page/TeamAcademyPagePlayers';
import { TeamAcademyPageTrainers } from 'entities/Team/components/Page/TeamAcademyPageTrainers';
import { TeamAcademyPageTestResult } from 'entities/Team/components/Page/TeamAcademyPageTestResult';

interface IProps {
  isDesktop: boolean;
}

enum ETeamAcademyPageTabs {
  Players = 'players',
  Trainers = 'trainers',
  TestResult = 'test-result'
}

type AllProps = IProps & ITeamConnectedProps;

const TeamAcademyPageComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { teamId, activeTab } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { teamModel, getTeamModel, isDesktop, clearTeamModel } = props;
  const { data: team, loading: teamLoading } = teamModel;
  const [selectedTab, setSelectedTab] = useState<ETeamAcademyPageTabs>(activeTab || ETeamAcademyPageTabs.Players);
  const teamAcademyId = team?.ownerAcademy?.id;

  const isLoading = useMemo(() => teamLoading, [teamLoading]);

  useEffect(() => {
    if (teamId) {
      getTeamModel(teamId);
    }
    return clearTeamModel;
  }, [teamId, getTeamModel, clearTeamModel]);

  const onTabChanged = (tab: string) => {
    const newUrl = url.includes(selectedTab) ? url.replace(selectedTab, tab) : `${url}/${tab}`;

    setSelectedTab(tab as ETeamAcademyPageTabs);
    history.push({ pathname: newUrl, search: undefined });
  };

  return (
    <Spin spinning={isLoading} size="large">
      <Row className={`${isDesktop ? '' : 'p-3'} width-full`}>
        <Row className="mb-5 p-5 width-full">
          <TeamForm />
        </Row>
      </Row>

      <Row className="width-full">
        <Tabs destroyInactiveTabPane onChange={onTabChanged} activeKey={selectedTab} className="width-full pl-4 pr-4">
          <Tabs.TabPane tab={t('Players')} key={ETeamAcademyPageTabs.Players}>
            <TeamAcademyPagePlayers teamId={teamId} academyId={teamAcademyId} isDesktop={isDesktop} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Trainers')} key={ETeamAcademyPageTabs.Trainers}>
            <TeamAcademyPageTrainers teamId={teamId} academyId={teamAcademyId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Test results')} key={ETeamAcademyPageTabs.TestResult}>
            <TeamAcademyPageTestResult teamId={teamId} academyId={teamAcademyId} />
          </Tabs.TabPane>
        </Tabs>
      </Row>
    </Spin>
  );
};

export const TeamAcademyPage = communicationTeam.injector(TeamAcademyPageComponent);
