import React, { useCallback } from 'react';
import List from 'antd/es/list';
import { Link } from 'react-router-dom';
import { ERoutesAcademy } from 'common/models/routesModel';
import { IFranchiseRequestModel } from 'entities/Franchise/Franchise.models';
import { AcademyViewCard } from 'entities/Academy/components/View/Card/AcademyViewCard';
import { ETrainerAcademyRequestStatus } from 'entities/Trainer/Trainer.models';
import { EAcademyStatus } from 'entities/Academy/Academy.models';

interface IComponentProps {
  item: IFranchiseRequestModel;
}

type AllProps = IComponentProps;

export const FranchiseAcademyListItemComponent: React.FC<AllProps> = props => {
  const { item } = props;
  const { academy, ...franchiseRequest } = item;

  const checkIfHaveAccess = useCallback(
    e => {
      if (franchiseRequest?.status !== ETrainerAcademyRequestStatus.Active || academy?.status !== EAcademyStatus.Active) {
        e?.preventDefault();
      }
    },
    [franchiseRequest, academy]
  );

  return (
    <List.Item key={academy.id}>
      <Link onClick={checkIfHaveAccess} to={`${ERoutesAcademy.AcademyView}/${academy.id}`}>
        <AcademyViewCard academy={academy} franchiseRequest={item} showFranchiseInfo={false} />
      </Link>
    </List.Item>
  );
};

export const FranchiseAcademyListItem = FranchiseAcademyListItemComponent;
