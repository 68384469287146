import React, { useEffect, useState, useContext } from 'react';
import { Upload, Button, message, Space, Table, Row, Popconfirm } from 'antd';
import {
  UploadOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FileOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileGifOutlined,
  FileWordOutlined,
  FileExcelOutlined
} from '@ant-design/icons';
import axios from 'axios';
import { useMediaPredicate } from 'react-media-hook';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { downloadFile } from 'common/helpers/loader.helper';
import { getBaseUrl } from 'common/helpers/axios.helper';
import { dateFormatter } from 'common/helpers/date.helper';
import { nameBuilder } from 'common/helpers/name.helper';
import { privatePlayerTransport } from 'entities/PrivatePlayer/PrivatePlayer.transport';
import { IUserModel } from 'entities/User/User.models';
import { IPlayerFileModel } from 'entities/PrivatePlayer/PrivatePlayer.models';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { PrivatePlayerProviderContext } from 'entities/PrivatePlayer/components/PrivatePlayerProvider';

type AllProps = IAuthConnectedProps;

const PlayerFileUploadListComponent: React.FC<AllProps> = ({ authModel }) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const host = getBaseUrl();
  const { t } = useTranslation();
  const { player } = useContext(PrivatePlayerProviderContext);
  const token = authModel.data?.access.token;
  const { data } = player ?? {};
  const { id: playerId } = data || {};

  useEffect(() => {
    (async () => {
      if (playerId) {
        setLoading(true);
        const files = await privatePlayerTransport.getPlayerFiles(playerId);
        setFileList(files?.data);
        setLoading(false);
      }
    })();
  }, [playerId]);

  const handleUpload = async (options: any) => {
    const { file, onSuccess } = options;
    setLoading(true);

    if (file.size > 15 * 1024 * 1024) {
      message.error(t('Size should be less than 15MB'));
      setLoading(false);
      return;
    }

    if (fileList.length >= 10) {
      message.error(t('Maximum number of files'));
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    if (playerId) {
      formData.append('player', playerId);
    }
    try {
      const response = await axios.post(`/files`, formData);
      onSuccess(response);

      setFileList([...fileList, response]);
      message.success(t('File uploaded successfully'));
    } catch (error) {
      message.error(t('Error uploading file'));
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      setLoading(true);
      await axios.delete(`/files/${id}`);
      const updatedList = fileList.filter((file: IPlayerFileModel) => file.id !== id);
      setFileList(updatedList);
      message.success(t('Successfully deleted'));
    } catch (error) {
      message.error(t('Error deleting file'));
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      key: 'mimeType',
      dataIndex: 'mimeType',
      fixed: 'left',
      width: 50,
      // eslint-disable-next-line react/no-multi-comp
      render: (mimeType: string) => {
        const icon = () => {
          switch (true) {
            case mimeType.includes('gif'): {
              return <FileGifOutlined style={{ fontSize: '28px' }} />;
            }
            case mimeType.includes('xml'): {
              return <FileExcelOutlined style={{ fontSize: '28px' }} />;
            }
            case mimeType.includes('word'): {
              return <FileWordOutlined style={{ fontSize: '28px' }} />;
            }
            case mimeType.includes('pdf'): {
              return <FilePdfOutlined style={{ fontSize: '28px' }} />;
            }
            case mimeType.includes('image'): {
              return <FileImageOutlined style={{ fontSize: '28px' }} />;
            }
            default: {
              return <FileOutlined style={{ fontSize: '28px' }} />;
            }
          }
        };
        return (
          <Row justify="center" align="middle">
            {icon()}
          </Row>
        );
      }
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      width: 150,
      ellipsis: true
    },
    {
      title: t('Date of download'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ellipsis: true,
      render: (createdAt: string) => dateFormatter(createdAt)
    },
    {
      title: t('User'),
      dataIndex: 'uploader',
      key: 'uploader',
      width: 150,
      ellipsis: true,
      render: (item: IUserModel) => nameBuilder(item?.firstName, item?.lastName)
    },
    {
      dataIndex: 'id',
      key: 'id',
      fixed: 'right',
      width: 100,
      // eslint-disable-next-line react/no-multi-comp
      render: (id: string) => (
        <Row justify="center" align="middle">
          <Space size="middle">
            <Button
              title={t('Download')}
              icon={<DownloadOutlined />}
              onClick={() => downloadFile(`${host}/files/${id}`, token)}
            />
            <Popconfirm
              okButtonProps={{
                type: 'text',
                size: 'middle',
                danger: true
              }}
              cancelButtonProps={{
                size: 'middle'
              }}
              okText={t('Delete')}
              cancelText={t('Cancel')}
              title={t('The operation is irreversible, are you sure?')}
              onConfirm={() => handleDelete(id)}
            >
              <Button title={t('Delete')} icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        </Row>
      )
    }
  ];
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);

  return (
    <div>
      <Row wrap={false} justify="space-between" className="mb-4">
        <Typography.Title className="mb-0" level={isDesktop ? 2 : 3}>
          {t('Documents')}
        </Typography.Title>
        <Upload
          showUploadList={false}
          headers={{ authorization: `Bearer ${token}` }}
          customRequest={handleUpload}
          disabled={isLoading}
        >
          <Button
            size={isDesktop ? 'large' : 'middle'}
            icon={<UploadOutlined />}
            type="primary"
            loading={isLoading}
            disabled={isLoading}
          >
            {t('Upload')}
          </Button>
        </Upload>
      </Row>
      <Table rowKey="id" pagination={false} columns={columns} dataSource={fileList} scroll={{ x: 600 }} loading={isLoading} />
    </div>
  );
};

export const PlayerFileUploadList = communicationAuth.injector(PlayerFileUploadListComponent);
