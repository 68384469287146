import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Spin from 'antd/es/spin';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import Popconfirm from 'antd/es/popconfirm';
import { translateTariffData } from 'common/helpers/tariff-helper';
import { clientDateFormat } from 'common/models/dateModels';
import { communicationSubscription, ISubscriptionConnectedProps } from 'entities/Subscription/Subscription.communication';
import { communicationTariff, ITariffConnectedProps } from 'entities/Tariff/Tariff.communication';
import { SubscriptionDelayedCancellation } from 'entities/Subscription/components/SubscriptionDelayedCancellation';
import {
  ESubscriptionsEngines,
  ESubscriptionStatus,
  IStripeNextWithdrawal,
  ISubscriptionModel
} from 'entities/Subscription/Subscription.models';
import { IMentorRequestModel } from 'entities/MentorRequest/MentorRequest.models';
import {
  getStripeNextWithdrawal,
  getStripeSubscriptionAllGiftcards,
  getStripeSubscriptionNextTariff,
  isStripeCurrentPhaseGiftcard,
  isStripeTrialVersion
} from 'entities/Subscription/helpers/subscription.helper';
import { StripeSubscriptionInfoCardCenteredLayout } from 'entities/Subscription/components/StripeSubscriptionInfoCard/StripeSubscriptionInfoCardCenteredLayout';
import { StripeSubscriptionInfoCardNextWithdrawal } from 'entities/Subscription/components/StripeSubscriptionInfoCard/StripeSubscriptionInfoCardNextWithdrawal';

interface IProps {
  subscription?: ISubscriptionModel | null;
  mentorRequest?: IMentorRequestModel | null;
}

type AllProps = IProps & ISubscriptionConnectedProps & ITariffConnectedProps;

const Component: React.FC<AllProps> = props => {
  const {
    subscription,
    mentorRequest,
    tariffCollection,
    changeTariffSubscriptionModel,
    subscriptionModel,
    updateSubscriptionModel
  } = props;
  const { t } = useTranslation();
  const { loading } = subscriptionModel;
  const isDelayedCancellation = useMemo(() => subscription && subscription.delayedCancellation, [subscription]);
  const nextTariff = useMemo(() => getStripeSubscriptionNextTariff(subscription), [subscription]);
  const allGiftcards = useMemo(() => getStripeSubscriptionAllGiftcards(subscription), [subscription]);
  const nextWithdrawal = useMemo(() => getStripeNextWithdrawal(subscription), [subscription]);
  const isGiftcardPhaseNow = useMemo(() => isStripeCurrentPhaseGiftcard(subscription), [subscription]);
  const isTrialPhaseNow = useMemo(() => isStripeTrialVersion(subscription), [subscription]);
  const nextTariffData = useMemo(() => tariffCollection?.data?.data?.find(tariff => tariff.id === nextTariff?.id), [
    tariffCollection?.data,
    nextTariff
  ]);
  const { title: nextTariffTitle } = translateTariffData(nextTariffData);

  const onCancelNextTariffCallback = useCallback(() => {
    if (subscription) {
      if (isGiftcardPhaseNow) {
        updateSubscriptionModel({
          engine: ESubscriptionsEngines.Stripe,
          id: subscription.id,
          status: ESubscriptionStatus.Canceled
        });
      } else {
        changeTariffSubscriptionModel({
          engine: ESubscriptionsEngines.Stripe,
          subscriptionId: subscription.id,
          tariff: subscription.currentPhase?.tariffId || subscription.tariff.id,
          isSameTariff: true
        });
      }
    }
  }, [subscription, isGiftcardPhaseNow]);

  const Element = useMemo(() => {
    switch (true) {
      case loading: {
        return (
          <StripeSubscriptionInfoCardCenteredLayout>
            <Spin size="large" />
          </StripeSubscriptionInfoCardCenteredLayout>
        );
      }
      case subscription?.status === ESubscriptionStatus.Expired:
        return (
          <StripeSubscriptionInfoCardCenteredLayout>
            <Typography.Text>{t('Awaiting payment')}</Typography.Text>
          </StripeSubscriptionInfoCardCenteredLayout>
        );
      case subscription?.status === ESubscriptionStatus.Canceled:
        return (
          <StripeSubscriptionInfoCardCenteredLayout>
            <Typography.Text>{t('Subscription cancelled')}</Typography.Text>
          </StripeSubscriptionInfoCardCenteredLayout>
        );
      case isDelayedCancellation:
        return (
          <StripeSubscriptionInfoCardCenteredLayout>
            <SubscriptionDelayedCancellation block subscription={subscription} />
          </StripeSubscriptionInfoCardCenteredLayout>
        );
      case Boolean(nextTariff?.id): {
        return (
          <Row align="middle">
            <StripeSubscriptionInfoCardNextWithdrawal className="mb-3" {...(nextWithdrawal as IStripeNextWithdrawal)} />
            <Typography.Text>
              {t('Next tariff', { title: nextTariffTitle })}{' '}
              <Popconfirm
                title={t('Are you sure you want to cancel the switch to tariff', { title: nextTariffTitle })}
                okText={t('Revoke')}
                cancelText={t('Leave')}
                onConfirm={onCancelNextTariffCallback}
              >
                (<Typography.Text className="cursor-pointer color-link">{t('Revoke')}</Typography.Text>)
              </Popconfirm>
            </Typography.Text>
          </Row>
        );
      }
      case Boolean(nextWithdrawal): {
        return <StripeSubscriptionInfoCardNextWithdrawal {...(nextWithdrawal as IStripeNextWithdrawal)} />;
      }
      case isTrialPhaseNow: {
        return (
          <StripeSubscriptionInfoCardCenteredLayout>
            <Typography.Text>{t('Trial version')}</Typography.Text>
          </StripeSubscriptionInfoCardCenteredLayout>
        );
      }
      case isGiftcardPhaseNow: {
        return (
          <StripeSubscriptionInfoCardCenteredLayout>
            <Typography.Text>{t('Gift card applied')}</Typography.Text>
          </StripeSubscriptionInfoCardCenteredLayout>
        );
      }
      case subscription?.status === ESubscriptionStatus.Active: {
        return (
          <StripeSubscriptionInfoCardCenteredLayout>
            <Typography.Text>{t('Subscription active')}</Typography.Text>
          </StripeSubscriptionInfoCardCenteredLayout>
        );
      }
      default:
        return (
          <StripeSubscriptionInfoCardCenteredLayout>
            <Typography.Text>{t('Subscription not registered')}</Typography.Text>
          </StripeSubscriptionInfoCardCenteredLayout>
        );
    }
  }, [
    isDelayedCancellation,
    mentorRequest,
    nextTariffTitle,
    subscription,
    nextTariff?.id,
    onCancelNextTariffCallback,
    nextWithdrawal,
    loading,
    isGiftcardPhaseNow,
    isTrialPhaseNow
  ]);

  return (
    <Col className="stripe-subscription-info-card d-flex p-5 border-light radius-small height-full">
      {Element}
      {!!allGiftcards?.length && (
        <>
          <Typography.Title level={5} className="my-3 color-disable">
            {t('Gift cards')}
          </Typography.Title>
          {allGiftcards?.map(phase => (
            <Row key={phase.giftcardNumber} justify="space-between" wrap={false}>
              <Typography.Text>{phase.giftcardNumber}</Typography.Text>
              <Typography.Text className="color-disable t-align-e">
                {moment(phase.periodStart).format(clientDateFormat)} - {moment(phase.periodEnd).format(clientDateFormat)}
              </Typography.Text>
            </Row>
          ))}
        </>
      )}
    </Col>
  );
};

export const StripeSubscriptionInfoCard = communicationTariff.injector(communicationSubscription.injector(Component));
