import React, { useMemo, useCallback } from 'react';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { formatDateFromServer } from 'common/helpers/date.helper';
import { ERoutesMentor } from 'common/models/routesModel';
import { ESubscriptionsEngines } from 'entities/Subscription/Subscription.models';
import { EMentorRequestStatus, IMentorRequestModel } from 'entities/MentorRequest/MentorRequest.models';
import { communicationSubscription, ISubscriptionConnectedProps } from 'entities/Subscription/Subscription.communication';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';
import { SubscriptionButton } from 'entities/Subscription/components/SubscriptionButton';
import { isAcademyHideTariffs } from 'entities/Subscription/helpers/subscription.helper';

interface IProps {
  item: IMentorRequestModel;
  isDesktop: boolean;
}

type AllProps = IProps & ISubscriptionConnectedProps & IMentorRequestConnectedProps;

const ImageSize = 48;

const SubscriptionsTabComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { item, isDesktop, updateMentorRequestModel } = props;
  const { subscription, player, updatedAt, id, status, subscriptionEngine } = item;
  const { image, firstName, lastName, isPaid } = player;
  const hideTariffs = useMemo(() => isAcademyHideTariffs(player), [player]);

  const addedDate = useMemo(() => {
    const format = 'DD MMMM YYYY';
    return formatDateFromServer(updatedAt, format);
  }, [updatedAt]);

  const isRequestSend = useMemo(() => status === EMentorRequestStatus.New, [status]);

  const onPlayerClick = useCallback(() => {
    if (!isRequestSend && player?.id) {
      history.push(`${ERoutesMentor.Player}/${player.id}`);
    }
  }, [id]);

  const onRemoveRequest = useCallback(() => {
    updateMentorRequestModel({
      status: EMentorRequestStatus.Aborted,
      id
    });
  }, [id]);

  return (
    <Row
      key={id}
      justify="space-between"
      onClick={onPlayerClick}
      align="middle"
      className="payment-and-subscription-page__subscriptions-tab__list__element"
    >
      <Row className="payment-and-subscription-page__subscriptions-tab__list__element__data" wrap={false}>
        <div className="mr-4">
          <ImagePrivate
            className="border-circle"
            imgId={image?.id}
            height={ImageSize}
            width={ImageSize}
            alt={t('Player avatar')}
          />
        </div>
        <div className="payment-and-subscription-page__subscriptions-tab__list__element__data__info">
          <Typography.Text className="width-full mb-2" ellipsis strong>
            {firstName || lastName ? `${firstName} ${lastName}` : t('Unknown player')}
          </Typography.Text>
          <Typography.Paragraph className="mb-0 color-text-secondary">{t('Added at', { date: addedDate })}</Typography.Paragraph>
        </div>
      </Row>
      <div className={isDesktop ? '' : 'width-full mt-3'}>
        {isRequestSend ? (
          <Popconfirm
            okButtonProps={{
              type: 'text',
              size: 'middle',
              danger: true
            }}
            cancelButtonProps={{
              size: 'middle'
            }}
            okText={t('Cancel request')}
            cancelText={t('Leave')}
            title={t('Cancel the add request?')}
            onConfirm={onRemoveRequest}
          >
            <Button className="color-gray" type="text">
              {t(`Awaiting confirmation`)}
            </Button>
          </Popconfirm>
        ) : (
          <SubscriptionButton
            playerId={player.id}
            mentorRequestSubscriptionEngine={subscriptionEngine as ESubscriptionsEngines}
            block={!isDesktop}
            subscription={subscription}
            mentorRequestId={id}
            hideTariffs={hideTariffs}
            isPaid={isPaid}
          />
        )}
      </div>
    </Row>
  );
};

export const SubscriptionsTabListElement = communicationMentorRequest.injector(
  communicationSubscription.injector(SubscriptionsTabComponent)
);
