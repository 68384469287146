export enum EErrorStatus {
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  NotConfirmedEmail = 406,
  Conflict = 409,
  PayloadTooLarge = 413,
  UnsupportedMediaType = 415,
  Validation = 422,
  FailedDependency = 424,
  TooManyRequests = 429,
  RetryWith = 449,
  InternalServerError = 500,
  BadGateway = 502,
  ServiceUnavailable = 503
}

export enum ESuccessStatus {
  Ok = 200,
  Created = 201
}

export interface IBasePathParams {
  id: string;
}

export interface IBaseFilterModel {
  limit?: number;
  offset?: number;
}
