import React from 'react';
import Card from 'antd/es/card';
import Spin from 'antd/es/spin';
import Col from 'antd/es/col';
import { useMediaPredicate } from 'react-media-hook';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { PrivatePlayerProviderContext } from 'entities/PrivatePlayer/components/PrivatePlayerProvider';
import { PlayerFileUploadList } from 'entities/PrivatePlayer/components/Upload/PlayerFileUploadList';

export const PrivatePlayerDocumentTab: React.FC = () => {
  const { player } = React.useContext(PrivatePlayerProviderContext);
  const { loading } = player ?? {};
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);

  return (
    <Col span={24}>
      <Spin spinning={loading} className="width-full">
        <Card bordered={false} className={`radius-default mb-7 ${!isDesktop ? 'card-small-py px-0 py-3' : 'px-3'} `}>
          <PlayerFileUploadList />
        </Card>
      </Spin>
    </Col>
  );
};
