import { IPlayerProfileModel, IPlayerProfilePlayerModel } from 'entities/Player/Player.models';

export const extractPlayerFromProfile = (profile: IPlayerProfileModel): IPlayerProfilePlayerModel => ({
  rating: profile.rating,
  ageGroup: profile.ageGroup,
  firstName: profile.firstName,
  lastName: profile.lastName,
  id: profile.playerId,
  image: profile?.imageId ? { id: profile.imageId } : undefined,
  address: profile.address,
  leadingLeg: profile.leadingLeg,
  birthday: profile.birthday,
  gender: profile.gender,
  height: profile?.height,
  academy: profile.academy,
  recommendation: profile.recommendation,
  weight: profile.weight,
  lang: profile.lang,
  juniCoachPublicity: profile?.juniCoachPublicity,
  academyToPlayerRequest: profile?.academyToPlayerRequest
});
