import { ETestRanging } from 'entities/Test/Test.models';
import { IPlayerToTestInfo } from 'entities/Player/Player.models';

export const getResultBarColor = (rawValue: number, highlightNotRated?: boolean) => {
  if (rawValue === 0 && highlightNotRated) {
    return 'component-third';
  }

  const value = rawValue < 10 ? 10 : rawValue;

  switch (true) {
    case value < 33: {
      return 'danger';
    }
    case value < 66: {
      return 'warning';
    }
    default: {
      return 'success';
    }
  }
};

export const getResultData = ({ avg, min, max, playerValue, ranging }: any) => {
  const result: Partial<IPlayerToTestInfo> = { avg, min, max, playerValue, ranging };
  const isSB = result?.ranging === ETestRanging.SB;
  const playerTestValue = result?.playerValue || 0;

  if (result?.min === result?.max && result?.min === result?.playerValue) {
    const balancer = playerTestValue * 0.2;
    result.min = isSB ? playerTestValue + balancer : playerTestValue - balancer;
    result.max = isSB ? playerTestValue - balancer : playerTestValue + balancer;
  }

  if (isSB) {
    return {
      avg: -(result?.avg || 0),
      min: -(result?.min || 0),
      max: -(result?.max || 0),
      playerValue:
        result?.playerValue === null || result?.playerValue === undefined ? null : -(result?.playerValue ?? -(result?.min || 0))
    };
  }

  return {
    avg: result?.avg,
    min: result?.min,
    max: result?.max,
    playerValue: result?.playerValue === null || result?.playerValue === undefined ? null : result?.playerValue ?? result?.min
  };
};
