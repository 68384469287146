import React, { useCallback, useContext, useRef } from 'react';
import Button from 'antd/es/button';
import Carousel from 'antd/es/carousel';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { CarouselRef } from 'antd/es/carousel';
import CaretLeftOutlined from '@ant-design/icons/CaretLeftOutlined';
import CaretRightOutlined from '@ant-design/icons/CaretRightOutlined';
import { useMediaPredicate } from 'react-media-hook';
import { translateTestData } from 'common/helpers/test.helper';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { VideoPrivate } from 'common/components/Video/VideoPrivate';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { MeasurementContext } from 'common/components/Provider/MeasurementContext';
import { ITestModel } from 'entities/Test/Test.models';

const sizeHeight = 320;

interface IComponent {
  item: ITestModel;
}

const TestModalDescriptionComponent: React.FC<IComponent> = props => {
  const { item } = props;
  const carouselRef = useRef<CarouselRef>(null);
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const { firstImage, secondImage, video } = item;
  const { measurementSystem } = useContext(MeasurementContext);
  const { description } = translateTestData(item, measurementSystem);

  const carouselNext = useCallback(() => {
    carouselRef?.current?.next();
  }, []);

  const carouselPrev = useCallback(() => {
    carouselRef?.current?.prev();
  }, []);

  return (
    <>
      <Carousel className="mt-2 mb-10" ref={carouselRef}>
        {firstImage?.id && (
          <ImagePrivate className="radius-default" height={sizeHeight} alt="Image-first" imgId={firstImage?.id} />
        )}
        {secondImage?.id && (
          <ImagePrivate className="radius-default" height={sizeHeight} alt="Image-second" imgId={secondImage?.id} />
        )}
        {video?.id && <VideoPrivate className="width-full" height={sizeHeight} videoId={video?.id} preview={video?.image?.id} />}
      </Carousel>
      {isDesktop && (secondImage?.id || video?.id) && (
        <Row justify="center" className="mb-5">
          <Button onClick={carouselPrev}>
            <CaretLeftOutlined />
          </Button>
          <Button onClick={carouselNext}>
            <CaretRightOutlined />
          </Button>
        </Row>
      )}
      <Typography.Text title={description} className="t-pre-wrap">
        {description}
      </Typography.Text>
    </>
  );
};

export const TestModalDescription = TestModalDescriptionComponent;
