import React from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import Icon from '@ant-design/icons/es/components/Icon';
import { useTranslation } from 'react-i18next';
import Button from 'antd/es/button';
import { ReactComponent as NoDataIcon } from 'app/assets/icons/nodata-magnifier.svg';

interface IComponentProps {
  showClearFilterButton: boolean;
  clearFilter: () => void;
}

const TrainerAcademyListNoDataComponent: React.FC<IComponentProps> = props => {
  const { showClearFilterButton, clearFilter } = props;
  const { t } = useTranslation();

  return (
    <Row justify="center" align="middle">
      <Col xs={24} sm={12}>
        <Col span={24}>
          <Icon className="players-nodata__icon" component={NoDataIcon} />
        </Col>
        <Typography.Title level={3}>{t('Trainers not found')}</Typography.Title>
        {showClearFilterButton && <Typography.Text className="t-align-c">{t('empty-search-description')}</Typography.Text>}
        <Col span={24}>
          {showClearFilterButton && (
            <Button onClick={clearFilter} className="mt-9" size="large" type="primary">
              {t('Clear filter')}
            </Button>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export const TrainerAcademyListNoData = TrainerAcademyListNoDataComponent;
