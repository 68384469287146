import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import Switch from 'antd/es/switch';
import moment, { Moment } from 'moment';
import DatePicker from 'antd/lib/date-picker';
import { dateToMomentFormat, disabledFutureDates, getBirthdayAgeGroup } from 'common/helpers/date.helper';
import { ELocationType, ILocationModel } from 'common/helpers/location.helper';
import { GenderSelect } from 'common/components/GenderSelect';
import { LocationInput } from 'common/components/Input/LocationInput';
import { AgeGroupSelector } from 'common/components/Selector/AgeGroupSelector';
import { EPlayerAgeGroup } from 'entities/Player/Player.models';
import { IPrivatePlayerCollectionFilter, TPlayerFilterOrderField } from 'entities/PrivatePlayer/PrivatePlayer.models';
import { TrainerSelector } from 'entities/Trainer/components/Selectors/TrainerSelector';
import { ITrainerModel } from 'entities/Trainer/Trainer.models';
import { TeamSelector } from 'entities/Team/components/TeamSelector';
import { ITeamPureModel } from 'entities/Team/Team.models';
import { AcademySelector } from 'entities/Academy/components/Selector/AcademySelector';
import { IAcademySearchModel } from 'entities/Academy/Academy.models';
import { communicationPrivatePlayer, IPrivatePlayerConnectedProps } from 'entities/PrivatePlayer/PrivatePlayer.communication';
import { PlayerOrderFieldSelector } from 'entities/PrivatePlayer/components/Selectors/PlayerOrderFieldSelector';
import { EUserGender } from 'entities/User/User.models';

export enum PrivatePlayerFilterCardsFilters {
  Name = 'name',
  Location = 'location',
  AgeGroup = 'ageGroup',
  Year = 'year',
  Gender = 'gender',
  Trainer = 'trainer',
  HasTrainer = 'hasTrainer',
  HasMentor = 'hasMentor',
  Team = 'team',
  Academy = 'academy',
  OrderField = 'orderField',
  PlayerCount = 'playerCount'
}

const DefaultEnabledFilters = [
  PrivatePlayerFilterCardsFilters.AgeGroup,
  PrivatePlayerFilterCardsFilters.Year,
  PrivatePlayerFilterCardsFilters.Gender,
  PrivatePlayerFilterCardsFilters.HasTrainer,
  PrivatePlayerFilterCardsFilters.Location,
  PrivatePlayerFilterCardsFilters.Name,
  PrivatePlayerFilterCardsFilters.Trainer,
  PrivatePlayerFilterCardsFilters.Team,
  PrivatePlayerFilterCardsFilters.HasMentor,
  PrivatePlayerFilterCardsFilters.OrderField,
  PrivatePlayerFilterCardsFilters.Academy
];

const DefaultYearPickerValue: Moment = moment().subtract(15, 'years');

interface IComponentProps {
  onFilterChange: (filter: Partial<IPrivatePlayerCollectionFilter>) => void;
  filter?: Partial<IPrivatePlayerCollectionFilter>;
  academyId?: string;
  isFranshisor?: boolean;
  enabledFilters?: PrivatePlayerFilterCardsFilters[];
}

type AllProps = IPrivatePlayerConnectedProps & IComponentProps;

const PrivatePlayerListFilterCardComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const {
    onFilterChange,
    filter,
    academyId,
    enabledFilters = DefaultEnabledFilters,
    isFranshisor,
    privatePlayerCollection
  } = props;
  const playerCount = privatePlayerCollection?.data?.meta?.count;
  const yearData = filter?.year;
  const playerNameValue = filter?.name;
  const genderValue = filter?.gender;
  const locationValue = filter?.location;
  const ageGroupValue = filter?.ageGroup;
  const orderFieldValue = filter?.orderField;
  const trainerValue = filter?.trainerModel;
  const teamValue = filter?.teamModel;
  const academyFilterValue = filter?.academyFilter;
  const withoutTrainer = filter?.hasTrainer !== undefined ? !filter?.hasTrainer : undefined;
  const withoutTeam = filter?.hasTeam !== undefined ? !filter?.hasTeam : undefined;
  const withoutMentor = filter?.hasMentor !== undefined ? !filter?.hasMentor : undefined;
  const yearValue: Moment | undefined = yearData ? dateToMomentFormat(yearData.toString(), 'YYYY') : undefined;

  const debounceSearch = useDebouncedCallback((text: string) => {
    const name = text === '' ? undefined : text;
    onFilterChange({ name });
  }, 300);

  const locationSearch = (location?: ILocationModel) => {
    if (JSON.stringify(filter?.location) !== JSON.stringify(location)) {
      onFilterChange({ location });
    }
  };

  const onChangeAgeSelector = (ageGroup: EPlayerAgeGroup) => {
    onFilterChange({ ageGroup });
  };

  const onChangeOrderField = (orderField: TPlayerFilterOrderField) => {
    onFilterChange({ orderField });
  };

  const onChangeTrainerSelector = (trainerFilter?: ITrainerModel) => {
    const trainerModel = trainerFilter
      ? { id: trainerFilter.id, firstName: trainerFilter.firstName, lastName: trainerFilter.lastName }
      : undefined;

    onFilterChange({ trainerModel });
  };

  const onChangeTeamSelector = (rawTeam?: ITeamPureModel | ITeamPureModel[]) => {
    const selectedTeam: ITeamPureModel | undefined = Array.isArray(rawTeam) ? rawTeam?.[0] : rawTeam;
    const filterContent = selectedTeam ? { id: selectedTeam?.id, name: selectedTeam?.name } : undefined;
    onFilterChange({ teamModel: filterContent });
  };

  const onChangeHasTrainer = (checked: boolean) => {
    onFilterChange({ hasTrainer: checked ? !checked : undefined });
  };

  const onChangeHasTeam = (checked: boolean) => {
    onFilterChange({ hasTeam: checked ? !checked : undefined });
  };

  const onChangeHasMentor = (checked: boolean) => {
    onFilterChange({ hasMentor: checked ? !checked : undefined });
  };

  const onChangeAcademySelector = (academyModel?: IAcademySearchModel) => {
    const academyFilter = academyModel
      ? {
          id: academyModel?.id,
          name: academyModel?.name
        }
      : undefined;

    onFilterChange({ academyFilter });
  };

  const onChangeHideArchived = (hideArchived: boolean) => {
    onFilterChange({ hideArchived });
  };

  const onChangeGender = (gender: EUserGender) => {
    onFilterChange({ gender });
  };

  const onChangeBirthdayAgeGroupSelector = (date: any) => {
    const year = parseInt(getBirthdayAgeGroup(date)) || undefined;
    onFilterChange({ year });
  };

  return (
    <Card className="border-none px-3 pb-0 width-full players-private-filters">
      <Row gutter={[16, 16]}>
        {enabledFilters.includes(PrivatePlayerFilterCardsFilters.Name) && (
          <Col xs={24} sm={enabledFilters.includes(PrivatePlayerFilterCardsFilters.AgeGroup) ? 5 : 24}>
            <Typography.Text>{t('Player name')}</Typography.Text>
            <Input
              className="mt-3"
              size="large"
              name="playerName"
              type="text"
              placeholder={t('Player name')}
              defaultValue={playerNameValue}
              allowClear
              onChange={(e: ChangeEvent<HTMLInputElement>) => debounceSearch.callback(e.target.value)}
            />
          </Col>
        )}
        {enabledFilters.includes(PrivatePlayerFilterCardsFilters.OrderField) && (
          <Col xs={24} sm={enabledFilters.includes(PrivatePlayerFilterCardsFilters.AgeGroup) ? 5 : 24}>
            <Typography.Text>{t('Sort')}</Typography.Text>
            <PlayerOrderFieldSelector
              allowClear
              value={orderFieldValue}
              className="width-full mt-3"
              size="large"
              onChange={onChangeOrderField}
            />
          </Col>
        )}
        {enabledFilters.includes(PrivatePlayerFilterCardsFilters.AgeGroup) && (
          <Col xs={24} sm={enabledFilters.includes(PrivatePlayerFilterCardsFilters.Name) ? 5 : 24}>
            <Typography.Text>{t('Group')}</Typography.Text>
            <AgeGroupSelector
              allowClear
              value={ageGroupValue}
              className="width-full mt-3"
              size="large"
              onChange={onChangeAgeSelector}
            />
          </Col>
        )}
        {enabledFilters.includes(PrivatePlayerFilterCardsFilters.Year) && (
          <Col xs={24} sm={enabledFilters.includes(PrivatePlayerFilterCardsFilters.Name) ? 4 : 24}>
            <Typography.Text ellipsis>{t('Year of birth')}</Typography.Text>
            <DatePicker
              defaultPickerValue={yearValue ? undefined : DefaultYearPickerValue}
              allowClear
              disabledDate={disabledFutureDates}
              value={yearValue as any}
              size="large"
              className="width-full mt-3"
              format="YYYY"
              placeholder={t('Year')}
              onChange={onChangeBirthdayAgeGroupSelector}
              picker="year"
            />
          </Col>
        )}
        {enabledFilters.includes(PrivatePlayerFilterCardsFilters.Gender) && (
          <Col xs={24} sm={enabledFilters.includes(PrivatePlayerFilterCardsFilters.Name) ? 5 : 24}>
            <Typography.Text>{t('Gender')}</Typography.Text>
            <GenderSelect
              placeholder={t('Gender')}
              allowClear
              value={genderValue}
              className="width-full mt-3"
              size="large"
              onChange={onChangeGender}
            />
          </Col>
        )}
        {enabledFilters.includes(PrivatePlayerFilterCardsFilters.Location) && (
          <Col xs={24} sm={isFranshisor && enabledFilters.includes(PrivatePlayerFilterCardsFilters.Academy) ? 12 : 24}>
            <Typography.Text>{t('City or region')}</Typography.Text>
            <LocationInput
              types={[ELocationType.Regions]}
              className="mt-3"
              allowClear
              placeholder={t('City or region')}
              onChange={locationSearch}
              value={locationValue}
            />
          </Col>
        )}
        {isFranshisor && enabledFilters.includes(PrivatePlayerFilterCardsFilters.Academy) && (
          <Col xs={24} sm={enabledFilters.includes(PrivatePlayerFilterCardsFilters.Location) ? 12 : 24}>
            <Typography.Text>{t('Academy')}</Typography.Text>
            <AcademySelector
              className="mt-3"
              onChange={onChangeAcademySelector}
              value={academyFilterValue}
              size="large"
              placeholder={t('Academy')}
            />
          </Col>
        )}
        {enabledFilters.includes(PrivatePlayerFilterCardsFilters.Trainer) && (
          <Col xs={24} sm={enabledFilters.includes(PrivatePlayerFilterCardsFilters.Team) ? 12 : 24}>
            <Typography.Text>{t('Trainer')}</Typography.Text>
            <TrainerSelector
              className="mt-3"
              placeholder={t('Trainer')}
              size="large"
              academyId={academyId}
              onChange={onChangeTrainerSelector}
              value={trainerValue}
            />
          </Col>
        )}
        {enabledFilters.includes(PrivatePlayerFilterCardsFilters.Team) && (
          <Col xs={24} sm={enabledFilters.includes(PrivatePlayerFilterCardsFilters.Trainer) ? 12 : 24}>
            <Typography.Text className="mr-3">{t('Team')}</Typography.Text>
            <TeamSelector
              className="mt-3"
              onChange={onChangeTeamSelector}
              value={teamValue}
              academyId={academyId}
              size="large"
              placeholder={t('Team')}
            />
          </Col>
        )}

        <Col xs={24}>
          <Row justify="space-between" gutter={[12, 8]}>
            <Col sm={4} xs={24}>
              {enabledFilters.includes(PrivatePlayerFilterCardsFilters.PlayerCount) && playerCount !== undefined && (
                <Typography.Text title={`${t('Players')}: ${playerCount}`}>{`${t('Players')}: ${playerCount}`}</Typography.Text>
              )}
            </Col>
            <Col sm={20} xs={24}>
              <Row justify="end" gutter={[12, 8]}>
                {enabledFilters.includes(PrivatePlayerFilterCardsFilters.HasMentor) && (
                  <Col className="t-align-e">
                    <Typography.Text className="mr-3">{t('Without mentor')}</Typography.Text>
                    <Switch checked={withoutMentor} onChange={onChangeHasMentor} />
                  </Col>
                )}
                {enabledFilters.includes(PrivatePlayerFilterCardsFilters.Team) && (
                  <Col className="t-align-e">
                    <Typography.Text className="mr-3">{t('Without team')}</Typography.Text>
                    <Switch checked={withoutTeam} onChange={onChangeHasTeam} />
                  </Col>
                )}
                {enabledFilters.includes(PrivatePlayerFilterCardsFilters.HasTrainer) && (
                  <Col className="t-align-e">
                    <Typography.Text className="mr-3">{t('Without trainer')}</Typography.Text>
                    <Switch checked={withoutTrainer} onChange={onChangeHasTrainer} />
                  </Col>
                )}
                <Col className="t-align-e">
                  <Typography.Text className="mr-3">{t('Hide archived')}</Typography.Text>
                  <Switch checked={filter?.hideArchived} onChange={onChangeHideArchived} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export const PrivatePlayerListFilterCard = communicationPrivatePlayer.injector(PrivatePlayerListFilterCardComponent);
