import React from 'react';
import Typography from 'antd/es/typography';
import { ButtonsComponent } from 'common/components/ComponentsPage/Buttons';
import { InputsComponent } from 'common/components/ComponentsPage/Inputs';
import { CheckboxComponent } from 'common/components/ComponentsPage/Checkbox';
import { TypographyComponent } from 'common/components/ComponentsPage/Typography';
import { SelectComponent } from 'common/components/ComponentsPage/Select';
import { RadioButtonsComponent } from 'common/components/ComponentsPage/RadioButtons';
import { TabsComponent } from 'common/components/ComponentsPage/Tabs';

const { Title } = Typography;

const ComponentsPageComponent: React.FC = () => {
  return (
    <div className="components-page">
      <Title>Components Page</Title>
      <TypographyComponent />
      <ButtonsComponent />
      <InputsComponent />
      <SelectComponent />
      <CheckboxComponent />
      <RadioButtonsComponent />
      <TabsComponent />
    </div>
  );
};

export default ComponentsPageComponent;
