import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  getStartType,
  getSuccessType,
  StoreBranch
} from '@axmit/redux-communications';
import { put } from 'redux-saga/effects';
import { store } from 'common/helpers/axios.helper';
import {
  EMentorRequestToAcademyModalType,
  IModalMentorRequestModel,
  IModalMentorRequestParams,
  IModalMentorRequestToAcademyModel,
  IModalMentorRequestToAcademyParams,
  IModalModel,
  IModalPaymentRequirementsModel,
  IModalPaymentRequirementsParams,
  IModalChangeTariffParams,
  IModalChangeTariffModel,
  IModalFranchiseInvitationAcceptModel,
  IModalFranchiseInvitationAcceptParams,
  IModalFranchiseInviteParams,
  IModalFranchiseInviteModel,
  IModalAcademyTrainerInviteParams,
  IModalAcademyTrainerInviteModel,
  IModalAcademyPlayerCreateModel,
  IModalMentorInviteParams,
  IModalMentorInviteModel,
  IModalPlayerProfileTestResultModel,
  IAcademyLastTeamModel,
  IAcademyLastTeamParams,
  IModalTestResultModel,
  IModalTestResultParams,
  IModalPlayerProfileTestResultParams,
  ITransferPlayerUIModel,
  ITransferPlayerUIParams,
  IModalStripePaymentModel,
  IModalStripePaymentParams,
  IModalMentorSignUpModel,
  IModalMentorSignUpParams,
  IModalStripeCancelSubscriptionParams,
  IModalStripeCancelSubscriptionModel,
  IShareLinkModel,
  IShareLinkParams,
  IPlayerMergeModalModel,
  IPlayerMergeModalParams,
  IPlayerEmailEditModalModel,
  IPlayerEmailEditModalParams
} from 'entities/UI/UI.models';
import { namespace as privatePlayerNamespace } from 'entities/PrivatePlayer/PrivatePlayer.communication';
import { teamNamespace } from 'entities/Team/Team.communication';

const namespace = 'UI';

export interface IUIConnectedProps {
  UICommonModal: StoreBranch<IModalModel>;
  UIMentorSignUpModal: StoreBranch<IModalMentorSignUpModel>;
  UIMentorRequestModal: StoreBranch<IModalMentorRequestModel>;
  UIFranchiseInviteModal: StoreBranch<IModalFranchiseInviteModel>;
  UIPaymentRequirementsModal: StoreBranch<IModalPaymentRequirementsModel>;
  UIMentorRequestToAcademyModal: StoreBranch<IModalMentorRequestToAcademyModel>;
  UIChangeTariffModal: StoreBranch<IModalChangeTariffModel>;
  UIStripePaymentModal: StoreBranch<IModalStripePaymentModel, IModalStripePaymentParams>;
  UIStripeCancelSubscriptionModal: StoreBranch<IModalStripeCancelSubscriptionModel, IModalStripeCancelSubscriptionParams>;
  UIFranchiseInvitationAcceptModal: StoreBranch<IModalFranchiseInvitationAcceptModel>;
  UIAcademyTrainerInviteModal: StoreBranch<IModalAcademyTrainerInviteModel>;
  UIMentorInviteModal: StoreBranch<IModalMentorInviteModel>;
  UIAcademyPlayerCreateModal: StoreBranch<IModalAcademyPlayerCreateModel>;
  UITeamCreateModal: StoreBranch<IModalModel>;
  UIPlayerProfileTestResultModal: StoreBranch<IModalPlayerProfileTestResultModel>;
  UIAcademyLastTeamModel: StoreBranch<IAcademyLastTeamModel, IAcademyLastTeamParams>;
  UITransferPlayerModal: StoreBranch<ITransferPlayerUIModel, ITransferPlayerUIParams>;
  UIShareLinkModel: StoreBranch<IShareLinkModel, ITransferPlayerUIParams>;

  UITestResultModal: StoreBranch<IModalTestResultModel>;
  UIPlayerMergeModal: StoreBranch<IPlayerMergeModalModel, IPlayerMergeModalParams>;

  openUIPlayerMergeModal(params?: IPlayerMergeModalParams): void;
  closeUIPlayerMergeModal(): void;

  UIPlayerEmailEditModal: StoreBranch<IPlayerEmailEditModalModel, IPlayerEmailEditModalParams>;

  openUIPlayerEmailEditModal(params?: IPlayerEmailEditModalParams): void;
  closeUIPlayerEmailEditModal(): void;

  openUICommonModal(): void;
  closeUICommonModal(): void;

  openUIMentorSignUpModal(params: IModalMentorSignUpParams): void;
  closeUIMentorSignUpModal(): void;

  openUIMentorRequestModal(params: IModalMentorRequestParams): void;
  closeUIMentorRequestModal(): void;

  openUIFranchiseInviteModal(params: IModalFranchiseInviteParams): void;
  closeUIFranchiseInviteModal(): void;

  openUIAcademyTrainerInviteModal(params: IModalAcademyTrainerInviteParams): void;
  closeUIAcademyTrainerInviteModal(): void;

  openUIMentorInviteModal(params: IModalMentorInviteParams): void;
  closeUIMentorInviteModal(): void;

  openUIPaymentRequirementsModal(params: IModalPaymentRequirementsParams): void;
  closeUIPaymentRequirementsModal(): void;

  openUIMentorRequestToAcademyModal(params: IModalMentorRequestToAcademyParams): void;
  closeUIMentorRequestToAcademyModal(): void;

  openUIChangeTariffModal(params: IModalChangeTariffParams): void;
  closeUIChangeTariffModal(): void;

  openUIStripePaymentModal(params: IModalStripePaymentParams): void;
  updateUIStripePaymentModal(params: IModalStripePaymentParams): void;
  closeUIStripePaymentModal(): void;

  openUIStripeCancelSubscriptionModal(params: IModalStripeCancelSubscriptionParams): void;
  closeUIStripeCancelSubscriptionModal(): void;

  openUIFranchiseInvitationAcceptModal(params: IModalFranchiseInvitationAcceptParams): void;
  closeUIFranchiseInvitationAcceptModal(): void;

  openUITransferPlayerModal(params: ITransferPlayerUIParams): void;
  closeUITransferPlayerModal(): void;

  openUIAcademyPlayerCreateModal(): void;
  closeUIAcademyPlayerCreateModal(): void;

  openUITeamCreateModal(): void;
  closeUITeamCreateModal(): void;

  openUIPlayerProfileTestResultModal(data: IModalPlayerProfileTestResultParams): void;
  closeUIPlayerProfileTestResultModal(): void;

  openUITestResultModal(data: IModalTestResultParams): void;
  closeUITestResultModal(): void;

  openUIShareLinkModel(data: IShareLinkModel): void;
  closeUIShareLinkModel(): void;

  saveUIAcademyLastTeamModel(params: IAcademyLastTeamParams): void;
}

const CommonModalApiProvider = [
  new APIProvider('open', (): Promise<IModalModel> => Promise.resolve({ isVisible: true })),
  new APIProvider('close', (): Promise<IModalModel> => Promise.resolve({ isVisible: false }))
];

const mentorRequestModalApiProvider = [
  new APIProvider(
    'open',
    (params: IModalMentorRequestParams): Promise<IModalMentorRequestModel> => Promise.resolve({ isVisible: true, ...params })
  ),
  new APIProvider('close', (): Promise<IModalMentorRequestModel> => Promise.resolve({ isVisible: false, modalType: null }))
];

const mentorSignUpModalApiProvider = [
  new APIProvider(
    'open',
    (params: IModalMentorSignUpParams): Promise<IModalMentorSignUpModel> => Promise.resolve({ isVisible: true, ...params })
  ),
  new APIProvider(
    'close',
    (): Promise<IModalMentorSignUpModel> => Promise.resolve({ isVisible: false, tokenExpired: undefined, modalType: null })
  )
];

const franchiseInviteModalApiProvider = [
  new APIProvider(
    'open',
    (params: IModalFranchiseInviteParams): Promise<IModalFranchiseInviteModel> => Promise.resolve({ isVisible: true, ...params })
  ),
  new APIProvider('close', (): Promise<IModalFranchiseInviteModel> => Promise.resolve({ isVisible: false, modalType: null }))
];

const academyPlayerCreateModalApiProvider = [
  new APIProvider('open', (): Promise<IModalAcademyPlayerCreateModel> => Promise.resolve({ isVisible: true })),
  new APIProvider('close', (): Promise<IModalAcademyPlayerCreateModel> => Promise.resolve({ isVisible: false }))
];

const teamCreateModalApiProvider = [
  new APIProvider('open', (): Promise<IModalModel> => Promise.resolve({ isVisible: true })),
  new APIProvider('close', (): Promise<IModalModel> => Promise.resolve({ isVisible: false }))
];

const academyTrainerInviteModalApiProvider = [
  new APIProvider(
    'open',
    (params: IModalAcademyTrainerInviteParams): Promise<IModalAcademyTrainerInviteModel> =>
      Promise.resolve({ isVisible: true, ...params })
  ),
  new APIProvider('close', (): Promise<IModalAcademyTrainerInviteModel> => Promise.resolve({ isVisible: false, modalType: null }))
];

const transferPlayerModalApiProvider = [
  new APIProvider(
    'open',
    (params: ITransferPlayerUIParams): Promise<ITransferPlayerUIModel> => Promise.resolve({ isVisible: true, ...params })
  ),
  new APIProvider('close', (): Promise<ITransferPlayerUIModel> => Promise.resolve({ isVisible: false, playerId: null }))
];

const mergeModalApiProvider = [
  new APIProvider(
    'open',
    (params: IPlayerMergeModalParams): Promise<IPlayerMergeModalModel> => Promise.resolve({ isVisible: true, ...params })
  ),
  new APIProvider(
    'close',
    (): Promise<IPlayerMergeModalParams> => Promise.resolve({ isVisible: false, sourceId: undefined, targetId: undefined })
  )
];
const editPlayerEmailModalApiProvider = [
  new APIProvider(
    'open',
    (params: IPlayerEmailEditModalParams): Promise<IPlayerEmailEditModalModel> => Promise.resolve({ isVisible: true, ...params })
  ),
  new APIProvider(
    'close',
    (): Promise<IPlayerEmailEditModalModel> =>
      Promise.resolve({ isVisible: false, mentorRequestId: undefined, playerId: undefined, needAcceptRequest: undefined })
  )
];

const mentorInviteModalApiProvider = [
  new APIProvider(
    'open',
    (params: IModalMentorInviteParams): Promise<IModalMentorInviteModel> => Promise.resolve({ isVisible: true, ...params })
  ),
  new APIProvider(
    'close',
    (): Promise<IModalMentorInviteModel> => Promise.resolve({ isVisible: false, modalType: null, inviteFromList: undefined })
  )
];

const paymentRequirementsModalApiProvider = [
  new APIProvider(
    'open',
    (params: IModalPaymentRequirementsParams): Promise<IModalPaymentRequirementsModel> =>
      Promise.resolve({ isVisible: true, ...params })
  ),
  new APIProvider(
    'close',
    (): Promise<IModalPaymentRequirementsModel> =>
      Promise.resolve({ isVisible: false, subscriptionId: undefined, mentorRequestId: undefined, processing: undefined })
  )
];

const changeFranchiseInvitationAcceptApiProvider = [
  new APIProvider(
    'open',
    (params: IModalFranchiseInvitationAcceptParams): Promise<IModalFranchiseInvitationAcceptModel> =>
      Promise.resolve({ isVisible: true, ...params })
  ),
  new APIProvider(
    'close',
    (): Promise<IModalFranchiseInvitationAcceptModel> => Promise.resolve({ isVisible: false, franchiseName: '' })
  )
];

const changeTariffModalApiProvider = [
  new APIProvider(
    'open',
    (params: IModalChangeTariffParams): Promise<IModalChangeTariffModel> => Promise.resolve({ isVisible: true, ...params })
  ),
  new APIProvider(
    'close',
    (): Promise<IModalChangeTariffParams> => Promise.resolve({ isVisible: false, subscriptionId: undefined })
  )
];

const stripePaymentModalApiProvider = [
  new APIProvider(
    'open',
    (params: IModalStripePaymentParams): Promise<IModalStripePaymentModel> => Promise.resolve({ isVisible: true, ...params })
  ),
  new APIProvider(
    'update',
    (params: IModalStripePaymentParams): Promise<IModalStripePaymentModel> => Promise.resolve({ ...params }),
    {
      preRequestDataMapper: (response, payload, branchState) => {
        return branchState.data;
      },
      mapParams: (originalParams, branchState) => {
        return {
          ...branchState?.data,
          ...originalParams
        };
      }
    }
  ),
  new APIProvider(
    'close',
    (): Promise<IModalStripePaymentModel> =>
      Promise.resolve({
        isVisible: false,
        withCardDetails: undefined,
        withCoupon: undefined,
        title: undefined,
        cardDetailBtnText: undefined,
        loading: false,
        tariff: undefined,
        mentorRequest: undefined,
        subscription: undefined
      })
  )
];

const stripeCancelSubscriptionModalApiProvider = [
  new APIProvider(
    'open',
    (params: IModalStripeCancelSubscriptionParams): Promise<IModalStripeCancelSubscriptionModel> =>
      Promise.resolve({ isVisible: true, ...params })
  ),
  new APIProvider(
    'close',
    (): Promise<IModalStripeCancelSubscriptionModel> =>
      Promise.resolve({
        isVisible: false,
        subscription: undefined
      })
  )
];

const mentorRequestToAcademyModalApiProvider = [
  new APIProvider(
    'open',
    (params: IModalMentorRequestToAcademyParams): Promise<IModalMentorRequestToAcademyModel> =>
      Promise.resolve({ isVisible: true, ...params })
  ),
  new APIProvider(
    'close',
    (): Promise<IModalMentorRequestToAcademyModel> => Promise.resolve({ isVisible: false, modalType: null })
  )
];

const playerProfileTestResultModalApiProvider = [
  new APIProvider(
    'open',
    ({ params }: IModalPlayerProfileTestResultParams): Promise<IModalPlayerProfileTestResultModel> =>
      Promise.resolve({ isVisible: true, params })
  ),
  new APIProvider('close', (): Promise<IModalPlayerProfileTestResultModel> => Promise.resolve({ isVisible: false, params: null }))
];

const testResultModalApiProvider = [
  new APIProvider(
    'open',
    ({ params }: IModalTestResultParams): Promise<IModalTestResultModel> => Promise.resolve({ isVisible: true, params })
  ),
  new APIProvider('close', (): Promise<IModalTestResultModel> => Promise.resolve({ isVisible: false, params: null }))
];

const shareLinkModalApiProvider = [
  new APIProvider('open', (params: IShareLinkParams): Promise<IShareLinkModel> => Promise.resolve(params)),
  new APIProvider('close', (): Promise<IShareLinkModel> => Promise.resolve({}))
];

const academyLastTeamModelApiProvider = [
  new APIProvider('save', (params: IAcademyLastTeamParams): Promise<IAcademyLastTeamModel> => Promise.resolve({ ...params }))
];

const branches = [
  new Branch('commonModal', CommonModalApiProvider),
  new Branch('mentorSignUpModal', mentorSignUpModalApiProvider),
  new Branch('mentorRequestModal', mentorRequestModalApiProvider),
  new Branch('franchiseInviteModal', franchiseInviteModalApiProvider),
  new Branch('academyTrainerInviteModal', academyTrainerInviteModalApiProvider),
  new Branch('mentorInviteModal', mentorInviteModalApiProvider),
  new Branch('mentorRequestToAcademyModal', mentorRequestToAcademyModalApiProvider),
  new Branch('paymentRequirementsModal', paymentRequirementsModalApiProvider),
  new Branch('changeTariffModal', changeTariffModalApiProvider),
  new Branch('stripePaymentModal', stripePaymentModalApiProvider),
  new Branch('stripeCancelSubscriptionModal', stripeCancelSubscriptionModalApiProvider),
  new Branch('franchiseInvitationAcceptModal', changeFranchiseInvitationAcceptApiProvider),
  new Branch('academyPlayerCreateModal', academyPlayerCreateModalApiProvider),
  new Branch('teamCreateModal', teamCreateModalApiProvider),
  new Branch('playerProfileTestResultModal', playerProfileTestResultModalApiProvider),
  new Branch('testResultModal', testResultModalApiProvider),
  new Branch('academyLastTeamModel', academyLastTeamModelApiProvider),
  new Branch('shareLinkModel', shareLinkModalApiProvider),
  new Branch('transferPlayerModal', transferPlayerModalApiProvider),
  new Branch('playerMergeModal', mergeModalApiProvider),
  new Branch('playerEmailEditModal', editPlayerEmailModalApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationUI = buildCommunication<IUIConnectedProps>(strategy);

export function* closeCommonModal() {
  yield put({ type: getStartType(namespace, 'commonModal', 'close') });
}

export function* updateMentorRequestModal(params: IModalMentorRequestModel) {
  yield put({
    type: getSuccessType(namespace, 'mentorRequestModal', 'open'),
    payload: params
  });
}
export function* updateFranchiseInviteModal(params: IModalFranchiseInviteModel) {
  yield put({
    type: getSuccessType(namespace, 'franchiseInviteModal', 'open'),
    payload: params
  });
}
export function* updateAcademyTrainerInviteModal(params: IModalAcademyTrainerInviteModel) {
  yield put({
    type: getSuccessType(namespace, 'academyTrainerInviteModal', 'open'),
    payload: params
  });
}
export function* updateMentorInviteModal(params: IModalMentorInviteModel) {
  yield put({
    type: getSuccessType(namespace, 'mentorInviteModal', 'open'),
    payload: params
  });
}
export function* updatePaymentRequirementsModal(params: IModalPaymentRequirementsParams) {
  yield put({
    type: getStartType(namespace, 'paymentRequirementsModal', 'open'),
    payload: params
  });
}
export function* closeMentorRequestModal() {
  yield put({ type: getStartType(namespace, 'mentorRequestModal', 'close') });
}
export function* closeMentorSignUpModal() {
  yield put({ type: getStartType(namespace, 'mentorSignUpModal', 'close') });
}
export function* closeTransferPlayerModal() {
  yield put({ type: getStartType(namespace, 'TransferPlayerModal', 'close') });
}
export function* closeFranchiseInviteModal() {
  yield put({ type: getStartType(namespace, 'franchiseInviteModal', 'close') });
}
export function* closeAcademyPlayerCreateModal() {
  yield put({ type: getStartType(namespace, 'academyPlayerCreateModal', 'close') });
  yield put({ type: getStartType(privatePlayerNamespace, 'model', 'clear') });
}
export function* closeTeamCreateModal() {
  yield put({ type: getStartType(namespace, 'teamCreateModal', 'close') });
  yield put({ type: getStartType(teamNamespace, 'model', 'clear') });
}
export function* closePaymentRequirementsModal() {
  yield put({ type: getStartType(namespace, 'paymentRequirementsModal', 'close') });
}

export function* openChangeTariffModal(params: IModalChangeTariffParams) {
  yield put({
    type: getStartType(namespace, 'changeTariffModal', 'open'),
    payload: params
  });
}
export function* closeChangeTariffModal() {
  yield put({ type: getStartType(namespace, 'changeTariffModal', 'close') });
}

export function* closeStripePaymentModal() {
  yield put({ type: getStartType(namespace, 'stripePaymentModal', 'close') });
}

export function* closeStripeCancelSubscriptionModal() {
  yield put({ type: getStartType(namespace, 'stripeCancelSubscriptionModal', 'close') });
}

export function* openFranchiseInvitationAcceptModal(params: IModalFranchiseInvitationAcceptParams) {
  yield put({
    type: getStartType(namespace, 'franchiseInvitationAcceptModal', 'open'),
    payload: params
  });
}

export function* closeFranchiseInvitationAcceptModal() {
  yield put({ type: getStartType(namespace, 'franchiseInvitationAcceptModal', 'close') });
}

export function* closeMentorRequestToAcademyModal() {
  yield put({ type: getStartType(namespace, 'mentorRequestToAcademyModal', 'close') });
}

export function* openMentorRequestToAcademySuccessModal() {
  yield put({
    type: getStartType(namespace, 'mentorRequestToAcademyModal', 'open'),
    payload: { isVisible: true, modalType: EMentorRequestToAcademyModalType.Success }
  });
}

export function openShareLinkModalAction(body: IShareLinkModel) {
  store.dispatch({ type: getStartType(namespace, 'shareLinkModel', 'open'), payload: body });
}
