import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import { useTranslation } from 'react-i18next';
import { Header } from 'common/components/Header/Header';
import { HeaderPromo } from 'common/components/Header/HeaderPromo';
import { HeaderRating } from 'common/components/Header/HeaderRating';
import { HeaderWithoutAuth } from 'common/components/Header/HeaderWithoutAuth';
import { LayoutBasic } from 'common/components/Layouts/BasicLayout';
import { ERoutesCommon, ERoutesMentor, ERoutesAcademy, ERoutesPublic, playersCompareRoute } from 'common/models/routesModel';
import { PermissionLayout } from 'common/components/Layouts/PermissionLayout';
import { RoleCheckLayout } from 'common/components/Layouts/RoleCheckLayout';
import { RootAcademyNotActiveStatusPage } from 'common/components/Pages/RootAcademyNotActivePage';
import { RootMentorPage } from 'common/components/Pages/RootMentorPage';
import { RootAcademyPage } from 'common/components/Pages/RootAcademyPage';
import { RootPublicPage } from 'common/components/Pages/RootPublicPage';
import ComponentsPage from 'common/components/Pages/ComponentsPage';
import { AcademyStatusLayout } from 'common/components/Layouts/AcademyStatusLayout';
import { CenteredWithLogoLayout } from 'common/components/Layouts/CenteredWithLogoLayout';
import { HelpButton } from 'common/components/HelpButton';
import { MentorRequestPage } from 'app/pages/Mentor/MentorRequestPage';
import { MentorPlayerPage } from 'app/pages/Mentor/MentorPlayerPage';
import { MentorPlayersPage } from 'app/pages/Mentor/MentorPlayersPage';
import { InitAcademyInfoPage } from 'app/pages/Academy/InitAcademyInfoPage';
import { MentorEditPage } from 'app/pages/Mentor/MentorEditPage';
import { AcademyEditPage } from 'app/pages/Academy/AcademyEditPage';
import { CommonSharedViewPage } from 'app/pages/Common/CommonSharedViewPage';
import { PlayerPublicRatingPage } from 'app/pages/Common/PlayerPublicRatingPage';
import { CommonRatingPage } from 'app/pages/Common/CommonRatingPage';
import { CoachInvitePage } from 'app/pages/Coach/CoachInvitePage';
import { PaymentAndSubscriptionPage } from 'app/pages/Mentor/PaymentAndSubscriptionPage';
import { AcademyReferralPage } from 'app/pages/Academy/AcademyReferral';
import { CommonNotificationsPage } from 'app/pages/Common/CommonNotificationsPage';
import { FranchiseInvitePage } from 'app/pages/Academy/FranchiseInvitePage';
import { MentorPlayerInvitePage } from 'app/pages/Mentor/MentorPlayerInvitePage';
import { AcademyViewPage } from 'app/pages/Academy/AcademyViewPage';
import { BanInfoPage } from 'app/pages/Common/BanInfoPage';
import { RfsAuthPagePage } from 'app/pages/RfsAuthPage';
import { SignUpFinishPage } from 'app/pages/Academy/SignUpFinishPage';
import { RedirectPage } from 'app/pages/Common/RedirectPage';
import { CoachPasswordRestorePage } from 'app/pages/Coach/CoachPasswordRestorePage';
import { CoachSignUpPage } from 'app/pages/Coach/CoachSignUpPage';
import { CoachRestorePasswordSuccessPage } from 'app/pages/Coach/CoachRestorePasswordSuccessPage';
import { InfluencerStatisticsPage } from 'app/pages/Common/InfluencerStatisticsPage';
import { PlayerProfileNotFoundPage } from 'app/pages/Common/PlayerProfileNotFoundPage';
import { UnauthorizedPlayerPage } from 'app/pages/UnauthorizedPlayerPage';
import { CoachLoginPage } from 'app/pages/Coach/CoachLoginPage';
import { AcademyStatisticPage } from 'app/pages/Academy/AcademyStatisticPage';
import { MentorPlayersComparePage } from 'app/pages/Mentor/MentorPlayersComparePage';
import { ShareModal } from 'entities/Share/components/ShareModal';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { SignUpConfirmPage } from 'entities/Auth/components/SignUpConfirmPage';
import { PasswordRestorePage } from 'entities/Auth/components/PasswordRestorePage';
import { PasswordForgotPage } from 'entities/Auth/components/PasswordForgotPage';
import { ServiceDocumentsPage } from 'entities/Auth/components/ServiceDocumentsPage';
import { SignUpSuccessPage } from 'entities/Auth/components/SignUpSuccessPage';
import { WelcomePage } from 'entities/Auth/components/WelcomePage';
import { AcademyModerationCard } from 'entities/Academy/components/AcademyModerationCard';
import { ValidateSubscriptionPaymentLayout } from 'entities/Subscription/components/ValidateSubscriptionPaymentLayout';
import { FranchiseInvitationAcceptSuccessModal } from 'entities/Franchise/components/FranchiseInvitationAcceptSuccessModal';
import { MentorSignUpModal } from 'entities/Auth/components/Modal/MentorSignUpModal';
import { MentorInviteModal } from 'entities/PrivatePlayer/components/View/InviteMentor/MentorInviteModal';
import { MentorInvitePage } from 'entities/MentorRequest/components/MentorInvitePage';
import { AuthPage } from 'entities/Auth/components/AuthPage';
import { JuniStatAppLinkPage } from 'entities/Auth/components/JuniStatAppLinkPage';
import { UserLoginPage } from 'entities/Auth/components/UserLoginPage';

type AllProps = IAuthConnectedProps;

const AppComponent: React.FC<AllProps> = () => {
  const { t } = useTranslation();

  return (
    <>
      <Switch>
        <Route
          path={ERoutesCommon.JuniStatLink}
          component={() => (
            <CenteredWithLogoLayout>
              <div className="auth-page width-full">
                <JuniStatAppLinkPage />
                <Row justify="center" align="middle">
                  <Link to={ERoutesCommon.Root}>
                    <Button type="primary" size="large" className="t-align-c mt-3">
                      {t('Home')}
                    </Button>
                  </Link>
                </Row>
              </div>
            </CenteredWithLogoLayout>
          )}
          exact
        />
        <Route path={ERoutesPublic.SignUpConfirmUser} component={SignUpConfirmPage} exact />
        <Route
          path={ERoutesPublic.ShareView}
          component={() => (
            <>
              <HeaderPromo />
              <LayoutBasic id="basicLayout" className="promo-layout">
                <CommonSharedViewPage />
              </LayoutBasic>
            </>
          )}
          exact
        />
        <Route
          path={ERoutesPublic.Rating}
          render={() => (
            <>
              <HeaderRating />
              <LayoutBasic id="basicLayout" className="rating-layout mt-0">
                <CommonRatingPage />
              </LayoutBasic>
            </>
          )}
        />
        <Route
          path={`${ERoutesPublic.RatingPlayer}/:id`}
          render={() => (
            <>
              <HeaderRating />
              <LayoutBasic id="basicLayout" className="rating-layout bg-page mt-0">
                <PlayerPublicRatingPage />
              </LayoutBasic>
            </>
          )}
        />
        <Route
          path={ERoutesPublic.MentorPlayerInviteNew}
          render={() => (
            <>
              <HeaderWithoutAuth />
              <MentorPlayerInvitePage />
            </>
          )}
          exact
        />
        <Route
          path={ERoutesPublic.MentorPlayerInviteExist}
          render={() => (
            <>
              <HeaderWithoutAuth />
              <MentorPlayerInvitePage isExist />
            </>
          )}
          exact
        />
        <Route path={ERoutesPublic.Redirect} component={RedirectPage} exact />
        <Route
          path={[ERoutesPublic.TrainerInviteNew, ERoutesPublic.TrainerInviteExist]}
          render={() => (
            <>
              <HeaderWithoutAuth />
              <CoachInvitePage />
            </>
          )}
          exact
        />
        <Route
          path={ERoutesPublic.CoachSignUp}
          render={() => (
            <>
              <HeaderWithoutAuth />
              <CoachSignUpPage />
            </>
          )}
          exact
        />
        <Route
          path={ERoutesPublic.CoachLogin}
          render={() => (
            <>
              <HeaderWithoutAuth />
              <CoachLoginPage />
            </>
          )}
          exact
        />
        <Route
          path={ERoutesPublic.CoachPasswordRestore}
          render={() => (
            <>
              <HeaderWithoutAuth />
              <CoachPasswordRestorePage />
            </>
          )}
          exact
        />
        <Route
          path={ERoutesPublic.FranchiseInviteNew}
          render={() => (
            <>
              <HeaderWithoutAuth />
              <FranchiseInvitePage />
            </>
          )}
          exact
        />
        <Route
          path={ERoutesPublic.FranchiseInviteExist}
          render={() => (
            <>
              <HeaderWithoutAuth />
              <FranchiseInvitePage isExist />
            </>
          )}
          exact
        />
        <Route
          path={ERoutesPublic.InfluencerStatisticsPage}
          render={() => (
            <>
              <HeaderWithoutAuth />
              <InfluencerStatisticsPage />
            </>
          )}
          exact
        />
        <Route
          path={ERoutesPublic.PlayerProfileNotFound}
          render={() => (
            <LayoutBasic id="basicLayout" className="player-profile-not-found-layout mt-0">
              <PlayerProfileNotFoundPage />
            </LayoutBasic>
          )}
          exact
        />
        <Route
          path={ERoutesPublic.PlayerProfile}
          render={() => {
            const playerId = location.pathname.split('/').slice(-1)?.[0];

            if (playerId) {
              return <Redirect to={`${ERoutesPublic.Player}/${playerId}`} />;
            }

            return <Redirect to={ERoutesPublic.Login} />;

            // todo This component is not used because it is outdated use it for example only
            // <LayoutBasic id="basicLayout" className="player-profile-layout mt-0">
            //   <PlayerProfilePage />
            // </LayoutBasic>;
          }}
          exact
        />
        <PermissionLayout
          publicRoutes={
            <>
              <MentorSignUpModal />
              <HeaderWithoutAuth />
              <Switch>
                <Route
                  path={`${ERoutesPublic.PlayerView}/:id`}
                  render={() => (
                    <LayoutBasic id="basicLayout" className="mt-0 pt-0 height-full">
                      <UnauthorizedPlayerPage />
                    </LayoutBasic>
                  )}
                />
                <Route
                  path={ERoutesPublic.MentorInvite}
                  render={() => (
                    <LayoutBasic id="basicLayout">
                      <MentorInvitePage />
                    </LayoutBasic>
                  )}
                  exact
                />
                <Route
                  path={`${ERoutesPublic.Player}/:id`}
                  // eslint-disable-next-line
                  render={() => (
                    <LayoutBasic id="basicLayout">
                      <UnauthorizedPlayerPage />
                    </LayoutBasic>
                  )}
                />
                <CenteredWithLogoLayout>
                  <Switch>
                    <Route path={ERoutesPublic.Welcome} component={WelcomePage} exact />
                    <Route path={[ERoutesPublic.SignUp, ERoutesPublic.Login]} component={AuthPage} exact />
                    <Route path={ERoutesPublic.PasswordRestoreSuccess} component={CoachRestorePasswordSuccessPage} exact />
                    <Route path={ERoutesPublic.PasswordForgot} component={PasswordForgotPage} exact />
                    <Route path={ERoutesPublic.PasswordRestore} component={PasswordRestorePage} exact />
                    <Route path={ERoutesPublic.RfsSSO} component={RfsAuthPagePage} exact />
                    <Route path={ERoutesPublic.SignUpConfirmUser} component={SignUpConfirmPage} exact />
                    <Route path={ERoutesPublic.UserLogin} component={UserLoginPage} exact />
                    <Route path={ERoutesPublic.SignUpSuccess} component={SignUpSuccessPage} exact />
                    <Route path={ERoutesPublic.ServiceDocuments} component={ServiceDocumentsPage} exact />
                    {/* <Route path={ERoutesCommon.Components} component={ComponentsPage} exact /> */}
                    <Route path={`${ERoutesCommon.Root}*`} component={RootPublicPage} />
                  </Switch>
                </CenteredWithLogoLayout>
              </Switch>
            </>
          }
          privateRoutes={
            <>
              <MentorSignUpModal />
              <MentorInviteModal />
              <RoleCheckLayout
                signUpFinish={
                  <Switch>
                    <Route path={`${ERoutesCommon.Root}*`} component={SignUpFinishPage} exact />
                  </Switch>
                }
                banRoutes={
                  <Switch>
                    <Route path={`${ERoutesCommon.Root}*`} component={BanInfoPage} exact />
                  </Switch>
                }
                academyRoutes={
                  <Switch>
                    <Route path={ERoutesAcademy.InitAcademyInfo} component={InitAcademyInfoPage} exact />
                    <>
                      <Header />
                      <FranchiseInvitationAcceptSuccessModal />
                      <LayoutBasic id="basicLayout">
                        <AcademyStatusLayout
                          activeStatusRoutes={
                            <Switch>
                              <Route path={`${ERoutesCommon.Notifications}`} component={CommonNotificationsPage} />
                              <Route path={`${ERoutesMentor.Player}/:id`} component={MentorPlayerPage} />
                              <Route path={ERoutesAcademy.AcademyReferrals} component={AcademyReferralPage} exact />
                              <Route path={ERoutesAcademy.Statistic} component={AcademyStatisticPage} exact />
                              <Route path={ERoutesAcademy.AcademyEdit} component={AcademyEditPage} exact />
                              {/* <Route path={ERoutesAcademy.AcademyEditTests} component={AcademyEditTestsPage} exact />*/}
                              {/* <Route path={ERoutesAcademy.AcademyTestsList} component={TestsListPage} exact />*/}
                              <Route path={playersCompareRoute()} component={AcademyViewPage} exact />
                              <Route path={`${ERoutesAcademy.AcademyView}/:academyId`} component={AcademyViewPage} />
                              <Route path={`${ERoutesCommon.Root}*`} component={RootAcademyPage} />
                            </Switch>
                          }
                          notActiveStatusRoutes={
                            <Switch>
                              <Route path={ERoutesAcademy.AcademyReferrals} component={AcademyReferralPage} exact />
                              <Route path={ERoutesAcademy.AcademyEdit} component={AcademyEditPage} exact />
                              {/* <Route path={ERoutesAcademy.AcademyEditTests} component={AcademyEditTestsPage} exact />*/}
                              {/* <Route path={ERoutesAcademy.AcademyTestsList} component={TestsListPage} exact />*/}
                              <Route path={`${ERoutesAcademy.AcademyView}/:academyId`} component={AcademyModerationCard} exact />
                              <Route path={`${ERoutesCommon.Root}*`} component={RootAcademyNotActiveStatusPage} />
                            </Switch>
                          }
                        />
                      </LayoutBasic>
                    </>
                  </Switch>
                }
                mentorRoutes={
                  <>
                    <Header />
                    <LayoutBasic id="basicLayout">
                      <ValidateSubscriptionPaymentLayout>
                        <Switch>
                          <Route path={ERoutesPublic.MentorInvite} render={() => <MentorInvitePage isAuth />} exact />
                          <Route path={`${ERoutesCommon.Notifications}`} component={CommonNotificationsPage} />
                          <Route path={`${ERoutesMentor.Player}/:id`} component={MentorPlayerPage} />
                          <Route path={ERoutesMentor.Players} component={MentorPlayersPage} />
                          <Route path={ERoutesMentor.RequestList} component={MentorRequestPage} exact />
                          <Route path={ERoutesMentor.UsersEdit} component={MentorEditPage} exact />
                          <Route path={playersCompareRoute()} component={MentorPlayersComparePage} exact />
                          <Route
                            path={`${ERoutesMentor.PaymentAndSubscription}/:tab?`}
                            component={PaymentAndSubscriptionPage}
                            exact
                          />
                          <Route path={ERoutesCommon.Components} component={ComponentsPage} exact />
                          <Route path={`${ERoutesCommon.Root}*`} component={RootMentorPage} />
                        </Switch>
                      </ValidateSubscriptionPaymentLayout>
                    </LayoutBasic>
                  </>
                }
              />
            </>
          }
        />
      </Switch>
      <ShareModal />
      <HelpButton />
    </>
  );
};

export const App = communicationAuth.injector(AppComponent);
