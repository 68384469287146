import React, { useMemo } from 'react';
import { Typography } from 'antd';
import { ReactComponent as AB } from 'app/assets/icons/flags/ab.svg';
import { ReactComponent as AD } from 'app/assets/icons/flags/ad.svg';
import { ReactComponent as AE } from 'app/assets/icons/flags/ae.svg';
import { ReactComponent as AF } from 'app/assets/icons/flags/af.svg';
import { ReactComponent as AG } from 'app/assets/icons/flags/ag.svg';
import { ReactComponent as AI } from 'app/assets/icons/flags/ai.svg';
import { ReactComponent as AL } from 'app/assets/icons/flags/al.svg';
import { ReactComponent as AM } from 'app/assets/icons/flags/am.svg';
import { ReactComponent as AN } from 'app/assets/icons/flags/an.svg';
import { ReactComponent as AO } from 'app/assets/icons/flags/ao.svg';
import { ReactComponent as AQ } from 'app/assets/icons/flags/aq.svg';
import { ReactComponent as AR } from 'app/assets/icons/flags/ar.svg';
import { ReactComponent as AS } from 'app/assets/icons/flags/as.svg';
import { ReactComponent as AT } from 'app/assets/icons/flags/at.svg';
import { ReactComponent as AU } from 'app/assets/icons/flags/au.svg';
import { ReactComponent as AW } from 'app/assets/icons/flags/aw.svg';
import { ReactComponent as AX } from 'app/assets/icons/flags/ax.svg';
import { ReactComponent as AZ } from 'app/assets/icons/flags/az.svg';
import { ReactComponent as BA } from 'app/assets/icons/flags/ba.svg';
import { ReactComponent as BB } from 'app/assets/icons/flags/bb.svg';
import { ReactComponent as BD } from 'app/assets/icons/flags/bd.svg';
import { ReactComponent as BE } from 'app/assets/icons/flags/be.svg';
import { ReactComponent as BF } from 'app/assets/icons/flags/bf.svg';
import { ReactComponent as BG } from 'app/assets/icons/flags/bg.svg';
import { ReactComponent as BH } from 'app/assets/icons/flags/bh.svg';
import { ReactComponent as BI } from 'app/assets/icons/flags/bi.svg';
import { ReactComponent as BJ } from 'app/assets/icons/flags/bj.svg';
import { ReactComponent as BL } from 'app/assets/icons/flags/bl.svg';
import { ReactComponent as BM } from 'app/assets/icons/flags/bm.svg';
import { ReactComponent as BN } from 'app/assets/icons/flags/bn.svg';
import { ReactComponent as BO } from 'app/assets/icons/flags/bo.svg';
import { ReactComponent as BQ } from 'app/assets/icons/flags/bq.svg';
import { ReactComponent as BR } from 'app/assets/icons/flags/br.svg';
import { ReactComponent as BS } from 'app/assets/icons/flags/bs.svg';
import { ReactComponent as BT } from 'app/assets/icons/flags/bt.svg';
import { ReactComponent as BV } from 'app/assets/icons/flags/bv.svg';
import { ReactComponent as BW } from 'app/assets/icons/flags/bw.svg';
import { ReactComponent as BY } from 'app/assets/icons/flags/by.svg';
import { ReactComponent as BZ } from 'app/assets/icons/flags/bz.svg';
import { ReactComponent as CA } from 'app/assets/icons/flags/ca.svg';
import { ReactComponent as CC } from 'app/assets/icons/flags/cc.svg';
import { ReactComponent as CD } from 'app/assets/icons/flags/cd.svg';
import { ReactComponent as CF } from 'app/assets/icons/flags/cf.svg';
import { ReactComponent as CG } from 'app/assets/icons/flags/cg.svg';
import { ReactComponent as CH } from 'app/assets/icons/flags/ch.svg';
import { ReactComponent as CI } from 'app/assets/icons/flags/ci.svg';
import { ReactComponent as CK } from 'app/assets/icons/flags/ck.svg';
import { ReactComponent as CL } from 'app/assets/icons/flags/cl.svg';
import { ReactComponent as CM } from 'app/assets/icons/flags/cm.svg';
import { ReactComponent as CN } from 'app/assets/icons/flags/cn.svg';
import { ReactComponent as CO } from 'app/assets/icons/flags/co.svg';
import { ReactComponent as CR } from 'app/assets/icons/flags/cr.svg';
import { ReactComponent as CU } from 'app/assets/icons/flags/cu.svg';
import { ReactComponent as CV } from 'app/assets/icons/flags/cv.svg';
import { ReactComponent as CW } from 'app/assets/icons/flags/cw.svg';
import { ReactComponent as CX } from 'app/assets/icons/flags/cx.svg';
import { ReactComponent as CY } from 'app/assets/icons/flags/cy.svg';
import { ReactComponent as CZ } from 'app/assets/icons/flags/cz.svg';
import { ReactComponent as DE } from 'app/assets/icons/flags/de.svg';
import { ReactComponent as DJ } from 'app/assets/icons/flags/dj.svg';
import { ReactComponent as DK } from 'app/assets/icons/flags/dk.svg';
import { ReactComponent as DM } from 'app/assets/icons/flags/dm.svg';
import { ReactComponent as DO } from 'app/assets/icons/flags/do.svg';
import { ReactComponent as DZ } from 'app/assets/icons/flags/dz.svg';
import { ReactComponent as EC } from 'app/assets/icons/flags/ec.svg';
import { ReactComponent as EE } from 'app/assets/icons/flags/ee.svg';
import { ReactComponent as EG } from 'app/assets/icons/flags/eg.svg';
import { ReactComponent as EH } from 'app/assets/icons/flags/eh.svg';
import { ReactComponent as ER } from 'app/assets/icons/flags/er.svg';
import { ReactComponent as ES } from 'app/assets/icons/flags/es.svg';
import { ReactComponent as ET } from 'app/assets/icons/flags/et.svg';
import { ReactComponent as EU } from 'app/assets/icons/flags/eu.svg';
import { ReactComponent as FI } from 'app/assets/icons/flags/fi.svg';
import { ReactComponent as FJ } from 'app/assets/icons/flags/fj.svg';
import { ReactComponent as FK } from 'app/assets/icons/flags/fk.svg';
import { ReactComponent as FM } from 'app/assets/icons/flags/fm.svg';
import { ReactComponent as FO } from 'app/assets/icons/flags/fo.svg';
import { ReactComponent as FR } from 'app/assets/icons/flags/fr.svg';
import { ReactComponent as GA } from 'app/assets/icons/flags/ga.svg';
import { ReactComponent as GB_ENG } from 'app/assets/icons/flags/gb-eng.svg';
import { ReactComponent as GB_NIR } from 'app/assets/icons/flags/gb-nir.svg';
import { ReactComponent as GB_SCT } from 'app/assets/icons/flags/gb-sct.svg';
import { ReactComponent as GB_WLS } from 'app/assets/icons/flags/gb-wls.svg';
import { ReactComponent as GB } from 'app/assets/icons/flags/gb.svg';
import { ReactComponent as GD } from 'app/assets/icons/flags/gd.svg';
import { ReactComponent as GE } from 'app/assets/icons/flags/ge.svg';
import { ReactComponent as GF } from 'app/assets/icons/flags/gf.svg';
import { ReactComponent as GG } from 'app/assets/icons/flags/gg.svg';
import { ReactComponent as GH } from 'app/assets/icons/flags/gh.svg';
import { ReactComponent as GI } from 'app/assets/icons/flags/gi.svg';
import { ReactComponent as GL } from 'app/assets/icons/flags/gl.svg';
import { ReactComponent as GM } from 'app/assets/icons/flags/gm.svg';
import { ReactComponent as GN } from 'app/assets/icons/flags/gn.svg';
import { ReactComponent as GP } from 'app/assets/icons/flags/gp.svg';
import { ReactComponent as GQ } from 'app/assets/icons/flags/gq.svg';
import { ReactComponent as GR } from 'app/assets/icons/flags/gr.svg';
import { ReactComponent as GS } from 'app/assets/icons/flags/gs.svg';
import { ReactComponent as GT } from 'app/assets/icons/flags/gt.svg';
import { ReactComponent as GU } from 'app/assets/icons/flags/gu.svg';
import { ReactComponent as GW } from 'app/assets/icons/flags/gw.svg';
import { ReactComponent as GY } from 'app/assets/icons/flags/gy.svg';
import { ReactComponent as HK } from 'app/assets/icons/flags/hk.svg';
import { ReactComponent as HM } from 'app/assets/icons/flags/hm.svg';
import { ReactComponent as HN } from 'app/assets/icons/flags/hn.svg';
import { ReactComponent as HR } from 'app/assets/icons/flags/hr.svg';
import { ReactComponent as HT } from 'app/assets/icons/flags/ht.svg';
import { ReactComponent as HU } from 'app/assets/icons/flags/hu.svg';
import { ReactComponent as ID } from 'app/assets/icons/flags/id.svg';
import { ReactComponent as IE } from 'app/assets/icons/flags/ie.svg';
import { ReactComponent as IL } from 'app/assets/icons/flags/il.svg';
import { ReactComponent as IM } from 'app/assets/icons/flags/im.svg';
import { ReactComponent as IN } from 'app/assets/icons/flags/in.svg';
import { ReactComponent as IO } from 'app/assets/icons/flags/io.svg';
import { ReactComponent as IQ } from 'app/assets/icons/flags/iq.svg';
import { ReactComponent as IR } from 'app/assets/icons/flags/ir.svg';
import { ReactComponent as IS } from 'app/assets/icons/flags/is.svg';
import { ReactComponent as IT } from 'app/assets/icons/flags/it.svg';
import { ReactComponent as JE } from 'app/assets/icons/flags/je.svg';
import { ReactComponent as JM } from 'app/assets/icons/flags/jm.svg';
import { ReactComponent as JO } from 'app/assets/icons/flags/jo.svg';
import { ReactComponent as JP } from 'app/assets/icons/flags/jp.svg';
import { ReactComponent as KE } from 'app/assets/icons/flags/ke.svg';
import { ReactComponent as KG } from 'app/assets/icons/flags/kg.svg';
import { ReactComponent as KH } from 'app/assets/icons/flags/kh.svg';
import { ReactComponent as KI } from 'app/assets/icons/flags/ki.svg';
import { ReactComponent as KM } from 'app/assets/icons/flags/km.svg';
import { ReactComponent as KN } from 'app/assets/icons/flags/kn.svg';
import { ReactComponent as KP } from 'app/assets/icons/flags/kp.svg';
import { ReactComponent as KR } from 'app/assets/icons/flags/kr.svg';
import { ReactComponent as KW } from 'app/assets/icons/flags/kw.svg';
import { ReactComponent as KY } from 'app/assets/icons/flags/ky.svg';
import { ReactComponent as KZ } from 'app/assets/icons/flags/kz.svg';
import { ReactComponent as LA } from 'app/assets/icons/flags/la.svg';
import { ReactComponent as LB } from 'app/assets/icons/flags/lb.svg';
import { ReactComponent as LC } from 'app/assets/icons/flags/lc.svg';
import { ReactComponent as LI } from 'app/assets/icons/flags/li.svg';
import { ReactComponent as LK } from 'app/assets/icons/flags/lk.svg';
import { ReactComponent as LR } from 'app/assets/icons/flags/lr.svg';
import { ReactComponent as LS } from 'app/assets/icons/flags/ls.svg';
import { ReactComponent as LT } from 'app/assets/icons/flags/lt.svg';
import { ReactComponent as LU } from 'app/assets/icons/flags/lu.svg';
import { ReactComponent as LV } from 'app/assets/icons/flags/lv.svg';
import { ReactComponent as LY } from 'app/assets/icons/flags/ly.svg';
import { ReactComponent as MA } from 'app/assets/icons/flags/ma.svg';
import { ReactComponent as MC } from 'app/assets/icons/flags/mc.svg';
import { ReactComponent as MD } from 'app/assets/icons/flags/md.svg';
import { ReactComponent as ME } from 'app/assets/icons/flags/me.svg';
import { ReactComponent as MF } from 'app/assets/icons/flags/mf.svg';
import { ReactComponent as MG } from 'app/assets/icons/flags/mg.svg';
import { ReactComponent as MH } from 'app/assets/icons/flags/mh.svg';
import { ReactComponent as MK } from 'app/assets/icons/flags/mk.svg';
import { ReactComponent as ML } from 'app/assets/icons/flags/ml.svg';
import { ReactComponent as MM } from 'app/assets/icons/flags/mm.svg';
import { ReactComponent as MN } from 'app/assets/icons/flags/mn.svg';
import { ReactComponent as MO } from 'app/assets/icons/flags/mo.svg';
import { ReactComponent as MP } from 'app/assets/icons/flags/mp.svg';
import { ReactComponent as MQ } from 'app/assets/icons/flags/mq.svg';
import { ReactComponent as MR } from 'app/assets/icons/flags/mr.svg';
import { ReactComponent as MS } from 'app/assets/icons/flags/ms.svg';
import { ReactComponent as MT } from 'app/assets/icons/flags/mt.svg';
import { ReactComponent as MU } from 'app/assets/icons/flags/mu.svg';
import { ReactComponent as MV } from 'app/assets/icons/flags/mv.svg';
import { ReactComponent as MW } from 'app/assets/icons/flags/mw.svg';
import { ReactComponent as MX } from 'app/assets/icons/flags/mx.svg';
import { ReactComponent as MY } from 'app/assets/icons/flags/my.svg';
import { ReactComponent as MZ } from 'app/assets/icons/flags/mz.svg';
import { ReactComponent as NA } from 'app/assets/icons/flags/na.svg';
import { ReactComponent as NC } from 'app/assets/icons/flags/nc.svg';
import { ReactComponent as NE } from 'app/assets/icons/flags/ne.svg';
import { ReactComponent as NF } from 'app/assets/icons/flags/nf.svg';
import { ReactComponent as NG } from 'app/assets/icons/flags/ng.svg';
import { ReactComponent as NI } from 'app/assets/icons/flags/ni.svg';
import { ReactComponent as NL } from 'app/assets/icons/flags/nl.svg';
import { ReactComponent as NO } from 'app/assets/icons/flags/no.svg';
import { ReactComponent as NP } from 'app/assets/icons/flags/np.svg';
import { ReactComponent as NR } from 'app/assets/icons/flags/nr.svg';
import { ReactComponent as NU } from 'app/assets/icons/flags/nu.svg';
import { ReactComponent as NZ } from 'app/assets/icons/flags/nz.svg';
import { ReactComponent as OM } from 'app/assets/icons/flags/om.svg';
import { ReactComponent as PA } from 'app/assets/icons/flags/pa.svg';
import { ReactComponent as PE } from 'app/assets/icons/flags/pe.svg';
import { ReactComponent as PF } from 'app/assets/icons/flags/pf.svg';
import { ReactComponent as PG } from 'app/assets/icons/flags/pg.svg';
import { ReactComponent as PH } from 'app/assets/icons/flags/ph.svg';
import { ReactComponent as PK } from 'app/assets/icons/flags/pk.svg';
import { ReactComponent as PL } from 'app/assets/icons/flags/pl.svg';
import { ReactComponent as PM } from 'app/assets/icons/flags/pm.svg';
import { ReactComponent as PN } from 'app/assets/icons/flags/pn.svg';
import { ReactComponent as PR } from 'app/assets/icons/flags/pr.svg';
import { ReactComponent as PS } from 'app/assets/icons/flags/ps.svg';
import { ReactComponent as PT } from 'app/assets/icons/flags/pt.svg';
import { ReactComponent as PW } from 'app/assets/icons/flags/pw.svg';
import { ReactComponent as PY } from 'app/assets/icons/flags/py.svg';
import { ReactComponent as QA } from 'app/assets/icons/flags/qa.svg';
import { ReactComponent as RE } from 'app/assets/icons/flags/re.svg';
import { ReactComponent as RO } from 'app/assets/icons/flags/ro.svg';
import { ReactComponent as RS } from 'app/assets/icons/flags/rs.svg';
import { ReactComponent as RU } from 'app/assets/icons/flags/ru.svg';
import { ReactComponent as RW } from 'app/assets/icons/flags/rw.svg';
import { ReactComponent as SA } from 'app/assets/icons/flags/sa.svg';
import { ReactComponent as SB } from 'app/assets/icons/flags/sb.svg';
import { ReactComponent as SC } from 'app/assets/icons/flags/sc.svg';
import { ReactComponent as SD } from 'app/assets/icons/flags/sd.svg';
import { ReactComponent as SE } from 'app/assets/icons/flags/se.svg';
import { ReactComponent as SG } from 'app/assets/icons/flags/sg.svg';
import { ReactComponent as SH } from 'app/assets/icons/flags/sh.svg';
import { ReactComponent as SI } from 'app/assets/icons/flags/si.svg';
import { ReactComponent as SJ } from 'app/assets/icons/flags/sj.svg';
import { ReactComponent as SK } from 'app/assets/icons/flags/sk.svg';
import { ReactComponent as SL } from 'app/assets/icons/flags/sl.svg';
import { ReactComponent as SM } from 'app/assets/icons/flags/sm.svg';
import { ReactComponent as SN } from 'app/assets/icons/flags/sn.svg';
import { ReactComponent as SO } from 'app/assets/icons/flags/so.svg';
import { ReactComponent as SR } from 'app/assets/icons/flags/sr.svg';
import { ReactComponent as SS } from 'app/assets/icons/flags/ss.svg';
import { ReactComponent as ST } from 'app/assets/icons/flags/st.svg';
import { ReactComponent as SV } from 'app/assets/icons/flags/sv.svg';
import { ReactComponent as SX } from 'app/assets/icons/flags/sx.svg';
import { ReactComponent as SY } from 'app/assets/icons/flags/sy.svg';
import { ReactComponent as SZ } from 'app/assets/icons/flags/sz.svg';
import { ReactComponent as TC } from 'app/assets/icons/flags/tc.svg';
import { ReactComponent as TD } from 'app/assets/icons/flags/td.svg';
import { ReactComponent as TF } from 'app/assets/icons/flags/tf.svg';
import { ReactComponent as TG } from 'app/assets/icons/flags/tg.svg';
import { ReactComponent as TH } from 'app/assets/icons/flags/th.svg';
import { ReactComponent as TJ } from 'app/assets/icons/flags/tj.svg';
import { ReactComponent as TK } from 'app/assets/icons/flags/tk.svg';
import { ReactComponent as TL } from 'app/assets/icons/flags/tl.svg';
import { ReactComponent as TM } from 'app/assets/icons/flags/tm.svg';
import { ReactComponent as TN } from 'app/assets/icons/flags/tn.svg';
import { ReactComponent as TO } from 'app/assets/icons/flags/to.svg';
import { ReactComponent as TR } from 'app/assets/icons/flags/tr.svg';
import { ReactComponent as TT } from 'app/assets/icons/flags/tt.svg';
import { ReactComponent as TV } from 'app/assets/icons/flags/tv.svg';
import { ReactComponent as TW } from 'app/assets/icons/flags/tw.svg';
import { ReactComponent as TZ } from 'app/assets/icons/flags/tz.svg';
import { ReactComponent as UA } from 'app/assets/icons/flags/ua.svg';
import { ReactComponent as UG } from 'app/assets/icons/flags/ug.svg';
import { ReactComponent as UM } from 'app/assets/icons/flags/um.svg';
import { ReactComponent as US } from 'app/assets/icons/flags/us.svg';
import { ReactComponent as UY } from 'app/assets/icons/flags/uy.svg';
import { ReactComponent as UZ } from 'app/assets/icons/flags/uz.svg';
import { ReactComponent as VA } from 'app/assets/icons/flags/va.svg';
import { ReactComponent as VC } from 'app/assets/icons/flags/vc.svg';
import { ReactComponent as VE } from 'app/assets/icons/flags/ve.svg';
import { ReactComponent as VG } from 'app/assets/icons/flags/vg.svg';
import { ReactComponent as VI } from 'app/assets/icons/flags/vi.svg';
import { ReactComponent as VN } from 'app/assets/icons/flags/vn.svg';
import { ReactComponent as VU } from 'app/assets/icons/flags/vu.svg';
import { ReactComponent as WF } from 'app/assets/icons/flags/wf.svg';
import { ReactComponent as XK } from 'app/assets/icons/flags/xk.svg';
import { ReactComponent as WS } from 'app/assets/icons/flags/ws.svg';
import { ReactComponent as YE } from 'app/assets/icons/flags/ye.svg';
import { ReactComponent as YT } from 'app/assets/icons/flags/yt.svg';
import { ReactComponent as ZA } from 'app/assets/icons/flags/za.svg';
import { ReactComponent as ZM } from 'app/assets/icons/flags/zm.svg';
import { ReactComponent as ZW } from 'app/assets/icons/flags/zw.svg';

/* eslint-disable no-shadow */
export enum ECountryCode {
  ABH = 'AB',
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AN = 'AN',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  EU = 'EU',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB_ENG = 'GB_ENG',
  GB_NIR = 'GB_NIR',
  GB_SCT = 'GB_SCT',
  GB_WLS = 'GB_WLS',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  XK = 'XK',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW'
}
/* eslint-enable */

export const FLAGS = {
  [ECountryCode.ABH]: AB,
  [ECountryCode.AD]: AD,
  [ECountryCode.AE]: AE,
  [ECountryCode.AF]: AF,
  [ECountryCode.AG]: AG,
  [ECountryCode.AI]: AI,
  [ECountryCode.AL]: AL,
  [ECountryCode.AM]: AM,
  [ECountryCode.AN]: AN,
  [ECountryCode.AO]: AO,
  [ECountryCode.AQ]: AQ,
  [ECountryCode.AR]: AR,
  [ECountryCode.AS]: AS,
  [ECountryCode.AT]: AT,
  [ECountryCode.AU]: AU,
  [ECountryCode.AW]: AW,
  [ECountryCode.AX]: AX,
  [ECountryCode.AZ]: AZ,
  [ECountryCode.BA]: BA,
  [ECountryCode.BB]: BB,
  [ECountryCode.BD]: BD,
  [ECountryCode.BE]: BE,
  [ECountryCode.BF]: BF,
  [ECountryCode.BG]: BG,
  [ECountryCode.BH]: BH,
  [ECountryCode.BI]: BI,
  [ECountryCode.BJ]: BJ,
  [ECountryCode.BL]: BL,
  [ECountryCode.BM]: BM,
  [ECountryCode.BN]: BN,
  [ECountryCode.BO]: BO,
  [ECountryCode.BQ]: BQ,
  [ECountryCode.BR]: BR,
  [ECountryCode.BS]: BS,
  [ECountryCode.BT]: BT,
  [ECountryCode.BV]: BV,
  [ECountryCode.BW]: BW,
  [ECountryCode.BY]: BY,
  [ECountryCode.BZ]: BZ,
  [ECountryCode.CA]: CA,
  [ECountryCode.CC]: CC,
  [ECountryCode.CD]: CD,
  [ECountryCode.CF]: CF,
  [ECountryCode.CG]: CG,
  [ECountryCode.CH]: CH,
  [ECountryCode.CI]: CI,
  [ECountryCode.CK]: CK,
  [ECountryCode.CL]: CL,
  [ECountryCode.CM]: CM,
  [ECountryCode.CN]: CN,
  [ECountryCode.CO]: CO,
  [ECountryCode.CR]: CR,
  [ECountryCode.CU]: CU,
  [ECountryCode.CV]: CV,
  [ECountryCode.CW]: CW,
  [ECountryCode.CX]: CX,
  [ECountryCode.CY]: CY,
  [ECountryCode.CZ]: CZ,
  [ECountryCode.DE]: DE,
  [ECountryCode.DJ]: DJ,
  [ECountryCode.DK]: DK,
  [ECountryCode.DM]: DM,
  [ECountryCode.DO]: DO,
  [ECountryCode.DZ]: DZ,
  [ECountryCode.EC]: EC,
  [ECountryCode.EE]: EE,
  [ECountryCode.EG]: EG,
  [ECountryCode.EH]: EH,
  [ECountryCode.ER]: ER,
  [ECountryCode.ES]: ES,
  [ECountryCode.ET]: ET,
  [ECountryCode.EU]: EU,
  [ECountryCode.FI]: FI,
  [ECountryCode.FJ]: FJ,
  [ECountryCode.FK]: FK,
  [ECountryCode.FM]: FM,
  [ECountryCode.FO]: FO,
  [ECountryCode.FR]: FR,
  [ECountryCode.GA]: GA,
  [ECountryCode.GB_ENG]: GB_ENG,
  [ECountryCode.GB_NIR]: GB_NIR,
  [ECountryCode.GB_SCT]: GB_SCT,
  [ECountryCode.GB_WLS]: GB_WLS,
  [ECountryCode.GB]: GB,
  [ECountryCode.GD]: GD,
  [ECountryCode.GE]: GE,
  [ECountryCode.GF]: GF,
  [ECountryCode.GG]: GG,
  [ECountryCode.GH]: GH,
  [ECountryCode.GI]: GI,
  [ECountryCode.GL]: GL,
  [ECountryCode.GM]: GM,
  [ECountryCode.GN]: GN,
  [ECountryCode.GP]: GP,
  [ECountryCode.GQ]: GQ,
  [ECountryCode.GR]: GR,
  [ECountryCode.GS]: GS,
  [ECountryCode.GT]: GT,
  [ECountryCode.GU]: GU,
  [ECountryCode.GW]: GW,
  [ECountryCode.GY]: GY,
  [ECountryCode.HK]: HK,
  [ECountryCode.HM]: HM,
  [ECountryCode.HN]: HN,
  [ECountryCode.HR]: HR,
  [ECountryCode.HT]: HT,
  [ECountryCode.HU]: HU,
  [ECountryCode.ID]: ID,
  [ECountryCode.IE]: IE,
  [ECountryCode.IL]: IL,
  [ECountryCode.IM]: IM,
  [ECountryCode.IN]: IN,
  [ECountryCode.IO]: IO,
  [ECountryCode.IQ]: IQ,
  [ECountryCode.IR]: IR,
  [ECountryCode.IS]: IS,
  [ECountryCode.IT]: IT,
  [ECountryCode.JE]: JE,
  [ECountryCode.JM]: JM,
  [ECountryCode.JO]: JO,
  [ECountryCode.JP]: JP,
  [ECountryCode.KE]: KE,
  [ECountryCode.KG]: KG,
  [ECountryCode.KH]: KH,
  [ECountryCode.KI]: KI,
  [ECountryCode.KM]: KM,
  [ECountryCode.KN]: KN,
  [ECountryCode.KP]: KP,
  [ECountryCode.KR]: KR,
  [ECountryCode.KW]: KW,
  [ECountryCode.KY]: KY,
  [ECountryCode.KZ]: KZ,
  [ECountryCode.LA]: LA,
  [ECountryCode.LB]: LB,
  [ECountryCode.LC]: LC,
  [ECountryCode.LI]: LI,
  [ECountryCode.LK]: LK,
  [ECountryCode.LR]: LR,
  [ECountryCode.LS]: LS,
  [ECountryCode.LT]: LT,
  [ECountryCode.LU]: LU,
  [ECountryCode.LV]: LV,
  [ECountryCode.LY]: LY,
  [ECountryCode.MA]: MA,
  [ECountryCode.MC]: MC,
  [ECountryCode.MD]: MD,
  [ECountryCode.ME]: ME,
  [ECountryCode.MF]: MF,
  [ECountryCode.MG]: MG,
  [ECountryCode.MH]: MH,
  [ECountryCode.MK]: MK,
  [ECountryCode.ML]: ML,
  [ECountryCode.MM]: MM,
  [ECountryCode.MN]: MN,
  [ECountryCode.MO]: MO,
  [ECountryCode.MP]: MP,
  [ECountryCode.MQ]: MQ,
  [ECountryCode.MR]: MR,
  [ECountryCode.MS]: MS,
  [ECountryCode.MT]: MT,
  [ECountryCode.MU]: MU,
  [ECountryCode.MV]: MV,
  [ECountryCode.MW]: MW,
  [ECountryCode.MX]: MX,
  [ECountryCode.MY]: MY,
  [ECountryCode.MZ]: MZ,
  [ECountryCode.NA]: NA,
  [ECountryCode.NC]: NC,
  [ECountryCode.NE]: NE,
  [ECountryCode.NF]: NF,
  [ECountryCode.NG]: NG,
  [ECountryCode.NI]: NI,
  [ECountryCode.NL]: NL,
  [ECountryCode.NO]: NO,
  [ECountryCode.NP]: NP,
  [ECountryCode.NR]: NR,
  [ECountryCode.NU]: NU,
  [ECountryCode.NZ]: NZ,
  [ECountryCode.OM]: OM,
  [ECountryCode.PA]: PA,
  [ECountryCode.PE]: PE,
  [ECountryCode.PF]: PF,
  [ECountryCode.PG]: PG,
  [ECountryCode.PH]: PH,
  [ECountryCode.PK]: PK,
  [ECountryCode.PL]: PL,
  [ECountryCode.PM]: PM,
  [ECountryCode.PN]: PN,
  [ECountryCode.PR]: PR,
  [ECountryCode.PS]: PS,
  [ECountryCode.PT]: PT,
  [ECountryCode.PW]: PW,
  [ECountryCode.PY]: PY,
  [ECountryCode.QA]: QA,
  [ECountryCode.RE]: RE,
  [ECountryCode.RO]: RO,
  [ECountryCode.RS]: RS,
  [ECountryCode.RU]: RU,
  [ECountryCode.RW]: RW,
  [ECountryCode.SA]: SA,
  [ECountryCode.SB]: SB,
  [ECountryCode.SC]: SC,
  [ECountryCode.SD]: SD,
  [ECountryCode.SE]: SE,
  [ECountryCode.SG]: SG,
  [ECountryCode.SH]: SH,
  [ECountryCode.SI]: SI,
  [ECountryCode.SJ]: SJ,
  [ECountryCode.SK]: SK,
  [ECountryCode.SL]: SL,
  [ECountryCode.SM]: SM,
  [ECountryCode.SN]: SN,
  [ECountryCode.SO]: SO,
  [ECountryCode.SR]: SR,
  [ECountryCode.SS]: SS,
  [ECountryCode.ST]: ST,
  [ECountryCode.SV]: SV,
  [ECountryCode.SX]: SX,
  [ECountryCode.SY]: SY,
  [ECountryCode.SZ]: SZ,
  [ECountryCode.TC]: TC,
  [ECountryCode.TD]: TD,
  [ECountryCode.TF]: TF,
  [ECountryCode.TG]: TG,
  [ECountryCode.TH]: TH,
  [ECountryCode.TJ]: TJ,
  [ECountryCode.TK]: TK,
  [ECountryCode.TL]: TL,
  [ECountryCode.TM]: TM,
  [ECountryCode.TN]: TN,
  [ECountryCode.TO]: TO,
  [ECountryCode.TR]: TR,
  [ECountryCode.TT]: TT,
  [ECountryCode.TV]: TV,
  [ECountryCode.TW]: TW,
  [ECountryCode.TZ]: TZ,
  [ECountryCode.UA]: UA,
  [ECountryCode.UG]: UG,
  [ECountryCode.UM]: UM,
  [ECountryCode.US]: US,
  [ECountryCode.UY]: UY,
  [ECountryCode.UZ]: UZ,
  [ECountryCode.VA]: VA,
  [ECountryCode.VC]: VC,
  [ECountryCode.VE]: VE,
  [ECountryCode.VG]: VG,
  [ECountryCode.VI]: VI,
  [ECountryCode.VN]: VN,
  [ECountryCode.VU]: VU,
  [ECountryCode.WF]: WF,
  [ECountryCode.XK]: XK,
  [ECountryCode.WS]: WS,
  [ECountryCode.YE]: YE,
  [ECountryCode.YT]: YT,
  [ECountryCode.ZA]: ZA,
  [ECountryCode.ZM]: ZM,
  [ECountryCode.ZW]: ZW
};

interface IFlagProps {
  code: ECountryCode;
  width?: number;
  height?: number;
}

export const Flag: React.FC<IFlagProps> = ({ code, ...props }: IFlagProps) => {
  const FlagComponent = useMemo(() => FLAGS[code], [code]);
  if (FlagComponent) {
    return <FlagComponent className="flag-component" {...props} />;
  } else {
    return <Typography.Text strong>{code}</Typography.Text>;
  }
};
