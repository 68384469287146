import { options } from 'options';
import React from 'react';
import Button from 'antd/es/button';
import { useTranslation } from 'react-i18next';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { PublicPlayerBaseModal } from 'entities/PublicPlayer/components/Modals/PublicPlayerBaseModal';

interface IProps {
  visible: boolean;
  onCancel: () => void;
}

export const PublicPlayerPlayerModal: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const { visible, onCancel } = props;
  const searchRaw: { [index: string]: any } = queryToObject<{ [index: string]: any }>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ageGroups, orderField, orderDirection, ...filteredSearch } = searchRaw;
  const regPath = `${options.registerMentorPath}&${objectToQuery(filteredSearch).replace('?', '')}`;

  return (
    <PublicPlayerBaseModal visible={visible} onCancel={onCancel}>
      <span>{t('player-modal-text')}</span>
      <a href={regPath} target="_blank" rel="noopener noreferrer">
        <Button type="primary" size="large" block className="mt-13">
          {t('Became mentor')}
        </Button>
      </a>
    </PublicPlayerBaseModal>
  );
};
