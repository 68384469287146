import React, { useEffect } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { LayoutContent } from 'common/components/Layouts/ContentLayout';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';
import { communicationSubscription, ISubscriptionConnectedProps } from 'entities/Subscription/Subscription.communication';
import { PlayerTabs } from 'entities/Player/components/PlayerTabs';
import { PlayerProvider } from 'entities/Player/components/PlayerProvider';

type AllProps = IMentorRequestConnectedProps & ISubscriptionConnectedProps;

const MentorPlayerPageComponent: React.FC<AllProps> = props => {
  // todo create isDesktop context for app
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const { clearMentorRequestModel, clearSubscriptionModel, clearSubscriptionActivityModel } = props;

  useEffect(() => {
    return () => {
      clearMentorRequestModel();
      clearSubscriptionModel();
      clearSubscriptionActivityModel();
    };
  }, []);

  return (
    <LayoutContent>
      <PlayerProvider>
        <PlayerTabs isDesktop={isDesktop} />
      </PlayerProvider>
    </LayoutContent>
  );
};

export const MentorPlayerPage = communicationSubscription.injector(
  communicationMentorRequest.injector(MentorPlayerPageComponent)
);
