import React, { useState, useEffect, ReactElement, useCallback, useContext } from 'react';
import { queryToObject } from 'common/helpers/filters.helper';
import { StripeContext } from 'common/components/Stripe/StripeProvider';
import { communicationSubscription, ISubscriptionConnectedProps } from 'entities/Subscription/Subscription.communication';
import { EProcessings } from 'entities/Subscription/Subscription.models';
import { PaymentCheckModal } from 'entities/Subscription/components/PaymentCheckModal';
import { PaymentRequirementsModal } from 'entities/Subscription/components/PaymentRequirements/PaymentRequirementsModal';
import { ChangeTariffModal } from 'entities/Subscription/components/ChangeTariff/ChangeTariffModal';
import { StripePaymentModal } from 'entities/Subscription/components/StripePaymentModal/StripePaymentModal';
import { StripeCancelSubscriptionModal } from 'entities/Subscription/components/StripeCancelSubscriptionModal';
import { IUIConnectedProps, communicationUI } from 'entities/UI/UI.communication';

interface IProps {
  children: ReactElement;
}

interface IQueryState {
  subscriptionId?: string;
}

type AllProps = ISubscriptionConnectedProps & IProps & IUIConnectedProps;

const ValidateSubscriptionPaymentLayoutComponent: React.FC<AllProps> = props => {
  const { clearSavedPaymentMethod } = useContext(StripeContext);
  const [isCheckModalVisible, setCheckModalVisible] = useState<boolean>(false);
  const [checkingSubscription, setCheckingSubscription] = useState<boolean>(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false);
  const {
    children,
    getSubscriptionModel,
    subscriptionModel,
    openUIPaymentRequirementsModal,
    addSubscriptionActivityModel
  } = props;
  const { data, loading } = subscriptionModel;

  useEffect(() => {
    return () => {
      clearSavedPaymentMethod?.();
    };
  }, []);

  useEffect(() => {
    const { subscriptionId } = queryToObject<IQueryState>({ subscriptionId: '' }) as IQueryState;
    if (subscriptionId) {
      getSubscriptionModel(subscriptionId);
      setCheckingSubscription(true);
    }
  }, []);

  useEffect(() => {
    if (checkingSubscription && data) {
      if (data?.lastActivity?.isApplied !== undefined) {
        setIsPaymentSuccess(data?.lastActivity?.isApplied);
        openCheckModal();
      }
      setCheckingSubscription(false);
    }
  }, [loading]);

  const openCheckModal = useCallback(() => {
    setCheckModalVisible(true);
  }, []);

  const closeCheckModal = useCallback(() => {
    setCheckModalVisible(false);
  }, [data]);

  const onTryAgain = useCallback(() => {
    const requestData = data?.lastActivity?.req;
    if (data?.id) {
      if (requestData && 'isTest' in requestData && requestData.isTest && !requestData?.giftcard) {
        addSubscriptionActivityModel({
          isTest: true,
          subscriptionId: data.id,
          processing: EProcessings.Yookassa
        });
      } else {
        openUIPaymentRequirementsModal({ subscriptionId: data.id });
      }
    }
    closeCheckModal();
  }, [data]);

  return (
    <>
      {children}
      <PaymentCheckModal
        onTryAgain={onTryAgain}
        visible={isCheckModalVisible}
        subscription={data}
        isSuccess={isPaymentSuccess}
        onCancel={closeCheckModal}
      />
      <PaymentRequirementsModal />
      <ChangeTariffModal />
      <StripePaymentModal />
      <StripeCancelSubscriptionModal />
    </>
  );
};

export const ValidateSubscriptionPaymentLayout = communicationUI.injector(
  communicationSubscription.injector(ValidateSubscriptionPaymentLayoutComponent)
);
