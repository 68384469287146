import React from 'react';
import { Link } from 'react-router-dom';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import { ERoutesMentor } from 'common/models/routesModel';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { communicationAcademy, IAcademyConnectedProps } from 'entities/Academy/Academy.communication';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import { EAcademyTabKeys } from 'entities/Academy/Academy.models';
import { IPlayerModel, IPlayerCollectionFilter, IPlayerCollection } from 'entities/Player/Player.models';
import { PlayersListItem } from 'entities/Player/components/List/PlayersListItem';
import { PlayersListNoData } from 'entities/Player/components/List/PlayersListNoData';
import { TPrivatePlayerActionTypes } from 'entities/PrivatePlayer/PrivatePlayer.models';
import { EPublicPlayerOrder } from 'entities/PublicPlayer/PublicPlayer.models';

interface IComponentProps {
  onItemClick?: (itemModel: IPlayerModel) => void;
  setCount?: (count?: number) => void;
  defaultAgeGroup?: string;
  clearFilter?: (tab: EAcademyTabKeys) => void;
  isForMentor?: boolean;
  actionType?: TPrivatePlayerActionTypes;
}

type AllProps = IComponentProps & IAcademyConnectedProps & IPlayerConnectedProps;

class PlayerListComponent extends InfiniteList<IPlayerCollection, IPlayerModel, AllProps, Partial<IPlayerCollectionFilter>> {
  loadCollection = async (params: IBaseFilterModel) => {
    const { filter, getPlayerCollection } = this.props;
    const { location, ageGroup, visibleOnly, name, year, orderField, ...clearedFilter } = filter || {};

    const filterParams: IPlayerCollectionFilter = {
      ...clearedFilter,
      ...params,
      ...location,
      visibleOnly: visibleOnly ?? false,
      orderField: 'rating'
    };

    if (orderField) {
      filterParams.orderField = orderField;
      filterParams.orderDirection = orderField === 'name' ? EPublicPlayerOrder.Asc : EPublicPlayerOrder.Desc;
    }

    if (year) {
      filterParams.year = year;
    }
    if (name) {
      filterParams.name = name;
    }

    if (ageGroup) {
      filterParams.ageGroups = [ageGroup];
    }

    const clearedFilterParams = Object.keys(filterParams)?.reduce((acc, key) => {
      const value: any = (filterParams as any)[key];
      return value !== undefined ? { ...acc, [key]: value } : acc;
    }, {});

    return getPlayerCollection(clearedFilterParams);
  };

  renderListItem = (item: IPlayerModel) => {
    const { actionType } = this.props;

    return (
      <Link to={`${ERoutesMentor.Player}/${item.id}/profile`} target="_blank" replace>
        <PlayersListItem item={item} actionType={actionType} />
      </Link>
    );
  };

  renderNoData = () => {
    const { filter, defaultAgeGroup, clearFilter, playerCollection } = this.props;
    const { loading: playerLoading, data: playerData } = playerCollection;
    const isAgeGroupFilter = filter?.ageGroup && filter?.ageGroup !== defaultAgeGroup;

    const isEmpty = !playerLoading && playerData?.data.length === 0;

    const showClearFilterButton = !!(
      filter?.name ||
      filter?.visibleOnly ||
      filter?.location ||
      // (filter as IAcademyPlayerCollectionFilter)?.resultRangesValue ||
      isAgeGroupFilter ||
      filter?.skills?.length
    );

    return isEmpty ? (
      <PlayersListNoData showClearFilterButton={showClearFilterButton} clearFilter={clearFilter} />
    ) : (
      <LoadingSpin />
    );
  };

  getCollection = () => {
    const { setCount, playerCollection, isForMentor } = this.props;

    if (isForMentor) {
      return playerCollection;
    }

    setCount && setTimeout(() => setCount(playerCollection?.data?.meta?.unseenRequestCount), 0);

    return playerCollection;
  };

  clearCollection = () => {
    const { clearPlayerCollection } = this.props;

    return clearPlayerCollection();
  };
}

export const PlayersList = communicationPlayer.injector(communicationAcademy.injector(PlayerListComponent));
