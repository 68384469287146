import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCollectionPreRequestDataMapper,
  buildCollectionResponseFormatter,
  buildCommunication,
  EActionsTypes,
  getStartType,
  getSuccessType,
  StoreBranch
} from '@axmit/redux-communications';
import { put, takeLatest } from 'redux-saga/effects';
import {
  EMentorRequestStatus,
  IMentorRequestCollection,
  IMentorRequestCollectionFilter,
  IMentorRequestCreateParams,
  IMentorRequestModel,
  IMentorRequestUpdateParams,
  PLAYER_NOT_EXIST_CODE,
  TMentorRequestParamsModel,
  IInviteMentorParams
} from 'entities/MentorRequest/MentorRequest.models';
import { mentorRequestTransport } from 'entities/MentorRequest/MentorRequest.transport';
import { updateMentorInviteModal, updateMentorRequestModal } from 'entities/UI/UI.communication';
import { EMentorInviteModalType, EMentorRequestModalType } from 'entities/UI/UI.models';
import {
  getPrivatePlayerModelStartType,
  updateMentorRequestStatusPlayerCollection
} from 'entities/PrivatePlayer/PrivatePlayer.communication';

export const namespace = 'mentorRequest';

export interface IMentorRequestConnectedProps {
  mentorRequestModel: StoreBranch<IMentorRequestModel, TMentorRequestParamsModel>;
  mentorRequestInviteModel: StoreBranch<IMentorRequestModel, IInviteMentorParams>;
  mentorRequestCollection: StoreBranch<IMentorRequestCollection, IMentorRequestCollectionFilter>;

  getMentorRequestModel(id: string): void;
  addMentorRequestModel(params: IMentorRequestCreateParams): void;
  updateMentorRequestModel(params: IMentorRequestUpdateParams): void;

  addMentorRequestInviteModel(params: IInviteMentorParams): void;

  getMentorRequestCollection(params: IMentorRequestCollectionFilter): Promise<void>;
  clearMentorRequestModel(): void;
  clearMentorRequestCollection(): void;
  clearMentorRequestInviteModel(): void;
}

const MentorRequestModelAPIProviders = [
  new APIProvider(
    EActionsTypes.get,
    mentorRequestTransport.get,
    {
      preRequestDataMapper: (response, payload, branchState) => {
        return branchState.data;
      }
    },
    takeLatest
  ),

  new APIProvider(EActionsTypes.update, mentorRequestTransport.update, {
    onSuccess: function*(response, originalParams, branchState, fullState: any) {
      const collectionData = fullState[namespace]?.collection?.data || {};
      const data = collectionData.data || [];
      const updatedData = data.map((el: IMentorRequestModel) => (el.id === response.id ? response : el));
      const filteredData = updatedData.filter((el: IMentorRequestModel) =>
        [EMentorRequestStatus.New, EMentorRequestStatus.Accepted].includes(el.status as EMentorRequestStatus)
      );
      yield put({
        type: getSuccessType(namespace, 'collection', EActionsTypes.get),
        payload: {
          ...collectionData,
          data: filteredData
        }
      });
    }
  }),

  new APIProvider(EActionsTypes.add, mentorRequestTransport.add, {
    onSuccess: function*(response, originalParams, branchState, fullState: any) {
      yield updateMentorRequestModal({ modalType: EMentorRequestModalType.AfterAddPlayer, isVisible: true });
      const collectionData = fullState[namespace]?.collection?.data || {};
      const data = collectionData.data || [];
      data.unshift(response);
      const filteredData = data.filter((el: IMentorRequestModel) =>
        [EMentorRequestStatus.New, EMentorRequestStatus.Accepted].includes(el.status as EMentorRequestStatus)
      );
      yield put({
        type: getSuccessType(namespace, 'collection', EActionsTypes.get),
        payload: {
          ...collectionData,
          data: filteredData
        }
      });
    },
    onFail: function*(response) {
      const errors = response.data.errors ?? {};
      const playerErrors = errors['.email'];
      if (playerErrors?.length === 1 && playerErrors[0]?.code === PLAYER_NOT_EXIST_CODE) {
        yield updateMentorRequestModal({ modalType: EMentorRequestModalType.InvitePlayer, isVisible: true });
      }
    }
  })
];
const MentorRequestCollectionAPIProviders = [
  new APIProvider(
    EActionsTypes.get,
    mentorRequestTransport.getCollection,
    {
      mapSuccess: buildCollectionResponseFormatter<any, any>(),
      preRequestDataMapper: buildCollectionPreRequestDataMapper<IMentorRequestCollection, IMentorRequestCollectionFilter>()
    },
    takeLatest
  )
];
const InviteMentorRequestModelAPIProviders = [
  new APIProvider(EActionsTypes.add, mentorRequestTransport.inviteMentor, {
    onSuccess: function*(response: IMentorRequestModel, originalParams) {
      const { player, inviteFromList } = originalParams || {};
      yield updateMentorInviteModal({
        modalType: EMentorInviteModalType.AfterAddMentor,
        isVisible: true
      });
      if (player) {
        yield inviteFromList ? updateMentorRequestStatusPlayerCollection(player) : getPrivatePlayerModelStartType(player);
      }
    }
  })
];

const branches = [
  new Branch('model', MentorRequestModelAPIProviders),
  new Branch('inviteModel', InviteMentorRequestModelAPIProviders),
  new Branch('collection', MentorRequestCollectionAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export function* getMentorRequestModel(id: string) {
  yield put({ type: getStartType(namespace, 'model', EActionsTypes.get), payload: id });
}

export const communicationMentorRequest = buildCommunication<IMentorRequestConnectedProps>(strategy);
