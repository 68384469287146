import React, { useMemo } from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import classNames from 'classnames';
import Spin from 'antd/es/spin';
import { PlayerSkillsBlockItem } from 'entities/PlayerTest/components/PlayerSkillsBlock/PlayerSkillsBlockItem';
import { IPlayerProfileSkillModel } from 'entities/Player/Player.models';

interface IProps {
  data?: IPlayerProfileSkillModel[];
  selected?: string;
  className?: string;
  onSkillSelectCallback?(skillId: string): void;
  loading?: boolean;
  activeWrap?: boolean;
  itemXs?: number;
  itemMd?: number;
}

const PlayerSkillsBlockComponent: React.FC<IProps> = props => {
  const {
    data,
    selected,
    className,
    onSkillSelectCallback = () => undefined,
    loading,
    activeWrap = false,
    itemXs = 23,
    itemMd
  } = props;
  const { md } = useBreakpoint();
  const itemMdSpan = useMemo(() => (selected ? 8 : 12), [selected]);

  return (
    <Spin spinning={loading} className="width-full" wrapperClassName="width-full">
      <Row
        className={classNames(`player-skills-block overflow-auto width-full`, {
          'pb-3': !md,
          'pb-5': md,
          [className as string]: className
        })}
        gutter={[8, 8]}
        wrap={md || activeWrap}
      >
        {data?.map(item => (
          <Col xs={itemXs} md={itemMd || itemMdSpan} key={item.skillId} className="pb-1">
            <PlayerSkillsBlockItem
              id={item.skillId}
              onSkillSelectCallback={onSkillSelectCallback}
              selected={selected}
              tag={item.tag}
              value={item.value}
              skills={item.microSkills}
            />
          </Col>
        ))}
      </Row>
    </Spin>
  );
};

export const PlayerSkillsBlock = PlayerSkillsBlockComponent;
