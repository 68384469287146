import CountryLanguage from 'country-language';
import { getCurrentLang } from 'common/helpers/i18n';
import { queryToObject } from 'common/helpers/filters.helper';

export const getLangFromSearchParams = () => {
  const { lang } = queryToObject<{ lang?: string }>({ lang: undefined }) as { lang?: string };
  return lang;
};

const DefaultCountry = 'us';

const DefaultLocaleCountries: { [key: string]: string } = {
  en: 'us',
  ru: 'ru'
};

export const useCountryCode = () => {
  const i18nLang = getCurrentLang();
  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;

  if (browserLocales?.[0] && CountryLanguage.countryCodeExists(browserLocales?.[0])) {
    return browserLocales?.[0];
  }

  const countryCodesByLocales = browserLocales.reduce(
    (acc: { langs: string[]; countries: string[] }, locale: string) => {
      const [localeCode, countryCode] = locale?.trim()?.split('-') || [];

      if (localeCode) {
        acc.langs.push(localeCode);
      }
      if (countryCode) {
        acc.countries.push(countryCode?.toLowerCase());
      }

      return acc;
    },
    { langs: [], countries: [] }
  );

  if (countryCodesByLocales?.countries?.length) {
    return countryCodesByLocales.countries[0];
  }

  const localeToDetect = countryCodesByLocales?.langs?.length ? countryCodesByLocales?.langs[0] : i18nLang;

  if (Object.keys(DefaultLocaleCountries).includes(localeToDetect)) {
    return DefaultLocaleCountries[localeToDetect];
  }

  if (CountryLanguage.countryCodeExists(localeToDetect)) {
    return localeToDetect;
  }

  const countriesByLanguage = CountryLanguage.getLanguageCountries(localeToDetect);

  if (countriesByLanguage?.length) {
    return countriesByLanguage[0]?.code_2?.toLowerCase();
  }

  return DefaultCountry;
};
