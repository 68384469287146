import React from 'react';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { communicationTrainer, ITrainerConnectedProps } from 'entities/Trainer/Trainer.communication';
import { TrainerAcademyListItem } from 'entities/Trainer/components/AcademyList/TrainerAcademyListItem';
import { TrainerAcademyListNoData } from 'entities/Trainer/components/AcademyList/TrainerAcademyNoData';
import {
  ITrainerAcademyRequestModel,
  ITrainerAcademyRequestCollectionFilter,
  ITrainerAcademyRequestCollection
} from 'entities/Trainer/Trainer.models';

interface IComponentProps {
  academyId?: string;
  teamId?: string;
  clearFilter: () => void;
}
type AllProps = ITrainerConnectedProps & IComponentProps;

class TrainerAcademyListComponent extends InfiniteList<
  ITrainerAcademyRequestCollection,
  ITrainerAcademyRequestModel,
  AllProps,
  Partial<ITrainerAcademyRequestCollectionFilter>
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getTrainerAcademyRequestCollection, filter, academyId, teamId } = this.props;
    const { teamModel, academyFilter, ...filterData } = filter || {};

    const filterParams: ITrainerAcademyRequestCollectionFilter = {
      ...params,
      ...filterData
    };

    if (academyId) {
      filterParams.academy = academyId;
    }
    if (academyFilter) {
      filterParams.academy = academyFilter?.id;
    }

    if (teamId || teamModel?.id) {
      filterParams.team = teamId || teamModel?.id;
    }

    const clearedFilterParams = Object.keys(filterParams)?.reduce((acc, key) => {
      const value: any = (filterParams as any)[key];
      return value !== undefined ? { ...acc, [key]: value } : acc;
    }, {});

    return getTrainerAcademyRequestCollection(clearedFilterParams);
  };

  renderListItem = (item: ITrainerAcademyRequestModel) => {
    return <TrainerAcademyListItem item={item} />;
  };

  getCollection = () => {
    return this.props.trainerAcademyRequestCollection;
  };
  clearCollection = () => {
    return this.props.clearTrainerAcademyRequestCollection();
  };
  renderNoData = () => {
    const { trainerAcademyRequestCollection, filter, clearFilter } = this.props;
    const { loading, data } = trainerAcademyRequestCollection;

    const showClearFilterButton = !!filter?.search || filter?.hasTeam !== undefined || filter?.team !== undefined;

    return !loading && data?.data.length === 0 ? (
      <TrainerAcademyListNoData showClearFilterButton={showClearFilterButton} clearFilter={clearFilter} />
    ) : (
      <LoadingSpin />
    );
  };
}

export const TrainerAcademyList = communicationTrainer.injector(TrainerAcademyListComponent);
