import React from 'react';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { ListNoData } from 'common/components/ListNoData';
import { communicationTeam, ITeamConnectedProps } from 'entities/Team/Team.communication';
import { TeamAcademyListItem } from 'entities/Team/components/List/TeamAcademyListItem';
import { TeamAcademyListNoData } from 'entities/Team/components/List/TeamAcademyNoData';
import { ITeamCollectionFilter, ITeamCollection, ITeamPureModel } from 'entities/Team/Team.models';

interface IComponentProps {
  academyId?: string;
  clearFilter: () => void;
  actionNoDataButton?: React.ReactNode;
  initFilter?: ITeamCollectionFilter;
}
type AllProps = ITeamConnectedProps & IComponentProps;

class TeamAcademyListComponent extends InfiniteList<ITeamCollection, ITeamPureModel, AllProps, Partial<ITeamCollectionFilter>> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getTeamCollection, filter, academyId } = this.props;
    const { name, academyFilter, ...restFilter } = filter || {};

    const newFilter: ITeamCollectionFilter = {
      ...params,
      ...restFilter
    };

    if (academyId) {
      newFilter.academy = academyId;
    }
    if (academyFilter) {
      newFilter.academy = academyFilter?.id;
    }

    if (name) {
      newFilter.name = name;
    }

    return getTeamCollection(newFilter);
  };

  renderListItem = (item: ITeamPureModel) => {
    return <TeamAcademyListItem item={item} />;
  };

  getCollection = () => {
    return this.props.teamCollection;
  };
  clearCollection = () => {
    return this.props.clearTeamCollection();
  };
  renderNoData = () => {
    const { teamCollection, filter, clearFilter, actionNoDataButton, initFilter } = this.props;
    const { loading, data } = teamCollection;
    const isEmpty = !loading && data?.data.length === 0;
    const isDefaultFilters = JSON.stringify(filter) !== JSON.stringify(initFilter);

    return isEmpty ? (
      actionNoDataButton && !isDefaultFilters ? (
        <ListNoData actionButton={actionNoDataButton} startText="Get started by creating first team" />
      ) : (
        <TeamAcademyListNoData showClearFilterButton={isDefaultFilters} clearFilter={clearFilter} />
      )
    ) : (
      <LoadingSpin />
    );
  };
}

export const TeamAcademyList = communicationTeam.injector(TeamAcademyListComponent);
