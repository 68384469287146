import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Col, Row } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import classNames from 'classnames';
import Button from 'antd/es/button';
import { useHistory } from 'react-router';
import { ERoutesPublic } from 'common/models/routesModel';
import { EPermissionType } from 'common/helpers/permission.helper';
import { nameBuilder } from 'common/helpers/name.helper';
import { queryToObject } from 'common/helpers/filters.helper';
import { SCREEN_LG, SCREEN_MD } from 'common/const/config.const';
import { PlayerProfileAbout } from 'entities/Player/components/Profile/PlayerProfileAbout';
import { PlayerPrint } from 'entities/Player/components/PlayerPrint';
import { PlayerShield } from 'entities/Player/components/Shield/PlayerShield';
import { PlayerSkillsBlock } from 'entities/PlayerTest/components/PlayerSkillsBlock/PlayerSkillsBlock';
import { EPlayerAgeGroup, EPlayerLeadingLegs } from 'entities/Player/Player.models';
import { PlayerVideosBlock } from 'entities/Player/components/VideoBlock/PlayerVideosBlock';
import { PlayerProfileProviderContext, PlayerProviderContext } from 'entities/Player/components/PlayerProvider';
import { PrivateContent } from 'entities/Auth/components/PrivateContent';
import { PlayerRecommendationBlock } from 'entities/Player/components/PlayerRecommendationBlock';
import { MentorRequestButton } from 'entities/MentorRequest/components/MentorRequestButton';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { EModalMentorSignUpType } from 'entities/UI/UI.models';
import { playerTransport } from 'entities/Player/Player.transport';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EUserRole } from 'entities/User/User.models';
import { PlayerFavorite } from 'entities/Player/components/ActionButtons/PlayerFavorite';
import { ShareButton } from 'entities/Share/components/SharePlayerButton';
import { ESubscriptionsEngines } from 'entities/Subscription/Subscription.models';
import { SubscriptionButton } from 'entities/Subscription/components/SubscriptionButton';
import { isAcademyHideTariffs } from 'entities/Subscription/helpers/subscription.helper';
import { PlayerCompareAction } from 'entities/Player/components/ActionButtons/PlayerCompareAction';

interface IProps {
  onSkillSelectCallback(skillId: string): void;
  showMentorRequestButton?: boolean;
  unauthorized?: boolean;
  isUserMentorForSomeoneElse?: boolean;
  children?: React.ReactNode;
}

type AllProps = IAuthConnectedProps & IProps & IUIConnectedProps;

const PlayerProfileCommonViewComponent: React.FC<AllProps> = (props: AllProps) => {
  const { t } = useTranslation();
  const { profile } = useContext(PlayerProfileProviderContext);
  const { player: playerModel } = useContext(PlayerProviderContext);
  const { data: playerData } = playerModel || {};
  const {
    onSkillSelectCallback,
    showMentorRequestButton,
    unauthorized,
    openUIMentorSignUpModal,
    isUserMentorForSomeoneElse,
    authModel,
    authUser
  } = props;
  const { data: player, loading } = profile ?? {};
  const mentorRequestId = playerData?.mentorRequest?.id;
  const subscription = playerData?.mentorRequest?.subscription;
  const { isPaid, id } = playerData || {};
  const mentorRequestSubscriptionEngine = playerData?.mentorRequest?.subscriptionEngine;
  const hideTariffs = useMemo(() => isAcademyHideTariffs(playerData), [playerData]);
  const history = useHistory();
  const isPlayerViewPage = history.location.pathname.includes(ERoutesPublic.PlayerView);
  const {
    academy,
    skills,
    lastName,
    firstName,
    ageGroup,
    address,
    leadingLeg,
    recommendation,
    imageId,
    rating,
    videos,
    playerId,
    coach,
    mentor,
    academyToPlayerRequest,
    juniCoachPublicity,
    sponsor
  } = player || {};
  const isLg: boolean = useMediaPredicate(`(min-width: ${SCREEN_LG})`);
  const isMd: boolean = useMediaPredicate(`(min-width: ${SCREEN_MD})`);
  const { firstName: coachFirstName, lastName: coachLastName, imageId: coachImageId } = coach || {};
  const isHaveMentor = !!mentor?.mentorId && !mentor?.isVirtual;
  const isAcademyWorker = authUser?.data?.role === EUserRole.AcademyWorker;
  const isUserAuth = authModel?.data?.access;
  const isPlayerAnonym = !firstName && !lastName;
  const { token } = queryToObject<{ token?: string }>({ token: undefined }) as { token: string | undefined };

  const checkToken = useCallback(
    async (type: EModalMentorSignUpType) => {
      if (!playerId || isAcademyWorker) {
        return;
      }

      if (!token) {
        if (!isPlayerViewPage) {
          openUIMentorSignUpModal({ modalType: type });
        }
        return;
      }

      try {
        await playerTransport.checkShareToken({ playerId, token });

        if (!isPlayerViewPage) {
          openUIMentorSignUpModal({
            tokenExpired: false,
            token,
            modalType: type
          });
        }
      } catch (e) {
        // Only if token is expired and user not authorized we should show modal without close btn
        if (
          e?.data?.code === 'error.tokenExpiredException' &&
          !isUserAuth &&
          type === EModalMentorSignUpType.SignUp &&
          !isPlayerViewPage
        ) {
          openUIMentorSignUpModal({ tokenExpired: true, token, modalType: EModalMentorSignUpType.SignUp });
          // Looks like redundant useless check but keeped for history, can be removed after 01.07.22
          // } else if (){
          //   message.error(translateErrors('The link is invalid or has already been used'));
        }
      }
    },
    [token, isUserAuth, isUserMentorForSomeoneElse]
  );

  useEffect(() => {
    if (!token) {
      return;
    }
    if (isUserMentorForSomeoneElse) {
      checkToken(EModalMentorSignUpType.Accept);
    }
    if (!isUserAuth) {
      checkToken(EModalMentorSignUpType.SignUp);
    }
  }, []);

  const onClickSignUp = useCallback(async () => {
    checkToken(EModalMentorSignUpType.SignUp);
  }, [checkToken]);

  const onClickMentor = useCallback(async () => {
    checkToken(EModalMentorSignUpType.Accept);
  }, [checkToken]);

  const isAcademyPlayer = useMemo(() => academy?.academyId === authUser.data?.academyWorker?.academy.id, []);

  return (
    <>
      <Col xs={24} className={classNames('player-profile__common-view width-full', { 'px-5': !isLg })}>
        <Row className="width-full" gutter={[0, isMd ? 0 : 24]} justify="center" wrap={!isMd}>
          <Col style={{ maxWidth: 288 }} className={classNames({ 'mr-7': isMd })}>
            <Row className="mb-5" justify="center">
              <PlayerShield
                rating={rating}
                leadingLeg={leadingLeg as EPlayerLeadingLegs}
                ageGroup={ageGroup as EPlayerAgeGroup}
                firstName={firstName}
                loading={loading}
                playerImage={imageId}
                academyImage={academy?.imageId}
                showSkills
                lastName={lastName}
                skills={skills}
                countryCode={address?.countryCode}
                sponsor={sponsor}
              />
            </Row>
            {!isHaveMentor && showMentorRequestButton && (
              <PrivateContent availableFor={EPermissionType.ShowDataForAcademy}>
                <Row className="mb-5" justify="center">
                  <MentorRequestButton block playerId={playerId} />
                </Row>
              </PrivateContent>
            )}
            <PrivateContent availableFor={EPermissionType.ShowDataForMentor}>
              <Row className="mb-5 player-profile__common-view__subscription">
                <SubscriptionButton
                  block
                  playerId={id}
                  hideActiveStatus
                  hideUnsubscribeBtn
                  mentorRequestId={mentorRequestId}
                  subscription={subscription}
                  hideTariffs={hideTariffs}
                  isPaid={isPaid}
                  mentorRequestSubscriptionEngine={mentorRequestSubscriptionEngine as ESubscriptionsEngines}
                />
              </Row>
            </PrivateContent>
            {unauthorized && !isPlayerViewPage && (
              <Row className="mb-5" justify="center">
                <Button htmlType="submit" type="primary" block size="large" onClick={onClickSignUp} title={t('Register')}>
                  {t('Register')}
                </Button>
              </Row>
            )}
            {!isHaveMentor && isUserMentorForSomeoneElse && !isPlayerAnonym && !!token && !isPlayerViewPage && (
              <Row className="mb-5" justify="center">
                <Button htmlType="submit" type="primary" block size="large" onClick={onClickMentor} title={t('Send request')}>
                  {t('Send request')}
                </Button>
              </Row>
            )}
            <Row className={`${isPlayerViewPage ? '' : 'mb-5'} hide-on-print`} justify="center">
              {player && (
                <Col span={24} className="mb-5">
                  <ShareButton
                    playerId={playerId}
                    playerAcademyId={player?.academy?.academyId}
                    disabled={loading}
                    hasMentor={isHaveMentor}
                    publicity={juniCoachPublicity}
                    block
                    type="default"
                  />
                </Col>
              )}
              {!isPlayerViewPage && (
                <Button onClick={window.print} block size="large" title={t('Print')}>
                  <PrinterOutlined /> {t('Print')}
                </Button>
              )}
            </Row>
            {!unauthorized && !!playerId && (
              <Row className="mb-5">
                <PlayerCompareAction playerId={playerId} size="large" adaptive={false} className="width-full" />
              </Row>
            )}
            <PrivateContent availableFor={EUserRole.AcademyWorker}>
              {player && (
                <Row className="mb-5 hide-on-print" justify="center">
                  <PlayerFavorite
                    className="width-full"
                    size="large"
                    adaptive={false}
                    id={playerId}
                    showIcon
                    academyToPlayerRequest={academyToPlayerRequest}
                    isAcademyPlayer={isAcademyPlayer}
                  />
                </Row>
              )}
            </PrivateContent>
            {player && <PlayerProfileAbout player={player} />}
          </Col>
          <Col flex={1}>
            <Row className="player-profile__common-view__skills">
              <PlayerSkillsBlock
                data={skills}
                onSkillSelectCallback={onSkillSelectCallback}
                className={classNames({
                  'mb-3': isMd,
                  'mb-5': !isMd
                })}
                loading={loading}
              />
            </Row>
            <PlayerRecommendationBlock
              coachImg={coachImageId}
              coachName={nameBuilder(coachFirstName, coachLastName)}
              recommendation={recommendation}
              loading={loading}
              className="mb-5"
            />
            <PlayerVideosBlock data={videos} loading={loading} />
          </Col>
        </Row>
      </Col>
      {player && !isPlayerViewPage && <PlayerPrint player={player} />}
    </>
  );
};

export const PlayerProfileCommonView = communicationAuth.injector(communicationUI.injector(PlayerProfileCommonViewComponent));
