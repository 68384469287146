import Typography from 'antd/es/typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EPlayerAgeGroup } from 'entities/Player/Player.models';

interface IComponentProps {
  ageGroup?: EPlayerAgeGroup;
}

export const PlayerAgeGroupSmall = ({ ageGroup }: IComponentProps) => {
  const { t } = useTranslation();

  return (
    <Typography.Title level={5} title={t('Age group')} className="px-3 py-1 mb-0 player-age-group-small radius-round t-align-c">
      {ageGroup ? t(`${ageGroup}`) : '-'}
    </Typography.Title>
  );
};
