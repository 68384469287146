import React from 'react';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Icon from '@ant-design/icons/es/components/Icon';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HumanIcon } from 'app/assets/icons/full-human.svg';
import { EMentorRequestModalType } from 'entities/UI/UI.models';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';

type AllProps = IUIConnectedProps;

const MentorRequestListNoDataComponent = (props: AllProps) => {
  const { t } = useTranslation();

  const onClick = () => {
    const { openUIMentorRequestModal } = props;
    openUIMentorRequestModal({ modalType: EMentorRequestModalType.AddPlayer });
  };

  return (
    <Row className="players-nodata" justify="center" align="middle">
      <Icon className="players-nodata__icon" component={HumanIcon} />
      <Button block onClick={onClick} size="large" type="primary">
        {t('Add player')}
      </Button>
    </Row>
  );
};

export const MentorRequestListNoData = communicationUI.injector(MentorRequestListNoDataComponent);
