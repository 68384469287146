import React, { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { ERoutesAcademy } from 'common/models/routesModel';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EUserRole, EUserStatus } from 'entities/User/User.models';
import { EAcademyStatus } from 'entities/Academy/Academy.models';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';

interface IComponentProps {
  academyRoutes: ReactElement;
  mentorRoutes: ReactElement;
  banRoutes: ReactElement;
  signUpFinish: ReactElement;
}
type AllProps = IAuthConnectedProps & IComponentProps & IPlayerConnectedProps;

const RoleCheckLayoutComponent: React.FC<AllProps> = props => {
  const history = useHistory();
  const {
    authUser,
    academyRoutes,
    mentorRoutes,
    authAcademy,
    banRoutes,
    getPlayerCompare,
    clearPlayerCompare,
    signUpFinish
  } = props;
  const { data: authUserData, loading: authUserLoading } = authUser;
  const { data: authAcademyData, loading: authAcademyLoading } = authAcademy;
  const isMentor = authUserData?.role === EUserRole.Mentor;
  const isMentorBanned = authUserData?.status === EUserStatus.Banned;
  const isAcademyBanned = authAcademyData?.status === EAcademyStatus.Banned;
  const academyLoading = authUserData?.role === EUserRole.AcademyWorker ? authAcademyLoading : false;
  const isSignUpNotFinish = authUserData?.firstName === '' || authUserData?.lastName === '' || !authUserData?.email;

  useEffect(() => {
    getPlayerCompare();

    return clearPlayerCompare;
  }, []);

  useEffect(() => {
    if (authAcademyData && !authAcademyData.address) {
      history.push(ERoutesAcademy.InitAcademyInfo);
    }
  }, [authAcademyData]);

  if (authUserLoading || academyLoading) {
    return <LoadingSpin />;
  }

  return isMentorBanned || isAcademyBanned
    ? banRoutes
    : isMentor
    ? mentorRoutes
    : isSignUpNotFinish
    ? signUpFinish
    : academyRoutes;
};

export const RoleCheckLayout = communicationPlayer.injector(communicationAuth.injector(RoleCheckLayoutComponent));
