import { options } from 'options';
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import Typography from 'antd/es/typography';
import Row from 'antd/es/row';
import { ERoutesMentor } from 'common/models/routesModel';
import { formatDateFromServer } from 'common/helpers/date.helper';
import { clientDateFullMonthFormat } from 'common/models/dateModels';
import { useMomentLocale } from 'common/helpers/use-moment-locale.helper';
import SuccessIcon from 'app/assets/images/payment-success.png';
import FailureIcon from 'app/assets/images/payment-failure.png';
import { EPlayerTabKeys } from 'entities/Player/Player.models';
import { ISubscriptionModel } from 'entities/Subscription/Subscription.models';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  onTryAgain: () => void;
  isSuccess: boolean;
  subscription: ISubscriptionModel | null;
}

type AllProps = IProps;

const IconSize = 128;

const PaymentCheckModalComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const momentLocale = useMomentLocale();
  const { visible, onCancel, isSuccess, subscription, onTryAgain } = props;

  const clearSubscriptionInfo = () => {
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search.slice(1));
    urlParams.delete('subscriptionId');
    history.replace(`${url.pathname}?${urlParams.toString()}`);
  };

  const onClose = () => {
    clearSubscriptionInfo();
    onCancel();
  };

  const onTryAgainCallback = () => {
    clearSubscriptionInfo();
    onTryAgain();
  };

  const { title, description, icon, playerId } = useMemo(() => {
    const subscriptionEndDate = formatDateFromServer(subscription?.prepaidTo, clientDateFullMonthFormat);

    return {
      title: isSuccess ? t('Subscribed successfully') : t('Payment error'),
      description: isSuccess ? t('payment-success-description', { date: subscriptionEndDate }) : t('payment-error-description'),
      icon: isSuccess ? SuccessIcon : FailureIcon,
      playerId: subscription?.mentorRequest?.player?.id
    };
  }, [isSuccess, subscription, momentLocale]);

  const toPlayerSettings = useCallback(() => {
    onClose();
    history.push(`${ERoutesMentor.Player}/${playerId}/${EPlayerTabKeys.Setting}`);
  }, [playerId]);

  return (
    <Modal visible={visible} width={375} footer={null} onCancel={onClose} centered destroyOnClose className="payment-check-modal">
      <Row justify="center" className="mt-9 mb-5">
        <img alt={title} src={icon} width={IconSize} height={IconSize} />
      </Row>

      <Typography.Title level={3} className="width-full t-align-c mb-3">
        {title}
      </Typography.Title>
      <Row className="mb-5 px-3">
        <Typography.Text className="mb-0 t-align-c payment-check-modal__description">
          {description}
          {!isSuccess && (
            <Typography.Link underline={false} className="ml-2 color-link" href={`mailto:${options.supportMail}`}>
              {options.supportMail}
            </Typography.Link>
          )}
        </Typography.Text>
      </Row>
      {isSuccess ? (
        <>
          <Button className="mb-5" onClick={toPlayerSettings} type="primary" block size="large">
            {t('Player settings')}
          </Button>
          <Button onClick={onClose} block size="large">
            {t('Close')}
          </Button>
        </>
      ) : (
        <Button type="primary" block onClick={onTryAgainCallback} size="large">
          {t('Try again')}
        </Button>
      )}
    </Modal>
  );
};

export const PaymentCheckModal = PaymentCheckModalComponent;
