import React, { useState, useMemo, useEffect } from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import Spin from 'antd/es/spin';
import { useTranslation } from 'react-i18next';
import { useCountryCode } from 'common/helpers/language.helper';

enum EInputSize {
  Small = 'small',
  Middle = 'middle',
  Large = 'large'
}

interface IComponentProps {
  size?: EInputSize;
  onChange?: (
    phone: string | null,
    country: { format: string; dialCode?: string } | {},
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => void;
}

type AllProps = Omit<PhoneInputProps, 'onChange'> & IComponentProps;

const DefaultMaxLength = 11;

const ExistingLocalesFiles = ['ru', 'ar', 'cn', 'de', 'es', 'fr', 'id', 'ir', 'it', 'jp', 'pt', 'tr'];

export const Phone = ({ onChange, size = EInputSize.Large, value, ...props }: AllProps) => {
  const { i18n, t } = useTranslation();
  const [localization, setLocalization] = useState<undefined | object>();
  const [localeLoading, setLocaleLoading] = useState(false);
  const [mask, setMask] = useState<string>('');
  const language = i18n?.language;
  const countryCode = useCountryCode();

  const languageChanged = async () => {
    setLocaleLoading(true);

    if (language && ExistingLocalesFiles.includes(language)) {
      const localeFile = await import(`react-phone-input-2/lang/${language}.json`);
      setLocalization(localeFile?.default);
    } else {
      setLocalization(undefined);
    }

    setTimeout(() => setLocaleLoading(false));
  };

  useEffect(() => {
    languageChanged();
  }, [language]);

  const phoneValue = useMemo(() => value || '', [value]);

  const antdClass = useMemo(() => {
    switch (size) {
      case EInputSize.Large:
        return 'ant-input-lg';
      case EInputSize.Middle:
        return '';
      case EInputSize.Small:
        return 'ant-input-sm';
    }
  }, [size]);

  const inputProps = useMemo(
    () => ({
      maxLength: mask.length || DefaultMaxLength
    }),
    [mask]
  );

  if (localeLoading) {
    return <Spin />;
  }

  const onChangePhone = (
    item: string,
    country: { format: string; dialCode?: string },
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    const phone = !item ? '' : `+${item}`;
    onChange && onChange(phone, country, event, formattedValue);
    setMask(country.format ? country.format.replace(/\./g, '1') : '');
  };

  return (
    <div className="react-tel-input-wrapper">
      <PhoneInput
        value={phoneValue}
        specialLabel=""
        enableLongNumbers
        country={countryCode}
        localization={localization}
        onChange={onChangePhone}
        inputProps={inputProps}
        containerClass={`ant-input size__${size} ${antdClass}`}
        inputClass=""
        {...props}
      />
      {mask && <span className="react-tel-input-wrapper__format mt-2">{`${t('Phone format', { mask })}`}</span>}
    </div>
  );
};
