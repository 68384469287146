import React from 'react';
import Row from 'antd/es/row';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
// import Rate from 'antd/es/rate';
import { useTranslation } from 'react-i18next';
// import { getPlayerRatingByValue } from 'common/helpers/player.helper';
// import { EPlayerWFS } from 'entities/Player/Player.models';
import { ISkills } from 'entities/Share/Share.models';

const DEFAULT_AVATAR = '/images/defaultAvatar.svg';
interface IComponentProps {
  firstName?: string;
  lastName?: string;
  rating?: number;
  ageGroup?: string;
  imageUrl?: string;
  skills?: ISkills;
}
const ShareProfileComponent: React.FC<IComponentProps> = props => {
  const { t } = useTranslation();
  const { firstName, lastName, rating, ageGroup, imageUrl = DEFAULT_AVATAR } = props;

  // const weakFootValue = useMemo(() => {
  //   let skillValue = 0;
  //   if (skills && EPlayerWFS.PlayingWithWeakFoot in skills) {
  //     skillValue = skills[EPlayerWFS.PlayingWithWeakFoot]?.value || 0;
  //   }
  //   return getPlayerRatingByValue(skillValue);
  // }, [skills]);

  const isDefaultPlayerAvatar = imageUrl?.includes('defaultPlayerAvatar');

  return (
    <Card className="promo-page__card promo-page__player" bordered={false}>
      <img className={`promo-page__image ${isDefaultPlayerAvatar ? 'default-avatar' : ''}`} src={imageUrl} alt="User avatar" />
      <Row className="px-6 pt-5 pb-6" gutter={[0, 12]}>
        <Col span={24}>
          <span className="promo-page__playerName"> {firstName} </span>
          <span className="promo-page__playerName"> {lastName} </span>
        </Col>
        <Col span={24}>
          <Row gutter={[10, 10]}>
            {rating !== undefined && (
              <Col>
                <div className="promo-page__playerMetric promo-page__playerMetric-rating">
                  <label> {t('Rating JuniStat')}</label>
                  <div className="promo-page__playerRating">
                    <label>{rating}</label>
                  </div>
                </div>
              </Col>
            )}
            <Col>
              {ageGroup && (
                <div className="promo-page__playerMetric">
                  <label>{t('Group')}</label>
                  <div className="promo-page__playerAge">{t(`${ageGroup}`)}</div>
                </div>
              )}
            </Col>
            {/* <Col>*/}
            {/*  <div className="promo-page__playerMetric">*/}
            {/*    <label className="promo-page__playerWeakLeg-label"> {t('Weak leg')} </label>*/}
            {/*    <Rate disabled className="promo-page__playerWeakLeg" allowHalf value={weakFootValue} />*/}
            {/*  </div>*/}
            {/* </Col>*/}
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export const ShareProfile = ShareProfileComponent;
