import React, { ReactElement, useCallback, useMemo } from 'react';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import Popover from 'antd/es/popover';
import Typography from 'antd/es/typography';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { stripeSettingsPageRoute } from 'common/models/routesModel';
import {
  EPaymentActivitiesStatus,
  EProcessings,
  ESubscriptionsEngines,
  ESubscriptionStatus,
  ISubscriptionModel
} from 'entities/Subscription/Subscription.models';
import { communicationSubscription, ISubscriptionConnectedProps } from 'entities/Subscription/Subscription.communication';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { SubscriptionDelayedCancellation } from 'entities/Subscription/components/SubscriptionDelayedCancellation';
import { getSubscriptionInfoTextClass } from 'entities/Subscription/helpers/subscription.helper';

interface IProps {
  subscription?: ISubscriptionModel;
  mentorRequestId: string | undefined;
  playerId?: string;
  mentorRequestSubscriptionEngine: ESubscriptionsEngines;
  onUnsubscribeClick?: () => void;
  onSubscribeClick?: () => void;
  onPayClick?: () => void;
  hideTariffs?: boolean;
  isPaid?: boolean;
  hideUnsubscribeBtn?: boolean;
  hideActiveStatus?: boolean;
  unsubscribeElement?: ReactElement;
  block?: boolean;
  size?: 'large' | 'middle' | 'small';
}

type AllProps = IProps & ISubscriptionConnectedProps & IUIConnectedProps;

const SubscriptionButtonComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    onSubscribeClick,
    subscription,
    mentorRequestId,
    updateSubscriptionModel,
    onUnsubscribeClick,
    hideUnsubscribeBtn,
    unsubscribeElement,
    block,
    size = 'middle',
    openUIPaymentRequirementsModal,
    subscriptionModel,
    mentorRequestSubscriptionEngine,
    playerId,
    isPaid,
    hideTariffs,
    hideActiveStatus
  } = props;
  const { loading: subscriptionLoading } = subscriptionModel;

  const isSubscriptionActive = useMemo(() => subscription && subscription.status === ESubscriptionStatus.Active, [subscription]);

  const isDelayedCancellation = useMemo(() => subscription && subscription.delayedCancellation, [subscription]);

  const linkToPay = useMemo(() => {
    if (subscription && subscription.lastActivity?.confirmationUrl) {
      return subscription.lastActivity.confirmationUrl;
    }
    return '';
  }, [subscription]);

  const isCheckingLastPayment = useMemo(() => {
    if (subscription && subscription.lastActivity && subscription?.engine !== ESubscriptionsEngines.Stripe) {
      return (
        subscription.lastActivity.status === EPaymentActivitiesStatus.New || subscription.lastActivity.isApplied === undefined
      );
    }

    return false;
  }, [subscription]);

  const label = useMemo(() => {
    switch (subscription?.status) {
      case ESubscriptionStatus.Active: {
        return t('Unsubscribe');
      }
      case ESubscriptionStatus.Expired: {
        return t('Pay for a Subscription');
      }
      default: {
        return t('Subscribe');
      }
    }
  }, [subscription]);

  const getInfoTextClass = useMemo(() => getSubscriptionInfoTextClass(size, block), [size, block]);

  const btnType = useMemo(() => {
    if (isSubscriptionActive) {
      return 'default';
    }
    return 'primary';
  }, [subscription]);

  const onUnsubscribe = () => {
    if (subscription?.id) {
      updateSubscriptionModel({
        id: subscription.id,
        status: ESubscriptionStatus.Canceled
      });
      if (onUnsubscribeClick) {
        onUnsubscribeClick();
      }
    }
  };

  const onClickHandler = useCallback(
    e => {
      e?.stopPropagation();
      if (subscription?.status !== ESubscriptionStatus.Active) {
        if (mentorRequestSubscriptionEngine === ESubscriptionsEngines.Yookassa) {
          const body =
            subscription?.status === ESubscriptionStatus.Expired
              ? {
                  subscriptionId: subscription.id,
                  processing: EProcessings.Yookassa
                }
              : {
                  mentorRequestId
                };
          openUIPaymentRequirementsModal(body);
        }
        if (mentorRequestSubscriptionEngine === ESubscriptionsEngines.Stripe && playerId) {
          history.push(stripeSettingsPageRoute(playerId));
        }
        onSubscribeClick && onSubscribeClick();
      }
    },
    [subscription, playerId, mentorRequestSubscriptionEngine]
  );

  const preventDefault = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  }, []);

  switch (true) {
    case isDelayedCancellation:
      return <SubscriptionDelayedCancellation subscription={subscription} size={size} block={block} />;
    case isCheckingLastPayment:
      return (
        <div className={block ? 'width-full' : ''} onClick={preventDefault}>
          <Typography.Text className={getInfoTextClass}>
            {t('Payment is verifying')}
            {mentorRequestSubscriptionEngine === ESubscriptionsEngines.Yookassa && (
              <Popover
                placement="top"
                trigger={['click']}
                content={
                  <Typography.Text className="mb-0">
                    {t("If you didn't pay")}
                    <Typography.Link href={linkToPay} className="color-link ml-2 t-decoration-none">
                      {t('click here')}
                    </Typography.Link>
                  </Typography.Text>
                }
              >
                <InfoCircleOutlined className="ml-2 color-link cursor-pointer" />
              </Popover>
            )}
          </Typography.Text>
        </div>
      );
    case isPaid:
    case hideTariffs: {
      return !hideActiveStatus ? (
        <Typography.Text className="color-text-additional d-block py-3 fs-xs">{t('active')}</Typography.Text>
      ) : null;
    }
    case isSubscriptionActive: {
      if (hideUnsubscribeBtn) {
        return null;
      }
      if (unsubscribeElement) {
        return unsubscribeElement;
      }
      return <Typography.Text className="color-text-additional d-block py-3 fs-xs">{t('active')}</Typography.Text>;
    }
    default:
      return (
        <Popconfirm
          disabled={!isSubscriptionActive}
          okButtonProps={{
            type: 'text',
            size: 'middle',
            danger: true
          }}
          cancelButtonProps={{
            size: 'middle'
          }}
          okText={t('Yes')}
          cancelText={t('No')}
          title={t('Unsubscribe?')}
          onConfirm={onUnsubscribe}
        >
          <Button type={btnType} disabled={subscriptionLoading} size={size} block={block} onClick={onClickHandler}>
            {label}
          </Button>
        </Popconfirm>
      );
  }
};

export const SubscriptionButton = communicationUI.injector(communicationSubscription.injector(SubscriptionButtonComponent));
