import { Col, Row, Space } from 'antd';
import QRCode from 'qrcode.react';
import React from 'react';
import { Paper } from 'common/components/Paper/Paper';
import { ERoutesMentor } from 'common/models/routesModel';
import { getPlayerName } from 'common/helpers/name.helper';
import { Print } from 'common/components/Print/Print';
import { PlayerProfileAbout } from 'entities/Player/components/Profile/PlayerProfileAbout';
import { PlayerSkillsBlock } from 'entities/PlayerTest/components/PlayerSkillsBlock/PlayerSkillsBlock';
import { PlayerShieldSmall } from 'entities/Player/components/Shield/PlayerShieldSmall';
import { EPlayerAgeGroup, EPlayerLeadingLegs, IPlayerProfileModel } from 'entities/Player/Player.models';

export interface IPlayerPrint {
  player: IPlayerProfileModel;
}

const PlayerPrintComponent: React.FC<IPlayerPrint> = props => {
  const { player } = props;
  const { rating, firstName, leadingLeg, ageGroup, imageId, lastName, skills, address, academy, playerId } = player;

  const playerName = getPlayerName(player);
  return (
    <Print title={playerName}>
      <Row gutter={[16, 16]} wrap={false}>
        <Col flex={0} style={{ maxWidth: 288 }}>
          <Space direction="vertical" className="width-full">
            <PlayerShieldSmall
              className="p-0"
              rating={rating}
              leadingLeg={leadingLeg as EPlayerLeadingLegs}
              ageGroup={ageGroup as EPlayerAgeGroup}
              firstName={firstName}
              playerImage={imageId}
              academyImage={academy?.imageId}
              showSkills
              lastName={lastName}
              skills={skills}
              countryCode={address?.countryCode}
            />
            <Paper>
              <QRCode value={`${location.origin}${ERoutesMentor.Player}/${playerId}`} renderAs="svg" width="100%" />
            </Paper>
            {player && <PlayerProfileAbout player={player} />}
          </Space>
        </Col>
        <Col flex={1}>
          <PlayerSkillsBlock itemXs={12} itemMd={8} activeWrap loading={false} data={skills} className="mb-5" />
        </Col>
      </Row>
    </Print>
  );
};

export const PlayerPrint = PlayerPrintComponent;
