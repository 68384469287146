import React, { ReactElement } from 'react';
import Row from 'antd/es/row';

interface IProps {
  children: ReactElement;
}

const Component: React.FC<IProps> = props => {
  const { children } = props;

  return (
    <Row align="middle" justify="center" style={{ flex: 1 }}>
      {children}
    </Row>
  );
};

export const StripeSubscriptionInfoCardCenteredLayout = Component;
