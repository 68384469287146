import React from 'react';

interface IComponentProps {
  title: string;
  icon?: React.ReactNode;
  eventsCount?: number;
}

export const Tab: React.FC<IComponentProps> = props => {
  const { title, icon, eventsCount } = props;

  return (
    <div className="d-flex">
      {icon && <div className="mr-4 fs-priority">{icon}</div>}
      {title}
      <span className="color-text-additional"> &nbsp;{eventsCount && `+${eventsCount}`} </span>
    </div>
  );
};
