import React from 'react';
import { Redirect } from 'react-router';
import { ERoutesAcademy } from 'common/models/routesModel';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps;

const RootAcademyPageComponent: React.FC<AllProps> = ({ authAcademy }) => {
  const authAcademyModelData = authAcademy?.data;
  const { id } = authAcademyModelData || {};

  return <Redirect to={`${ERoutesAcademy.AcademyView}/${id}`} />;
};
export const RootAcademyPage = communicationAuth.injector(RootAcademyPageComponent);
