import React, { useState } from 'react';
import { PlayerVideosModalBodyUpload } from 'entities/Player/components/PlayerVideos/PlayerVideosModalBodyUpload';
import { PlayerVideosModalBodyEdit } from 'entities/Player/components/PlayerVideos/PlayerVideosModalBodyEdit';
import { IAddPlayerVideoParams } from 'entities/Player/Player.models';

enum EModalState {
  Upload = 'upload',
  Edit = 'edit'
}

interface IComponentProps {
  onCancel: () => void;
  id?: string;
  isDesktop: boolean;
}

type AllProps = IComponentProps;

const PlayerVideosModalBodyComponent: React.FC<AllProps> = props => {
  const { onCancel, id, isDesktop } = props;
  const [modalState, setModalState] = useState<EModalState>(() => (id ? EModalState.Edit : EModalState.Upload));
  const [uploadData, setUploadData] = useState<Partial<IAddPlayerVideoParams>>({ title: '' });

  const onUpload = (data: Partial<IAddPlayerVideoParams>) => {
    setUploadData(data);
    setModalState(EModalState.Edit);
  };

  return (
    <div className="players__videos-tab__modal__body width-full">
      {modalState === EModalState.Upload && (
        <PlayerVideosModalBodyUpload onCancel={onCancel} isDesktop={isDesktop} setUploadData={onUpload} />
      )}
      {modalState === EModalState.Edit && (
        <PlayerVideosModalBodyEdit id={id} uploadData={uploadData} isDesktop={isDesktop} onCancel={onCancel} />
      )}
    </div>
  );
};

export const PlayerVideosModalBody = PlayerVideosModalBodyComponent;
