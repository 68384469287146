import { useMemo } from 'react';
import { StoreBranch } from '@axmit/redux-communications';
import { IAcademyModel } from 'entities/Academy/Academy.models';
import { EFranchiseStatuses, EFranchiseTypes } from 'entities/Franchise/Franchise.models';

export const isAcademyFranchiseActive = (academy?: IAcademyModel | null) => {
  return academy?.franchiseRequest?.status === EFranchiseStatuses.Active;
};

// main in franchise
export const isAcademyFranchisor = (academy?: IAcademyModel | null) => {
  return academy?.franchiseRequest?.type === EFranchiseTypes.Main && isAcademyFranchiseActive(academy);
};

// child in franchise
export const isAcademyFranchisee = (academy?: IAcademyModel | null) => {
  return academy?.franchiseRequest?.type === EFranchiseTypes.Child && isAcademyFranchiseActive(academy);
};

export const useAcademiesFilterWithFranchisor = (academyId?: string, authAcademy?: StoreBranch<IAcademyModel>): Array<string> => {
  const isAuthFranchisor = isAcademyFranchisor(authAcademy?.data);

  return useMemo(() => {
    const academies = [];

    if (academyId) {
      academies.push(academyId);
    }

    if (authAcademy?.data?.id && isAuthFranchisor && academyId !== authAcademy.data.id) {
      academies.push(authAcademy.data.id);
    }

    return academies;
  }, [isAuthFranchisor, authAcademy?.data, academyId]);
};
