import React from 'react';
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import CameraOutlined from '@ant-design/icons/CameraOutlined';
import Col from 'antd/es/col';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { nameBuilder } from 'common/helpers/name.helper';
import { EPlayerAgeGroup, IPlayerProfilePlayerModel } from 'entities/Player/Player.models';
import { PlayerAgeGroupSmall } from 'entities/Player/components/Card/PlayerAgeGroupSmall';
import { PlayerRatingSmall } from 'entities/Player/components/Card/PlayerRatingSmall';

interface IComponentProps {
  player: IPlayerProfilePlayerModel;
  previewAvatar?: boolean;
  onClick?(itemModel: IPlayerProfilePlayerModel): void;
  showPlayerData?: boolean;
}

const avatarSize = 48;
const defaultAvatarStyle = { width: avatarSize, height: avatarSize, fontSize: avatarSize / 2 };

const PlayerCardSmallComponent: React.FC<IComponentProps> = props => {
  const { player, onClick, previewAvatar, showPlayerData } = props;
  const { firstName, lastName, image, ageGroup, rating } = player || {};
  const { t } = useTranslation();
  const playerName = nameBuilder(firstName, lastName);

  return (
    <Card
      hoverable={!!onClick}
      onClick={() => {
        onClick && onClick(player);
      }}
      className="p-3 player-card-small bg-component border-none radius-default"
    >
      <Row className="width-full" align="middle" wrap={false}>
        <Col className="mr-3">
          {showPlayerData && image?.id ? (
            <ImagePrivate
              alt={t('Player avatar')}
              width={avatarSize}
              height={avatarSize}
              preview={previewAvatar}
              className="border-circle"
              imgId={image.id}
            />
          ) : (
            <Row justify="center" align="middle" className="bg-component-third radius-round" style={defaultAvatarStyle}>
              <CameraOutlined />
            </Row>
          )}
        </Col>
        <Col flex={1} className="mr-3">
          <Typography.Title ellipsis={{ rows: 2 }} level={5} title={playerName} className="font-weight-5 mb-0">
            {!showPlayerData ? t('Profile hidden') : playerName}
          </Typography.Title>
        </Col>
        <Col>
          <PlayerAgeGroupSmall ageGroup={ageGroup as EPlayerAgeGroup} />
        </Col>
        <Col className="ml-2">
          <PlayerRatingSmall rating={rating} />
        </Col>
      </Row>
    </Card>
  );
};

export const PlayerCardSmall = PlayerCardSmallComponent;
