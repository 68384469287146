import React from 'react';
import Typography from 'antd/es/typography';
import i18n from 'common/helpers/i18n';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import { IPlayerVideoCollection, IPlayerVideoCollectionFilter, IPlayerVideoModel } from 'entities/Player/Player.models';
import { PlayerVideosListItem } from 'entities/Player/components/PlayerVideos/PlayerVideosListItem';

interface IComponentProps {
  playerId: string;
  onOpenElement: (elementId: string) => void;
  isDesktop: boolean;
}

type AllProps = IComponentProps & IPlayerConnectedProps;

class PlayerVideosListComponent extends InfiniteList<
  IPlayerVideoCollection,
  IPlayerVideoModel,
  AllProps,
  IPlayerVideoCollectionFilter
> {
  loadCollection = async (params: IBaseFilterModel) => {
    const { getPlayerVideosCollection, playerId } = this.props;

    if (playerId) {
      return getPlayerVideosCollection({ ...params, playerId });
    }
  };

  renderNoData = () => {
    return <Typography.Text>{i18n.t('There is no video yet')}</Typography.Text>;
  };

  renderListItem = (item: IPlayerVideoModel) => {
    const { isDesktop, onOpenElement } = this.props;
    return <PlayerVideosListItem item={item} isDesktop={isDesktop} onOpenElement={onOpenElement} />;
  };

  getCollection = () => {
    return this.props.playerVideosCollection;
  };

  clearCollection = () => {
    return this.props.clearPlayerVideosCollection();
  };
}

export const PlayerVideosList = communicationPlayer.injector(PlayerVideosListComponent);
