import React, { useEffect } from 'react';
import window from 'global/window';
import { options } from 'options';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { isiOsSafari } from 'common/helpers/system.helper';

export const RedirectPage: React.FC = () => {
  useEffect(() => {
    let replaceURL: string = 'https://junistatapp.page.link';
    const redirectLink =
      window.location.href.split('redirectLink=').length === 2 ? window.location.href.split('redirectLink=')[1] : null;

    switch (true) {
      case !!window.location.href.match(options.coachGooglePlayId):
        replaceURL = 'https://junicoach.page.link';
        break;

      case !!redirectLink:
        replaceURL = decodeURIComponent(redirectLink);
        break;

      case !!window.location.href.match('junicoach.page.link'):
        replaceURL = 'https://junicoach.page.link';
        break;

      default:
        break;
    }

    let search = `${window.location.search}`;

    if (isiOsSafari('iPhone') || isiOsSafari('iPad')) {
      search = search.replace('efr=1', 'efr=0');
    }

    window.location.replace(redirectLink ? replaceURL : `${replaceURL}/${search}`);
  }, []);

  return <LoadingSpin />;
};
