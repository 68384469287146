import React, { useEffect, useMemo } from 'react';
import Typography from 'antd/es/typography';
import Row from 'antd/es/row';
import { useTranslation } from 'react-i18next';
import { stripeSettingsPageRoute } from 'common/models/routesModel';
import { isCurrentUserMentorForPlayer } from 'common/helpers/player.helper';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import { communicationSubscription, ISubscriptionConnectedProps } from 'entities/Subscription/Subscription.communication';
import {
  isSubscriptionActive,
  stripeTrialSubscriptionLeft,
  isAcademyHideTariffs
} from 'entities/Subscription/helpers/subscription.helper';

type AllProps = IPlayerConnectedProps & ISubscriptionConnectedProps;

const CLASS_LIST = 'width-full t-align-c p-4 pre-line-space text-white zIndex-100';

const Component: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { playerModel, getSubscriptionModel, subscriptionModel } = props;
  const { data: player } = playerModel;
  const { data: subscription, loading: subscriptionLoading } = subscriptionModel;
  const { isPaid } = player || {};
  const playerSubscriptionId = player?.mentorRequest?.subscription?.id;
  const trialVersionDiffDays = useMemo(() => stripeTrialSubscriptionLeft(subscription), [subscription]);
  const isSubscriptionActiveNow = useMemo(() => isSubscriptionActive(subscription), [subscription]);
  const isUserMentorForPlayer = useMemo(() => isCurrentUserMentorForPlayer(), [player]);
  const hideTariffs = useMemo(() => isAcademyHideTariffs(player), [player]);

  useEffect(() => {
    if (playerSubscriptionId && playerSubscriptionId !== subscription?.id) {
      getSubscriptionModel(playerSubscriptionId);
    }
  }, [playerSubscriptionId, subscription?.id]);

  if (
    isUserMentorForPlayer &&
    !hideTariffs &&
    !isPaid &&
    !subscriptionLoading &&
    (trialVersionDiffDays || !isSubscriptionActiveNow) &&
    player?.id
  ) {
    return (
      <Row className={`${CLASS_LIST} ${trialVersionDiffDays ? 'bg-primary' : 'bg-component-third'}`} justify="center">
        <Typography.Text className="my-0 mr-5">
          {trialVersionDiffDays
            ? t('Trial subscription activated', { left: trialVersionDiffDays })
            : `${t('Player without subscription')}. ${t('Some features are not available')}`}
        </Typography.Text>
        <Typography.Link className="t-decoration-none" href={stripeSettingsPageRoute(player?.id)}>
          {t('Choose a tariff')}
        </Typography.Link>
      </Row>
    );
  }

  return null;
};

export const PlayerBanner = communicationSubscription.injector(communicationPlayer.injector(Component));
