import React from 'react';
import { ILineLegend, ILinePalette } from 'common/models/lineChartModel';

interface IProps {
  legends: ILineLegend[];
  palette: ILinePalette;
}

type AllProps = IProps;

export const LineChartLegend = (props: AllProps) => {
  const { legends, palette } = props;

  return (
    <div className="d-flex flex-row flex-justify-center flex-align-center">
      {legends.map(({ title, color }) => (
        <div key={color} className="d-flex flex-row mx-4 flex-align-center">
          <div className="radius-default mr-2" style={{ color: palette.label, height: 8, width: 8, background: color }} />
          <div className="fs-xxxxs uppercase" style={{ color: palette.label }}>
            {title}
          </div>
        </div>
      ))}
    </div>
  );
};
