import { Table } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import Typography from 'antd/es/typography';
import { nameBuilder } from 'common/helpers/name.helper';
import { normalizeDateFirstMouth } from 'common/helpers/normalize.helper';
import { getStripePaymentIncludingDiscount } from 'entities/Subscription/helpers/subscription.helper';
import {
  EPaymentActivitiesTypes,
  ESubscriptionInvoiceType,
  ESubscriptionsEngines,
  ISubscriptionActivityModel
} from 'entities/Subscription/Subscription.models';
import { communicationSubscription, ISubscriptionConnectedProps } from 'entities/Subscription/Subscription.communication';

export interface IPaymentsHistoryProps {
  subscriptionId?: string;
  className?: string;
  isEmpty?: boolean;
}

export interface IPaymentsTableState {
  current?: number;
  pageSize?: number;
  total?: number;
}

type AllProps = IPaymentsHistoryProps & ISubscriptionConnectedProps;

const PaymentsHistoryComponent: React.FC<AllProps> = props => {
  const { search } = useLocation();
  const { push } = useHistory();
  const { t } = useTranslation(['web-payment-and-subscription-page', 'web-payment-statuses']);
  const {
    subscriptionId,
    getSubscriptionActivityCollection,
    clearSubscriptionActivityCollection,
    subscriptionActivityCollection,
    isEmpty,
    className
  } = props;
  const [state, setState] = useState<IPaymentsTableState>(
    queryString.parse(search, { parseNumbers: true }) ?? { current: 1, pageSize: 10 }
  );
  const { data, loading } = subscriptionActivityCollection;
  const { data: datasets, meta } = data ?? {};

  const [dataSource, setDataSource] = useState<typeof datasets>([]);

  useEffect(() => {
    if (datasets) {
      setDataSource(datasets);
      return;
    }
    if (isEmpty) {
      setDataSource([]);
    }
  }, [datasets, loading]);

  useEffect(() => {
    const { pageSize = 10, current = 1 } = state;
    if (isEmpty) {
      return;
    }
    getSubscriptionActivityCollection({
      subscription: subscriptionId,
      limit: pageSize,
      offset: pageSize * (current - 1)
    });
    return () => {
      clearSubscriptionActivityCollection();
    };
  }, [subscriptionId, state]);

  const columns = useMemo(
    () => [
      {
        title: t('Date'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 120,
        ellipsis: true,
        render: normalizeDateFirstMouth
      },
      {
        title: t('Description'),
        dataIndex: 'id',
        key: 'id',
        width: 240,
        ellipsis: true,
        render: (value: string, row: ISubscriptionActivityModel) => {
          const { type, subscription } = row;
          const isGiftCardUsed = type === EPaymentActivitiesTypes.Giftcard;
          const firstName = subscription?.mentorRequest?.player?.firstName;
          const lastName = subscription?.mentorRequest?.player?.lastName;
          const playerName = nameBuilder(firstName, lastName);
          return `${t('Subscription to', { playerName })} ${isGiftCardUsed ? t('by giftcard') : ''}`;
        }
      },
      {
        title: t('Amount'),
        dataIndex: 'amountValue',
        key: 'amountValue',
        width: 80,
        ellipsis: true,
        render: (value: number, row: ISubscriptionActivityModel) => {
          let currency = row.amountCurrency;
          let amount = row?.amountValue ?? '0.00';

          if (row.processing === ESubscriptionsEngines.Stripe && row.invoice?.type === ESubscriptionInvoiceType.Payment) {
            const paymentAmount = getStripePaymentIncludingDiscount(row.invoice.amount, row.invoice.discountAmount);

            if (paymentAmount !== undefined) {
              amount = paymentAmount;
            }
            if (row.invoice.currency) {
              currency = row.invoice.currency;
            }
          }

          return currency ? `${currency} ${amount}` : `-`;
        }
      },
      {
        title: t('Status'),
        dataIndex: 'status',
        key: 'status',
        width: 120,
        ellipsis: true,
        // eslint-disable-next-line react/no-multi-comp
        render: (value: string, activityModel: ISubscriptionActivityModel) => {
          const label = activityModel.isApplied === undefined ? 'Awaiting' : activityModel.isApplied ? 'Successful' : 'Failed';

          return (
            <Typography.Text>
              {t(label)}
              {activityModel.isApplied === undefined &&
                activityModel.processing === ESubscriptionsEngines.Stripe &&
                activityModel?.invoice?.url && (
                  <span>
                    {' '}
                    (
                    <Typography.Link
                      href={activityModel?.invoice?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="color-link"
                    >
                      {t('Pay')}
                    </Typography.Link>
                    )
                  </span>
                )}
              {activityModel.invoice?.urlPdf ? (
                <span>
                  {' '}
                  (
                  <Typography.Link
                    href={activityModel.invoice?.urlPdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="color-link"
                  >
                    {t('Download PDF')}
                  </Typography.Link>
                  )
                </span>
              ) : (
                ''
              )}
            </Typography.Text>
          );
        }
      }
    ],
    []
  );

  useEffect(() => {
    push({
      search: queryString.stringify(state)
    });
  }, [state]);

  const onChange = useCallback(event => {
    setState({
      current: event.current,
      pageSize: event.pageSize,
      total: event.total
    });
  }, []);

  return (
    <Table
      className={className}
      columns={columns}
      onChange={onChange}
      rowKey="id"
      size="small"
      scroll={{
        x: 'max-content'
      }}
      loading={(loading || !Array.isArray(datasets)) && !isEmpty}
      pagination={{
        ...state,
        total: meta?.count === undefined ? state.total : meta.count,
        showSizeChanger: false,
        pageSizeOptions: []
      }}
      dataSource={isEmpty ? [] : dataSource}
    />
  );
};

export const PaymentsHistory = communicationSubscription.injector(PaymentsHistoryComponent);
