import React from 'react';
import { ERoutesPublic } from 'common/models/routesModel';
import { LoadingSpin } from 'common/components/LoadingSpin';

export const RootSuspenseFallback = () => {
  const locationPaths = location?.pathname?.split('/');
  const isPlayerProfilePage = locationPaths?.[1] === ERoutesPublic.PlayerProfile?.split('/')[1];

  return (
    <div className={`root-suspense-fallback ${isPlayerProfilePage ? 'white-style' : ''}`}>
      <LoadingSpin />
    </div>
  );
};
