import React from 'react';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { communicationFranchise, IFranchiseConnectedProps } from 'entities/Franchise/Franchise.communication';
import { FranchiseAcademyListItem } from 'entities/Academy/components/FranchiseList/FranchiseAcademyListItem';
import { FranchiseAcademyListNoData } from 'entities/Academy/components/FranchiseList/FranchiseAcademyNoData';
import {
  IFranchiseRequestModel,
  IFranchiseRequestCollectionFilter,
  IFranchiseRequestCollection
} from 'entities/Franchise/Franchise.models';

interface IComponentProps {
  clearFilter: () => void;
}
type AllProps = IFranchiseConnectedProps & IComponentProps;

class FranchiseAcademyListComponent extends InfiniteList<
  IFranchiseRequestCollection,
  IFranchiseRequestModel,
  AllProps,
  Partial<IFranchiseRequestCollectionFilter>
> {
  loadCollection = async (params: IBaseFilterModel) => {
    const { getFranchiseRequestCollection, filter } = this.props;
    const { location, ...clearedFilter } = filter || {};

    const newFilter: IFranchiseRequestCollectionFilter = {
      mainFirst: true,
      ...clearedFilter,
      ...params,
      ...location
    };

    return getFranchiseRequestCollection(newFilter);
  };

  renderListItem = (item: IFranchiseRequestModel) => {
    return <FranchiseAcademyListItem item={item} />;
  };

  getCollection = () => {
    return this.props.franchiseRequestCollection;
  };
  clearCollection = () => {
    return this.props.clearFranchiseRequestCollection();
  };
  renderNoData = () => {
    const { filter, franchiseRequestCollection, clearFilter } = this.props;
    const { loading, data } = franchiseRequestCollection;

    const showClearFilterButton = !!(filter?.name || filter?.location);

    return !loading && data?.data.length === 0 ? (
      <FranchiseAcademyListNoData showClearFilterButton={showClearFilterButton} clearFilter={clearFilter} />
    ) : (
      <LoadingSpin />
    );
  };
}

export const FranchiseAcademyList = communicationFranchise.injector(FranchiseAcademyListComponent);
