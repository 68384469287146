import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  IAction,
  getSuccessType,
  buildCollectionResponseFormatter,
  buildCollectionPreRequestDataMapper
} from '@axmit/redux-communications';
import { takeEvery, select, put, takeLatest } from 'redux-saga/effects';
import { ESocketActions } from 'common/ws/SocketIo';
import { IApplicationState } from 'app/store/reducers';
import { notificationsTransport } from 'entities/Notification/Notification.transport';
import {
  INotificationModel,
  INotificationCollection,
  INotificationModelParams,
  INotificationCollectionFilter
} from 'entities/Notification/Notification.models';

const namespace = 'notification';

export interface INotificationStoreProps {
  collection: StoreBranch<INotificationCollection, INotificationCollectionFilter>;
}

export interface INotificationConnectedProps {
  notificationModel: StoreBranch<INotificationModel, INotificationModelParams>;
  notificationCollection: StoreBranch<INotificationCollection, INotificationCollectionFilter>;

  getNotificationModel(id: string): void;
  getNotificationCollection(filter?: INotificationCollectionFilter): Promise<void>;

  clearNotificationModel(): void;
  clearNotificationCollection(): void;
}

const notificationsModelAPIProviders = [new APIProvider(EActionsTypes.get, notificationsTransport.get)];

const notificationsCollectionAPIProviders = [
  new APIProvider(
    EActionsTypes.get,
    notificationsTransport.getCollection,
    {
      mapSuccess: buildCollectionResponseFormatter<any, any>(),
      preRequestDataMapper: buildCollectionPreRequestDataMapper<INotificationCollection, INotificationCollectionFilter>()
    },
    takeLatest
  )
];

const branches = [
  new Branch('model', notificationsModelAPIProviders),
  new Branch('collection', notificationsCollectionAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});
function* onNewNotification() {
  yield takeEvery(ESocketActions.NewNotification, function*({ payload: data }: IAction<INotificationModel>) {
    const notificationCollection: INotificationCollection | null = yield select(
      (state: IApplicationState) => state.notification.collection.data
    );
    if (data && notificationCollection) {
      const resultCollection = [data, ...notificationCollection.data];
      const newCollection: INotificationCollection = {
        data: resultCollection,
        meta: { count: notificationCollection.meta.count++ }
      };

      yield getNotificationCollectionSuccessType(newCollection);
    }
  });
}

function* getNotificationCollectionSuccessType(payload: INotificationCollection) {
  yield put({ type: getSuccessType(namespace, 'collection', EActionsTypes.get), payload });
}

export const communicationNotification = buildCommunication<INotificationConnectedProps>(strategy);

communicationNotification.sagas.push(onNewNotification());
