import React from 'react';
import Button from 'antd/es/button';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import Icon from '@ant-design/icons/es/components/Icon';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoDataIcon } from 'app/assets/icons/nodata-magnifier.svg';
import { EAcademyTabKeys } from 'entities/Academy/Academy.models';

interface IComponentProps {
  showClearFilterButton: boolean;
  clearFilter?: (tab: EAcademyTabKeys) => void;
}

const PlayersListNoDataComponent: React.FC<IComponentProps> = props => {
  const { showClearFilterButton, clearFilter } = props;
  const history = useHistory();
  const listType = queryString.parse(history.location.search).tab as any;
  const isMyRequests = listType === EAcademyTabKeys.Favorites;
  const isFollow = listType === EAcademyTabKeys.Incoming;
  const { t } = useTranslation();

  return (
    <Row justify="center" align="middle">
      <Col xs={24} sm={12}>
        <Col span={24}>
          <Icon className="players-nodata__icon" component={NoDataIcon} />
        </Col>
        <Typography.Title level={3}>{t('Players not found')}</Typography.Title>
        <Typography.Text className="t-align-c">
          {showClearFilterButton ? (
            t('empty-search-description')
          ) : (
            <>
              {isFollow && t('empty-follow-description')}
              {isMyRequests && !showClearFilterButton && t('empty-request-description')}
            </>
          )}
        </Typography.Text>
        <Col span={24}>
          {showClearFilterButton && (
            <Button onClick={() => clearFilter && clearFilter(listType)} className="mt-9" size="large" type="primary">
              {t('Clear filter')}
            </Button>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export const PlayersListNoData = PlayersListNoDataComponent;
