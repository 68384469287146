import { ButtonProps } from 'antd';
import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import Typography from 'antd/es/typography';
import { useHistory, useRouteMatch } from 'react-router';
import Row from 'antd/es/row';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { ButtonType } from 'antd/es/button/button';
import { shareLink } from 'common/helpers/share.helper';
import { ERoutesAcademy, ERoutesMentor, ERoutesPublic, sharePlayerProfileRoute } from 'common/models/routesModel';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { ReactComponent as ShareIcon } from 'app/assets/icons/ShareIcon.svg';
import { EUserRole } from 'entities/User/User.models';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { privatePlayerTransport } from 'entities/PrivatePlayer/PrivatePlayer.transport';
import { EPrivatePlayerTabKeys } from 'entities/PrivatePlayer/PrivatePlayer.models';

interface IProps {
  playerId?: string;
  playerAcademyId?: string;
  disabled?: boolean;
  publicity?: boolean;
  hasMentor?: boolean;
  fromList?: boolean;
  block?: boolean;
  type?: ButtonType;
  size?: 'small' | 'default' | 'large';
  className?: string;
}

type AllProps = IProps & IAuthConnectedProps & ButtonProps;

const ShareButtonComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const {
    disabled,
    hasMentor,
    playerId,
    publicity = false,
    fromList = false,
    authUser,
    block,
    playerAcademyId,
    type = 'primary',
    size = 'large',
    className
  } = props;
  const isDesktopMedia: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const isDesktop: boolean = isDesktopMedia || !fromList;
  const isHasMentor = useMemo(() => hasMentor, [hasMentor]);
  const academyId = (match?.params as { academyId?: string })?.academyId;
  const isAcademyWorker = authUser.data?.role === EUserRole.AcademyWorker;
  const authUserAcademyId = authUser?.data?.academyWorker?.academy?.id;
  const isCurrentPlayerAcademy = isAcademyWorker && authUserAcademyId === playerAcademyId;
  const onClickCommon = useCallback(e => {
    e?.stopPropagation();
    e?.preventDefault();
    setVisibleModal(false);
  }, []);
  const isPlayerViewPage = history.location.pathname.includes(ERoutesPublic.PlayerView);

  const isBlock = useMemo(() => (block !== undefined ? block : !fromList && !isDesktopMedia), [block, fromList, isDesktopMedia]);
  const id = useMemo(() => playerId, [playerId]);

  const onCopy = useCallback(
    async (e?: any) => {
      e?.stopPropagation();
      if (id) {
        const shareLinkWithoutToken = sharePlayerProfileRoute(id, true);
        let link = shareLinkWithoutToken;

        if ((isCurrentPlayerAcademy && !isHasMentor) || isPlayerViewPage) {
          try {
            const { token } = await privatePlayerTransport.createShareToken(id);
            link = `${shareLinkWithoutToken}?token=${token}`;
          } catch (err) {
            console.log(err);
          }
        }
        await shareLink(link);
      }
    },
    [id, isPlayerViewPage]
  );

  return (
    <>
      <Button
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e?.stopPropagation();
          e?.preventDefault();
          isCurrentPlayerAcademy && !publicity ? setVisibleModal(true) : onCopy();
        }}
        block={isBlock}
        disabled={disabled}
        type={type}
        size={size}
        className={className}
      >
        <Row align="middle" justify="center" wrap={false}>
          <ShareIcon /> {isDesktop && <div className="ml-3">{t('Share')}</div>}
        </Row>
      </Button>
      <Modal title={t('The player is hidden')} centered onCancel={onClickCommon} visible={visibleModal} footer={null}>
        <Typography.Title level={5}>{t('The player is hidden, the data will be anonymized')}</Typography.Title>
        <Row justify="space-between" className="mt-5">
          <Button
            type="default"
            size="large"
            onClick={e => {
              onCopy(e);
              setVisibleModal(false);
            }}
          >
            {t('Share')}
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              onClickCommon(e);
              history.push(
                `${ERoutesAcademy.AcademyView}/${academyId}${ERoutesMentor.Players}/${id}/${EPrivatePlayerTabKeys.Info}`
              );
            }}
          >
            {t('Edit')}
          </Button>
        </Row>
      </Modal>
    </>
  );
};

export const ShareButton = communicationAuth.injector(ShareButtonComponent);
