import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  IPrivatePlayerCreateParams,
  IPrivatePlayerUpdateParams,
  IPrivatePlayerCollectionFilter,
  ITransferPlayerParams,
  IMagicTokenModel,
  IPlayerFileCollection
} from 'entities/PrivatePlayer/PrivatePlayer.models';
import { IPlayerCollection, IPlayerModel } from 'entities/Player/Player.models';

const basePath = '/trainers/players';

class PrivatePlayerTransport extends BaseHttpTransport<
  AxiosStatic,
  IPlayerModel,
  IPlayerCollection,
  IPrivatePlayerCreateParams,
  IPrivatePlayerUpdateParams,
  IPrivatePlayerCollectionFilter
> {
  public createShareToken = (id: string): Promise<IMagicTokenModel> => axios.post(`${basePath}/${id}/magic-token`);
  public getPlayerFiles = (id: string): Promise<IPlayerFileCollection> => axios.get(`${basePath}/${id}/files`);
  public transferPrivatePlayer = (params: ITransferPlayerParams | undefined): Promise<IPlayerModel> => {
    const { id, ...body } = params || {};

    return axios.put(`${basePath}/${id}/academy`, body);
  };
}

export const privatePlayerTransport = new PrivatePlayerTransport(basePath, axios);
