import React from 'react';
import { LayoutContent } from 'common/components/Layouts/ContentLayout';
import { PlayersCompare } from 'entities/Player/components/PlayersCompare/PlayersCompare';

const Component: React.FC = () => {
  return (
    <LayoutContent className="mentor-players-compare-page">
      <PlayersCompare />
    </LayoutContent>
  );
};

export const MentorPlayersComparePage = Component;
