import React from 'react';
import Modal from 'antd/es/modal/Modal';
import { useTranslation } from 'react-i18next';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { communicationPrivatePlayer, IPrivatePlayerConnectedProps } from 'entities/PrivatePlayer/PrivatePlayer.communication';
import { PrivatePlayerForm } from 'entities/PrivatePlayer/components/PrivatePlayerForm';

interface IProps {
  academyId: string;
}

type AllProps = IUIConnectedProps & IPrivatePlayerConnectedProps & IProps;

const AcademyPlayerCreateModalComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { UIAcademyPlayerCreateModal, closeUIAcademyPlayerCreateModal, clearPrivatePlayerModel, academyId } = props;
  const isVisible = UIAcademyPlayerCreateModal.data?.isVisible;

  const closeModal = () => {
    clearPrivatePlayerModel();
    closeUIAcademyPlayerCreateModal();
  };

  return (
    <Modal
      className="create-private-player-modal"
      destroyOnClose
      maskClosable={false}
      bodyStyle={{ border: 'none' }}
      width={450}
      visible={isVisible}
      onCancel={closeModal}
      footer={false}
      title={t('Player creating')}
    >
      <PrivatePlayerForm academyId={academyId} />
    </Modal>
  );
};

export const AcademyPrivatePlayerCreateModal = communicationPrivatePlayer.injector(
  communicationUI.injector(AcademyPlayerCreateModalComponent)
);
