import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import debounce from 'lodash.debounce';
import { ItemsNotFound } from 'common/components/Text/ItemsNotFound';

interface IComponentProps<Model> {
  onChange?: (item?: Model) => void;
  placeholder?: string;
  disabled?: boolean;
  mode?: 'tags' | 'multiple';
  isTags?: boolean;
  size?: 'large' | 'middle' | 'small';
}

type AllProps<Model> = SelectProps<any> & IComponentProps<Model>;

interface IComponentState<Model> {
  data: Model[];
  value: Model | Model[] | undefined;
}

export abstract class AbstractSearchableSelector<Model extends { id: string; name?: string }, IProps> extends React.PureComponent<
  AllProps<Model> & IProps
> {
  state: IComponentState<Model> = {
    data: [],
    value: this.props.value
  };

  readonly searchDebounced: any;

  mounted: boolean = false;

  protected constructor(props: AllProps<Model> & IProps) {
    super(props);
    this.searchDebounced = debounce(this.handleSearch, 200);
  }

  static getDerivedStateFromProps<Model, IProps>(props: AllProps<Model> & IProps, state: IComponentState<Model>) {
    if (Array.isArray(props.value)) {
      if (!props.value?.length && state.value) {
        return { value: undefined };
      }

      if (props.value.length && (!state.value || (Array.isArray(state.value) && !state.value?.length))) {
        return { value: props.value };
      }
    } else {
      if (!props.value?.id && state.value) {
        return { value: undefined };
      }

      if (props.value && !state.value) {
        return { value: props.value };
      }
    }

    return null;
  }

  async componentDidMount() {
    this.mounted = true;
    await this.getCollection();
  }

  updateState(data: Model[]) {
    if (this.mounted) {
      this.setState({ data });
    }
  }

  getCollection = async () => {
    const { data } = await this.loadData();
    this.updateState(data);
  };

  handleSearch = async (value?: string) => {
    const { data } = await this.loadData(value);

    this.updateState(data);
  };

  handleChange = async (data: string | number | string[] | number[]) => {
    const { onChange, mode } = this.props;
    let value: any;

    if (mode && Array.isArray(data)) {
      value = (data as any[]).map((id: string | number) => this.state.data.find(el => el.id === id) || { name: id });
      this.handleSearch(undefined);
    } else {
      value = this.state.data.find(el => el.id === data);
    }

    this.setState({ value }, () => onChange && onChange(value));
  };

  get value() {
    const { value } = this.state;

    return Array.isArray(value)
      ? value.reduce((acc: string[], el: Model) => {
          const itemValue = el?.id || el?.name;
          if (itemValue) {
            return [...acc, itemValue];
          }
          return acc;
        }, [])
      : value?.id;
  }

  get data() {
    const { value, data } = this.state;

    if (value) {
      if (Array.isArray(value)) {
        const nonExistsValues = value.reduce((acc: Model[], valueEl: Model) => {
          const exist = data.find(el => el?.id === valueEl?.id);

          if (!exist && valueEl) {
            return [...acc, valueEl];
          }

          return acc;
        }, []);

        if (nonExistsValues.length) {
          data.unshift(...nonExistsValues);
        }
      } else {
        const exist = data.find(el => el.id === value?.id);
        if (!exist && value) {
          data.unshift(value);
        }
      }
    }

    return data;
  }

  render() {
    const { placeholder, disabled, mode, size, className } = this.props;

    const options = this.data
      .filter(item => item.id || item.name)
      .map(option => (
        <Select.Option key={option.id || option.name} value={option.id || (option.name as string)}>
          {this.getItemLabel(option)}
        </Select.Option>
      ));

    return this.data ? (
      <Select
        size={size}
        mode={mode}
        allowClear
        className={`${className} width-full`}
        placeholder={placeholder}
        disabled={disabled}
        showSearch
        value={this.value}
        defaultActiveFirstOption={false}
        showArrow={true}
        filterOption={false}
        onFocus={() => this.searchDebounced('')}
        onSearch={this.searchDebounced}
        onChange={this.handleChange}
        notFoundContent={<ItemsNotFound />}
        getPopupContainer={triggerNode => triggerNode as HTMLElement}
      >
        {options}
      </Select>
    ) : (
      <div />
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  abstract getItemLabel: (item: Model) => string;
  abstract loadData: (value?: string) => Promise<{ data: Model[] }>;
}
