import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  getSuccessType
} from '@axmit/redux-communications';
import message from 'antd/es/message';
import { call, put } from 'redux-saga/effects';
import { translateToasts } from 'common/helpers/translate.helper';
import { changeI18nLanguage } from 'common/helpers/i18n';
import { multilineMessage } from 'common/components/MultilineMessage';
import { STRIPE_WAIT_EXPLANATION_KEY } from 'common/const/i18n.const';
import {
  EUserSuccessMessage,
  IUserCreateParams,
  IUserModel,
  TUserParamsModel,
  IUserUpdatePasswordParams,
  IUserUpdateModelForm,
  IUserWithPlayerSignUpParams,
  IUserWithPlayerSignUpModel
} from 'entities/User/User.models';
import { userTransport } from 'entities/User/User.transport';
import { updateAuthUser } from 'entities/Auth/Auth.communication';

const namespace = 'user';

export interface IUserConnectedProps {
  userModel: StoreBranch<IUserModel, TUserParamsModel>;
  userCheckTokenModel: StoreBranch<null>;
  userPasswordModel: StoreBranch<null>;
  userSignUpWithPlayer: StoreBranch<IUserModel, IUserWithPlayerSignUpParams>;
  userCheckTokenSignUpWithPlayer: StoreBranch<IUserWithPlayerSignUpModel>;

  getUserModel(id: string): void;
  addUserModel(params: IUserCreateParams): void;
  addUserSignUpWithPlayer(params: IUserWithPlayerSignUpParams): IUserModel;
  updateUserModel(params: IUserUpdateModelForm): void;

  updatePasswordUserPasswordModel(params: IUserUpdatePasswordParams): void;

  addUserCheckTokenModel(token: string): void;
  addUserCheckTokenSignUpWithPlayer(token: string): void;

  clearUserModel(): void;
  clearUserSignUpWithPlayer(): void;
  clearUserCheckTokenModel(): void;
  clearUserCheckTokenSignUpWithPlayer(): void;
}

const UserModelAPIProviders = [
  new APIProvider(EActionsTypes.get, userTransport.get),

  new APIProvider(EActionsTypes.update, userTransport.update, {
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    },
    onSuccess: function*(response, props, branchState, fullState: any) {
      if (props && 'stripePaymentMethodToken' in props) {
        multilineMessage(['Request to change payment data successfully sent', STRIPE_WAIT_EXPLANATION_KEY]);
      } else {
        const { id } = response;
        const currentUserId = fullState.auth.user.data.id;
        if (id === currentUserId) {
          yield updateAuthUser(response);
        }
        // Direct change lang need to show toast on correct lang
        yield call(changeI18nLanguage, response.lang);

        setTimeout(() => {
          message.success(translateToasts(EUserSuccessMessage.Update));
        }, 1000);
      }
    }
  }),

  new APIProvider(EActionsTypes.add, userTransport.add, {
    onSuccess: function() {
      message.success(translateToasts(EUserSuccessMessage.Add));
    }
  })
];

const UserCheckTokenModelAPIProviders = [new APIProvider(EActionsTypes.add, userTransport.checkToken)];
const CheckTokenSignUpWithPlayerAPIProviders = [new APIProvider(EActionsTypes.add, userTransport.checkTokenSignUpWithPlayer)];

const UserSignUpWithPlayerAPIProviders = [
  new APIProvider(EActionsTypes.add, userTransport.signUpWithPlayer, {
    throwOnFail: true,
    onSuccess: function*(res: IUserModel) {
      yield setUserModel(res);
    }
  })
];

const UserPasswordModelAPIProviders = [
  new APIProvider('updatePassword', userTransport.updatePassword, {
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    },
    onSuccess: function() {
      message.success(translateToasts(EUserSuccessMessage.EditPassword));
    }
  })
];

const branches = [
  new Branch('model', UserModelAPIProviders),
  new Branch('signUpWithPlayer', UserSignUpWithPlayerAPIProviders),
  new Branch('checkTokenModel', UserCheckTokenModelAPIProviders),
  new Branch('checkTokenSignUpWithPlayer', CheckTokenSignUpWithPlayerAPIProviders),
  new Branch('passwordModel', UserPasswordModelAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export function* setUserModel(response: IUserModel) {
  yield put({ type: getSuccessType(namespace, 'model', EActionsTypes.get), payload: response });
}

export const communicationUser = buildCommunication<IUserConnectedProps>(strategy);
