import React from 'react';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import { communicationPublicPlayer, IPublicPlayerConnectedProps } from 'entities/PublicPlayer/PublicPlayer.communication';
import {
  IPublicPlayerModel,
  IPublicPlayerCollection,
  IPublicPlayerCollectionFilter
} from 'entities/PublicPlayer/PublicPlayer.models';
import { PublicPlayersListItem } from 'entities/PublicPlayer/components/List/PublicPlayersListItem';
import { PublicPlayersListNoData } from 'entities/PublicPlayer/components/List/PublicPlayersListNoData';
import { PublicPlayersListSkeleton } from 'entities/PublicPlayer/components/List/PublicPlayersListSkeleton';

interface IComponentProps {
  onItemClick?: (itemModel: IPublicPlayerModel) => void;
  defaultAgeGroup?: string;
  clearFilter?: () => void;
}

type AllProps = IComponentProps & IPublicPlayerConnectedProps;

class PublicPlayerListComponent extends InfiniteList<
  IPublicPlayerCollection,
  IPublicPlayerModel,
  AllProps,
  Partial<IPublicPlayerCollectionFilter>
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { filter, getPublicPlayerCollection } = this.props;
    const { location, ageGroups, visibleOnly, name, ...clearedFilter } = filter || {};

    const filterParams: IPublicPlayerCollectionFilter = {
      ...clearedFilter,
      ...params,
      ...location,
      visibleOnly: visibleOnly ?? false
    };

    if (name) {
      filterParams.name = name;
    }

    if (ageGroups) {
      filterParams.ageGroups = ageGroups;
    }

    const clearedFilterParams = Object.keys(filterParams)?.reduce((acc, key) => {
      const value: any = (filterParams as any)[key];
      return value !== undefined ? { ...acc, [key]: value } : acc;
    }, {});

    return getPublicPlayerCollection(clearedFilterParams);
  };

  renderListItem = (item: IPublicPlayerModel) => {
    return <PublicPlayersListItem item={item} />;
  };

  renderNoData = () => {
    const { publicPlayerCollection, grid } = this.props;
    const { loading, data } = publicPlayerCollection;

    const isEmpty = !loading && data?.data.length === 0;

    return isEmpty ? <PublicPlayersListNoData /> : <PublicPlayersListSkeleton grid={grid} />;
  };

  getCollection = () => {
    const { publicPlayerCollection } = this.props;

    return publicPlayerCollection;
  };

  clearCollection = () => {
    const { clearPublicPlayerCollection } = this.props;

    return clearPublicPlayerCollection();
  };
}

export const PublicPlayersList = communicationPublicPlayer.injector(PublicPlayerListComponent);
