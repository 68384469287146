import React, { useCallback } from 'react';
import Modal from 'antd/es/modal';
import { useTranslation } from 'react-i18next';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { IPrivatePlayerConnectedProps, communicationPrivatePlayer } from 'entities/PrivatePlayer/PrivatePlayer.communication';
import { PlayerTransferModalBody } from 'entities/PrivatePlayer/components/TransferModal/PlayerTransferModalBody';
import { ITransferModalForm, ITransferPlayerParams } from 'entities/PrivatePlayer/PrivatePlayer.models';

type AllProps = IUIConnectedProps & IPrivatePlayerConnectedProps;

const FormName = 'transfer-player-form';

const OkButtonProps: { htmlType: 'submit'; key: 'submit'; form: string } = {
  htmlType: 'submit',
  key: 'submit',
  form: FormName
};

const PlayerTransferModalComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { closeUITransferPlayerModal, UITransferPlayerModal, privatePlayerModel, transferPrivatePlayerModel } = props;
  const { loading } = privatePlayerModel;
  const isVisible = UITransferPlayerModal.data?.isVisible;
  const playerId = UITransferPlayerModal.data?.playerId;

  const onCloseModal = useCallback(() => {
    closeUITransferPlayerModal();
  }, [closeUITransferPlayerModal]);

  const onFinish = (values: ITransferModalForm) => {
    const { academy, teams, trainers } = values;

    if (playerId) {
      const body: ITransferPlayerParams = {
        id: playerId,
        academy
      };

      if (teams?.length) {
        body.teams = teams;
      }

      if (trainers?.length) {
        body.trainers = trainers;
      }

      transferPrivatePlayerModel(body);
    }
  };

  return (
    <Modal
      visible={isVisible}
      getContainer={false}
      width={500}
      title={t('Player transfer to another academy')}
      onCancel={onCloseModal}
      centered
      destroyOnClose
      confirmLoading={loading}
      okButtonProps={OkButtonProps}
      okText={t('Save')}
      cancelText={t('Cancel')}
    >
      <PlayerTransferModalBody onFinish={onFinish} formName={FormName} />
    </Modal>
  );
};

export const PlayerTransferModal = communicationPrivatePlayer.injector(communicationUI.injector(PlayerTransferModalComponent));
