import React from 'react';
import { useParams } from 'react-router';
import { StoreBranch } from '@axmit/redux-communications';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { IPrivatePlayerParamsModel } from 'entities/PrivatePlayer/PrivatePlayer.models';
import { communicationPrivatePlayer, IPrivatePlayerConnectedProps } from 'entities/PrivatePlayer/PrivatePlayer.communication';
import { IPlayerModel } from 'entities/Player/Player.models';

export interface IPrivatePlayerProviderContext {
  player?: StoreBranch<IPlayerModel, IPrivatePlayerParamsModel> | null;
}

export const PrivatePlayerProviderContext = React.createContext<IPrivatePlayerProviderContext>({});

type AllProps = IPrivatePlayerConnectedProps;

const PrivatePlayerProviderComponent: React.FC<AllProps> = props => {
  const { children, getPrivatePlayerModel, privatePlayerModel, clearPrivatePlayerModel } = props;
  const { id: playerId } = useParams();

  React.useEffect(() => {
    getPrivatePlayerModel(playerId);
    return () => clearPrivatePlayerModel();
  }, [getPrivatePlayerModel, clearPrivatePlayerModel]);

  if (!privatePlayerModel.data) {
    return <LoadingSpin />;
  }

  return (
    <PrivatePlayerProviderContext.Provider value={{ player: privatePlayerModel }}>
      {children}
    </PrivatePlayerProviderContext.Provider>
  );
};

export const PrivatePlayerProvider = communicationPrivatePlayer.injector(PrivatePlayerProviderComponent);
