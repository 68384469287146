import React from 'react';
import i18n from 'i18next';

interface IComponentProps {
  label?: string;
}

type AllProps = IComponentProps;

class ItemsNotFoundComponent extends React.PureComponent<AllProps> {
  render() {
    const { label } = this.props;

    return <span className="t-align-c d-block">{label || i18n.t('No Data')}</span>;
  }
}

export const ItemsNotFound = ItemsNotFoundComponent;
