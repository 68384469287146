import React, { useMemo } from 'react';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import AcademyRequestImg from 'app/assets/icons/academy-request.svg';
import { ESubscriptionsEngines } from 'entities/Subscription/Subscription.models';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import { SubscriptionButton } from 'entities/Subscription/components/SubscriptionButton';
import { isAcademyHideTariffs } from 'entities/Subscription/helpers/subscription.helper';

type AllProps = IPlayerConnectedProps;

const MentorRequestToAcademyWithoutSubComponent: React.FC<AllProps> = props => {
  const { playerModel } = props;
  const { t } = useTranslation();
  const { data: playerData } = playerModel || {};
  const mentorRequestId = playerData?.mentorRequest?.id;
  const mentorRequestSubscriptionEngine = playerData?.mentorRequest?.subscriptionEngine;
  const subscription = playerData?.mentorRequest?.subscription;
  const modalTitle = t('Player without subscription');
  const { isPaid, id } = playerData || {};
  const hideTariffs = useMemo(() => isAcademyHideTariffs(playerData), [playerData]);

  return (
    <>
      <Row className="mt-9" justify="center">
        <img alt={modalTitle} height={128} width={128} src={AcademyRequestImg} className="cursor-pointer" />
      </Row>
      <Typography.Title level={3} className="t-align-c mt-5 mb-5" title={modalTitle}>
        {modalTitle}
      </Typography.Title>
      <Typography.Text className="d-block t-align-c" title={t('Modal subscription description_1')}>
        {t('Modal subscription description_1')}
      </Typography.Text>
      <Typography.Text className="mt-5 d-block t-align-c" title={t('Modal subscription description_2')}>
        {t('Modal subscription description_2')}
      </Typography.Text>
      <Row className="mt-6" justify="center">
        <SubscriptionButton
          playerId={id}
          size="large"
          hideUnsubscribeBtn
          mentorRequestId={mentorRequestId}
          subscription={subscription}
          hideTariffs={hideTariffs}
          isPaid={isPaid}
          mentorRequestSubscriptionEngine={mentorRequestSubscriptionEngine as ESubscriptionsEngines}
        />
      </Row>
    </>
  );
};

export const MentorRequestToAcademyWithoutSub = communicationPlayer.injector(MentorRequestToAcademyWithoutSubComponent);
