import React, { useMemo, useState, useEffect } from 'react';
import Row from 'antd/es/row';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import { useTranslation } from 'react-i18next';
import { useFormMapper } from '@axmit/antd4-helpers';
import { VideoPrivate } from 'common/components/Video/VideoPrivate';
import { requiredField, maxLengthRule } from 'common/helpers/filed-rules';
import { IAddPlayerVideoParams } from 'entities/Player/Player.models';
import { IPlayerConnectedProps, communicationPlayer } from 'entities/Player/Player.communication';
import { maxVideoTitleLength, maxVideoDescriptionLength } from 'entities/Video/Video.models';

interface IComponentProps {
  id: string | undefined;
  uploadData: Partial<IAddPlayerVideoParams>;
  onCancel: () => void;
  isDesktop: boolean;
}

interface IFormValues {
  title: string;
  description: string;
}

type AllProps = IComponentProps & IPlayerConnectedProps;

const playerOptions = (isMobile: boolean) => ({
  width: isMobile ? '100%' : 344,
  height: 224
});

const formName = 'edit-player-video';

const PlayerVideosModalBodyEditComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const [isVideoLoading, setIsVideoLoading] = useState<boolean>(false);
  const {
    id,
    onCancel,
    uploadData,
    isDesktop,
    playerModel,
    addPlayerVideosModel,
    playerVideosModel,
    playerVideosCollection,
    getPlayerVideosCollection,
    updatePlayerVideosModel,
    deletePlayerVideosModel,
    clearPlayerVideosModel
  } = props;
  const { loading, errors, params } = playerVideosModel;
  const playerId = playerModel?.data?.id;

  const data = useMemo(() => {
    if (id) {
      const currentVideo = playerVideosCollection.data?.data?.find(videoEl => videoEl.id === id);
      const preview = currentVideo?.youtubeId ? currentVideo.preview : currentVideo?.video?.image?.id;
      return { ...currentVideo, video: currentVideo?.video?.id, preview };
    }
    return uploadData;
  }, []) as IAddPlayerVideoParams;

  if (!data?.video && !data?.youtubeId) {
    onCancel();
    return null;
  }

  const { video, youtubeId, preview } = data;
  const { fields } = useFormMapper(['title', 'description'], data, params, errors);

  useEffect(() => {
    if (isVideoLoading && !loading) {
      if (errors) {
        setIsVideoLoading(false);
      } else {
        onCancel();
        if (playerId) {
          getPlayerVideosCollection({ playerId });
        }
      }
    }
  }, [loading]);

  useEffect(() => {
    return () => clearPlayerVideosModel();
  }, []);

  const onFinish = (values: IFormValues) => {
    if (playerId) {
      if (id) {
        const body = {
          playerId,
          id,
          ...values
        };
        updatePlayerVideosModel(body);
      } else {
        const videoData = video ? { video } : { youtubeId, preview };
        const body = {
          playerId,
          ...videoData,
          ...values
        } as IAddPlayerVideoParams;
        addPlayerVideosModel(body);
      }
      setIsVideoLoading(true);
    }
  };

  const onRemove = () => {
    if (playerId && id) {
      deletePlayerVideosModel({ playerId, id });
      setIsVideoLoading(true);
    }
  };

  return (
    <div className="players__videos-tab__element__edit width-full">
      <VideoPrivate
        preview={preview}
        width={playerOptions(!isDesktop).width}
        height={playerOptions(!isDesktop).height}
        videoId={video}
        youtubeId={youtubeId}
        previewClass="radius-default"
      />
      <Form onFinish={onFinish} name={formName} fields={fields} layout="vertical" className="my-5">
        <Form.Item rules={[requiredField, maxLengthRule(maxVideoTitleLength)]} name="title" label={t('Title')} className="mb-5">
          <Input placeholder={t('Video name')} maxLength={maxVideoTitleLength} disabled={loading} size="large" />
        </Form.Item>
        <Form.Item rules={[requiredField]} name="description" label={t('Description')} className="mb-0">
          <Input.TextArea
            maxLength={maxVideoDescriptionLength}
            disabled={loading}
            autoSize={{ minRows: 3 }}
            placeholder={t('What to look for (limit 140 symbols)')}
          />
        </Form.Item>
      </Form>
      <Row justify="end" className={`players__videos-tab__element__edit__buttons ${isDesktop ? '' : 'mobile'}`}>
        {id && (
          <Popconfirm
            title={t('Videos cannot be recovered')}
            cancelText={t('Cancel')}
            onConfirm={onRemove}
            okText={t('Remove')}
            okButtonProps={{ type: 'text', danger: true, size: 'middle' }}
            cancelButtonProps={{ size: 'middle' }}
          >
            <Button type="text" disabled={loading} danger className={isDesktop ? 'mr-5' : ''}>
              {t('Remove')}
            </Button>
          </Popconfirm>
        )}
        <>
          <Button block={!isDesktop} className={isDesktop ? 'mr-5' : 'mb-3'} onClick={onCancel} disabled={loading}>
            {t('Cancel')}
          </Button>
          <Button
            block={!isDesktop}
            className={isDesktop ? '' : 'mb-3'}
            form={formName}
            htmlType="submit"
            type="primary"
            disabled={loading}
          >
            {t('Save')}
          </Button>
        </>
      </Row>
    </div>
  );
};

export const PlayerVideosModalBodyEdit = communicationPlayer.injector(PlayerVideosModalBodyEditComponent);
