import React, { useMemo } from 'react';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { nameBuilder } from 'common/helpers/name.helper';
import { TeamsBadge } from 'common/components/TeamsBadge';
import { ITrainerAcademyRequestModel, ETrainerAcademyRequestStatus, ETrainerActionTypes } from 'entities/Trainer/Trainer.models';
import { IUserPureModel } from 'entities/User/User.models';
import { AbortTrainerRequest } from 'entities/Trainer/components/ActionButtons/AbortRequest';
import { UnlinkTrainerFromTeam } from 'entities/Trainer/components/ActionButtons/UnlinkTrainerFromTeam';

interface IComponentProps {
  trainerAcademyRequest?: Partial<ITrainerAcademyRequestModel>;
  trainer?: IUserPureModel;
  actionType?: ETrainerActionTypes;
  teamId?: string;
}

type AllProps = IComponentProps;

const avatarWidth = 56;
const avatarHeight = 56;

const TrainerCardComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { trainerAcademyRequest, trainer, actionType = ETrainerActionTypes.AbortRequest, teamId } = props;
  const { firstName, lastName, image, email } = trainer || {};
  const { playerCount, teams } = trainerAcademyRequest || {};
  const formattedName = nameBuilder(firstName, lastName, email);
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const imgId = image?.id;
  const trainerAcademyRequestStatus = trainerAcademyRequest?.status;
  const isTrainerRequestActive =
    !trainerAcademyRequestStatus || trainerAcademyRequestStatus === ETrainerAcademyRequestStatus.Active;

  const ActionButton = useMemo(() => {
    switch (actionType) {
      case ETrainerActionTypes.UnlinkFromTeam:
        return <UnlinkTrainerFromTeam trainerId={trainer?.id} teamId={teamId} />;
      case ETrainerActionTypes.AbortRequest:
        return <AbortTrainerRequest trainerAcademyRequest={trainerAcademyRequest} />;
      default:
        return null;
    }
  }, [actionType, trainer, teamId, trainerAcademyRequest]);

  return (
    <Card hoverable bordered={false} className={`trainer-card ${isTrainerRequestActive ? '' : 'cursor-not-allowed'}`}>
      <Row className="flex-noWrap" align={isDesktop ? 'middle' : 'top'}>
        <ImagePrivate
          preview={false}
          imgId={imgId}
          height={avatarHeight}
          width={avatarWidth}
          alt="trainer img"
          className="border-circle mr-5"
        />
        <Row justify="space-between" align="middle" className="width-full">
          <Col xs={24} lg={15}>
            <div className="trainer-card__body">
              <Row justify="center" className="flex-column">
                <div
                  className="ant-typography-ellipsis ant-typography-ellipsis-multiple-line fs-xxs font-weight-7 mb-2"
                  title={formattedName}
                >
                  {formattedName}
                </div>
                <TeamsBadge teamLinks={teams} />
                {isTrainerRequestActive && playerCount !== undefined && (
                  <div
                    className="ant-typography-ellipsis ant-typography-ellipsis-multiple-line fs-xxxs font-weight-5 mt-2"
                    title={`${t('Players')}: ${playerCount}`}
                  >{`${t('Players')}: ${playerCount}`}</div>
                )}
              </Row>
            </div>
          </Col>
          <Col xs={24} lg={7}>
            {ActionButton}
          </Col>
        </Row>
      </Row>
    </Card>
  );
};

export const TrainerCard = TrainerCardComponent;
