import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { ITariffModel, ITariffCollectionFilter, ITariffCollection } from 'entities/Tariff/Tariff.models';

const basePath = '/tariffs';

class TariffTransport extends BaseHttpTransport<
  AxiosStatic,
  ITariffModel,
  ITariffCollection,
  null,
  void,
  ITariffCollectionFilter
> {}

export const tariffTransport = new TariffTransport(basePath, axios);
