import i18n from 'i18next';
import Row from 'antd/es/row';
import React from 'react';
import moment from 'moment';
import { IBasePathParams } from 'common/models/requestModels';
import DefaultAvatar from 'app/assets/images/pp-avatar-empty.svg';
import { EResultTypes } from 'entities/PlayerTest/PlayerTest.models';
import { getImageUrl } from 'entities/Image/Image.helper';
import { EPlayerAgeGroup, IPlayerItemModel } from 'entities/Player/Player.models';
import { ITrainerModel } from 'entities/Trainer/Trainer.models';
import { ITeamModel } from 'entities/Team/Team.models';
import { IAcademySearchModel } from 'entities/Academy/Academy.models';
import { EUserRole, TUserRole } from 'entities/User/User.models';
import { EImageSize } from 'entities/Image/Image.models';

export interface ITestModel extends Components.Schemas.TestDto {}
export interface ITestPureModel extends Components.Schemas.PlayerToTestPureTestDto {}
export interface ITestCollection extends Components.Schemas.TestCollectionDto {}

export interface ITestCollectionFilter extends Paths.TestControllerList.QueryParameters {}
export interface ITestUpdateData extends Components.Schemas.UpdateTestDto, ITestUpdateParams {}
export interface ITestUpdateParams extends Paths.TestControllerUpdate.PathParameters {}

export interface ITestBaseResultModel extends Components.Schemas.BaseResultDto {}

export interface ITestResultsHistoryPlayerTestModel extends Components.Schemas.TestResultsHistoryPlayerTestDto {}
// export interface ITestResultsHistoryTeamModel extends Components.Schemas.TestResultsHistoryTeamDto {}
// export interface ITestResultsHistoryTrainerItemModel extends Components.Schemas.TestResultsHistoryTrainerDto {}
export interface ITestResultsHistoryPlayerTestResultModel extends Components.Schemas.TestResultsHistoryPlayerTestResultDto {}

export interface ITestResultsHistoryModel extends Components.Schemas.TestResultsHistoryItemDto {}
export interface ITestResultsHistoryCollection extends Components.Schemas.TestResultsHistoryDto {}
export interface ITestResultsHistoryCollectionFilter
  extends Paths.TestControllerGetTestResultsHistory.QueryParameters,
    Paths.TestControllerGetTestResultsHistory.PathParameters {
  testTag?: TTestTag;
  pageIndex?: number;
  tags?: string[];
  trainer?: Partial<ITrainerModel>;
  team?: Partial<ITeamModel>;
  academy?: Partial<IAcademySearchModel>;
  teamListFilter?: Partial<ITestTeamHistoryCollectionFilter>;
  teamId?: string;
  fromPlayerAcademy?: boolean;
  playerAcademyId?: string;
}

export interface ITestTeamHistoryModel extends Components.Schemas.TeamTestsHistoryItemDto {}
export interface ITestTeamHistoryCollection extends Components.Schemas.TeamTestsHistoryDto {}
export interface ITestTeamHistoryCollectionFilter
  extends Paths.TeamControllerGetTeamTestsHistory.QueryParameters,
    Paths.TeamControllerGetTeamTestsHistory.PathParameters {}

export type TTestResultHistoryOrderField = Paths.TestControllerGetTestResultsHistory.Parameters.OrderField;

// todo check after JS-4569
export interface ITestResultItemModel {
  units?: EResultTypes;
  value: number | null;
  isBest: boolean;
}

export type TTestParamsModel = ITestUpdateData | IBasePathParams;

export enum ETestStatus {
  New = 'new',
  AI = 'aIModeration',
  Moderation = 'moderation',
  Accept = 'accept',
  Reject = 'reject'
}

export enum ETestRanging {
  SB = 'SB',
  LB = 'LB'
}
export enum ETestListTabs {
  Trainers = 'Trainers',
  Players = 'Players'
}

export enum ETestListTabKeys {
  JuniStat = 'junistat',
  RFSList = 'rfs'
}
export enum ETestListTabKeysText {
  JuniStat = 'JuniStat',
  RFSList = 'RFS'
}

interface IPlayerTestRejectReasonText {
  [key: number]: string;
}

export const initTestResultFilter: Partial<ITestResultsHistoryCollectionFilter> = {
  pageIndex: 1,
  id: undefined,
  testTag: undefined,
  academies: undefined,
  ageGroups: undefined,
  from: moment()
    .subtract(6, 'month')
    .startOf('day')
    .toISOString(),
  to: moment()
    .endOf('day')
    .toISOString(),
  hasTeam: undefined,
  hasTrainer: undefined,
  name: undefined,
  orderDirection: 'DESC',
  orderField: 'date',
  teams: undefined,
  trainers: undefined,
  tags: undefined,
  trainer: undefined,
  team: undefined,
  teamId: undefined,
  academy: undefined,
  teamListFilter: undefined,
  years: undefined,
  uploaderType: undefined,
  fromPlayerAcademy: true,
  onlyMy: undefined
};

export const EPlayerTestRejectReasonText: IPlayerTestRejectReasonText = {
  1: 'Bad video quality',
  2: 'Poor video lighting',
  3: 'Video is too long',
  4: 'Inappropriate video content',
  5: 'AI was unable to rate the video',
  6: 'Exceeded number of execution errors',
  7: 'AI not support test',
  8: 'Error while downloading video',
  9: 'Execution of the exercise is wrong',
  10: 'The camera must be stationary',
  11: 'The player must be fully framed throughout the video',
  12: 'Video upload error',
  13: 'Incorrect job execution',
  14: 'Failed to find chips/cones on video',
  15: 'Camera installed incorrectly'
};

export enum EComplexity {
  Low = 'low',
  Medium = 'medium',
  Pro = 'pro'
}

export enum ETestResultFilter {
  PlayerName = 'playerName',
  AgeGroup = 'ageGroup',
  BirthYear = 'birthYear',
  TestDateRange = 'testDateRange',
  Academy = 'academy',
  MyAcademy = 'myAcademy',
  ShowAll = 'showAll',
  Coach = 'coach',
  Team = 'team',
  UploaderType = 'uploaderType',
  Download = 'download'
}

export enum ETestUploaderType {
  Self = 'player',
  AtTheAcademy = 'trainer'
}

export enum ETestUploaderTypeText {
  Self = 'Self',
  AtTheAcademy = 'At the academy'
}

const renderItem = (item: string) => {
  return <Row justify="center">{item}</Row>;
};

export const tableTestResultConfig = (isDesktopValue?: boolean, role?: TUserRole) => {
  const isAcademyWorker = role === EUserRole.AcademyWorker;
  return [
    {
      title: i18n.t('Player'),
      dataIndex: 'img',
      key: 'img',
      fixed: true,
      ellipsis: true,
      colSpan: isDesktopValue ? 2 : 1,
      width: 70,
      // eslint-disable-next-line react/no-multi-comp
      render: (image: string) => {
        const imgUrl = getImageUrl(image, EImageSize.X56) || DefaultAvatar;
        return {
          props: { style: { background: '#ffffff' } },
          children: <img className="radius-default fit-cover" src={imgUrl} width={50} height={50} alt="avatar" />
        };
      }
    },
    {
      title: isDesktopValue ? '' : i18n.t('First name'),
      dataIndex: 'player',
      key: 'player',
      colSpan: isDesktopValue ? 0 : 1,
      width: isDesktopValue === undefined ? 300 : isDesktopValue ? 300 : 150,
      fixed: isDesktopValue,
      ellipsis: true,
      // eslint-disable-next-line react/no-multi-comp
      render: (renderItemValue: IPlayerItemModel) => {
        const { firstName, lastName } = renderItemValue || {};
        const isName = firstName && lastName;
        const firstNameValue = isName ? firstName : i18n.t('Is hidden');
        const lastNameValue = isName ? lastName : i18n.t('Player');

        return {
          props: { style: { background: '#ffffff' } },
          children: (
            <Row align="middle" wrap={false} className="flex-noWrap width-full">
              <Row className="flex-column" justify="space-between" wrap={false}>
                <span className="ellipsis flex-noWrap test-result-history-table__name">{lastNameValue}</span>
                <span className="ellipsis flex-noWrap test-result-history-table__name">{firstNameValue}</span>
              </Row>
            </Row>
          )
        };
      }
    },
    {
      title: <span className="pre-wrap">{i18n.t('Year of birth')}</span>,
      dataIndex: 'birthday',
      key: 'birthday',
      ellipsis: true,
      width: 120,
      sorter: true,
      render: renderItem
    },
    {
      title: i18n.t('Age group'),
      dataIndex: 'ageGroup',
      key: 'ageGroup',
      ellipsis: true,
      width: 90,
      sorter: true,
      render: (ageGroup: EPlayerAgeGroup) => renderItem(i18n.t(ageGroup))
    },
    {
      title: <span className="pre-wrap">{i18n.t('Test date')}</span>,
      dataIndex: 'date',
      key: 'date',
      ellipsis: true,
      width: 150,
      sorter: true,
      render: renderItem
    },
    isAcademyWorker
      ? {
          title: i18n.t('Trainer'),
          dataIndex: 'trainer',
          key: 'trainer',
          ellipsis: false,
          sorter: true,
          width: 200,
          render: renderItem
        }
      : undefined,
    isAcademyWorker
      ? {
          title: i18n.t('Team'),
          dataIndex: 'team',
          key: 'team',
          ellipsis: false,
          sorter: true,
          width: 200,
          render: renderItem
        }
      : undefined
  ];
};

export type TTestTag =
  | 'run10m'
  | 'run15m'
  | 'arrow'
  | 'arrowWithBallRight'
  | 'arrowWithBallLeft'
  | 'snake'
  | 'run15mStart'
  | 'runningRight15m'
  | 'runningLeft15m'
  | 'jump1'
  | 'jump2'
  | 'pushUps'
  | 'ladderForwardBackward'
  | 'ladderLeftRight'
  | 'braking'
  | 'sota1'
  | 'sota2'
  | 'rightLegStop'
  | 'leftLegStop'
  | 'gate'
  | 'shortPass'
  | 'longRightPass'
  | 'longLeftPass'
  | 'rightLegKick'
  | 'leftLegKick'
  | 'monsterKickRF'
  | 'monsterKickLF'
  | 'juggles'
  | 'freestyle'
  | 'freeKickRF'
  | 'freeKickLF'
  | 'run5mRfs'
  | 'run10mRfs'
  | 'run15mRfs'
  | 'run20mRfs'
  | 'jumpLongRfs'
  | 'jumpUpRfs'
  | 'jumpTripleLongRfs'
  | 'run30mRfs'
  | 'shuttleRun3x10mRfs'
  | 'dribblingRight10mRfs'
  | 'dribblingLeft10mRfs'
  | 'dribblingRight3x10mRfs'
  | 'dribblingLeft3x10mRfs'
  | 'dribblingWithDirectionRight10mRfs'
  | 'dribblingWithDirectionLeft10mRfs'
  | 'accuracyHitRightRfs'
  | 'accuracyHitLeftRfs'
  | 'passingLeftRfs'
  | 'passingRightRfs'
  | 'jugglingHeadRfs'
  | 'feints1x1WithTasksRfs'
  | 'dribblingGame1x1LineRfs'
  | 'strike1x1In2GateRfs'
  | 'receivingAndPassingGame2x1Rfs'
  | 'positionAndOpeningGame2x1Rfs';

export type TSkillTag =
  | 't1'
  | 'n2'
  | 't3'
  | 'n4'
  | 't5'
  | 't6'
  | 'v7'
  | 't8'
  | 'd9'
  | 't10'
  | 'v11'
  | 'v12'
  | 't13'
  | 't14'
  | 't15'
  | 'n16'
  | 'h17'
  | 'h18'
  | 'h19'
  | 'n20'
  | 'h21'
  | 'h22'
  | 'h23'
  | 'n24'
  | 't25'
  | 'r26'
  | 'l27'
  | 't28'
  | 'r29'
  | 'l30'
  | 't31'
  | 't32'
  | 't33'
  | 'n34'
  | 't35'
  | 't36'
  | 't37'
  | 'n38'
  | 'x39'
  | 'tz39'
  | 'n40'
  | 'x41'
  | 'n42'
  | 'x43'
  | 'n44'
  | 'pr45'
  | 'tr46'
  | 'r47'
  | 'pl48'
  | 'tl49'
  | 'l50'
  | 'pr51'
  | 'mr52'
  | 'tr53'
  | 'pl54'
  | 'ml55'
  | 'tl56'
  | '1dr57'
  | '2dr57'
  | '3dr57'
  | '4dr57'
  | '5dr57'
  | '1dl58'
  | '2dl58'
  | '3dl58'
  | '4dl58'
  | '5dl58'
  | '1dg59'
  | '2dg59'
  | '3dg59'
  | '4dg59'
  | '5dg59'
  | '1dr60'
  | '2dr60'
  | '3dr60'
  | '4dr60'
  | '5dr60'
  | '1t61'
  | '2t61'
  | '3t61'
  | '4t61'
  | '5t61'
  | '1r62'
  | '2r62'
  | '3r62'
  | '4r62'
  | '5r62'
  | '1dg63'
  | '2dg63'
  | '3dg63'
  | '4dg63'
  | '5dg63'
  | '1dl64'
  | '2dl64'
  | '3dl64'
  | '4dl64'
  | '5dl64'
  | '1t65'
  | '2t65'
  | '3t65'
  | '4t65'
  | '5t65'
  | '1l66'
  | '2l66'
  | '3l66'
  | '4l66'
  | '5l66'
  | 'f67'
  | 'j68'
  | 'ir_l69'
  | 'pp1'
  | 'pp2'
  | 'pp3'
  | 'pp5'
  | 'pp9'
  | 'pp10'
  | 'pp11'
  | 'pp14'
  | 'pp15'
  | 'pp17'
  | 'pp17_avg'
  | 'pp18'
  | 'pp21'
  | 'pp21_avg'
  | 'pp23'
  | 'pp28'
  | 'pp32'
  | 'pp33'
  | 'pp37'
  | 'pp38'
  | 'pp45'
  | 'pp47'
  | 'pp49'
  | 'pp50'
  | 'pp51'
  | 'pp52'
  | 'pp53'
  | 'pp54'
  | 'pp55'
  | 'pp56'
  | 'pp57'
  | 'pp57_avg'
  | 'pp58'
  | 'pp58_avg'
  | 'pp59'
  | 'pp60'
  | 'pp60_avg'
  | 'pp62'
  | 'pp63'
  | 'pp64'
  | 'pp64_avg'
  | 'pp66'
  | 'pp70'
  | 'pp71'
  | 'pp72'
  | 'pp73'
  | 'pp74'
  | 'pp75'
  | 'pp76'
  | 'pp77'
  | 'pp78'
  | 'pp79';
