import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Spin from 'antd/es/spin';
import Input from 'antd/es/input';
import Typography from 'antd/es/typography';
import Form from 'antd/es/form';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormMapper } from '@axmit/antd4-helpers';
import { ERoutesCommon } from 'common/models/routesModel';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { passwordRules, requiredField } from 'common/helpers/filed-rules';
import { CenteredWithLogoLayout } from 'common/components/Layouts/CenteredWithLogoLayout';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';
import { IUserConnectedProps, communicationUser } from 'entities/User/User.communication';

interface IProps {
  isExist?: boolean;
}

type AllProps = IProps & IAuthConnectedProps & IUserConnectedProps;

const MentorPlayerInvitePageComponent: React.FC<AllProps> = props => {
  const history = useHistory();
  const [isRequestSend, setIsRequestSend] = useState(false);
  const { t } = useTranslation();
  const { isExist, authUserConfirm, userCheckTokenModel, addUserCheckTokenModel, checkAuthUserConfirm } = props;
  const { loading: checkLoading } = authUserConfirm;
  const { loading: tokenCheckLoading, errors: tokenCheckError } = userCheckTokenModel;
  const { params, errors } = authUserConfirm;
  const isTokenExpired = !tokenCheckLoading && tokenCheckError;
  const query = queryString.parse(history.location.search);
  const token = Array.isArray(query?.token) ? query?.token[0] : query?.token;

  const { fields } = useFormMapper(['firstName', 'lastName', 'password'], null, params, errors);

  useEffect(() => {
    if (token) {
      if (isExist) {
        checkAuthUserConfirm({ token, silent: true });
      } else {
        addUserCheckTokenModel(token);
      }
    }
  }, [addUserCheckTokenModel, token, checkAuthUserConfirm]);

  useEffect(() => {
    if (isRequestSend && errors) {
      setIsRequestSend(false);
    }
  }, [errors]);

  const createMentor = (values: any) => {
    if (token) {
      // todo Figure out why on authUserConfirm.loading doesn't work here
      setIsRequestSend(true);
      checkAuthUserConfirm({ ...values, token });
    }
  };

  if (tokenCheckLoading || checkLoading) {
    return (
      <CenteredWithLogoLayout>
        <LoadingSpin />
      </CenteredWithLogoLayout>
    );
  }

  if (isTokenExpired || !token) {
    return (
      <CenteredWithLogoLayout>
        <Row justify="center" align="middle">
          <span className="t-align-c fs-sm width-full">{t('This link is expired')}</span>
          <span className="t-align-c fs-sm width-full">{t('expire-mentor-link-description')}</span>
          <Link to={ERoutesCommon.Root}>
            <Button type="primary" size="large" className="t-align-c mt-3">
              {t('Home')}
            </Button>
          </Link>
        </Row>
      </CenteredWithLogoLayout>
    );
  }

  return (
    <CenteredWithLogoLayout>
      <Spin size="large" spinning={isRequestSend}>
        <Col className="width-full mentor-player-invite-page mt-5">
          <Row className="mentor-player-invite-page__content p-7">
            <Row justify="start" className="mb-3">
              <Typography.Title level={3}>{t('Mentor invite')}</Typography.Title>
            </Row>
            <Row justify="start" className="mb-5">
              <Typography.Title level={5}>{t('mentor-invitation-description')}</Typography.Title>
            </Row>
            <Form onFinish={createMentor} fields={fields} layout="vertical" className="width-full">
              <Form.Item rules={[requiredField]} name="firstName" label={t('First Name')} className="mb-5">
                <Input name="firstName" type="text" size="large" />
              </Form.Item>
              <Form.Item rules={[requiredField]} name="lastName" label={t('Last Name')} className="mb-5">
                <Input name="lastName" type="text" size="large" />
              </Form.Item>
              <Form.Item rules={[requiredField, passwordRules]} name="password" label={t('Password')} className="mb-5">
                <Input.Password autoComplete="new-password" size="large" type="password" />
              </Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                block
                size="large"
                disabled={isRequestSend}
                loading={isRequestSend}
                title={t('Register')}
              >
                {t('Register')}
              </Button>
            </Form>
          </Row>
        </Col>
      </Spin>
    </CenteredWithLogoLayout>
  );
};

export const MentorPlayerInvitePage = communicationUser.injector(communicationAuth.injector(MentorPlayerInvitePageComponent));
