import React, { useEffect } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { LayoutContent } from 'common/components/Layouts/ContentLayout';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { AcademyModelProvider } from 'entities/Academy/components/View/Card/AcademyViewCardProvider';
import { AcademyViewCardInfo } from 'entities/Academy/components/View/Card/AcademyViewCardInfo';
import { AcademyViewTabs } from 'entities/Academy/components/View/AcademyViewTabs';
import { communicationAcademy, IAcademyConnectedProps } from 'entities/Academy/Academy.communication';

type AllProps = IAcademyConnectedProps;

const AcademyViewPageComponent: React.FC<AllProps> = props => {
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const { clearAcademyModel } = props;

  useEffect(() => {
    return () => {
      clearAcademyModel();
    };
  }, []);

  return (
    <LayoutContent>
      <AcademyModelProvider>
        {!isDesktop && <AcademyViewCardInfo />}
        <AcademyViewTabs isDesktop={isDesktop} />
      </AcademyModelProvider>
    </LayoutContent>
  );
};

export const AcademyViewPage = communicationAcademy.injector(AcademyViewPageComponent);
