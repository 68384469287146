import window from 'global/window';
import { parse } from 'query-string';

export function queryToObject<State extends {}>(keysOfProps?: State): {} {
  const location = window.location;

  if (!location) {
    return {};
  }

  const acc: { [index: string]: any } = {};
  // @ts-ignore
  for (const [key, value] of new window.URL(location).searchParams) {
    const clearedKey = key.replace('[]', '');
    const isValArray = key !== clearedKey;
    const keyOfPropsArray = Object.keys(keysOfProps || {});

    if (keyOfPropsArray.includes(clearedKey) || keyOfPropsArray.length === 0) {
      let val = value;
      try {
        val = JSON.parse(value);
        // eslint-disable-next-line no-empty
      } catch (e) {}

      if (isValArray) {
        if (!acc[clearedKey]) {
          acc[clearedKey] = [val];
        }
        const exist = acc[clearedKey].find((el: any) => (el.id ? el.id === val.id : el === val));
        if (!exist) {
          acc[clearedKey].push(val);
        }
      } else {
        acc[clearedKey] = val;
      }
    }
  }
  return acc;
}

// export function objectToQuery(obj: { [index: string]: any } = {}, saveSearchParams?: boolean): string {
//   const urlSearch = getUrlSearchAsObject();
//   let urlSearchParsed: any = {};
//   if (saveSearchParams) {
//     urlSearchParsed =
//       urlSearch && Object.keys(urlSearch).length
//         ? Object.keys(urlSearch).reduce((acc: any, param: string = '') => {
//             if (param?.replace('[', '').replace(']', '') in obj || !param) {
//               return acc;
//             }
//             return { ...acc, [param]: urlSearch[param] };
//           }, {})
//         : {};
//   }
//
//   const objToParse = obj && typeof obj === 'object' ? (saveSearchParams ? { ...urlSearchParsed, ...obj } : { ...obj }) : obj;
//
//   return objToParse && typeof objToParse === 'object'
//     ? `?${Object.keys(objToParse)
//         .filter((k: any) => objToParse[k] !== undefined)
//         .map(k =>
//           Array.isArray(objToParse[k])
//             ? objToParse[k]
//                 .filter((item: any) => item !== undefined)
//                 .map(
//                   (item: any) =>
//                     `${encodeURIComponent(k)}[]=${
//                       typeof item === 'object' ? encodeURIComponent(JSON.stringify(item)) : encodeURIComponent(item)
//                     }`
//                 )
//                 .join('&')
//             : `${encodeURIComponent(k)}=${
//                 typeof objToParse[k] === 'object'
//                   ? encodeURIComponent(JSON.stringify(objToParse[k]))
//                   : encodeURIComponent(objToParse[k])
//               }`
//         )
//         .join('&')}`
//     : !objToParse
//     ? ''
//     : objToParse;
// }

export function objectToQuery(obj: { [index: string]: any } = {}): string {
  return obj && typeof obj === 'object'
    ? `?${Object.keys(obj)
        .filter((k: any) => obj[k] !== undefined)
        .map(k =>
          Array.isArray(obj[k])
            ? obj[k]
                .filter((item: any) => item !== undefined)
                .map(
                  (item: any) =>
                    `${encodeURIComponent(k)}[]=${
                      typeof item === 'object' ? encodeURIComponent(JSON.stringify(item)) : encodeURIComponent(item)
                    }`
                )
                .join('&')
            : `${encodeURIComponent(k)}=${
                typeof obj[k] === 'object' ? encodeURIComponent(JSON.stringify(obj[k])) : encodeURIComponent(obj[k])
              }`
        )
        .join('&')}`
    : !obj
    ? ''
    : obj;
}

export function clearFromUndefinedFields(obj?: { [key: string]: any }) {
  return Object.keys(obj || {}).reduce((acc: { [key: string]: any }, key) => {
    if (obj && obj[key] !== undefined) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

export const getUrlSearchAsObject = () => parse(window.location.search, { decode: false });
