import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { ICommonStatisticModel } from 'entities/Common/Common.models';

const basePath = '/common';

class CommonTransport extends BaseHttpTransport<AxiosStatic, void, void, void, void, void> {
  public getStatistic = (): Promise<ICommonStatisticModel> => axios.get(`${basePath}/stats`);
}

export const commonTransport = new CommonTransport(basePath, axios);
