import React from 'react';
import moment from 'moment';
import { EMeasurementSystem, measurementConverterValue } from 'common/helpers/units-converter.helper';
import { isUnitInteger } from 'common/helpers/test.helper';
import i18n from 'common/helpers/i18n';
import { ReactComponent as LoseSmallIcon } from 'app/assets/icons/LoseSmall.svg';
import { ReactComponent as WinSmallIcon } from 'app/assets/icons/WinSmall.svg';
import { EResultTypes } from 'entities/PlayerTest/PlayerTest.models';

const DEFAULT_VALUE = '-';

export const normalize = (
  value?: number | null,
  options?: {
    minimumFractionDigits?: number;
  }
) => {
  const { minimumFractionDigits = 0 } = options ?? {};
  if (value !== undefined && value !== null) {
    return Number(value).toLocaleString(undefined, { minimumFractionDigits, maximumFractionDigits: 2 });
  }
  return DEFAULT_VALUE;
};

export const normalizeUnitResult = (value?: number | null, unit?: EResultTypes | string, system?: EMeasurementSystem) => {
  const isInteger = isUnitInteger(unit as EResultTypes);
  const isBoolean = unit === EResultTypes.Bool;

  if (isBoolean) {
    return value !== undefined && value !== null && !Number.isNaN(value) ? (
      value === 0 ? (
        <LoseSmallIcon />
      ) : (
        <WinSmallIcon />
      )
    ) : (
      '-'
    );
  }

  const convertedValue = measurementConverterValue(value, unit, system);
  if (isInteger) {
    return normalize(convertedValue && Math.round(convertedValue));
  }
  return normalize(convertedValue, {
    minimumFractionDigits: 2
  });
};

export const copyObject = (object: object) => JSON.parse(JSON.stringify(object));

export const normalizeMoney = (value?: number | null | string, currency?: string) => {
  if (value !== undefined && value !== null) {
    return Number(value).toLocaleString(undefined, { style: 'currency', currency });
  }
  return DEFAULT_VALUE;
};

export const normalizeTestValue = (
  tag?: string,
  value?: string | number | null,
  units?: EResultTypes,
  system?: EMeasurementSystem
) => {
  const translate = i18n.t(`${tag}`) as any;

  if (typeof translate === 'string') {
    if (value !== undefined && value !== null) {
      return normalizeUnitResult(Number(value), units, system);
    }
  } else {
    const result = translate[normalizeUnitResult(Number(value), units, system) as string];
    if (result) {
      return result;
    }
  }

  return DEFAULT_VALUE;
};

export const normalizeDate = (date: string) => (date ? moment(date).format('DD.MM.YYYY') : '-');
export const normalizeStringDate = (date: string) => (date ? moment(date).format('DD MMMM YYYY') : '-');
export const normalizeDateFirstMouth = (date: string) => {
  if (!date) {
    return '-';
  }
  const dateString = moment(date).format('MMMM DD, YYYY');
  return `${dateString[0].toUpperCase()}${dateString.slice(1)}`;
};
