import i18n from 'common/helpers/i18n';
import { EMeasurementSystem, measurementConverterUnit } from 'common/helpers/units-converter.helper';

export const translateErrors = (error: string) => i18n.t(error);
export const translateToasts = (message: string) => i18n.t(message);

export const normalizeCode = (code: string) => code.replace(/error\.|validation./g, '');

export const getErrorMessageByCode = (code: string, params?: object) => {
  const normalizedCode = normalizeCode(code);
  const str = i18n.t(normalizedCode, params);

  if (normalizedCode.includes(str)) {
    return null;
  } else {
    return str;
  }
};

export const notExistTranslationWrapper = (key?: string, options?: { [key: string]: any }, system?: EMeasurementSystem) => {
  const keyValue = measurementConverterUnit(key, system);

  return keyValue && i18n.exists(keyValue) ? i18n.t(keyValue, options) : '';
};
