import React, { useEffect, useMemo } from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Typography from 'antd/es/typography';
import Select from 'antd/es/select';
import Button from 'antd/es/button';
import InputNumber from 'antd/es/input-number';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import Form from 'antd/es/form';
import { useFormMapper } from '@axmit/antd4-helpers';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { requiredField } from 'common/helpers/filed-rules';

export enum ETestBalanceRoles {
  Academy = 'academy',
  Franchise = 'franchise',
  Trainer = 'trainer'
}

interface IProps {
  params?: any;
  errors?: any;
  toBalance: number | undefined;
  fromBalance: number | undefined;
  loading?: boolean;
  viewOnly?: boolean;
  from: ETestBalanceRoles;
  to: ETestBalanceRoles;
  onChange: (diff: number) => void;
}

type AllProps = IProps;

enum EActions {
  Replenished = 'replenished',
  ChargeOff = 'charge-off'
}

const BalanceFieldName = 'balanceDiff';
const ActionFieldName = 'action';

const TestsBalanceEditorComponent: React.FC<AllProps> = props => {
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const { t } = useTranslation();
  const [balanceForm] = Form.useForm();
  const { toBalance = 0, loading, fromBalance = 0, from, to, viewOnly, onChange, errors, params } = props;
  const formParams: any = useMemo(
    () => (params?.balanceDiff ? { ...params, balanceDiff: Math.abs(params?.balanceDiff) } : params),
    [params]
  );

  const { fields } = useFormMapper([BalanceFieldName], null, formParams, errors);

  useEffect(() => {
    if (toBalance <= 0) {
      balanceForm.setFieldsValue({ [ActionFieldName]: EActions.Replenished });
    }
    if (fromBalance <= 0) {
      balanceForm.setFieldsValue({ [ActionFieldName]: EActions.ChargeOff });
    }
  }, [toBalance, fromBalance]);

  const onApply = (values: { balanceDiff: number; action: EActions }) => {
    const { [ActionFieldName]: action, [BalanceFieldName]: balanceDiff } = values;
    if (balanceDiff > 0) {
      onChange(action === EActions.Replenished ? balanceDiff : -balanceDiff);
    }
  };

  return (
    <Col className="tests-balance-editor" span={24}>
      {viewOnly ? (
        <Row align="middle" className="tests-balance-editor__academy-current">
          <Typography.Text>{t('Test count', { count: toBalance })}</Typography.Text>
        </Row>
      ) : (
        <>
          <Row align="middle" className="tests-balance-editor__academy-current">
            <Typography.Text className="mb-5">
              {t('Test balance', { count: toBalance, roleValue: t(`roles.${to}`) })}
            </Typography.Text>
          </Row>
          <Form form={balanceForm} onFinish={onApply} fields={fields}>
            <Row gutter={[16, 16]} align="top" className="tests-balance-editor__edit mb-3 width-full" wrap={!isDesktop}>
              <Col xs={12} md={7}>
                <Form.Item name={BalanceFieldName} className="mb-0" rules={[requiredField]}>
                  <InputNumber
                    placeholder="0"
                    size="large"
                    precision={0}
                    className="width-full"
                    step={1}
                    min={0}
                    disabled={loading}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} md={7}>
                <Form.Item name={ActionFieldName} className="mb-0" initialValue={EActions.Replenished} rules={[requiredField]}>
                  <Select className="width-full" size="large" loading={loading} disabled={loading}>
                    <Select.Option value={EActions.Replenished} disabled={fromBalance <= 0}>
                      {t(`${EActions.Replenished}`)}
                    </Select.Option>
                    <Select.Option value={EActions.ChargeOff} disabled={toBalance <= 0}>
                      {t(`${EActions.ChargeOff}`)}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={5}>
                <Button htmlType="submit" type="primary" size="large" block={!isDesktop} loading={loading} disabled={loading}>
                  {t('Apply')}
                </Button>
              </Col>
            </Row>
          </Form>
          <Row align="middle" className="tests-balance-editor__user-current">
            <Typography.Text className="mb-0 color-text-secondary">
              {t('Test balance', { count: fromBalance, roleValue: t(`roles.${from}`) })}
            </Typography.Text>
          </Row>
        </>
      )}
    </Col>
  );
};

export const TestsBalanceEditor = TestsBalanceEditorComponent;
