import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  ISubscriptionCollection,
  ISubscriptionCollectionFilter,
  ISubscriptionCreate,
  ISubscriptionModel,
  IPaymentRequestParams,
  ISubscriptionActivityModel,
  ISubscriptionActivityCollectionFilter,
  ISubscriptionActivityCollection,
  ISubscriptionActivityApplyGiftcard,
  ISubscriptionChangeTariffParams,
  ISubscriptionUpdate,
  ISubscriptionPromoCodeInfo,
  ISubscriptionPromoCodeInfoParams
} from 'entities/Subscription/Subscription.models';

const basePath = '/subscriptions';

class SubscriptionTransport extends BaseHttpTransport<
  AxiosStatic,
  ISubscriptionModel,
  ISubscriptionCollection,
  ISubscriptionCreate,
  ISubscriptionUpdate,
  ISubscriptionCollectionFilter
> {
  public createSubscriptionActivity = ({
    subscriptionId,
    ...rest
  }: IPaymentRequestParams): Promise<ISubscriptionActivityModel> => {
    const url = new URL(window.location.href);
    const params = { ...rest };
    const urlParams = new URLSearchParams(url.search.slice(1));
    urlParams.set('subscriptionId', subscriptionId);
    params.returnUrl = `${url.origin}${url.pathname}?${urlParams.toString()}`;

    return axios.post(`${basePath}/${subscriptionId}/activities/payments/`, params);
  };

  public applyGiftcardSubscriptionActivity = ({
    subscriptionId,
    ...rest
  }: ISubscriptionActivityApplyGiftcard): Promise<ISubscriptionActivityModel> =>
    axios.post(`${basePath}/${subscriptionId}/activities/giftcards`, rest);

  public changeSubscriptionTariff = ({ subscriptionId, ...rest }: ISubscriptionChangeTariffParams): Promise<ISubscriptionModel> =>
    axios.put(`${basePath}/${subscriptionId}/tariff`, rest);

  public getSubscriptionActivityCollection = (
    filters: ISubscriptionActivityCollectionFilter
  ): Promise<ISubscriptionActivityCollection> => axios.get(`${basePath}/activities/list${objectToQuery(filters)}`);

  public deleteSubscriptionActivity = (subscriptionId: string): Promise<void> =>
    axios.delete(`${basePath}/${subscriptionId}/activities/payments`);

  public getSubscriptionPromoCodeInfo = ({ code }: ISubscriptionPromoCodeInfoParams): Promise<ISubscriptionPromoCodeInfo> =>
    axios.get(`${basePath}/stripe/promotion-codes/${code}`);
}

export const subscriptionTransport = new SubscriptionTransport(basePath, axios);
