import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { ELocationType, ILocationModel } from 'common/helpers/location.helper';
import { LocationInput } from 'common/components/Input/LocationInput';
import { IFranchiseRequestCollectionFilter } from 'entities/Franchise/Franchise.models';

interface IComponentProps {
  onFilterChange: (filter: Partial<IFranchiseRequestCollectionFilter>) => void;
  filter?: Partial<IFranchiseRequestCollectionFilter>;
}

export const FranchiseAcademyListFilterCard: React.FC<IComponentProps> = props => {
  const { t } = useTranslation();
  const { onFilterChange, filter } = props;
  const nameValue = filter?.name;
  const locationValue = filter?.location;

  const autocompleteSearch = useDebouncedCallback((text: string) => {
    const name = text === '' ? undefined : text;
    onFilterChange({ name });
  }, 300);

  const locationSearch = (location?: ILocationModel) => {
    if (JSON.stringify(filter?.location) !== JSON.stringify(location)) {
      onFilterChange({ location });
    }
  };

  return (
    <Card className="px-3 mt-5" bordered={false}>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Typography.Text>{t('Academy name')}</Typography.Text>
          <Input
            className="mt-3"
            size="large"
            name="name"
            type="text"
            placeholder={t('Academy name')}
            defaultValue={nameValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => autocompleteSearch.callback(e.target.value)}
          />
        </Col>

        <Col span={24}>
          <Typography.Text>{t('City or region')}</Typography.Text>
          <LocationInput
            className="mt-3"
            placeholder={t('City or region')}
            onChange={locationSearch}
            value={locationValue}
            types={[ELocationType.Regions]}
          />
        </Col>
      </Row>
    </Card>
  );
};
