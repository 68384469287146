import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { TStatisticCollection, IStatisticCollectionFilter } from 'entities/Statistic/Statistic.models';
import { statisticTransport } from 'entities/Statistic/Statistic.transport';

const namespace = 'statistic';

export interface IStatisticConnectedProps {
  statisticCollection: StoreBranch<TStatisticCollection, IStatisticCollectionFilter>;

  getStatisticCollection(filter?: IStatisticCollectionFilter): void;

  clearStatisticCollection(): void;
}

const StatisticCollectionAPIProviders = [new APIProvider(EActionsTypes.get, statisticTransport.getStatisticCollection)];

const branches = [new Branch('collection', StatisticCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationStatistic = buildCommunication<IStatisticConnectedProps>(strategy);
