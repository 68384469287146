import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import moment from 'moment';
import Switch from 'antd/es/switch';
import Typography from 'antd/es/typography';
import { Moment } from 'moment';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import DatePicker from 'antd/lib/date-picker';
import { getBirthdayAgeGroup, dateToMomentFormat } from 'common/helpers/date.helper';
import { ELocationType, ILocationModel } from 'common/helpers/location.helper';
import { LocationInput } from 'common/components/Input/LocationInput';
import { AgeGroupSelector } from 'common/components/Selector/AgeGroupSelector';
import { GenderSelect } from 'common/components/GenderSelect';
import { EPlayerAgeGroup } from 'entities/Player/Player.models';
import { IPlayerCollectionFilter } from 'entities/Player/Player.models';
import { PlayerOrderFieldSelector } from 'entities/PrivatePlayer/components/Selectors/PlayerOrderFieldSelector';
import { TPlayerFilterOrderField } from 'entities/PrivatePlayer/PrivatePlayer.models';
import { EUserGender } from 'entities/User/User.models';

interface IComponentProps {
  onFilterChange: (filter: Partial<IPlayerCollectionFilter>) => void;
  filter?: Partial<IPlayerCollectionFilter>;
  isForMentor?: boolean;
}

export const PlayerListFilterCard: React.FC<IComponentProps> = props => {
  const { t } = useTranslation();
  const { onFilterChange, filter } = props;
  const playerNameValue = filter?.name;
  const locationValue = filter?.location;
  const ageGroupValue = filter?.ageGroup;
  const genderValue = filter?.gender;
  const yearData = filter?.year;
  const visibleOnly = filter?.visibleOnly;
  const orderFieldValue = filter?.orderField;
  // const [showSkillFilter, setShowSkillFilter] = useState<boolean>(!!filter?.skills?.length);
  const yearValue: Moment | undefined = yearData ? dateToMomentFormat(yearData.toString(), 'YYYY') : undefined;

  const debounceSearch = useDebouncedCallback((text: string) => {
    const name = text === '' ? undefined : text;
    onFilterChange({ name });
  }, 300);

  const locationSearch = (location?: ILocationModel) => {
    if (JSON.stringify(filter?.location) !== JSON.stringify(location)) {
      onFilterChange({ location });
    }
  };

  const onChangeAgeSelector = (ageGroup: EPlayerAgeGroup) => {
    onFilterChange({ ageGroup });
  };

  const onChangeGender = (gender: EUserGender) => {
    onFilterChange({ gender });
  };

  const onChangeBirthdayAgeGroupSelector = (date: any) => {
    const year = parseInt(getBirthdayAgeGroup(date)) || undefined;
    onFilterChange({ year });
  };

  // const onChangeTags = (resultRangesValue?: { id: string }[]) => {
  //   onFilterChange({ resultRangesValue });
  // };

  const onChangeVisibleOnly = (checked: boolean) => {
    onFilterChange({ visibleOnly: checked });
  };

  const onChangeOrderField = (orderField: TPlayerFilterOrderField) => {
    onFilterChange({ orderField });
  };

  return (
    <Card className="border-none px-3 pb-0 width-full players-filters">
      <Row gutter={[16, 16]}>
        {/* {!isForMentor && (*/}
        {/* <Col span={24}>*/}
        {/*  <Row justify="center">*/}
        {/*    <TagsCheckbox onChange={onChangeTags} value={(filter as IAcademyPlayerCollectionFilter)?.resultRangesValue} />*/}
        {/*  </Row>*/}
        {/* </Col>*/}
        {/* )}*/}
        <Col xs={24} sm={6}>
          <Typography.Text>{t('Player name')}</Typography.Text>
          <Input
            allowClear
            className="mt-3"
            size="large"
            name="playerName"
            type="text"
            placeholder={t('Player name')}
            defaultValue={playerNameValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => debounceSearch.callback(e.target.value)}
          />
        </Col>
        <Col xs={24} sm={6}>
          <Typography.Text>{t('Sort')}</Typography.Text>
          <PlayerOrderFieldSelector
            allowClear
            value={orderFieldValue}
            className="width-full mt-3"
            size="large"
            onChange={onChangeOrderField}
          />
        </Col>
        <Col xs={24} sm={6}>
          <Typography.Text>{t('Group')}</Typography.Text>
          <AgeGroupSelector
            allowClear
            value={ageGroupValue}
            className="width-full mt-3"
            size="large"
            onChange={onChangeAgeSelector}
          />
        </Col>
        <Col xs={24} sm={6}>
          <Typography.Text ellipsis>{t('Year of birth')}</Typography.Text>
          <DatePicker
            allowClear
            defaultPickerValue={moment().subtract(15, 'years')}
            value={yearValue as any}
            size="large"
            className="width-full mt-3"
            format="YYYY"
            placeholder={t('Year')}
            onChange={onChangeBirthdayAgeGroupSelector}
            picker="year"
          />
        </Col>
        <Col xs={24} sm={18}>
          <Typography.Text>{t('City or region')}</Typography.Text>
          <LocationInput
            types={[ELocationType.Regions]}
            className="mt-3"
            placeholder={t('City or region')}
            onChange={locationSearch}
            value={locationValue}
          />
        </Col>
        <Col xs={24} sm={6}>
          <Typography.Text>{t('Gender')}</Typography.Text>
          <GenderSelect
            placeholder={t('Gender')}
            allowClear
            value={genderValue}
            className="width-full mt-3"
            size="large"
            onChange={onChangeGender}
          />
        </Col>
        <Col xs={24}>
          <Row gutter={[16, 16]} justify="end">
            <Col className="t-align-e">
              <Typography.Text className="mr-3">{t('Visible only')}</Typography.Text>
              <Switch checked={visibleOnly} onChange={onChangeVisibleOnly} />
            </Col>
            {/* <Col>*/}
            {/*  <Row justify="end" align="middle" className="mb-5">*/}
            {/*    <Space align="center" size="middle" className="players-filters__switch-select">*/}
            {/*      {t('Skill filters')}*/}
            {/*      <Switch*/}
            {/*        onChange={() => {*/}
            {/*          onFilterChange({ skills: [] });*/}
            {/*          setShowSkillFilter(!showSkillFilter);*/}
            {/*        }}*/}
            {/*        checked={showSkillFilter}*/}
            {/*      />*/}
            {/*    </Space>*/}
            {/*  </Row>*/}
            {/* </Col>*/}
          </Row>
          {/* {showSkillFilter && <PlayerSkillFilter filter={filter} onFilterChange={onFilterChange} />}*/}
        </Col>
      </Row>
    </Card>
  );
};
