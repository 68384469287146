import * as React from 'react';
import Select, { SelectProps } from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import { EStatisticType, EStatisticTypeValue } from 'entities/Statistic/Statistic.models';

export const StatisticTypeSelector: React.FC<SelectProps<any>> = props => {
  const { t } = useTranslation();
  return (
    <Select placeholder={t('Type')} getPopupContainer={triggerNode => triggerNode as HTMLElement} {...props}>
      <Select.Option key={EStatisticTypeValue.Test} value={EStatisticTypeValue.Test}>
        {t(EStatisticType.Test)}
      </Select.Option>
      <Select.Option key={EStatisticTypeValue.Billing} value={EStatisticTypeValue.Billing}>
        {t(EStatisticType.Billing)}
      </Select.Option>
    </Select>
  );
};
