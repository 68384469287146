import React from 'react';
import Form, { FormInstance } from 'antd/es/form';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { useFormMapper } from '@axmit/antd4-helpers';
import { requiredField } from 'common/helpers/filed-rules';
import { ITransferModalForm } from 'entities/PrivatePlayer/PrivatePlayer.models';
import { communicationPrivatePlayer, IPrivatePlayerConnectedProps } from 'entities/PrivatePlayer/PrivatePlayer.communication';
import { AcademySelector } from 'entities/Academy/components/Selector/AcademySelector';
import { TrainerSelector } from 'entities/Trainer/components/Selectors/TrainerSelector';
import { TeamSelector } from 'entities/Team/components/TeamSelector';

interface IProps {
  onFinish(values: ITransferModalForm): void;
  formName: string;
}

type AllProps = IProps & IPrivatePlayerConnectedProps;

const AcademyFieldName = 'academy';
const TrainersFieldName = 'trainers';
const TeamsFieldName = 'teams';

const IsRequiredRule = (isRequired: boolean) => {
  const rules = [];

  if (isRequired) {
    rules.push(requiredField);
  }

  return rules;
};

const shouldTrainerFieldUpdate = (prevValues: ITransferModalForm, currentValues: ITransferModalForm) =>
  prevValues[AcademyFieldName]?.id !== currentValues[AcademyFieldName]?.id ||
  prevValues[TeamsFieldName]?.length !== currentValues[TeamsFieldName]?.length;

const shouldTeamsFieldUpdate = (prevValues: ITransferModalForm, currentValues: ITransferModalForm) =>
  prevValues[AcademyFieldName]?.id !== currentValues[AcademyFieldName]?.id ||
  prevValues[TrainersFieldName]?.length !== currentValues[TrainersFieldName]?.length;

const PlayerTransferModalBodyComponent: React.FC<AllProps> = props => {
  const { formName, onFinish, privatePlayerModel } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { params, errors } = privatePlayerModel;

  const { fields } = useFormMapper([AcademyFieldName, TrainersFieldName, TeamsFieldName], null, params, errors);

  const onFieldsChanged = (changedFields: any) => {
    if (changedFields?.length === 1 && changedFields[0]?.name?.includes(AcademyFieldName)) {
      form.resetFields([TrainersFieldName, TeamsFieldName]);
    }
  };

  return (
    <Form name={formName} form={form} onFinish={onFinish} fields={fields} layout="vertical" onFieldsChange={onFieldsChanged}>
      <Row className="mb-5">
        <Typography.Text className="color-danger">{t('Attention!')}</Typography.Text>
        <Typography.Text>{t('transfer-player-description')}</Typography.Text>
      </Row>
      <Col>
        <Form.Item name={AcademyFieldName} rules={[requiredField]} label={t('New academy')}>
          <AcademySelector
            size="large"
            filters={{
              status: 'active'
            }}
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate={shouldTrainerFieldUpdate}>
          {({ getFieldValue }: FormInstance) => (
            <Form.Item
              name={TrainersFieldName}
              rules={IsRequiredRule(!getFieldValue(TeamsFieldName)?.length)}
              label={t('New trainers')}
            >
              <TrainerSelector
                size="large"
                mode="multiple"
                disabled={!getFieldValue(AcademyFieldName)?.id}
                academyId={getFieldValue(AcademyFieldName)?.id}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate={shouldTeamsFieldUpdate}>
          {({ getFieldValue }: FormInstance) => (
            <Form.Item
              name={TeamsFieldName}
              rules={IsRequiredRule(!getFieldValue(TrainersFieldName)?.length)}
              label={t('New teams')}
            >
              <TeamSelector
                size="large"
                mode="multiple"
                disabled={!getFieldValue(AcademyFieldName)?.id}
                academyId={getFieldValue(AcademyFieldName)?.id}
              />
            </Form.Item>
          )}
        </Form.Item>
      </Col>
    </Form>
  );
};

export const PlayerTransferModalBody = communicationPrivatePlayer.injector(PlayerTransferModalBodyComponent);
