import * as React from 'react';
import Select, { SelectProps } from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import { EUserGender, EUserGenderText } from 'entities/User/User.models';

export const GenderSelect: React.FC<SelectProps<any>> = props => {
  const { t } = useTranslation();

  return (
    <Select {...props}>
      <Select.Option key={EUserGender.Male} value={EUserGender.Male}>
        {t(EUserGenderText.Male)}
      </Select.Option>
      <Select.Option key={EUserGender.Female} value={EUserGender.Female}>
        {t(EUserGenderText.Female)}
      </Select.Option>
    </Select>
  );
};
