import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import i18n from 'i18next';
import Typography from 'antd/es/typography';
import { RouteComponentProps, withRouter } from 'react-router';
import { IBaseFilterModel } from 'common/models/requestModels';
import { TableCommon } from 'common/components/Table/TableCommon';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import {
  initStatisticState,
  TStatisticCollection,
  IStatisticCollectionFilter,
  IStatisticModel,
  translateStatisticValue
} from 'entities/Statistic/Statistic.models';
import { communicationStatistic, IStatisticConnectedProps } from 'entities/Statistic/Statistic.communication';

interface IComponentProps {
  config: ColumnsType<any>;
  franchiseId?: string;
  isDesktop?: boolean;
}

type AllProps = IComponentProps & IStatisticConnectedProps & RouteComponentProps;

class StatisticTableComponent extends TableCommon<
  TStatisticCollection,
  IStatisticModel,
  ColumnsType<any>,
  AllProps,
  IStatisticCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getStatisticCollection, filter, franchiseId } = this.props;
    const { from, to, statisticType, academyFilter } = filter || {};
    const id = academyFilter?.id || franchiseId;
    const isFranchise = !academyFilter?.id || academyFilter?.id === franchiseId;

    if (id && statisticType) {
      const filterParams: IStatisticCollectionFilter = {
        ...params,
        statisticType,
        isFranchise,
        id
      };
      if (filter?.from) {
        filterParams.from = from;
      }
      if (filter?.to) {
        filterParams.to = to;
      }

      getStatisticCollection(filterParams);
    }
  };

  getCollection = () => {
    const { statisticCollection } = this.props;
    if (statisticCollection?.data?.data) {
      const data: IStatisticModel[] = statisticCollection.data.data.map((item, index) => {
        return {
          ...item,
          id: index
        };
      });
      return {
        ...statisticCollection,
        data: {
          ...statisticCollection.data,
          data
        }
      };
    } else {
      return statisticCollection;
    }
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<Partial<IStatisticCollectionFilter>>(initStatisticState);
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  clearCollection = () => {
    return this.props.clearStatisticCollection();
  };

  renderHeader = () => {
    const { statisticCollection, isDesktop } = this.props;
    const { meta } = statisticCollection.data || {};
    if (meta) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { count, amounts, ...metaArray } = meta;
      const keyArray = Object.keys(metaArray);
      const valuesArray = Object.values(metaArray);

      const renderItems = keyArray.map((item, index) => (
        <Col key={index} span={isDesktop ? undefined : 24} md="auto">
          <Typography.Text className="fs-xxs font-weight-5 mr-2" ellipsis>
            {i18n.t(translateStatisticValue[item]) || item}:
          </Typography.Text>
          <br />
          <Typography.Text className="fs-xxs " ellipsis>
            {valuesArray[index] || '-'}
          </Typography.Text>
        </Col>
      ));
      const renderAmount = () =>
        amounts?.map((item, index) => {
          const { amountCount = '-', totalAmount = '-', currency = '-' } = item || {};
          return (
            <Col key={index} span={isDesktop ? undefined : 24} md="auto">
              <Typography.Text className="fs-xxs font-weight-5" ellipsis>
                {i18n.t(translateStatisticValue['amountCount']) || 'amountCount'}:
              </Typography.Text>
              <Typography.Text className="fs-xxs ml-2" ellipsis>
                {amountCount}
              </Typography.Text>
              <Typography.Text className="fs-xxs font-weight-5 ml-4" ellipsis>
                {i18n.t(translateStatisticValue['totalAmount']) || 'totalAmount'}:
              </Typography.Text>
              <Typography.Text className="fs-xxs ml-2" ellipsis>
                {totalAmount} {currency}
              </Typography.Text>
            </Col>
          );
        });

      return (
        <>
          <Row wrap gutter={[16, 16]}>
            {renderItems}
          </Row>
          {amounts ? renderAmount() : null}
        </>
      );
    } else {
      return <div />;
    }
  };
}

export const StatisticTable = communicationStatistic.injector(withRouter(StatisticTableComponent));
