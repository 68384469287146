import React, { SVGProps, useContext } from 'react';
import { normalizeUnitResult } from 'common/helpers/normalize.helper';
import { MeasurementContext } from 'common/components/Provider/MeasurementContext';
import { EResultTypes } from 'entities/PlayerTest/PlayerTest.models';

export interface INumberTickItem extends SVGProps<any> {
  fill: string;
  index: number;
  payload: {
    coordinate: number;
    offset: number;
    value: number;
  };
  radius: number;
  stroke: string;
  textAnchor: string;
  x: number;
  y: number;
}

interface ITick {
  item: INumberTickItem;
  isInvert?: boolean;
  units?: EResultTypes;
}

export const NumberTick = ({ item, isInvert, units }: ITick) => {
  const { payload } = item;
  const { measurementSystem } = useContext(MeasurementContext);
  const props = {} as SVGProps<any>;

  return (
    <g>
      <text {...item} {...props}>
        {normalizeUnitResult(payload.value * (isInvert ? -1 : 1), units, measurementSystem)}
      </text>
    </g>
  );
};
