import React from 'react';
import Select from 'antd/es/select';
import { SelectProps } from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import { EUserRepresentationType } from 'entities/User/User.models';

export const UserRepresentationTypesSelect: React.FC<SelectProps<any>> = props => {
  const { t } = useTranslation();

  return (
    <Select defaultValue={EUserRepresentationType.Parent} {...props}>
      <Select.Option key={EUserRepresentationType.Parent} value={EUserRepresentationType.Parent}>
        {t(EUserRepresentationType.Parent)}
      </Select.Option>
    </Select>
  );
};
