import { AbstractSearchableSelector } from 'common/components/Input/AbstractSearchableSelector';
import { nameBuilder } from 'common/helpers/name.helper';
import { ITrainerModel, ETrainerAcademyRequestStatus, ITrainerSearchCollectionFilter } from 'entities/Trainer/Trainer.models';
import { trainerTransport } from 'entities/Trainer/Trainer.transport';
import { EUserRole } from 'entities/User/User.models';

interface IComponentProps {
  academyId?: string | string[];
  excludeTeam?: string;
}

export class TrainerSelector extends AbstractSearchableSelector<ITrainerModel, IComponentProps> {
  getItemLabel = (item: ITrainerModel): string => {
    return nameBuilder(item?.firstName, item?.lastName, item?.email);
  };

  loadData = (value?: string): Promise<{ data: ITrainerModel[] }> => {
    const { academyId, excludeTeam } = this.props;
    const filter: ITrainerSearchCollectionFilter = {};

    if (value) {
      filter.search = value;
    }

    if (academyId) {
      filter.academiesIds = Array.isArray(academyId) ? academyId : [academyId];
    }

    if (excludeTeam) {
      filter.excludeTeamId = excludeTeam;
    }

    filter.status = ETrainerAcademyRequestStatus.Active;
    filter.roles = [EUserRole.Trainer];

    return trainerTransport.getTrainerSearch(filter);
  };
}
