import React from 'react';
import Radio from 'antd/es/radio';
import Typography from 'antd/es/typography';

const { Title, Text } = Typography;

export const RadioButtonsComponent: React.FC = () => (
  <>
    <Title>Radio Buttons</Title>
    <Text>Outlined</Text>
    <div>
      <Radio.Group defaultValue="a">
        <Radio.Button value="a">Test1</Radio.Button>
        <Radio.Button value="b">Test2</Radio.Button>
        <Radio.Button value="c">Test3</Radio.Button>
        <Radio.Button value="d" disabled>
          Test4
        </Radio.Button>
      </Radio.Group>
    </div>
    <Text>Solid</Text>
    <div>
      <Radio.Group defaultValue="d" buttonStyle="solid">
        <Radio.Button value="a">Test1</Radio.Button>
        <Radio.Button value="b">Test2</Radio.Button>
        <Radio.Button value="c">Test3</Radio.Button>
        <Radio.Button value="d" disabled>
          Test4
        </Radio.Button>
      </Radio.Group>
    </div>
  </>
);
