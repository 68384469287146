import React, { useEffect, useMemo, useState } from 'react';
import Typography from 'antd/es/typography';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Spin from 'antd/lib/spin';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import Col from 'antd/es/col';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { AcademyTeamCreateModal } from 'entities/Academy/components/View/Tabs/Teams/AcademyTeamCreateModal';
import { ITeamCollectionFilter } from 'entities/Team/Team.models';
import { TeamAcademyListFilterCard } from 'entities/Team/components/List/TeamAcademyListFilterCard';
import { TeamAcademyList } from 'entities/Team/components/List/TeamAcademyList';
import { IAcademyModel } from 'entities/Academy/Academy.models';
import { communicationTeam, ITeamConnectedProps } from 'entities/Team/Team.communication';

interface IProps {
  isDesktop: boolean;
  franchisorModel?: IAcademyModel | null;
}

type AllProps = IProps & IUIConnectedProps & ITeamConnectedProps;

const AcademyTeamsTabComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { academyId: urlAcademyId } = useParams();
  const { isDesktop, openUITeamCreateModal, franchisorModel, teamCollection } = props;
  const [renderIndex, setRenderIndex] = useState(Math.random());
  const teamCount = teamCollection?.data?.meta?.count;
  const isCurrentAuthAcademy = franchisorModel?.id === urlAcademyId;
  const academyId = isCurrentAuthAcademy ? undefined : urlAcademyId || undefined;
  const initState: ITeamCollectionFilter = useMemo(() => {
    return {
      name: undefined,
      academyFilter: isCurrentAuthAcademy ? { id: franchisorModel?.id, name: franchisorModel?.name } : undefined
    };
  }, [isCurrentAuthAcademy, franchisorModel]);
  const [state, setState] = useState<Partial<ITeamCollectionFilter>>({
    ...initState,
    ...queryToObject<Partial<ITeamCollectionFilter>>(initState)
  });

  const debPush = useDebouncedCallback((val: any) => {
    history.replace(val);
  }, 300);

  useEffect(() => {
    debPush.callback({ search: objectToQuery(state) });
  }, [state]);

  const createTeamButton = (
    <Button size={isDesktop ? 'large' : 'middle'} type="primary" onClick={openUITeamCreateModal}>
      {t('Create team')}
    </Button>
  );

  return (
    <>
      <Row className={`pb-5 ${!isDesktop ? 'px-3' : ''}`} justify="space-between" align="middle">
        <Typography.Title className="mb-0" level={isDesktop ? 2 : 3}>
          {t('Teams tab')} {teamCount !== undefined ? `(${teamCount})` : <Spin className="ml-4" />}
        </Typography.Title>
        {createTeamButton}
      </Row>
      <Col xs={24} className="mt-5">
        <Row className="height-full mb-5" key={renderIndex}>
          <TeamAcademyListFilterCard
            isFranshisor={!!franchisorModel}
            onFilterChange={partialFilter => setState({ ...state, ...partialFilter })}
            filter={state}
          />
        </Row>
        <Row className={`height-full ${isDesktop ? '' : 'px-5'}`}>
          <TeamAcademyList
            className="mt-5 academy-view-page__teams__list"
            elementId="basicLayout"
            initFilter={initState}
            actionNoDataButton={createTeamButton}
            academyId={academyId}
            filter={state}
            clearFilter={() => {
              setState(initState);
              setRenderIndex(Math.random());
            }}
          />
        </Row>
      </Col>
      <AcademyTeamCreateModal />
    </>
  );
};

export const AcademyTeamsTab = communicationTeam.injector(communicationUI.injector(AcademyTeamsTabComponent));
