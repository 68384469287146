import { IBasePathParams } from 'common/models/requestModels';

export interface IInfluencerModel extends Components.Schemas.InfluencerStatisticDto {}
export interface IInfluencerParams extends IBasePathParams, Paths.CommonControllerInfluencerStatistics.QueryParameters {}

export const influencerStatisticTableConfig = [
  {
    title: 'Started registration',
    dataIndex: 'totalRegCount',
    key: 'totalRegCount'
  },
  {
    title: 'Completed registration',
    dataIndex: 'completeRegCount',
    key: 'completeRegCount'
  }
];
