import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { commonTransport } from 'entities/Common/Common.transport';
import { ICommonStatisticModel } from 'entities/Common/Common.models';

const namespace = 'common';

export interface ICommonConnectedProps {
  commonStatistic: StoreBranch<ICommonStatisticModel>;
  getCommonStatistic(): void;
}

const CommonProvider = [new APIProvider(EActionsTypes.get, commonTransport.getStatistic)];

const branches = [new Branch('statistic', CommonProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationCommon = buildCommunication<ICommonConnectedProps>(strategy);
