import React from 'react';
import Typography from 'antd/es/typography';
import i18n from 'common/helpers/i18n';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import { ITestCollection, ITestCollectionFilter, ITestModel } from 'entities/Test/Test.models';
import { communicationTest, ITestConnectedProps } from 'entities/Test/Test.communication';
import { ESubtitleTypes, TestListItem } from 'entities/Test/components/List/TestListItem';

interface IComponentProps {
  playerId?: string;
  onItemClick?: (itemModel: ITestModel) => void;
  subtitle?: ESubtitleTypes;
  hideComplexity?: boolean;
}

type AllProps = IComponentProps & ITestConnectedProps;

class TestListComponent extends InfiniteList<ITestCollection, ITestModel, AllProps, ITestCollectionFilter> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getTestCollection, playerId, filter } = this.props;
    const body: ITestCollectionFilter = {
      ...params
    };

    if (playerId) {
      body.player = playerId;
    }
    if (filter?.isVisibleForTrainer) {
      body.isVisibleForTrainer = filter?.isVisibleForTrainer;
      body.isVisibleForRfs = false;
      body.isVisibleForPlayer = false;
    }
    if (filter?.isVisibleForPlayer) {
      body.isVisibleForPlayer = filter?.isVisibleForPlayer;
      body.isVisibleForTrainer = false;
      body.isVisibleForRfs = false;
    }
    if (filter?.isVisibleForRfs) {
      body.isVisibleForRfs = filter?.isVisibleForRfs;
      body.isVisibleForTrainer = true;
      body.isVisibleForPlayer = false;
    }

    return getTestCollection(body);
  };

  renderNoData = () => {
    return <Typography.Title level={5}>{i18n.t('Test list is empty')}</Typography.Title>;
  };

  renderListItem = (item: ITestModel) => {
    const { onItemClick = () => null, subtitle, hideComplexity } = this.props;
    return <TestListItem hideComplexity={hideComplexity} subtitle={subtitle} item={item} onItemClick={onItemClick} />;
  };

  getCollection = () => {
    return this.props.testCollection;
  };

  clearCollection = () => {
    return this.props.clearTestCollection();
  };
}

export const TestList = communicationTest.injector(TestListComponent);
