import React, { useEffect, useState, Fragment } from 'react';
import Typography from 'antd/es/typography';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Spin from 'antd/lib/spin';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { EAcademyTrainerInviteModalType } from 'entities/UI/UI.models';
import { communicationTrainer, ITrainerConnectedProps } from 'entities/Trainer/Trainer.communication';
import { AcademyTrainerInviteModal } from 'entities/Academy/components/View/Tabs/Trainers/AcademyTrainerInviteModal';
import { ITrainerAcademyRequestCollectionFilter } from 'entities/Trainer/Trainer.models';
import { TrainerAcademyList } from 'entities/Trainer/components/AcademyList/TrainerAcademyList';
import { TrainerAcademyListFilterCard } from 'entities/Trainer/components/AcademyList/TrainerAcademyListFilterCard';
import { IAcademyModel } from 'entities/Academy/Academy.models';

interface IProps {
  isDesktop: boolean;
  franchisorModel?: IAcademyModel | null;
}

type AllProps = IProps & IUIConnectedProps & ITrainerConnectedProps;

const AcademyTrainersTabComponent: React.FC<AllProps> = props => {
  const history = useHistory();
  const { academyId: urlAcademyId } = useParams();
  const { t } = useTranslation();
  const { openUIAcademyTrainerInviteModal, isDesktop, franchisorModel, trainerAcademyRequestCollection } = props;
  const [renderIndex, setRenderIndex] = useState(Math.random());
  const trainerCount = trainerAcademyRequestCollection?.data?.meta?.count;
  const isCurrentAuthAcademy = franchisorModel?.id === urlAcademyId;
  const academyId = isCurrentAuthAcademy ? undefined : urlAcademyId || undefined;

  const initState: ITrainerAcademyRequestCollectionFilter = {
    search: undefined,
    hasTeam: undefined,
    teamModel: undefined,
    academyFilter: undefined
  };

  const [state, setState] = useState<Partial<ITrainerAcademyRequestCollectionFilter>>({
    ...initState,
    academyFilter: isCurrentAuthAcademy ? { id: franchisorModel?.id, name: franchisorModel?.name } : undefined,
    ...queryToObject<Partial<ITrainerAcademyRequestCollectionFilter>>(initState)
  });

  const debPush = useDebouncedCallback((val: any) => {
    history.replace(val);
  }, 300);

  useEffect(() => {
    debPush.callback({ search: objectToQuery(state) });
  }, [state]);

  const showAddTrainerModal = () => {
    openUIAcademyTrainerInviteModal({ modalType: EAcademyTrainerInviteModalType.AddTrainer });
  };

  return (
    <>
      <Row className={`pb-5 ${!isDesktop ? 'px-3' : ''}`} justify="space-between" align="middle">
        <Typography.Title className="mb-0" level={isDesktop ? 2 : 3}>
          {t('Trainers')} {trainerCount !== undefined ? `(${trainerCount})` : <Spin className="ml-4" />}
        </Typography.Title>
        <Button size={isDesktop ? 'large' : 'middle'} type="primary" onClick={showAddTrainerModal}>
          {t('Invite trainer')}
        </Button>
      </Row>
      <Fragment key={renderIndex}>
        <TrainerAcademyListFilterCard
          isFranshisor={!!franchisorModel}
          filter={state}
          onFilterChange={partialFilter => setState({ ...state, ...partialFilter })}
        />
      </Fragment>
      <TrainerAcademyList
        className="mt-5 academy-view-page__trainers__list"
        academyId={academyId}
        clearFilter={() => {
          setState({ ...initState });
          setRenderIndex(Math.random());
        }}
        elementId="basicLayout"
        filter={state}
      />
      <AcademyTrainerInviteModal />
    </>
  );
};

export const AcademyTrainersTab = communicationTrainer.injector(communicationUI.injector(AcademyTrainersTabComponent));
