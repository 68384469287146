import React from 'react';
import { Col, Row } from 'antd';
import Typography from 'antd/es/typography';
import { nameBuilder } from 'common/helpers/name.helper';
import { ITrainerTeamModel } from 'entities/Trainer/Trainer.models';

interface IComponentProps {
  trainers?: ITrainerTeamModel[];
}

const ARRAY_LENGTH = 3;

export const TrainersBadge = ({ trainers }: IComponentProps) => {
  const slicedTrainerArray = trainers?.slice(0, ARRAY_LENGTH);
  const trainersOverflow = (trainers?.length || 0) - ARRAY_LENGTH;

  return (
    <Row className="teams-badge" gutter={[8, 8]} align="middle">
      {slicedTrainerArray?.map(({ firstName, lastName, email, id }) => (
        <Col key={id} style={{ maxWidth: `${100 / slicedTrainerArray?.length}%` }}>
          <Typography.Text ellipsis title={name} className="teams-badge__element">
            {nameBuilder(firstName, lastName, email)}
          </Typography.Text>
        </Col>
      )) || null}
      {trainersOverflow > 0 && (
        <Typography.Text ellipsis title={name} className="teams-badge__element">
          +{trainersOverflow}
        </Typography.Text>
      )}
    </Row>
  );
};
