import React, { useMemo, useCallback } from 'react';
import List from 'antd/es/list';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ERoutesPublic } from 'common/models/routesModel';
import { ModalUrls } from 'common/const/rating-page.const';
import { EGoogleTagEvents } from 'common/const/google-tag-manager.const';
import { googleTagManagerPushEvent } from 'common/helpers/google-tag-manager.help';
import ShieldImage from 'app/assets/images/shield.png';
import { IPublicPlayerModel } from 'entities/PublicPlayer/PublicPlayer.models';
import { ESkillTagName, TSkillName } from 'entities/Skill/Skill.models';
import { EPlayerVisibilityMode } from 'entities/Player/Player.models';
import { getImageUrl } from 'entities/Image/Image.helper';

type AllProps = IComponentProps;

interface IComponentProps {
  item: IPublicPlayerModel;
}

const PublicPlayersListItemComponent = ({ item }: AllProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { id, rating, firstName, lastName, ageGroup, bestSkills, playerSkills, image, visibility, publicity } = item;
  const isPlayerOpened = visibility === EPlayerVisibilityMode.Public && publicity;

  const avatar = useMemo(() => {
    const url = getImageUrl(image?.id) || '/icons/for-preview/default-player-avatar.png';

    return { backgroundImage: `url(${url})` };
  }, [image]);

  const skillsValues = useMemo(() => {
    return bestSkills?.reduce((acc: { tag: TSkillName | undefined; value: number | undefined }[], bestSkill: string) => {
      const skillObject = playerSkills?.find(playerSkill => playerSkill?.skill?.tag === bestSkill);
      return [...acc, { tag: skillObject?.skill?.tag, value: skillObject?.value }];
    }, []);
  }, [playerSkills, bestSkills]);

  const onOpenCard = useCallback(() => {
    googleTagManagerPushEvent(EGoogleTagEvents.RatingPageOpenPlayerClick);
  }, []);

  const onCardClick = useCallback(() => {
    if (isPlayerOpened) {
      window.open(`${ERoutesPublic.RatingPlayer}/${id}${location.search}`);
    }
  }, [isPlayerOpened]);

  return (
    <List.Item key={id} className="rating-page__player-item">
      {!isPlayerOpened && (
        <div className="rating-page__player-item__open-card-container" onClick={onOpenCard}>
          <Link to={{ ...location, hash: ModalUrls.Player }}>
            <div className="rating-page__player-item__open-card">
              {t('Open card')?.replace(/&nbsp;/g, String.fromCharCode(160))}
            </div>
          </Link>
        </div>
      )}
      <div
        className={`rating-page__player-item__container ${
          !isPlayerOpened ? 'hidden' : 'rating-page__player-card-open cursor-pointer'
        }`}
        onClick={onCardClick}
      >
        <div className="rating-page__player-item__avatar" style={avatar} />
        <div className="rating-page__player-item__shield-icon">
          <img width="100%" height="auto" alt="Shield icon" src={ShieldImage} />
        </div>
        <div className="rating-page__player-item__content">
          <div className="rating-page__player-item__content__top">
            <div className="rating-page__player-item__rating">
              <div className="rating-page__player-item__rating__top">
                <span className="rating-page__player-item__rating__top__value">{rating}</span>
                <span className="rating-page__player-item__rating__top__label">{t('Rating')}</span>
              </div>
              <div className="rating-page__player-item__rating__bottom">
                <span className="rating-page__player-item__rating__bottom__group">{t(ageGroup ?? '')}</span>
              </div>
            </div>
          </div>
          <div className="rating-page__player-item__content__bottom">
            <div className="rating-page__player-item__person-info">
              <div className="rating-page__player-item__person-info__name">
                <span>{firstName || t('First name')}</span>
                <span>{lastName || t('Hidden')}</span>
              </div>
              <div className="rating-page__player-item__person-info__region" />
            </div>
            <div className="rating-page__player-item__person-data">
              {skillsValues?.map((skill, index) => (
                <div key={skill.tag} className={`rating-page__player-item__person-data__${index}`}>
                  <span
                    className={`rating-page__player-item__person-data__value ${skill?.value && skill.value > 99 ? 'big' : ''}`}
                  >
                    {skill.value}
                  </span>
                  <span className="rating-page__player-item__person-data__label">
                    {t(skill.tag ? `${ESkillTagName[skill.tag]}` : 'Unknown')}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="rating-page__player-item__logo">
            <img alt="Logo" width={54} height={12} src="/icons/for-preview/share-logo.png" />
          </div>
        </div>
      </div>
    </List.Item>
  );
};

export const PublicPlayersListItem = PublicPlayersListItemComponent;
