import Typography from 'antd/es/typography';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { converterFromBeMeasurementValue, EMeasurementSystem } from 'common/helpers/units-converter.helper';
import { MeasurementContext } from 'common/components/Provider/MeasurementContext';
import { EResultTypes } from 'entities/PlayerTest/PlayerTest.models';

interface IComponentProps {
  weight?: number;
}

export const PlayerWeightBadge = ({ weight }: IComponentProps) => {
  const { t } = useTranslation();
  const { measurementSystem } = useContext(MeasurementContext);

  return (
    <Typography.Title level={5} title={t('Weight')} className="px-3 py-1 mb-0 player-weight-badge radius-round t-align-c">
      {measurementSystem === EMeasurementSystem.Metric
        ? t('kg', { value: weight })
        : `${converterFromBeMeasurementValue(weight, EResultTypes.KG, measurementSystem) || '-'} ${t('lb')}`}
    </Typography.Title>
  );
};
