import { ButtonProps } from 'antd';
import React from 'react';
import { ButtonType } from 'antd/es/button/button';
import Row from 'antd/es/row';
import { useMediaPredicate } from 'react-media-hook';
import { useTranslation } from 'react-i18next';
import Button from 'antd/es/button';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { ReactComponent as EmailPush } from 'app/assets/icons/EmailPush.svg';
import { ReactComponent as EmailSuccess } from 'app/assets/icons/EmailSuccess.svg';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { EMentorInviteModalType } from 'entities/UI/UI.models';

interface IComponentProps {
  playerId?: string;
  sendAgain?: boolean;
  type?: ButtonType;
  inviteFromList?: boolean;
  disabled?: boolean;
  block?: boolean;
  size?: 'small' | 'default' | 'large';
  className?: string;
}
type AllProps = IUIConnectedProps & IComponentProps & ButtonProps;

const MentorRequestButtonComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const {
    openUIMentorInviteModal,
    playerId,
    sendAgain,
    inviteFromList,
    type = 'primary',
    disabled = false,
    block,
    size = 'large',
    className
  } = props;
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`) || !inviteFromList;

  const openInviteMentorModal = (e: any) => {
    e?.stopPropagation();
    e?.preventDefault();
    openUIMentorInviteModal({ modalType: EMentorInviteModalType.AddMentor, playerId, inviteFromList });
  };
  const buttonText = sendAgain ? 'Invite again' : 'Invite mentor';
  const buttonTitle = sendAgain ? 'Awaiting confirmation' : 'Invite mentor';

  return (
    <Button
      size={size}
      onClick={openInviteMentorModal}
      block={block}
      type={type}
      title={t(buttonTitle)}
      disabled={disabled}
      className={className}
    >
      <Row justify="center" align="middle" wrap={false}>
        <Row align="middle" className={isDesktop ? 'mr-3' : ''}>
          {sendAgain ? <EmailSuccess /> : <EmailPush />}
        </Row>
        {isDesktop ? t(buttonText) : null}
      </Row>
    </Button>
  );
};

export const MentorRequestButton = communicationUI.injector(MentorRequestButtonComponent);
