import React from 'react';
import i18n from 'i18next';
import Modal from 'antd/es/modal/Modal';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { EMentorRequestModalType } from 'entities/UI/UI.models';
import { MentorRequestCreateForm } from 'entities/MentorRequest/components/MentorRequestCreateForm';
import { MentorRequestModalFinal } from 'entities/MentorRequest/components/MentorRequestModalFinal';
import { MentorRequestModalInvite } from 'entities/MentorRequest/components/MentorRequestModalInvite';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';

type AllProps = IUIConnectedProps & IMentorRequestConnectedProps;

class MentorRequestModalComponent extends React.PureComponent<AllProps> {
  render() {
    const { UIMentorRequestModal } = this.props;
    const isVisible = UIMentorRequestModal.data?.isVisible;
    const modalType = UIMentorRequestModal.data?.modalType;
    const isAddModal = modalType === EMentorRequestModalType.AddPlayer;
    const isAfterAddModal = modalType === EMentorRequestModalType.AfterAddPlayer;
    const isInviteModal = modalType === EMentorRequestModalType.InvitePlayer;
    return (
      <Modal
        forceRender
        bodyStyle={{ border: 'none' }}
        width={376}
        visible={isVisible}
        onCancel={this.closeModal}
        footer={false}
        title={i18n.t('Adding a player')}
      >
        {isAddModal && <MentorRequestCreateForm onClose={this.closeModal} />}
        {isAfterAddModal && <MentorRequestModalFinal onClose={this.closeModal} />}
        {isInviteModal && <MentorRequestModalInvite onClose={this.closeModal} />}
      </Modal>
    );
  }

  closeModal = () => {
    const { closeUIMentorRequestModal, clearMentorRequestModel } = this.props;
    clearMentorRequestModel();
    closeUIMentorRequestModal();
  };
}

export const MentorRequestModal = communicationMentorRequest.injector(communicationUI.injector(MentorRequestModalComponent));
