import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import Row from 'antd/es/row';
import { ScrollSync } from 'react-scroll-sync';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';
import Col from 'antd/es/col';
import { useMediaPredicate } from 'react-media-hook';
import classNames from 'classnames';
import { queryToObject, objectToQuery } from 'common/helpers/filters.helper';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { IPlayerConnectedProps, communicationPlayer } from 'entities/Player/Player.communication';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';
import { PlayerSelector } from 'entities/Player/components/Selectors/PlayerSelector';
import { EUserRole } from 'entities/User/User.models';
import { IPlayerModel, IPlayerProfileModel } from 'entities/Player/Player.models';
import { PlayersCompareSkills } from 'entities/Player/components/PlayersCompare/PlayersCompareSkills';
import { PlayersCompareCards } from 'entities/Player/components/PlayersCompare/PlayersCompareCards';

type AllProps = IPlayerConnectedProps & IAuthConnectedProps;

interface IPlayersCompareInQuery {
  playersCompare?: string[];
}

const { playersCompare: playersInQuery } = queryToObject<IPlayersCompareInQuery>({
  playersCompare: undefined
}) as IPlayersCompareInQuery;

const Component: React.FC<AllProps> = props => {
  const history = useHistory();
  const { t } = useTranslation();
  const [selectorUpdateKey, setSelectorUpdateKey] = useState<string | number>(Math.random());
  const {
    getPlayerCompare,
    clearPlayerCompare,
    playerCompare,
    addPlayerCompare,
    savePlayerCompare,
    getPlayerProfileCollection,
    playerProfileCollection,
    authUser
  } = props;
  const { data: playersToCompare } = playerCompare;
  const { data: profileCollection, loading: collectionLoading } = playerProfileCollection;
  const isMentor = useMemo(() => authUser.data?.role === EUserRole.Mentor, [authUser]);
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);

  useEffect(() => {
    if (playersInQuery?.length) {
      savePlayerCompare(playersInQuery);
    } else {
      getPlayerCompare();
    }

    return clearPlayerCompare;
  }, []);

  useEffect(() => {
    if (playersToCompare && !collectionLoading) {
      getPlayerProfileCollection({
        players: playersToCompare.map(id => ({ id, keepCache: true }))
      });
    }

    history.replace({ search: objectToQuery({ playersCompare: playersToCompare }) });
  }, [playersToCompare]);

  const profiles: IPlayerProfileModel[] | undefined = useMemo(() => {
    const profilesData: { [key in string]: IPlayerProfileModel } | undefined = profileCollection?.data?.reduce((acc, profile) => {
      return {
        ...acc,
        [profile.playerId]: profile
      };
    }, {});

    return playerCompare?.data?.map(playerId => profilesData?.[playerId])?.filter(profile => Boolean(profile)) as
      | IPlayerProfileModel[]
      | undefined;
  }, [playerCompare, profileCollection]);

  const onSelectPlayerCallback = useCallback(
    (player?: IPlayerModel) => {
      if (player?.id) {
        addPlayerCompare(player.id);
        setSelectorUpdateKey(player.id);
      }
    },
    [addPlayerCompare, setSelectorUpdateKey]
  );

  return (
    <div className={classNames('players-compare', { 'px-5': !isDesktop })}>
      <Typography.Title level={2} className="font-weight-5 pl-2 mb-7">
        {t('Compare players')}
      </Typography.Title>
      <Row align="middle" className="mb-9 pl-2 players-compare__search">
        <Typography.Title level={5} className={`mr-5 ${isDesktop ? 'mb-0' : ''}`}>
          {t('Select players to compare')}
        </Typography.Title>
        <Col xs={24} md={10} className="players-compare__search__field">
          <PlayerSelector
            key={selectorUpdateKey}
            excludePlayers={playersToCompare}
            isForMentor={isMentor}
            academyId={authUser.data?.academyWorker?.academy?.id}
            onChange={onSelectPlayerCallback}
          />
        </Col>
      </Row>
      {profiles?.length || collectionLoading ? (
        <Spin spinning={collectionLoading}>
          {profiles && Boolean(profiles?.length) && (
            <ScrollSync vertical={false}>
              <>
                <PlayersCompareCards profiles={profiles} isLoading={collectionLoading} />
                <PlayersCompareSkills profiles={profiles} />
              </>
            </ScrollSync>
          )}
        </Spin>
      ) : (
        <Row className="width-full" justify="center">
          <Typography.Title level={5} className="color-disable">
            {t('No players added to comparison yet')}
          </Typography.Title>
        </Row>
      )}
    </div>
  );
};

export const PlayersCompare = communicationAuth.injector(communicationPlayer.injector(Component));
