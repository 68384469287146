import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import Row from 'antd/es/row';
import Spin from 'antd/es/spin';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import Col from 'antd/es/col';
import classNames from 'classnames';
import { Paper } from 'common/components/Paper/Paper';
import { StripeContext } from 'common/components/Stripe/StripeProvider';
import { formatDateFromServer } from 'common/helpers/date.helper';
import { clientDateFullMonthFormat } from 'common/models/dateModels';
import {
  getStripeSubscriptionCurrentTariffEndDate,
  getStripeSubscriptionNextTariff,
  getStripeSubscriptionTariffId,
  isAcademyHideTariffs,
  isStripeTrialVersion
} from 'entities/Subscription/helpers/subscription.helper';
import { TariffsStripeComponent } from 'entities/Tariff/components/TariffsStripe/TariffsStripeComponent';
import { PlayerProviderContext } from 'entities/Player/components/PlayerProvider';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';
import { communicationSubscription, ISubscriptionConnectedProps } from 'entities/Subscription/Subscription.communication';
import { IUIConnectedProps, communicationUI } from 'entities/UI/UI.communication';
import { StripeSavedPaymentMethodCard } from 'entities/Subscription/components/StripeSavedPaymentMethodCard';
import { StripeGiftcardCard } from 'entities/Subscription/components/StripeGiftcardCard';
import { PaymentsHistory } from 'entities/Subscription/components/PaymentAndSubscriptions/PaymentsHistory';
import { StripeSubscriptionInfoCard } from 'entities/Subscription/components/StripeSubscriptionInfoCard/StripeSubscriptionInfoCard';
import { CANCEL_SUBSCRIPTION_TARIFF_ID } from 'entities/Tariff/Tariff.models';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';

interface IProps {
  isDesktop: boolean;
}

type AllProps = IProps & IMentorRequestConnectedProps & ISubscriptionConnectedProps & IUIConnectedProps & IAuthConnectedProps;

const PlayerSettingTabStripeSubscriptionComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const {
    authUser,
    mentorRequestModel,
    subscriptionModel,
    getMentorRequestModel,
    getSubscriptionModel,
    openUIStripePaymentModal,
    openUIStripeCancelSubscriptionModal
  } = props;
  const { player } = useContext(PlayerProviderContext);
  const { lastSavedPaymentMethod } = useContext(StripeContext);
  const { data: mentorRequest, loading: mentorRequestLoading } = mentorRequestModel;
  const { data: subscription, loading: subscriptionLoading } = subscriptionModel;
  const userPaymentMethod = authUser?.data?.stripePaymentMethod;
  const mentorRequestId = player?.data?.mentorRequest?.id;
  const playerId = player?.data?.id;
  const isPaid = player?.data?.isPaid;
  const mentorId = mentorRequest?.mentor?.id;
  const subscriptionId = mentorRequest?.subscription?.id;

  const hideTariffs = useMemo(() => isAcademyHideTariffs(player?.data), [player]);
  const selectedTariffId = useMemo(() => getStripeSubscriptionTariffId(subscription), [subscription]);
  const tariffEnd = useMemo(() => {
    const date = getStripeSubscriptionCurrentTariffEndDate(subscription);

    return date ? formatDateFromServer(date, clientDateFullMonthFormat) : undefined;
  }, [subscription]);
  const nextTariff = useMemo(() => getStripeSubscriptionNextTariff(subscription), [subscription]);
  const isTrialPhaseNow = useMemo(() => isStripeTrialVersion(subscription), [subscription]);

  const commonLoading = useMemo(() => mentorRequestLoading || subscriptionLoading, [mentorRequestLoading, subscriptionLoading]);

  const paymentMethod = useMemo(() => lastSavedPaymentMethod?.card || userPaymentMethod, [
    userPaymentMethod,
    lastSavedPaymentMethod
  ]);

  useEffect(() => {
    if (mentorRequestId && mentorRequestId !== mentorRequest?.id) {
      getMentorRequestModel(mentorRequestId);
    }
  }, [mentorRequestId, mentorRequest, mentorRequestLoading]);

  useEffect(() => {
    if (subscriptionId) {
      getSubscriptionModel(subscriptionId);
    }
  }, [subscriptionId]);

  const onTariffSelectCallback = useCallback(
    tariffId => {
      if (tariffId) {
        if (tariffId === CANCEL_SUBSCRIPTION_TARIFF_ID) {
          openUIStripeCancelSubscriptionModal({
            subscription: subscriptionId
          });
        } else {
          openUIStripePaymentModal({
            tariff: tariffId,
            title: t('Subscription payment'),
            withCoupon: true,
            withCardDetails: !paymentMethod,
            mentorRequest: mentorRequestId,
            subscription: subscriptionId
          });
        }
      }
    },
    [openUIStripePaymentModal, paymentMethod, mentorRequestId, subscriptionId]
  );

  return (
    <Spin size="large" spinning={commonLoading} wrapperClassName="spin-opacity-0">
      <Paper className="p-7">
        <Row className={classNames({ 'mb-9': !hideTariffs && !isPaid })}>
          {!hideTariffs && !isPaid && (
            <Row>
              <Typography.Title level={3} className="font-weight-5 mb-5">
                {t('Player subscription')}
              </Typography.Title>
            </Row>
          )}
          <Row className="width-full">
            <Col span={24}>
              <TariffsStripeComponent
                hidden={isPaid || hideTariffs}
                isTrialPhaseNow={isTrialPhaseNow}
                tariffEnd={tariffEnd}
                nextTariff={nextTariff?.id}
                nextTariffStart={nextTariff?.periodStart}
                onSelectTariff={onTariffSelectCallback}
                selectedTariffId={selectedTariffId}
                subscriptionId={subscription?.id}
                playerId={playerId}
                mentorId={mentorId}
              />
            </Col>
          </Row>
        </Row>
        <Row className={isPaid || hideTariffs ? '' : 'mb-9'}>
          <Row className="width-full">
            <Typography.Title level={3} className="font-weight-5 mb-5">
              {t('Payment')}
            </Typography.Title>
          </Row>
          <Row className="width-full" gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <StripeSubscriptionInfoCard mentorRequest={mentorRequest} subscription={subscription} />
            </Col>
            {!hideTariffs && !isPaid && (
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                <StripeSavedPaymentMethodCard userPaymentMethod={userPaymentMethod} />
              </Col>
            )}
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <StripeGiftcardCard subscriptionId={subscriptionId} mentorRequestId={mentorRequestId} />
            </Col>
          </Row>
        </Row>
        {!hideTariffs && !isPaid && (
          <Row>
            <Row className="width-full">
              <Typography.Title level={3} className="font-weight-5 mb-5">
                {t('Bills')}
              </Typography.Title>
            </Row>
            <Row className="width-full">
              <PaymentsHistory className="width-full" isEmpty={!subscriptionId} subscriptionId={subscriptionId} />
            </Row>
          </Row>
        )}
      </Paper>
    </Spin>
  );
};

export const PlayerSettingTabStripeSubscription = communicationAuth.injector(
  communicationSubscription.injector(
    communicationMentorRequest.injector(communicationUI.injector(PlayerSettingTabStripeSubscriptionComponent))
  )
);
