import React from 'react';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import AcademyRequestSuccessImg from 'app/assets/icons/academy-request-success.svg';

interface IComponentProps {
  onClose: () => void;
}
type AllProps = IComponentProps;

const MentorRequestToAcademySuccessComponent: React.FC<AllProps> = props => {
  const { onClose } = props;
  const { t } = useTranslation();
  const modalTitle = t('Modal success');

  return (
    <>
      <Row className="mt-9" justify="center">
        <img alt={modalTitle} height={128} width={128} src={AcademyRequestSuccessImg} className="cursor-pointer" />
      </Row>
      <Typography.Title level={3} className="t-align-c mt-5 mb-5" title={modalTitle}>
        {modalTitle}
      </Typography.Title>
      <Typography.Text className="d-block t-align-c" title={t('Modal success description')}>
        {t('Modal success description')}
      </Typography.Text>
      <Row className="mt-6" justify="center">
        <Button block type="primary" size="large" onClick={onClose} title={t('Continue')}>
          {t('Continue')}
        </Button>
      </Row>
    </>
  );
};

export const MentorRequestToAcademySuccess = MentorRequestToAcademySuccessComponent;
