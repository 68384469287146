import React from 'react';
import { Redirect } from 'react-router';
import { ERoutesPublic } from 'common/models/routesModel';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps;

const RootPublicPageComponent: React.FC<AllProps> = props => {
  const { authModel } = props;
  const { loading } = authModel;

  if (loading) {
    return <LoadingSpin />;
  }
  return <Redirect to={ERoutesPublic.Welcome} />;
};
export const RootPublicPage = communicationAuth.injector(RootPublicPageComponent);
