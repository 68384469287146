import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal/Modal';
import Typography from 'antd/lib/typography';
import { PlayerProviderContext } from 'entities/Player/components/PlayerProvider';
import {
  isAcademyHideTariffs,
  isStripeTrialVersion,
  isSubscriptionActive
} from 'entities/Subscription/helpers/subscription.helper';
import { SubscriptionButton } from 'entities/Subscription/components/SubscriptionButton';
import { ESubscriptionsEngines } from 'entities/Subscription/Subscription.models';

const ITEM_NAME = 'SUB_MODAL';

const PlayerSubscriptionModalComponent: React.FC = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();
  const [visible, setVisible] = useState<boolean>(false);
  const { player } = useContext(PlayerProviderContext);
  const { data: playerData } = player || {};
  const playerId = playerData?.id;
  const pathname = history?.location?.pathname;
  const subscription = playerData?.mentorRequest?.subscription;
  const mentorRequestId = playerData?.mentorRequest?.id;
  const mentorRequestSubscriptionEngine = playerData?.mentorRequest?.subscriptionEngine;
  const isSubscriptionActiveNow = useMemo(() => isSubscriptionActive(subscription), [subscription]);
  const isSubscriptionTab = useMemo(() => pathname?.indexOf('settings') >= 0, [pathname]);
  const isTrialVersion = useMemo(() => isStripeTrialVersion(subscription), [subscription]);
  const isPaid = playerData?.isPaid;
  const hideTariffs = useMemo(() => isAcademyHideTariffs(playerData), [playerData]);

  const setTime = useCallback(() => {
    const currentTime = moment().format();
    localStorage.setItem(`${ITEM_NAME}_${playerId}`, currentTime);
  }, []);

  const showModal = useCallback(() => {
    const localStorageDate = localStorage.getItem(`${ITEM_NAME}_${playerId}`);

    return localStorageDate ? moment().diff(moment(localStorageDate), 'minutes') >= 60 : true;
  }, []);
  const timeIsOut = showModal();

  const onCancel = useCallback(() => {
    setVisible(false);
    setTime();
  }, []);

  useEffect(() => {
    if (!isSubscriptionActiveNow && !isTrialVersion && !isPaid && !hideTariffs && timeIsOut) {
      setVisible(true);
    }
    if (isSubscriptionTab) {
      onCancel();
    }
  }, [match, timeIsOut, isSubscriptionActiveNow, isAcademyHideTariffs, isTrialVersion, isSubscriptionTab]);

  return (
    <Modal
      width={360}
      footer={null}
      centered
      destroyOnClose
      visible={visible}
      title={<div className="t-align-c">{t('Player subscription')}</div>}
      onCancel={e => {
        e.stopPropagation();
        e.preventDefault();
        onCancel();
      }}
    >
      <Typography.Title level={5} className="mb-5 t-align-c">
        {t('We ask you to pay a subscription for a digital player profile')}
      </Typography.Title>

      <Row justify="center" align="middle">
        <Col span={18}>
          <SubscriptionButton
            block
            playerId={playerId}
            size="large"
            hideUnsubscribeBtn
            mentorRequestId={mentorRequestId}
            subscription={subscription}
            hideTariffs={hideTariffs}
            isPaid={isPaid}
            mentorRequestSubscriptionEngine={mentorRequestSubscriptionEngine as ESubscriptionsEngines}
          />
        </Col>
      </Row>
      <Typography.Title level={5} className="mt-5 color-text-secondary t-align-c">
        {t('Thank you for developing a football career with us')}
      </Typography.Title>
    </Modal>
  );
};

export const PlayerSubscriptionModal = PlayerSubscriptionModalComponent;
