import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  ITestCollection,
  ITestCollectionFilter,
  ITestModel,
  ITestResultsHistoryCollection,
  ITestResultsHistoryCollectionFilter,
  ITestTeamHistoryCollection,
  ITestTeamHistoryCollectionFilter,
  ITestUpdateData
} from 'entities/Test/Test.models';

const basePath = '/tests';
const teamBasePath = '/teams';

class TestTransport extends BaseHttpTransport<
  AxiosStatic,
  ITestModel,
  ITestCollection,
  null,
  ITestUpdateData,
  ITestCollectionFilter
> {
  public getTestResultHistoryCollection = ({
    id,
    ...params
  }: ITestResultsHistoryCollectionFilter): Promise<ITestResultsHistoryCollection> =>
    axios.get(`${basePath}/${id}/results-history${objectToQuery(params)}`);
  public getTestTeamHistoryCollection = ({
    id,
    ...params
  }: ITestTeamHistoryCollectionFilter): Promise<ITestTeamHistoryCollection> =>
    axios.get(`${teamBasePath}/${id}/tests${objectToQuery(params)}`);
}

export const testTransport = new TestTransport(basePath, axios);
