import React from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Card from 'antd/es/card';
import Skeleton from 'antd/es/skeleton';

interface IComponentProps {}

type AllProps = IComponentProps;

const avatarSize = 56;

const PrivatePlayerListItemSkeletonComponent: React.FC<AllProps> = () => (
  <Card hoverable className="p-5 private-player-card bg-component border-none radius-default">
    <Col xs={24}>
      <Row wrap={false} align="middle">
        <Col span={10}>
          <Row wrap={false} align="middle">
            <Skeleton active paragraph={{ rows: 1, width: '100%' }} />
            <Skeleton.Avatar active size={avatarSize} shape="circle" />
          </Row>
        </Col>
        <Col span={14}>
          <Skeleton active paragraph={{ rows: 1, width: '100%' }} />
        </Col>
      </Row>
    </Col>
    <Skeleton active paragraph={{ rows: 1, width: '100%' }} className="width-full" />
  </Card>
);

export const PrivatePlayerListItemSkeleton = PrivatePlayerListItemSkeletonComponent;
