import { IBasePathParams } from 'common/models/requestModels';
import { ILocationModel } from 'common/helpers/location.helper';

export interface IPublicPlayerCollection extends Components.Schemas.PlayerForPublicCollectionDto {}
export interface IPublicPlayerCollectionFilter extends Paths.PublicPlayerControllerList.QueryParameters {
  location?: ILocationModel;
  playerName?: string;
  ageGroup?: Paths.PlayerControllerGetAcademyListWithPlayer.Parameters.AgeGroup;
}

export interface IPublicPlayerModel extends Components.Schemas.PlayerForPublicDto {}
export interface IPublicPlayerCreateParams extends Components.Schemas.CreatePlayerDto {}
export interface IPublicPlayerUpdateParams extends Components.Schemas.UpdatePlayerDto, IBasePathParams {}
export interface IPublicPlayerParamsModel extends IPublicPlayerUpdateParams, IBasePathParams {}

export enum EPublicPlayerTabKeys {
  Training = 'training'
}

export enum EPublicPlayerWFS {
  PlayingWithWeakFoot = 'playingWithWeakFoot'
}

export enum EPublicPlayerSorter {
  Rating = 'rating',
  Age = 'ageGroup'
}

export enum EPublicPlayerOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum EPublicPlayerAgeGroup {
  U7 = 'U7',
  U8 = 'U8',
  U9 = 'U9',
  U10 = 'U10',
  U11 = 'U11',
  U12 = 'U12',
  U13 = 'U13',
  U14 = 'U14',
  U15 = 'U15',
  U16 = 'U16',
  U17 = 'U17',
  U17p = 'U17+'
}

export const PublicPlayerAgeGroup = [
  EPublicPlayerAgeGroup.U7,
  EPublicPlayerAgeGroup.U8,
  EPublicPlayerAgeGroup.U9,
  EPublicPlayerAgeGroup.U10,
  EPublicPlayerAgeGroup.U11,
  EPublicPlayerAgeGroup.U12,
  EPublicPlayerAgeGroup.U13,
  EPublicPlayerAgeGroup.U14,
  EPublicPlayerAgeGroup.U15,
  EPublicPlayerAgeGroup.U16,
  EPublicPlayerAgeGroup.U17,
  EPublicPlayerAgeGroup.U17p
] as EPublicPlayerAgeGroup[];

export const PUBLIC_PLAYER_RATINGS_MAP = [
  { limit: 95, value: 5 },
  { limit: 90, value: 4.5 },
  { limit: 87, value: 4 },
  { limit: 84, value: 3.5 },
  { limit: 80, value: 3 },
  { limit: 75, value: 2.5 },
  { limit: 70, value: 2 },
  { limit: 60, value: 1.5 },
  { limit: 50, value: 1 },
  { limit: 40, value: 0 }
];

export const gridPublicPlayerConfig = {
  gutter: 20,
  xs: 2,
  sm: 3,
  md: 3,
  lg: 4,
  xl: 5,
  xxl: 5
};

export const EPlayerVisibility = {
  All: 'all',
  Subscribed: 'subscribed',
  Nobody: 'nobody'
};
