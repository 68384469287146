import React from 'react';
import Modal from 'antd/es/modal/Modal';
import { useTranslation } from 'react-i18next';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { EFranchiseInviteModalType } from 'entities/UI/UI.models';
import { FranchiseInviteCreateForm } from 'entities/Franchise/components/FranchiseInviteCreateForm';
import { FranchiseInviteModalFinal } from 'entities/Franchise/components/FranchiseInviteModalFinal';
import { communicationFranchise, IFranchiseConnectedProps } from 'entities/Franchise/Franchise.communication';

type AllProps = IUIConnectedProps & IFranchiseConnectedProps;

const FranchiseInviteModalComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { UIFranchiseInviteModal, closeUIFranchiseInviteModal, clearFranchiseRequestModel } = props;
  const isVisible = UIFranchiseInviteModal.data?.isVisible;
  const modalType = UIFranchiseInviteModal.data?.modalType;
  const isAddModal = modalType === EFranchiseInviteModalType.AddAcademy;
  const isAfterAddModal = modalType === EFranchiseInviteModalType.AfterAddAcademy;

  const closeModal = () => {
    clearFranchiseRequestModel();
    closeUIFranchiseInviteModal();
  };

  return (
    <Modal
      forceRender
      bodyStyle={{ border: 'none' }}
      width={376}
      visible={isVisible}
      onCancel={closeModal}
      footer={false}
      title={t('Academy invitation')}
    >
      {isAddModal && <FranchiseInviteCreateForm onClose={closeModal} />}
      {isAfterAddModal && <FranchiseInviteModalFinal onClose={closeModal} />}
    </Modal>
  );
};

export const FranchiseInviteModal = communicationFranchise.injector(communicationUI.injector(FranchiseInviteModalComponent));
