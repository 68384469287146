import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  buildCollectionResponseFormatter,
  buildCollectionPreRequestDataMapper,
  getStartType,
  getSuccessType
} from '@axmit/redux-communications';
import message from 'antd/es/message';
import { put, takeLatest } from 'redux-saga/effects';
import { IError } from 'fe-error-helper';
import { translateToasts } from 'common/helpers/translate.helper';
import { copyObject } from 'common/helpers/normalize.helper';
import {
  EAcademySuccessMessage,
  IAcademyCreateParams,
  IAcademyModel,
  TAcademyParamsModel,
  IAcademyUpdateParams,
  IAcademyTestUpdateParams,
  IAcademyTestCollectionFilter,
  IAcademyTestCollection,
  IAcademyPlayerToAcademyParams,
  IAcademyUpdateTestsBalance
} from 'entities/Academy/Academy.models';
import { academyTransport } from 'entities/Academy/Academy.transport';
import { IAcademyCollection, IAcademyCollectionFilter } from 'entities/Academy/Academy.models';
import { playerTransport } from 'entities/Player/Player.transport';
import {
  IPlayerAcademyCollectionFilter,
  IPlayerAcademyModelParams,
  IPlayerModel,
  IPlayerParamsModel,
  IPlayerCollection
} from 'entities/Player/Player.models';
import { updateAuthAcademy, refreshAuthAcademySilent } from 'entities/Auth/Auth.communication';

export const namespace = 'academy';
export const playerCollection = 'playerCollection';
export const playerModel = 'playerModel';

export interface IAcademyConnectedProps {
  academyModel: StoreBranch<IAcademyModel, TAcademyParamsModel>;
  academyCollection: StoreBranch<IAcademyCollection, IAcademyCollectionFilter>;
  academyCollectionForPlayer: StoreBranch<IAcademyCollection, IPlayerAcademyCollectionFilter>;
  academyBaseResults: StoreBranch<IAcademyTestCollection, IAcademyTestCollectionFilter>;
  academyPlayerModel: StoreBranch<IPlayerModel, IPlayerParamsModel, IError>;

  getAcademyModel(id: string): void;
  getForPlayerAcademyModel(params: IPlayerAcademyModelParams): void;
  addAcademyModel(params: IAcademyCreateParams): void;
  updateAcademyModel(params: IAcademyUpdateParams): void;
  updateBalanceAcademyModel(params: IAcademyUpdateTestsBalance): void;

  getAcademyPlayerModel(params: IAcademyPlayerToAcademyParams): void;

  getAcademyBaseResults(params: IAcademyTestCollectionFilter): void;
  updateAcademyBaseResults(params: IAcademyTestUpdateParams): void;

  getAcademyCollection(filter?: IAcademyCollectionFilter): void;
  getAcademyCollectionForPlayer(filter?: IPlayerAcademyCollectionFilter): void;

  clearAcademyModel(): void;
  clearAcademyCollection(): void;
  clearAcademyCollectionForPlayer(): void;
  clearAcademyPlayerCollection(): void;
}

const AcademyModelAPIProviders = [
  new APIProvider(EActionsTypes.get, academyTransport.get),
  new APIProvider('getForPlayer', playerTransport.getAcademyModelForPlayer, {
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    }
  }),

  new APIProvider(EActionsTypes.update, academyTransport.update, {
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    },
    onSuccess: function*(response, props, branchState, fullState: any) {
      const { id } = response;
      const academyId = fullState.auth.academy.data.id;
      if (id === academyId) {
        yield updateAuthAcademy(response);
      }
      message.success(translateToasts(EAcademySuccessMessage.UpdateAcademy));
    }
  }),

  new APIProvider('updateBalance', academyTransport.updateAcademyTestsBalance, {
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    },
    onSuccess: function*() {
      yield refreshAuthAcademySilent();
    }
  }),

  new APIProvider(EActionsTypes.add, academyTransport.add, {
    onSuccess: function() {
      message.success(translateToasts(EAcademySuccessMessage.Add));
    }
  })
];

const AcademyCollectionAPIProviders = [
  new APIProvider(
    EActionsTypes.get,
    academyTransport.getCollection,
    {
      mapSuccess: buildCollectionResponseFormatter<any, any>(),
      preRequestDataMapper: buildCollectionPreRequestDataMapper<IAcademyCollection, IAcademyCollectionFilter>()
    },
    takeLatest
  )
];

const AcademyTestsCollectionAPIProviders = [
  new APIProvider(EActionsTypes.get, academyTransport.getAcademyBaseResults),
  new APIProvider(EActionsTypes.update, academyTransport.updateAcademyBaseResults, {
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    },
    onSuccess: function*(response, originalParams, branchState, fullState: any) {
      const { showTestConfig } = originalParams ?? {};
      const afterState = copyObject(fullState[namespace]?.model.data);
      afterState.showTestConfig = showTestConfig;
      yield put({
        type: getSuccessType(namespace, 'model', EActionsTypes.get),
        payload: afterState
      });
    }
  })
];

const AcademyPlayerModelAPIProviders = [
  new APIProvider(EActionsTypes.get, academyTransport.getAcademyPlayer, {
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    }
  })
];

const AcademyCollectionForPlayerAPIProviders = [
  new APIProvider(
    EActionsTypes.get,
    playerTransport.getAcademyCollectionForPlayer,
    {
      mapSuccess: buildCollectionResponseFormatter<any, any>(),
      preRequestDataMapper: buildCollectionPreRequestDataMapper<IAcademyCollection, IPlayerAcademyCollectionFilter>()
    },
    takeLatest
  )
];

const branches = [
  new Branch('model', AcademyModelAPIProviders),
  new Branch('baseResults', AcademyTestsCollectionAPIProviders),
  new Branch('collection', AcademyCollectionAPIProviders),
  new Branch('collectionForPlayer', AcademyCollectionForPlayerAPIProviders),
  new Branch(playerModel, AcademyPlayerModelAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export function* getForPlayerAcademyModel(payload: IPlayerAcademyModelParams) {
  yield put({ type: getStartType(namespace, 'model', 'getForPlayer'), payload });
}

export function* setAcademyModel(response: IAcademyModel) {
  yield put({ type: getSuccessType(namespace, 'model', EActionsTypes.get), payload: response });
}

export function* getSuccessTypeAcademyPlayerCollection(payload: IPlayerCollection) {
  yield put({ type: getSuccessType(namespace, 'playerCollection', EActionsTypes.get), payload });
}

export const communicationAcademy = buildCommunication<IAcademyConnectedProps>(strategy);
