import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  IUserCreateParams,
  IUserModel,
  IUserConfirmParams,
  IUserUpdatePasswordParams,
  IUserCollection,
  IUserCollectionFilter,
  IUserWithPlayerSignUpParams,
  IUserWithPlayerSignUpModel
} from 'entities/User/User.models';

const basePath = '/users';

class UserTransport extends BaseHttpTransport<
  AxiosStatic,
  IUserModel,
  IUserCollection,
  IUserCreateParams,
  void,
  IUserCollectionFilter
> {
  public updatePassword = (params: IUserUpdatePasswordParams): Promise<IUserModel> =>
    axios.put(`${basePath}/${params?.id}/password`, params);
  public confirm = (params: IUserConfirmParams): Promise<IUserModel> => axios.post(`${basePath}/join`, params);
  public checkToken = (token: string): Promise<void> => axios.post(`${basePath}/check-token`, { token });
  public checkTokenSignUpWithPlayer = (token: string): Promise<IUserWithPlayerSignUpModel> =>
    axios.post(`/token/invite-mentors/check`, { token });
  public signUpWithPlayer = ({ ac, ...params }: IUserWithPlayerSignUpParams): Promise<IUserModel> =>
    axios.post(`${basePath}/with-player${objectToQuery({ ac })}`, params);
}

export const userTransport = new UserTransport(basePath, axios);
