import React from 'react';
import Row from 'antd/es/row';
import { ScrollSyncPane } from 'react-scroll-sync';
import classNames from 'classnames';
import { PlayersCompareCardElement } from 'entities/Player/components/PlayersCompare/PlayersCompareCardElement';
import { IPlayerProfileModel } from 'entities/Player/Player.models';

interface IProps {
  profiles: IPlayerProfileModel[];
  isLoading: boolean;
}

const Component: React.FC<IProps> = props => {
  const { profiles, isLoading } = props;

  return (
    <ScrollSyncPane>
      <div className={classNames('players-compare__overflow players-compare__cards p-2', { sticky: !isLoading })}>
        <Row wrap={false} className="width-fit" justify="space-between">
          {profiles?.map(profile => (
            <PlayersCompareCardElement key={profile.playerId} profile={profile} />
          ))}
        </Row>
      </div>
    </ScrollSyncPane>
  );
};

export const PlayersCompareCards = Component;
