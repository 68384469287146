import { Typography } from 'antd';
import React, { ReactNode } from 'react';
import { Paper } from 'common/components/Paper/Paper';

interface IComponentProps {
  label?: string;
  action?: ReactNode;
}

type AllProps = IComponentProps;

export const TextBlock: React.FC<AllProps> = ({ label, children, action }) => (
  <Paper className="width-full">
    <div className="d-flex flex-justify-space-between">
      <Typography.Title level={4}>{label}</Typography.Title>
      {action}
    </div>
    <Typography.Paragraph>
      <span className="fs-xxs">{children}</span>
    </Typography.Paragraph>
  </Paper>
);
