import { IQueries } from 'common/models/routesModel';

export const getPageWithQuery = (path: string, queries: IQueries): string => {
  let url = `${path}?`;

  Object.keys(queries).forEach((key: string, index: number) => {
    url += `${index ? '&' : ''}${key}=${queries[key]}`;
  });

  return url;
};
