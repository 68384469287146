import React, { useMemo } from 'react';
import Modal from 'antd/es/modal';
import { StripePaymentModalBody } from 'entities/Subscription/components/StripePaymentModal/StripePaymentModalBody';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';

type AllProps = IUIConnectedProps;

const MAX_WIDTH = 764;
const MIN_WIDTH = 360;
const Z_INDEX = 9998;

const Component: React.FC<AllProps> = props => {
  const { UIStripePaymentModal, closeUIStripePaymentModal } = props;

  const isVisible = UIStripePaymentModal?.data?.isVisible;
  const withCoupon = UIStripePaymentModal?.data?.withCoupon;
  const withCardDetails = UIStripePaymentModal?.data?.withCardDetails;
  const title = UIStripePaymentModal?.data?.title;

  const modalWidth = useMemo(() => (withCoupon && withCardDetails ? MAX_WIDTH : MIN_WIDTH), [withCoupon, withCardDetails]);

  return (
    <Modal
      zIndex={Z_INDEX}
      title={title}
      width={modalWidth}
      footer={null}
      centered
      destroyOnClose
      visible={isVisible}
      onCancel={closeUIStripePaymentModal}
    >
      <StripePaymentModalBody />
    </Modal>
  );
};

export const StripePaymentModal = communicationUI.injector(Component);
