import React, { Suspense } from 'react';
import Spin from 'antd/es/spin';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';
import { ILineDataset } from 'common/models/lineChartModel';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import { IPlayerTestResultModel } from 'entities/PlayerTest/PlayerTest.models';
import { isAvailable } from 'entities/Auth/components/PrivateContent';
import { EUserRole } from 'entities/User/User.models';

const LineChart = React.lazy(() => import('common/components/LineChart/LineChart'));

interface IProps {
  playerId: string;
  testId: string;
}

interface IDatasets {
  [key: string]: ReadonlyArray<ILineDataset>;
}

type AllProps = IProps & IPlayerConnectedProps;

const PlayerTestResultsComponent: React.FC<AllProps> = props => {
  const { playerId, testId, getPlayerTestHistory, playerTestHistory } = props;
  const containerRef = React.useRef<ScrollContainer>(null);
  const { t } = useTranslation();
  const { data, loading } = playerTestHistory;

  React.useEffect(() => {
    getPlayerTestHistory({
      playerId,
      testId,
      onlyBestOfDay: true
    });
  }, [playerId, testId]);

  const datasets = React.useMemo(() => {
    if (!data?.data) {
      return {};
    }

    return data.data.reduce<IDatasets>((reducer: IDatasets, item: IPlayerTestResultModel) => {
      const { baseResult, createdAt, value, updatedAt } = item;
      const { tag } = baseResult;
      const tagValue = { date: createdAt, updateDate: updatedAt, value };

      if (reducer[tag]) {
        return {
          ...reducer,
          [tag]: [...reducer[tag], tagValue]
        };
      } else {
        return {
          ...reducer,
          [tag]: [tagValue]
        };
      }
    }, {});
  }, [data]);

  const refChartsCollection = React.useMemo(() => {
    return Object.keys(datasets)?.map(() => React.createRef<HTMLElement>());
  }, [datasets]);

  const onChartScroll = React.useCallback(
    index => (y: number) => {
      refChartsCollection.forEach((ref, refIndex) => {
        if (index !== refIndex && ref?.current?.scrollLeft !== undefined) {
          ref.current.scrollLeft = y;
        }
      });
    },
    [refChartsCollection]
  );

  if (loading) {
    return (
      <div className="player-test-results t-align-c">
        <Spin />
      </div>
    );
  }

  if (!loading && Object.keys(datasets).length === 0) {
    return (
      <Row className="width-full p-5" justify="center">
        {isAvailable(EUserRole.AcademyWorker) ? (
          <Typography.Title level={5} title={t('The player has no grades for this test')}>
            {t('The player has no grades for this test')}
          </Typography.Title>
        ) : (
          <Typography.Title level={5} title={t('Take tests to get grades')}>
            {t('Take tests to get grades')}
          </Typography.Title>
        )}
      </Row>
    );
  }

  return (
    <ScrollContainer ref={containerRef} className="player-test-results">
      <Suspense fallback={<Spin />}>
        {Object.keys(datasets).map((tag, index) => (
          <div key={tag} className="mb-3">
            <Typography.Title level={5}> {t(`${tag}`)} </Typography.Title>
            <LineChart
              scrollRef={refChartsCollection[index]}
              onScroll={onChartScroll(index)}
              oyTicks={null}
              oyMaxDomain="dataMax"
              id={`chart${tag}`}
              datasets={datasets[tag]}
              tag={tag}
              height={250}
            />
          </div>
        ))}
      </Suspense>
    </ScrollContainer>
  );
};

export const PlayerTestResults = communicationPlayer.injector(PlayerTestResultsComponent);
