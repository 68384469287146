import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  IFranchiseConfirmInviteNewParams,
  IFranchiseModel,
  IFranchiseUpdateParams,
  IFranchiseInviteCheckParams,
  IFranchiseConfirmInviteExistParams,
  IFranchiseRequestCreate,
  IFranchiseRequestCollectionFilter,
  IFranchiseRequestModelUpdateStatusParams
} from 'entities/Franchise/Franchise.models';

const basePath = '/franchises';

class FranchiseTransport extends BaseHttpTransport<AxiosStatic, IFranchiseModel, void, void, IFranchiseUpdateParams, void> {
  public confirmInvite = (params: IFranchiseConfirmInviteNewParams): Promise<void> =>
    axios.post(`${basePath}/confirm-academy-invite-new`, params);

  public confirmExist = (params: IFranchiseConfirmInviteExistParams): Promise<void> =>
    axios.post(`${basePath}/confirm-academy-invite-exists`, params);

  public checkInvite = (params: IFranchiseInviteCheckParams): Promise<void> =>
    axios.post(`${basePath}/check-academy-invite`, params);

  public getFranchiseRequest = (id: string): Promise<void> => axios.get(`franchise-requests/${id}`);

  public addFranchiseRequest = ({ id, ...rest }: IFranchiseRequestCreate): Promise<void> =>
    axios.put(`${basePath}/${id}/academies`, rest);

  public updateStatusFranchiseRequest = ({ id, ...rest }: IFranchiseRequestModelUpdateStatusParams): Promise<void> =>
    axios.put(`franchise-requests/${id}`, rest);

  public getFranchiseRequestCollection = (filter?: IFranchiseRequestCollectionFilter): Promise<void> =>
    axios.get(`franchise-requests/${objectToQuery(filter)}`);
}

export const franchiseTransport = new FranchiseTransport(basePath, axios);
