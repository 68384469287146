import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import DatePicker from 'antd/es/date-picker';
import moment, { Moment } from 'moment';
import { CenteredWithLogoLayout } from 'common/components/Layouts/CenteredWithLogoLayout';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { clientDateFormat } from 'common/models/dateModels';
import { getUTCEndOfDay, getUTCStartOfDay } from 'common/helpers/date.helper';
import { communicationInfluencer, IInfluencerConnectedProps } from 'entities/Influencer/Influencer.communication';
import { IInfluencerParams, influencerStatisticTableConfig } from 'entities/Influencer/Influencer.models';

interface IProps {}

type AllProps = IProps & IInfluencerConnectedProps;

const { RangePicker } = DatePicker;

interface IState extends Partial<IInfluencerParams> {}

const InfluencerStatisticsPageComponent: React.FC<AllProps> = props => {
  const history = useHistory();
  const { tag } = useParams();
  const { from, to } = queryToObject<IState>({ from: undefined, to: undefined }) as IState;
  const [state, setState] = useState<IState>({
    from: from
      ? moment(from).toISOString()
      : moment()
          .subtract(2, 'week')
          .toISOString(),
    to: moment(to).toISOString()
  });
  const { getInfluencerModel, clearInfluencerModel, influencerModel } = props;
  const { data, loading } = influencerModel;

  useEffect(() => {
    if (tag) {
      getInfluencerModel({
        id: tag,
        from: state.from,
        to: state.to
      });
    }

    return clearInfluencerModel;
  }, [tag, state]);

  const rangeValue = useMemo<[Moment, Moment]>(() => {
    const fromValue = state.from ? moment(state.from) : moment().subtract(2, 'week');
    const toValue = state.to ? moment(state.to).utc() : moment();

    return [fromValue, toValue];
  }, [state]);

  const onChangeRangePicker = (value: [Moment | null, Moment | null] | null) => {
    const fromRaw = value?.[0];
    const toRaw = value?.[1];
    const formState = {
      from: fromRaw ? getUTCStartOfDay(fromRaw) : undefined,
      to: toRaw ? getUTCEndOfDay(toRaw) : undefined
    };

    setState(formState);
    history.replace({ search: objectToQuery(formState) });
  };

  return (
    <CenteredWithLogoLayout>
      <Spin size="large" spinning={loading}>
        <Col className="width-full radius-default bg-component influencer-statistics-page mt-5 p-7">
          <Row>
            <RangePicker
              // @ts-ignore
              value={rangeValue}
              allowEmpty={[false, false]}
              clearIcon={false}
              showNow={false}
              showTime={false}
              format={clientDateFormat}
              onChange={onChangeRangePicker}
            />
          </Row>
          <Row className="mt-5">
            <Table columns={influencerStatisticTableConfig} dataSource={[data || {}]} pagination={false} />
          </Row>
        </Col>
      </Spin>
    </CenteredWithLogoLayout>
  );
};

export const InfluencerStatisticsPage = communicationInfluencer.injector(InfluencerStatisticsPageComponent);
