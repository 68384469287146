import React from 'react';
import Modal from 'antd/es/modal/Modal';
import { useTranslation } from 'react-i18next';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { EAcademyTrainerInviteModalType } from 'entities/UI/UI.models';
import { AcademyTrainerInviteCreateForm } from 'entities/Academy/components/View/Tabs/Trainers/AcademyTrainerInviteCreateForm';
import { AcademyTrainerInviteModalFinal } from 'entities/Academy/components/View/Tabs/Trainers/AcademyTrainerInviteModalFinal';
import { communicationTrainer, ITrainerConnectedProps } from 'entities/Trainer/Trainer.communication';

type AllProps = IUIConnectedProps & ITrainerConnectedProps;

const AcademyTrainerInviteModalComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { UIAcademyTrainerInviteModal, closeUIAcademyTrainerInviteModal, clearTrainerAcademyRequestModel } = props;
  const isVisible = UIAcademyTrainerInviteModal.data?.isVisible;
  const modalType = UIAcademyTrainerInviteModal.data?.modalType;
  const isAddModal = modalType === EAcademyTrainerInviteModalType.AddTrainer;
  const isAfterAddModal = modalType === EAcademyTrainerInviteModalType.AfterAddTrainer;

  const closeModal = () => {
    clearTrainerAcademyRequestModel();
    closeUIAcademyTrainerInviteModal();
  };

  return (
    <Modal
      forceRender
      bodyStyle={{ border: 'none' }}
      width={376}
      visible={isVisible}
      onCancel={closeModal}
      footer={false}
      title={t('Trainer invitation')}
    >
      {isAddModal && <AcademyTrainerInviteCreateForm onClose={closeModal} />}
      {isAfterAddModal && <AcademyTrainerInviteModalFinal onClose={closeModal} />}
    </Modal>
  );
};

export const AcademyTrainerInviteModal = communicationTrainer.injector(
  communicationUI.injector(AcademyTrainerInviteModalComponent)
);
