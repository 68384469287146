import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Spin from 'antd/es/spin';
import Input from 'antd/es/input';
import Typography from 'antd/es/typography';
import Form from 'antd/es/form';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormMapper } from '@axmit/antd4-helpers';
import { ERoutesCommon } from 'common/models/routesModel';
import { passwordRules, requiredField } from 'common/helpers/filed-rules';
import { CenteredWithLogoLayout } from 'common/components/Layouts/CenteredWithLogoLayout';
import { communicationFranchise, IFranchiseConnectedProps } from 'entities/Franchise/Franchise.communication';
import { IFranchiseConfirmInviteActions, IFranchiseInviteCheckParams } from 'entities/Franchise/Franchise.models';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';

interface IProps {
  isExist?: boolean;
}

type AllProps = IProps & IFranchiseConnectedProps & IAuthConnectedProps;

const FranchiseInviteComponent: React.FC<AllProps> = props => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    confirmNewFranchiseInvite,
    franchiseSecret,
    checkFranchiseSecret,
    isExist,
    confirmExistFranchiseInvite,
    franchiseInvite,
    authUser
  } = props;
  const { loading: secretCheckLoading, errors: secretCheckError, data: secretCheckData } = franchiseSecret;
  const { loading: franchiseLoading, errors: franchiseErrors, data: franchiseData, params: franchiseParams } = franchiseInvite;
  const isTokenExpired = !secretCheckLoading && secretCheckError;
  const query = queryString.parse(history.location.search);
  const secret = Array.isArray(query?.secret) ? query?.secret[0] : query?.secret;
  const token = Array.isArray(query?.token) ? query?.token[0] : query?.token;
  const franchiseName = secretCheckData?.franchise?.name;

  const isLoading = secretCheckLoading || franchiseLoading || (!!authUser?.params && authUser?.loading);

  const { fields } = useFormMapper(
    ['name', 'firstName', 'lastName', 'password'],
    franchiseData,
    franchiseParams,
    franchiseErrors
  );

  useEffect(() => {
    if (secret) {
      const body: IFranchiseInviteCheckParams = { secret };

      if (token) {
        body.userToken = token;
      }

      checkFranchiseSecret(body);
    }
  }, [checkFranchiseSecret, secret, token]);

  const createAcademy = (values: any) => {
    confirmNewFranchiseInvite({ ...values, token, secret });
  };

  const acceptInvitation = () => {
    if (secret && franchiseName) {
      confirmExistFranchiseInvite({ secret, action: IFranchiseConfirmInviteActions.Accept, franchiseName });
    }
  };

  if (isTokenExpired) {
    return (
      <CenteredWithLogoLayout>
        <Row justify="center">
          <Row justify="center" align="middle">
            <span className="t-align-c fs-sm width-full">{t('This link is expired')}</span>
            <span className="t-align-c fs-sm width-full">{t('expire-franchise-link-description')}</span>
            <Link to={ERoutesCommon.Root}>
              <Button type="primary" size="large" className="t-align-c mt-3">
                {t('Home')}
              </Button>
            </Link>
          </Row>
        </Row>
      </CenteredWithLogoLayout>
    );
  }

  return (
    <CenteredWithLogoLayout>
      <Spin size="large" spinning={isLoading}>
        <Col className="width-full franchise-invite-page mt-5">
          <Row className="franchise-invite-page__content p-7">
            <Row justify="start" className="mb-3">
              <Typography.Title level={3}>{t('Franchise invite')}</Typography.Title>
            </Row>
            <Row justify="start" className="mb-5">
              <Typography.Title level={5}>{t('invitation-franchise-description', { franchiseName })}</Typography.Title>
            </Row>
            {isExist ? (
              <Button
                type="primary"
                block
                size="large"
                onClick={acceptInvitation}
                disabled={secretCheckLoading}
                loading={secretCheckLoading}
                title={t('Accept')}
              >
                {t('Accept')}
              </Button>
            ) : (
              <Form onFinish={createAcademy} fields={fields} layout="vertical" className="width-full">
                <Form.Item rules={[requiredField]} name="name" label={t('Academy name')} className="mb-7">
                  <Input name="name" type="text" size="large" placeholder={t('Academy name')} />
                </Form.Item>
                <Typography.Title level={5} className="d-block mb-5">
                  {t('Contact person info')}
                </Typography.Title>
                <Form.Item rules={[requiredField]} name="firstName" label={t('First Name')} className="mb-5">
                  <Input name="firstName" type="text" size="large" />
                </Form.Item>
                <Form.Item rules={[requiredField]} name="lastName" label={t('Last Name')} className="mb-5">
                  <Input name="lastName" type="text" size="large" />
                </Form.Item>
                <Form.Item rules={[requiredField, passwordRules]} name="password" label={t('Password')} className="mb-5">
                  <Input.Password autoComplete="new-password" size="large" type="password" />
                </Form.Item>
                <Form.Item className="mb-0">
                  <Button
                    htmlType="submit"
                    type="primary"
                    block
                    size="large"
                    disabled={secretCheckLoading}
                    loading={secretCheckLoading}
                    title={t('Register')}
                  >
                    {t('Register')}
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Row>
        </Col>
      </Spin>
    </CenteredWithLogoLayout>
  );
};

export const FranchiseInvitePage = communicationAuth.injector(communicationFranchise.injector(FranchiseInviteComponent));
