import React, { ReactElement } from 'react';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EAcademyStatus } from 'entities/Academy/Academy.models';

interface IComponentProps {
  activeStatusRoutes: ReactElement;
  notActiveStatusRoutes: ReactElement;
}
type AllProps = IAuthConnectedProps & IComponentProps;

const AcademyStatusLayoutComponent: React.FC<AllProps> = props => {
  const { authAcademy, activeStatusRoutes, notActiveStatusRoutes } = props;
  const { data, loading } = authAcademy;
  const status = data?.status;
  const isAcademyActive = status === EAcademyStatus.Active;

  if (loading || !status) {
    return <LoadingSpin />;
  }

  return isAcademyActive ? activeStatusRoutes : notActiveStatusRoutes;
};

export const AcademyStatusLayout = communicationAuth.injector(AcademyStatusLayoutComponent);
