import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import defaultAntdLocale from 'antd/es/locale/en_US';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import { changeSystemLang, defaultLng } from 'common/helpers/i18n';
import { getLangFromSearchParams } from 'common/helpers/language.helper';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationLanguage, ILanguageConnectedProps } from 'entities/Language/Language.communication';

interface IProps {
  children: ReactElement;
}

type AllProps = IProps & IAuthConnectedProps & ILanguageConnectedProps;

const UserLanguageLayoutComponent: React.FC<AllProps> = props => {
  const { authUser, children, getLanguageCollection, languageCollection } = props;
  const { data } = languageCollection;
  const { i18n } = useTranslation();
  const supportedLanguages = useMemo(() => data?.data.map(lng => lng.locale) || [], [data?.data]);
  const userLang = authUser?.data?.lang;
  const [antdLocaleFile, setAntdLocaleFile] = useState(defaultAntdLocale);
  const [localized, setLocalized] = useState(false);

  useEffect(() => {
    const change = async () => {
      const queryLang = getLangFromSearchParams();
      const unAuthLangRaw = queryLang || i18n.language;
      const unAuthLang = supportedLanguages.includes(unAuthLangRaw) ? unAuthLangRaw : defaultLng;
      const lang = userLang || unAuthLang;
      await changeSystemLang(lang, setAntdLocaleFile);
      setLocalized(true);
    };
    if (supportedLanguages.length) {
      change();
    }
  }, [userLang, supportedLanguages]);

  useEffect(() => {
    getLanguageCollection();
  }, []);

  return localized ? <ConfigProvider locale={antdLocaleFile}>{children}</ConfigProvider> : null;
};

export const UserLanguageLayout = communicationLanguage.injector(communicationAuth.injector(UserLanguageLayoutComponent));
