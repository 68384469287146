import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { shareTransport } from 'entities/Share/Share.transport';
import { IShareModel } from 'entities/Share/Share.models';

const namespace = 'share';

export interface IShareConnectedProps {
  shareModel: StoreBranch<IShareModel, void>;

  getShareModel(id: string): void;

  clearShareModel(): void;
}

const sharesModelAPIProviders = [new APIProvider(EActionsTypes.get, shareTransport.get)];

const branches = [new Branch('model', sharesModelAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationShare = buildCommunication<IShareConnectedProps>(strategy);
