import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { ELocationType, ILocationModel } from 'common/helpers/location.helper';
import { LocationInput } from 'common/components/Input/LocationInput';
import { AgeGroupSelector } from 'common/components/Selector/AgeGroupSelector';
// import { TagsCheckbox } from 'common/components/TagsCheckbox/TagsCheckbox';
import { EPlayerAgeGroup, IPlayerAcademyCollectionFilter } from 'entities/Player/Player.models';

interface IComponentProps {
  onFilterChange: (filter: Partial<IPlayerAcademyCollectionFilter>) => void;
  filter?: Partial<IPlayerAcademyCollectionFilter>;
}

export const AcademyListFilterCard: React.FC<IComponentProps> = props => {
  const { t } = useTranslation();
  const { onFilterChange, filter } = props;
  const nameValue = filter?.name;
  const locationValue = filter?.location;
  const ageGroupValue = filter?.ageGroup;
  // const resultRanges = filter?.resultRangesValue;

  const autocompleteSearch = useDebouncedCallback((text: string) => {
    const name = text === '' ? undefined : text;
    onFilterChange({ name });
  }, 300);

  const locationSearch = (location?: ILocationModel) => {
    if (JSON.stringify(filter?.location) !== JSON.stringify(location)) {
      onFilterChange({ location });
    }
  };

  const onChangeAgeSelector = (ageGroup: EPlayerAgeGroup) => {
    onFilterChange({ ageGroup });
  };

  // const onChangeTags = (resultRangesValue?: { id: string }[]) => {
  //   onFilterChange({ resultRangesValue });
  // };

  return (
    <Card className="px-3 mt-9" bordered={false}>
      <Row gutter={[16, 16]}>
        {/* <Col span={24}>*/}
        {/*  <Row justify="center" align="middle" className="width-full">*/}
        {/*    <TagsCheckbox onChange={onChangeTags} value={resultRanges} />*/}
        {/*  </Row>*/}
        {/* </Col>*/}
        <Col xs={24} sm={16} md={10}>
          <Typography.Text>{t('Academy name')}</Typography.Text>
          <Input
            className="mt-3"
            size="large"
            name="academyName"
            type="text"
            placeholder={t('Academy name')}
            defaultValue={nameValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => autocompleteSearch.callback(e.target.value)}
          />
        </Col>

        <Col xs={24} sm={8} md={4}>
          <Typography.Text>{t('Age group')}</Typography.Text>
          <AgeGroupSelector
            allowClear
            value={ageGroupValue}
            className="width-full mt-3"
            size="large"
            onChange={onChangeAgeSelector}
          />
        </Col>
        <Col xs={24} md={10}>
          <Typography.Text>{t('City or region')}</Typography.Text>
          <LocationInput
            className="mt-3"
            placeholder={t('City or region')}
            onChange={locationSearch}
            value={locationValue}
            types={[ELocationType.Regions]}
          />
        </Col>
      </Row>
    </Card>
  );
};
