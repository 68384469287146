import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import { useTranslation } from 'react-i18next';
import { translateToasts } from 'common/helpers/translate.helper';
import AndroidPlayEn from 'app/assets/images/android-play-en.svg';
import QRIcon from 'app/assets/icons/QR.svg';
import { getCoachGoogleLink } from 'entities/ReferralCode/ReferralCode.helper';

export const CoachRestorePasswordSuccessPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="restore-password-page width-full p-7">
      <Row justify="center" align="middle">
        <span className="t-align-c fs-sm width-full mb-6">{translateToasts('Password has been successfully changed')}</span>
        <Col className="mb-6">
          <a href={getCoachGoogleLink()} target="_blank" rel="noopener noreferrer">
            <img width={146} height={44} src={AndroidPlayEn} alt="app-store" />
          </a>
        </Col>
        <Col>
          <img width={146} height={146} src={QRIcon} alt="app-store" />
        </Col>
        <span className="t-align-c fs-xxs width-full mt-6">{t('web-recover-password-page:Can enter to app')}</span>
      </Row>
    </div>
  );
};
