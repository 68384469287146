import React from 'react';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import { IPlayerTestCollection, IPlayerTestCollectionFilter, IPlayerTestModel } from 'entities/PlayerTest/PlayerTest.models';
import { communicationPlayerTest, IPlayerTestConnectedProps } from 'entities/PlayerTest/PlayerTest.communication';
import { PlayerTestListItem } from 'entities/PlayerTest/components/PlayerTestListItem';
import { ETestStatus, ITestResultsHistoryCollectionFilter } from 'entities/Test/Test.models';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

interface IComponentProps {
  playerId: string;
  onItemClick?: (itemModel: ITestResultsHistoryCollectionFilter) => void;
}

type AllProps = IComponentProps & IPlayerTestConnectedProps & IAuthConnectedProps;

class PlayerTestListComponent extends InfiniteList<
  IPlayerTestCollection,
  IPlayerTestModel,
  AllProps,
  IPlayerTestCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getPlayerTestCollection, playerId } = this.props;
    return getPlayerTestCollection({
      ...params,
      status: ETestStatus.Accept,
      player: playerId
    });
  };

  renderListItem = (item: IPlayerTestModel) => {
    const { onItemClick, authModel } = this.props;
    const isUserAuth = authModel?.data?.access;

    return <PlayerTestListItem item={item} onItemClick={onItemClick} isUserAuth={!!isUserAuth} />;
  };

  getCollection = () => {
    return this.props.playerTestCollection;
  };
  clearCollection = () => {
    return this.props.clearPlayerTestCollection();
  };
}

export const PlayerTestList = communicationAuth.injector(communicationPlayerTest.injector(PlayerTestListComponent));
