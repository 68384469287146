import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'antd/lib/card';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Divider from 'antd/es/divider';
import { useMediaPredicate } from 'react-media-hook';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { ReactComponent as AgilityIcon } from 'app/assets/icons/AgilityIcon.svg';
import { ReactComponent as PaceIcon } from 'app/assets/icons/PaceIcon.svg';
import { ReactComponent as DribblingIcon } from 'app/assets/icons/DribblingIcon.svg';
import { ReactComponent as PhysicalIcon } from 'app/assets/icons/PhysicalIcon.svg';
import { ReactComponent as ShootingIcon } from 'app/assets/icons/ShootingIcon.svg';
import { ITestCollectionFilter, ITestModel } from 'entities/Test/Test.models';
import { ESubtitleTypes, TestListItem } from 'entities/Test/components/List/TestListItem';
import { communicationTest, ITestConnectedProps } from 'entities/Test/Test.communication';

interface IComponentProps {
  playerId?: string;
  onItemClick?: (itemModel: ITestModel) => void;
  subtitle?: ESubtitleTypes;
  hideComplexity?: boolean;
  isRFS?: boolean;
}
interface IItem {
  [skill: string]: ITestModel[];
}
const cardConfigRFSDesktop = [
  [{ title: 'Physical training', icon: <PaceIcon /> }],
  [{ title: 'Technical training', icon: <AgilityIcon /> }]
];
const cardConfigRFSMob = [
  [
    { title: 'Physical training', icon: <PaceIcon /> },
    { title: 'Technical training', icon: <AgilityIcon /> }
  ]
];
const cardConfigDesktop = [
  [
    { title: 'agility', icon: <AgilityIcon /> },
    { title: 'physical', icon: <PhysicalIcon /> }
  ],

  [
    { title: 'pace', icon: <PaceIcon /> },
    { title: 'shooting', icon: <ShootingIcon /> }
  ],
  [{ title: 'dribbling', icon: <DribblingIcon /> }]
];
const cardConfigMob = [
  [
    { title: 'agility', icon: <AgilityIcon /> },
    { title: 'physical', icon: <PhysicalIcon /> },
    { title: 'pace', icon: <PaceIcon /> },
    { title: 'shooting', icon: <ShootingIcon /> },
    { title: 'dribbling', icon: <DribblingIcon /> }
  ]
];

type AllProps = ITestConnectedProps & IComponentProps;

const TestSkillCardListComponent: React.FC<AllProps> = props => {
  const { getTestCollection, playerId, testCollection, onItemClick, subtitle, hideComplexity, isRFS } = props;
  const { data: testCollectionData, loading } = testCollection;
  const { t } = useTranslation();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const configDefault = useMemo(() => (isDesktop ? cardConfigDesktop : cardConfigMob), [isDesktop]);
  const configRfs = useMemo(() => (isDesktop ? cardConfigRFSDesktop : cardConfigRFSMob), [isDesktop]);
  const config = isRFS ? configRfs : configDefault;

  const testsBySkill = useMemo(
    () =>
      testCollectionData?.data
        .sort((a, b) => a.displayPointCloud - b.displayPointCloud)
        .reduce((acc: IItem, el: ITestModel) => {
          const skillArray = isRFS ? [el.typeOfTraining] : el.skillsDependencies;
          if (!skillArray) {
            return acc;
          }
          skillArray?.forEach(item => {
            if (!acc[item]) {
              acc[item] = [el];
            } else {
              acc[item].push(el);
            }
          });
          return acc;
        }, {}),
    [testCollectionData]
  );

  useEffect(() => {
    const body: ITestCollectionFilter = {
      offset: 0,
      limit: 100
    };

    if (playerId) {
      body.player = playerId;
    }

    body.isVisibleForRfs = !!isRFS;
    body.isVisibleForTrainer = true;
    body.isVisibleForPlayer = false;

    getTestCollection(body);
  }, [getTestCollection]);

  if (loading) {
    return <LoadingSpin />;
  }

  return (
    <Col span={24}>
      <Row gutter={[12, 12]}>
        {config.map((cardItem, index) => (
          <Col key={index} span={isDesktop ? 8 : 24} className="height-fit">
            <Row gutter={[0, 12]}>
              {cardItem?.map(item => (
                <Col key={item.title} span={24} className="height-fit">
                  <Card className="border-none radius-default height-fit" bodyStyle={{ paddingBottom: 0, paddingTop: 8 }}>
                    <Row align="middle" className="p-2 pt-2">
                      {item.icon} <span className="pp-font-title-3 font-weight-5 ml-5 fs-md">{t(item.title)}</span>
                    </Row>
                    {testsBySkill && !!testsBySkill[item?.title]?.length
                      ? testsBySkill[item?.title]?.map(testItem =>
                          testItem && onItemClick ? (
                            <div key={testItem?.id}>
                              <Divider className="my-2" />
                              <TestListItem
                                item={testItem}
                                smallSize
                                onItemClick={onItemClick}
                                hideComplexity={hideComplexity}
                                subtitle={subtitle}
                              />
                            </div>
                          ) : null
                        )
                      : null}
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export const TestSkillCardList = communicationTest.injector(TestSkillCardListComponent);
