import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useFormMapper } from '@axmit/antd4-helpers';
import { nameBuilder } from 'common/helpers/name.helper';
import { CenteredWithLogoLayout } from 'common/components/Layouts/CenteredWithLogoLayout';
import { requiredField, phoneRule } from 'common/helpers/filed-rules';
import { LocationInput } from 'common/components/Input/LocationInput';
import { ELocationType, ILocationModel } from 'common/helpers/location.helper';
import { ERoutesAcademy } from 'common/models/routesModel';
import { Phone } from 'common/components/Phone/Phone';
import { AvatarEditor } from 'common/components/AvatarEditor/AvatarEditor';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationAcademy, IAcademyConnectedProps } from 'entities/Academy/Academy.communication';
import { IAcademyUpdateParams } from 'entities/Academy/Academy.models';
import { EPlayerAgeGroup } from 'entities/Player/Player.models';

interface IFormValues {
  address: ILocationModel;
  contactPhone: string;
  contactName: string;
  description: string;
  contactPosition?: string;
  site?: string;
  ageGroups: EPlayerAgeGroup[];
  image: string;
}

type AllProps = IAuthConnectedProps & IAcademyConnectedProps;

const InitAcademyInfoComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const [avatarId, setAvatarId] = useState<string | null>(null);
  const history = useHistory();
  const { deleteAuthModel, updateAcademyModel, authUser, academyModel } = props;
  const { data: userData } = authUser;
  const academyId = userData?.academyWorker?.academy.id;
  const { data: academyData, params, errors, loading } = academyModel;
  const formData = useMemo(() => {
    return { contactName: nameBuilder(userData?.firstName, userData?.lastName) };
  }, [userData]);

  const { fields } = useFormMapper(
    ['address', 'contactPhone', 'contactName', 'description', 'contactPosition', 'site', 'image'],
    formData,
    params,
    errors
  );

  useEffect(() => {
    if (academyData?.address) {
      history.push(ERoutesAcademy.AcademyEdit);
    }
  }, [academyData]);

  const onFinish = (values: IFormValues) => {
    if (academyId) {
      const { address, description, contactPhone, contactName, contactPosition, site, image } = values;
      const body: IAcademyUpdateParams = {
        address,
        id: academyId,
        contactName,
        contactPhone: contactPhone || null,
        description: description || null,
        image
      };

      if (contactPosition !== undefined) {
        body.contactPosition = contactPosition === '' ? null : contactPosition;
      }

      if (site !== undefined) {
        body.site = site === '' ? null : site;
      }

      updateAcademyModel(body);
    }
  };

  const onLogout = () => {
    deleteAuthModel();
  };

  return (
    <CenteredWithLogoLayout>
      <div className="init-academy-info-page width-full p-7">
        <Row justify="start" className="mb-5">
          <Typography.Title level={3} className="mb-0">
            {t('Academy registration')}
          </Typography.Title>
        </Row>
        <Form onFinish={onFinish} fields={fields} layout="vertical">
          <Form.Item name="image" label={`${t('Logo')} ${t('Optional')}`} className="mb-5">
            <AvatarEditor onChange={setAvatarId} avatarId={avatarId} />
          </Form.Item>
          <Form.Item rules={[requiredField]} name="address" label={t('City')} className="mb-5">
            <LocationInput types={[ELocationType.Cities]} disabled={loading} />
          </Form.Item>
          <Form.Item
            rules={[phoneRule]}
            validateTrigger="submit"
            name="contactPhone"
            label={`${t('Contact person phone')} ${t('Optional')}`}
            className="mb-5"
          >
            <Phone disabled={loading} />
          </Form.Item>
          <Form.Item rules={[requiredField]} name="contactName" label={t('Contact person')} className="mb-5">
            <Input size="large" />
          </Form.Item>
          <Form.Item name="contactPosition" label={t('Contact person position (optional)')} className="mb-5">
            <Input size="large" />
          </Form.Item>
          <Form.Item name="site" label={t('Academy site (optional)')} className="mb-5">
            <Input size="large" placeholder="https://www.academy-site.com" />
          </Form.Item>
          {/* <Row justify="start" className="mb-5">*/}
          {/*  <Typography.Text>{t('age-description')}</Typography.Text>*/}
          {/* </Row>*/}
          {/* <Form.Item rules={[requiredField]} name="ageGroups" className="mb-3">*/}
          {/*  <AcademyAgesSelect disabled={loading} />*/}
          {/* </Form.Item>*/}
          <Form.Item name="description" label={`${t('Description')} ${t('Optional')}`} className="mb-7">
            <Input.TextArea
              showCount
              maxLength={1024}
              disabled={loading}
              autoSize={{ minRows: 3 }}
              placeholder={t('Academy information')}
            />
          </Form.Item>
          <Form.Item className="mb-5">
            <Button htmlType="submit" block type="primary" size="large" disabled={loading}>
              {t('Complete registration')}
            </Button>
          </Form.Item>
        </Form>
        <Row justify="start">
          <Button block onClick={onLogout}>
            {t('Logout')}
          </Button>
        </Row>
      </div>
    </CenteredWithLogoLayout>
  );
};

export const InitAcademyInfoPage = communicationAcademy.injector(communicationAuth.injector(InitAcademyInfoComponent));
