import React from 'react';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { IAcademyCollection, IAcademyModel } from 'entities/Academy/Academy.models';
import { communicationAcademy, IAcademyConnectedProps } from 'entities/Academy/Academy.communication';
import { AcademyListItem } from 'entities/Academy/components/List/AcademyListItem';
import { PlayerAcademyTabListNoData } from 'entities/Player/components/Tabs/PlayerAcademyTabNoData';
import { EPlayerAcademyTabKeys, IPlayerAcademyCollectionFilter } from 'entities/Player/Player.models';

interface IComponentProps {
  clearFilter: (tab: EPlayerAcademyTabKeys) => void;
}
type AllProps = IAcademyConnectedProps & IComponentProps;

class AcademyListComponent extends InfiniteList<
  IAcademyCollection,
  IAcademyModel,
  AllProps,
  Partial<IPlayerAcademyCollectionFilter>
> {
  loadCollection = async (params: IBaseFilterModel) => {
    const { getAcademyCollectionForPlayer, filter } = this.props;
    const { location, resultRangesValue, ...clearedFilter } = filter || {};
    const resultRanges = resultRangesValue?.map(item => item.id);

    if (filter?.playerId) {
      const newFilter: IPlayerAcademyCollectionFilter = {
        ...clearedFilter,
        ...params,
        ...location,
        resultRanges,
        playerId: filter?.playerId
      };

      if (filter?.ageGroup) {
        newFilter.ageGroup = filter?.ageGroup;
      }

      return getAcademyCollectionForPlayer(newFilter);
    }
  };

  renderListItem = (item: IAcademyModel) => {
    return <AcademyListItem item={item} />;
  };

  getCollection = () => {
    return this.props.academyCollectionForPlayer;
  };
  clearCollection = () => {
    return this.props.clearAcademyCollectionForPlayer();
  };
  renderNoData = () => {
    const { filter, academyCollectionForPlayer, clearFilter } = this.props;
    const { loading, data } = academyCollectionForPlayer;
    const showClearFilterButton = !!(filter?.name || filter?.location || filter?.resultRangesValue || filter?.ageGroup);

    return !loading && data?.data.length === 0 ? (
      <PlayerAcademyTabListNoData showClearFilterButton={showClearFilterButton} clearFilter={clearFilter} />
    ) : (
      <LoadingSpin />
    );
  };
}

export const AcademyList = communicationAcademy.injector(AcademyListComponent);
