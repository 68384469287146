import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  buildCollectionResponseFormatter,
  buildCollectionPreRequestDataMapper,
  getStartType,
  getSuccessType
} from '@axmit/redux-communications';
import { put, select, takeLatest } from 'redux-saga/effects';
import { IError } from 'fe-error-helper';
import message from 'antd/es/message';
import { getErrorMessageByCode, translateToasts } from 'common/helpers/translate.helper';
import { history } from 'common/helpers/axios.helper';
import { ERoutesCommon } from 'common/models/routesModel';
import i18n from 'common/helpers/i18n';
import { EErrorStatus } from 'common/models/requestModels';
import { playerTransport } from 'entities/Player/Player.transport';
import {
  IPlayerCollection,
  IPlayerCollectionFilter,
  IPlayerModel,
  IPlayerParamsModel,
  IPlayerUpdateParams,
  IPlayerSkillCollection,
  IPlayerRatingCollection,
  IPlayerSkillCollectionFilter,
  IPlayerRatingCollectionFilter,
  IPlayerTestResultsParams,
  IPlayerTestResultCollection,
  IPlayerUpdateMentorRequestSettings,
  IPlayerVideoModel,
  IPlayerVideoCollection,
  IAddPlayerVideoParams,
  IUpdatePlayerVideoParams,
  IDeletePlayerVideoParams,
  IPlayerVideoCollectionFilter,
  IPlayerInviteParams,
  IPlayerUpdateByMentorParams,
  EPlayerSuccessMessage,
  IPlayerPublicProfileModel,
  IPlayerProfileModel,
  IPlayerPublicProfileTestResults,
  IPlayerPublicProfileTestResultsParams,
  IPlayerProfileTestResultsModel,
  IPlayerProfileTestResultsParams,
  PLAYER_ERROR_CODES,
  TPlayerCompareModel,
  IPlayerProfileCollection,
  IPlayerProfileCollectionParams,
  IPlayerProfileParams
} from 'entities/Player/Player.models';
import { updateMentorRequestModal } from 'entities/UI/UI.communication';
import { EMentorRequestModalType } from 'entities/UI/UI.models';

export const namespace = 'player';

function* updateProfile(id?: string) {
  if (id) {
    yield getPlayerProfileModel({ playerId: id });
  }
}

export interface IPlayerConnectedProps {
  playerModel: StoreBranch<IPlayerModel, IPlayerParamsModel, IError>;
  playerSetting: StoreBranch<IPlayerModel, IPlayerUpdateMentorRequestSettings>;
  playerCollection: StoreBranch<IPlayerCollection, IPlayerCollectionFilter>;
  playerRatingHistory: StoreBranch<IPlayerRatingCollection>;
  playerPhysicalHistory: StoreBranch<IPlayerSkillCollection>;
  playerTechnicalHistory: StoreBranch<IPlayerSkillCollection>;
  playerTestHistory: StoreBranch<IPlayerTestResultCollection>;
  playerVideosModel: StoreBranch<IPlayerVideoModel>;
  playerVideosCollection: StoreBranch<IPlayerVideoCollection>;
  playerInvite: StoreBranch<IPlayerModel>;
  playerPublicProfile: StoreBranch<IPlayerPublicProfileModel>;
  playerPublicProfileTestResult: StoreBranch<IPlayerPublicProfileTestResults, IPlayerPublicProfileTestResultsParams>;
  playerProfile: StoreBranch<IPlayerProfileModel, null, IError>;
  playerProfileTestResult: StoreBranch<IPlayerProfileTestResultsModel, IPlayerProfileTestResultsParams>;
  playerCompare: StoreBranch<TPlayerCompareModel>;
  playerProfileCollection: StoreBranch<IPlayerProfileCollection, IPlayerProfileCollectionParams>;

  getPlayerRatingHistory(params: IPlayerRatingCollectionFilter): void;
  getPlayerPhysicalHistory(params: IPlayerSkillCollectionFilter): void;
  getPlayerTechnicalHistory(params: IPlayerSkillCollectionFilter): void;
  getPlayerModel(id: string): void;
  updatePlayerModel(params: IPlayerUpdateParams): void;
  updateByMentorPlayerModel(params: IPlayerUpdateByMentorParams): void;
  deletePlayerModel(id: string): void;
  getPlayerCollection(filter?: IPlayerCollectionFilter): Promise<void>;
  getPlayerTestHistory(params: IPlayerTestResultsParams): void;
  getPlayerVideosCollection(params: IPlayerVideoCollectionFilter): Promise<void>;
  addPlayerVideosModel(params: IAddPlayerVideoParams): void;
  updatePlayerVideosModel(params: IUpdatePlayerVideoParams): void;
  deletePlayerVideosModel(params: IDeletePlayerVideoParams): void;
  sendPlayerInvite(params: IPlayerInviteParams): void;

  updateMentorRequestPlayerSetting(params: IPlayerUpdateMentorRequestSettings): void;

  getPlayerProfile(params: IPlayerProfileParams): void;
  getPlayerProfileTestResult(params: IPlayerProfileTestResultsParams): void;

  getPlayerPublicProfile(id: string): void;
  getPlayerPublicProfileTestResult(params: IPlayerPublicProfileTestResultsParams): void;

  getPlayerCompare(): void;
  addPlayerCompare(playerId: string): void;
  deletePlayerCompare(playerId: string): void;
  savePlayerCompare(playersIds: string[]): void;

  getPlayerProfileCollection(params: IPlayerProfileCollectionParams): void;

  clearPlayerModel(): void;
  clearPlayerVideosModel(): void;
  clearPlayerCollection(): void;
  clearPlayerVideosCollection(): void;

  clearPlayerProfile(): void;
  clearPlayerProfileTestResult(): void;

  clearPlayerPublicProfile(): void;
  clearPlayerPublicProfileTestResult(): void;

  clearPlayerCompare(): void;

  clearPlayerProfileCollection(): void;
}

const PlayerModelAPIProviders = [
  new APIProvider(EActionsTypes.get, playerTransport.getPlayerModel, {
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    }
  }),
  new APIProvider(EActionsTypes.update, playerTransport.update),
  new APIProvider('updateByMentor', playerTransport.updatePlayerByMentor, {
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    },
    onSuccess: function*(response, originalParams) {
      message.success(translateToasts(EPlayerSuccessMessage.UpdateSuccess));
      if (originalParams?.id) {
        yield getPlayerProfileModel({ playerId: originalParams.id });
      }
    },
    onFail: (response: IError) => {
      message.error(getErrorMessageByCode(response?.data?.code));
    }
  }),
  new APIProvider(EActionsTypes.delete, playerTransport.delete)
];
const PlayerSettingAPIProviders = [
  new APIProvider(
    'updateMentorRequest',
    playerTransport.updateMentorRequestPlayerSettings,
    {
      onStart: function*(originalParams, branchState, fullState: any) {
        const playerData = fullState[namespace]?.model?.data;

        if (originalParams && playerData) {
          yield getSuccessTypePlayerModel({ ...playerData, ...originalParams });
        }
      },
      onSuccess: function*(response: IPlayerModel) {
        const { id } = response;
        if (id) {
          yield getSuccessTypePlayerModel(response);
        }
      },
      onFail: function*(response: IError, originalParams) {
        const { data } = response;
        const { code } = data;

        message.error(getErrorMessageByCode(code));

        if (originalParams?.id) {
          yield getPlayerModel(originalParams.id);
        }
      }
    },
    takeLatest
  )
];

const PlayerCollectionAPIProviders = [
  new APIProvider(
    EActionsTypes.get,
    playerTransport.getCollection,
    {
      mapSuccess: buildCollectionResponseFormatter<any, any>(),
      preRequestDataMapper: buildCollectionPreRequestDataMapper<IPlayerCollection, IPlayerCollectionFilter>()
    },
    takeLatest
  )
];

const PlayerRatingHistoryProviders = [new APIProvider(EActionsTypes.get, playerTransport.getPlayerRatingHistory)];
const PlayerPhysicalSkillHistoryProviders = [
  new APIProvider(EActionsTypes.get, playerTransport.getPlayerPhysicalHistory, {
    mapSuccess: buildCollectionResponseFormatter<any, any>(),
    preRequestDataMapper: buildCollectionPreRequestDataMapper<IPlayerSkillCollection, IPlayerSkillCollectionFilter>()
  })
];
const PlayerTechnicalSkillHistoryProviders = [
  new APIProvider(EActionsTypes.get, playerTransport.getPlayerTechnicalHistory, {
    mapSuccess: buildCollectionResponseFormatter<any, any>(),
    preRequestDataMapper: buildCollectionPreRequestDataMapper<IPlayerSkillCollection, IPlayerSkillCollectionFilter>()
  })
];
const PlayerTestHistory = [new APIProvider(EActionsTypes.get, playerTransport.getPlayerTestHistory)];

const PlayerVideosModelAPIProviders = [
  new APIProvider(EActionsTypes.add, playerTransport.addPlayerVideo, {
    onSuccess: function*(response, originalParams) {
      yield updateProfile(originalParams?.playerId);
    }
  }),
  new APIProvider(EActionsTypes.update, playerTransport.updatePlayerVideosModel, {
    onSuccess: function*(response, originalParams) {
      yield updateProfile(originalParams?.playerId);
    }
  }),
  new APIProvider(EActionsTypes.delete, playerTransport.deletePlayerVideosModel, {
    onSuccess: function*(response, originalParams) {
      yield updateProfile(originalParams?.playerId);
    }
  })
];
const PlayerVideosCollectionAPIProviders = [new APIProvider(EActionsTypes.get, playerTransport.getPlayerVideosCollection)];

const PlayerInviteProviders = [
  new APIProvider('send', playerTransport.sendPlayerInvite, {
    onSuccess: function*() {
      yield updateMentorRequestModal({ modalType: EMentorRequestModalType.AfterAddPlayer, isVisible: true });
    },
    onFail: response => {
      const errors = response.data.errors ?? {};
      const playerErrors = errors['.phone'];

      if (playerErrors?.length && playerErrors[0]?.code === PLAYER_ERROR_CODES.IncorrectPhone) {
        message.error(getErrorMessageByCode(playerErrors[0]?.code));
      }
    }
  })
];
const PlayerProfileAPIProviders = [
  new APIProvider(EActionsTypes.get, playerTransport.getPlayerProfile, {
    preRequestDataMapper: (_response, _payload, branchState) => {
      return branchState.data;
    },
    onFail: response => {
      if (![EErrorStatus.NotFound, EErrorStatus.Forbidden].includes(response.status)) {
        message.error(i18n.t('Unexpected error'));
      }
      history.push(ERoutesCommon.Root);
    }
  })
];
const PlayerProfileTestResultAPIProviders = [
  new APIProvider(EActionsTypes.get, playerTransport.getPlayerProfileTestResult, {
    preRequestDataMapper: (_response, _payload, branchState) => {
      return branchState.data;
    }
  })
];

const PlayerPublicProfileAPIProviders = [new APIProvider(EActionsTypes.get, playerTransport.getPlayerPublicProfile)];
const PlayerPublicProfileTestResultAPIProviders = [
  new APIProvider(EActionsTypes.get, playerTransport.getPlayerPublicProfileTestResult)
];

const PlayerCompareAPIProviders = [
  new APIProvider(EActionsTypes.get, playerTransport.getPlayersCompare, {
    preRequestDataMapper: (_response, _payload, branchState) => {
      return branchState.data;
    }
  }),
  new APIProvider(EActionsTypes.add, playerTransport.addPlayerCompare, {
    preRequestDataMapper: (_response, _payload, branchState) => {
      return branchState.data;
    }
  }),
  new APIProvider(EActionsTypes.delete, playerTransport.deletePlayerCompare, {
    preRequestDataMapper: (_response, _payload, branchState) => {
      return branchState.data;
    }
  }),
  new APIProvider('save', playerTransport.savePlayerCompare, {
    preRequestDataMapper: (_response, _payload, branchState) => {
      return branchState.data;
    }
  })
];

const PlayerProfileCollectionAPIProviders = [
  new APIProvider(
    EActionsTypes.get,
    playerTransport.getPlayerProfileCollection,
    {
      mapParams: (originalParams, branchState) => {
        const requestedPlayers = branchState?.data?.data?.map(profile => profile.playerId) || [];

        return {
          ...originalParams,
          playerIds: originalParams?.players?.filter(({ id, keepCache }) => (keepCache ? !requestedPlayers.includes(id) : true))
        };
      },
      preRequestDataMapper: buildCollectionPreRequestDataMapper<IPlayerProfileCollection, IPlayerProfileCollectionParams>()
    },
    takeLatest
  )
];

const branches = [
  new Branch('model', PlayerModelAPIProviders),
  new Branch('collection', PlayerCollectionAPIProviders),
  new Branch('ratingHistory', PlayerRatingHistoryProviders),
  new Branch('testHistory', PlayerTestHistory),
  new Branch('physicalHistory', PlayerPhysicalSkillHistoryProviders),
  new Branch('technicalHistory', PlayerTechnicalSkillHistoryProviders),
  new Branch('setting', PlayerSettingAPIProviders),
  new Branch('videosModel', PlayerVideosModelAPIProviders),
  new Branch('invite', PlayerInviteProviders),
  new Branch('videosCollection', PlayerVideosCollectionAPIProviders),
  new Branch('profile', PlayerProfileAPIProviders),
  new Branch('profileTestResult', PlayerProfileTestResultAPIProviders),
  new Branch('publicProfile', PlayerPublicProfileAPIProviders),
  new Branch('publicProfileTestResult', PlayerPublicProfileTestResultAPIProviders),
  new Branch('compare', PlayerCompareAPIProviders),
  new Branch('profileCollection', PlayerProfileCollectionAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationPlayer = buildCommunication<IPlayerConnectedProps>(strategy);

export function* getPlayerModel(id: string) {
  yield put({ type: getStartType(namespace, 'model', EActionsTypes.get), payload: id });
}

export function* getPlayerProfileModel(payload: IPlayerProfileParams) {
  yield put({ type: getStartType(namespace, 'profile', EActionsTypes.get), payload });
}

export function* getProfileForProfilesCollection(playerId: string) {
  const state = yield select();
  const playerProfilesParamsPlayers = state[namespace].profileCollection.params.players;

  const payloadData = playerProfilesParamsPlayers?.map?.((player: { id: string }) => ({
    id: player.id,
    keepCache: player.id !== playerId
  }));

  yield put({
    type: getStartType(namespace, 'profileCollection', EActionsTypes.get),
    payload: {
      players: payloadData
    }
  });
}

export function* getSuccessTypePlayerModel(payload: IPlayerModel) {
  yield put({ type: getSuccessType(namespace, 'model', EActionsTypes.get), payload });
}

export function* getSuccessTypePartialPlayerProfileModel(payload: Partial<IPlayerProfileModel> = {}) {
  const state = yield select();
  const profileOld = state.player.profile.data || {};
  const profileNew = { ...profileOld, ...payload };

  yield put({ type: getSuccessType(namespace, 'profile', EActionsTypes.get), payload: profileNew });
}

export function* getSuccessTypePlayerProfileCollection(payload: IPlayerProfileCollection) {
  yield put({ type: getSuccessType(namespace, 'profileCollection', EActionsTypes.get), payload });
}
