import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { ITeamCollectionFilter } from 'entities/Team/Team.models';
import { AcademySelector } from 'entities/Academy/components/Selector/AcademySelector';
import { IAcademySearchModel } from 'entities/Academy/Academy.models';

interface IComponentProps {
  onFilterChange: (filter: Partial<ITeamCollectionFilter>) => void;
  filter?: Partial<ITeamCollectionFilter>;
  isFranshisor?: boolean;
}

export const TeamAcademyListFilterCard: React.FC<IComponentProps> = props => {
  const { t } = useTranslation();
  const { onFilterChange, filter, isFranshisor } = props;
  const searchValue = filter?.name;
  const academyFilterValue = filter?.academyFilter;

  const autocompleteSearch = useDebouncedCallback((text: string) => {
    const search = text === '' ? undefined : text;
    onFilterChange({ name: search });
  }, 300);

  const onChangeAcademySelector = (academyModel?: IAcademySearchModel) => {
    const academyFilter = academyModel
      ? {
          id: academyModel?.id,
          name: academyModel?.name
        }
      : undefined;

    onFilterChange({ academyFilter });
  };

  return (
    <Card className="px-3 width-full" bordered={false}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={isFranshisor ? 12 : 24}>
          <Typography.Text>{t('Title')}</Typography.Text>
          <Input
            allowClear
            className="mt-3"
            size="large"
            name="name"
            type="text"
            placeholder={t('Title')}
            defaultValue={searchValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => autocompleteSearch.callback(e.target.value)}
          />
        </Col>
        {isFranshisor && (
          <Col xs={24} sm={isFranshisor ? 12 : 24}>
            <Typography.Text>{t('Academy')}</Typography.Text>
            <AcademySelector
              className="mt-3"
              onChange={onChangeAcademySelector}
              value={academyFilterValue}
              size="large"
              placeholder={t('Academy')}
            />
          </Col>
        )}
      </Row>
    </Card>
  );
};
