import React, { useCallback } from 'react';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import { useTranslation } from 'react-i18next';
import { communicationTeam, ITeamConnectedProps } from 'entities/Team/Team.communication';

interface IProps {
  trainerId?: string;
  teamId?: string;
}

type AllProps = IProps & ITeamConnectedProps;

const UnlinkTrainerFromTeamComponent: React.FC<AllProps> = props => {
  const { trainerId, teamId, teamModel, removeTrainerFromTeamAction } = props;
  const { loading } = teamModel;
  const { t } = useTranslation();

  const stopPropagation = useCallback((e: any) => {
    e?.stopPropagation();
  }, []);

  const onUnlinkTrainer = (e: any) => {
    stopPropagation(e);
    if (teamId && trainerId) {
      removeTrainerFromTeamAction({ id: teamId, trainer: trainerId });
    }
  };

  return (
    <Popconfirm
      okButtonProps={{
        type: 'text',
        size: 'middle',
        danger: true
      }}
      cancelButtonProps={{
        size: 'middle'
      }}
      okText={t('Unlink')}
      cancelText={t('Cancel')}
      title={t('Unlink trainer from team?')}
      onConfirm={onUnlinkTrainer}
      onCancel={stopPropagation}
    >
      <Button type="primary" block danger disabled={loading} loading={loading}>
        {t('Unlink')}
      </Button>
    </Popconfirm>
  );
};

export const UnlinkTrainerFromTeam = communicationTeam.injector(UnlinkTrainerFromTeamComponent);
