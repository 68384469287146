import { options } from 'options';
import React, { Suspense } from 'react';
import 'jsreap';
import message from 'antd/es/message';
import { loadStripe } from '@stripe/stripe-js';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { I18nextProvider } from 'react-i18next';
import window from 'global/window';
import i18n from 'common/helpers/i18n';
import { CLIENT_VERSION, SENTRY_DSN, SENTRY_ENV } from 'common/const/config.const';
import { history, initAxios, store } from 'common/helpers/axios.helper';
import { UserLanguageLayout } from 'common/components/Layouts/UserLanguageLayout';
import { RootSuspenseFallback } from 'common/components/RootSuspenseFallback';
import { StripeProvider } from 'common/components/Stripe/StripeProvider';
import { MeasurementProvider } from 'common/components/Provider/MeasurementContext';
import { App } from 'app/App';
import 'antd/dist/antd.less';
import 'app/assets/sass/index.scss';
import 'video-react/dist/video-react.css';

// Sentry initialization
Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  release: CLIENT_VERSION
});
window.checkSentry = (text?: string) => {
  const msg = `Sentry successfully connected. Config: ${SENTRY_DSN} ${SENTRY_ENV} ${new Date()}. Test string: ${text}`;
  console.log('Go to slack channel and check an error with message: ');
  console.log(msg);
  Sentry.captureException(msg);
};
initAxios();
// Render Setup

// Antd initialization
message.config({ duration: 2 });

// Stripe initialization
const stripePromise = loadStripe(options.stripeToken);

const MOUNT_NODE = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-explicit-any

ReactDOM.render(
  <Suspense fallback={<RootSuspenseFallback />}>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ConnectedRouter history={history}>
          <Sentry.ErrorBoundary showDialog>
            <UserLanguageLayout>
              <MeasurementProvider>
                <StripeProvider promise={stripePromise}>
                  <App />
                </StripeProvider>
              </MeasurementProvider>
            </UserLanguageLayout>
          </Sentry.ErrorBoundary>
        </ConnectedRouter>
      </I18nextProvider>
    </Provider>
  </Suspense>,
  MOUNT_NODE
);
