import React, { useEffect, useMemo } from 'react';
import Spin from 'antd/es/spin';
import List from 'antd/es/list';
import {
  CANCEL_SUBSCRIPTION_TARIFF_ID,
  ETariffStatus,
  ITariffCollectionFilter,
  ITariffModel
} from 'entities/Tariff/Tariff.models';
import { communicationTariff, ITariffConnectedProps } from 'entities/Tariff/Tariff.communication';
import { TariffStripeComponentItem } from 'entities/Tariff/components/TariffsStripe/TariffsStripeComponentItem';

interface IProps {
  onSelectTariff(tariffId: string | undefined): void;
  mentorId?: string;
  playerId?: string;
  isTrialPhaseNow?: boolean;
  tariffEnd?: string;
  subscriptionId?: string;
  selectedTariffId?: string;
  disabled?: boolean;
  nextTariff?: string;
  nextTariffStart?: string;
  hidden?: boolean;
}

type AllProps = IProps & ITariffConnectedProps;

const LIST_GRID = {
  gutter: 16,
  column: 3,
  xs: 1,
  sm: 1,
  md: 2,
  lg: 2,
  xl: 3
};

const Component: React.FC<AllProps> = props => {
  const {
    tariffCollection,
    getTariffCollection,
    clearTariffCollection,
    mentorId,
    playerId,
    subscriptionId,
    selectedTariffId,
    onSelectTariff,
    disabled,
    tariffEnd,
    nextTariff,
    nextTariffStart,
    isTrialPhaseNow,
    hidden
  } = props;
  const { data, loading } = tariffCollection;
  const tariffs = data?.data;

  useEffect(() => {
    const filter: ITariffCollectionFilter = {
      limit: 100,
      orderField: 'status',
      orderDirection: 'ASC',
      statuses: [ETariffStatus.Active]
    };

    if (!mentorId && !playerId && !subscriptionId) {
      return;
    }

    if (mentorId) {
      filter.forMentor = mentorId;
    }

    if (playerId) {
      filter.forPlayer = playerId;
    }

    if (subscriptionId) {
      filter.forSubscription = subscriptionId;
    }

    getTariffCollection(filter);
  }, [mentorId, playerId, subscriptionId]);

  useEffect(() => {
    return () => clearTariffCollection();
  }, []);

  const dataSource: Partial<ITariffModel>[] = useMemo(
    () => [
      {
        id: CANCEL_SUBSCRIPTION_TARIFF_ID,
        localizationTag: isTrialPhaseNow ? 'Trial' : 'Initial',
        currentPrice: { amount: '0', currency: 'RUB', country: '' }
      },
      ...(tariffs || [])
    ],
    [tariffs, isTrialPhaseNow]
  );

  if (hidden) {
    return null;
  }

  return (
    <Spin spinning={loading} size="large" wrapperClassName="width-full">
      <List
        className="tariffs-stripe-component"
        grid={LIST_GRID}
        dataSource={dataSource}
        loading={loading}
        renderItem={item => (
          <List.Item className="height-full mb-0">
            <TariffStripeComponentItem
              nextTariff={nextTariff}
              nextTariffStart={nextTariffStart}
              tariffEnd={tariffEnd}
              disabled={disabled}
              onSelect={onSelectTariff}
              selectedTariffId={selectedTariffId}
              item={item}
            />
          </List.Item>
        )}
      />
    </Spin>
  );
};

export const TariffsStripeComponent = communicationTariff.injector(Component);
