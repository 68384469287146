import React, { useEffect, useState } from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { useDebouncedCallback } from 'use-debounce';
import { useHistory } from 'react-router';
import Card from 'antd/es/card';
import { queryToObject, objectToQuery } from 'common/helpers/filters.helper';
import { TestTeamHistoryList } from 'entities/Test/components/List/TestTeamHistory/TestTeamHistoryList';
import { TestResultModal } from 'entities/Academy/components/View/Modal/TestResultModal';
import { initTestResultFilter, ITestResultsHistoryCollectionFilter } from 'entities/Test/Test.models';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { TestTeamHistoryFilterCard } from 'entities/Test/components/List/TestTeamHistory/TestTeamHistoryFilterCard';

interface IProps {
  academyId?: string;
  teamId: string;
}

type AllProps = IProps & IUIConnectedProps;

const TeamAcademyPageTestResultComponent: React.FC<AllProps> = props => {
  const history = useHistory();
  const { teamId, openUITestResultModal } = props;
  const params = queryToObject<Partial<ITestResultsHistoryCollectionFilter>>(initTestResultFilter);
  const [state, setState] = useState<Partial<ITestResultsHistoryCollectionFilter> | undefined>({
    ...initTestResultFilter,
    ...params
  });

  const debPush = useDebouncedCallback((val: any) => {
    history.replace(val);
  }, 300);

  useEffect(() => {
    debPush.callback({ search: objectToQuery(state) });
  }, [state]);

  useEffect(() => {
    if (params) {
      openUITestResultModal({ params });
    }
  }, []);

  return (
    <Row className="width-full">
      <Col xs={24}>
        <Row className="width-full">
          <TestTeamHistoryFilterCard
            filter={state?.teamListFilter}
            onFilterChange={partialFilter => setState({ teamListFilter: { ...state?.teamListFilter, ...partialFilter } })}
          />
        </Row>
        <Col span={24}>
          <Card className="border-none width-full px-3 mt-5 card-small-py">
            <TestTeamHistoryList
              className="basic__list"
              elementId="basicLayout"
              filter={{ ...state?.teamListFilter, id: teamId }}
            />
          </Card>
        </Col>
        <TestResultModal disableFilters={true} />
      </Col>
    </Row>
  );
};

export const TeamAcademyPageTestResult = communicationUI.injector(TeamAcademyPageTestResultComponent);
