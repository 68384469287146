import i18n from 'i18next';
import { ITariffModel } from 'entities/Tariff/Tariff.models';

enum TranslationKeys {
  Title = 'Title',
  SaleDescription1 = 'Sale description 1 line',
  SaleDescription2 = 'Sale description 2 line'
}

export const translateTariffData = (
  item: Partial<ITariffModel> | null | undefined
): { title: string; saleDescription1: string; saleDescription2: string } => {
  const tag = item?.localizationTag;
  const unknownTitle = i18n.t('Unknown title');

  if (tag) {
    const title = i18n.exists(`${tag}.${TranslationKeys.Title}`) ? i18n.t(`${tag}.${TranslationKeys.Title}`) : tag;
    const saleDescription1 = i18n.exists(`${tag}.${TranslationKeys.SaleDescription1}`)
      ? i18n.t(`${tag}.${TranslationKeys.SaleDescription1}`)
      : '';
    const saleDescription2 = i18n.exists(`${tag}.${TranslationKeys.SaleDescription2}`)
      ? i18n.t(`${tag}.${TranslationKeys.SaleDescription2}`)
      : '';
    return { title, saleDescription1, saleDescription2 };
  }

  return { title: unknownTitle, saleDescription1: '', saleDescription2: '' };
};

export const getCurrencySign = (currency: string) => {
  if (currency) {
    return (0)
      .toLocaleString(undefined, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })
      .replace(/\d/g, '')
      .trim();
  }

  return '';
};

export const getTariffAmountAndCurrency = (item: Partial<ITariffModel> | null | undefined) => {
  const { currency, currentPrice, amount } = item || {};

  const tariffAmount = currentPrice?.amount || amount;
  const tariffCurrency = currentPrice?.currency || currency;

  return { tariffAmount, tariffCurrency };
};
