import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import Row from 'antd/es/row';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { PrivatePlayerViewTabs } from 'entities/PrivatePlayer/components/View/PrivatePlayerViewTabs';
import { PrivatePlayerProvider } from 'entities/PrivatePlayer/components/PrivatePlayerProvider';
import { PlayerProvider } from 'entities/Player/components/PlayerProvider';

const PrivatePlayerViewComponent: React.FC = () => {
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);

  return (
    <Row className="width-full">
      <PrivatePlayerProvider>
        <PlayerProvider>
          <PrivatePlayerViewTabs isDesktop={isDesktop} />
        </PlayerProvider>
      </PrivatePlayerProvider>
    </Row>
  );
};

export const PrivatePlayerView = PrivatePlayerViewComponent;
