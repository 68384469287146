import React, { useMemo } from 'react';
import Typography from 'antd/es/typography';
import Row from 'antd/es/row';
import Card from 'antd/es/card';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Form from 'antd/es/form';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import { useFormMapper } from '@axmit/antd4-helpers';
import { useMediaPredicate } from 'react-media-hook';
import Divider from 'antd/es/divider';
import { externalIdRules } from 'common/helpers/filed-rules';
import { SCREEN_MD } from 'common/const/config.const';
import { ETestBalanceRoles, TestsBalanceEditor } from 'common/components/TestsBalanceEditor';
import { isAcademyFranchisor } from 'common/helpers/franchise.helper';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationAcademy, IAcademyConnectedProps } from 'entities/Academy/Academy.communication';

interface IProps {}

type AllProps = IProps & IAcademyConnectedProps & IAuthConnectedProps;

const AcademyTestsTabComponent: React.FC<AllProps> = props => {
  const { academyId } = useParams();
  const { t } = useTranslation();
  const { authAcademy, academyModel, updateBalanceAcademyModel, authRefreshAcademy, updateAcademyModel } = props;
  const { data: academyData, params, errors } = academyModel;
  const data = useMemo(
    () => ({
      externalId: academyData?.externalId || undefined
    }),
    [academyData]
  );
  const { fields } = useFormMapper(['externalId'], data, params, errors);
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${SCREEN_MD})`);
  const academyBalance = academyData?.testBalance;
  const franchiseBalance = authAcademy?.data?.testBalance;
  const loading = academyModel?.loading || authRefreshAcademy?.loading;
  const isViewOnlyBalance = !isAcademyFranchisor(authAcademy?.data) || academyId === authAcademy?.data?.id;
  const isAuthAcademyFranchisor = isAcademyFranchisor(authAcademy?.data);
  const isAvailableRfsTests = authAcademy?.data?.isAvailableRfsTests;
  const webhookUrl = authAcademy?.data?.franchiseRequest?.franchise?.webhookUrl;
  const showExternalId = isAuthAcademyFranchisor && webhookUrl && isAvailableRfsTests;

  const onChangeBalance = (diff: number) => {
    if (academyId) {
      updateBalanceAcademyModel({ id: academyId, balanceDiff: diff });
    }
  };
  const onSubmit = (values: any) => {
    const { externalId } = values || {};
    if (academyId) {
      updateAcademyModel({ id: academyId, externalId });
    }
  };

  return (
    <>
      <Row className="pb-5 px-3" justify="space-between" align="middle">
        <Typography.Title className="mb-0" level={2}>
          {t('Tests')}
        </Typography.Title>
      </Row>
      <Card className="px-3 mt-5" bordered={false}>
        <Typography.Title className="mb-5" level={4}>
          {t('Academy balance')}
        </Typography.Title>
        <TestsBalanceEditor
          params={params}
          errors={errors}
          onChange={onChangeBalance}
          viewOnly={isViewOnlyBalance}
          toBalance={academyBalance}
          fromBalance={franchiseBalance}
          loading={loading}
          from={ETestBalanceRoles.Franchise}
          to={ETestBalanceRoles.Academy}
        />
        {showExternalId && (
          <>
            <Divider />
            <Form onFinish={onSubmit} fields={fields} layout="vertical" className="width-full">
              <Row gutter={[16, 16]} align="top" className="mb-3 width-full">
                <Col xs={24} md={7}>
                  <Form.Item
                    rules={[externalIdRules]}
                    name="externalId"
                    label={`${t('External Id')}${t('Optional')}`}
                    className="mb-0"
                  >
                    <Input disabled={loading} size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={7} className={isDesktop ? 'mt-8 pt-1' : ''}>
                  <Button htmlType="submit" block type="primary" size="large" disabled={loading} loading={loading}>
                    {t('Save')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Card>
    </>
  );
};

export const AcademyTestsTab = communicationAuth.injector(communicationAcademy.injector(AcademyTestsTabComponent));
