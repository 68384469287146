import React, { ReactNode } from 'react';
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import CameraOutlined from '@ant-design/icons/CameraOutlined';
import Col from 'antd/es/col';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { nameBuilder } from 'common/helpers/name.helper';
import { countryCodeToIso } from 'common/helpers/location.helper';
import { ECountryCode, Flag } from 'common/components/Flag/Flag';
import LeadingLegLeftIcon from 'app/assets/images/leading-leg-left.svg';
import LeadingLegRightIcon from 'app/assets/images/leading-leg-right.svg';
import AcademyJsAvatar from 'app/assets/images/academy-js.svg';
import { EPlayerAgeGroup, EPlayerLeadingLegs, IPlayerProfilePlayerModel } from 'entities/Player/Player.models';
import { PlayerAgeGroupSmall } from 'entities/Player/components/Card/PlayerAgeGroupSmall';
import { PlayerRatingSmall } from 'entities/Player/components/Card/PlayerRatingSmall';
import { getImageUrl } from 'entities/Image/Image.helper';
import { PlayerHeightBadge } from 'entities/Player/components/Card/PlayerHeightBadge';
import { PlayerWeightBadge } from 'entities/Player/components/Card/PlayerWeightBadge';

export enum EPlayerCardFullBadges {
  AgeGroup = 'ageGroup',
  Rating = 'rating',
  LeadingLeg = 'leadingLeg',
  AcademyAvatar = 'academyAvatar',
  CountryFlag = 'countryFlag',
  Height = 'height',
  Weight = 'weight'
}

interface IComponentProps {
  player: IPlayerProfilePlayerModel;
  previewAvatar?: boolean;
  onClick?(itemModel: IPlayerProfilePlayerModel): void;
  actionElements?: ReactNode;
  badges?: EPlayerCardFullBadges[];
}

const DEFAULT_BADGES = [
  EPlayerCardFullBadges.AgeGroup,
  EPlayerCardFullBadges.Rating,
  EPlayerCardFullBadges.LeadingLeg,
  EPlayerCardFullBadges.AcademyAvatar,
  EPlayerCardFullBadges.CountryFlag,
  EPlayerCardFullBadges.Height,
  EPlayerCardFullBadges.Weight
];

const AVATAR_SIZE = 48;
const ICON_SIZE = 24;
const DEFAULT_AVATAR_SIZE = { width: AVATAR_SIZE, height: AVATAR_SIZE, fontSize: AVATAR_SIZE / 2 };

const Component: React.FC<IComponentProps> = props => {
  const { player, onClick, previewAvatar, actionElements, badges = DEFAULT_BADGES } = props;
  const { firstName, lastName, image, ageGroup, rating, leadingLeg, academy, address, height, weight } = player || {};
  const { t } = useTranslation();
  const playerName = nameBuilder(firstName, lastName);

  return (
    <Card
      hoverable={!!onClick}
      onClick={() => {
        onClick && onClick(player);
      }}
      className="p-3 player-card-full bg-component border-none radius-default width-full"
    >
      <Row className="width-full mb-3" align="middle" wrap={false}>
        <Col className="mr-3">
          {image?.id ? (
            <ImagePrivate
              alt={t('Player avatar')}
              width={AVATAR_SIZE}
              height={AVATAR_SIZE}
              preview={previewAvatar}
              className="border-circle"
              imgId={image.id}
            />
          ) : (
            <Row justify="center" align="middle" className="bg-component-third radius-round" style={DEFAULT_AVATAR_SIZE}>
              <CameraOutlined />
            </Row>
          )}
        </Col>
        <Col flex={1} className="mr-3 player-card-full__name">
          <Typography.Title ellipsis={{ rows: 2 }} level={5} title={playerName} className="font-weight-5 mb-0">
            {playerName}
          </Typography.Title>
        </Col>
        {Boolean(actionElements) && actionElements}
      </Row>
      <Row gutter={[10, 8]}>
        {badges?.includes(EPlayerCardFullBadges.AgeGroup) && (
          <Col>
            <PlayerAgeGroupSmall ageGroup={ageGroup as EPlayerAgeGroup} />
          </Col>
        )}
        {badges?.includes(EPlayerCardFullBadges.Rating) && (
          <Col>
            <PlayerRatingSmall rating={rating} />
          </Col>
        )}
        {badges?.includes(EPlayerCardFullBadges.LeadingLeg) && (
          <Col>
            <img
              alt=""
              width={ICON_SIZE}
              height={ICON_SIZE}
              src={leadingLeg === EPlayerLeadingLegs.Left ? LeadingLegLeftIcon : LeadingLegRightIcon}
            />
          </Col>
        )}
        {badges?.includes(EPlayerCardFullBadges.AcademyAvatar) && (
          <Col>
            <img
              className="border-circle"
              src={!academy?.imageId ? AcademyJsAvatar : getImageUrl(academy?.imageId)}
              width={ICON_SIZE}
              height={ICON_SIZE}
              alt=""
            />
          </Col>
        )}
        {badges?.includes(EPlayerCardFullBadges.CountryFlag) && (
          <Col>
            <Flag code={countryCodeToIso(address?.countryCode as ECountryCode)} height={ICON_SIZE} />
          </Col>
        )}
        {badges?.includes(EPlayerCardFullBadges.Height) && (
          <Col>
            <PlayerHeightBadge height={height} />
          </Col>
        )}
        {badges?.includes(EPlayerCardFullBadges.Weight) && (
          <Col>
            <PlayerWeightBadge weight={weight} />
          </Col>
        )}
      </Row>
    </Card>
  );
};

export const PlayerCardFull = Component;
