import { useFormMapper } from '@axmit/antd4-helpers';
import React from 'react';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { requiredField } from 'common/helpers/filed-rules';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';
import { IMentorRequestCreateParams } from 'entities/MentorRequest/MentorRequest.models';

interface IComponentProps {
  onClose: () => void;
}
type AllProps = IMentorRequestConnectedProps & IComponentProps;

const MentorRequestCreateFormComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { mentorRequestModel, addMentorRequestModel } = props;
  const { params, errors, loading } = mentorRequestModel;
  const { fields } = useFormMapper(['email'], null, params, errors);

  const addMentorRequest = (values: any) => {
    const { email } = values;
    const body: IMentorRequestCreateParams = {
      email
    };

    addMentorRequestModel(body);
  };

  return (
    <Form layout="vertical" onFinish={addMentorRequest} fields={fields}>
      <Form.Item className="mb-5" rules={[requiredField]} name="email" label={t('Email')}>
        <Input size="large" />
      </Form.Item>
      <Typography.Text className="d-block mb-5">
        {t('Player must confirm the change of representative in his application')}
      </Typography.Text>
      <Form.Item className="mb-0">
        <Button block size="large" htmlType="submit" type="primary" disabled={loading} loading={loading}>
          {t('Send request')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export const MentorRequestCreateForm = communicationMentorRequest.injector(MentorRequestCreateFormComponent);
