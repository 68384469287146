import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  IMentorRequestCollection,
  IMentorRequestCreateParams,
  IMentorRequestModel,
  IMentorRequestUpdateParams,
  IMentorRequestCollectionFilter,
  IInviteMentorParams
} from 'entities/MentorRequest/MentorRequest.models';

const basePath = '/mentor-requests';

class MentorRequestTransport extends BaseHttpTransport<
  AxiosStatic,
  IMentorRequestModel,
  IMentorRequestCollection,
  IMentorRequestCreateParams,
  IMentorRequestUpdateParams,
  IMentorRequestCollectionFilter
> {
  public inviteMentor = (params: IInviteMentorParams): Promise<void> => axios.post('/trainers/invite-mentor', params);
  public acceptMentorRequest = (token: string): Promise<IMentorRequestModel> => axios.post(`${basePath}/magic-token`, { token });
}

export const mentorRequestTransport = new MentorRequestTransport(basePath, axios);
