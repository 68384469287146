import { ILogger, ILoggerConfig, LogWriter } from '@axmit/class-logger';
import * as Sentry from '@sentry/react';
import { EErrorStatus } from 'common/models/requestModels';

const config: ILoggerConfig = { pid: 0 };

let logger: ILogger = console;

if (process.env.NODE_ENV !== 'development') {
  logger = {
    log: (msg?: string) => {
      if (msg && msg.match('"ERR"')) {
        console.error(msg);
        Sentry.captureException(msg);
      } else {
        console.error(msg || 'No message');
      }
      console.log(msg);
    }
  };
}

class LoggerClass extends LogWriter {
  err(labels: string[], data: any, err: any) {
    const status = err?.data?.statusCode;
    switch (status) {
      case EErrorStatus.Unauthorized:
      case EErrorStatus.Validation:
        break;
      default:
        super.err(labels, data, err);
        break;
    }
  }
}

const Logger = new LoggerClass(logger, config);

export default Logger;
