import { IPlayerModel } from 'entities/Player/Player.models';
import { ESubscriptionStatus } from 'entities/Subscription/Subscription.models';
import { IAcademyModel } from 'entities/Academy/Academy.models';
import { EUserRole, IUserModel } from 'entities/User/User.models';

export enum EPermissionType {
  ShowPlayerDataForAcademy = 'showPlayerDataForAcademy',
  ShowDataForMentor = 'showDataForMentor',
  ShowDataForAcademy = 'showDataForAcademy',
  ShowDataForPlayerAcademy = 'showDataForPlayerAcademy',
  ShowDataForAllMentors = 'showDataForAllMentors',
  ShowDataForMentorAcademyTestConfig = 'showDataForMentorAcademyTestConfig',
  ShowDataForMentorSubActive = 'showDataForMentorSubActive'
}

export const permissionHelper = (
  type: EPermissionType,
  authUserModel?: IUserModel | null,
  playerModel?: IPlayerModel | null,
  academyModel?: IAcademyModel | null
): boolean => {
  const isMentorSubActive = playerModel?.mentorRequest?.subscription?.status === ESubscriptionStatus.Active;

  // const visibilityForAll = playerModel?.mentorRequest?.playerVisibility === EMentorRequestPlayerVisibility.All;
  // const visibilityForSubAcademy = playerModel?.mentorRequest?.playerVisibility === EMentorRequestPlayerVisibility.Subscribed;
  // const academyRequest = playerModel?.academyRequests;
  // const academyRequestAccepted = academyRequest ? academyRequest[0]?.status === EAcademyRequestStatus.Accepted : false;

  const showPlayerData = !!(playerModel?.firstName || playerModel?.lastName);
  const isAuthUserMentorPlayer = playerModel?.mentorRequest?.mentor.id === authUserModel?.id;

  const showTestConfig = academyModel?.showTestConfig;
  const isMentor = authUserModel?.role === EUserRole.Mentor;
  const isAcademyWorker = authUserModel?.role === EUserRole.AcademyWorker;

  switch (type) {
    case EPermissionType.ShowDataForMentor: {
      return isMentor ? isAuthUserMentorPlayer : false;
    }
    case EPermissionType.ShowDataForMentorAcademyTestConfig: {
      return isMentor ? isMentorSubActive && !!showTestConfig : false;
    }
    case EPermissionType.ShowDataForMentorSubActive: {
      return isMentor ? isMentorSubActive : false;
    }
    case EPermissionType.ShowDataForAllMentors: {
      return isMentor;
    }
    case EPermissionType.ShowDataForAcademy: {
      return isAcademyWorker;
    }
    case EPermissionType.ShowPlayerDataForAcademy: {
      // return isMentorSubActive && isAcademyWorker
      //   ? visibilityForAll || (visibilityForSubAcademy && academyRequestAccepted)
      //   : false;
      return isAcademyWorker && showPlayerData;
    }
    case EPermissionType.ShowDataForPlayerAcademy: {
      return isAcademyWorker && authUserModel?.academyWorker?.academy?.id === playerModel?.academyLink?.academy?.id;
    }

    default: {
      return false;
    }
  }
};

export const isAcademyWorkerHelper = (authUserModel?: IUserModel | null) => {
  return authUserModel?.role === EUserRole.AcademyWorker;
};
