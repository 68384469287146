import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Row, Col } from 'antd';
import { getResultBarColor } from 'common/helpers/result-bar.helper';

interface IComponentProps {
  value: number | null;
  height?: number;
  color?: string;
  bgColor?: string;
  className?: string;
}

type AllProps = IComponentProps;

export const Bar = ({ value: rawValue, height = 4, bgColor, color, className }: AllProps) => {
  const value = rawValue !== null ? useMemo(() => rawValue, [rawValue]) : undefined;
  const bgColorClassName = useMemo(() => (value ? `bg-${getResultBarColor(value)}` : ''), [rawValue]);
  const flexValue = value === undefined || 100 - value === 0 ? 1 : 100 - value;

  return (
    <Row className={classNames('rounded overflow-hidden', { [className as string]: Boolean(className) })}>
      <Col flex={value} className={classNames(bgColorClassName)} style={{ height, backgroundColor: color }} />
      {value && value <= 100 ? (
        <Col className="bg-component-third" flex={flexValue} style={{ height, backgroundColor: bgColor }} />
      ) : (
        <Col className="bg-component-third" flex={100} style={{ height, backgroundColor: bgColor }} />
      )}
    </Row>
  );
};
