import React, { useCallback, useMemo } from 'react';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Radio from 'antd/es/radio';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import Space from 'antd/es/space';
import { useTranslation } from 'react-i18next';
import { RadioChangeEvent } from 'antd/es/radio/interface';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import {
  EPlayersVisibilityModeFromSettings,
  EPlayerVisibilityMode,
  EPlayerVisibilityModeText,
  EPlayerVisibilitySettingsValues
} from 'entities/Player/Player.models';

interface IComponentProps {
  isDesktop: boolean;
}

type AllProps = IComponentProps & IPlayerConnectedProps;

export const PlayerVisibilityComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { playerModel, updateMentorRequestPlayerSetting } = props;
  const { data: playerData } = playerModel;
  const isPlayerHideFromJuniCoach = playerData?.juniCoachModule ? !playerData?.juniCoachPublicity : false;

  const playerVisibility = useMemo(() => {
    switch (true) {
      case playerData?.juniStatVisibility === EPlayerVisibilityMode.Public:
        return EPlayerVisibilitySettingsValues.Public;
      case playerData?.juniStatVisibility === EPlayerVisibilityMode.Protected:
        return EPlayerVisibilitySettingsValues.Protected;
      case playerData?.juniStatPublicity:
        return EPlayerVisibilitySettingsValues.Private;
      default:
        return EPlayerVisibilitySettingsValues.Hidden;
    }
  }, [playerData]);

  const viewSettings = React.useMemo(() => {
    return [
      EPlayerVisibilitySettingsValues.Public,
      EPlayerVisibilitySettingsValues.Protected,
      EPlayerVisibilitySettingsValues.Private,
      EPlayerVisibilitySettingsValues.Hidden
    ];
  }, []);

  const onChangeVisibility = useCallback(
    (e: RadioChangeEvent) => {
      if (playerData?.id) {
        const radioValue = e.target.value as EPlayerVisibilitySettingsValues;
        updateMentorRequestPlayerSetting({
          id: playerData.id,
          juniStatVisibility: EPlayersVisibilityModeFromSettings[radioValue] as EPlayerVisibilityMode,
          juniStatPublicity: radioValue !== EPlayerVisibilitySettingsValues.Hidden
        });
      }
    },
    [playerData]
  );

  return (
    <Col xs={24} className="player-visibility-settings">
      <Card className="border-none px-3">
        <Typography.Title level={4}>{t('Who sees the player profile')}</Typography.Title>
        <Radio.Group
          onChange={onChangeVisibility}
          value={playerVisibility}
          defaultValue={EPlayerVisibilitySettingsValues.Private}
        >
          <Space direction="vertical">
            {viewSettings.map(item => (
              <Radio className="d-flex player-visibility-settings__radio" key={item} value={item}>
                <div className="player-visibility-settings__radio__label">
                  <Typography.Text>{t(`view-settings-${EPlayerVisibilityModeText[item]}-title`)}</Typography.Text>
                  <Typography.Paragraph className="color-gray mb-0" type="secondary">
                    {t(`view-settings-${EPlayerVisibilityModeText[item]}-label`)}
                  </Typography.Paragraph>
                </div>
              </Radio>
            ))}
          </Space>
        </Radio.Group>
        {isPlayerHideFromJuniCoach && (
          <Row className="mt-5">
            <Typography.Text type="danger" className="mr-2">
              {t('Attention')}.
            </Typography.Text>
            <Typography.Text>{t('The academy hid the player with its privacy settings')}</Typography.Text>
          </Row>
        )}
      </Card>
    </Col>
  );
};

export const PlayerVisibility = communicationPlayer.injector(PlayerVisibilityComponent);
