import React from 'react';
import { Redirect } from 'react-router';
import { ERoutesCommon } from 'common/models/routesModel';
import { isAcademyFranchisor } from 'common/helpers/franchise.helper';
import { LayoutContent } from 'common/components/Layouts/ContentLayout';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { StatisticTablePage } from 'entities/Statistic/component/StatisticTablePage';

type AllProps = IAuthConnectedProps;

const AcademyStatisticPageComponent: React.FC<AllProps> = props => {
  const { authAcademy } = props;
  const isFranchisor = isAcademyFranchisor(authAcademy?.data);

  return isFranchisor ? (
    <LayoutContent>
      <StatisticTablePage />
    </LayoutContent>
  ) : (
    <Redirect to={ERoutesCommon.Root} />
  );
};

export const AcademyStatisticPage = communicationAuth.injector(AcademyStatisticPageComponent);
