import * as React from 'react';
import Select, { SelectProps } from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import { ELeadingLeg } from 'entities/PrivatePlayer/PrivatePlayer.models';

export const LeadingLegSelect: React.FC<SelectProps<any>> = props => {
  const { t } = useTranslation();

  return (
    <Select {...props}>
      <Select.Option key={ELeadingLeg.Right} value={ELeadingLeg.Right}>
        {t(ELeadingLeg.Right)}
      </Select.Option>
      <Select.Option key={ELeadingLeg.Left} value={ELeadingLeg.Left}>
        {t(ELeadingLeg.Left)}
      </Select.Option>
    </Select>
  );
};
