import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  IAuthModel,
  IPasswordRestoreParams,
  IPasswordForgotParams,
  IPasswordRestoreTokenCheckParams,
  IAuthDeleteParams,
  ISocialAuthParams,
  ISocialUnlinkParams,
  ITokenLoginAuthParams
} from 'entities/Auth/Auth.models';

const basePath = '/auth';
const passwordRestore = '/restore-password';
const socialPath = '/social-accounts';
const tokenLoginPath = '/login-by-token';

class AuthTransport extends BaseHttpTransport<AxiosStatic, IAuthModel, void, void, void, void> {
  public logout = (params: IAuthDeleteParams): Promise<void> =>
    axios.post(`${basePath}/logout`, !params?.logoutAll ? { logoutAll: false } : null);
  public tokenLoginAuth = async (params: ITokenLoginAuthParams): Promise<IAuthModel> =>
    axios.post(`${basePath}${tokenLoginPath}`, params);
  public socialAuth = async (params: ISocialAuthParams): Promise<IAuthModel> => axios.post(`${socialPath}/user`, params);
  public socialLink = async (params: ISocialAuthParams): Promise<void> => axios.post(`${socialPath}/link`, params);
  public socialUnlink = async (params: ISocialUnlinkParams): Promise<void> =>
    axios.delete(`${socialPath}${objectToQuery(params)}`);
}

export const authTransport = new AuthTransport(basePath, axios);

class PasswordRestoreTransport extends BaseHttpTransport<
  AxiosStatic,
  void,
  IPasswordForgotParams,
  IPasswordRestoreParams,
  void,
  void
> {
  public passwordRestore = (params: IPasswordRestoreParams): Promise<void> => axios.put(passwordRestore, params);
  public checkRestoreToken = (params: IPasswordRestoreTokenCheckParams): Promise<void> =>
    axios.post(`${passwordRestore}/check-token`, params);
}

export const passwordRestoreTransport = new PasswordRestoreTransport(passwordRestore, axios);
