import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Row, Button, Typography } from 'antd';
import Card from 'antd/es/card';
import { useMediaPredicate } from 'react-media-hook';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { PlayerVideosModal } from 'entities/Player/components/PlayerVideos/PlayerVideosModal';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import { PlayerVideosList } from 'entities/Player/components/PlayerVideos/PlayerVideosList';

interface IParams {
  id: string;
}

type AllProps = IPlayerConnectedProps;

const PlayerVideoUploadCardComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const { id: playerId } = useParams<IParams>();
  const { playerVideosCollection } = props;
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>('');
  const { data, loading } = playerVideosCollection;
  const videos = data?.data || [];

  const isUploadDisabled = useMemo(() => Object.keys(videos).length >= 3, [videos]) || loading;

  const openUploadModal = useCallback(() => {
    setShowUploadModal(true);
  }, []);

  const closeUploadModal = useCallback(() => {
    setShowUploadModal(false);
    setSelectedItem(undefined);
  }, []);

  const onOpenElement = useCallback(id => {
    setSelectedItem(id);
    openUploadModal();
  }, []);

  return (
    <Card bordered={false} className="radius-default mb-7 px-5 pb-2">
      {isDesktop ? (
        <Row justify="space-between" align="middle" className="mb-3">
          <Typography.Title level={4} className="mb-0">
            {t('Player videos')}
          </Typography.Title>
          <Button size="large" onClick={openUploadModal} loading={loading} disabled={isUploadDisabled}>
            {t('Upload video')}
          </Button>
        </Row>
      ) : (
        <Row className="mb-5" align="middle" justify="center">
          <Typography.Title level={4} className="mb-5 t-align-c">
            {t('Player videos')}
          </Typography.Title>
          <Button block onClick={openUploadModal} loading={loading} disabled={isUploadDisabled}>
            {t('Upload video')}
          </Button>
        </Row>
      )}
      <div className="radius-default">
        {playerId ? (
          <PlayerVideosList elementId="basicLayout" onOpenElement={onOpenElement} isDesktop={isDesktop} playerId={playerId} />
        ) : (
          <Row className="width-full" justify="center" align="middle">
            <Typography.Text>{t('Videos are hidden by privacy settings')}</Typography.Text>
          </Row>
        )}
      </div>
      <PlayerVideosModal visible={showUploadModal} onCancel={closeUploadModal} id={selectedItem} isDesktop={isDesktop} />
    </Card>
  );
};

export const PlayerVideoUploadCard = communicationPlayer.injector(PlayerVideoUploadCardComponent);
