import React, { useCallback, useState } from 'react';
import Modal from 'antd/es/modal/Modal';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import Col from 'antd/es/col';
import message from 'antd/es/message';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import { useFormMapper } from '@axmit/antd4-helpers';
import { translateErrors } from 'common/helpers/translate.helper';
import { requiredField } from 'common/helpers/filed-rules';
import { playerTransport } from 'entities/Player/Player.transport';
import { EMentorRequestStatus } from 'entities/MentorRequest/MentorRequest.models';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';

type AllProps = IMentorRequestConnectedProps & IUIConnectedProps;

const PlayerEmailEditModalComponent: React.FC<AllProps> = props => {
  const {
    UIPlayerEmailEditModal,
    closeUIPlayerEmailEditModal,
    updateMentorRequestModel,
    mentorRequestModel,
    openUIPlayerMergeModal,
    mentorRequestCollection,
    getMentorRequestCollection
  } = props;
  const { isVisible, mentorRequestId: id, playerId, needAcceptRequest } = UIPlayerEmailEditModal?.data || {};
  const [isLoading, setLoading] = useState(false);
  const loading = mentorRequestModel.loading || isLoading;
  const { params } = mentorRequestCollection;
  const { t } = useTranslation();
  const { fields } = useFormMapper(['email'], null);

  const onAcceptRequest = useCallback(async () => {
    if (id && needAcceptRequest) {
      setLoading(true);
      await updateMentorRequestModel({
        status: EMentorRequestStatus.Accepted,
        id
      });
      setLoading(false);
    }
  }, [id]);

  const onSubmit = useCallback(
    async (values: any) => {
      const { email: emailValue } = values;
      await onAcceptRequest();

      if (playerId && emailValue) {
        try {
          setLoading(true);
          await playerTransport.setPlayerEmail({ id: playerId, email: emailValue });
          getMentorRequestCollection({ ...params, offset: 0, limit: 100 });
        } catch (e) {
          if (e?.data?.code === 'error.playerWithEmailAlreadyLinkedException') {
            const { sourceCanMerge, targetCanMerge, targetId, sourceId } = e?.data || {};
            if (sourceCanMerge && targetCanMerge) {
              openUIPlayerMergeModal({ sourceId, targetId });
            } else {
              message.warning(translateErrors('Merging accounts is not possible'));
            }
          }
        } finally {
          setLoading(false);
        }
      }
      closeUIPlayerEmailEditModal();
    },
    [id, status]
  );

  return (
    <Modal
      destroyOnClose
      bodyStyle={{ border: 'none' }}
      visible={isVisible}
      onCancel={e => {
        e?.stopPropagation();
        e?.preventDefault();
        closeUIPlayerEmailEditModal();
      }}
      footer={false}
      title={t('Player email')}
    >
      <Form onFinish={onSubmit} fields={fields} layout="vertical">
        <Form.Item normalize={value => value.trimStart()} rules={[requiredField]} name="email" className="mb-5">
          <Input name="email" type="email" size="large" />
        </Form.Item>
        <Typography.Text className="color-grey fs-xxs">
          {t('Enter the player email that will be used to log into the JuniStat app')}
        </Typography.Text>
        <Row gutter={[16, 8]} className="mt-5">
          <Col xs={24} sm={12}>
            <Form.Item className="mb-0">
              <Button block htmlType="submit" type="primary" size="large" loading={loading} disabled={loading}>
                {t('Save')}
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Button
              block
              size="large"
              onClick={() => {
                closeUIPlayerEmailEditModal();
                onAcceptRequest();
              }}
              loading={loading}
              disabled={loading}
            >
              {t('Skip')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const PlayerEmailEditModal = communicationUI.injector(communicationMentorRequest.injector(PlayerEmailEditModalComponent));
