import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Spin from 'antd/es/spin';
import { CenterLayout } from 'common/components/Layouts/CenterLayout';
import { queryToObject } from 'common/helpers/filters.helper';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

interface IQuery {
  token?: string;
  player?: string;
}

type AllProps = IAuthConnectedProps & RouteComponentProps;

class SignUpConfirmComponent extends React.PureComponent<AllProps> {
  componentDidMount() {
    const { checkAuthUserConfirm } = this.props;
    const { token, player } = queryToObject<IQuery>({ token: undefined, player: undefined }) as IQuery;

    if (token) {
      checkAuthUserConfirm({ token, playerIdRedirect: player });
    }
  }

  render() {
    return (
      <CenterLayout>
        <Spin spinning />
      </CenterLayout>
    );
  }
}

export const SignUpConfirmPage = communicationAuth.injector(withRouter(SignUpConfirmComponent));
