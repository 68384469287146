import React from 'react';
import Modal from 'antd/es/modal/Modal';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { EMentorRequestToAcademyModalType } from 'entities/UI/UI.models';
import { MentorRequestToAcademyWithoutSub } from 'entities/AcademyRequest/components/Modal/MentorRequestToAcademyWithoutSub';
import { MentorRequestToAcademySuccess } from 'entities/AcademyRequest/components/Modal/MentorToAcademyRequestSuccess';

type AllProps = IUIConnectedProps;

const MentorRequestToAcademyModalComponent: React.FC<AllProps> = props => {
  const { UIMentorRequestToAcademyModal, closeUIMentorRequestToAcademyModal } = props;
  const isVisible = UIMentorRequestToAcademyModal.data?.isVisible;
  const modalType = UIMentorRequestToAcademyModal.data?.modalType;
  const isAddSubModal = modalType === EMentorRequestToAcademyModalType.AddSub;
  const isSuccessModal = modalType === EMentorRequestToAcademyModalType.Success;

  const closeModal = () => {
    closeUIMentorRequestToAcademyModal();
  };

  return (
    <Modal bodyStyle={{ border: 'none' }} width={376} visible={isVisible} onCancel={closeModal} footer={false} title={null}>
      {isAddSubModal && <MentorRequestToAcademyWithoutSub />}
      {isSuccessModal && <MentorRequestToAcademySuccess onClose={closeModal} />}
    </Modal>
  );
};

export const MentorRequestToAcademyModal = communicationUI.injector(MentorRequestToAcademyModalComponent);
