import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { IShareModel } from 'entities/Share/Share.models';

const basePath = '/shares';

class ShareTransport extends BaseHttpTransport<AxiosStatic, IShareModel, void, void, void, void> {
  public get = (id: string): Promise<IShareModel> => {
    return this.axios.get(`${this.path}/${id}/model`).then((r: any) => {
      return this.mapper && this.mapper.mapFrom && typeof this.mapper.mapFrom === 'function' ? this.mapper.mapFrom(r) : r;
    });
  };
}

export const shareTransport = new ShareTransport(basePath, axios);
