import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  buildCollectionResponseFormatter,
  buildCollectionPreRequestDataMapper,
  getSuccessType,
  getStartType
} from '@axmit/redux-communications';
import { put, select, takeLatest } from 'redux-saga/effects';
import message from 'antd/es/message';
import { translateToasts } from 'common/helpers/translate.helper';
import { IApplicationState } from 'app/store/reducers';
import {
  ITrainerAcademyRequestModel,
  ITrainerAcademyRequestCreateParams,
  ITrainerAcademyRequestParamsModel,
  ITrainerAcademyRequestCollection,
  ITrainerAcademyRequestCollectionFilter,
  ITrainerAcademyUpdateParams,
  ITrainerUpdateTestsBalance,
  ITrainerModel,
  ITrainerParamsModel,
  ITrainerUpdateModelForm,
  ETrainerSuccessMessage,
  ITrainerLinkCreateParams,
  ITrainerLinkParamsModel,
  ITrainerLinkModel,
  ITrainerLinkDeleteParams,
  ITrainerCollection,
  ITrainerCollectionFilter,
  ITrainerSearchCollectionFilter
} from 'entities/Trainer/Trainer.models';
import { trainerTransport } from 'entities/Trainer/Trainer.transport';
import { updateAcademyTrainerInviteModal } from 'entities/UI/UI.communication';
import { EAcademyTrainerInviteModalType } from 'entities/UI/UI.models';
import { userTransport } from 'entities/User/User.transport';
import { getPrivatePlayerCollectionStartType } from 'entities/PrivatePlayer/PrivatePlayer.communication';
import { ITeamModel } from 'entities/Team/Team.models';

export const namespace = 'trainer';

export interface ITrainerStoreProps {
  academyRequestCollection: StoreBranch<ITrainerAcademyRequestCollection, ITrainerAcademyRequestCollectionFilter>;
  collection: StoreBranch<ITrainerCollection, ITrainerCollectionFilter>;
}

export interface ITrainerConnectedProps {
  trainerModel: StoreBranch<ITrainerModel, ITrainerParamsModel>;
  trainerCollection: StoreBranch<ITrainerCollection, ITrainerCollectionFilter>;
  trainerLinkModel: StoreBranch<ITrainerLinkModel, ITrainerLinkParamsModel>;
  trainerAcademyRequestModel: StoreBranch<ITrainerAcademyRequestModel, ITrainerAcademyRequestParamsModel>;
  trainerAcademyRequestCollection: StoreBranch<ITrainerAcademyRequestCollection, ITrainerAcademyRequestCollectionFilter>;

  getTrainerModel(id: string): void;
  updateTrainerModel(params: ITrainerUpdateModelForm): void;
  updateBalanceTrainerModel(params: ITrainerUpdateTestsBalance): void;

  getTrainerCollection(params: ITrainerCollectionFilter): Promise<void>;
  getSearchTrainerCollection(params: ITrainerSearchCollectionFilter): void;

  getTrainerAcademyRequestModel(id: string): void;
  addTrainerAcademyRequestModel(params: ITrainerAcademyRequestCreateParams): void;
  updateTrainerAcademyRequestModel(params: ITrainerAcademyUpdateParams): void;

  getTrainerAcademyRequestCollection(filter?: ITrainerAcademyRequestCollectionFilter): Promise<void>;

  addTrainerLinkModel(params: ITrainerLinkCreateParams): void;
  deleteTrainerLinkModel(params: ITrainerLinkDeleteParams): void;

  clearTrainerModel(): void;
  clearTrainerCollection(): void;
  clearTrainerAcademyRequestModel(): void;
  clearTrainerAcademyRequestCollection(): void;
}

const TrainerRequestAPIProviders = [
  new APIProvider(EActionsTypes.get, userTransport.get),
  new APIProvider(EActionsTypes.update, userTransport.update, {
    mapParams: (originalParams: any) => ({
      ...originalParams,
      teams: originalParams?.teams?.map((team: ITeamModel) => team.id || team.name)
    }),
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    },
    onSuccess: () => {
      message.success(translateToasts(ETrainerSuccessMessage.Update));
    }
  }),
  new APIProvider('updateBalance', trainerTransport.updateBalanceTrainer, {
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    }
  })
];

const TrainerLinkAPIProviders = [
  new APIProvider(EActionsTypes.add, trainerTransport.addTrainerLink, {
    onSuccess: function*() {
      yield getPrivatePlayerCollectionStartType();
    }
  }),
  new APIProvider(EActionsTypes.delete, trainerTransport.deleteTrainerLink, {
    onSuccess: function*() {
      yield getPrivatePlayerCollectionStartType();
    }
  })
];

const TrainerAcademyRequestModelAPIProviders = [
  new APIProvider(EActionsTypes.get, trainerTransport.getTrainerAcademyRequestModel),
  new APIProvider(EActionsTypes.add, trainerTransport.addTrainerAcademyRequestModel, {
    onSuccess: function*(response: ITrainerAcademyRequestModel) {
      yield updateAcademyTrainerInviteModal({
        modalType: EAcademyTrainerInviteModalType.AfterAddTrainer,
        isVisible: true
      });

      if (response?.firstSend) {
        yield getAcademyRequestCollectionStartType();
      } else {
        message.success(translateToasts(ETrainerSuccessMessage.ResendInvitation));
      }
    }
  }),
  new APIProvider(EActionsTypes.update, trainerTransport.updateStatusTrainerAcademyRequest, {
    onSuccess: function*() {
      yield getAcademyRequestCollectionStartType();
    }
  })
];

const TrainerCollectionAPIProviders = [
  new APIProvider(
    EActionsTypes.get,
    trainerTransport.getCollection,
    {
      mapParams: originalParams => ({
        ...originalParams,
        orderDirection: 'ASC',
        orderField: 'name'
      }),
      mapSuccess: buildCollectionResponseFormatter<any, any>(),
      preRequestDataMapper: buildCollectionPreRequestDataMapper<ITrainerCollection, ITrainerCollectionFilter>()
    },
    takeLatest
  ),
  new APIProvider('getSearch', trainerTransport.getTrainerSearch, {
    mapSuccess: buildCollectionResponseFormatter<any, any>(),
    preRequestDataMapper: buildCollectionPreRequestDataMapper<ITrainerCollection, ITrainerCollectionFilter>()
  })
];

const TrainerAcademyRequestCollectionAPIProviders = [
  new APIProvider(
    EActionsTypes.get,
    trainerTransport.getTrainerAcademyRequestCollection,
    {
      mapParams: originalParams => ({
        ...originalParams,
        orderField: 'coachName',
        orderDirection: 'ASC'
      }),
      mapSuccess: buildCollectionResponseFormatter<any, any>(),
      preRequestDataMapper: buildCollectionPreRequestDataMapper<
        ITrainerAcademyRequestCollection,
        ITrainerAcademyRequestCollectionFilter
      >()
    },
    takeLatest
  )
];

const branches = [
  new Branch('model', TrainerRequestAPIProviders),
  new Branch('collection', TrainerCollectionAPIProviders),
  new Branch('linkModel', TrainerLinkAPIProviders),
  new Branch('academyRequestModel', TrainerAcademyRequestModelAPIProviders),
  new Branch('academyRequestCollection', TrainerAcademyRequestCollectionAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationTrainer = buildCommunication<ITrainerConnectedProps>(strategy);

export function* removeTrainerCollectionItem(trainerId: string) {
  const trainerCollection: ITrainerCollection = yield select((state: IApplicationState) => state[namespace].collection?.data);
  const trainerCollectionData = trainerCollection?.data;
  const existingItemIndex = trainerCollectionData?.findIndex(item => item?.id === trainerId);

  if (existingItemIndex > -1) {
    trainerCollectionData?.splice(existingItemIndex, 1);
  }

  const meta = { count: existingItemIndex > -1 ? trainerCollection.meta.count - 1 : trainerCollection.meta.count };

  const newCollection: ITrainerCollection = {
    meta,
    data: trainerCollectionData
  };
  yield getTrainerCollectionSuccessType(newCollection);
}

function* getTrainerCollectionSuccessType(payload: ITrainerCollection) {
  yield put({ type: getSuccessType(namespace, 'collection', EActionsTypes.get), payload });
}

export function* getTrainerCollectionStartType() {
  const payload: ITrainerCollectionFilter = yield select((state: IApplicationState) => state[namespace].collection?.params);

  payload.offset = 0;
  payload.limit = 20;

  yield put({ type: getStartType(namespace, 'collection', EActionsTypes.get), payload });
}

function* getAcademyRequestCollectionStartType() {
  const payload: ITrainerCollectionFilter = yield select(
    (state: IApplicationState) => state[namespace].academyRequestCollection?.params
  );

  payload.offset = 0;
  payload.limit = 20;

  yield put({ type: getStartType(namespace, 'academyRequestCollection', EActionsTypes.get), payload });
}
