import React from 'react';
import Select, { SelectProps } from 'antd/es/select';
import Tooltip from 'antd/es/tooltip';
import { useTranslation } from 'react-i18next';
import { EFieldPositions, FIELD_POSITIONS } from 'entities/Player/Player.models';

interface IProps extends SelectProps<any> {
  availablePositions?: EFieldPositions[];
  max?: number;
}

const SELECT_STYLE = { width: '100%' };

export const FieldPositionSelector: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const { availablePositions, ...allProps } = props;

  return (
    <Select
      placeholder={t('Positions')}
      getPopupContainer={triggerNode => triggerNode as HTMLElement}
      style={SELECT_STYLE}
      maxTagCount="responsive"
      optionLabelProp="value"
      {...allProps}
    >
      {Object.entries(FIELD_POSITIONS)
        .filter(([position]) => Array.isArray(availablePositions) && availablePositions.includes(position as EFieldPositions))
        .map(([position, { label }]) => (
          <Select.Option key={position} value={position}>
            <Tooltip title={t(label)}>
              <span className="d-block width-full">
                {position} ({t(label)})
              </span>
            </Tooltip>
          </Select.Option>
        ))}
    </Select>
  );
};
