import { IBasePathParams } from 'common/models/requestModels';

export interface ITariffModel extends Components.Schemas.TariffDto {}
export interface ITariffCollection extends Components.Schemas.TariffCollectionDto {}
export interface ITariffCollectionFilter extends Paths.TariffControllerList.QueryParameters {}

export interface ITariffUpdateStatus extends Components.Schemas.UpdateTariffStatusDto, IBasePathParams {}

export type TTariffParamsModel = ITariffUpdateStatus | IBasePathParams;

export enum ETariffTypes {
  Repeatable = 'repeatable'
}

export enum ETariffStatus {
  Active = 'active',
  Archived = 'archived'
}

export enum ETariffVisivility {
  Public = 'public',
  Private = 'private'
}

export const CANCEL_SUBSCRIPTION_TARIFF_ID = 'no-tariff';
