import React, { useMemo } from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import { useTranslation } from 'react-i18next';
import { ScrollSyncPane } from 'react-scroll-sync';
import Typography from 'antd/es/typography';
import Collapse from 'antd/es/collapse';
import { ETestRanging } from 'entities/Test/Test.models';
import { IPlayerProfileModel, IPlayerProfileSkillModel } from 'entities/Player/Player.models';
import { PlayersCompareSkillsElement } from 'entities/Player/components/PlayersCompare/PlayersCompareSkillsElement';
import { PlayerTestMacroSkillBar } from 'entities/PlayerTest/components/PlayerTestMacroSkillBar';

interface IComponentProps {
  profiles: IPlayerProfileModel[];
}

interface IPlayersCompareSkillModel extends IPlayerProfileSkillModel {
  playerId: string;
}

interface IPlayerSkillBestModel {
  playerId: string;
  value: number | null;
}

interface IPlayerCompareSkillDataItem {
  skills: IPlayersCompareSkillModel[];
  best?: IPlayerSkillBestModel;
  bestMicroSkills?: {
    [microSkillTag: string]: IPlayerSkillBestModel;
  };
}

interface IPlayerSkillsDataModel {
  [skillId: string]: IPlayerCompareSkillDataItem;
}

const Component: React.FC<IComponentProps> = props => {
  const { t } = useTranslation();
  const { profiles } = props;

  const skills = useMemo(
    () =>
      profiles[0].skills.map(skill => ({
        id: skill.skillId,
        tag: skill.tag,
        microSkillsTags: skill.microSkills.map(microSkill => microSkill.tag)
      })),
    [profiles]
  );

  const skillsData: IPlayerSkillsDataModel = useMemo(
    () =>
      skills.reduce(
        (acc, skill) => ({
          ...acc,
          [skill.id]: profiles.reduce(
            (profilesSkillsAcc, profile) => {
              const profileSkill = profile.skills.find(el => el.skillId === skill.id);

              if (profileSkill) {
                const isBest = !profilesSkillsAcc.best || (profileSkill.value ?? 0) > (profilesSkillsAcc.best.value ?? 0);
                const bestMicroSkills = { ...profilesSkillsAcc.bestMicroSkills };

                profileSkill.microSkills.forEach(microSkill => {
                  let isBestMicroSkill = false;

                  switch (true) {
                    case microSkill.value === null: {
                      isBestMicroSkill = false;
                      break;
                    }
                    case bestMicroSkills[microSkill.tag]?.value === undefined: {
                      isBestMicroSkill = true;
                      break;
                    }
                    case microSkill.ranging === ETestRanging.LB: {
                      isBestMicroSkill = (microSkill.value as number) > (bestMicroSkills[microSkill.tag].value as number);
                      break;
                    }
                    case microSkill.ranging === ETestRanging.SB: {
                      isBestMicroSkill = (microSkill.value as number) < (bestMicroSkills[microSkill.tag].value as number);
                      break;
                    }
                  }

                  bestMicroSkills[microSkill.tag] = isBestMicroSkill
                    ? { playerId: profile.playerId, value: microSkill.value }
                    : bestMicroSkills[microSkill.tag];
                });

                return {
                  skills: [...profilesSkillsAcc.skills, { ...profileSkill, playerId: profile.playerId }],
                  best: isBest ? { playerId: profile.playerId, value: profileSkill.value } : profilesSkillsAcc.best,
                  bestMicroSkills
                };
              }

              return profilesSkillsAcc;
            },
            { skills: [] } as IPlayerCompareSkillDataItem
          )
        }),
        {}
      ),
    [skills, profiles]
  );

  return (
    <Col className="players-compare__skills width-fit p-2">
      <Collapse ghost defaultActiveKey={skills.map(skill => skill.id)}>
        {skills.map(skill => (
          <Collapse.Panel
            className="width-fit width-max-full mt-3"
            key={skill.id}
            header={
              <Typography.Title level={4} className="mb-0">
                {t(skill.tag)}
              </Typography.Title>
            }
          >
            <Row wrap={false} className="width-fit width-max-full">
              {skillsData[skill.id] && (
                <Col className="width-fit">
                  <ScrollSyncPane>
                    <div className="players-compare__overflow no-scroll">
                      <Row wrap={false} className="width-fit">
                        {skillsData[skill.id].skills.map(skillData => (
                          <Col key={skillData.playerId} className="width-fit players-compare__skills__element">
                            <PlayerTestMacroSkillBar
                              wrapperClassName={
                                skillsData[skill.id].best?.playerId === skillData.playerId ? 'opacity-100' : 'opacity-50'
                              }
                              titleLevel={4}
                              showValue
                              value={skillData.value}
                              bgColor="component-bg-third"
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </ScrollSyncPane>
                  <div className="width-fit width-max-full players-compare__micro-skills__blocks">
                    {skill.microSkillsTags.map(microSkillTag => (
                      <div className="width-fit width-max-full players-compare__micro-skills__block" key={microSkillTag}>
                        <div className="mb-3">
                          <Typography.Text>{t(microSkillTag)}</Typography.Text>
                        </div>
                        <ScrollSyncPane>
                          <div className="players-compare__overflow no-scroll">
                            <Row wrap={false} className="width-fit">
                              {skillsData[skill.id].skills.map(skillData => (
                                <Col key={skillData.playerId} className="width-full players-compare__micro-skills__element">
                                  <PlayersCompareSkillsElement
                                    isBest={
                                      skillsData[skill.id]?.bestMicroSkills?.[microSkillTag]?.playerId === skillData.playerId
                                    }
                                    skill={skillData}
                                    microSkillTag={microSkillTag}
                                  />
                                </Col>
                              ))}
                            </Row>
                          </div>
                        </ScrollSyncPane>
                      </div>
                    ))}
                  </div>
                </Col>
              )}
            </Row>
          </Collapse.Panel>
        ))}
      </Collapse>
    </Col>
  );
};

export const PlayersCompareSkills = Component;
