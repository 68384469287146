import React, { useState } from 'react';
import { useParams } from 'react-router';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { isAcademyWorkerHelper } from 'common/helpers/permission.helper';
import { TestList } from 'entities/Test/components/List/TestList';
import { ITestModel } from 'entities/Test/Test.models';
import { TestModal } from 'entities/Test/components/Modal/TestModal';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';
import { TestSkillCardList } from 'entities/Test/components/List/TestSkillCardList';

interface IParams {
  id: string;
}

interface IProps {
  hideComplexity?: boolean;
  isRFS?: boolean;
}

type AllProps = IAuthConnectedProps & IProps;

const PlayerTestTabComponent: React.FC<AllProps> = props => {
  const { id: playerId } = useParams<IParams>();
  const { hideComplexity, authUser, isRFS } = props;
  const isAcademyWorker = isAcademyWorkerHelper(authUser?.data);
  const [showModal, setShowModal] = useState(false);
  const [testItem, setTestItem] = useState<ITestModel>();
  const testId = testItem?.id;
  const isHideComplexity = typeof hideComplexity === 'boolean' ? hideComplexity : isAcademyWorker;

  const openModal = (item: ITestModel) => {
    setShowModal(true);
    setTestItem(item);
  };
  const onClose = () => {
    setShowModal(false);
    setTestItem(undefined);
  };

  return (
    <Row className="layout__content">
      <Col span={24}>
        {isRFS ? (
          <TestList
            grid={{ gutter: 8, xs: 1, sm: 2, md: 3, lg: 2, xl: 3, xxl: 3 }}
            hideComplexity={isHideComplexity}
            filter={
              isRFS
                ? {
                    isVisibleForPlayer: false,
                    isVisibleForTrainer: true,
                    isVisibleForRfs: true
                  }
                : undefined
            }
            elementId="basicLayout"
            playerId={playerId}
            onItemClick={openModal}
            pageSize={50}
          />
        ) : (
          <TestSkillCardList hideComplexity={isHideComplexity} playerId={playerId} onItemClick={openModal} />
        )}
        {testItem && <TestModal item={testItem} playerId={playerId} testId={testId} onCancel={onClose} visible={showModal} />}
      </Col>
    </Row>
  );
};

export const PlayerTestTab = communicationAuth.injector(PlayerTestTabComponent);
