import { RouteComponentProps, withRouter } from 'react-router';
import React from 'react';
import { IBaseFilterModel } from 'common/models/requestModels';
import { InfiniteList } from 'common/components/InfiniteList';
import { communicationTest, ITestConnectedProps } from 'entities/Test/Test.communication';
import { ITestTeamHistoryCollection, ITestTeamHistoryCollectionFilter, ITestTeamHistoryModel } from 'entities/Test/Test.models';
import { TestTeamHistoryListItem } from 'entities/Test/components/List/TestTeamHistory/TestTeamHistoryListItem';

type AllProps = ITestConnectedProps & RouteComponentProps;

class TestTeamHistoryListComponent extends InfiniteList<
  ITestTeamHistoryCollection,
  ITestTeamHistoryModel,
  AllProps,
  ITestTeamHistoryCollectionFilter
> {
  loadCollection = async (params: IBaseFilterModel) => {
    const { getTestTeamHistoryCollection, filter } = this.props;
    if (filter?.id) {
      const { id, ...filterParamsData } = filter || {};
      const filterParams: ITestTeamHistoryCollectionFilter = { id, ...params, ...filterParamsData };

      return getTestTeamHistoryCollection(filterParams);
    }
  };

  getCollection = () => {
    const { testTeamHistoryCollection } = this.props;

    return testTeamHistoryCollection;
  };

  renderListItem = (item: ITestTeamHistoryModel) => {
    return <TestTeamHistoryListItem item={item} />;
  };

  clearCollection = () => {
    return this.props.clearTestTeamHistoryCollection();
  };
}

export const TestTeamHistoryList = communicationTest.injector(withRouter(TestTeamHistoryListComponent));
