import React from 'react';
import Button from 'antd/es/button';
import Typography from 'antd/es/typography';
import SearchOutlined from '@ant-design/icons/SearchOutlined';

const { Title, Text } = Typography;

export const ButtonsComponent: React.FC = () => (
  <>
    <Title>Buttons</Title>
    <Text>Type Primary</Text>
    <div>
      <Button type="primary" className="mr-2">
        Primary
      </Button>
      <Button type="primary" disabled className="mr-2">
        Disabled
      </Button>
      <Button type="primary" icon={<SearchOutlined />} className="mr-2">
        Search
      </Button>
      <Button type="primary" shape="circle" icon={<SearchOutlined />} className="mr-2" />
      <Button type="primary" icon={<SearchOutlined />} size="small" className="mr-2">
        Small
      </Button>
      <Button type="primary" icon={<SearchOutlined />} size="large" className="mr-2">
        Large
      </Button>
    </div>
    <Text>Type Default</Text>
    <div>
      <Button type="default" className="mr-2">
        Default
      </Button>
      <Button type="default" disabled className="mr-2">
        Disabled
      </Button>
      <Button type="default" icon={<SearchOutlined />} className="mr-2">
        Search
      </Button>
      <Button type="default" shape="circle" icon={<SearchOutlined />} className="mr-2" />
      <Button type="default" icon={<SearchOutlined />} size="small" className="mr-2">
        Small
      </Button>
      <Button type="default" icon={<SearchOutlined />} size="large" className="mr-2">
        Large
      </Button>
    </div>
    <Text>Type Dashed</Text>
    <div>
      <Button type="dashed" className="mr-2">
        Dashed
      </Button>
      <Button type="dashed" disabled className="mr-2">
        Disabled
      </Button>
      <Button type="dashed" icon={<SearchOutlined />} className="mr-2">
        Search
      </Button>
      <Button type="dashed" shape="circle" icon={<SearchOutlined />} className="mr-2" />
      <Button type="dashed" icon={<SearchOutlined />} size="small" className="mr-2">
        Small
      </Button>
      <Button type="dashed" icon={<SearchOutlined />} size="large" className="mr-2">
        Large
      </Button>
    </div>
    <Text>Link</Text>
    <div>
      <Button type="link" className="mr-2">
        Link
      </Button>
      <Button type="link" disabled className="mr-2">
        Disabled
      </Button>
      <Button type="link" icon={<SearchOutlined />} className="mr-2">
        Search
      </Button>
      <Button type="link" shape="circle" icon={<SearchOutlined />} className="mr-2" />
      <Button type="link" icon={<SearchOutlined />} size="small" className="mr-2">
        Small
      </Button>
      <Button type="link" icon={<SearchOutlined />} size="large" className="mr-2">
        Large
      </Button>
    </div>
    <Text>Text</Text>
    <div>
      <Button type="text" className="mr-2">
        Text
      </Button>
      <Button type="text" disabled className="mr-2">
        Disabled
      </Button>
      <Button type="text" icon={<SearchOutlined />} className="mr-2">
        Search
      </Button>
      <Button type="text" shape="circle" icon={<SearchOutlined />} className="mr-2" />
      <Button type="text" icon={<SearchOutlined />} size="small" className="mr-2">
        Small
      </Button>
      <Button type="text" icon={<SearchOutlined />} size="large" className="mr-2">
        Large
      </Button>
    </div>
    <Text>Danger</Text>
    <div>
      <Button type="primary" danger className="mr-2">
        Primary
      </Button>
      <Button type="default" danger className="mr-2">
        Default
      </Button>
      <Button type="dashed" danger className="mr-2">
        Dashed
      </Button>
      <Button type="link" danger className="mr-2">
        Danger
      </Button>
      <Button type="text" danger className="mr-2">
        Danger
      </Button>
    </div>
  </>
);
