import React from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import classNames from 'classnames';

interface IComponentProps {
  className?: string;
  rowClassName?: string;
  isSmall?: boolean;
  isMedium?: boolean;
  id?: string;
}

export const LayoutContent: React.FC<IComponentProps> = props => {
  const { children, className = '', id, isSmall = false, isMedium, rowClassName = '' } = props;

  return (
    <Row
      id={id}
      className={classNames(`layout__content height-full`, {
        isSmall: isSmall,
        isMedium: isMedium,
        [rowClassName as string]: Boolean(rowClassName)
      })}
      justify="center"
    >
      <Col span={24} className={`py-5 ${className}`}>
        {children}
      </Col>
    </Row>
  );
};
