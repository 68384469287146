import { TProcessings } from 'entities/Subscription/Subscription.models';
import { IPlayerPublicProfileTestResultsParams } from 'entities/Player/Player.models';
import { ITestResultsHistoryCollectionFilter } from 'entities/Test/Test.models';

export interface IModalModel {
  isVisible: boolean;
}

export enum ECommonModalType {
  Add = 'add',
  Edit = 'edit'
}
export interface IPlayerMergeModalModel {
  isVisible: boolean;
  sourceId?: string;
  targetId?: string;
}
export interface IPlayerMergeModalParams {
  sourceId?: string;
  targetId?: string;
}

export interface IPlayerEmailEditModalModel {
  isVisible: boolean;
  mentorRequestId?: string;
  playerId?: string;
  needAcceptRequest?: boolean;
}
export interface IPlayerEmailEditModalParams {
  mentorRequestId: string;
  playerId: string;
  needAcceptRequest?: boolean;
}

export enum EMentorRequestModalType {
  AddPlayer = 'add-player',
  AfterAddPlayer = 'after-add-player',
  InvitePlayer = 'invite-player'
}

export enum EFranchiseInviteModalType {
  AddAcademy = 'add-academy',
  AfterAddAcademy = 'after-add-academy'
}

export enum EAcademyTrainerInviteModalType {
  AddTrainer = 'add-trainery',
  AfterAddTrainer = 'after-add-trainer'
}

export enum EMentorInviteModalType {
  AddMentor = 'add-mentor',
  AfterAddMentor = 'after-add-mentor'
}

export interface IModalMentorRequestParams {
  modalType: EMentorRequestModalType | null;
}

export interface IModalMentorRequestModel {
  isVisible: boolean;
  modalType: EMentorRequestModalType | null;
}

export interface IModalMentorSignUpParams {
  modalType: EModalMentorSignUpType | null;
  tokenExpired?: boolean;
  token?: string;
}

export interface IModalMentorSignUpModel {
  isVisible: boolean;
  modalType: EModalMentorSignUpType | null;
  tokenExpired?: boolean;
  token?: string;
}

export interface IModalFranchiseInviteParams {
  modalType: EFranchiseInviteModalType | null;
}

export interface IModalFranchiseInviteModel {
  isVisible: boolean;
  modalType: EFranchiseInviteModalType | null;
}

export interface IModalAcademyPlayerCreateModel {
  isVisible: boolean;
}

export interface IModalAcademyTrainerInviteParams {
  modalType: EAcademyTrainerInviteModalType | null;
}

export interface IModalAcademyTrainerInviteModel {
  isVisible: boolean;
  modalType: EAcademyTrainerInviteModalType | null;
}

export interface IModalMentorInviteParams {
  modalType: EMentorInviteModalType | null;
  playerId?: string;
  inviteFromList?: boolean;
}

export interface IModalMentorInviteModel {
  isVisible: boolean;
  modalType: EMentorInviteModalType | null;
  playerId?: string;
  inviteFromList?: boolean;
}

export interface IModalPaymentRequirementsModel {
  isVisible: boolean;
  subscriptionId?: string;
  mentorRequestId?: string;
  processing?: TProcessings;
}

export interface IModalPaymentRequirementsParams {
  subscriptionId?: string;
  mentorRequestId?: string;
  processing?: TProcessings;
}

export interface IModalChangeTariffModel {
  isVisible: boolean;
  subscriptionId?: string;
  tariffId?: string;
}

export interface IModalChangeTariffParams {
  subscriptionId?: string;
  tariffId?: string;
}

export enum EModalStripePaymentType {
  ReactivateSubscription = 'reactivateSubscription'
}

export interface IModalStripePaymentParams {
  withCardDetails?: boolean;
  withCoupon?: boolean;
  title?: string;
  cardDetailBtnText?: string;
  loading?: boolean;
  tariff?: string;
  mentorRequest?: string;
  subscription?: string;
  type?: EModalStripePaymentType;
}

export interface IModalStripePaymentModel extends IModalStripePaymentParams {
  isVisible?: boolean;
}

export interface IModalStripeCancelSubscriptionParams {
  subscription?: string;
}

export interface IModalStripeCancelSubscriptionModel extends IModalStripeCancelSubscriptionParams {
  isVisible?: boolean;
}

export interface ITransferPlayerUIModel {
  isVisible: boolean;
  playerId: string | null;
}

export interface ITransferPlayerUIParams {
  playerId: string;
}

export enum EModalMentorSignUpType {
  SignUp = 'signUp',
  Accept = 'accept'
}
export enum EMentorRequestToAcademyModalType {
  AddSub = 'AddSub',
  Success = 'success'
}
export interface IModalMentorRequestToAcademyParams {
  modalType: EMentorRequestToAcademyModalType | null;
}

export interface IModalMentorRequestToAcademyModel {
  isVisible: boolean;
  modalType: EMentorRequestToAcademyModalType | null;
}

export interface IModalFranchiseInvitationAcceptModel {
  isVisible: boolean;
  franchiseName: string;
}

export interface IModalFranchiseInvitationAcceptParams {
  franchiseName: string;
}

export interface IModalPlayerProfileTestResultParams {
  params: IPlayerPublicProfileTestResultsParams;
}

export interface IModalPlayerProfileTestResultModel {
  isVisible: boolean;
  params: IPlayerPublicProfileTestResultsParams | null;
}

export interface IModalTestResultParams {
  params: Partial<ITestResultsHistoryCollectionFilter>;
}

export interface IModalTestResultModel {
  isVisible: boolean;
  params: Partial<ITestResultsHistoryCollectionFilter> | null;
}

export interface IAcademyLastTeamModel {
  lastTeam: { name: string; id?: string };
  academyId: string;
}

export interface IAcademyLastTeamParams {
  lastTeam: { name: string; id?: string };
  academyId: string;
}

export interface IShareLinkModel {
  url?: string;
  title?: string;
  description?: string;
}

export interface IShareLinkParams {
  url: string;
  title: string;
  description?: string;
}
