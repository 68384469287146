import { SOURCE_SERVICE } from 'common/const/config.const';
import { EAcademyViewTabKeys } from 'entities/Academy/Academy.models';
import { EPlayerSettingsTabKeys, EPlayerTabKeys } from 'entities/Player/Player.models';

export const PLAYERS_COMPARE_ROUTE_KEY = EAcademyViewTabKeys.Compare;

export enum ERoutesCommon {
  Root = '/',
  Components = '/components',
  PageForMock = '/page-for-mock',
  Notifications = '/notifications',
  JuniStatLink = '/junistat'
}

export enum ERoutesPublic {
  Redirect = '/redirect',
  RfsSSO = '/sso/rfs/callback',
  Welcome = '/welcome',
  Login = '/login',
  LoginMentor = '/login?role=mentor',
  SignUp = '/sign-up',
  SignUpAcademy = '/sign-up?role=academy',
  SignUpMentor = '/sign-up?role=mentor',
  SignUpSuccess = '/sign-up/success',
  PasswordForgot = '/password-forgot',
  PasswordRestore = '/password-restore/token',
  PasswordRestoreSuccess = '/password-restore-success',
  CoachSignUp = '/coach/confirm',
  CoachPasswordRestore = '/password-restore/coach',
  TrainerInviteNew = '/academy/:academyId/trainer/:trainerId/invite-new',
  TrainerInviteExist = '/academy/:academyId/trainer/:trainerId/invite-exists',
  SignUpConfirmUser = '/user/confirm',
  UserLogin = '/user/login',
  CoachLogin = '/coach/login',
  ServiceDocuments = '/service-documents',
  ShareView = '/share-view/:id',
  Rating = '/rating',
  RatingPlayer = '/rating-player',
  FranchiseInviteNew = '/franchise/:franchiseId/academy/:academyId/invite-new',
  FranchiseInviteExist = '/franchise/:franchiseId/academy/:academyId/invite-exists',
  MentorInvite = '/invite-mentors',
  MentorPlayerInviteNew = '/player/:playerId/mentor/:mentorId/invite-new',
  MentorPlayerInviteExist = '/player/:playerId/mentor/:mentorId/invite-exists',
  InfluencerStatisticsPage = '/influencer/statistic/:tag',
  PlayerProfile = '/player-profile/:playerId',
  PlayerProfileNotFound = '/player-profile/not-found',
  Player = '/player',
  PlayerView = '/player-view'
}
export enum ERoutesMentor {
  UsersEdit = '/users/edit',
  RequestList = '/requests',
  Player = '/player',
  PaymentAndSubscription = '/payment-and-subscription',
  Players = '/players'
}
export enum ERoutesAcademy {
  AcademyEdit = '/academy/edit',
  AcademyEditTests = '/academy/edit-tests',
  AcademyTestsList = '/academy/tests-list',
  AcademyReferrals = '/referrals/:type?',
  InitAcademyInfo = '/initial-academy-info',
  PlayersList = '/players-list',
  Player = '/player',
  Statistic = '/statistic',
  AcademyView = '/academy-view',
  PrivatePlayer = '/private-player'
}

export interface IQueries {
  [key: string]: string;
}

export const imgApiRoute = '/api/images/';
export const normalizePath = (url: string, isFullPath?: boolean) => (isFullPath ? location.origin + url : url);
export const sharePlayerProfileRoute = (playerId: string, isFullPath?: boolean) =>
  normalizePath(`/player/${playerId}`, isFullPath);
export const videoApiRoute = (videoId: string, isPublic?: boolean) =>
  `/api/videos/${videoId}/${isPublic ? 'public' : 'user'}?source=${SOURCE_SERVICE}`;
export const stripeSettingsPageRoute = (playerId: string) =>
  `${ERoutesMentor.Player}/${playerId}/${EPlayerTabKeys.Setting}/${EPlayerSettingsTabKeys.Subscription}`;
export const getAcademyPlayerRoute = (playerId: string, academyId: string) =>
  `${ERoutesAcademy.AcademyView}/${academyId}/${EAcademyViewTabKeys.Players}/${playerId}`;
export const getPlayerRoute = (playerId: string) => `${ERoutesPublic.Player}/${playerId}`;
export const getAcademyViewRoute = (tab?: string) => `${ERoutesAcademy.AcademyView}/:academyId${tab ? `/${tab}` : ''}`;
export const playersCompareRoute = () => `/${PLAYERS_COMPARE_ROUTE_KEY}`;
