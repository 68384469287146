import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { GiftcardForm, IGiftcardChangeEvent } from 'entities/Subscription/components/GiftcardForm';
import { communicationSubscription, ISubscriptionConnectedProps } from 'entities/Subscription/Subscription.communication';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';
import { EProcessings, ESubscriptionsEngines, TProcessings } from 'entities/Subscription/Subscription.models';
import { TariffsComponent } from 'entities/Tariff/components/Tariffs/TariffsComponent';

interface IProps {
  subscriptionId?: string;
  mentorRequestId?: string;
  processing?: TProcessings;
  closeModal: () => void;
}

type AllProps = IProps & ISubscriptionConnectedProps & IMentorRequestConnectedProps;

const PaymentRequirementsModalBodyComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation(['web-subscription-button', 'common-fields-rules']);
  const [chosenTariff, setChosenTariff] = useState<string | undefined>(undefined);
  const [giftcard, setGiftcard] = useState<IGiftcardChangeEvent | undefined>();
  const {
    addSubscriptionModel,
    subscriptionActivityModel,
    addSubscriptionActivityModel,
    getSubscriptionModel,
    getMentorRequestModel,
    clearSubscriptionModel,
    subscriptionModel,
    mentorRequestModel,
    subscriptionId,
    mentorRequestId,
    processing,
    clearSubscriptionActivityModel,
    closeModal
  } = props;
  const { data, loading: subscriptionLoading, errors: subscriptionErrors } = subscriptionModel;
  const { loading: subscriptionActivityLoading, errors: activityErrors } = subscriptionActivityModel;
  const { data: mentorRequestData, loading: mentorRequestLoading } = mentorRequestModel;

  const errors = activityErrors || subscriptionErrors;

  const mentorId = mentorRequestData?.mentor?.id;
  const playerId = mentorRequestData?.player?.id;
  const tariffId = data?.tariff?.id;

  const loading = useMemo(() => subscriptionLoading || subscriptionActivityLoading || mentorRequestLoading, [
    subscriptionLoading,
    subscriptionActivityLoading,
    mentorRequestLoading
  ]);

  useEffect(() => {
    if (subscriptionId) {
      getSubscriptionModel(subscriptionId);
    }
  }, [subscriptionId]);

  useEffect(() => {
    if (mentorRequestId) {
      getMentorRequestModel(mentorRequestId);
    }
  }, [mentorRequestId]);

  useEffect(() => {
    return () => {
      clearSubscriptionActivityModel();
      if (subscriptionId) {
        clearSubscriptionModel();
      }
    };
  }, []);

  const isFirstPayment = useMemo(() => {
    const prepaidTo = data?.prepaidTo;
    return !subscriptionId || !prepaidTo;
  }, [data, subscriptionId]);

  const paymentBtnDisabled = useMemo(
    () =>
      loading ||
      (subscriptionId ? false : !chosenTariff) ||
      ((isFirstPayment || !data?.hasSavedPaymentMethod) && !giftcard?.isGiftcardOk),
    [loading, giftcard, isFirstPayment, data, chosenTariff, subscriptionId]
  );

  const onPayReadyClick = useCallback(() => {
    const body: {
      giftcard?: string;
      isTest?: boolean;
    } = {};

    if ((isFirstPayment || !data?.hasSavedPaymentMethod) && giftcard?.isGiftcardOk && giftcard?.number) {
      body.giftcard = giftcard.number;
      body.isTest = true;
    }

    if (subscriptionId) {
      addSubscriptionActivityModel({
        subscriptionId,
        processing: processing || EProcessings.Yookassa,
        tariffId,
        ...body
      });
    } else if (mentorRequestId && chosenTariff) {
      addSubscriptionModel({
        mentorRequest: mentorRequestId,
        tariff: chosenTariff,
        engine: ESubscriptionsEngines.Yookassa,
        ...body
      });
    }
  }, [
    subscriptionId,
    mentorRequestId,
    processing,
    isFirstPayment,
    giftcard,
    chosenTariff,
    tariffId,
    data?.hasSavedPaymentMethod
  ]);

  return (
    <>
      <Typography.Title level={3} className="width-full t-align-c mb-5">
        {t('Subscription payment')}
      </Typography.Title>
      {!subscriptionId && (
        <Row justify="center" className="mb-5">
          <TariffsComponent
            chosenTariff={chosenTariff}
            onChoseTariff={setChosenTariff}
            subscriptionId={subscriptionId}
            mentorId={mentorId}
            playerId={playerId}
          />
        </Row>
      )}
      <Row className="mb-7 px-3">
        <Typography.Text className="mb-0 payment-requirements-modal__description">{t('payment-description')}</Typography.Text>
      </Row>
      <Row className="mb-5">
        <GiftcardForm
          engine={ESubscriptionsEngines.Yookassa}
          size="large"
          errors={errors}
          loading={loading}
          subscriptionId={subscriptionId}
          showApplyButton={!isFirstPayment && data?.hasSavedPaymentMethod}
          onGiftcardChanged={setGiftcard}
        />
      </Row>
      {isFirstPayment && giftcard?.isGiftcardOk && giftcard?.period && (
        <Row>
          <Typography.Paragraph>{t('test-period-description')}</Typography.Paragraph>
        </Row>
      )}
      <Button className="mb-5" disabled={paymentBtnDisabled} onClick={onPayReadyClick} type="primary" block size="large">
        {t('Proceed to checkout')}
      </Button>
      <Button disabled={loading} onClick={closeModal} type="text" danger block size="large">
        {t('Not now')}
      </Button>
    </>
  );
};

export const PaymentRequirementsModalBody = communicationMentorRequest.injector(
  communicationSubscription.injector(PaymentRequirementsModalBodyComponent)
);
