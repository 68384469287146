import React from 'react';
import Modal from 'antd/es/modal';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  children: React.ReactElement | React.ReactElement[];
}

export const PublicPlayerBaseModal: React.FC<IProps> = props => {
  const { visible, onCancel, children } = props;

  return (
    <Modal title=" " centered onCancel={onCancel} visible={visible} footer={null} className="rating-page__base-modal" width={864}>
      {children}
    </Modal>
  );
};
