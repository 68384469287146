import { EAuthRoles } from 'entities/Auth/Auth.models';
import { EUserRole } from 'entities/User/User.models';

export const roleHelper = (key?: EAuthRoles | EUserRole | string | null): EAuthRoles => {
  switch (key) {
    case EUserRole.Mentor:
    case EAuthRoles.Mentor:
      return EAuthRoles.Mentor;

    case EAuthRoles.Academy:
    case EUserRole.AcademyWorker:
      return EAuthRoles.Academy;

    default:
      return EAuthRoles.Mentor;
  }
};
