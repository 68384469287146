import { options } from 'options';
import React from 'react';
import { Skeleton } from 'antd';
import Autocomplete from 'react-google-autocomplete';
import debounce from 'lodash.debounce';
import { CloseCircleFilled } from '@ant-design/icons';
import { SelectProps } from 'antd/es/select';
import {
  ELocationType,
  ILocationModel,
  locationFormatter,
  parseLocation,
  parseLocationCountryCode
} from 'common/helpers/location.helper';
import { getCurrentLang } from 'common/helpers/i18n';

interface IComponentProps {
  placeholder?: string;
  value?: ILocationModel;
  onChange?: (value?: ILocationModel) => void;
  disabled?: boolean;
  types?: ELocationType[];
  withCountryCode?: boolean;
}

interface IComponentState {
  active: boolean;
  remount: boolean;
  key: string;
}

type AllProps = IComponentProps & SelectProps<ILocationModel>;

export class LocationInput extends React.PureComponent<AllProps, IComponentState> {
  state: IComponentState = {
    active: false,
    remount: false,
    key: 'key'
  };

  onChange = debounce((value?: ILocationModel) => {
    const { onChange } = this.props;
    if (onChange) {
      value ? onChange(value) : onChange(undefined);
    }
  }, 300);

  onKey = (event: KeyboardEvent) => {
    const { active } = this.state;

    if (active && event.keyCode === 13) {
      event.preventDefault();
    }
  };

  remount = () => {
    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({ remount: true }, () => {
      this.setState({ remount: false });
    });
  };

  componentDidUpdate(prevProps: AllProps) {
    if (!prevProps.value && this.props.value) {
      this.remount();
    }
  }

  delayedRemount() {
    // @ts-ignore
    if (!window.google?.maps) {
      setTimeout(() => {
        this.setState({ key: `key` });
      }, 0);
    }
    setTimeout(() => {
      // @ts-ignore
      if (window.google?.maps && this.state.key === 'key') {
        this.setState({ key: `${Math.random()}` });
      }
    }, 1000);
  }

  componentDidCatch() {
    this.delayedRemount();
  }

  render() {
    const {
      placeholder = '',
      value,
      disabled,
      className = '',
      types = [ELocationType.Address],
      withCountryCode,
      allowClear
    } = this.props;
    const { remount, key } = this.state;
    const initValue = locationFormatter(value);
    const lang = getCurrentLang();

    return (
      <div key={key}>
        {/* @ts-ignore */}
        {!remount && window.google?.maps ? (
          <>
            <Autocomplete
              apiKey={options.googleMapsApiKey}
              language={lang}
              className={`ant-input ant-input-lg ${className}`}
              placeholder={placeholder}
              style={{ width: '100%', textOverflow: 'ellipsis' }}
              defaultValue={initValue}
              onFocus={() => {
                this.setState({ active: true });
              }}
              onPlaceSelected={(place: any) => {
                if (!place || !place.geometry) {
                  this.onChange(undefined);
                  return;
                }

                const res: ILocationModel = {
                  region:
                    parseLocation(place, ELocationType.RegionLevelOne) || parseLocation(place, ELocationType.RegionLevelTwo),
                  country: parseLocation(place, ELocationType.Country),
                  city: parseLocation(place, ELocationType.City),
                  placeId: place.place_id || ''
                };

                if (withCountryCode) {
                  res.countryCode = parseLocationCountryCode(place);
                }

                this.onChange(res);
                this.setState({ active: false });
              }}
              options={{
                types
              }}
              onKeyPress={this.onKey}
              onKeyDown={this.onKey}
              onKeyUp={this.onKey}
              onChange={() => this.onChange(undefined)}
              disabled={disabled}
            />
            {allowClear && value && (
              <div
                className="ant-input-suffix"
                style={{ position: 'absolute', bottom: 13, right: 20 }}
                onClick={() => {
                  this.props?.onChange && this.props?.onChange(undefined);
                  this.setState({ remount: true }, () => {
                    this.setState({ remount: false });
                  });
                }}
              >
                <CloseCircleFilled className="anticon anticon-close-circle ant-input-clear-icon" />
              </div>
            )}
          </>
        ) : (
          (() => {
            this.delayedRemount();
            return <Skeleton.Input size="large" active={true} />;
          })()
        )}
      </div>
    );
  }
}
