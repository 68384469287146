import React, { useState, useEffect, useMemo } from 'react';
import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import Typography from 'antd/es/typography';
import Row from 'antd/es/row';
import { useTranslation } from 'react-i18next';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { TariffsComponent } from 'entities/Tariff/components/Tariffs/TariffsComponent';
import { ISubscriptionConnectedProps, communicationSubscription } from 'entities/Subscription/Subscription.communication';

type AllProps = IUIConnectedProps & ISubscriptionConnectedProps;

const ChangeTariffModalComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { closeUIChangeTariffModal, UIChangeTariffModal, subscriptionModel, changeTariffSubscriptionModel } = props;
  const [chosenTariff, setChosenTariff] = useState<string | undefined>('');
  const { loading } = subscriptionModel;
  const isVisible = UIChangeTariffModal.data?.isVisible;
  const subscriptionId = UIChangeTariffModal.data?.subscriptionId;
  const tariffId = UIChangeTariffModal.data?.tariffId;

  useEffect(() => {
    if (tariffId && tariffId !== chosenTariff) {
      setChosenTariff(tariffId);
    }
  }, [tariffId]);

  const isChangeBtnDisabled = useMemo(() => {
    return loading || !chosenTariff || tariffId === chosenTariff;
  }, [chosenTariff, tariffId, loading]);

  const onConfirmClick = () => {
    if (subscriptionId && chosenTariff) {
      changeTariffSubscriptionModel({
        subscriptionId,
        tariff: chosenTariff
      });
    }
  };

  return (
    <Modal
      visible={isVisible}
      width={396}
      footer={null}
      onCancel={closeUIChangeTariffModal}
      centered
      destroyOnClose
      className="change-tariff-modal"
    >
      <Typography.Title level={3} className="width-full t-align-c mb-5">
        {t('Change tariff')}
      </Typography.Title>
      <TariffsComponent chosenTariff={chosenTariff} onChoseTariff={setChosenTariff} subscriptionId={subscriptionId} />
      <Row className="mt-5">
        <Button className="mb-5" disabled={isChangeBtnDisabled} onClick={onConfirmClick} type="primary" block size="large">
          {t('Change')}
        </Button>
        <Button disabled={loading} onClick={closeUIChangeTariffModal} type="text" danger block size="large">
          {t('Revoke')}
        </Button>
      </Row>
    </Modal>
  );
};

export const ChangeTariffModal = communicationSubscription.injector(communicationUI.injector(ChangeTariffModalComponent));
