/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, useContext, useEffect } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import { useTranslation } from 'react-i18next';
import message from 'antd/es/message';
import { requiredField } from 'common/helpers/filed-rules';
import { LocationInput } from 'common/components/Input/LocationInput';
import { ELocationType } from 'common/helpers/location.helper';
import { StripeContext } from 'common/components/Stripe/StripeProvider';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: 'rgba(0, 0, 0, 0.25)'
      }
    },
    invalid: {
      color: '#ff4d4f',
      iconColor: '#ff4d4f'
    }
  },
  hidePostalCode: true,
  classes: {
    base: 'ant-input ant-input-lg stripe-card-element__card',
    invalid: 'stripe-card-element__card__error'
  }
};

const DEFAULT_FORM_NAME = 'stripe-save-payment';

export interface IStripeCardElementProps {
  formName?: string;
  onCardComplete?(cardComplete: boolean): void;
  onReady?(ready: boolean): void;
}

const Component: React.FC<IStripeCardElementProps> = props => {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const { formName = DEFAULT_FORM_NAME, onCardComplete, onReady } = props;
  const { setLastSavedPaymentMethod, setLoading } = useContext(StripeContext);

  useEffect(() => {
    if (onReady && stripe && elements) {
      onReady(true);
    }
  }, [stripe, elements]);

  const onSubmitCallback = useCallback(
    async (values: any) => {
      if (!stripe || !elements) {
        return;
      }

      const { address, postalCode } = values;
      const card = elements.getElement(CardElement);

      if (card) {
        setLoading?.(true);

        try {
          const result = await stripe.createPaymentMethod({
            type: 'card',
            card,
            billing_details: {
              address: {
                country: address?.countryCode,
                city: address?.city,
                postal_code: postalCode
              }
            }
          });

          if (result?.error) {
            console.error('Error while creating payment method in stripe', result.error);
            message.error(result.error.message);
          } else {
            const { paymentMethod } = result;

            if (paymentMethod?.id && paymentMethod.card && setLastSavedPaymentMethod) {
              const savedPayment = {
                paymentId: paymentMethod.id,
                card: paymentMethod.card
              };

              setLastSavedPaymentMethod(savedPayment);
            }
          }
        } catch (e) {
          console.error('Error while creating payment method in stripe', e);
          message.error(t("There's been an error while saving payment method"));
        }

        setLoading?.(false);
      }
    },
    [stripe, elements, setLastSavedPaymentMethod]
  );

  return (
    <Form onFinish={onSubmitCallback} name={formName} layout="vertical" className="stripe-card-element">
      <Form.Item name="card" label={t('Card')}>
        <CardElement
          options={CARD_ELEMENT_OPTIONS}
          onChange={e => {
            onCardComplete && onCardComplete(e?.complete);
          }}
        />
      </Form.Item>
      <Form.Item rules={[requiredField]} name="address" label={t('City')}>
        <LocationInput withCountryCode types={[ELocationType.Cities]} placeholder={t('City')} />
      </Form.Item>
      <Form.Item rules={[requiredField]} name="postalCode" label={t('Postal code')}>
        <Input
          size="large"
          placeholder={t('Postal code')}
          // precision={0}
          className="stripe-card-element__postal-code width-full"
        />
      </Form.Item>
    </Form>
  );
};

export const StripeCardElementComponent = Component;
