export const valueToPercent = (
  valueRaw: number | null | undefined,
  minValueRaw: number = 0,
  maxValueRaw: number = 0,
  ranging: 'SB' | 'LB' | undefined
) => {
  const isSB = ranging === 'SB';
  if (valueRaw === null || valueRaw === undefined) {
    return null;
  }
  if (maxValueRaw - minValueRaw === 0) {
    return 100;
  }
  let value = valueRaw;
  let min = minValueRaw;
  let max = maxValueRaw;

  if (isSB) {
    min = maxValueRaw;
    max = minValueRaw;
  }

  if (value < minValueRaw) {
    value = minValueRaw;
  }
  if (value > maxValueRaw) {
    value = maxValueRaw;
  }

  const delta = ((max - min) / 100) * 10;
  min = min - delta;

  if (max >= min) {
    return (100 / (max - min)) * (value - min);
  } else {
    return 100 - (100 / (min - max)) * (value - max);
  }
};
