import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { queryToObject } from 'common/helpers/filters.helper';
import { PlayerTestList } from 'entities/PlayerTest/components/PlayerTestList';
import { TestResultModal } from 'entities/Academy/components/View/Modal/TestResultModal';
import { ETestResultFilter, initTestResultFilter, ITestResultsHistoryCollectionFilter } from 'entities/Test/Test.models';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';

interface IParams {
  id: string;
}
interface IComponentProps {
  filterConfig?: ETestResultFilter[];
}

type AllProps = IUIConnectedProps & IComponentProps;

const PlayerTestResultTabComponent: React.FC<AllProps> = props => {
  const { id: playerId } = useParams<IParams>();
  const { openUITestResultModal, filterConfig } = props;

  useEffect(() => {
    const params = queryToObject<Partial<ITestResultsHistoryCollectionFilter>>(initTestResultFilter);
    if (params) {
      openUITestResultModal({ params });
    }
  }, []);

  const onItemClick = useCallback(
    ({ id, testTag, tags, from, to, ageGroups }: ITestResultsHistoryCollectionFilter) => {
      openUITestResultModal({
        params: {
          id,
          testTag,
          tags,
          from,
          to,
          ageGroups,
          onlyMy: false
        }
      });
    },
    [openUITestResultModal]
  );

  return (
    <Row className="layout__content" justify="center">
      <Col xs={24}>
        <PlayerTestList
          elementId="basicLayout"
          grid={{ gutter: 8, xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
          playerId={playerId}
          onItemClick={onItemClick}
        />
      </Col>
      <TestResultModal disableFilters filterConfig={filterConfig} />
    </Row>
  );
};

export const PlayerTestResultTab = communicationUI.injector(PlayerTestResultTabComponent);
