import React, { useEffect } from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Spin from 'antd/es/spin';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useMediaPredicate } from 'react-media-hook';
import Typography from 'antd/es/typography';
import { LayoutContent } from 'common/components/Layouts/ContentLayout';
import { SCREEN_MD } from 'common/const/config.const';
import { PHYSICAL_SKILLS, TECH_SKILLS } from 'entities/Player/Player.models';
import { communicationShare, IShareConnectedProps } from 'entities/Share/Share.communication';
import { ShareProfile } from 'entities/Share/components/ShareProfile';
import { ShareSkills } from 'entities/Share/components/ShareSkills';
import { SharePlayerAcademy } from 'entities/Share/components/SharePlayerAcademy';
import { ShareVideoCommon } from 'entities/Share/components/ShareVideoCommon';
import { LanguageSelect } from 'entities/Language/components/LanguageSelect';

const CommonSharedViewComponent: React.FC<IShareConnectedProps> = props => {
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${SCREEN_MD})`);
  const { t } = useTranslation();
  const { getShareModel, shareModel } = props;
  const { id } = useParams();
  const { loading, data } = shareModel;
  const { type } = data || {};
  const { player } = data?.data ?? {};
  const { skills, firstName, lastName, rating, ageGroup, imageUrl } = player ?? {};

  useEffect(() => {
    getShareModel(id);
  }, [id]);

  const content = React.useMemo(() => {
    if (type) {
      switch (type) {
        case 'academyToPlayerRequests': {
          return (
            <>
              <Col lg={8} md={24} sm={24} xs={24}>
                <ShareProfile
                  firstName={firstName}
                  imageUrl={imageUrl}
                  ageGroup={ageGroup}
                  rating={rating?.value}
                  lastName={lastName}
                  skills={skills}
                />
              </Col>
              <Col lg={16} md={24} sm={24} xs={24}>
                <SharePlayerAcademy />
              </Col>
            </>
          );
        }
        case 'playerRating': {
          return (
            <>
              <Col lg={8} md={24} sm={24} xs={24}>
                <ShareProfile
                  firstName={firstName}
                  imageUrl={imageUrl}
                  ageGroup={ageGroup}
                  rating={rating?.value}
                  lastName={lastName}
                  skills={skills}
                />
              </Col>
              <Col lg={8} md={12} sm={12} xs={24}>
                <ShareSkills color="#006FF2" title={t('Physical training')} skillsList={PHYSICAL_SKILLS} skills={skills} />
              </Col>
              <Col lg={8} md={12} sm={12} xs={24}>
                <ShareSkills color="#00C1FF" title={t('Technical training')} skillsList={TECH_SKILLS} skills={skills} />
              </Col>
            </>
          );
        }
        case 'feed': {
          return (
            <Col span={24}>
              <ShareVideoCommon type="feed" />
            </Col>
          );
        }
        default: {
          return (
            <Col span={24}>
              <ShareVideoCommon type="playerToTest" />
            </Col>
          );
        }
      }
    }
    return null;
  }, [type]);

  return (
    <LayoutContent rowClassName="promo-container">
      <Spin spinning={loading}>
        <Row gutter={[16, 16]} className="promo-container__content">
          {content}
        </Row>
      </Spin>
      {!isDesktop && (
        <Row justify="center" align="middle" className="mt-7">
          <Typography.Paragraph className="mb-0 mr-3">{t('Language')}</Typography.Paragraph>
          <Col span={10}>
            <LanguageSelect size="large" className="width-full" />
          </Col>
        </Row>
      )}
      <div className="promo-background"> </div>
    </LayoutContent>
  );
};

export const CommonSharedViewPage = communicationShare.injector(CommonSharedViewComponent);
