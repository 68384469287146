import { Col, Row, Typography } from 'antd';
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getCurrencySign } from 'common/helpers/tariff-helper';
import { IStripeNextWithdrawal } from 'entities/Subscription/Subscription.models';

interface IProps extends IStripeNextWithdrawal {
  className?: string;
}

const Component: React.FC<IProps> = props => {
  const { amount, currency, date, className } = props;
  const { t } = useTranslation();

  return (
    <Row className={classNames({ [className as string]: Boolean(className) })}>
      <Col xs={24}>
        <Typography.Title level={5} className="color-disable mb-0">
          {t('Next withdrawal')}
        </Typography.Title>
      </Col>
      <Col xs={24}>
        <Typography.Title level={5} className="my-0">
          {amount ? `${getCurrencySign(currency as string)}${amount} ${currency} • ${date}` : date}
        </Typography.Title>
      </Col>
    </Row>
  );
};

export const StripeSubscriptionInfoCardNextWithdrawal = Component;
