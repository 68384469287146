import { AbstractSearchableSelector } from 'common/components/Input/AbstractSearchableSelector';
import { getPlayerName } from 'common/helpers/name.helper';
import { EPrivatePlayerSearchCollectionFilterSorters } from 'entities/PrivatePlayer/PrivatePlayer.models';
import { IPlayerSearchCollectionFilter, IPlayerSearchModel } from 'entities/Player/Player.models';
import { playerTransport } from 'entities/Player/Player.transport';

interface IComponentProps {
  academyId?: string | string[];
  excludeTrainer?: string;
  excludeTeam?: string;
  orderField?: EPrivatePlayerSearchCollectionFilterSorters;
  playerType?: 'target' | 'source';
}

export class PrivatePlayerSelector extends AbstractSearchableSelector<IPlayerSearchModel, IComponentProps> {
  getItemLabel = (item: IPlayerSearchModel): string => {
    return getPlayerName(item);
  };

  loadData = (value?: string): Promise<{ data: IPlayerSearchModel[] }> => {
    const { academyId, excludeTrainer, excludeTeam, orderField, playerType } = this.props;
    const filter: IPlayerSearchCollectionFilter = {};

    if (value) {
      filter.name = value;
    }
    if (playerType !== undefined) {
      filter.merged = false;
      filter.regComplete = true;
      filter.juniCoach = playerType === 'target';
    }
    if (orderField) {
      filter.orderField = orderField;
    }
    if (academyId) {
      filter.academiesIds = Array.isArray(academyId) ? academyId : [academyId];
    }
    if (excludeTrainer) {
      filter.excludeTrainerId = excludeTrainer;
    }
    if (excludeTeam) {
      filter.excludeTeamId = excludeTeam;
    }

    return playerTransport.getPlayerSearchCollection(filter);
  };
}
