import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  ITeamModel,
  ITeamCreateParams,
  ITeamUpdateParams,
  ITeamCollectionFilter,
  ITeamCollection,
  ITeamUpdateTrainerParams,
  ITeamUpdatePlayerParams,
  IDeleteTeamParams,
  ITeamRemovePlayerParams,
  ITeamRemoveTrainerParams
} from 'entities/Team/Team.models';

const basePath = '/teams';

class TeamTransport extends BaseHttpTransport<
  AxiosStatic,
  ITeamModel,
  ITeamCollection,
  ITeamCreateParams,
  ITeamUpdateParams,
  ITeamCollectionFilter
> {
  public removeTeam = ({ id }: IDeleteTeamParams): Promise<ITeamModel> => axios.delete(`${basePath}/${id}`);

  public addPlayerToTeam = ({ id, ...rest }: ITeamUpdatePlayerParams): Promise<void> =>
    axios.put(`${basePath}/${id}/players`, rest);

  public removePlayerFromTeam = ({ id, player }: ITeamRemovePlayerParams): Promise<void> =>
    axios.delete(`${basePath}/${id}/players/${player}`);

  public addTrainerToTeam = ({ id, ...rest }: ITeamUpdateTrainerParams): Promise<void> =>
    axios.put(`${basePath}/${id}/trainers`, rest);

  public removeTrainerFromTeam = ({ id, trainer }: ITeamRemoveTrainerParams): Promise<void> =>
    axios.delete(`${basePath}/${id}/trainers/${trainer}`);
}

export const teamTransport = new TeamTransport(basePath, axios);
