import React from 'react';
import Checkbox from 'antd/es/checkbox';
import Typography from 'antd/es/typography';
import { TagsCheckbox } from 'common/components/TagsCheckbox/TagsCheckbox';

const { Title } = Typography;

export const CheckboxComponent: React.FC = () => {
  const [value, setValue] = React.useState<{ id: string }[]>([{ id: '0-10' }]);

  const onChange = (element: { id: string }[]) => {
    setValue(element);
  };

  return (
    <>
      <Title>Checkbox</Title>
      <div>
        <Checkbox />
      </div>
      <div>
        <TagsCheckbox value={value} onChange={onChange} />
      </div>
    </>
  );
};
