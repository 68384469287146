import React from 'react';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Typography from 'antd/es/typography';

const { Title, Text } = Typography;
const { TextArea } = Input;

export const InputsComponent: React.FC = () => (
  <>
    <Title>Inputs</Title>
    <div>
      <Input placeholder="Basic usage" className="mb-5" />
      <Text>Size large</Text>
      <Input size="large" placeholder="Basic usage" className="mb-5" />
      <Text>Textarea</Text>
      <TextArea placeholder="Basic usage" className="mb-5" />
      <Text className="d-block">InputNumber</Text>
      <InputNumber size="large" />
    </div>
  </>
);
