import React from 'react';
import Modal from 'antd/es/modal';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { PaymentRequirementsModalBody } from 'entities/Subscription/components/PaymentRequirements/PaymentRequirementsModalBody';

type AllProps = IUIConnectedProps;

const PaymentRequirementsModalComponent: React.FC<AllProps> = props => {
  const { closeUIPaymentRequirementsModal, UIPaymentRequirementsModal } = props;
  const isVisible = UIPaymentRequirementsModal.data?.isVisible;
  const subscriptionId = UIPaymentRequirementsModal.data?.subscriptionId;
  const mentorRequestId = UIPaymentRequirementsModal.data?.mentorRequestId;
  const processing = UIPaymentRequirementsModal.data?.processing;

  return (
    <Modal
      visible={isVisible}
      width={396}
      footer={null}
      onCancel={e => {
        e.stopPropagation();
        e.preventDefault();
        closeUIPaymentRequirementsModal();
      }}
      centered
      destroyOnClose
      className="payment-requirements-modal"
    >
      <PaymentRequirementsModalBody
        subscriptionId={subscriptionId}
        mentorRequestId={mentorRequestId}
        processing={processing}
        closeModal={closeUIPaymentRequirementsModal}
      />
    </Modal>
  );
};

export const PaymentRequirementsModal = communicationUI.injector(PaymentRequirementsModalComponent);
