import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import AndroidPlayEn from 'app/assets/images/android-play-en.svg';
import AppStoreEn from 'app/assets/images/app-store-en.svg';
import { getAppleLink, getGoogleLink } from 'entities/ReferralCode/ReferralCode.helper';

interface IProps {
  className?: string;
  justify?: 'start' | 'end' | 'space-between' | 'center' | 'space-around';
}

export const AppsMarket: React.FC<IProps> = props => {
  const { className, justify = 'start' } = props;

  return (
    <Row gutter={[16, 8]} justify={justify} align="middle" className={`${className}`}>
      <Col>
        <a href={getAppleLink()} target="_blank" rel="noopener noreferrer">
          <img width={131} height={44} src={AppStoreEn} alt="app-store" />
        </a>
      </Col>
      <Col>
        <a href={getGoogleLink()} target="_blank" rel="noopener noreferrer">
          <img width={146} height={44} src={AndroidPlayEn} alt="app-store" />
        </a>
      </Col>
    </Row>
  );
};
