import React from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';

export const CenteredWithLogoLayout: React.FC = props => {
  return (
    <Row className="height-full width-full layout__centered-with-logo" justify="center">
      <Col span={24} className="height-full">
        <Row justify="center" align="middle" className="pt-5 layout__centered-with-logo__children">
          {props.children}
        </Row>
      </Col>
    </Row>
  );
};
