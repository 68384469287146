import { EditOutlined } from '@ant-design/icons';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { Spin, Row, Col } from 'antd';
import { ERoutesMentor } from 'common/models/routesModel';
import { Paper } from 'common/components/Paper/Paper';
import { EPermissionType } from 'common/helpers/permission.helper';
import { EPlayerTabKeys, IPlayerProfileVideoModel } from 'entities/Player/Player.models';
import { PlayerVideosBlockItem } from 'entities/Player/components/VideoBlock/PlayerVideosBlockItem';
import { PrivateContent } from 'entities/Auth/components/PrivateContent';

interface IProps {
  data?: IPlayerProfileVideoModel[];
  loading?: boolean;
}

type AllProps = IProps;

const PlayerVideosBlockComponent: React.FC<AllProps> = props => {
  const { data, loading } = props;
  const { t } = useTranslation();
  const { push } = useHistory();
  const { id } = useParams();

  return (
    <Paper className="width-full">
      <Spin spinning={loading}>
        <Row justify="space-between" className="flex-row mb-5">
          <Typography.Title level={4} className="font-weight-5">
            {t('Player videos')}
          </Typography.Title>
          <PrivateContent availableFor={EPermissionType.ShowDataForMentor}>
            <EditOutlined
              className="color-gray cursor-pointer fs-sm"
              onClick={() => push(`${ERoutesMentor.Player}/${id}/${EPlayerTabKeys.Video}`)}
            />
          </PrivateContent>
        </Row>
        {data?.length ? (
          <Row gutter={[18, 24]} wrap>
            {data?.map(video => (
              <Col key={`${video.videoId || video.youtubeId}_${video.title}_${video.description}`} xs={24} md={8}>
                <PlayerVideosBlockItem item={video} />
              </Col>
            ))}
          </Row>
        ) : (
          <Typography.Text>{t('There is no video yet')}</Typography.Text>
        )}
      </Spin>
    </Paper>
  );
};

export const PlayerVideosBlock = PlayerVideosBlockComponent;
