import Typography from 'antd/es/typography';
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import moment, { Moment } from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'antd/es/date-picker';
import Button from 'antd/es/button';
import { DownloadOutlined } from '@ant-design/icons';
import { getBaseUrl } from 'common/helpers/axios.helper';
import { clientDateFormat, serverDateFormat } from 'common/models/dateModels';
import { dateFormatter, disabledFutureDates, getUTCEndOfDay, getUTCStartOfDay } from 'common/helpers/date.helper';
import { StatisticTypeSelector } from 'entities/Statistic/component/StatisticTypeSelector';
import { EStatisticTypeValue, IStatisticCollectionFilter } from 'entities/Statistic/Statistic.models';
import { getStatisticFileUrl } from 'entities/Statistic/Statistic.transport';

interface IComponentProps {
  onFilterChange: (filter: Partial<IStatisticCollectionFilter>) => void;
  filter?: Partial<IStatisticCollectionFilter>;
  franchiseId?: string;
}
const { RangePicker } = DatePicker;

export const StatisticFilterCard: React.FC<IComponentProps> = props => {
  const { t } = useTranslation();
  const { onFilterChange, filter, franchiseId } = props;
  const { from, to, statisticType, academyFilter, token } = filter || {};
  const host = getBaseUrl();

  const rangeValue = useMemo<[Moment | undefined, Moment | undefined]>(() => {
    const fromValue = from ? moment(from) : undefined;
    const toValue = to ? moment(to).utc() : undefined;

    return [fromValue, toValue];
  }, [from, to]);

  const onChangeRangePicker = (value: [Moment | null, Moment | null] | null) => {
    const fromRaw = value?.[0];
    const toRaw = value?.[1];

    onFilterChange({
      from: fromRaw ? dateFormatter(getUTCStartOfDay(fromRaw), serverDateFormat) : undefined,
      to: toRaw ? dateFormatter(getUTCEndOfDay(toRaw), serverDateFormat) : undefined
    });
  };

  const onChangeTypeSelector = (statisticTypeValue: EStatisticTypeValue) => {
    onFilterChange({ statisticType: statisticTypeValue });
  };

  // const onChangeAcademySelector = (academyModel?: IAcademySearchModel) => {
  //   const academyFilterValue = academyModel
  //     ? {
  //         id: academyModel?.id,
  //         name: academyModel?.name
  //       }
  //     : undefined;
  //
  //   onFilterChange({ academyFilter: academyFilterValue });
  // };

  return (
    <Card className="border-none width-full px-3">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={6}>
          <Typography.Text title={t('Type of statistic')}>{t('Type of statistic')}</Typography.Text>
          <StatisticTypeSelector value={statisticType} className="width-full mt-3" size="large" onChange={onChangeTypeSelector} />
        </Col>
        {/* <Col xs={24} sm={6}>*/}
        {/*  <Typography.Text>{t('Academy')}</Typography.Text>*/}
        {/*  <AcademySelector*/}
        {/*    allowClear*/}
        {/*    className="mt-3"*/}
        {/*    filters={{*/}
        {/*      status: 'active',*/}
        {/*      franchiseId*/}
        {/*    }}*/}
        {/*    onChange={onChangeAcademySelector}*/}
        {/*    value={academyFilter}*/}
        {/*    size="large"*/}
        {/*    placeholder={t('Academy')}*/}
        {/*  />*/}
        {/* </Col>*/}
        <Col xs={24} sm={8}>
          <Typography.Text>{t('Test date')}</Typography.Text>
          <RangePicker
            // @ts-ignore
            value={rangeValue}
            allowClear={false}
            className="width-full mt-3"
            size="large"
            disabledDate={disabledFutureDates}
            allowEmpty={[false, false]}
            showNow={false}
            showTime={false}
            placeholder={[t('Date from'), t('Date to')]}
            format={clientDateFormat}
            onChange={onChangeRangePicker}
          />
        </Col>
        <Col xs={24} sm={5}>
          <Typography.Text>{t('Download statistic report')}</Typography.Text>
          <a
            href={`${host}${getStatisticFileUrl({
              id: academyFilter?.id || franchiseId,
              statisticType,
              token,
              from,
              to,
              isFranchise: !academyFilter?.id || academyFilter?.id === franchiseId
            })}`}
            target="_blank"
            rel="noopener noreferrer"
            className="d-block mt-3"
          >
            <Button block type="primary" size="large" title={t('Download')}>
              <DownloadOutlined /> {t(`Download`)}
            </Button>
          </a>
        </Col>
      </Row>
    </Card>
  );
};
