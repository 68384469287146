import React, { useMemo } from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import DatePicker from 'antd/es/date-picker';
import Button from 'antd/es/button';
import { useTranslation } from 'react-i18next';
import { useFormMapper } from '@axmit/antd4-helpers';
import moment from 'moment';
import Typography from 'antd/es/typography';
import { commonStringRules, requiredField } from 'common/helpers/filed-rules';
import { clientDateFormat } from 'common/models/dateModels';
import { LocationInput } from 'common/components/Input/LocationInput';
import { ELocationType } from 'common/helpers/location.helper';
import { GenderSelect } from 'common/components/GenderSelect';
import { LeadingLegSelect } from 'entities/PrivatePlayer/components/LeadingLegSelect';
import { ELeadingLeg } from 'entities/PrivatePlayer/PrivatePlayer.models';
import { EUserGender, IUserWithPlayerSignUpPlayerModel } from 'entities/User/User.models';

interface IProps {
  data?: Partial<IUserWithPlayerSignUpPlayerModel>;
  onSubmit?: (value?: any) => void;
  isAuth?: boolean;
  disabled?: boolean;
}

type AllProps = IProps;

const PlayerCreateFormComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { onSubmit, data, isAuth, disabled } = props;
  const formData: any = useMemo(() => {
    return {
      ...data,
      address: data?.address || undefined,
      leadingLeg: ELeadingLeg?.Right,
      gender: EUserGender?.Male
    };
  }, [data]);

  const { fields } = useFormMapper(
    ['firstName', 'lastName', 'birthday', 'address', 'leadingLeg', 'playerEmail', 'gender'],
    formData
  );

  const onFinish = (values: any) => {
    if (onSubmit) {
      const { playerEmail, ...valueData } = values;
      if (playerEmail) {
        valueData.playerEmail = playerEmail;
      }
      onSubmit(valueData);
    }
  };

  return (
    <Form onFinish={onFinish} fields={fields} layout="vertical">
      <Form.Item
        normalize={value => value.trimStart()}
        rules={[requiredField, ...commonStringRules()]}
        name="firstName"
        label={t('First Name')}
        className="mb-5"
      >
        <Input name="firstName" type="text" size="large" />
      </Form.Item>
      <Form.Item
        normalize={value => value.trimStart()}
        rules={[requiredField, ...commonStringRules()]}
        name="lastName"
        label={t('Last Name')}
        className="mb-5"
      >
        <Input name="lastName" type="text" size="large" />
      </Form.Item>
      <Form.Item rules={[requiredField]} name="birthday" label={t('Birthday')} className="width-full mb-5">
        <DatePicker
          defaultPickerValue={moment().subtract(10, 'year')}
          disabledDate={current =>
            !current ||
            current.isAfter(
              moment()
                .subtract(1, 'year')
                .toISOString()
            )
          }
          inputReadOnly={false}
          className="width-full"
          size="large"
          showToday={false}
          format={clientDateFormat}
          placeholder={t('DD MM YYYY')}
        />
      </Form.Item>
      <Form.Item rules={[requiredField]} name="address" label={t('Address')} className="mb-5">
        <LocationInput types={[ELocationType.Cities]} size="large" />
      </Form.Item>
      <Form.Item rules={[requiredField]} name="gender" label={t('Gender')} className="mb-7">
        <GenderSelect size="large" />
      </Form.Item>
      <Form.Item rules={[requiredField]} name="leadingLeg" label={t('Leading leg')} className="mb-5">
        <LeadingLegSelect size="large" />
      </Form.Item>
      <Form.Item normalize={value => value.trimStart()} name="playerEmail" label={t('Player email')} className="mb-5">
        <Input name="playerEmail" type="email" size="large" />
      </Form.Item>
      <Typography.Text className="color-grey fs-xxs">
        {t('Enter the player email that will be used to log into the JuniStat app')}
      </Typography.Text>
      <Form.Item className="mb-0 mt-5">
        <Button block htmlType="submit" type="primary" size="large" loading={disabled} disabled={disabled}>
          {isAuth ? t('Create') : t('Next')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export const PlayerCreateForm = PlayerCreateFormComponent;
