import React, { ReactElement } from 'react';
import Skeleton from 'antd/es/skeleton';
import Typography from 'antd/es/typography';
import Col from 'antd/es/col';

interface IProps {
  loading: boolean;
  isEmpty?: boolean;
  emptyElement?: ReactElement | null;
  children: ReactElement;
  title?: { width?: number } | boolean;
  paragraph?: { width?: number; rows?: number } | boolean;
}

type AllProps = IProps;

const DEFAULT_TITLE = { width: 50 };
const DEFAULT_EMPTY_ELEMENT = <Typography.Title level={2}>-</Typography.Title>;

const PlayerShieldBlockWrapperComponent: React.FC<AllProps> = props => {
  const { isEmpty, loading, children, title = DEFAULT_TITLE, paragraph = false, emptyElement = DEFAULT_EMPTY_ELEMENT } = props;

  return loading ? (
    <Col>
      <Skeleton title={title} active paragraph={paragraph} />
    </Col>
  ) : !isEmpty ? (
    children
  ) : (
    emptyElement
  );
};

export const PlayerShieldBlockWrapper = PlayerShieldBlockWrapperComponent;
