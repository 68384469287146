import React from 'react';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { TrainersBadge } from 'common/components/TrainerBadge';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { ITeamPureModel } from 'entities/Team/Team.models';

interface IComponentProps {
  team: ITeamPureModel;
}

type AllProps = IComponentProps;

export const TeamCardComponent: React.FC<AllProps> = props => {
  const { team } = props;
  const { name, playerCount, trainers } = team;
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const { t } = useTranslation();

  return (
    <Card hoverable bordered={false} className="team-card">
      <Row className="flex-noWrap" align={isDesktop ? 'middle' : 'top'}>
        <Row justify="space-between" align="middle" className="width-full">
          <Col xs={24} lg={15}>
            <div className="team-card__body">
              <Row justify="center" className="flex-column">
                <div className="ant-typography-ellipsis ant-typography-ellipsis-multiple-line fs-xxs font-weight-7 " title={name}>
                  {name}
                </div>
                {playerCount !== undefined && (
                  <div
                    className="ant-typography-ellipsis ant-typography-ellipsis-multiple-line fs-xxxs font-weight-5 mb-2"
                    title={`${t('Players')}: ${playerCount}`}
                  >{`${t('Players')}: ${playerCount}`}</div>
                )}
              </Row>
              <TrainersBadge trainers={trainers} />
            </div>
          </Col>
        </Row>
      </Row>
    </Card>
  );
};

export const TeamCard = TeamCardComponent;
