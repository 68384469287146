import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { IBasePathParams } from 'common/models/requestModels';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  IPlayerAcademyCollectionFilter,
  IPlayerAcademyModelParams,
  IPlayerCollection,
  IPlayerCollectionFilter,
  IPlayerModel,
  IPlayerRatingCollectionFilter,
  IPlayerSkillCollectionFilter,
  IPlayerTestResultsParams,
  IPlayerUpdateParams,
  IPlayerUpdateMentorRequestSettings,
  IAddPlayerVideoParams,
  IPlayerVideoModel,
  IPlayerVideoCollection,
  IUpdatePlayerVideoParams,
  IDeletePlayerVideoParams,
  IPlayerVideoCollectionFilter,
  IPlayerInviteParams,
  IPlayerUpdateByMentorParams,
  IPlayerPublicProfileModel,
  IPlayerPublicProfileTestResults,
  IPlayerPublicProfileTestResultsParams,
  IPlayerProfileTestResultsModel,
  IPlayerProfileTestResultsParams,
  IPlayerSearchCollectionFilter,
  IPlayerSearchCollection,
  IPlayerMergeParams,
  IPlayerTestResultCollection,
  PLAYER_COMPARE_LS_FIELD_NAME,
  TPlayerCompareModel,
  IPlayerProfileCollection,
  IPlayerProfileModel,
  IPlayerProfileCollectionParams,
  IPlayerChangeAcademyParams,
  IPlayerProfileParams,
  IPlayerCheckTokenParams
} from 'entities/Player/Player.models';
import { IAcademyCollection, IAcademyModel } from 'entities/Academy/Academy.models';

const basePath = '/players';
const publicProfilePath = '/public/profile';

class PlayerTransport extends BaseHttpTransport<
  AxiosStatic,
  IPlayerModel,
  IPlayerCollection,
  null,
  IPlayerUpdateParams,
  IPlayerCollectionFilter
> {
  public changePlayerAcademy = ({ id, ...params }: IPlayerChangeAcademyParams) => axios.put(`${basePath}/${id}/academy`, params);
  public mergePlayers = (params: IPlayerMergeParams): Promise<IPlayerModel> => axios.post(`${basePath}/merge`, params);

  public sendPlayerInvite = (params: IPlayerInviteParams): Promise<void> => axios.post(`${basePath}/send-invite`, params);

  public getPlayerModel = (id: string): Promise<IPlayerModel> => axios.get(`${basePath}/${id}/pure`);

  public checkShareToken = ({ playerId, token }: IPlayerCheckTokenParams): Promise<void> =>
    axios.post(`${basePath}/${playerId}/check-token`, { token });

  public getPlayerPhysicalHistory = (params: IPlayerSkillCollectionFilter): Promise<void> =>
    axios.get(`${basePath}/${params.id}/skill-history/${params.skillId}`);

  public getPlayerTechnicalHistory = (params: IPlayerSkillCollectionFilter): Promise<void> =>
    axios.get(`${basePath}/${params.id}/skill-history/${params.skillId}`);

  public getPlayerRatingHistory = (params: IPlayerRatingCollectionFilter): Promise<void> =>
    axios.get(`${basePath}/${params.id}/rating-history`);

  public getAcademyCollectionForPlayer = ({ playerId, ...params }: IPlayerAcademyCollectionFilter): Promise<IAcademyCollection> =>
    axios.get(`${basePath}/${playerId}/academies${objectToQuery(params)}`);

  public setPlayerEmail = ({ id, ...body }: IPlayerInviteParams & IBasePathParams): Promise<IPlayerModel> =>
    axios.put(`${basePath}/${id}/set-email`, body);

  public updateMentorRequestPlayerSettings = ({ id, ...body }: IPlayerUpdateMentorRequestSettings): Promise<IPlayerModel> =>
    axios.put(`${basePath}/${id}/settings`, body);

  public updatePlayerByMentor = ({ id, ...body }: IPlayerUpdateByMentorParams): Promise<IPlayerModel> =>
    axios.put(`${basePath}/${id}/by-mentor`, body);

  public getAcademyModelForPlayer = ({ playerId, academyId }: IPlayerAcademyModelParams): Promise<IAcademyModel> =>
    axios.get(`${basePath}/${playerId}/academies/${academyId}`);

  public getPlayerTestHistory = ({
    playerId,
    testId,
    onlyBestOfDay
  }: IPlayerTestResultsParams): Promise<IPlayerTestResultCollection> =>
    axios.get(`${basePath}/${playerId}/test-history/${testId}`, {
      params: {
        onlyBestOfDay
      }
    });

  public createInviteMentorsToken = (): Promise<{ token?: string }> => axios.post(`/trainers${basePath}/invite-mentors-token`);

  public addPlayerVideo = ({ playerId, ...body }: IAddPlayerVideoParams): Promise<IPlayerVideoModel> =>
    axios.post(`${basePath}/${playerId}/player-videos`, body);

  public updatePlayerVideosModel = ({ playerId, id, ...body }: IUpdatePlayerVideoParams): Promise<IPlayerVideoModel> =>
    axios.put(`${basePath}/${playerId}/player-videos/${id}`, body);

  public getPlayerVideosCollection = ({ playerId, ...params }: IPlayerVideoCollectionFilter): Promise<IPlayerVideoCollection> =>
    axios.get(`${basePath}/${playerId}/player-videos${objectToQuery(params)}`);

  public deletePlayerVideosModel = ({ playerId, id }: IDeletePlayerVideoParams): Promise<IPlayerVideoModel> =>
    axios.delete(`${basePath}/${playerId}/player-videos/${id}`);

  public getPlayerProfile = ({ playerId, token }: IPlayerProfileParams): Promise<IPlayerProfileModel> =>
    axios.get(`${basePath}/${playerId}/profile${objectToQuery({ token })}`);

  public getPlayerProfileTestResult = ({
    playerId,
    testId
  }: IPlayerProfileTestResultsParams): Promise<IPlayerProfileTestResultsModel> =>
    axios.get(`${basePath}/${playerId}/tests/${testId}/results`);

  public getPlayerPublicProfile = (id: string): Promise<IPlayerPublicProfileModel> => axios.get(`${publicProfilePath}/${id}`);

  public getPlayerPublicProfileTestResult = ({
    id,
    date,
    test
  }: IPlayerPublicProfileTestResultsParams): Promise<IPlayerPublicProfileTestResults> =>
    axios.get(`${publicProfilePath}/${id}/test/${test}/${date}`);
  public getPlayerSearchCollection = (params: IPlayerSearchCollectionFilter): Promise<IPlayerSearchCollection> =>
    axios.get(`${basePath}/search${objectToQuery(params)}`);

  public getPlayersCompare = (): Promise<TPlayerCompareModel> => {
    return new Promise(resolve => {
      const playersCompare = localStorage.getItem(PLAYER_COMPARE_LS_FIELD_NAME);

      resolve(playersCompare?.length ? JSON.parse(playersCompare) : []);
    });
  };

  public addPlayerCompare = async (playerId: string): Promise<TPlayerCompareModel> => {
    const savedPlayersCompare = await this.getPlayersCompare();
    let newPlayersCompare = savedPlayersCompare;

    if (!savedPlayersCompare?.includes(playerId)) {
      if (savedPlayersCompare && savedPlayersCompare?.length > 3) {
        savedPlayersCompare.pop();
      }
      newPlayersCompare = savedPlayersCompare?.length ? [playerId, ...savedPlayersCompare] : [playerId];
    }

    return new Promise(resolve => {
      localStorage.setItem(PLAYER_COMPARE_LS_FIELD_NAME, JSON.stringify(newPlayersCompare));

      resolve(newPlayersCompare);
    });
  };

  public deletePlayerCompare = async (playerId: string): Promise<TPlayerCompareModel> => {
    const playersCompare = await this.getPlayersCompare();
    const indexToRemove = playersCompare?.findIndex?.(comparePlayerId => comparePlayerId === playerId);

    if (indexToRemove !== undefined && indexToRemove > -1) {
      playersCompare?.splice(indexToRemove, 1);
    }

    return new Promise(resolve => {
      localStorage.setItem(PLAYER_COMPARE_LS_FIELD_NAME, JSON.stringify(playersCompare));

      resolve(playersCompare);
    });
  };

  public savePlayerCompare = async (playersIds: string[]): Promise<TPlayerCompareModel> => {
    return new Promise(resolve => {
      localStorage.setItem(PLAYER_COMPARE_LS_FIELD_NAME, JSON.stringify(playersIds));

      resolve(playersIds);
    });
  };

  public getPlayerProfileCollection = async (params: IPlayerProfileCollectionParams): Promise<IPlayerProfileCollection> => {
    const profilesPromises = await Promise.allSettled(params.players.map(({ id }) => this.getPlayerProfile({ playerId: id })));
    const profiles = profilesPromises.reduce((acc: IPlayerProfileModel[], profilePromise, currentIndex) => {
      if (profilePromise.status === 'fulfilled') {
        acc.push(profilePromise.value);
      } else {
        this.deletePlayerCompare(params.players[currentIndex].id);
      }

      return acc;
    }, []);

    return {
      meta: { count: profiles.length },
      data: profiles
    };
  };
}

export const playerTransport = new PlayerTransport(basePath, axios);
