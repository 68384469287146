import React from 'react';
import Space from 'antd/es/space';
import Tag from 'antd/es/tag';
import { useTranslation } from 'react-i18next';

export enum ETagColors {
  Default = 'default',
  Green = 'green',
  Yellow = 'yellow',
  Orange = 'orange',
  Red = 'red'
}
export enum ETagColorValue {
  Green = '20-25',
  Yellow = '16-19',
  Orange = '11-15',
  Red = '0-10'
}

export enum ETagColorRangeConfig {
  Min = 0,
  Red = 10,
  Orange = 15,
  Yellow = 19,
  Green = 25
}

interface IComponentProps {
  value?: { id: string }[];
  onChange?: (item: { id: string }[]) => void;
}

type AllProps = IComponentProps;

export const TagsCheckbox = (props: AllProps) => {
  const { value = [], onChange } = props;
  const { t } = useTranslation();

  const optionsData = [
    {
      label: t('green'),
      value: { id: ETagColorValue.Green },
      type: ETagColors.Green
    },
    {
      label: t('yellow'),
      value: { id: ETagColorValue.Yellow },
      type: ETagColors.Yellow
    },
    {
      label: t('orange'),
      value: { id: ETagColorValue.Orange },
      type: ETagColors.Orange
    },
    {
      label: t('red'),
      value: { id: ETagColorValue.Red },
      type: ETagColors.Red
    }
  ];
  const onCheckboxChange = (item: { id: string }) => (checked: boolean) => {
    if (onChange) {
      if (checked) {
        onChange([...value, item]);
      } else {
        onChange(value.filter(element => item.id !== element.id));
      }
    }
  };

  return (
    <Space className="component-tags-checkbox" size={1}>
      {optionsData.map(({ label, value: optionValue, type = ETagColors.Default }) => (
        <Tag.CheckableTag
          className={type}
          key={optionValue.id}
          checked={value.findIndex(item => item.id === optionValue.id) >= 0}
          onChange={onCheckboxChange(optionValue)}
        >
          {label}
        </Tag.CheckableTag>
      ))}
    </Space>
  );
};
