import React, { useEffect, useMemo, useState } from 'react';
import Spin from 'antd/es/spin';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Typography from 'antd/es/typography';
import Card from 'antd/es/card';
import { useDebouncedCallback } from 'use-debounce';
import { ERoutesCommon } from 'common/models/routesModel';
import { ETestBalanceRoles, TestsBalanceEditor } from 'common/components/TestsBalanceEditor';
import { requiredField } from 'common/helpers/filed-rules';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { isAcademyFranchisor } from 'common/helpers/franchise.helper';
import { ETrainerAcademyRequestStatus } from 'entities/Trainer/Trainer.models';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationTrainer, ITrainerConnectedProps } from 'entities/Trainer/Trainer.communication';
import { DEFAULT_PRIVATE_PLAYER_LIST_GRID, PrivatePlayerList } from 'entities/PrivatePlayer/components/List/PrivatePlayersList';
import { TrainerForm } from 'entities/Trainer/components/TrainerForm';
import { PrivatePlayerSelector } from 'entities/PrivatePlayer/components/Selectors/PrivatePlayerSelector';
import {
  EPrivatePlayerActionTypes,
  EPrivatePlayerSearchCollectionFilterSorters,
  IPrivatePlayerCollectionFilter
} from 'entities/PrivatePlayer/PrivatePlayer.models';
import {
  PrivatePlayerListFilterCard,
  PrivatePlayerFilterCardsFilters
} from 'entities/PrivatePlayer/components/List/PrivatePlayerListFilterCard';
import { IPlayerModel } from 'entities/Player/Player.models';

interface IProps {
  isDesktop: boolean;
}

const initState: IPrivatePlayerCollectionFilter = {
  name: undefined,
  ageGroup: undefined,
  year: undefined,
  gender: undefined,
  hasTeam: undefined,
  team: undefined,
  hideArchived: true
};

const clearedState: IPrivatePlayerCollectionFilter = {
  ...initState
};

type AllProps = IProps & IAuthConnectedProps & ITrainerConnectedProps;

const TrainerAcademyPageComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { trainerId } = useParams();
  const [addPlayerForm] = Form.useForm();
  const history = useHistory();
  const {
    authAcademy,
    trainerModel,
    getTrainerModel,
    isDesktop,
    clearTrainerModel,
    updateBalanceTrainerModel,
    addTrainerLinkModel,
    trainerLinkModel
  } = props;
  const { data: academy, loading: authLoading } = authAcademy;
  const { data: trainer, params: trainerParams, loading: trainerLoading, errors: trainerErrors } = trainerModel;
  const { loading: trainerLinkLoading } = trainerLinkModel;
  const academyBalance = trainer?.trainerRequest?.academy?.testBalance;
  const trainerBalance = trainer?.testBalance;
  const trainerAcademyId = trainer?.trainerRequest?.academy?.id;
  const authAcademyId = authAcademy?.data?.id;
  const isFranchisor = isAcademyFranchisor(authAcademy?.data);
  const playerSelectorAcademyFilter = isFranchisor && authAcademyId === trainerAcademyId ? undefined : trainerAcademyId;
  const [renderIndex, setRenderIndex] = useState(Math.random());
  const [state, setState] = useState<Partial<IPrivatePlayerCollectionFilter>>({
    ...initState,
    ...queryToObject<Partial<IPrivatePlayerCollectionFilter>>(initState)
  });

  const isLoading = useMemo(() => authLoading || trainerLoading, [authLoading, trainerLoading]);

  useEffect(() => {
    if (trainerId) {
      getTrainerModel(trainerId);
    }
    return clearTrainerModel;
  }, [trainerId, getTrainerModel, clearTrainerModel]);

  const debPush = useDebouncedCallback((val: any) => {
    history.replace(val);
  }, 300);

  useEffect(() => {
    debPush.callback({ search: objectToQuery(state) });
  }, [state]);

  useEffect(() => {
    if (!isLoading && trainer && academy) {
      const academyFranchiseId = academy?.franchiseRequest?.franchise?.id;
      const trainerFranchiseId = trainer?.trainerRequest?.academy?.franchiseRequest?.franchise?.id;
      const trainerFranchiseRequestStatus = trainer?.trainerRequest?.status;

      if (trainerFranchiseRequestStatus !== ETrainerAcademyRequestStatus.Active || academyFranchiseId !== trainerFranchiseId) {
        history.push(ERoutesCommon.Root);
      }
    }
  }, [isLoading, academy, trainer]);

  const onChangeBalance = (diff: number) => {
    if (trainer?.id) {
      updateBalanceTrainerModel({ id: trainer?.id, balanceDiff: diff });
    }
  };

  const onAddPlayer = (form: { player: IPlayerModel }) => {
    if (trainer?.id) {
      addTrainerLinkModel({ id: trainer?.id, player: form.player?.id });
      addPlayerForm.resetFields();
    }
  };

  return (
    <Spin spinning={isLoading} size="large">
      <Row className="mb-5">
        <TrainerForm />
      </Row>

      <Card className="px-3 mt-5" bordered={false}>
        <Typography.Title className="mb-5" level={4}>
          {t('Trainer balance')}
        </Typography.Title>
        <TestsBalanceEditor
          params={trainerParams}
          errors={trainerErrors}
          onChange={onChangeBalance}
          toBalance={trainerBalance}
          fromBalance={academyBalance}
          loading={isLoading}
          from={ETestBalanceRoles.Academy}
          to={ETestBalanceRoles.Trainer}
        />
      </Card>
      {trainer?.id && (
        <Col span={24}>
          <Card className="px-3 mt-5" bordered={false}>
            <Typography.Title className="mb-5" level={4}>
              {t('Players')}
            </Typography.Title>
            <Row>
              <Form
                form={addPlayerForm}
                onFinish={onAddPlayer}
                name="add-player-to-trainer"
                layout="horizontal"
                className="width-full"
              >
                <Row className="width-full" justify="space-between">
                  <Col xs={24} md={17}>
                    <Form.Item name="player" rules={[requiredField]}>
                      <PrivatePlayerSelector
                        excludeTrainer={trainer?.id}
                        size="large"
                        orderField={EPrivatePlayerSearchCollectionFilterSorters.ByAcademy}
                        disabled={trainerLinkLoading || trainerLoading}
                        academyId={playerSelectorAcademyFilter}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Button
                      block
                      htmlType="submit"
                      type="primary"
                      size="large"
                      disabled={trainerLinkLoading}
                      loading={trainerLinkLoading}
                    >
                      {t('Link')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Row>
          </Card>
          <Col xs={24} className="mt-5">
            <Row className="height-full mb-5" key={renderIndex}>
              <PrivatePlayerListFilterCard
                enabledFilters={[
                  PrivatePlayerFilterCardsFilters.Name,
                  PrivatePlayerFilterCardsFilters.AgeGroup,
                  PrivatePlayerFilterCardsFilters.Year,
                  PrivatePlayerFilterCardsFilters.Team,
                  PrivatePlayerFilterCardsFilters.PlayerCount
                ]}
                academyId={trainerAcademyId}
                onFilterChange={partialFilter => setState({ ...state, ...partialFilter })}
                filter={state}
              />
            </Row>
            <Row className={`height-full mt-5 ${isDesktop ? '' : 'px-5'}`}>
              <PrivatePlayerList
                grid={DEFAULT_PRIVATE_PLAYER_LIST_GRID}
                hasPlaceholder
                initFilter={initState}
                elementId="basicLayout"
                trainerId={trainer?.id}
                filter={state}
                clearFilter={() => {
                  setState({ ...clearedState });
                  setRenderIndex(Math.random());
                }}
                actionType={EPrivatePlayerActionTypes.Unlink}
              />
            </Row>
          </Col>
        </Col>
      )}
    </Spin>
  );
};

export const TrainerAcademyPage = communicationTrainer.injector(communicationAuth.injector(TrainerAcademyPageComponent));
