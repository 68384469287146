import React, { useEffect, useMemo, useState } from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useMediaPredicate } from 'react-media-hook';
import Typography from 'antd/es/typography';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { StatisticTable } from 'entities/Statistic/component/StatisticTable';
import {
  EStatisticTypeValue,
  initStatisticState,
  IStatisticCollectionFilter,
  statisticTableConfig
} from 'entities/Statistic/Statistic.models';
import { StatisticFilterCard } from 'entities/Statistic/component/StatisticFilterCard';

type AllProps = IAuthConnectedProps;

const StatisticTablePageComponent: React.FC<AllProps> = props => {
  const { authAcademy, authModel } = props;
  const { data: authAcademyModelData } = authAcademy;
  const { id, franchiseRequest } = authAcademyModelData || {};
  const { id: franchiseId } = useMemo(() => franchiseRequest?.franchise, [franchiseRequest]) || {};
  const token = useMemo(() => authModel.data?.access.token, [authModel]);
  const { t } = useTranslation();
  const history = useHistory();
  const [state, setState] = useState<IStatisticCollectionFilter>({
    statisticType: EStatisticTypeValue.Test,
    ...initStatisticState,
    ...queryToObject<Partial<IStatisticCollectionFilter>>(initStatisticState)
  });
  const tableConfig = useMemo(() => statisticTableConfig(state.statisticType === EStatisticTypeValue.Billing), [state]) || {};
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);

  const debPush = useDebouncedCallback((val: any) => {
    history.replace(val);
  }, 300);

  useEffect(() => {
    debPush.callback({ search: objectToQuery(state) });
  }, [state]);

  return (
    <>
      <Row className={`pb-5 ${!isDesktop ? 'px-3' : ''}`} justify="space-between" align="middle">
        <Typography.Title className="mb-0" level={isDesktop ? 2 : 3}>
          {t('Statistic')}
        </Typography.Title>
      </Row>

      {id ? (
        <Col xs={24}>
          <Row className="height-full mb-5">
            <StatisticFilterCard
              onFilterChange={partialFilter =>
                setState({
                  ...state,
                  ...partialFilter,
                  from: partialFilter?.from || initStatisticState.from,
                  to: partialFilter?.to || initStatisticState.to
                })
              }
              filter={{ ...state, token }}
              franchiseId={franchiseId}
            />
          </Row>
          <Row className={`height-full ${isDesktop ? '' : 'px-5'}`}>
            <StatisticTable
              props={{
                showHeader: true,
                scroll: { x: 1200, y: 500 },
                bordered: false,
                size: 'middle'
              }}
              isDesktop={isDesktop}
              filter={state}
              config={tableConfig}
              franchiseId={franchiseId}
            />
          </Row>
        </Col>
      ) : null}
    </>
  );
};

export const StatisticTablePage = communicationAuth.injector(StatisticTablePageComponent);
