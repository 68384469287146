import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  IAcademyRequestAcademyToPlayerParams,
  IAcademyRequestCollection,
  IAcademyRequestCollectionFilter,
  IAcademyRequestModel,
  IAcademyRequestPlayerToAcademyParams,
  IAcademyRequestUpdateParams
} from 'entities/AcademyRequest/AcademyRequest.models';

const basePath = '/academy-requests';

class AcademyRequestTransport extends BaseHttpTransport<
  AxiosStatic,
  IAcademyRequestModel,
  IAcademyRequestCollection,
  void,
  IAcademyRequestUpdateParams,
  IAcademyRequestCollectionFilter
> {
  public requestAcademyToPlayer = (params: IAcademyRequestAcademyToPlayerParams): Promise<IAcademyRequestModel> =>
    axios.post(`${basePath}/academy-to-player`, params);
  public requestPlayerToAcademy = (params: IAcademyRequestPlayerToAcademyParams): Promise<IAcademyRequestModel> =>
    axios.post(`${basePath}/player-to-academy`, params);
}

export const academyRequestTransport = new AcademyRequestTransport(basePath, axios);
