import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import { useMediaPredicate } from 'react-media-hook';
import { TableOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Tabs from 'antd/es/tabs';
import RadarChartOutlined from '@ant-design/icons/RadarChartOutlined';
import LineChartOutlined from '@ant-design/icons/LineChartOutlined';
import { MEDIA_DESKTOP, SCREEN_LG } from 'common/const/config.const';
import { LayoutContent } from 'common/components/Layouts/ContentLayout';
import { ERoutesPublic } from 'common/models/routesModel';
import { PlayerProfileCommonView } from 'entities/Player/components/Profile/PlayerProfileCommonView';
import { PlayerProfileSkillView } from 'entities/Player/components/Profile/PlayerProfileSkillView';
import { EPlayerUnauthorizedTabKeys } from 'entities/Player/Player.models';
import { PlayerProfileCardInfo } from 'entities/Player/components/Card/PlayerProfileInfo';
import { PlayerProvider } from 'entities/Player/components/PlayerProvider';
import { PlayerTestResultTab } from 'entities/Player/components/Tabs/PlayerTestResultTab';
import { ETestResultFilter } from 'entities/Test/Test.models';

const UnauthorizedPlayerPageComponent: React.FC = () => {
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const tabPosition = isDesktop ? 'left' : 'top';
  const isLg: boolean = useMediaPredicate(`(min-width: ${SCREEN_LG})`);
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const tabKey = pathname.split(url)[1].split('/')[1];
  const secondId = pathname.split(url)[1].split('/')[2];
  const [searchValue, setSearchValue] = useState<string | undefined>(search);
  const [tabValue, setTabValue] = useState<EPlayerUnauthorizedTabKeys>();
  const isPlayerViewPage = history.location.pathname.includes(ERoutesPublic.PlayerView);
  const selectTab = (key: string): EPlayerUnauthorizedTabKeys => {
    switch (key) {
      case EPlayerUnauthorizedTabKeys.Training:
        return EPlayerUnauthorizedTabKeys.Training;
      case EPlayerUnauthorizedTabKeys.Progress:
        return EPlayerUnauthorizedTabKeys.Progress;
      case EPlayerUnauthorizedTabKeys.TestResults:
        return EPlayerUnauthorizedTabKeys.TestResults;

      default:
        return EPlayerUnauthorizedTabKeys.Training;
    }
  };

  useEffect(() => {
    replace(selectTab(tabKey));
    setTabValue(selectTab(tabKey));
  }, [tabKey]);

  const onTabChange = (tab: string) => {
    const selectedTab = selectTab(tab);
    setSearchValue(undefined);
    setTabValue(selectedTab);
    history.push({ pathname: `${url}/${selectedTab}`, search: undefined });
  };

  const replace = (tab: string) => {
    secondId
      ? history.replace({ pathname: `${url}/${selectTab(tab)}/${secondId}`, search: searchValue })
      : history.replace({ pathname: `${url}/${selectTab(tab)}`, search: searchValue });
  };

  const onSkillSelectCallback = useCallback(
    (skillId: string) => {
      history.push({ pathname: `${url}/${EPlayerUnauthorizedTabKeys.Progress}/${skillId}`, search: undefined });
    },
    [url]
  );

  return (
    <LayoutContent className="width-full">
      <PlayerProvider>
        <Row
          className={`${isDesktop ? 'flex-row' : 'flex-column mobile'} unauthorized-player-profile width-full ${
            isPlayerViewPage ? 'mt-0 pt-0' : 'mt-6'
          }`}
        >
          <Col className="unauthorized-player-profile__tabs">
            <Tabs
              className="unauthorized-player-profile__tabs-container"
              tabPosition={tabPosition}
              onChange={onTabChange}
              activeKey={tabValue}
            >
              <>
                <Tabs.TabPane
                  tab={
                    <>
                      <RadarChartOutlined /> {t('Training')}
                    </>
                  }
                  key={EPlayerUnauthorizedTabKeys.Training}
                />
                <Tabs.TabPane
                  tab={
                    <>
                      <LineChartOutlined /> {t('Progress')}
                    </>
                  }
                  key={EPlayerUnauthorizedTabKeys.Progress}
                />
                <Tabs.TabPane
                  tab={
                    <>
                      <TableOutlined /> {t('Test results')}
                    </>
                  }
                  key={EPlayerUnauthorizedTabKeys.TestResults}
                />
              </>
            </Tabs>
          </Col>
          <Col className="unauthorized-player-profile__content">
            <Switch>
              <Route path={`${path}/${EPlayerUnauthorizedTabKeys.Training}`} exact>
                <PlayerProfileCommonView onSkillSelectCallback={onSkillSelectCallback} unauthorized />
              </Route>
              <Route path={`${path}/${EPlayerUnauthorizedTabKeys.Progress}/:skillId?`} exact>
                <>
                  <Col xs={24} sm={10} md={8} className={classNames('mb-3', { 'px-5': !isLg })}>
                    <PlayerProfileCardInfo onClick={() => onTabChange(EPlayerUnauthorizedTabKeys.Training)} />
                  </Col>
                  <PlayerProfileSkillView onSkillSelectCallback={onSkillSelectCallback} />
                </>
              </Route>
              <Route path={`${path}/${EPlayerUnauthorizedTabKeys.TestResults}`} exact>
                <PlayerTestResultTab
                  filterConfig={[
                    ETestResultFilter.PlayerName,
                    ETestResultFilter.AgeGroup,
                    ETestResultFilter.UploaderType,
                    ETestResultFilter.BirthYear,
                    ETestResultFilter.MyAcademy
                  ]}
                />
              </Route>
              <Route path={`${path}/*`}>
                <PlayerProfileCommonView onSkillSelectCallback={onSkillSelectCallback} unauthorized />
              </Route>
            </Switch>
          </Col>
        </Row>
      </PlayerProvider>
    </LayoutContent>
  );
};

export const UnauthorizedPlayerPage = UnauthorizedPlayerPageComponent;
