import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Tabs from 'antd/es/tabs';
import BankOutlined from '@ant-design/icons/BankOutlined';
import DashboardOutlined from '@ant-design/icons/DashboardOutlined';
import BarChartOutlined from '@ant-design/icons/BarChartOutlined';
import LineChartOutlined from '@ant-design/icons/LineChartOutlined';
import RadarChartOutlined from '@ant-design/icons/RadarChartOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import classNames from 'classnames';
import { TableOutlined } from '@ant-design/icons';
import { useMediaPredicate } from 'react-media-hook';
import { isCurrentUserMentorForSomeoneElse } from 'common/helpers/player.helper';
import { SCREEN_LG } from 'common/const/config.const';
import { EPermissionType } from 'common/helpers/permission.helper';
import { getAcademyPlayerRoute } from 'common/models/routesModel';
import { MentorAcademyPage } from 'app/pages/Mentor/MentorAcademyPage';
import { PlayerTestTab } from 'entities/Player/components/Tabs/PlayerTestTab';
import { EPlayerTabKeys, EPlayerUnauthorizedTabKeys } from 'entities/Player/Player.models';
import { PlayerSettingTab } from 'entities/Player/components/Tabs/PlayerSettingTab/PlayerSettingTab';
import { PlayerAcademyTab } from 'entities/Player/components/Tabs/PlayerAcademyTab';
import { PlayerAboutTab } from 'entities/Player/components/Tabs/PlayerAboutTab';
import { PlayerContactTab } from 'entities/Player/components/Tabs/PlayerContactTab';
import { isAvailableForPlayer, PrivateContent } from 'entities/Auth/components/PrivateContent';
import { EUserRole } from 'entities/User/User.models';
import { PlayerProfileCommonView } from 'entities/Player/components/Profile/PlayerProfileCommonView';
import { PlayerProfileCardInfo } from 'entities/Player/components/Card/PlayerProfileInfo';
import { PlayerProfileSkillView } from 'entities/Player/components/Profile/PlayerProfileSkillView';
import { PlayerTestResultTab } from 'entities/Player/components/Tabs/PlayerTestResultTab';
import { ETestResultFilter } from 'entities/Test/Test.models';
import { PlayerProfileProviderContext, PlayerProviderContext } from 'entities/Player/components/PlayerProvider';
import { PlayerSubscriptionModal } from 'entities/Player/components/Modal/PlayerSubscriptionModal';

interface IComponentProps {
  isDesktop: boolean;
}

type AllProps = IComponentProps;

const menuConfig = [
  {
    title: 'Training',
    icon: <RadarChartOutlined />,
    key: EPlayerTabKeys.Training
  },
  {
    title: 'Progress',
    icon: <LineChartOutlined />,
    key: EPlayerTabKeys.Progress
  },
  {
    title: 'Test results',
    icon: <TableOutlined />,
    key: EPlayerTabKeys.TestResults
  },
  {
    title: 'Academies',
    icon: <BankOutlined />,
    key: EPlayerTabKeys.Academy,
    availableFor: [EUserRole.Mentor]
  },
  {
    title: 'Tests',
    icon: <DashboardOutlined />,
    key: EPlayerTabKeys.Tests,
    availableFor: [EUserRole.Mentor]
  },
  {
    title: 'RFS',
    icon: <BarChartOutlined />,
    key: EPlayerTabKeys.RFS,
    availableFor: [EUserRole.Mentor]
  },
  {
    title: 'About me',
    icon: <UserOutlined />,
    key: EPlayerTabKeys.Video,
    availableFor: [EUserRole.Mentor]
  },
  {
    title: 'Settings',
    icon: <SettingOutlined />,
    key: EPlayerTabKeys.Setting,
    availableFor: [EUserRole.Mentor]
  },
  {
    title: 'Contacts',
    icon: <InfoCircleOutlined />,
    key: EPlayerTabKeys.Contacts,
    availableFor: [EUserRole.AcademyWorker]
  }
];

const PlayerTabsComponent: React.FC<AllProps> = props => {
  const { isDesktop } = props;
  const { t } = useTranslation();
  const tabPosition = isDesktop ? 'left' : 'top';
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { path, url } = useRouteMatch();
  const { profile, isUserAcademyPlayer, playerId } = useContext(PlayerProfileProviderContext);
  const { player } = useContext(PlayerProviderContext);
  const tabKey = pathname.split(url)[1].split('/')[1];
  const secondId = pathname.split(url)[1].split('/')[2];
  const [searchValue, setSearchValue] = useState<string | undefined>(search);
  const [tabValue, setTabValue] = useState<EPlayerTabKeys>();
  const isUserMentorForSomeoneElse = isCurrentUserMentorForSomeoneElse();
  const isAvailableRfsTests = false;

  useEffect(() => {
    replace(selectTab(tabKey));
    setTabValue(selectTab(tabKey));
  }, [tabKey]);

  const onSkillSelectCallback = useCallback(
    (skillId: string) => {
      history.push({ pathname: `${url}/${EPlayerUnauthorizedTabKeys.Progress}/${skillId}`, search: undefined });
    },
    [url]
  );

  const isLg: boolean = useMediaPredicate(`(min-width: ${SCREEN_LG})`);

  const onTabChange = (tab: string) => {
    const selectedTab = selectTab(tab);
    setSearchValue(undefined);
    setTabValue(selectedTab);
    history.push({ pathname: `${url}/${selectedTab}`, search: undefined });
  };

  const replace = (tab: string) => {
    secondId
      ? history.replace({ pathname: `${url}/${selectTab(tab)}/${secondId}`, search: searchValue })
      : history.replace({ pathname: `${url}/${selectTab(tab)}`, search: searchValue });
  };

  const selectTab = (key: string): EPlayerTabKeys => {
    const configItem = menuConfig.find(el => el?.key === key);
    const isHidden = !!configItem && configItem?.availableFor && !isAvailableForPlayer(configItem?.availableFor);

    if (isHidden) {
      return EPlayerTabKeys.Training;
    } else {
      switch (key) {
        case EPlayerTabKeys.Academy:
          return EPlayerTabKeys.Academy;
        case EPlayerTabKeys.Training:
          return EPlayerTabKeys.Training;
        case EPlayerTabKeys.Progress:
          return EPlayerTabKeys.Progress;
        case EPlayerTabKeys.TestResults:
          return EPlayerTabKeys.TestResults;
        case EPlayerTabKeys.Tests:
          return EPlayerTabKeys.Tests;
        case EPlayerTabKeys.RFS:
          return isAvailableRfsTests ? EPlayerTabKeys.RFS : EPlayerTabKeys.Tests;
        case EPlayerTabKeys.Video:
          return EPlayerTabKeys.Video;
        case EPlayerTabKeys.Setting:
          return EPlayerTabKeys.Setting;
        case EPlayerTabKeys.Contacts:
          return EPlayerTabKeys.Contacts;

        default:
          return EPlayerTabKeys.Training;
      }
    }
  };

  const tabs = useMemo(() => {
    return menuConfig.map(el => {
      const isHidden = el.availableFor && !isAvailableForPlayer(el.availableFor);

      if (isHidden) {
        return null;
      }
      if (el?.key === EPlayerTabKeys.RFS && !isAvailableRfsTests) {
        return null;
      }
      return (
        <Tabs.TabPane
          tab={
            <>
              {el.icon}
              {t(el.title)}
            </>
          }
          key={el.key}
        />
      );
    });
  }, [player, profile]);

  const routes = useMemo(() => {
    return menuConfig.map(el => {
      const isHidden = el.availableFor && !isAvailableForPlayer(el.availableFor);

      if (isHidden) {
        return null;
      } else {
        switch (el.key) {
          case EPlayerTabKeys.Training:
            return (
              <Route key={el?.key} path={`${path}/${EPlayerTabKeys.Training}`} exact>
                <PlayerProfileCommonView
                  onSkillSelectCallback={onSkillSelectCallback}
                  isUserMentorForSomeoneElse={isUserMentorForSomeoneElse}
                />
              </Route>
            );
          case EPlayerTabKeys.Progress:
            return (
              <Route key={el?.key} path={`${path}/${EPlayerTabKeys.Progress}/:skillId?`} exact>
                <PlayerProfileSkillView onSkillSelectCallback={onSkillSelectCallback} />
              </Route>
            );
          case EPlayerTabKeys.TestResults:
            return (
              <Route key={el?.key} path={`${path}/${EPlayerTabKeys.TestResults}`} exact>
                <PlayerTestResultTab
                  filterConfig={[
                    ETestResultFilter.PlayerName,
                    ETestResultFilter.AgeGroup,
                    ETestResultFilter.UploaderType,
                    ETestResultFilter.BirthYear,
                    ETestResultFilter.MyAcademy
                  ]}
                />
              </Route>
            );
          case EPlayerTabKeys.Tests:
            return (
              <Route key={el?.key} path={`${path}/${EPlayerTabKeys.Tests}`} exact>
                <PlayerTestTab />
              </Route>
            );
          case EPlayerTabKeys.RFS:
            return isAvailableRfsTests ? (
              <Route key={el?.key} path={`${path}/${EPlayerTabKeys.RFS}`} exact>
                <PlayerTestTab isRFS />
              </Route>
            ) : null;
          case EPlayerTabKeys.Video:
            return (
              <Route key={el?.key} path={`${path}/${EPlayerTabKeys.Video}`} exact>
                <PlayerAboutTab isDesktop={isDesktop} />
              </Route>
            );
          case EPlayerTabKeys.Setting:
            return (
              <Route key={el?.key} path={`${path}/${EPlayerTabKeys.Setting}/:stripeTab?`} exact>
                <PlayerSettingTab isDesktop={isDesktop} />
              </Route>
            );
          case EPlayerTabKeys.Contacts:
            return (
              <Route key={el?.key} path={`${path}/${EPlayerTabKeys.Contacts}`} exact>
                <PlayerContactTab />
              </Route>
            );
          case EPlayerTabKeys.Academy:
            // TODO DONT TOUCH THIS PART OF ROUTING, ITS GOOD
            return (
              <Route key={el?.key} path={`${path}/${EPlayerTabKeys.Academy}`}>
                <Switch>
                  <Route path={`${path}/${EPlayerTabKeys.Academy}`} exact>
                    <PlayerAcademyTab />
                  </Route>
                  <Route path={`${path}/${EPlayerTabKeys.Academy}/:academyId`} exact>
                    <MentorAcademyPage />
                  </Route>
                </Switch>
              </Route>
            );

          default:
            return null;
        }
      }
    });
  }, [player, profile]);

  useEffect(() => {
    if (isUserAcademyPlayer && playerId && profile?.data?.academy?.academyId) {
      history.replace(getAcademyPlayerRoute(playerId, profile.data.academy.academyId));
    }
  }, [isUserAcademyPlayer]);

  return (
    <Row className={`${isDesktop ? 'flex-row' : 'flex-column mt-6 mobile'} players__player-page`}>
      <Col className="players__player-page__tabs">
        <Tabs className="players__tabs" tabPosition={tabPosition} onChange={onTabChange} activeKey={tabValue}>
          {tabs}
        </Tabs>
      </Col>
      <Col className="players__player-page__content">
        {tabValue !== EPlayerTabKeys.Training && (
          <Col xs={24} sm={10} md={8} className={classNames('mb-3', { 'px-5': !isLg })}>
            <PlayerProfileCardInfo onClick={() => onTabChange(EPlayerTabKeys.Training)} />
          </Col>
        )}
        <Switch>{routes}</Switch>
      </Col>
      <PrivateContent availableFor={EPermissionType.ShowDataForMentor}>
        <PlayerSubscriptionModal />
      </PrivateContent>
    </Row>
  );
};

export const PlayerTabs = PlayerTabsComponent;
