import React, { useCallback, useMemo } from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import classNames from 'classnames';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { Paper } from 'common/components/Paper/Paper';
import { Bar } from 'common/components/Bar/Bar';
import { getResultBarColor } from 'common/helpers/result-bar.helper';
import { PlayerTestResultChart } from 'entities/PlayerTest/components/PlayerTestResutChart';
import { IPlayerProfileMicroSkillModel } from 'entities/Player/Player.models';

interface IProps {
  id: string;
  tag: string;
  value: number | null;
  skills: IPlayerProfileMicroSkillModel[];
  selected?: string;
  onSkillSelectCallback?(skillId: string): void;
}

const PlayerSkillsBlockItemComponent: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const { tag, value, skills, selected, onSkillSelectCallback, id } = props;
  const isItemUnselected = useMemo(() => selected !== id, [selected, id]);

  const colorClassName = useMemo(() => (value === 0 ? 'color-text-primary' : `color-${getResultBarColor(value || 0)}`), [value]);

  const onClickCallback = useCallback(() => {
    onSkillSelectCallback && onSkillSelectCallback(id);
  }, [onSkillSelectCallback, id]);

  return (
    <Paper
      className={classNames(`player-skills-block__item ant-card-hoverable cursor-pointer width-full px-0 height-full`, {
        unselected: isItemUnselected
      })}
      onClick={onClickCallback}
    >
      <Col span={24} className="px-7">
        <Row justify="space-between" wrap={false} gutter={[8, 0]}>
          <Col className="player-skills-block__item__title" flex={1}>
            <Typography.Title ellipsis level={4} className="uppercase width-max-full">
              {t(tag)}
            </Typography.Title>
          </Col>
          <Col>
            <Typography.Title ellipsis level={4} className={colorClassName}>
              {value}
            </Typography.Title>
          </Col>
        </Row>
      </Col>
      <Col span={24} className="px-0">
        <Bar className="radius-none border-none" value={value} height={12} bgColor="transparent" />
      </Col>
      {!selected && (
        <Col span={24} className="mt-7 px-7">
          {skills?.map(skill => (
            <PlayerTestResultChart className="mb-5" key={skill.tag} data={skill} />
          ))}
        </Col>
      )}
    </Paper>
  );
};

export const PlayerSkillsBlockItem = PlayerSkillsBlockItemComponent;
