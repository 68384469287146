import { IBasePathParams } from 'common/models/requestModels';
import { EPlayerAgeGroup } from 'entities/Player/Player.models';

export interface IAcademyModel extends Components.Schemas.AcademyDto {}
export interface IAcademyCreateParams extends Components.Schemas.CreateAcademyDto {}
export interface IAcademyUpdateParams
  extends Omit<Components.Schemas.UpdateAcademyDto, 'contactPosition' | 'site' | 'contactPhone'>,
    IBasePathParams {
  contactPosition?: string | null;
  site?: string | null;
  contactPhone?: string | null;
}
export interface IAcademyUpdateTestsBalance extends Components.Schemas.UpdateBalanceDto, IBasePathParams {}

export interface IAcademyCollection extends Components.Schemas.AcademyCollectionDto {}

export interface IAcademyCollectionFilter extends Paths.AcademyControllerList.QueryParameters {}

export interface IAcademySearchCollection extends Components.Schemas.AcademySearchCollectionDto {}
export interface IAcademySearchModel extends Components.Schemas.AcademySearchDto {}

export type TAcademyParamsModel = IAcademyCreateParams | IAcademyUpdateParams | IAcademyUpdateTestsBalance | IBasePathParams;

export interface IAcademyTestCollection extends Components.Schemas.BaseResultToAcademyCollectionDto {}
export interface IAcademyPlayerToAcademyParams extends Components.Schemas.CreateAcademyRequestFromPlayerToAcademyDto {}

export interface IAcademyTestCollectionFilter
  extends Paths.BaseResultToAcademyControllerList.QueryParameters,
    Paths.BaseResultToAcademyControllerList.PathParameters {
  ageGroup: EPlayerAgeGroup;
}
export interface IAcademySearchCollectionFilter extends Paths.AcademyControllerSearch.QueryParameters {}

export interface IBaseResultToAcademyModel extends Components.Schemas.BaseResultToAcademyDto {}

export interface IAcademyTestUpdateParams
  extends Paths.BaseResultToAcademyControllerUpdateValues.QueryParameters,
    Paths.BaseResultToAcademyControllerUpdateValues.PathParameters,
    Paths.BaseResultToAcademyControllerUpdateValues.RequestBody {}

export enum EAcademySuccessMessage {
  Add = 'Check contact person email and confirm account',
  UpdateAcademy = 'Information about Academy is updated'
}

export enum EAcademyStatus {
  New = 'new',
  Moderation = 'moderation',
  Active = 'active',
  Banned = 'banned'
}

export enum EAcademyTabKeys {
  Search = 'search',
  Incoming = 'incoming',
  Favorites = 'favorites'
}

export enum EAcademyViewTabKeys {
  Selection = 'selection',
  Players = 'players',
  MyAcademies = 'my-academies',
  Trainers = 'trainers',
  Teams = 'teams',
  TestResults = 'test-results',
  TestsBalance = 'tests-balance',
  Compare = 'players-compare',
  RFS = 'rfs'
}
