import React from 'react';
import Row from 'antd/es/row';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Col from 'antd/es/col';
import Card from 'antd/lib/card';
import { getPageWithQuery } from 'common/helpers/routes.helper';
import { getUrlSearchAsObject } from 'common/helpers/filters.helper';
import { ERoutesPublic } from 'common/models/routesModel';
import { ReactComponent as PlayerIcon } from 'app/assets/icons/PlayerIcon.svg';
import { ReactComponent as AcademyIcon } from 'app/assets/icons/AcademyIcon.svg';
import { ReactComponent as CoachIcon } from 'app/assets/icons/CoachIcon.svg';
import { ReactComponent as MentorIcon } from 'app/assets/icons/MentorIcon.svg';
import { EAuthRoles } from 'entities/Auth/Auth.models';

export const WelcomePage: React.FC = () => {
  const history = useHistory();
  const searchParams = getUrlSearchAsObject();
  const { t } = useTranslation();

  const onSelectRole = (role: string) => {
    const queries = { ...searchParams, role: role };
    const path = getPageWithQuery(ERoutesPublic.Login, queries);
    history.push(path);
  };

  return (
    <Col xs={24} md={20} lg={16}>
      <span className="d-block pp-font-title-2 font-weight-5 t-align-c mb-7">{t('Select profile')}</span>
      <Row gutter={[8, 8]} align="middle" justify="center">
        <Col xs={24} md={12}>
          <Card className="p-5" bodyStyle={{ padding: 0 }} hoverable onClick={() => onSelectRole(EAuthRoles.Academy)}>
            <Row className="flex-row" align="middle">
              <AcademyIcon />
              <span className="d-block pp-font-title-4 ml-5">{t('Academy manager')}</span>
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className="p-5" bodyStyle={{ padding: 0 }} hoverable onClick={() => onSelectRole(EAuthRoles.Mentor)}>
            <Row className="flex-row" align="middle">
              <MentorIcon />
              <span className="d-block pp-font-title-4 ml-5">{t('Mentor or parent')}</span>
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className="p-5" bodyStyle={{ padding: 0 }} hoverable onClick={() => onSelectRole(EAuthRoles.Coach)}>
            <Row className="flex-row" align="middle">
              <CoachIcon />
              <span className="d-block pp-font-title-4 ml-5">{t('Trainer')}</span>
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className="p-5" bodyStyle={{ padding: 0 }} hoverable onClick={() => onSelectRole(EAuthRoles.Player)}>
            <Row className="flex-row" align="middle">
              <PlayerIcon />
              <span className="d-block pp-font-title-4 ml-5">{t('Player')}</span>
            </Row>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};
