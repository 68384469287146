import { options } from 'options';
import React from 'react';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { ERoutesPublic } from 'common/models/routesModel';
import { GoBackButton } from 'common/components/GoBackButton';

const ServiceDocumentsComponent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="service-documents-page width-full p-7">
      <GoBackButton path={ERoutesPublic.SignUp} />
      <Row justify="start" className="mb-3">
        <Typography.Title level={3} className="mb-0">
          {t('Service rules')}
        </Typography.Title>
      </Row>
      <Row justify="start" className="service-documents-page__doc">
        <Typography.Link href={options.documents.paymentMethod} target="_blank" download={`${t('Payment methods')}.pdf`}>
          {t('Payment methods')}
        </Typography.Link>
      </Row>
      <Row justify="start" className="service-documents-page__doc">
        <Typography.Link
          href={options.documents.personalDataPolicy}
          target="_blank"
          download={`${t('Personal data policy')}.pdf`}
        >
          {t('Personal data policy')}
        </Typography.Link>
      </Row>
      <Row justify="start" className="service-documents-page__doc">
        <Typography.Link href={options.documents.termsOfUse} target="_blank" download={`${t('Terms of use')}.pdf`}>
          {t('Terms of use')}
        </Typography.Link>
      </Row>
    </div>
  );
};

export const ServiceDocumentsPage = ServiceDocumentsComponent;
