import React, { SVGProps } from 'react';
import { ILinePalette } from 'common/models/lineChartModel';
import { DEFAULT_PALETTE } from 'entities/Player/components/Profile_old/Draft/LineChart/LineChart';

export interface IDotItem extends SVGProps<any> {
  cx: number;
  cy: number;
  dataKey: string;
  fill: string;
  fillOpacity: number;
  height: number;
  index: number;
  key: string;
  payload: {
    date: number;
    secondValue: number;
    updateDate: number;
    value: number;
    uploadedByPlayer: boolean;
    [key: string]: number | boolean;
  };
  r: number;
  stroke: string;
  strokeWidth: number;
  value: number[];
  width: number;
}

interface IComponentProps {
  item: IDotItem;
  isSecond?: boolean;
  palette: ILinePalette;
}

export const Dot = (props: IComponentProps) => {
  const { item, isSecond, palette } = props;
  const { value, secondValue, uploadedByPlayer } = item.payload;
  const colors = { ...DEFAULT_PALETTE, ...palette };
  let stroke = colors?.dotBorder;

  if (isSecond) {
    if (secondValue === undefined) {
      return null;
    }
    stroke = colors.second;
  } else {
    if (value === undefined) {
      return null;
    }
  }

  if (isSecond) {
    stroke = colors?.dotBorder;
  } else {
    stroke = value <= secondValue ? colors?.dotBorder : uploadedByPlayer ? colors?.alternativeDotBorder : colors?.mainDotBorder;
  }

  return (
    <g>
      <circle {...item} stroke={stroke} strokeWidth={1} r={5} fillOpacity={1} />
    </g>
  );
};
