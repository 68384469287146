import { options } from 'options';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Button from 'antd/es/button';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useMediaPredicate } from 'react-media-hook';
import MenuOutlined from '@ant-design/icons/MenuOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { scrollToTop } from 'common/helpers/dom.helper';
import { SCREEN_XS, PROMO_SCREEN } from 'common/const/config.const';
import { ERoutesPublic } from 'common/models/routesModel';
import { ModalUrls } from 'common/const/rating-page.const';
import { EGoogleTagEvents } from 'common/const/google-tag-manager.const';
import { googleTagManagerPushEvent } from 'common/helpers/google-tag-manager.help';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import Logo from 'app/assets/images/logo-monochrome.svg';
import LoginIcon from 'app/assets/icons/login.png';
import FbIcon from 'app/assets/icons/fb-rating.png';
import IgIcon from 'app/assets/icons/ig-rating.png';
import TgIcon from 'app/assets/icons/tg-rating.png';
import VkIcon from 'app/assets/icons/vk-rating.png';
import { PublicPlayerModals } from 'entities/PublicPlayer/components/Modals/PublicPlayerModals';
import { LanguageSelect } from 'entities/Language/components/LanguageSelect';

const MenuElements = [
  {
    title: 'Mentors\\Parents',
    link: ModalUrls.Parents,
    event: EGoogleTagEvents.RatingPageParentsClick
  },
  {
    title: 'To players',
    link: ModalUrls.Players,
    event: EGoogleTagEvents.RatingPagePlayersClick
  },
  {
    title: 'To academies',
    link: ModalUrls.Academies,
    event: EGoogleTagEvents.RatingPageAcademiesClick
  }
];

const onClickMenu = (event: EGoogleTagEvents) => () => {
  googleTagManagerPushEvent(event);
};
const socials = (
  <Col className="rating-header__social">
    <a href={options.socialLinks.facebook} target="_blank" rel="noopener noreferrer">
      <img alt="Facebook" width={20} height={20} src={FbIcon} />
    </a>
    <a href={options.socialLinks.instagram} target="_blank" rel="noopener noreferrer">
      <img alt="Instagram" width={20} height={20} src={IgIcon} />
    </a>
    <a href={options.socialLinks.telegram} target="_blank" rel="noopener noreferrer">
      <img alt="Telegram" width={22} height={20} src={TgIcon} />
    </a>
    <a href={options.socialLinks.vkontakte} target="_blank" rel="noopener noreferrer">
      <img alt="Vkontakte" width={24} height={15} src={VkIcon} />
    </a>
  </Col>
);

const HeaderComponent = () => {
  const location = useLocation();
  const isXs: boolean = useMediaPredicate(`(max-width: ${SCREEN_XS})`);
  const isLessThanPromoWidth: boolean = useMediaPredicate(`(max-width: ${PROMO_SCREEN})`);
  const { t } = useTranslation();
  const menuRef: { current: null | HTMLButtonElement } = useRef(null);
  const ratingPlayerPage = location?.pathname.includes(ERoutesPublic.RatingPlayer);

  const searchRaw: { [index: string]: any } = queryToObject<{ [index: string]: any }>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ageGroups, orderField, orderDirection, ...filteredSearch } = searchRaw;

  const login = (
    <Col className="rating-header__login">
      <Link to={`${ERoutesPublic.Login}${objectToQuery(filteredSearch)}`}>
        <img alt="Login icon" width={16} height={16} src={LoginIcon} />
        <Typography.Text strong>{t('Entry')}</Typography.Text>
      </Link>
    </Col>
  );

  const headerMenu = useMemo(
    () => (
      <Menu>
        {isXs && (
          <div className="rating-header__dropdown__close">
            <Button shape="circle" type="text" onClick={() => menuRef?.current && menuRef.current?.click()}>
              <Typography.Text>
                <CloseOutlined className="fs-lg" />
              </Typography.Text>
            </Button>
          </div>
        )}
        {ratingPlayerPage && (
          <Menu.Item key="rating" onClick={onClickMenu(EGoogleTagEvents.PlayerRatingPageBackToRating)}>
            <Link to={`${ERoutesPublic.Rating}${location.search}`}>
              <Typography.Text strong className="rating-header__menu__element">
                {t('Rating')}
              </Typography.Text>
            </Link>
          </Menu.Item>
        )}
        {MenuElements.map(element => (
          <Menu.Item key={element.title} onClick={onClickMenu(element.event)}>
            <Link to={{ ...location, hash: element.link }}>
              <Typography.Text strong className="rating-header__menu__element">
                {t(element.title)}
              </Typography.Text>
            </Link>
          </Menu.Item>
        ))}
        <Menu.Item key="login">{login}</Menu.Item>
        <Menu.Item key="language">
          <LanguageSelect />
        </Menu.Item>
        <Menu.Item key="socials">{socials}</Menu.Item>
      </Menu>
    ),
    [menuRef]
  );

  return (
    <header className="ant-menu basic__header width-full px-5 rating-header">
      <Row
        className="flex-noWrap height-full width-full basic__header_content rating-container"
        justify="space-around"
        align="middle"
      >
        <Col flex={1} className="rating-header__left">
          <a href={options.siteUrl} onClick={() => scrollToTop('basicLayout')}>
            <img className="basic__header_logo" src={Logo} alt="logo" />
          </a>
        </Col>
        <Col flex={1} className="rating-header__right">
          {isLessThanPromoWidth ? (
            <Dropdown overlayClassName="rating-header__dropdown" overlay={headerMenu} trigger={['click']}>
              <Button ref={menuRef} shape="circle" type="text">
                <Typography.Text>
                  <MenuOutlined className="color-text-primary fs-lg" />
                </Typography.Text>
              </Button>
            </Dropdown>
          ) : (
            <Row className="rating-header__elements">
              <Col className="rating-header__menu">
                {ratingPlayerPage && (
                  <div onClick={onClickMenu(EGoogleTagEvents.PlayerRatingPageBackToRating)}>
                    <Link to={`${ERoutesPublic.Rating}${location.search}`}>
                      <Typography.Text strong className="rating-header__menu__element">
                        {t(`Rating`)}
                      </Typography.Text>
                    </Link>
                  </div>
                )}
                {MenuElements.map(element => (
                  <div key={element.title} onClick={onClickMenu(element.event)}>
                    <Link to={{ ...location, hash: element.link }}>
                      <Typography.Text strong className="rating-header__menu__element">
                        {t(element.title)}
                      </Typography.Text>
                    </Link>
                  </div>
                ))}
              </Col>
              <Col className="rating-header__splitter" />
              {login}
              <Col className="rating-header__splitter" />
              {socials}
              <Col className="rating-header__splitter mx-6" />
              <Col span={3}>
                <LanguageSelect className="width-full" />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <PublicPlayerModals />
    </header>
  );
};

export const HeaderRating = HeaderComponent;
