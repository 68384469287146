import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCollectionPreRequestDataMapper,
  buildCollectionResponseFormatter,
  buildCommunication,
  EActionsTypes,
  getStartType,
  IAction,
  StoreBranch
} from '@axmit/redux-communications';
import { put, delay, takeLatest } from 'redux-saga/effects';
import message from 'antd/es/message';
import { translateErrors } from 'common/helpers/translate.helper';
import { ERoutesMentor } from 'common/models/routesModel';
import { EErrorStatus } from 'common/models/requestModels';
import i18n from 'common/helpers/i18n';
import { ESocketActions } from 'common/ws/SocketIo';
import { store } from 'common/helpers/axios.helper';
import { STRIPE_WAIT_EXPLANATION_KEY } from 'common/const/i18n.const';
import { multilineMessage } from 'common/components/MultilineMessage';
import { supportMessage } from 'common/components/SupportMessage';
import { subscriptionTransport } from 'entities/Subscription/Subscription.transport';
import {
  EPaymentActivityErrorCodes,
  EProcessings,
  EStripeErrors,
  ESubscriptionFailMessages,
  ESubscriptionsEngines,
  ESubscriptionStatus,
  IPaymentRequestParams,
  ISubscriptionActivityApplyGiftcard,
  ISubscriptionActivityCollection,
  ISubscriptionActivityCollectionFilter,
  ISubscriptionActivityModel,
  ISubscriptionChangeTariffParams,
  ISubscriptionCollection,
  ISubscriptionCollectionFilter,
  ISubscriptionCreate,
  ISubscriptionModel,
  ISubscriptionUpdate,
  STRIPE_ERRORS,
  TSubscriptionParamsModel
} from 'entities/Subscription/Subscription.models';
import { getMentorRequestModel, namespace as mentorRequestNamespace } from 'entities/MentorRequest/MentorRequest.communication';
import { getPlayerModel, getPlayerProfileModel, namespace as playerNamespace } from 'entities/Player/Player.communication';
import {
  closeChangeTariffModal,
  closeMentorRequestToAcademyModal,
  closePaymentRequirementsModal,
  closeStripeCancelSubscriptionModal,
  closeStripePaymentModal,
  openChangeTariffModal,
  updatePaymentRequirementsModal
} from 'entities/UI/UI.communication';
import { IChangeSubscriptionNotificationModel } from 'entities/Notification/Notification.models';

export const subscriptionNamespace = 'subscription';

export interface ISubscriptionConnectedProps {
  subscriptionModel: StoreBranch<ISubscriptionModel, TSubscriptionParamsModel>;
  subscriptionCollection: StoreBranch<ISubscriptionCollection, ISubscriptionCollectionFilter>;
  subscriptionActivityModel: StoreBranch<ISubscriptionActivityModel, ISubscriptionCollectionFilter>;
  subscriptionActivityCollection: StoreBranch<ISubscriptionActivityCollection, ISubscriptionActivityCollectionFilter>;

  addSubscriptionModel(params: ISubscriptionCreate): void;
  getSubscriptionModel(id: string): void;
  updateSubscriptionModel(params: ISubscriptionUpdate): void;
  changeTariffSubscriptionModel(params: ISubscriptionChangeTariffParams): void;

  getSubscriptionCollection(filter?: ISubscriptionCollectionFilter): Promise<void>;

  addSubscriptionActivityModel(params: IPaymentRequestParams): void;
  deleteSubscriptionActivityModel(subscriptionId: string): void;
  applyGiftcardSubscriptionActivityModel(params: ISubscriptionActivityApplyGiftcard): void;
  getSubscriptionActivityCollection(params: ISubscriptionActivityCollectionFilter): Promise<void>;

  clearSubscriptionModel(): void;
  clearSubscriptionCollection(): void;
  clearSubscriptionActivityModel(): void;
  clearSubscriptionActivityCollection(): void;
}

const updateMentorRequests = function*(response: ISubscriptionModel, fullState: any) {
  const { player } = response?.mentorRequest || {};
  const { pathname } = fullState.router.location;
  const playerId = player?.id;

  switch (true) {
    case pathname === ERoutesMentor.RequestList || pathname.includes(ERoutesMentor.PaymentAndSubscription): {
      yield put({
        type: getStartType(playerNamespace, 'collection', EActionsTypes.get),
        payload: {
          ...fullState[playerNamespace]?.['collection'].params
        }
      });
      yield put({
        type: getStartType(mentorRequestNamespace, 'collection', EActionsTypes.get),
        payload: {
          ...fullState[mentorRequestNamespace]?.collection.params
        }
      });
      break;
    }
    case pathname.includes(ERoutesMentor.Player): {
      let playerRequestId = playerId;

      if (!playerRequestId) {
        const playerState = fullState.player?.model?.data;
        const playerStateParamsId = fullState.player?.model?.params;

        if (playerState?.id) {
          playerRequestId = playerState.id;
        } else if (playerStateParamsId) {
          playerRequestId = playerStateParamsId;
        }
      }

      if (playerRequestId) {
        yield closeMentorRequestToAcademyModal();
        yield getPlayerModel(playerRequestId);
      }
    }
  }
};

const SubscriptionModelAPIProviders = [
  new APIProvider(
    EActionsTypes.get,
    subscriptionTransport.get,
    {
      preRequestDataMapper: (response, payload, branchState) => {
        return branchState.data;
      }
    },
    takeLatest
  ),
  new APIProvider(EActionsTypes.add, subscriptionTransport.add, {
    mapParams: (originalParams: ISubscriptionCreate | undefined) => {
      if (originalParams) {
        const clearedParams = { ...originalParams };

        if (clearedParams?.giftcard && clearedParams?.engine !== ESubscriptionsEngines.Stripe) {
          delete clearedParams.giftcard;
        }

        if (clearedParams?.isTest) {
          delete clearedParams.isTest;
        }

        if (clearedParams?.onFail) {
          delete clearedParams.onFail;
        }

        return clearedParams;
      }

      return originalParams;
    },
    onSuccess: function*(response, originalParams) {
      const { id: subscriptionId } = response;
      const engine = originalParams?.engine;

      if (engine === ESubscriptionsEngines.Yookassa) {
        const giftcard = originalParams?.giftcard;
        const isTest = originalParams?.isTest;
        const payload: IPaymentRequestParams = {
          subscriptionId,
          processing: EProcessings.Yookassa
        };

        if (giftcard) {
          payload.giftcard = giftcard;
        }

        if (isTest) {
          payload.isTest = isTest;
        }

        yield put({ type: getStartType(subscriptionNamespace, 'activityModel', EActionsTypes.add), payload });
      }

      if (engine === ESubscriptionsEngines.Stripe) {
        if (originalParams?.mentorRequest) {
          yield getMentorRequestModel(originalParams.mentorRequest);
        }
        yield closeStripePaymentModal();
        multilineMessage(['Tariff connection request successfully created', STRIPE_WAIT_EXPLANATION_KEY]);
      }

      originalParams?.onSuccess?.();
    },
    onFail: (error, originalParams) => {
      if (error?.data?.code === EStripeErrors.CurrencyMismatch) {
        supportMessage(EStripeErrors.CurrencyMismatch.replace(/error./, ''), 'error', 5);
      } else if (Object.values(STRIPE_ERRORS).includes(error?.data?.code)) {
        message.error(i18n.t(error?.data?.code?.replace(/error./, '')));
      }

      originalParams?.onFail?.();
    }
  }),
  new APIProvider(EActionsTypes.update, subscriptionTransport.update, {
    mapParams: originalParams => {
      if (originalParams) {
        const clearedParams = { ...(originalParams as ISubscriptionUpdate) };

        if ('onFail' in clearedParams) {
          delete clearedParams.onFail;
        }

        return clearedParams;
      }

      return originalParams;
    },
    onSuccess: function*(response, originalParams, branchState, fullState: any) {
      const payload = originalParams as ISubscriptionUpdate;

      if ('paymentMethodToken' in payload && !('status' in payload)) {
        yield closeStripePaymentModal();
        multilineMessage(['Request to change payment data successfully sent', STRIPE_WAIT_EXPLANATION_KEY]);
      } else if (originalParams?.engine === ESubscriptionsEngines.Stripe && 'status' in originalParams) {
        if (originalParams.status === ESubscriptionStatus.Canceled) {
          yield closeStripeCancelSubscriptionModal();
          multilineMessage(['Cancel subscription request successfully sent', STRIPE_WAIT_EXPLANATION_KEY]);
        }
        if (originalParams.status === ESubscriptionStatus.Active) {
          multilineMessage(['Revoke subscription request successfully sent', STRIPE_WAIT_EXPLANATION_KEY]);
          if ('paymentMethodToken' in payload) {
            yield closeStripePaymentModal();
          }
        }
      } else {
        yield updateMentorRequests(response, fullState);
      }
    },
    onFail: (error, originalParams) => {
      if (
        error?.data?.message &&
        error?.status !== EErrorStatus.PaymentRequired &&
        error?.data?.code !== EStripeErrors.SubscriptionIsBusy
      ) {
        if (error?.status === EErrorStatus.Forbidden && originalParams?.status === ESubscriptionStatus.Active) {
          message.error(translateErrors('An accepted similar request'));
        } else if (error?.data?.message) {
          message.error(translateErrors(error?.data?.message));
        }
      }

      (originalParams as ISubscriptionUpdate)?.onFail?.();
    }
  }),
  new APIProvider('changeTariff', subscriptionTransport.changeSubscriptionTariff, {
    preRequestDataMapper: (response, payload, branchState) => {
      return branchState.data;
    },
    mapParams: originalParams => {
      const clearedParams = { ...(originalParams as ISubscriptionChangeTariffParams) };

      if (clearedParams?.onFail) {
        delete clearedParams.onFail;
      }

      if (clearedParams?.isSameTariff) {
        delete clearedParams.isSameTariff;
      }

      return clearedParams;
    },
    onSuccess: function*(response, originalParams, branchState, fullState: any) {
      if (originalParams?.engine === ESubscriptionsEngines.Stripe) {
        if (originalParams?.isSameTariff) {
          multilineMessage(['A request to cancel the transition to a new tariff has been sent', STRIPE_WAIT_EXPLANATION_KEY]);
        } else {
          yield closeStripePaymentModal();
          multilineMessage(['Tariff change request successfully sent', STRIPE_WAIT_EXPLANATION_KEY]);
        }
      } else {
        yield closeChangeTariffModal();
        yield updateMentorRequests(response, fullState);
      }
    },
    onFail: (response, originalParams) => {
      if (response?.data?.code === EStripeErrors.CurrencyMismatch) {
        supportMessage(EStripeErrors.CurrencyMismatch.replace(/error./, ''), 'error', 5);
      } else if (
        response?.data?.message &&
        !response?.data?.errors?.['.coupon'] &&
        response?.status !== EErrorStatus.PaymentRequired &&
        response?.data?.code !== EStripeErrors.SubscriptionIsBusy
      ) {
        message.error(i18n.t(response?.data?.message));
      }

      originalParams?.onFail?.();
    }
  })
];

const SubscriptionActivityModelAPIProviders = [
  new APIProvider(EActionsTypes.add, subscriptionTransport.createSubscriptionActivity, {
    // eslint-disable-next-line require-yield
    onSuccess: function*(response) {
      const { confirmationUrl } = response;

      if (confirmationUrl) {
        location.replace(confirmationUrl);
      }
    },
    onFail: function*(response, originalParams, branchState, fullState: any) {
      yield updateMentorRequests(response, fullState);

      if (response.status !== EErrorStatus.Forbidden && response?.data?.code !== EStripeErrors.SubscriptionIsBusy) {
        message.error(translateErrors(ESubscriptionFailMessages.ErrorProcessingPayment));
      }

      const subscriptionId = originalParams?.subscriptionId;
      const processing = originalParams?.processing;

      if (subscriptionId && (!originalParams?.isTest || originalParams?.giftcard)) {
        yield updatePaymentRequirementsModal({
          subscriptionId,
          processing,
          mentorRequestId: undefined
        });
      }

      if (
        response?.data?.code === EPaymentActivityErrorCodes.YookassaExternal &&
        originalParams?.tariffId &&
        originalParams?.subscriptionId
      ) {
        yield closePaymentRequirementsModal();
        yield openChangeTariffModal({
          subscriptionId: originalParams.subscriptionId,
          tariffId: originalParams.tariffId
        });
      }
    }
  }),
  new APIProvider('applyGiftcard', subscriptionTransport.applyGiftcardSubscriptionActivity, {
    onSuccess: function*(response, originalParams, branchState, fullState: any) {
      if (originalParams?.engine === ESubscriptionsEngines.Yookassa) {
        yield updateMentorRequests(response, fullState);
        yield closePaymentRequirementsModal();
      }

      originalParams?.onSuccess?.();
    },
    onFail: function*(response, originalParams, branchState, fullState: any) {
      if (originalParams?.engine === ESubscriptionsEngines.Yookassa) {
        yield updateMentorRequests(response, fullState);
      }

      if (response?.data?.code !== EStripeErrors.SubscriptionIsBusy) {
        message.error(translateErrors(ESubscriptionFailMessages.ErrorApplyGiftcard));
      }
    }
  }),
  new APIProvider(EActionsTypes.delete, subscriptionTransport.deleteSubscriptionActivity, {
    onSuccess: function*(response, originalParams, branchState, fullState: any) {
      yield updateMentorRequests(response, fullState);
    }
  })
];

const SubscriptionCollectionAPIProviders = [new APIProvider(EActionsTypes.get, subscriptionTransport.getCollection)];
const SubscriptionActivityCollectionAPIProviders = [
  new APIProvider(EActionsTypes.get, subscriptionTransport.getSubscriptionActivityCollection, {
    mapSuccess: buildCollectionResponseFormatter<any, any>(),
    preRequestDataMapper: buildCollectionPreRequestDataMapper<
      ISubscriptionActivityCollection,
      ISubscriptionActivityCollectionFilter
    >()
  })
];

const branches = [
  new Branch('model', SubscriptionModelAPIProviders),
  new Branch('collection', SubscriptionCollectionAPIProviders),
  new Branch('activityModel', SubscriptionActivityModelAPIProviders),
  new Branch('activityCollection', SubscriptionActivityCollectionAPIProviders)
];

const strategy = new BaseStrategy({
  namespace: subscriptionNamespace,
  branches
});

function* getSubscriptionModel(id: string) {
  yield put({ type: getStartType(subscriptionNamespace, 'model', EActionsTypes.get), payload: id });
}

function* getSubscriptionActivityCollection(params: ISubscriptionActivityCollectionFilter) {
  yield put({
    type: getStartType(subscriptionNamespace, 'activityCollection', EActionsTypes.get),
    payload: params
  });
}

function* onSubscriptionChange() {
  yield takeLatest(ESocketActions.SubscriptionChange, function*({
    payload: data
  }: IAction<IChangeSubscriptionNotificationModel>) {
    yield delay(5000);
    const state = store.getState();
    const currentSubscriptionId = state[subscriptionNamespace].model.data?.id;
    const currentMentorRequestId = state[mentorRequestNamespace].model.data?.id;

    if (data?.subscription?.id && currentSubscriptionId === data.subscription.id) {
      const subscriptionActivityParams: ISubscriptionActivityCollectionFilter =
        state[subscriptionNamespace].activityCollection.params;
      const currentPlayerId = state[playerNamespace].profile.params?.id;

      yield getSubscriptionModel(data.subscription.id);
      if (subscriptionActivityParams?.subscription === data.subscription.id) {
        yield getSubscriptionActivityCollection({ ...subscriptionActivityParams, subscription: data.subscription.id });
      }
      if (currentPlayerId) {
        yield getPlayerProfileModel({ playerId: currentPlayerId });
      }
    }
    if (data?.subscription?.mentorRequest?.id && currentMentorRequestId === data.subscription.mentorRequest.id) {
      yield getMentorRequestModel(data.subscription.mentorRequest.id);
    }
  });
}

export const communicationSubscription = buildCommunication<ISubscriptionConnectedProps>(strategy);

communicationSubscription.sagas.push(onSubscriptionChange());
