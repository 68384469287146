import React, { useCallback, useState, useMemo } from 'react';
import Typography from 'antd/es/typography';
import Tabs from 'antd/es/tabs';
import Upload from 'antd/es/upload';
import Input from 'antd/es/input';
import Spin from 'antd/es/spin';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import message from 'antd/es/message';
import { useTranslation } from 'react-i18next';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { RcFile } from 'antd/es/upload/interface';
import { EErrorStatus } from 'common/models/requestModels';
import { getYouTubeData, checkVideo } from 'common/helpers/loader.helper';
import { uploadVideo } from 'entities/Video/Video.transport';
import { IAddPlayerVideoParams } from 'entities/Player/Player.models';
import { maxVideoTitleLength } from 'entities/Video/Video.models';

const { Dragger } = Upload;

enum EUploadType {
  fromFile = 'fromFile',
  fromYoutube = 'fromYoutube'
}

interface IComponentProps {
  onCancel: () => void;
  setUploadData: (data: Partial<IAddPlayerVideoParams>) => void;
  isDesktop: boolean;
}

interface IVideoFileData {
  title: string;
}

type AllProps = IComponentProps;

const PlayerVideosModalBodyUploadComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { onCancel, setUploadData, isDesktop } = props;
  const [activeTab, setActiveTab] = useState<EUploadType>(EUploadType.fromFile);
  const [youtubeLink, setYoutubeLink] = useState<string>('');
  const [videoFile, setVideoFile] = useState<RcFile>();
  const [loading, setLoading] = useState<boolean>(false);
  const [videoFileData, setVideoFileData] = useState<IVideoFileData>({ title: '' });

  const onChangeTab = useCallback(newActiveTab => {
    setActiveTab(newActiveTab);
  }, []);

  const isUploadButtonDisabled = useMemo(() => {
    if (activeTab === EUploadType.fromFile) {
      return !videoFile;
    } else {
      return !youtubeLink;
    }
  }, [videoFile, youtubeLink, activeTab]);

  const onBeforeUpload = useCallback(async (file: RcFile): Promise<void> => {
    setLoading(true);
    const isVideoOk = await checkVideo(file);
    if (isVideoOk) {
      setVideoFile(file);
      const videoTitle = file.name || '';
      setVideoFileData({ title: videoTitle.substr(0, maxVideoTitleLength) });
    }
    setLoading(false);
    return Promise.reject();
  }, []);

  const onRemoveFile = useCallback(() => {
    setVideoFile(undefined);
    setVideoFileData({ title: '' });
  }, [videoFile]);

  const onChangeYouTubeLink = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setYoutubeLink(e?.target?.value);
  }, []);

  const onUpload = useCallback(async () => {
    setLoading(true);
    let videoData: Partial<IAddPlayerVideoParams> = { title: '' };

    if (activeTab === EUploadType.fromYoutube) {
      const data = await getYouTubeData(youtubeLink);
      if (typeof data === 'object') {
        videoData = { ...videoData, ...data };
      }
    } else if (videoFile) {
      try {
        const { id } = await uploadVideo(videoFile, videoFileData.title);
        videoData = { ...videoData, ...videoFileData, video: id };
      } catch (e) {
        switch (e.status) {
          case EErrorStatus.PayloadTooLarge: {
            message.error(t('Size should be less than 100MB'));
            break;
          }
          case EErrorStatus.UnsupportedMediaType: {
            message.error(t('Only video files'));
            break;
          }

          default: {
            message.error(t('Error while uploading video'));
            break;
          }
        }
      }
    }

    setLoading(false);

    if (videoData?.title) {
      setUploadData(videoData);
    }
  }, [activeTab, youtubeLink, videoFileData, videoFile]);

  return (
    <div className="players__videos-tab__modal__body width-full">
      <Spin spinning={loading} size="large">
        <Tabs activeKey={activeTab} onChange={onChangeTab}>
          <Tabs.TabPane tab={t('From file')} key={EUploadType.fromFile}>
            {videoFileData.title ? (
              <Row justify="space-between">
                <Typography.Text>{videoFileData.title}</Typography.Text>
                <DeleteOutlined className="cursor-pointer" onClick={onRemoveFile} />
              </Row>
            ) : (
              <Dragger
                multiple={false}
                disabled={loading}
                beforeUpload={onBeforeUpload}
                showUploadList={false}
                accept="video/mp4,video/x-m4v,video/*"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <Typography.Title level={5} className="mb-0">
                  {t('Click or drag file to this area to upload')}
                </Typography.Title>
              </Dragger>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('From YouTube')} key={EUploadType.fromYoutube}>
            <Typography.Text>{t('Link on video from YouTube')}</Typography.Text>
            <Input
              autoFocus
              size="large"
              onChange={onChangeYouTubeLink}
              disabled={loading}
              className="mt-3"
              placeholder="https://www.youtube.com/watch?v=ymsswuIoXz8"
            />
          </Tabs.TabPane>
        </Tabs>
        <Row justify="end" className="mt-7">
          <Button block={!isDesktop} className={isDesktop ? 'mr-5' : 'mb-3'} onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button block={!isDesktop} type="primary" onClick={onUpload} disabled={isUploadButtonDisabled}>
            {t('Upload')}
          </Button>
        </Row>
      </Spin>
    </div>
  );
};

export const PlayerVideosModalBodyUpload = PlayerVideosModalBodyUploadComponent;
