import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Form from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import MaskedInput from 'antd-mask-input';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import { decodeGiftcardNumber, IDecodeGiftcardInterface } from 'common/helpers/giftcard.helper';
import { getErrorMessageByCode } from 'common/helpers/translate.helper';
import { validateGiftcard } from 'common/helpers/filed-rules';
import { ESubscriptionsEngines } from 'entities/Subscription/Subscription.models';
import { communicationSubscription, ISubscriptionConnectedProps } from 'entities/Subscription/Subscription.communication';

const giftcardFieldName = 'giftcard';
const giftcardFormName = 'giftcardForm';
const INITIAL_STATE = { isGiftcardOk: true };

export interface IGiftcardChangeEvent extends IDecodeGiftcardInterface {
  number?: string;
}

interface IProps {
  errors?: any;
  loading?: boolean;
  subscriptionId?: string;
  showApplyButton?: boolean;
  engine?: ESubscriptionsEngines;
  mentorRequest?: string;
  onGiftcardChanged?(giftcard: IGiftcardChangeEvent): void;
  size?: 'small' | 'middle' | 'large';
}

type AllProps = IProps & ISubscriptionConnectedProps;

const getValueFromMask = (value?: string) => value?.replace(/ /g, '');

const Component: React.FC<AllProps> = props => {
  const {
    errors,
    loading,
    subscriptionId,
    applyGiftcardSubscriptionActivityModel,
    addSubscriptionModel,
    showApplyButton,
    onGiftcardChanged,
    size = 'middle',
    mentorRequest,
    engine
  } = props;
  const { t, i18n } = useTranslation(['web-subscription-button', 'common-fields-rules']);
  const [giftcardForm] = Form.useForm();
  const [giftcardMessage, setGiftcardMessage] = useState<string>('');
  const [giftcard, setGiftcard] = useState<IDecodeGiftcardInterface>(INITIAL_STATE);

  useEffect(() => {
    const errorMessage: any = errors;
    if (errorMessage && errorMessage?.data?.errors?.['.giftcard']?.length) {
      const [errorData] = errorMessage?.data?.errors?.['.giftcard'];
      if (errorData?.message) {
        setGiftcardMessage(
          i18n.exists(errorData.message)
            ? t(errorData.message)
            : (getErrorMessageByCode(errorData.code, { _property: t('giftcard') }) as string)
        );
        setGiftcard({ isGiftcardOk: false });
        giftcardForm.setFields([{ name: giftcardFieldName, errors: [errorData.message] }]);
      }
    }
  }, [errors]);

  const clearGiftcardState = useCallback(() => {
    setGiftcardMessage('');
    setGiftcard(INITIAL_STATE);
  }, [giftcardForm, setGiftcardMessage, setGiftcard]);

  const sendChangeEvent = useCallback(() => {
    const giftcardValue = giftcardForm.getFieldValue(giftcardFieldName);
    const number = getValueFromMask(giftcardValue);

    if (engine === ESubscriptionsEngines.Stripe && !giftcardValue) {
      clearGiftcardState();
    }

    onGiftcardChanged?.({ ...giftcard, number });
  }, [giftcard, engine, clearGiftcardState]);

  useEffect(() => {
    sendChangeEvent();
  }, [giftcard]);

  const giftcardBtnDisabled = useMemo(() => loading || !giftcard.isGiftcardOk || (!subscriptionId && !mentorRequest), [
    loading,
    giftcard,
    subscriptionId,
    mentorRequest
  ]);

  const getGiftcardInfo = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const number = getValueFromMask(e?.target?.value);

      if (number) {
        const giftcardValue = decodeGiftcardNumber(number);
        setGiftcard(giftcardValue);
        if (giftcardValue.isGiftcardOk) {
          if (giftcardValue?.period) {
            setGiftcardMessage(t('promocode-amount', { period: giftcardValue?.period }));
          } else {
            setGiftcardMessage('');
          }
        } else {
          setGiftcardMessage(t('common-fields-rules:Wrong giftcard'));
        }
      }
    },
    [setGiftcardMessage]
  );

  const giftcardIcon = useMemo(() => {
    const { isGiftcardOk } = giftcard;
    const number = giftcardForm.getFieldValue(giftcardFieldName);

    if (!number) {
      return <span />;
    }

    return isGiftcardOk ? (
      <CheckCircleFilled style={{ color: '#52c41a' }} />
    ) : (
      <ExclamationCircleFilled style={{ color: '#fa666e' }} />
    );
  }, [giftcard]);

  const onResetForm = useCallback(() => {
    giftcardForm.resetFields();
    clearGiftcardState();
  }, [giftcardForm, clearGiftcardState]);

  const onApplyGiftcard = useCallback(() => {
    const giftcardValue = giftcardForm.getFieldValue(giftcardFieldName);
    const number = getValueFromMask(giftcardValue);

    if (engine && number && giftcard.isGiftcardOk) {
      if (subscriptionId) {
        applyGiftcardSubscriptionActivityModel({
          subscriptionId,
          giftcard: number,
          engine,
          onSuccess: onResetForm
        });
      } else if (mentorRequest) {
        addSubscriptionModel({
          giftcard: number,
          engine,
          mentorRequest,
          onSuccess: onResetForm
        });
      }
    }
  }, [applyGiftcardSubscriptionActivityModel, giftcard, subscriptionId, mentorRequest, engine, onResetForm]);

  return (
    <Row gutter={[8, 0]} className="width-full">
      <Col span={!showApplyButton ? 24 : 15}>
        <Form form={giftcardForm} name={giftcardFormName} onFinish={onApplyGiftcard} onValuesChange={sendChangeEvent}>
          <Form.Item name={giftcardFieldName} className="mb-0" help={giftcardMessage} rules={[validateGiftcard]}>
            <MaskedInput
              mask="wwwwww11 11111111"
              placeholderChar=" "
              disabled={loading}
              formatCharacters={{
                w: {
                  validate: (char: string) => /[A-Za-z0-9]/.test(char)
                }
              }}
              size={size}
              suffix={giftcardIcon}
              placeholder={t('Giftcard, if any')}
              onChange={getGiftcardInfo}
            />
          </Form.Item>
        </Form>
      </Col>
      {showApplyButton && (
        <Col span={9}>
          <Button
            className="min-width-content"
            size={size}
            form={giftcardFormName}
            htmlType="submit"
            block
            disabled={giftcardBtnDisabled}
          >
            {t('Apply')}
          </Button>
        </Col>
      )}
    </Row>
  );
};

export const GiftcardForm = communicationSubscription.injector(Component);
