import Divider from 'antd/es/divider';
import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import Card from 'antd/es/card';
import CameraOutlined from '@ant-design/icons/CameraOutlined';
import { SkillBarChart } from 'common/components/SkillBarChart/SkillBarChart';
import { nameBuilder } from 'common/helpers/name.helper';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { locationFormatter } from 'common/helpers/location.helper';
import { TeamsBadge } from 'common/components/TeamsBadge';
import { EPrivatePlayerActionTypes, TPrivatePlayerActionTypes } from 'entities/PrivatePlayer/PrivatePlayer.models';
import { UnlinkActionButton } from 'entities/PrivatePlayer/components/ActionButtons/UnlinkActionButton';
import { UnlinkFromTeamActionButton } from 'entities/PrivatePlayer/components/ActionButtons/UnlinkFromTeamActionButton';
import { IPlayerModel } from 'entities/Player/Player.models';
import { MentorRequestButton } from 'entities/MentorRequest/components/MentorRequestButton';
import { EMentorRequestStatus } from 'entities/MentorRequest/MentorRequest.models';
import { ShareButton } from 'entities/Share/components/SharePlayerButton';
import { PlayerCompareAction } from 'entities/Player/components/ActionButtons/PlayerCompareAction';
import { PlayerFavorite } from 'entities/Player/components/ActionButtons/PlayerFavorite';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EAcademyRequestSuccessUpdateModels } from 'entities/AcademyRequest/AcademyRequest.models';

interface IComponentProps {
  player: IPlayerModel;
  previewAvatar?: boolean;
  actionType?: TPrivatePlayerActionTypes;
  trainerId?: string;
  teamId?: string;
  fromAcademy: boolean;
}

type AllProps = IComponentProps & IAuthConnectedProps;

const avatarSize = 48;
const defaultAvatarStyle = { width: avatarSize, height: avatarSize, fontSize: avatarSize / 2 };

const PlayerCardComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { player, actionType, trainerId, teamId, fromAcademy, authUser } = props;
  const {
    ageGroup,
    firstName,
    lastName,
    address,
    image,
    id,
    birthday,
    mentorRequest,
    rating,
    playerSkills = [],
    academyLink,
    academyToPlayerRequest
  } = player ?? {};

  const playerName = nameBuilder(firstName, lastName);
  const fullAddress = locationFormatter(address) ?? t('Unknown address');
  const isWithMentorRequest = mentorRequest?.status === EMentorRequestStatus.New;
  const isHasMentor = player?.mentorRequest?.status === EMentorRequestStatus.Accepted && !player?.mentorRequest?.isVirtual;
  const playerAcademy = academyLink?.academy;

  const isAcademyPlayer = useMemo(() => playerAcademy?.id === authUser.data?.academyWorker?.academy.id, [
    playerAcademy,
    authUser
  ]);

  const isWithoutMentor =
    !mentorRequest?.status ||
    (mentorRequest?.status !== EMentorRequestStatus.New && mentorRequest?.status !== EMentorRequestStatus.Accepted) ||
    mentorRequest.isVirtual;

  const ActionButton = useMemo(() => {
    if (!actionType) {
      return null;
    }

    const types = Array.isArray(actionType) ? actionType : [actionType];

    if (!types.includes(EPrivatePlayerActionTypes.Compare)) {
      types.unshift(EPrivatePlayerActionTypes.Compare);
    }

    return types.map(type => {
      let Element;

      switch (type) {
        case EPrivatePlayerActionTypes.UnlinkFromTeam:
          Element = (
            <UnlinkFromTeamActionButton
              key={type}
              size="small"
              block={false}
              className="private-player-card_button"
              type="default"
              teamId={teamId}
              playerId={id}
            />
          );
          break;
        case EPrivatePlayerActionTypes.Unlink:
          Element = (
            <UnlinkActionButton
              key={type}
              size="small"
              type="default"
              block={false}
              className="private-player-card_button"
              trainerId={trainerId}
              playerId={id}
            />
          );
          break;
        case EPrivatePlayerActionTypes.Share:
          Element = (
            <ShareButton
              key={type}
              size="small"
              type="default"
              block={false}
              className="private-player-card_button"
              playerId={player?.id}
              playerAcademyId={playerAcademy?.id}
              disabled={player?.archived}
              hasMentor={isHasMentor}
              fromList
              publicity={player?.juniCoachPublicity}
            />
          );
          break;
        case EPrivatePlayerActionTypes.Compare:
          Element = <PlayerCompareAction key={type} playerId={id} size="small" />;
          break;
        case EPrivatePlayerActionTypes.Favorite:
          Element = (
            <PlayerFavorite
              size="small"
              showIcon
              optimisticUpdate
              type="default"
              modelsToUpdate={[EAcademyRequestSuccessUpdateModels.Players]}
              id={player.id}
              academyToPlayerRequest={academyToPlayerRequest}
              isAcademyPlayer={isAcademyPlayer}
            />
          );
          break;
        case EPrivatePlayerActionTypes.InviteMentor:
          if (isWithMentorRequest || isWithoutMentor) {
            Element = (
              <MentorRequestButton
                key={type}
                size="small"
                type="default"
                block={false}
                className="private-player-card_button"
                playerId={id}
                sendAgain={isWithMentorRequest}
                inviteFromList
              />
            );
          } else {
            Element = null;
          }

          break;
        // case EPrivatePlayerActionTypes.Archive:
        //   return <div>Archive</div>;
        default:
          Element = null;
      }

      if (!Element) {
        return Element;
      }

      return (
        <Col className="height-fit" key={type}>
          {Element}
        </Col>
      );
    });
  }, [mentorRequest?.status, academyToPlayerRequest]);

  return (
    <Card hoverable className="p-5 private-player-card bg-component border-none radius-default">
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Row gutter={[16, 8]} wrap={false}>
            <Col>
              <Row gutter={[4, 4]} className="width-content">
                <Col>
                  <Row wrap={false} gutter={[4, 4]}>
                    <Col>
                      <Typography.Text className="d-block private-player-card_rating bg-primary height-full height color-black px-3 radius-round fs-xs t-align-c">
                        {rating ?? '-'}
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="bg-layout private-player-card_age-group color-black px-3 radius-round fs-xs t-align-c d-block">
                        {ageGroup}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Typography.Text className="bg-layout color-black private-player-card_year px-3 radius-round fs-xs t-align-c d-block">
                    {moment(birthday).year()}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col className="private-player-card__avatar-block">
              {image?.id ? (
                <ImagePrivate
                  alt={t('Player avatar')}
                  width={avatarSize}
                  height={avatarSize}
                  className="border-circle"
                  imgId={image.id}
                />
              ) : (
                <Row justify="center" align="middle" className="bg-component-third radius-round" style={defaultAvatarStyle}>
                  <CameraOutlined />
                </Row>
              )}
              {!fromAcademy && playerAcademy?.image?.id && (
                <ImagePrivate
                  alt={t('Academy avatar')}
                  width={24}
                  height={24}
                  className="border-circle private-player-card__avatar-block__academy"
                  imgId={playerAcademy.image.id}
                />
              )}
            </Col>
            <Col flex={1} className="overflow-hidden d-flex flex-align-center">
              <Row gutter={[4, 0]} align="middle">
                <Col xs={24} className="d-grid">
                  <Typography.Text className="fs-xs font-weight-5" ellipsis>
                    {playerName}
                  </Typography.Text>
                </Col>
                <Col xs={24} className="d-grid">
                  <Typography.Text className="fs-xxxs color-black-40" ellipsis>
                    {fullAddress}
                  </Typography.Text>
                </Col>
                {!fromAcademy && playerAcademy?.name && (
                  <Col xs={24} className="d-grid">
                    <Typography.Text className="fs-xxxs color-black-40" ellipsis>
                      {playerAcademy.name}
                    </Typography.Text>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        {fromAcademy && (
          <Col
            xs={24}
            className={classNames('private-player-card_teams', {
              'private-player-card_teams-divider': !player?.teamLinks?.length
            })}
          >
            {player?.teamLinks?.length ? (
              <Row>
                <TeamsBadge teamLinkBase={player?.teamLinks} />
              </Row>
            ) : (
              <Divider>{t('Without team')}</Divider>
            )}
          </Col>
        )}
        <Col xs={24}>
          <Row gutter={[16, 16]}>
            {playerSkills.map(playerSkill => (
              <Col flex={1} key={playerSkill.skill.id}>
                <SkillBarChart skill={playerSkill.skill} value={playerSkill.value} />
              </Col>
            ))}
          </Row>
        </Col>
        <Col xs={24}>
          <Row gutter={[8, 8]}>{ActionButton}</Row>
        </Col>
      </Row>
    </Card>
  );
};

export const PrivatePlayerCard = communicationAuth.injector(PlayerCardComponent);
