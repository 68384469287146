import React, { useCallback, useEffect, useState } from 'react';
import Avatar from 'antd/es/avatar';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import message from 'antd/es/message';
import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';
import Upload from 'antd/es/upload';
import CameraOutlined from '@ant-design/icons/CameraOutlined';
import { useTranslation } from 'react-i18next';
import { RcFile } from 'antd/es/upload/interface';
import { useMediaPredicate } from 'react-media-hook';
import { AvatarEditorBody } from 'common/components/AvatarEditor/AvatarEditorBody';
import { checkImage } from 'common/helpers/loader.helper';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { uploadImage } from 'entities/Image/Image.transport';
import { getImageUrl } from 'entities/Image/Image.helper';
import { EImageSize } from 'entities/Image/Image.models';

const modalWidth = 700;
const avatarSize = 88;

export interface ICrop {
  aspect: number;
  height: number;
  unit?: string;
  width: number;
  x: number;
  y: number;
}

export interface IProps {
  onChange: (imageId: string | null) => void;
  avatarId: string | null;
}

const cropOptions = {
  aspect: 1,
  width: 100,
  height: 100,
  x: 0,
  y: 0
};

export const AvatarEditor: React.FC<IProps> = ({ onChange, avatarId }) => {
  const { t } = useTranslation();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const [isEditorVisible, setEditorVisible] = useState<boolean>(false);
  const [src, setSrc] = useState<string | ArrayBuffer | null>();
  const [fileName, setFilename] = useState<string>('');
  const [image, setImage] = useState<HTMLImageElement | undefined>(undefined);
  const [crop, setCrop] = useState<ICrop>(cropOptions);
  const [loading, setLoading] = useState<boolean>(false);
  const [avatarSrc, setAvatarSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    setAvatarSrc(getImageUrl(avatarId, EImageSize.X96));
  }, [avatarId]);

  const openEditor = useCallback(() => {
    setEditorVisible(true);
  }, []);

  const closeEditor = useCallback(() => {
    setEditorVisible(false);
    setSrc(null);
    setImage(undefined);
  }, []);

  const handleUpload = useCallback((file: RcFile): boolean => {
    if (checkImage(file)) {
      setFilename(file.name);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setSrc(reader.result);
        openEditor();
      });
      reader.readAsDataURL(file);
    }
    return false;
  }, []);

  const menu = React.useMemo(
    () => (
      <Menu>
        <Menu.Item key="change">
          <Upload beforeUpload={handleUpload} accept="image/x-png,image/jpeg" showUploadList={false} multiple={false}>
            {t('Change photo')}
          </Upload>
        </Menu.Item>
        <Menu.Item
          key="delete"
          onClick={() => {
            onChange(null);
          }}
        >
          {t('Delete photo')}
        </Menu.Item>
      </Menu>
    ),
    [t]
  );
  const onSave = useCallback(() => {
    if (image) {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width * scaleX;
      canvas.height = crop.height * scaleY;
      const ctx = canvas.getContext('2d');

      if (ctx && crop) {
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );

        canvas.toBlob(blob => {
          if (!blob) {
            message.error(t('Error while uploading image'));
            return;
          }
          setLoading(true);
          uploadImage(blob, fileName)
            .then(res => {
              const { id } = res;
              onChange(id);
              closeEditor();
            })
            .catch(() => {
              message.error(t('Error while uploading image'));
            })
            .finally(() => {
              setLoading(false);
            });
        }, 'image/jpeg');
      }
    }
  }, [crop, image]);

  return (
    <>
      <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
        <div className="avatar-editor__avatar-container">
          <Avatar size={avatarSize} className="avatar-editor__avatar" icon={<CameraOutlined />} src={avatarSrc} />
          <div className="avatar-editor__avatar-overlay">{/* <CameraIcon />*/}</div>
        </div>
      </Dropdown>
      <Modal
        title={t('Edit avatar profile')}
        className={`avatar-editor__modal ${isDesktop ? '' : 'modal-mobile'}`}
        destroyOnClose
        centered
        maskClosable={false}
        width={modalWidth}
        visible={isEditorVisible}
        onCancel={closeEditor}
        okText={t('Select')}
        cancelText={t('Close')}
        onOk={onSave}
        okButtonProps={{ disabled: loading }}
      >
        <Spin spinning={loading} size="large">
          <AvatarEditorBody src={src} crop={crop} setCrop={setCrop} setImage={setImage} />
        </Spin>
      </Modal>
    </>
  );
};
