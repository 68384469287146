import React, { useEffect, useMemo, useState } from 'react';
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import DatePicker from 'antd/es/date-picker';
import Button from 'antd/es/button';
import { useFormMapper } from '@axmit/antd4-helpers';
import moment from 'moment';
import { useMediaPredicate } from 'react-media-hook';
import { useTranslation } from 'react-i18next';
import { AvatarEditor } from 'common/components/AvatarEditor/AvatarEditor';
import { externalIdRules, phoneRule, requiredField } from 'common/helpers/filed-rules';
import { GenderSelect } from 'common/components/GenderSelect';
import { clientDateFormat, serverDateFormat } from 'common/models/dateModels';
import { Phone } from 'common/components/Phone/Phone';
import { disabledFutureDates, getUTCStartOfDayFromString } from 'common/helpers/date.helper';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { isAcademyFranchisor, useAcademiesFilterWithFranchisor } from 'common/helpers/franchise.helper';
import { ITrainerUpdateModelForm } from 'entities/Trainer/Trainer.models';
import { EUserGender } from 'entities/User/User.models';
import { communicationTrainer, ITrainerConnectedProps } from 'entities/Trainer/Trainer.communication';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { TeamSelector } from 'entities/Team/components/TeamSelector';
import { ITeamModel } from 'entities/Team/Team.models';

interface IProps {}

interface IFormTrainerUpdateInfoValues {
  firstName: string;
  lastName: string;
  gender: EUserGender;
  birthday: string;
  phone: string;
  externalId?: string;
  teams: ITeamModel[] | undefined;
}

type AllProps = IProps & ITrainerConnectedProps & IUIConnectedProps & IAuthConnectedProps;

const TrainerFormComponent: React.FC<AllProps> = props => {
  const { updateTrainerModel, trainerModel, saveUIAcademyLastTeamModel, UIAcademyLastTeamModel, authAcademy } = props;
  const { t } = useTranslation();
  const [avatarId, setAvatarId] = useState<string | null>(null);
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const { data: trainer, loading: trainerLoading, params: trainerParams, errors: trainerErrors } = trainerModel;
  const lastSelectedTeam = UIAcademyLastTeamModel.data;
  const academyId = trainer?.trainerRequest?.academy?.id;
  const academiesWithFranchisor = useAcademiesFilterWithFranchisor(academyId, authAcademy);
  const isAuthAcademyFranchisor = isAcademyFranchisor(authAcademy?.data);
  const isAvailableRfsTests = authAcademy?.data?.isAvailableRfsTests;
  const webhookUrl = authAcademy?.data?.franchiseRequest?.franchise?.webhookUrl;
  const showExternalId = isAuthAcademyFranchisor && webhookUrl && isAvailableRfsTests;

  useEffect(() => {
    if (trainer?.image) {
      setAvatarId(trainer.image.id);
    }
  }, [trainer]);

  const data = useMemo(() => {
    const defaultTeam = academyId && lastSelectedTeam?.academyId === academyId ? [lastSelectedTeam?.lastTeam] : undefined;

    if (trainer) {
      const { birthday, phone, teamLinks, ...rest } = trainer;

      return {
        ...rest,
        phone: phone || undefined,
        birthday: birthday && moment(birthday, serverDateFormat),
        teams: teamLinks?.map(link => link.team)
      };
    }

    return {
      teams: defaultTeam
    };
  }, [trainer, academyId]);
  const { fields } = useFormMapper(
    ['firstName', 'lastName', 'gender', 'birthday', 'phone', 'teams', 'email', 'externalId'],
    data,
    trainerParams,
    trainerErrors
  );

  const onFinishUserInfoUpdate = (values: IFormTrainerUpdateInfoValues) => {
    if (trainer?.id) {
      const { firstName, lastName, externalId, gender, birthday, phone, teams } = values;
      const body: ITrainerUpdateModelForm = {
        firstName,
        lastName,
        gender,
        phone: phone || null,
        externalId: externalId || null,
        id: trainer.id,
        image: avatarId,
        birthday: null,
        teams
      };

      if (birthday) {
        body.birthday = getUTCStartOfDayFromString(birthday);
      }

      if (teams?.length) {
        const tempTeams = [...teams];
        const lastTeam = tempTeams.pop();

        if (lastTeam && academyId) {
          saveUIAcademyLastTeamModel({ lastTeam, academyId });
        }
      }

      updateTrainerModel(body);
    }
  };

  return (
    <Form
      onFinish={onFinishUserInfoUpdate}
      fields={fields}
      name="update-trainer-info"
      layout="vertical"
      className="trainer-academy-page__block width-max-full width-full p-5"
    >
      {isDesktop ? (
        <Row>
          <Col className="mr-5">
            <AvatarEditor onChange={setAvatarId} avatarId={avatarId} />
          </Col>
          <Col className="trainer-academy-page__block__data">
            <Row className="mb-5">
              <Form.Item rules={[requiredField]} name="firstName" label={t('First name')} className="mr-5">
                <Input disabled={trainerLoading} size="large" />
              </Form.Item>
              <Form.Item rules={[requiredField]} name="lastName" label={t('Last name')}>
                <Input disabled={trainerLoading} size="large" />
              </Form.Item>
            </Row>
            <Row className="mb-5">
              <Form.Item rules={[requiredField]} name="gender" label={t('Gender')} className="mr-5">
                <GenderSelect disabled={trainerLoading} size="large" />
              </Form.Item>
              <Form.Item name="birthday" label={t('Birthday')}>
                <DatePicker
                  disabledDate={disabledFutureDates}
                  disabled={trainerLoading}
                  className="width-full"
                  size="large"
                  format={clientDateFormat}
                  placeholder={t('DD MM YYYY')}
                />
              </Form.Item>
            </Row>
            <Row className="mb-5">
              <Form.Item rules={[phoneRule]} name="phone" label={t('Phone')} className="mr-5">
                <Phone disabled={trainerLoading} />
              </Form.Item>
              <Form.Item rules={[requiredField]} name="email" label={t('Email')}>
                <Input disabled size="large" />
              </Form.Item>
            </Row>
            <Row className="mb-5">
              {showExternalId && (
                <Form.Item rules={[externalIdRules]} name="externalId" label={`${t('External Id')}${t('Optional')}`}>
                  <Input size="large" />
                </Form.Item>
              )}
            </Row>
            {academiesWithFranchisor?.length && (
              <Form.Item name="teams" label={t('Teams(optional)')} className="mb-7">
                <TeamSelector mode="tags" disabled={trainerLoading} size="large" academyId={academiesWithFranchisor} />
              </Form.Item>
            )}
            <Row justify="end">
              <Button htmlType="submit" type="primary" size="large" disabled={trainerLoading} loading={trainerLoading}>
                {t('Save')}
              </Button>
            </Row>
          </Col>
        </Row>
      ) : (
        <>
          <Row className="flex-noWrap mb-5">
            <Col className="mr-5">
              <AvatarEditor onChange={setAvatarId} avatarId={avatarId} />
            </Col>
            <Col>
              <Row>
                <Form.Item name="firstName" className="mb-3">
                  <Input disabled={trainerLoading} size="large" />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item name="lastName" className="mb-0">
                  <Input disabled={trainerLoading} size="large" />
                </Form.Item>
              </Row>
            </Col>
          </Row>
          <Form.Item name="gender" label={t('Gender')} className="width-full mb-5">
            <GenderSelect disabled={trainerLoading} size="large" />
          </Form.Item>
          <Form.Item name="birthday" label={t('Birthday')} className="width-full mb-5">
            <DatePicker
              disabled={trainerLoading}
              disabledDate={disabledFutureDates}
              className="width-full"
              size="large"
              format={clientDateFormat}
              placeholder={t('DD MM YYYY')}
            />
          </Form.Item>
          <Form.Item rules={[phoneRule]} name="phone" label={t('Phone')} className="width-full mb-5">
            <Phone disabled={trainerLoading} />
          </Form.Item>
          <Form.Item name="email" label={t('Email')}>
            <Input disabled size="large" />
          </Form.Item>
          {showExternalId && (
            <Form.Item rules={[externalIdRules]} name="externalId" label={`${t('External Id')}${t('Optional')}`}>
              <Input size="large" />
            </Form.Item>
          )}
          {academyId && (
            <Form.Item name="teams" label={t('Teams(optional)')} className="mb-5">
              <TeamSelector mode="tags" disabled={trainerLoading} size="large" academyId={academyId} />
            </Form.Item>
          )}
          <Button block htmlType="submit" type="primary" size="large" disabled={trainerLoading} loading={trainerLoading}>
            {t('Save')}
          </Button>
        </>
      )}
    </Form>
  );
};

export const TrainerForm = communicationAuth.injector(
  communicationUI.injector(communicationTrainer.injector(TrainerFormComponent))
);
