import moment, { Moment } from 'moment';
import { store } from 'common/helpers/axios.helper';
import { EUserRole } from 'entities/User/User.models';
import { EPlayerAgeGroup, PLAYER_RATINGS_MAP } from 'entities/Player/Player.models';

export const isCurrentUserMentorForSomeoneElse = () => {
  const state = store.getState();
  const user = state.auth?.user?.data;
  const userId = user?.id;
  const userRole = user?.role;
  const mentorId = state.player?.model?.data?.mentorRequest?.mentor?.id;
  // const mentorId = state.player?.profile?.data?.mentor?.id || state.player?.model?.data?.mentorRequest?.mentor?.id;

  return userRole === EUserRole.Mentor && userId !== mentorId;
};

export const isCurrentUserMentorForPlayer = () => {
  const state = store.getState();
  const user = state.auth?.user?.data;
  const userId = user?.id;
  const userRole = user?.role;
  const mentorId = state.player?.model?.data?.mentorRequest?.mentor?.id;
  // const mentorId = state.player?.profile?.data?.mentor?.id || state.player?.model?.data?.mentorRequest?.mentor?.id;

  return userRole === EUserRole.Mentor && userId === mentorId;
};

export const isCurrentUserAcademyForPlayer = () => {
  const state = store.getState();
  const user = state.auth?.user?.data;
  const isAcademyWorker = user?.role === EUserRole.AcademyWorker;
  const userAcademyId = user?.academyWorker?.academy?.id;
  const playerAcademy = state.player?.model?.data?.academyLink?.academy?.id;

  return isAcademyWorker && userAcademyId === playerAcademy;
};

export const getPlayerRatingByValue = (value = 0) => {
  const item = PLAYER_RATINGS_MAP.find(({ limit }) => limit <= value);
  return item?.value ?? 0;
};

export const birthdayToAgeGroup = (birthdayMoment: string | Moment): EPlayerAgeGroup | undefined => {
  const birthday = moment(birthdayMoment).toDate();
  return birthday ? ageToAgeGroup(new Date().getFullYear() - new Date(birthday).getFullYear()) : undefined;
};

export const ageToAgeGroup = (age: number | null): EPlayerAgeGroup | undefined => {
  if (age === null) {
    return undefined;
  } else if (age < 7) {
    return EPlayerAgeGroup.U7;
  } else if (age > 17) {
    return EPlayerAgeGroup.U17p;
  } else {
    return `U${age}` as EPlayerAgeGroup;
  }
};
