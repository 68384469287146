import React from 'react';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import Col from 'antd/es/col';
import { useTranslation } from 'react-i18next';
import LampIcon from 'app/assets/icons/lamp.svg';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import { IMentorRequestCreateParams } from 'entities/MentorRequest/MentorRequest.models';

interface IComponentProps {
  onClose: () => void;
}
type AllProps = IMentorRequestConnectedProps & IPlayerConnectedProps & IComponentProps;

const MentorRequestModal: React.FC<AllProps> = props => {
  const { onClose, mentorRequestModel, sendPlayerInvite, playerInvite } = props;
  const { params = {} } = mentorRequestModel;
  const { loading } = playerInvite;

  const { t } = useTranslation();

  const onSend = React.useCallback(() => {
    const { email } = params as IMentorRequestCreateParams;
    if (email) {
      sendPlayerInvite({ email });
    }
  }, [params]);

  return (
    <Row className="t-align-c" align="middle" justify="center" gutter={[8, 16]}>
      <Col span={24}>
        <img height={88} src={LampIcon} alt="AircraftIcon" />
      </Col>
      <Col span={24}>
        <Typography.Title className="mb-0" level={3}>
          {t('Player not exist')}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Typography.Text className="mb-0"> {t('invite-player-mentor-message')}</Typography.Text>
      </Col>
      <Col span={24}>
        <Button loading={loading} type="primary" block onClick={onSend} size="large">
          {t('Send request')}
        </Button>
      </Col>
      <Col span={24}>
        <Button block onClick={onClose} size="large">
          {t('Revoke')}
        </Button>
      </Col>
    </Row>
  );
};

export const MentorRequestModalInvite = communicationPlayer.injector(communicationMentorRequest.injector(MentorRequestModal));
