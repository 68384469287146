import React, { useCallback, useContext, useMemo } from 'react';
import moment from 'moment';
import List from 'antd/es/list';
import Row from 'antd/es/row';
import Popover from 'antd/es/popover';
import { Col, Space, Tooltip } from 'antd';
import Button from 'antd/es/button';
import { TableOutlined } from '@ant-design/icons';
import Typography from 'antd/es/typography';
import { useMediaPredicate } from 'react-media-hook';
import { useTranslation } from 'react-i18next';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { SCREEN_MD } from 'common/const/config.const';
import { VideoPrivate } from 'common/components/Video/VideoPrivate';
import { getUTCEndOfDay, getUTCStartOfDay } from 'common/helpers/date.helper';
import { translateTestData } from 'common/helpers/test.helper';
import { normalizeStringDate } from 'common/helpers/normalize.helper';
import { MeasurementContext } from 'common/components/Provider/MeasurementContext';
import { ReactComponent as QuestionCircle } from 'app/assets/icons/QuestionCircle.svg';
import InfoIcon from 'app/assets/icons/pp-info-icon.svg';
import { ReactComponent as JcLogoIcon } from 'app/assets/icons/jc-logo.svg';
import { ReactComponent as JsLogoIcon } from 'app/assets/icons/js-logo.svg';
import { PlayerTestResultAverage } from 'entities/PlayerTest/components/PlayerTestResultAverage';
import { IPlayerTestModel, IPlayerTestResultPureModel } from 'entities/PlayerTest/PlayerTest.models';
import { ITestResultsHistoryCollectionFilter } from 'entities/Test/Test.models';
import { PlayerProviderContext } from 'entities/Player/components/PlayerProvider';

const IMAGE_SIZE = 48;
const VIDEO_SIZE = 190;

interface IComponentProps {
  item: IPlayerTestModel;
  isUserAuth: boolean;
  onItemClick?: (params: ITestResultsHistoryCollectionFilter) => void;
}
type AllProps = IComponentProps;

const TestListItemComponent: React.FC<AllProps> = props => {
  const { item, onItemClick, isUserAuth } = props;
  const { t } = useTranslation();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${SCREEN_MD})`);
  const { player } = React.useContext(PlayerProviderContext);
  const { id, test, testResults, createdAt: date, video, uploadById } = item;
  const { ageGroup } = player?.data || {};
  const { iconJC, icon, baseResultsForView, tag, id: testId } = test;
  const imgId = icon?.id || iconJC?.id;
  const { id: videoId, image: preview } = video || {};
  const from = getUTCStartOfDay(moment(date));
  const to = getUTCEndOfDay(moment(date));
  const { measurementSystem } = useContext(MeasurementContext);
  const { title, description } = translateTestData(test as any, measurementSystem);
  const openTestResultTable = useCallback(
    () =>
      onItemClick &&
      onItemClick({
        id: testId,
        tags: baseResultsForView,
        testTag: tag,
        from,
        to,
        ageGroups: ageGroup ? [ageGroup] : undefined
      }),
    [onItemClick, testId, baseResultsForView, tag, from, to, ageGroup]
  );

  const PopoverContent = useMemo(
    () => (
      <div>
        <span className="d-block mb-3 pp-font-title-2 font-weight-8">{t('Description')}</span>
        <span className="pp-font-title-3 player-profile-test-item__description__text pre-line-space">{description}</span>
      </div>
    ),
    [description]
  );

  const SourceIcon = useMemo(() => (uploadById ? JcLogoIcon : JsLogoIcon), [uploadById]);

  return (
    <List.Item key={id} className="players__test-list_item ant-card p-4 mb-3">
      <Space direction="vertical" size="small" className="width-full player-skills-detail">
        <Row justify="space-between" align="middle">
          <Col xs={20} md={18} lg={18} xl={18}>
            <Space wrap={false} size={16} align="start">
              <ImagePrivate
                preview={false}
                height={IMAGE_SIZE}
                width={IMAGE_SIZE}
                imgId={imgId}
                alt="Image-test"
                className="mr-0 radius-default"
              />
              <Row className="flex-column">
                <Typography.Title className="m-0 word-break" level={4} ellipsis={{ rows: 2 }}>
                  {title}
                </Typography.Title>
                {date && (
                  <Col className="mr-4">
                    <Typography.Text className="fs-xs pp-font-small d-block font-weight-5">
                      {normalizeStringDate(date)}
                    </Typography.Text>
                  </Col>
                )}
              </Row>
            </Space>
          </Col>

          <Col xs={4} md={6} lg={6} xl={6}>
            <Row justify="end" gutter={[8, 8]}>
              <Col>
                <Button type="default" onClick={openTestResultTable} title={t('Test result')}>
                  {isDesktop ? t('Test result') : <TableOutlined />}
                </Button>
              </Col>
              {isUserAuth && (
                <Col>
                  <SourceIcon width={32} height={32} />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row className="width-full" justify="space-between">
          <Col xs={24} lg={24} xl={4} className="mr-3">
            <div className="player-profile-test-item__video">
              <VideoPrivate height={130} width={VIDEO_SIZE} videoId={videoId} preview={preview?.id} isPublic />
              <Popover
                placement="topLeft"
                overlayClassName="player-profile-test-item__description__popover"
                content={PopoverContent}
                trigger="click"
              >
                <img className="player-profile-test-item__video__icon" src={InfoIcon} height={24} width={24} />
              </Popover>
            </div>
          </Col>
          <Col xs={24} md={18} lg={18} xl={19}>
            {isDesktop && (
              <Row align="middle" justify="space-between" className="width-full px-3 py-2 player-skills-detail__header">
                <Col span={11}>
                  <Row justify="space-between" align="middle" wrap={false}>
                    <Col>
                      <Typography.Text className="color-black-primary font-weight-4">{t('Parameter')}</Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="color-black-primary font-weight-4">{t('Result')}</Typography.Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={13}>
                  <Row justify="end" className="width-full" align="middle">
                    <Typography.Text className="color-black-primary font-weight-4 mr-2">{t('Benchmark')}</Typography.Text>
                    <Tooltip placement="top" title={t('Best 5% in rating')}>
                      <QuestionCircle />
                    </Tooltip>
                  </Row>
                </Col>
              </Row>
            )}

            <List
              bordered={!isDesktop}
              size="small"
              className="player-skills-detail__list"
              dataSource={testResults}
              renderItem={(testResultItem: IPlayerTestResultPureModel) => (
                <List.Item className="width-full py-4" key={testResultItem.baseResult.tag}>
                  <PlayerTestResultAverage result={{ ...testResultItem, ...testResultItem?.baseResult }} />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Space>
    </List.Item>
  );
};

export const PlayerTestListItem = TestListItemComponent;
