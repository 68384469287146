import React, { useCallback, useMemo } from 'react';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import List from 'antd/es/list';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import moment from 'moment';
import Popconfirm from 'antd/es/popconfirm';
import CameraOutlined from '@ant-design/icons/CameraOutlined';
import { useTranslation } from 'react-i18next';
import { nameBuilder } from 'common/helpers/name.helper';
import { locationFormatter } from 'common/helpers/location.helper';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { IMentorRequestModel, EMentorRequestStatus, EMentorRequestAcceptSide } from 'entities/MentorRequest/MentorRequest.models';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';
import { SubscriptionButton } from 'entities/Subscription/components/SubscriptionButton';
import { ESubscriptionsEngines } from 'entities/Subscription/Subscription.models';
import { isAcademyHideTariffs } from 'entities/Subscription/helpers/subscription.helper';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';

type AllProps = IComponentProps & IMentorRequestConnectedProps & IUIConnectedProps;

interface IComponentProps {
  item: IMentorRequestModel;
  onClick: (itemModel: IMentorRequestModel) => void;
}

const avatarSize = 48;
const defaultAvatarStyle = { width: avatarSize, height: avatarSize, fontSize: avatarSize / 2 };

const MentorRequestListItemComponent = ({
  item,
  onClick,
  updateMentorRequestModel,
  mentorRequestModel,
  openUIPlayerEmailEditModal
}: AllProps) => {
  const { loading, params } = mentorRequestModel;
  const { t } = useTranslation();
  const { id, player, status, subscription, acceptSide, subscriptionEngine } = item;
  const {
    firstName,
    lastName,
    ageGroup,
    rating,
    image,
    email,
    phone,
    isPaid,
    id: playerId,
    birthday,
    academyLink,
    address
  } = player;
  const hideTariffs = useMemo(() => isAcademyHideTariffs(player), [player]);
  const playerName = nameBuilder(firstName, lastName);
  const fullAddress = locationFormatter(address) ?? t('Unknown address');
  const playerAcademy = academyLink?.academy;

  const paramsId = useMemo(() => {
    if (params) {
      if (typeof params === 'string') {
        return params;
      }

      if ('id' in params) {
        return params.id;
      }
    }

    return undefined;
  }, [params]);
  const disabled = useMemo(() => (paramsId ? paramsId === id && loading : loading), [paramsId, id, loading]);

  const onItemClick = useCallback(() => {
    if (status === EMentorRequestStatus.Accepted) {
      onClick(item);
    }
  }, [id, status]);

  const onRemoveRequest = useCallback(() => {
    updateMentorRequestModel({
      status: EMentorRequestStatus.Aborted,
      id
    });
  }, [id]);

  const onAcceptRequest = useCallback(async () => {
    await updateMentorRequestModel({
      status: EMentorRequestStatus.Accepted,
      id
    });
  }, [id]);

  const onDeclineRequest = useCallback(() => {
    updateMentorRequestModel({
      status: EMentorRequestStatus.Declined,
      id
    });
  }, [id]);

  const actionItem = useMemo(() => {
    switch (true) {
      case status === EMentorRequestStatus.New && acceptSide === EMentorRequestAcceptSide.Mentor: {
        return (
          <Row gutter={[16, 8]}>
            <Col xs={24} sm={12}>
              <Button
                disabled={disabled}
                size="large"
                onClick={
                  email
                    ? onAcceptRequest
                    : () => openUIPlayerEmailEditModal({ playerId, mentorRequestId: id, needAcceptRequest: true })
                }
                block
                type="primary"
              >
                {t('Accept')}
              </Button>
            </Col>
            <Col xs={24} sm={12}>
              <Popconfirm
                disabled={disabled}
                okButtonProps={{
                  type: 'text',
                  size: 'middle',
                  danger: true
                }}
                cancelButtonProps={{
                  size: 'middle'
                }}
                okText={t('Decline')}
                cancelText={t('Leave')}
                title={t('Are you sure you want to decline request?')}
                onConfirm={onDeclineRequest}
              >
                <Button disabled={disabled} size="large" block danger type="primary">
                  <div className="ellipsis" title={t('Decline')}>
                    {t('Decline')}
                  </div>
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        );
      }
      case status === EMentorRequestStatus.New: {
        return (
          <Row justify="center">
            <Popconfirm
              okButtonProps={{
                type: 'text',
                size: 'middle',
                danger: true
              }}
              cancelButtonProps={{
                size: 'middle'
              }}
              okText={t('Revoke')}
              cancelText={t('Leave')}
              title={t('Cancel the add request?')}
              onConfirm={onRemoveRequest}
            >
              <Button disabled={disabled} size="large" block className="color-gray t-align-c" type="text">
                {t(`status-player-card.${status}`)}
              </Button>
            </Popconfirm>
          </Row>
        );
      }

      default: {
        return (
          <Row gutter={[16, 8]} align="middle" justify="center">
            <Col xs={24} sm={email ? 24 : 12}>
              <Row align="middle" justify="center">
                <SubscriptionButton
                  hideTariffs={hideTariffs}
                  isPaid={isPaid}
                  playerId={player.id}
                  block
                  mentorRequestSubscriptionEngine={subscriptionEngine as ESubscriptionsEngines}
                  size="large"
                  mentorRequestId={id}
                  subscription={subscription}
                />
              </Row>
            </Col>
            {!email && (
              <Col xs={24} sm={12}>
                <Button
                  disabled={disabled}
                  size="large"
                  block
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    openUIPlayerEmailEditModal({ playerId, mentorRequestId: id });
                  }}
                >
                  <Row align="middle" justify="center">
                    {t('Connect JuniStat')}
                  </Row>
                </Button>
              </Col>
            )}
          </Row>
        );
      }
    }
  }, [status, subscription, id, disabled, acceptSide, email]);

  return (
    <List.Item key={id} className="height-full">
      <Card
        hoverable={status === EMentorRequestStatus.Accepted}
        onClick={onItemClick}
        className="p-5 private-player-card bg-component border-none radius-default"
      >
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Row gutter={[16, 8]} wrap={false} style={{ marginBottom: playerAcademy?.name ? '' : '5px' }}>
              <Col>
                <Row gutter={[4, 4]} className="width-content">
                  <Col>
                    <Row wrap={false} gutter={[4, 4]}>
                      <Col>
                        <Typography.Text className="d-block private-player-card_rating bg-primary height-full height color-black px-3 radius-round fs-xs t-align-c">
                          {rating ?? '-'}
                        </Typography.Text>
                      </Col>
                      <Col>
                        <Typography.Text className="bg-layout private-player-card_age-group color-black px-3 radius-round fs-xs t-align-c d-block">
                          {ageGroup}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Typography.Text className="bg-layout color-black private-player-card_year px-3 radius-round fs-xs t-align-c d-block">
                      {moment(birthday).year()}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
              <Col className="private-player-card__avatar-block">
                {image?.id ? (
                  <ImagePrivate
                    alt={t('Player avatar')}
                    width={avatarSize}
                    height={avatarSize}
                    className="border-circle"
                    imgId={image.id}
                  />
                ) : (
                  <Row justify="center" align="middle" className="bg-component-third radius-round" style={defaultAvatarStyle}>
                    <CameraOutlined />
                  </Row>
                )}
                {playerAcademy?.image?.id && (
                  <ImagePrivate
                    alt={t('Academy avatar')}
                    width={24}
                    height={24}
                    className="border-circle private-player-card__avatar-block__academy"
                    imgId={playerAcademy.image.id}
                  />
                )}
              </Col>
              <Col flex={1} className="overflow-hidden d-flex flex-align-center">
                <Row gutter={[4, 0]} align="middle">
                  <Col xs={24} className="d-grid">
                    <Typography.Text className="fs-xs font-weight-5" ellipsis strong>
                      {firstName || lastName ? playerName : email ? email : phone ? phone : t('Unknown player')}
                    </Typography.Text>
                  </Col>
                  <Col xs={24} className="d-grid">
                    <Typography.Text className="fs-xxxs color-black-40" ellipsis>
                      {fullAddress}
                    </Typography.Text>
                  </Col>
                  {playerAcademy?.name && (
                    <Col xs={24} className="d-grid">
                      <Typography.Text className="fs-xxxs color-black-40" ellipsis>
                        {playerAcademy.name}
                      </Typography.Text>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24}>{actionItem}</Col>
        </Row>
      </Card>
    </List.Item>
  );
};

export const MentorRequestListItem = communicationUI.injector(
  communicationMentorRequest.injector(MentorRequestListItemComponent)
);
