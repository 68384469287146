import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Image from 'antd/es/image';
import CameraOutlined from '@ant-design/icons/CameraOutlined';
import { getImageSize, getImageUrl } from 'entities/Image/Image.helper';

interface IComponentProps {
  width?: number;
  height: number;
  imgId?: string | null;
  DefaultIcon?: any;
  alt: string;
  className?: string;
  preview?: boolean;
  stopPropagation?: boolean;
}

type AllProps = IComponentProps;

const ImagePrivateComponent: React.FC<AllProps> = props => {
  const { width, height, imgId, alt, className, preview = true, DefaultIcon = CameraOutlined } = props;
  const [imgUrl, setImgUrl] = useState<string | undefined>(undefined);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    const link = getImageUrl(imgId);
    const previewLink = getImageUrl(imgId, getImageSize(height, width));
    setImgUrl(link);
    setPreviewUrl(previewLink);
  }, [imgId]);

  const imgStyle = useMemo(
    () => ({
      width: width || '100%',
      minWidth: width || '100%',
      maxWidth: width || '100%',
      height,
      minHeight: height,
      maxHeight: height
    }),
    [width, height]
  );

  const onClickImg = useCallback(
    e => {
      if (preview) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [preview]
  );

  return imgUrl ? (
    <div style={imgStyle} onClick={onClickImg} className={`image-private cursor-pointer ${className}`}>
      <Image src={previewUrl} preview={preview && { src: imgUrl, mask: false }} alt={alt} />
    </div>
  ) : (
    <div className={`image-private__skeleton ${className} bg-component-third radius-round`} style={imgStyle}>
      <DefaultIcon />
    </div>
  );
};

export const ImagePrivate = ImagePrivateComponent;
