import React from 'react';
import { useHistory, useLocation } from 'react-router';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import { useTranslation } from 'react-i18next';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import { ERoutesPublic } from 'common/models/routesModel';

interface IComponentProps {
  path?: string;
}

export const GoBackButton: React.FC<IComponentProps> = props => {
  const { path = ERoutesPublic.Login } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();

  const goBack = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(`${path}${search}`);
    }
  };

  return (
    <Row justify="start" className="mb-5">
      <Button onClick={goBack}>
        <ArrowLeftOutlined /> {t('Back')}
      </Button>
    </Row>
  );
};
