import React, { useEffect, useState } from 'react';
import Modal from 'antd/es/modal/Modal';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import Alert from 'antd/lib/alert';
import Popconfirm from 'antd/es/popconfirm';
import Col from 'antd/es/col';
import message from 'antd/es/message';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Divider from 'antd/es/divider';
import CameraOutlined from '@ant-design/icons/CameraOutlined';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { ReactComponent as JCSmallIcon } from 'app/assets/images/JCSmall.svg';
import { ReactComponent as JSSmallIcon } from 'app/assets/images/JSSmall.svg';
import { PrivatePlayerSelector } from 'entities/PrivatePlayer/components/Selectors/PrivatePlayerSelector';
import { IPlayerModel, IPlayerSearchModel } from 'entities/Player/Player.models';
import { playerTransport } from 'entities/Player/Player.transport';
import { EMentorRequestStatus, IMentorRequestCollectionFilter } from 'entities/MentorRequest/MentorRequest.models';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';

type AllProps = IMentorRequestConnectedProps & IUIConnectedProps;

const avatarSize = 88;

const PlayerMergeModalComponent: React.FC<AllProps> = props => {
  const { getMentorRequestCollection, UIPlayerMergeModal, closeUIPlayerMergeModal } = props;
  const { isVisible, sourceId: sourceIdPlayer, targetId: targetIdPlayer } = UIPlayerMergeModal?.data || {};
  const [targetPlayerData, setTargetPlayerData] = useState<Partial<IPlayerSearchModel> | undefined>(undefined);
  const [sourcePlayerData, setSourcePlayerData] = useState<Partial<IPlayerSearchModel> | undefined>(undefined);
  const { imageId: sourceImageId, id: sourceId } = sourcePlayerData || {};
  const { imageId: targetImageId, id: targetId } = targetPlayerData || {};
  const [isLoading, setLoading] = useState(false);
  const disable = !sourceId || !targetId || isLoading;

  const { t } = useTranslation();
  const mergePlayer = async () => {
    if (sourceId && targetId) {
      try {
        setLoading(true);
        const player: IPlayerModel = await playerTransport.mergePlayers({ source: sourceId, target: targetId });

        if (player) {
          message.success(t('Players merge request has been successfully sent'));
          const filters: IMentorRequestCollectionFilter = {
            limit: 20,
            offset: 0,
            statuses: [EMentorRequestStatus.New, EMentorRequestStatus.Accepted],
            regComplete: true
          };
          await getMentorRequestCollection(filters);
          setLoading(false);
          closeUIPlayerMergeModal();
        }
      } catch (e) {
        if (e?.data?.code === 'error.subscriptionActiveException') {
          message.warning(t('subscriptionActiveException'));
        } else {
          message.warning(t('An error occurred while merging profiles. Contact support team.'));
        }
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (sourceIdPlayer && targetIdPlayer) {
        setLoading(true);
        const sourcePlayer = await playerTransport.getPlayerModel(sourceIdPlayer);
        const targetPlayer = await playerTransport.getPlayerModel(targetIdPlayer);
        setSourcePlayerData(sourcePlayer);
        setTargetPlayerData(targetPlayer);
        setLoading(false);
      }
    })();
  }, [sourceIdPlayer, targetIdPlayer]);
  useEffect(() => {
    if (!isVisible && (sourcePlayerData || targetPlayerData)) {
      setSourcePlayerData(undefined);
      setTargetPlayerData(undefined);
    }
  }, [isVisible, sourcePlayerData, targetPlayerData]);

  return (
    <Modal
      destroyOnClose
      bodyStyle={{ border: 'none' }}
      visible={isVisible}
      closable={false}
      onCancel={e => {
        e?.stopPropagation();
        e?.preventDefault();
        closeUIPlayerMergeModal();
      }}
      footer={false}
      title={t('Merge player profiles')}
    >
      <Typography.Title level={5}>{t('Merging player profiles description')}</Typography.Title>
      <Alert
        className="mt-5 mb-5 radius-default align-start"
        message={
          <>
            <span>{t('Player’s subscription will be saved')}</span>
            <br />
            <span>{t('Profile cannot be split back')}</span>
            <br />
            <span>{t('Profile cannot be deleted without confirmation by the academy/school')}</span>
          </>
        }
        type="warning"
        showIcon
      />
      <Divider />
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Row align="middle" className="mb-3">
              <JSSmallIcon className="radius-round" />
              <span className="fs-xxxs ml-2">JuniStat</span>
            </Row>
            <PrivatePlayerSelector playerType="source" onChange={setSourcePlayerData} value={sourcePlayerData} />
            {sourceId && (
              <Col span={24} className="mb-5 mt-5" flex={0}>
                <Row className="width-full" justify="start">
                  <ImagePrivate
                    DefaultIcon={CameraOutlined}
                    preview={false}
                    imgId={sourceImageId}
                    height={avatarSize}
                    width={avatarSize}
                    alt="img"
                    className="border-circle"
                  />
                </Row>
              </Col>
            )}
          </Col>
          <Col xs={24} sm={12}>
            <Row align="middle" className="mb-3">
              <JCSmallIcon className="radius-round" />
              <span className="fs-xxxs ml-2">JuniCoach</span>
            </Row>
            <PrivatePlayerSelector playerType="target" onChange={setTargetPlayerData} value={targetPlayerData} />
            {targetId && (
              <Col span={24} className="mb-5 mt-5" flex={0}>
                <Row className="width-full" justify="start">
                  <ImagePrivate
                    DefaultIcon={CameraOutlined}
                    preview={false}
                    imgId={targetImageId}
                    height={avatarSize}
                    width={avatarSize}
                    alt="img"
                    className="border-circle"
                  />
                </Row>
              </Col>
            )}
          </Col>
        </Row>
      </Col>
      <Divider />
      <Col span={24}>
        <Row justify="space-between">
          <Button
            size="large"
            onClick={e => {
              e?.preventDefault();
              e?.stopPropagation();
              closeUIPlayerMergeModal();
            }}
          >
            {t('Close')}
          </Button>
          <Popconfirm
            disabled={disable}
            okButtonProps={{
              type: 'text',
              size: 'middle'
            }}
            cancelButtonProps={{
              size: 'middle'
            }}
            okText={t('Yes')}
            cancelText={t('No')}
            title={t('The operation is irreversible, are you sure?')}
            onConfirm={mergePlayer}
          >
            <Button size="large" type="primary" disabled={disable}>
              {t('Merge')}
            </Button>
          </Popconfirm>
        </Row>
      </Col>
    </Modal>
  );
};

export const PlayerMergeModal = communicationUI.injector(communicationMentorRequest.injector(PlayerMergeModalComponent));
