import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { getPlayerName } from 'common/helpers/name.helper';
import { ILinePalette } from 'common/models/lineChartModel';
import { notExistTranslationWrapper } from 'common/helpers/translate.helper';
import { TagTabs, TagTabsPane } from 'common/components/TagTabs/TagTabs';
import { MeasurementContext } from 'common/components/Provider/MeasurementContext';
import { EResultTypes } from 'entities/PlayerTest/PlayerTest.models';
import { ETestRanging } from 'entities/Test/Test.models';
import LineChart from 'entities/Player/components/Profile_old/Draft/LineChart/LineChart';
import { IPlayerProfileModel, IPlayerProfileTestResultsHistoryModel } from 'entities/Player/Player.models';

interface IComponentProps {
  datasets?: IPlayerProfileTestResultsHistoryModel[];
  player?: IPlayerProfileModel | null;
}

type AllProps = IComponentProps;

const PALETTE: ILinePalette = {
  main: '#ffe83f',
  alternative: '#0082FC',
  second: '#000000',
  lines: '#DEDEDE',
  label: '#000000',
  yearLabel: '#000000',
  labelFontSize: 8
};

export const PlayerSkillsDevelopment: FC<AllProps> = ({ datasets, player }) => {
  const [selectedTag, setSelectedTag] = useState<string>();
  const { measurementSystem } = useContext(MeasurementContext);

  const selectedData = useMemo<IPlayerProfileTestResultsHistoryModel | undefined>(
    () => datasets?.find(item => selectedTag === item.tag),
    [selectedTag]
  );

  const { t } = useTranslation();
  useEffect(() => {
    setSelectedTag(datasets?.[0]?.tag);
  }, [datasets]);

  const { min, max } = useMemo(() => {
    const minValue = selectedData?.minValue || 0;
    const maxValue = selectedData?.maxValue || 0;

    if (selectedData?.minValue === selectedData?.maxValue) {
      const isSb = selectedData?.ranging === ETestRanging.SB;
      const balancer = minValue * 0.2;

      return {
        min: isSb ? minValue + balancer : minValue - balancer,
        max: isSb ? maxValue - balancer : maxValue + balancer
      };
    }

    return {
      min: minValue,
      max: maxValue
    };
  }, [selectedData]);

  const dataSource = useMemo(
    () =>
      selectedData?.values?.map(({ avgValue, date, value, uploaderType }) => ({
        value: value ?? undefined,
        secondValue: avgValue,
        updateDate: date,
        uploadedByPlayer: uploaderType === 'player',
        date
      })) ?? [],
    [selectedData]
  );

  const legends = useMemo(() => {
    if (player) {
      return [
        { title: getPlayerName(player), color: PALETTE.main ?? '' },
        { title: t(`Average`, { group: player.ageGroup }), color: PALETTE.second ?? '' }
      ];
    }
    return undefined;
  }, [player]);

  return (
    <Col className="player-skills-development position-relative width-full" xs={24}>
      <TagTabs className="player-skills-development__tabs" onChange={setSelectedTag} activeKey={selectedTag}>
        {datasets?.map(
          item => (
            <TagTabsPane tab={t(item.tag)} key={item.tag} />
          ),
          []
        )}
      </TagTabs>
      <LineChart
        units={selectedData?.units as EResultTypes}
        legends={legends}
        palette={PALETTE}
        yLabel={notExistTranslationWrapper(selectedData?.units, {}, measurementSystem)}
        isInvert={selectedData?.ranging === 'SB'}
        tooltipLabel={getPlayerName(player)}
        yMin={min}
        yMax={max}
        datasets={dataSource}
        height={250}
      />
    </Col>
  );
};
