import React, { Dispatch, SetStateAction } from 'react';
import ReactCrop from 'react-image-crop';
import { ICrop } from 'common/components/AvatarEditor/AvatarEditor';

interface IProps {
  src: string | ArrayBuffer | null | undefined;
  crop: ICrop;
  setCrop: Dispatch<SetStateAction<ICrop>>;
  setImage: Dispatch<SetStateAction<HTMLImageElement | undefined>>;
}

const cropOptions = {
  minWidth: 100,
  minHeight: 100
};

const imageStyle = { maxHeight: 600 };

export const AvatarEditorBody: React.FC<IProps> = ({ src, crop, setCrop, setImage }) => {
  return (
    <ReactCrop
      src={src}
      circularCrop
      minWidth={cropOptions.minWidth}
      minHeight={cropOptions.minHeight}
      onImageLoaded={setImage}
      crop={crop}
      imageStyle={imageStyle}
      onChange={setCrop}
    />
  );
};
