import React from 'react';
import { ControlBar, Player } from 'video-react';
import { communicationShare, IShareConnectedProps } from 'entities/Share/Share.communication';

type AllProps = IShareConnectedProps & {
  type: 'feed' | 'playerToTest';
};

const ShareVideoCommonComponent: React.FC<AllProps> = props => {
  const { shareModel, type } = props;
  const { data } = shareModel;
  const videoUrl = data?.data?.[type]?.videoUrl ?? '';

  return (
    <div style={{ height: '80vh' }}>
      <Player className="promo-page__video" autoPlay fluid={false} height="100%">
        <source src={videoUrl} type="video/mp4" />
        <ControlBar autoHide={false} />
      </Player>
    </div>
  );
};

export const ShareVideoCommon = communicationShare.injector(ShareVideoCommonComponent);
