import React, { useContext, ReactElement, useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import Spin from 'antd/es/spin';
import { StripeElementLocale } from '@stripe/stripe-js';
import { StripeContext } from 'common/components/Stripe/StripeProvider';

interface IProps {
  children: ReactElement;
}

export const StripeConsumer: React.FC<IProps> = props => {
  const { children } = props;
  const { promise, lang } = useContext(StripeContext);

  if (promise === undefined) {
    return <Spin />;
  }

  const elementsOptions = useMemo(
    () => ({
      locale: (lang as StripeElementLocale) || 'auto'
    }),
    [lang]
  );

  return (
    <Elements stripe={promise} options={elementsOptions}>
      {children}
    </Elements>
  );
};
