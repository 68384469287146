import React from 'react';
import Typography from 'antd/es/typography';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { IPlayerProfileSkillModel } from 'entities/Player/Player.models';

interface IProps {
  element: IPlayerProfileSkillModel;
}

type AllProps = IProps;

const PlayerShieldSkillBlockComponent: React.FC<AllProps> = props => {
  const { element } = props;

  return (
    <Row className="player-shield__skills__block">
      <Col span={12}>
        <Typography.Title level={3} className="mb-0 uppercase font-secondary font-weight-4">
          {element?.abbreviation || '-'}
        </Typography.Title>
      </Col>
      <Col span={12}>
        <Row justify="end">
          <Typography.Title level={3} className="mb-0 font-secondary font-weight-4">
            {element?.value ?? '-'}
          </Typography.Title>
        </Row>
      </Col>
    </Row>
  );
};

export const PlayerShieldSkillBlock = PlayerShieldSkillBlockComponent;
