import { Col, Row, Typography } from 'antd';
import React, { useMemo } from 'react';
import { getResultBarColor } from 'common/helpers/result-bar.helper';
import { ISkillModel } from 'entities/Player/Player.models';

interface IComponentProps {
  skill: ISkillModel;
  value?: number | null;
}

type AllProps = IComponentProps;

export const SkillBarChart = ({ skill, value }: AllProps) => {
  const { abbreviation } = skill;
  const colorClassName = useMemo(() => `bg-${getResultBarColor(value ?? 0, true)}`, [value]);

  return (
    <Row justify="center" className="skill-bar-chart">
      <Col xs={24} className="t-align-c">
        <Typography.Text className="font-weight-5">{abbreviation}</Typography.Text>
      </Col>
      <Col xs={24}>
        <div className={`width-full ${colorClassName} skill-bar-component_bar`}> </div>
      </Col>
      <Col xs={24} className="t-align-c">
        <Typography.Text className="font-weight-5">{value ?? 0}</Typography.Text>
      </Col>
    </Row>
  );
};
