import React from 'react';
import Select, { SelectProps } from 'antd/es/select';
import i18n from 'common/helpers/i18n';
import { EMeasurementSystem } from 'common/helpers/units-converter.helper';

export const MeasurementSystemSelector: React.FC<SelectProps<any>> = props => {
  return (
    <Select
      allowClear
      size="large"
      placeholder={i18n.t('Measurement system')}
      getPopupContainer={triggerNode => triggerNode as HTMLElement}
      {...props}
    >
      <Select.Option key={EMeasurementSystem.Metric} value={EMeasurementSystem.Metric}>
        {i18n.t(EMeasurementSystem.Metric)}
      </Select.Option>
      <Select.Option key={EMeasurementSystem.US} value={EMeasurementSystem.US}>
        {i18n.t(EMeasurementSystem.US)}
      </Select.Option>
    </Select>
  );
};
