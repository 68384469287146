import { useState, useEffect, RefObject, useLayoutEffect } from 'react';

export const scrollToTop = (id: string | undefined) => {
  if (id) {
    const elem = document.getElementById(id);

    elem?.scroll(0, 0);
  }
};

export const getElementSize = (ref: RefObject<HTMLElement>) => {
  return ref?.current && ref?.current.getBoundingClientRect();
};

export const useElementSize = (ref: RefObject<HTMLElement>) => {
  const [windowDimensions, setWindowDimensions] = useState(getElementSize(ref));

  useLayoutEffect(() => {
    const bounding = getElementSize(ref);
    if (bounding?.width !== windowDimensions?.width || bounding?.height !== windowDimensions?.height) {
      setWindowDimensions(bounding);
    }
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getElementSize(ref));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
