import React from 'react';

interface IComponentProps {
  label: string;
  value: string;
}

type AllProps = IComponentProps;

export const TextBlockStyle = ({ label, value }: AllProps) => {
  return (
    <div className="component-text-block-style">
      <div className="component-text-block-style__label">{label}</div>
      <div className="component-text-block-style__value">
        {value.split('').map((symbol, index) => (
          <div className="component-text-block-style__symbol" key={`${symbol}-${index}`}>
            {symbol}
          </div>
        ))}
      </div>
    </div>
  );
};
