import React, { useMemo } from 'react';
import Row from 'antd/es/row';
import List from 'antd/es/list';
import { PublicPlayersListItemSkeleton } from 'entities/PublicPlayer/components/List/PublicPlayersListItemSkeleton';

interface IProps {
  grid?: object;
}

type AllProps = IProps;

const PublicPlayersListSkeletonComponent: React.FC<AllProps> = props => {
  const { grid } = props;

  const playersPlaceholderArray = useMemo(() => {
    const array = [];
    for (let i = 0; i < 10; i++) {
      array.push({ index: i });
    }
    return array;
  }, []);

  return (
    <Row justify="center" align="middle" className="width-full">
      <List
        itemLayout="vertical"
        dataSource={playersPlaceholderArray}
        grid={grid}
        renderItem={({ index }: { index: number }) => <PublicPlayersListItemSkeleton index={index} />}
      />
    </Row>
  );
};

export const PublicPlayersListSkeleton = PublicPlayersListSkeletonComponent;
