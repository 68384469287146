import React from 'react';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { useTranslation } from 'react-i18next';
import { communicationShare, IShareConnectedProps } from 'entities/Share/Share.communication';

const SharePlayerAcademyComponent: React.FC<IShareConnectedProps> = props => {
  const { t } = useTranslation();
  const { shareModel } = props;
  const { data } = shareModel;

  const { academies = [] } = data?.data ?? {};

  return (
    <Card className="height-full px-4 height-full pb-3 promo-page__card" bordered={false}>
      <span className="promo-page__header">{t('Subscribed clubs')}</span>
      <Row gutter={[45, 36]} justify="start" className="pt-5 px-5">
        {academies.map(({ imageUrl, name, id }) => (
          <Col key={id} flex={0}>
            <Row gutter={[0, 8]} className="promo-page__academy">
              <Col xs={24}>
                <img className="promo-page__academyImage" src={imageUrl} />
              </Col>
              <Col xs={24}>
                <label title={name} className="promo-page__academyTitle">
                  {name}
                </label>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export const SharePlayerAcademy = communicationShare.injector(SharePlayerAcademyComponent);
