import React from 'react';
import Col from 'antd/es/col';
import List from 'antd/es/list';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { ImagePrivate } from 'common/components/Image/ImagePrivate';
import { dateFormatter } from 'common/helpers/date.helper';
import { INotificationModel } from 'entities/Notification/Notification.models';
import { communicationMentorRequest, IMentorRequestConnectedProps } from 'entities/MentorRequest/MentorRequest.communication';

type AllProps = IComponentProps & IMentorRequestConnectedProps;

interface IComponentProps {
  item: INotificationModel;
}

const IMAGE_SIZE = 48;

const NotificationListItemComponent = ({ item }: AllProps) => {
  const { id, title, description, imageId, createdAt } = item;
  const { t } = useTranslation();

  return (
    <List.Item key={id} className="height-full">
      <Row wrap={false} gutter={16}>
        <Col>
          <ImagePrivate
            alt={t('Notification')}
            width={IMAGE_SIZE}
            height={IMAGE_SIZE}
            className="border-circle"
            imgId={imageId}
          />
        </Col>
        <Col>
          <Row>
            <Typography.Text className="t-pre-wrap word-break">{title}</Typography.Text>
          </Row>
          {description && (
            <Row>
              <Typography.Paragraph className="mb-0 t-pre-wrap word-break" type="secondary">
                {description}
              </Typography.Paragraph>
            </Row>
          )}
          <Row>
            <Typography.Paragraph className="mb-0" type="secondary">
              {dateFormatter(createdAt)}
            </Typography.Paragraph>
          </Row>
        </Col>
      </Row>
    </List.Item>
  );
};

export const NotificationListItem = communicationMentorRequest.injector(NotificationListItemComponent);
