import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { ISorter, TableCommon } from 'common/components/Table/TableCommon';
import { nameBuilder } from 'common/helpers/name.helper';
import { addHours, getBirthdayAgeGroup, getISOStartOfDays } from 'common/helpers/date.helper';
import { clientDateFormat } from 'common/models/dateModels';
import { IBaseFilterModel } from 'common/models/requestModels';
import { parseOrderField } from 'common/helpers/test.helper';
import { communicationTest, ITestConnectedProps } from 'entities/Test/Test.communication';
import {
  ITestResultsHistoryCollection,
  ITestResultsHistoryCollectionFilter,
  ITestResultsHistoryModel,
  initTestResultFilter,
  ITestResultsHistoryPlayerTestResultModel
} from 'entities/Test/Test.models';

interface IComponentProps {
  config: ColumnsType<any>;
  onResultClick?(item: ITestResultsHistoryModel): void;
}

type AllProps = IComponentProps & ITestConnectedProps & RouteComponentProps;

class TestResultTableCommonComponent extends TableCommon<
  ITestResultsHistoryCollection,
  ITestResultsHistoryModel,
  ColumnsType<any>,
  AllProps,
  ITestResultsHistoryCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel, sorter?: ISorter) => {
    const { getTestResultHistoryCollection, filter } = this.props;
    if (filter?.id) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {
        orderField,
        orderDirection,
        tags,
        academy,
        team,
        trainer,
        teamId,
        fromPlayerAcademy,
        playerAcademyId,
        ...filterParamsData
      } = filter || {};
      const filterParams: ITestResultsHistoryCollectionFilter = { ...params, ...filterParamsData, id: filter?.id };

      if (sorter?.field && sorter?.order && (!orderField || orderField !== sorter?.field)) {
        // any need for type format: "br"
        filterParams.orderField = parseOrderField(sorter.field, tags);
        filterParams.orderDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
      } else if (orderField && orderDirection) {
        filterParams.orderField = parseOrderField(orderField, tags);
        filterParams.orderDirection = sorter?.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : orderDirection;
      }
      if (academy?.id) {
        filterParams.academies = [academy?.id];
      }
      if (fromPlayerAcademy && playerAcademyId) {
        filterParams.academies = [playerAcademyId];
      }
      if (filter.from) {
        const dateFrom = decodeURIComponent(filter.from);
        filterParams.from = moment(dateFrom).toISOString();
      }
      if (filter.to) {
        const dateTo = decodeURIComponent(filter.to);
        filterParams.to = moment(dateTo).toISOString();
      }
      if (team?.id) {
        filterParams.teams = [team?.id];
      }
      if (teamId) {
        filterParams.teams = [teamId];
      }
      if (trainer?.id) {
        filterParams.trainers = [trainer?.id];
      }

      getTestResultHistoryCollection(filterParams);
    }
  };

  getCollection = () => {
    const { testResultHistoryCollection } = this.props;
    if (testResultHistoryCollection?.data?.data) {
      const data: any = testResultHistoryCollection.data.data.map((itemData, index) => {
        const { playerTest, player, team, trainer } = itemData;
        // todo check after JS-4569
        const resultObject: Partial<ITestResultsHistoryPlayerTestResultModel> = playerTest?.results.reduce(
          (obj, item) => Object.assign(obj, { [item.tag]: { ...item } }),
          {}
        );
        const testDate = addHours(moment(playerTest?.date), playerTest?.localTime);

        return {
          ...itemData,
          ...resultObject,
          img: player?.imageId,
          team: team?.name || '-',
          trainer: nameBuilder(trainer?.firstName, trainer?.lastName),
          ageGroup: playerTest?.ageGroup,
          birthday: getBirthdayAgeGroup(player.birthday),
          date: getISOStartOfDays(testDate).format(clientDateFormat),
          id: index
        };
      });
      return {
        ...testResultHistoryCollection,
        data: {
          ...testResultHistoryCollection.data,
          data
        }
      };
    } else {
      return testResultHistoryCollection;
    }
  };

  addQuery = (pageIndex: number, sorter?: ISorter) => {
    const { filter } = this.props;
    const { tags } = filter || {};
    const searchPath = queryToObject<Partial<ITestResultsHistoryCollectionFilter>>(initTestResultFilter);

    let search;
    if (parseOrderField(sorter?.field, tags)) {
      if (sorter?.order) {
        const orderDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
        search = objectToQuery({ ...searchPath, pageIndex, orderField: sorter?.field, orderDirection });
      } else {
        search = objectToQuery({ ...searchPath, pageIndex, orderField: undefined, orderDirection: undefined });
      }
    } else {
      search = objectToQuery({ orderField: undefined, orderDirection: undefined, ...searchPath, pageIndex });
    }

    return this.props.history.replace({ search });
  };

  clearCollection = () => {
    return this.props.clearTestResultHistoryCollection();
  };

  onRowClick = (testResult: ITestResultsHistoryModel) => {
    this.props.onResultClick?.(testResult);
  };

  rowClassName = () => (this.props.onResultClick ? 'cursor-pointer' : '');
}

export const TestResultTableCommon = communicationTest.injector(withRouter(TestResultTableCommonComponent));
