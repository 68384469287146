import React, { SVGProps } from 'react';
import moment from 'moment';
import { ILinePalette } from 'common/models/lineChartModel';

export interface ITickItem extends SVGProps<any> {
  fill: string;
  index: number;
  visibleTicksCount: number;
  payload: {
    coordinate: number;
    offset: number;
    value: string;
  };
  radius: number;
  stroke: string;
  textAnchor: string;
  x: number;
  y: number;
}

interface ITick {
  item: ITickItem;
  beforeDate?: number;
  palette: ILinePalette;
}

const LINE_HEIGHT = 20;

export const DateTick = ({ item, palette, beforeDate }: ITick) => {
  const { payload } = item;
  const props = {} as SVGProps<any>;
  const date = moment(payload.value);
  const year = React.useMemo(() => {
    if (!beforeDate || moment(beforeDate).format('YYYY') !== date.format('YYYY')) {
      return date.format('YYYY');
    } else {
      return null;
    }
  }, []);

  return (
    <g>
      <text {...item} {...props}>
        {date.format('MMM')}
      </text>
      <text {...item} {...props} stroke={palette.yearLabel} y={item.y + LINE_HEIGHT}>
        {year && date.format('YYYY')}
      </text>
    </g>
  );
};
