import React from 'react';
import { store } from 'common/helpers/axios.helper';
import { isCurrentUserMentorForSomeoneElse } from 'common/helpers/player.helper';
import { EPermissionType, permissionHelper } from 'common/helpers/permission.helper';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EUserRole, IUserModel } from 'entities/User/User.models';

type TShowForFunction = (user: IUserModel) => boolean;

type TRole = EUserRole | EUserRole[] | TShowForFunction | EPermissionType;

interface IComponentProps {
  availableFor: TRole;
  alternative?: React.ReactElement;
}

type AllProps = IAuthConnectedProps & IPlayerConnectedProps & IComponentProps;

const isAvailableForUser = (showFor: TRole, user: IUserModel | null) => {
  const { role } = user ?? {};

  if (!role) {
    return false;
  }

  switch (true) {
    case Array.isArray(showFor): {
      return (showFor as EUserRole[]).includes(role as EUserRole);
    }
    case typeof showFor === 'function': {
      return (showFor as TShowForFunction)(user as IUserModel);
    }
    case typeof showFor === 'string': {
      return showFor === role;
    }
    default: {
      return false;
    }
  }
};

const PrivateContentComponent: React.FC<AllProps> = props => {
  const { availableFor, children, authUser, alternative, playerModel } = props;
  const { data } = authUser;
  const { data: playerData } = playerModel;
  const showDataForPlayerMentor = permissionHelper(EPermissionType.ShowDataForMentor, data, playerData);
  const showDataForAcademy = permissionHelper(EPermissionType.ShowDataForAcademy, data, playerData);
  const showDataForPlayerAcademy = permissionHelper(EPermissionType.ShowDataForPlayerAcademy, data, playerData);

  const isAvailable = React.useMemo(() => {
    return isAvailableForUser(availableFor, data);
  }, [availableFor, data]);

  if (availableFor === EPermissionType.ShowDataForMentor && showDataForPlayerMentor) {
    return children as React.ReactElement;
  }
  if (availableFor === EPermissionType.ShowDataForAcademy && showDataForAcademy) {
    return children as React.ReactElement;
  }
  if (availableFor === EPermissionType.ShowDataForPlayerAcademy && showDataForPlayerAcademy) {
    return children as React.ReactElement;
  }

  if (isAvailable) {
    return children as React.ReactElement;
  } else if (alternative) {
    return alternative;
  }

  return null;
};

export const isAvailable = (role: TRole) => {
  const state = store.getState();
  const user = state.auth?.user?.data;
  return isAvailableForUser(role, user);
};

export const isAvailableForPlayer = (role: EUserRole[]) => {
  const available = isAvailable(role);
  if (role.includes(EUserRole.Mentor)) {
    return available && !isCurrentUserMentorForSomeoneElse();
  }
  return available;
};

export const PrivateContent = communicationPlayer.injector(communicationAuth.injector(PrivateContentComponent));
