import React from 'react';
import Modal from 'antd/es/modal';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { PlayerVideosModalBody } from 'entities/Player/components/PlayerVideos/PlayerVideosModalBody';

interface IComponentProps {
  visible: boolean;
  onCancel: () => void;
  id?: string;
  isDesktop: boolean;
}

type AllProps = IComponentProps;

export const PlayerVideosModal: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { visible, onCancel, id, isDesktop } = props;

  return (
    <Modal
      className={`border-none players__videos-tab__modal ${isDesktop ? '' : 'modal-mobile'}`}
      centered
      destroyOnClose
      maskClosable={false}
      width={376}
      visible={visible}
      onCancel={onCancel}
      title={
        <Typography.Title className="mb-0" level={4}>
          {t(id ? 'Edit video' : 'Video upload')}
        </Typography.Title>
      }
      footer={false}
    >
      <PlayerVideosModalBody onCancel={onCancel} isDesktop={isDesktop} id={id} />
    </Modal>
  );
};
