import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import { ListNoData } from 'common/components/ListNoData';
import { ERoutesAcademy, ERoutesMentor } from 'common/models/routesModel';
import { communicationPrivatePlayer, IPrivatePlayerConnectedProps } from 'entities/PrivatePlayer/PrivatePlayer.communication';
import { IPrivatePlayerCollectionFilter, TPrivatePlayerActionTypes } from 'entities/PrivatePlayer/PrivatePlayer.models';
import { PrivatePlayersListItem } from 'entities/PrivatePlayer/components/List/PrivatePlayersListItem';
import { PrivatePlayersListNoData } from 'entities/PrivatePlayer/components/List/PrivatePlayersListNoData';
import { PrivatePlayersListSkeleton } from 'entities/PrivatePlayer/components/List/PrivatePlayersListSkeleton';
import { IPlayerModel, IPlayerCollection } from 'entities/Player/Player.models';
import { EPublicPlayerOrder } from 'entities/PublicPlayer/PublicPlayer.models';

interface IComponentProps {
  onItemClick?: (itemModel: IPlayerModel) => void;
  clearFilter?: () => void;
  academyId?: string;
  trainerId?: string;
  teamId?: string;
  initFilter?: IPrivatePlayerCollectionFilter;
  actionType?: TPrivatePlayerActionTypes;
  actionNoDataButton?: React.ReactNode;
}

type AllProps = IComponentProps & RouteComponentProps & IPrivatePlayerConnectedProps;

export const DEFAULT_PRIVATE_PLAYER_LIST_GRID = {
  gutter: 16,
  xs: 1,
  sm: 1,
  md: 1,
  lg: 2,
  xl: 2,
  xxl: 2
};

class PrivatePlayerListComponent extends InfiniteList<
  IPlayerCollection,
  IPlayerModel,
  AllProps,
  Partial<IPrivatePlayerCollectionFilter>
> {
  loadCollection = async (params: IBaseFilterModel) => {
    const { filter, getPrivatePlayerCollection, academyId, trainerId, teamId } = this.props;
    const { location, ageGroup, name, trainerModel, teamModel, orderField, academyFilter, ...clearedFilter } = filter || {};

    const filterParams: IPrivatePlayerCollectionFilter = {
      ...clearedFilter,
      ...params,
      ...location
    };

    if (orderField) {
      filterParams.orderField = orderField;
      filterParams.orderDirection = orderField === 'name' ? EPublicPlayerOrder.Asc : EPublicPlayerOrder.Desc;
    }

    if (academyId) {
      filterParams.academy = academyId;
    }
    if (academyFilter) {
      filterParams.academy = academyFilter?.id;
    }

    if (trainerId) {
      filterParams.trainer = trainerId;
    }

    if (teamId || teamModel?.id) {
      filterParams.team = teamId || teamModel?.id;
    }

    if (name) {
      filterParams.name = name;
    }

    if (ageGroup) {
      filterParams.ageGroups = [ageGroup];
    }

    if (trainerModel?.id) {
      filterParams.trainer = trainerModel.id;
    }

    const clearedFilterParams = Object.keys(filterParams)?.reduce((acc, key) => {
      const value: any = (filterParams as any)[key];
      return value !== undefined ? { ...acc, [key]: value } : acc;
    }, {});

    return getPrivatePlayerCollection(clearedFilterParams);
  };

  renderListItem = (item: IPlayerModel) => {
    const { actionType, trainerId, teamId, match } = this.props;
    const academyId = (match?.params as { academyId?: string })?.academyId;

    return (
      <Link
        to={{
          pathname: `${ERoutesAcademy.AcademyView}/${academyId}${ERoutesMentor.Players}/${item.id}/profile`,
          search: undefined
        }}
      >
        <PrivatePlayersListItem actionType={actionType} item={item} trainerId={trainerId} teamId={teamId} />
      </Link>
    );
  };

  renderNoData = () => {
    const { filter, privatePlayerCollection, clearFilter, initFilter, actionNoDataButton } = this.props;
    const { loading, data } = privatePlayerCollection;
    const isEmpty = !loading && data?.data.length === 0;
    const isDefaultFilters = JSON.stringify(filter) !== JSON.stringify(initFilter);

    return isEmpty ? (
      actionNoDataButton && !isDefaultFilters ? (
        <ListNoData actionButton={actionNoDataButton} startText="Get started by creating first player" />
      ) : (
        <PrivatePlayersListNoData showClearFilterButton={isDefaultFilters} clearFilter={clearFilter} />
      )
    ) : (
      <PrivatePlayersListSkeleton grid={DEFAULT_PRIVATE_PLAYER_LIST_GRID} />
    );
  };

  getCollection = () => {
    return this.props.privatePlayerCollection;
  };

  clearCollection = () => {
    return this.props.clearPrivatePlayerCollection();
  };
}

export const PrivatePlayerList = communicationPrivatePlayer.injector(withRouter(PrivatePlayerListComponent));
