import React, { useEffect, useMemo, useState } from 'react';
import message from 'antd/es/message';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Steps from 'antd/lib/steps';
import Col from 'antd/es/col';
import Card from 'antd/lib/card';
import { useTranslation } from 'react-i18next';
import window from 'global/window';
import { useHistory } from 'react-router';
import Typography from 'antd/es/typography';
import { ERoutesCommon, ERoutesPublic } from 'common/models/routesModel';
import { queryToObject } from 'common/helpers/filters.helper';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { translateErrors } from 'common/helpers/translate.helper';
import { EErrorStatus } from 'common/models/requestModels';
import { getCurrentLang } from 'common/helpers/i18n';
import { getUTCStartOfDayFromString } from 'common/helpers/date.helper';
import { getPageWithQuery } from 'common/helpers/routes.helper';
import { ReactComponent as LogoSmallIcon } from 'app/assets/images/LogoSmall.svg';
import { SignUpPage } from 'entities/Auth/components/SignUpPage';
import { PlayerCreateForm } from 'entities/Player/components/Form/PlayerCreateForm';
import { communicationUser, IUserConnectedProps } from 'entities/User/User.communication';
import { IUserWithPlayerSignUpParams } from 'entities/User/User.models';

interface IComponentProps {
  isAuth?: boolean;
}

interface IQuery {
  token?: string;
  ac?: boolean;
  ph?: boolean;
}

type AllProps = IComponentProps & IUserConnectedProps;

const MentorInvitePageComponent: React.FC<AllProps> = (props: AllProps) => {
  const {
    isAuth,
    clearUserCheckTokenSignUpWithPlayer,
    addUserCheckTokenSignUpWithPlayer,
    userCheckTokenSignUpWithPlayer,
    addUserSignUpWithPlayer,
    userSignUpWithPlayer
  } = props;
  const [current, setCurrent] = useState(0);
  const { loading, errors, data } = userCheckTokenSignUpWithPlayer;
  const { loading: formLoading } = userSignUpWithPlayer;
  const address = data?.address;
  const history = useHistory();
  const { t } = useTranslation();
  const { token, ac, ph } = queryToObject<IQuery>({ token: undefined, ac: undefined, ph: undefined }) as IQuery;
  const [currentModel, setCurrentModel] = useState<Partial<IUserWithPlayerSignUpParams>>();
  const [creds, setCreds] = useState<{ email: string; password: string }>({ email: '', password: '' });
  const isTokenExpired = !loading && errors;
  const currentLang = getCurrentLang();
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onSubmit = async (paramsData: Partial<IUserWithPlayerSignUpParams>) => {
    const { player, user } = { ...paramsData, ...currentModel } || {};

    if (token && player) {
      const params: IUserWithPlayerSignUpParams = {
        ...paramsData,
        token: { token },
        player
      };

      if (user) {
        params.user = {
          ...user,
          phone: ph && user?.phone && user?.phone?.length > 4 ? user.phone : undefined,
          lang: getCurrentLang()
        };
        setCreds({ email: params.user.email, password: params.user.password });
      }

      if (ac === true) {
        params.ac = true;
      }
      try {
        const res = await addUserSignUpWithPlayer(params);
        if (res.link) {
          window.location.replace(res.link);
        } else {
          const path = getPageWithQuery(ERoutesPublic.SignUpSuccess, { email: res.email });
          history.push(path);
        }
      } catch (e) {
        if (e.status === EErrorStatus.Validation) {
          const keys = Object.keys(e.validation);

          keys.forEach(key => {
            if (e.validation[key] && e.validation[key].length > 0) {
              message.warning(translateErrors(e.validation[key][0]), 5);
            }
          });

          return;
        }
      }
    }
  };

  useEffect(() => {
    if (token) {
      addUserCheckTokenSignUpWithPlayer(token);
    }
    return () => clearUserCheckTokenSignUpWithPlayer();
  }, [addUserCheckTokenSignUpWithPlayer, token]);

  const registrationStep = useMemo(
    () => ({
      title: t('Registration'),
      content: (
        <>
          {/* <Row gutter={[0, 16]} justify="center"> */}
          {/*  <Typography.Text className="t-align-c mb-4 width-full">*/}
          {/*    {t('Enter the details of a parent or other adult representative')}*/}
          {/*  </Typography.Text>*/}
          {/* </Row> */}
          <SignUpPage
            showPhone={!!ph}
            disabled={formLoading}
            data={{ ...currentModel?.user, ...currentModel?.player, ...creds, email: currentModel?.player?.playerEmail }}
            submitButtonText={t('Create account')}
            onSubmit={(user: any) => {
              onSubmit({ user });
            }}
          />
        </>
      )
    }),
    [formLoading, currentModel, creds, currentLang]
  );
  const playerStep = useMemo(
    () => ({
      title: t('Player'),
      content: (
        <PlayerCreateForm
          data={{ ...currentModel?.player, address: address as any }}
          isAuth={isAuth}
          disabled={formLoading}
          onSubmit={player => {
            setCurrentModel({
              ...currentModel,
              player: { ...player, birthday: player?.birthday ? getUTCStartOfDayFromString(player?.birthday) : null }
            });
            isAuth
              ? onSubmit({
                  ...currentModel,
                  player: { ...player, birthday: player?.birthday ? getUTCStartOfDayFromString(player?.birthday) : null }
                })
              : next();
          }}
        />
      )
    }),
    [isAuth, currentModel, formLoading, currentLang, address]
  );

  const stepsConfig = useMemo(() => (isAuth ? [playerStep] : [playerStep, registrationStep]), [
    isAuth,
    playerStep,
    registrationStep
  ]);

  if (loading) {
    return <LoadingSpin />;
  }

  return (
    <Col span={24}>
      <Row justify="center">
        <Row className="basic__layout-small pt-5" justify="center">
          {isTokenExpired ? (
            <Card className="border-none width-full p-5">
              <Row gutter={[0, 16]} justify="center">
                <LogoSmallIcon />
                <Typography.Title level={5} className="t-align-c mt-4 width-full" title={t('This link is expired')}>
                  {t('This link is expired')}
                </Typography.Title>
                <Button
                  onClick={() => history.push(ERoutesCommon.Root)}
                  block
                  disabled={loading}
                  type="primary"
                  title={t('Home')}
                >
                  {t('Home')}
                </Button>
              </Row>
            </Card>
          ) : (
            <>
              <Row justify="center">
                <Steps className="mt-4 mb-4" current={current} direction="horizontal" labelPlacement="vertical">
                  {!isAuth && stepsConfig.map(item => <Steps.Step key={item.title} />)}
                </Steps>
              </Row>
              <Card className="border-none width-full px-3">
                <Row justify="center">
                  <Typography.Title level={4}>{stepsConfig[current].title}</Typography.Title>
                </Row>
                {stepsConfig[current].content}
                {current > 0 && (
                  <Button block onClick={() => prev()} size="large" className="mt-4" loading={formLoading} disabled={formLoading}>
                    {t('Back')}
                  </Button>
                )}
              </Card>
            </>
          )}
        </Row>
      </Row>
    </Col>
  );
};

export const MentorInvitePage = communicationUser.injector(MentorInvitePageComponent);
