import { options } from 'options';
import React from 'react';
import Button from 'antd/es/button';
import { useTranslation } from 'react-i18next';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { PublicPlayerBaseModal } from 'entities/PublicPlayer/components/Modals/PublicPlayerBaseModal';

interface IProps {
  visible: boolean;
  onCancel: () => void;
}

export const PublicPlayerParentsModal: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const { visible, onCancel } = props;
  const searchRaw: { [index: string]: any } = queryToObject<{ [index: string]: any }>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ageGroups, orderField, orderDirection, ...filteredSearch } = searchRaw;
  const filteredSearchPath = Object.keys(filteredSearch).length !== 0 ? `&${objectToQuery(filteredSearch).replace('?', '')}` : '';
  const regPath = `${options.registerMentorPath}${filteredSearchPath}`;

  return (
    <PublicPlayerBaseModal visible={visible} onCancel={onCancel}>
      <span>
        {t('parents-modal-text-1')}
        <a href={regPath} target="_blank" rel="noopener noreferrer">
          {options.appUrl}
        </a>
        {t('parents-modal-text-2')}
      </span>
      <a href={regPath} target="_blank" rel="noopener noreferrer">
        <Button type="primary" size="large" block className="mt-13">
          {t('Register')}
        </Button>
      </a>
    </PublicPlayerBaseModal>
  );
};
