import React from 'react';
import { PaymentsHistory } from 'entities/Subscription/components/PaymentAndSubscriptions/PaymentsHistory';

const PaymentsTabComponent = () => {
  const baseClass = 'payment-and-subscription-page__payments-tab';

  return (
    <div className={`${baseClass} radius-default bg-component`}>
      <PaymentsHistory />
    </div>
  );
};

export const PaymentsTab = PaymentsTabComponent;
