import React, { useMemo } from 'react';
import List from 'antd/es/list';
import Skeleton from 'antd/es/skeleton';
import { useTranslation } from 'react-i18next';
import ShieldImage from 'app/assets/images/shield.png';

interface IProps {
  index: number;
}

type AllProps = IProps;

const PublicPlayersListItemSkeletonComponent: React.FC<AllProps> = props => {
  const { index } = props;
  const { t } = useTranslation();

  const avatar = useMemo(() => {
    const url = '/icons/for-preview/default-player-avatar.png';

    return { backgroundImage: `url(${url})` };
  }, []);

  return (
    <List.Item key={index} className="rating-page__player-item skeleton">
      <div className="rating-page__player-item__container">
        <div className="rating-page__player-item__avatar" style={avatar} />
        <div className="rating-page__player-item__shield-icon">
          <img width="100%" height="auto" alt="Shield icon" src={ShieldImage} />
        </div>
        <div className="rating-page__player-item__content">
          <div className="rating-page__player-item__content__top">
            <div className="rating-page__player-item__rating">
              <div className="rating-page__player-item__rating__top">
                <span className="rating-page__player-item__rating__top__value my-3">
                  <Skeleton title={{ width: 30 }} active paragraph={false} />
                </span>
                <span className="rating-page__player-item__rating__top__label">{t('Rating')}</span>
              </div>
              <div className="rating-page__player-item__rating__bottom">
                <span className="rating-page__player-item__rating__bottom__group my-3">
                  <Skeleton title={{ width: 30 }} active paragraph={false} />
                </span>
              </div>
            </div>
          </div>
          <div className="rating-page__player-item__content__bottom">
            <div className="rating-page__player-item__person-info">
              <div className="rating-page__player-item__person-info__name">
                <span>
                  <Skeleton title={{ width: 130 }} active paragraph={false} />
                </span>
                <span className="mt-2">
                  <Skeleton title={{ width: 130 }} active paragraph={false} />
                </span>
              </div>
              <div className="rating-page__player-item__person-info__region" />
            </div>
            <div className="rating-page__player-item__person-data">
              {[0, 1, 2, 3].map(skillIndex => (
                <div key={skillIndex} className={`rating-page__player-item__person-data__${skillIndex}`}>
                  <span className="rating-page__player-item__person-data__value">
                    <Skeleton title={{ width: 40 }} active paragraph={false} />
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="rating-page__player-item__logo">
            <img alt="Logo" width={54} height={12} src="/icons/for-preview/share-logo.png" />
          </div>
        </div>
      </div>
    </List.Item>
  );
};

export const PublicPlayersListItemSkeleton = PublicPlayersListItemSkeletonComponent;
