import { saveCreds } from 'axios-patch-jwt';
import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  buildCollectionResponseFormatter,
  buildCollectionPreRequestDataMapper,
  getStartType
} from '@axmit/redux-communications';
import message from 'antd/es/message';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { translateErrors, translateToasts } from 'common/helpers/translate.helper';
import { history } from 'common/helpers/axios.helper';
import { ERoutesAcademy, ERoutesCommon } from 'common/models/routesModel';
import { IApplicationState } from 'app/store/reducers';
import { franchiseTransport } from 'entities/Franchise/Franchise.transport';
import {
  IFranchiseModel,
  IFranchiseParamsModel,
  IFranchiseUpdateParams,
  IFranchiseConfirmInviteNewParams,
  IFranchiseInviteCheckParams,
  IFranchiseRequestModel,
  EFranchiseSuccessMessage,
  IFranchiseConfirmInviteExistParams,
  IFranchiseRequestCreate,
  IFranchiseRequestCollection,
  IFranchiseRequestCollectionFilter,
  IFranchiseRequestModelUpdateStatusParams,
  IFranchiseRequestParamsModel
} from 'entities/Franchise/Franchise.models';
import { IAuthModel } from 'entities/Auth/Auth.models';
import { clearAuth, successAuth } from 'entities/Auth/Auth.communication';
import { openFranchiseInvitationAcceptModal, updateFranchiseInviteModal } from 'entities/UI/UI.communication';
import { EFranchiseInviteModalType } from 'entities/UI/UI.models';
import { EAcademyViewTabKeys } from 'entities/Academy/Academy.models';

const namespace = 'franchise';

export interface IFranchiseConnectedProps {
  franchiseModel: StoreBranch<IFranchiseModel, IFranchiseParamsModel>;
  franchiseSecret: StoreBranch<IFranchiseRequestModel>;
  franchiseInvite: StoreBranch<null>;
  franchiseRequestModel: StoreBranch<IFranchiseRequestModel, IFranchiseRequestParamsModel>;
  franchiseRequestCollection: StoreBranch<IFranchiseRequestCollection, IFranchiseRequestCollectionFilter>;

  getFranchiseModel(id: string): void;
  updateFranchiseModel(params: IFranchiseUpdateParams): void;

  checkFranchiseSecret(params: IFranchiseInviteCheckParams): void;

  getFranchiseRequestModel(id: string): void;
  addFranchiseRequestModel(params: IFranchiseRequestCreate): void;
  updateStatusFranchiseRequestModel(params: IFranchiseRequestModelUpdateStatusParams): void;

  getFranchiseRequestCollection(filter?: IFranchiseRequestCollectionFilter): void;

  confirmNewFranchiseInvite(params: IFranchiseConfirmInviteNewParams): void;
  confirmExistFranchiseInvite(params: IFranchiseConfirmInviteExistParams): void;

  clearFranchiseModel(): void;
  clearFranchiseRequestModel(): void;
  clearFranchiseRequestCollection(): void;
}

const modelApiProvider = [
  new APIProvider(EActionsTypes.get, franchiseTransport.get),
  new APIProvider(EActionsTypes.update, franchiseTransport.update)
];

const inviteApiProvider = [
  new APIProvider('confirmNew', franchiseTransport.confirmInvite, {
    onStart: function*() {
      yield clearAuth();
    },
    onSuccess: function*(response: IAuthModel) {
      message.success(translateToasts(EFranchiseSuccessMessage.AcademyCreated));
      yield call(saveCreds, response);
      yield successAuth(response);
      history.push(ERoutesCommon.Root);
    }
  }),
  new APIProvider('confirmExist', franchiseTransport.confirmExist, {
    onStart: function*() {
      yield clearAuth();
    },
    onSuccess: function*(response: IAuthModel, originalParams) {
      yield call(saveCreds, response);
      yield successAuth(response);
      history.push(ERoutesAcademy.AcademyEdit);
      if (originalParams) {
        yield openFranchiseInvitationAcceptModal({ franchiseName: originalParams.franchiseName });
      }
    },
    onFail: () => {
      message.error(translateErrors('The link is invalid or has already been used'));
      history.push(ERoutesCommon.Root);
    }
  })
];

const secretApiProvider = [new APIProvider('check', franchiseTransport.checkInvite)];

const franchiseRequestModelApiProvider = [
  new APIProvider(EActionsTypes.get, franchiseTransport.getFranchiseRequest),
  new APIProvider(EActionsTypes.add, franchiseTransport.addFranchiseRequest, {
    onSuccess: function*(response) {
      yield updateFranchiseInviteModal({
        modalType: EFranchiseInviteModalType.AfterAddAcademy,
        isVisible: true
      });
      if (response?.firstSend) {
        yield getFranchiseRequestCollectionStartType();
      } else {
        message.success(translateToasts(EFranchiseSuccessMessage.ResendInvitation));
      }
    }
  }),
  new APIProvider('updateStatus', franchiseTransport.updateStatusFranchiseRequest, {
    onSuccess: function*() {
      if (window.location.pathname.includes(EAcademyViewTabKeys.MyAcademies)) {
        yield getFranchiseRequestCollectionStartType();
      }
    }
  })
];

const franchiseRequestCollectionApiProvider = [
  new APIProvider(
    EActionsTypes.get,
    franchiseTransport.getFranchiseRequestCollection,
    {
      mapParams: originalParams => ({
        ...originalParams,
        orderField: 'name',
        orderDirection: 'ASC'
      }),
      mapSuccess: buildCollectionResponseFormatter<any, any>(),
      preRequestDataMapper: buildCollectionPreRequestDataMapper<IFranchiseRequestCollection, IFranchiseRequestCollectionFilter>()
    },
    takeLatest
  )
];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('invite', inviteApiProvider),
  new Branch('secret', secretApiProvider),
  new Branch('requestModel', franchiseRequestModelApiProvider),
  new Branch('requestCollection', franchiseRequestCollectionApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationFranchise = buildCommunication<IFranchiseConnectedProps>(strategy);

export function* getFranchiseRequestCollectionStartType() {
  const payload: IFranchiseRequestCollectionFilter = yield select(
    (state: IApplicationState) => state[namespace].requestCollection?.params
  );

  payload.offset = 0;
  payload.limit = 20;

  yield put({ type: getStartType(namespace, 'requestCollection', EActionsTypes.get), payload });
}
