import React from 'react';
import Modal from 'antd/es/modal';
import { VideoPrivateModalBody } from 'common/components/Video/VideoPrivateModalBody';

interface IModalProps {
  visible: boolean;
  isPublic?: boolean;
  onCancel: () => void;
  videoId?: string | null;
  youtubeId?: string | null;
}

type AllProps = IModalProps;

export const VideoPrivateModal: React.FC<AllProps> = props => {
  const { visible, onCancel, ...rest } = props;

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      className="video-private__modal"
      title={null}
      footer={null}
      destroyOnClose
      centered
      width="100vw"
    >
      <VideoPrivateModalBody {...rest} />
    </Modal>
  );
};
