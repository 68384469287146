import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';
import { VideoPrivateModal } from 'common/components/Video/VideoPrivateModal';
import { getImageSize, getImageUrl } from 'entities/Image/Image.helper';

interface IComponentProps {
  width?: number | string;
  height: number | string;
  videoId?: string | null;
  youtubeId?: string | null;
  className?: string;
  previewClass?: string;
  preview?: string | null;
  youtubePreviewUrl?: string | null;
  isPublic?: boolean;
}
type AllProps = IComponentProps;

const VideoPrivateComponent: React.FC<AllProps> = props => {
  const { width, height, videoId, className = '', preview = '', youtubeId, previewClass = '', isPublic } = props;
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined);
  const [useFallbackImg, setUseFallbackImg] = useState<boolean>(false);

  useEffect(() => {
    if (preview) {
      if (youtubeId) {
        setPreviewUrl(preview);
      } else {
        setPreviewUrl(getImageUrl(preview, getImageSize(Number(height), Number(width))));
      }
    }
  }, [previewUrl]);

  const previewStyle = useMemo(
    () => ({
      width: width ?? '100%',
      minWidth: width ?? '100%',
      maxWidth: width ?? '100%',
      height,
      minHeight: height,
      maxHeight: height
    }),
    [width, height]
  );

  const onImageLoadError = useCallback(() => {
    setUseFallbackImg(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  return (
    <div className={`video-private ${className}`}>
      <div className="video-private__preview-container cursor-pointer" style={previewStyle} onClick={openModal}>
        {useFallbackImg || !preview ? (
          <Skeleton.Image style={previewStyle} className={previewClass} />
        ) : (
          <img
            onError={onImageLoadError}
            src={previewUrl}
            alt={t('Preview')}
            className={`video-private__preview-image ${previewClass}`}
          />
        )}
        <div className="video-private__preview-icon" />
      </div>
      <VideoPrivateModal
        isPublic={isPublic}
        visible={modalVisible}
        onCancel={closeModal}
        videoId={videoId}
        youtubeId={youtubeId}
      />
    </div>
  );
};

export const VideoPrivate = VideoPrivateComponent;
