import React, { useCallback, useMemo } from 'react';
import { Button, Input, Modal, Space, message } from 'antd';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import { useHistory } from 'react-router';
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,
  ViberShareButton,
  ViberIcon,
  VKShareButton,
  VKIcon
} from 'react-share';
import { ERoutesPublic } from 'common/models/routesModel';
import { copyToBuffer } from 'common/helpers/buffer.helper';
import { IShareLinkModel } from 'entities/UI/UI.models';
import { IUIConnectedProps, communicationUI } from 'entities/UI/UI.communication';

type AllProps = IUIConnectedProps & IShareLinkModel;

const ShareModalComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();

  const { UIShareLinkModel, closeUIShareLinkModel } = props;
  const { data } = UIShareLinkModel;
  const { url, title, description } = data ?? {};
  const history = useHistory();
  const isPlayerViewPage = history.location.pathname.includes(ERoutesPublic.PlayerView);

  const onClose = useCallback(() => {
    closeUIShareLinkModel();
  }, []);

  const buttonProps = useMemo(() => {
    return {
      url: url as string,
      title,
      description
    };
  }, [url]);

  const onLinkClick = useCallback(async () => {
    if (url) {
      await copyToBuffer(url);
      message.success(t('Link has been copied'));
    }
  }, [url]);

  const iconProps = useMemo(() => {
    return {
      size: 32,
      round: true
    };
  }, []);

  return (
    <Modal width={360} title={t('Share')} centered onCancel={onClose} visible={Boolean(url)} footer={null}>
      <Space className="share-modal__content" align="center">
        <WhatsappShareButton {...buttonProps}>
          <WhatsappIcon {...iconProps} />
        </WhatsappShareButton>
        <FacebookShareButton {...buttonProps}>
          <FacebookIcon {...iconProps} />
        </FacebookShareButton>
        <TwitterShareButton {...buttonProps}>
          <TwitterIcon {...iconProps} />
        </TwitterShareButton>
        <TelegramShareButton {...buttonProps}>
          <TelegramIcon {...iconProps} />
        </TelegramShareButton>
        <LinkedinShareButton {...buttonProps}>
          <LinkedinIcon {...iconProps} />
        </LinkedinShareButton>
        <ViberShareButton {...buttonProps}>
          <ViberIcon {...iconProps} />
        </ViberShareButton>
        <VKShareButton {...buttonProps}>
          <VKIcon {...iconProps} />
        </VKShareButton>
      </Space>
      {isPlayerViewPage ? (
        <QRCode value={url} renderAs="svg" width="100%" size={300} includeMargin />
      ) : (
        <div className="share-modal__input">
          <Input value={url} />
          {!isPlayerViewPage && (
            <Button type="primary" onClick={onLinkClick}>
              {t('Copy link')}
            </Button>
          )}
        </div>
      )}
    </Modal>
  );
};

export const ShareModal = communicationUI.injector(ShareModalComponent);
