import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { IInfluencerModel, IInfluencerParams } from 'entities/Influencer/Influencer.models';
import { influencerTransport } from 'entities/Influencer/Influencer.transport';

const namespace = 'influencer';

export interface IInfluencerConnectedProps {
  influencerModel: StoreBranch<IInfluencerModel, IInfluencerParams>;

  getInfluencerModel(params: IInfluencerParams): void;

  clearInfluencerModel(): void;
}

const InfluencerModelAPIProviders = [new APIProvider(EActionsTypes.get, influencerTransport.getInfluencerModel)];

const branches = [new Branch('model', InfluencerModelAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationInfluencer = buildCommunication<IInfluencerConnectedProps>(strategy);
