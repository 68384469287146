import React from 'react';
import List from 'antd/es/list';
import { Link } from 'react-router-dom';
import { ITeamPureModel } from 'entities/Team/Team.models';
import { TeamCard } from 'entities/Team/components/Card/TeamCard';
import { EAcademyViewTabKeys } from 'entities/Academy/Academy.models';

interface IComponentProps {
  item: ITeamPureModel;
}

type AllProps = IComponentProps;

const TeamAcademyListItemComponent: React.FC<AllProps> = props => {
  const { item } = props;

  return (
    <List.Item key={item.id}>
      <Link to={`${EAcademyViewTabKeys.Teams}/${item.id}`}>
        <TeamCard team={item} />
      </Link>
    </List.Item>
  );
};

export const TeamAcademyListItem = TeamAcademyListItemComponent;
