import React from 'react';
import QRCode from 'qrcode.react';
import Row from 'antd/es/row';
import { useTranslation } from 'react-i18next';
import window from 'global/window';
import Col from 'antd/es/col';
import Card from 'antd/lib/card';
import { ReactComponent as GooglePlayEn } from 'app/assets/icons/GooglePlayEn.svg';
import { ReactComponent as AppStoreEn } from 'app/assets/icons/AppStoreEn.svg';
import { getCoachAppleLink, getCoachGoogleLink } from 'entities/ReferralCode/ReferralCode.helper';

export const JuniCoachAppLinkPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Card className="p-5">
      <Row className="flex-column" align="middle">
        <span className="d-block pp-font-title-4 font-weight-4 t-align-c mb-4">
          {t('Download the app on your phone and sign up')}
        </span>
        <span className="d-block pp-font-description fs-xxxs t-align-c mb-7">
          {t('If you are an academy employee ask the academy manager to connect you to it')}
        </span>
        <Row className="flex-row width-full" align="middle" justify="space-around">
          <Col>
            <QRCode
              value={`${window.location?.origin}/redirect?redirectLink=https%3A%2F%2Fjunicoach.page.link%2FGo1D`}
              size={130}
            />
          </Col>
          <Row gutter={[16, 8]} className="flex-column">
            <Col>
              <a href={getCoachAppleLink()} target="_blank" rel="noopener noreferrer">
                <AppStoreEn />
              </a>
            </Col>
            <Col>
              <a href={getCoachGoogleLink()} target="_blank" rel="noopener noreferrer">
                <GooglePlayEn />
              </a>
            </Col>
          </Row>
        </Row>
      </Row>
    </Card>
  );
};
