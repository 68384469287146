import React from 'react';
import Tabs from 'antd/es/tabs';
import Typography from 'antd/es/typography';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

export const TabsComponent: React.FC = () => (
  <>
    <Title>Tab</Title>
    <Text>Horizontal</Text>
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Tab 1" key="1">
          Content of Tab Pane 1
        </TabPane>
        <TabPane tab="Tab 2" key="2">
          Content of Tab Pane 2
        </TabPane>
        <TabPane tab="Tab 3" key="3" disabled>
          Content of Tab Pane 3
        </TabPane>
      </Tabs>
    </div>
    <Text>Vertical</Text>
    <div>
      <Tabs defaultActiveKey="1" tabPosition="left">
        <TabPane tab="Tab 1" key="1">
          Content of Tab Pane 1
        </TabPane>
        <TabPane tab="Tab 2" key="2">
          Content of Tab Pane 2
        </TabPane>
        <TabPane tab="Tab 3" key="3">
          Content of Tab Pane 3
        </TabPane>
      </Tabs>
    </div>
    <Text>Card</Text>
    <div>
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="Tab 1" key="1">
          Content of Tab Pane 1
        </TabPane>
        <TabPane tab="Tab 2" key="2">
          Content of Tab Pane 2
        </TabPane>
        <TabPane tab="Tab 3" key="3">
          Content of Tab Pane 3
        </TabPane>
      </Tabs>
    </div>
  </>
);
