import React, { useEffect, useState } from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import { useHistory } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import { useMediaPredicate } from 'react-media-hook';
import { LayoutContent } from 'common/components/Layouts/ContentLayout';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { PlayerListFilterCard } from 'entities/Player/components/Filter/PlayerListFilterCard';
import { IPlayerCollectionFilter } from 'entities/Player/Player.models';
import { PlayersList } from 'entities/Player/components/List/PlayersList';
import { DEFAULT_PRIVATE_PLAYER_LIST_GRID } from 'entities/PrivatePlayer/components/List/PrivatePlayersList';
import { EPrivatePlayerActionTypes } from 'entities/PrivatePlayer/PrivatePlayer.models';

interface IProps {}

type AllProps = IProps;

const initState = {
  location: undefined,
  name: undefined,
  visibleOnly: undefined,
  ageGroup: undefined,
  year: undefined,
  gender: undefined,
  skills: []
};

const PLAYERS_ACTION_TYPES = [EPrivatePlayerActionTypes.Share];

const MentorPlayersPageComponent: React.FC<AllProps> = () => {
  const history = useHistory();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const [renderIndex, setRenderIndex] = useState(Math.random());
  const [state, setState] = useState<Partial<IPlayerCollectionFilter>>({
    ...initState,
    ...queryToObject<Partial<IPlayerCollectionFilter>>(initState)
  });

  const debPush = useDebouncedCallback((val: any) => {
    history.replace(val);
  }, 300);

  useEffect(() => {
    debPush.callback({ search: objectToQuery(state) });
  }, [state]);

  return (
    <LayoutContent isMedium className="mentor-players-page">
      <Col xs={24}>
        <Row className="height-full mb-5" key={renderIndex}>
          <PlayerListFilterCard
            isForMentor
            onFilterChange={partialFilter => setState({ ...state, ...partialFilter })}
            filter={state}
          />
        </Row>
        <Row className={`height-full ${isDesktop ? '' : 'px-5'}`}>
          <PlayersList
            grid={DEFAULT_PRIVATE_PLAYER_LIST_GRID}
            isForMentor
            actionType={PLAYERS_ACTION_TYPES}
            elementId="basicLayout"
            filter={state}
            clearFilter={() => {
              setState({ ...initState });
              setRenderIndex(Math.random());
            }}
          />
        </Row>
      </Col>
    </LayoutContent>
  );
};

export const MentorPlayersPage = MentorPlayersPageComponent;
