import { options } from 'options';
import React, { useEffect, useState } from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { useHistory } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import WhatsAppOutlined from '@ant-design/icons/WhatsAppOutlined';
import { LayoutContent } from 'common/components/Layouts/ContentLayout';
import { SCREEN_XS } from 'common/const/config.const';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { AppsMarket } from 'common/components/AppsMarket';
import { EGoogleTagEvents } from 'common/const/google-tag-manager.const';
import { googleTagManagerPushEvent } from 'common/helpers/google-tag-manager.help';
import { TextBlockStyle } from 'common/components/Text/TextBlockStyle';
import { gridPublicPlayerConfig, IPublicPlayerCollectionFilter } from 'entities/PublicPlayer/PublicPlayer.models';
import { PublicPlayersList } from 'entities/PublicPlayer/components/List/PublicPlayersList';
import { communicationCommon, ICommonConnectedProps } from 'entities/Common/Common.communication';

interface IProps {}

type AllProps = IProps & ICommonConnectedProps;

interface IState {
  [index: string]: any;
}

const ratingPageState: IState = {
  ageGroups: undefined,
  orderField: undefined,
  orderDirection: undefined
};

const CommonRatingPageComponent: React.FC<AllProps> = ({ getCommonStatistic, commonStatistic }) => {
  const history = useHistory();
  const [isShowPerson, setShowPerson] = React.useState<boolean>(false);
  const isXs: boolean = useMediaPredicate(`(max-width: ${SCREEN_XS})`);
  const { t } = useTranslation();
  const [state, setState] = useState<Partial<IPublicPlayerCollectionFilter>>({
    ...ratingPageState,
    ...queryToObject<Partial<IPublicPlayerCollectionFilter>>()
  });

  const debPush = useDebouncedCallback((val: any) => {
    history.replace(val);
  }, 300);

  useEffect(() => {
    getCommonStatistic();
  }, []);

  const { academiesCount, playersCount } = commonStatistic.data ?? {};

  useEffect(() => {
    debPush.callback({ search: objectToQuery(state) });
  }, [state]);

  return (
    <LayoutContent rowClassName="rating-container" className="rating-page">
      <Row className="rating-page__banners width-full">
        <div className="rating-page__banner banner-1" />
        <div className="rating-page__banner banner-2" />
      </Row>

      <div className="rating-page__data width-full">
        <Row className="rating-page__info mb-5">
          <span className="rating-page__info__title">{t('title')}</span>
        </Row>

        <Row gutter={[32, 16]} className="mb-5">
          <Col>{playersCount && <TextBlockStyle value={playersCount.toString()} label={t('Players')} />}</Col>
          <Col>{academiesCount && <TextBlockStyle value={academiesCount.toString()} label={t('Academies')} />}</Col>
        </Row>

        {!isXs && <AppsMarket className="rating-page__apps flex-noWrap" />}

        <Row className="height-full">
          <PublicPlayersList
            hasPlaceholder
            elementId="basicLayout"
            grid={gridPublicPlayerConfig}
            filter={state}
            clearFilter={() => {
              setState({ ...ratingPageState });
            }}
          />
        </Row>
      </div>
      <div
        className="rating-page__ask-question"
        onClick={() => googleTagManagerPushEvent(EGoogleTagEvents.RatingPageAskQuestionClick)}
        onMouseLeave={() => setShowPerson(false)}
      >
        {isShowPerson &&
          options.supportTeam.map((item: { link: string; avatarUrl: string; name: string }) => (
            <a
              key={item.link}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className="rating-page__ask-question-person"
            >
              <div className="rating-page__ask-question-support">
                <img src={item.avatarUrl} alt={t('Support')} className="rating-page__ask-question-support-logo" />
                <div className="rating-page__ask-question-support-name"> {item.name} </div>
              </div>
            </a>
          ))}
        <div
          className="rating-page__ask-question-container"
          onClick={() => setShowPerson(!isShowPerson)}
          onMouseEnter={() => setShowPerson(true)}
        >
          <WhatsAppOutlined className="rating-page__ask-question-icon" />
          {isShowPerson && <div className="rating-page__ask-question-title">{t('Send to WhatsApp')}</div>}
        </div>
      </div>
    </LayoutContent>
  );
};

export const CommonRatingPage = communicationCommon.injector(CommonRatingPageComponent);
