import React, { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { EMeasurementSystem } from 'common/helpers/units-converter.helper';
import { queryToObject } from 'common/helpers/filters.helper';
import { ERoutesPublic } from 'common/models/routesModel';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

interface IMeasurementContextType {
  measurementSystem?: EMeasurementSystem;
}

export const MeasurementContext = createContext<IMeasurementContextType>({});

type AllProps = { children: React.ReactNode } & IAuthConnectedProps;

const MeasurementProviderComponent: React.FC<AllProps> = ({ children, authUser }) => {
  const [measurementSystem, setMeasurementSystem] = useState<EMeasurementSystem>();
  const measurementSystemValue = authUser?.data?.measurementSystem;
  const history = useHistory();
  const { system } = queryToObject<{ system?: EMeasurementSystem }>({ system: undefined }) as {
    system: EMeasurementSystem | undefined;
  };
  const isPlayerViewPage = history.location.pathname.includes(ERoutesPublic.PlayerView);

  useEffect(() => {
    if (isPlayerViewPage) {
      setMeasurementSystem(system || EMeasurementSystem.Metric);
    }
  }, [system]);

  useEffect(() => {
    if (!isPlayerViewPage) {
      setMeasurementSystem((measurementSystemValue as any) || measurementSystem || EMeasurementSystem.Metric);
    }
  }, [measurementSystem, measurementSystemValue, isPlayerViewPage]);

  return <MeasurementContext.Provider value={{ measurementSystem }}>{children}</MeasurementContext.Provider>;
};
export const MeasurementProvider = communicationAuth.injector(MeasurementProviderComponent);
