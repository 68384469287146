import React, { useCallback, useContext, useMemo } from 'react';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Popconfirm from 'antd/es/popconfirm';
import { StripeContext } from 'common/components/Stripe/StripeProvider';
import { PlayerProviderContext } from 'entities/Player/components/PlayerProvider';
import { IUIConnectedProps, communicationUI } from 'entities/UI/UI.communication';
import { IUserPaymentMethod } from 'entities/User/User.models';
import { IUserConnectedProps, communicationUser } from 'entities/User/User.communication';

interface IProps {
  userPaymentMethod?: IUserPaymentMethod;
}

type AllProps = IProps & IUIConnectedProps & IUserConnectedProps;

const Component: React.FC<AllProps> = React.memo(props => {
  const { lastSavedPaymentMethod, clearSavedPaymentMethod } = useContext(StripeContext);
  const { player } = useContext(PlayerProviderContext);
  const { t } = useTranslation();
  const { openUIStripePaymentModal, userPaymentMethod, updateUserModel } = props;
  const subscriptionId = player?.data?.mentorRequest?.subscription?.id;
  const mentorId = player?.data?.mentorRequest?.mentor?.id;

  const paymentMethod: IUserPaymentMethod | undefined = useMemo(() => {
    if (lastSavedPaymentMethod?.card) {
      return {
        brand: lastSavedPaymentMethod?.card?.brand,
        expMonth: lastSavedPaymentMethod?.card?.exp_month,
        expYear: lastSavedPaymentMethod?.card?.exp_year,
        last4: lastSavedPaymentMethod?.card?.last4
      };
    }

    return userPaymentMethod;
  }, [lastSavedPaymentMethod, userPaymentMethod]);

  const onAddClickCallback = useCallback(() => {
    openUIStripePaymentModal({
      withCardDetails: true,
      withCoupon: false,
      title: t('Card adding'),
      cardDetailBtnText: t('Add'),
      subscription: subscriptionId
    });
  }, [openUIStripePaymentModal, subscriptionId]);

  const onChangeClickCallback = useCallback(() => {
    openUIStripePaymentModal({
      withCardDetails: true,
      withCoupon: false,
      title: t('Change card'),
      cardDetailBtnText: t('Change'),
      subscription: subscriptionId
    });
  }, [openUIStripePaymentModal, subscriptionId]);

  const onRemovePaymentDataCallback = useCallback(() => {
    if (mentorId) {
      updateUserModel({
        stripePaymentMethodToken: null,
        id: mentorId
      });
    }
    clearSavedPaymentMethod?.();
  }, [updateUserModel, mentorId, clearSavedPaymentMethod]);

  return (
    <Col className="stripe-saved-payment-method-card p-5 border-light radius-small height-full">
      <Row>
        <Typography.Title level={5} className="color-disable mb-0">
          {t('Used card')}
        </Typography.Title>
      </Row>
      {!paymentMethod ? (
        <>
          <Row className="mb-5">
            <Typography.Title level={5} className="color-light-danger mb-0">
              {t('Not connected')}
            </Typography.Title>
          </Row>
          {subscriptionId && (
            <Row>
              <Typography.Text className="color-text-additional cursor-pointer" onClick={onAddClickCallback}>
                {t('Add')}
              </Typography.Text>
            </Row>
          )}
        </>
      ) : (
        <>
          <Row className="mb-3">
            <Typography.Title level={5} className="mb-0">
              ****&nbsp;****&nbsp;****&nbsp;{paymentMethod.last4}
            </Typography.Title>
          </Row>
          <Row gutter={[16, 0]} wrap={false}>
            <Col>
              <Typography.Text className="cursor-pointer color-text-additional" onClick={onChangeClickCallback}>
                {t('Change')}
              </Typography.Text>
            </Col>
            <Col>
              <Popconfirm
                title={t('Are you sure you want to remove payment data?')}
                okText={t('Remove')}
                cancelText={t('Cancel')}
                onConfirm={onRemovePaymentDataCallback}
              >
                <Typography.Text className="cursor-pointer color-light-danger">{t('Delete')}</Typography.Text>
              </Popconfirm>
            </Col>
          </Row>
        </>
      )}
    </Col>
  );
});

export const StripeSavedPaymentMethodCard = communicationUser.injector(communicationUI.injector(Component));
