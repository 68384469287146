/* eslint-disable sonarjs/no-duplicate-string */
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Typography from 'antd/es/typography';
import { countryCodeToIso } from 'common/helpers/location.helper';
import { ECountryCode, Flag } from 'common/components/Flag/Flag';
import { getPlayerName } from 'common/helpers/name.helper';
import LeadingLegLeftIcon from 'app/assets/images/leading-leg-left.svg';
import LeadingLegRightIcon from 'app/assets/images/leading-leg-right.svg';
import ShieldIcon from 'app/assets/images/pp-shield.svg';
import ShortShieldIcon from 'app/assets/images/pp-shield-short.svg';
import ShortShieldLong from 'app/assets/images/pp-shield-long.svg';
import DefaultAvatar from 'app/assets/images/player-no-avatar.svg';
import AcademyJsAvatar from 'app/assets/images/academy-js.svg';
import { getImageUrl } from 'entities/Image/Image.helper';
import {
  EPlayerAgeGroup,
  EPlayerLeadingLegs,
  IPlayerProfileSkillModel,
  IPlayerShieldSponsorModel
} from 'entities/Player/Player.models';
import { PlayerShieldBlockWrapper } from 'entities/Player/components/Shield/PlayerShieldBlockWrapper';
import { PlayerShieldSkillBlock } from 'entities/Player/components/Shield/PlayerShieldSkillBlock';
import { PlayerShieldLogo } from 'entities/Player/components/Shield/PlayerShieldLogo';

interface IProps {
  showSkills?: boolean; // is shield has no player skills
  playerImage?: string | null; // id of player image or null
  academyImage?: string | null; // id of academy image or null
  loading?: boolean;
  ageGroup?: EPlayerAgeGroup;
  leadingLeg?: EPlayerLeadingLegs;
  firstName?: string;
  lastName?: string;
  rating?: number | string | null;
  skills?: IPlayerProfileSkillModel[];
  countryCode?: string;
  className?: string;
  style?: any;
  sponsor?: IPlayerShieldSponsorModel;
}

type AllProps = IProps;

const PlayerShieldComponent: React.FC<AllProps> = props => {
  const {
    showSkills,
    playerImage,
    loading = false,
    ageGroup,
    academyImage,
    leadingLeg,
    firstName,
    lastName,
    rating,
    skills,
    countryCode,
    className,
    style,
    sponsor
  } = props;
  const { t } = useTranslation();
  const playerName = getPlayerName({ firstName, lastName });

  const {
    leftSkills,
    rightSkills
  }: { leftSkills: IPlayerProfileSkillModel[]; rightSkills: IPlayerProfileSkillModel[] } = useMemo(() => {
    if (!skills?.length) {
      return {
        leftSkills: [],
        rightSkills: []
      };
    }

    return skills?.reduce(
      (accRaw, skill, index) => {
        const acc = JSON.parse(JSON.stringify(accRaw));

        if (index % 2 === 0) {
          acc.leftSkills.push(skill);
        } else {
          acc.rightSkills.push(skill);
        }

        return acc;
      },
      {
        leftSkills: [],
        rightSkills: []
      }
    );
  }, [skills]);

  return (
    <Col
      style={style}
      className={classNames(`player-shield-component width-full`, {
        [className ?? '']: className
      })}
    >
      <img
        alt="shield-icon"
        className="player-shield__shield-icon"
        src={showSkills ? (skills && skills?.length > 4 ? ShortShieldLong : ShieldIcon) : ShortShieldIcon}
        width="100%"
        height="auto"
      />
      <Row className="player-shield" align="top">
        <Row className="player-shield__top mb-5" justify="end">
          <Col className={`player-shield__top__info ${showSkills ? '' : 'pl-1'}`}>
            <Col className="player-shield__top__info__wrapper">
              <Row className="player-shield__top__info__group width-full">
                <Row className="player-shield__top__info__block-wrapper" justify="center">
                  <PlayerShieldBlockWrapper loading={loading} isEmpty={!ageGroup}>
                    <Typography.Title level={3} className="mb-0">
                      <span className="color-white font-weight-4 font-secondary">{t(`${ageGroup}`)}</span>
                    </Typography.Title>
                  </PlayerShieldBlockWrapper>
                </Row>
              </Row>
              <Row className="player-shield__top__info__rating width-full">
                <Row className="player-shield__top__info__block-wrapper" justify="center">
                  <PlayerShieldBlockWrapper
                    loading={loading}
                    isEmpty={!rating}
                    emptyElement={
                      <Typography.Title level={1} className="mb-0 player-shield__top__info__rating-empty font-secondary">
                        -
                      </Typography.Title>
                    }
                  >
                    <Typography.Title level={1} className="mb-0 font-secondary">
                      {rating}
                    </Typography.Title>
                  </PlayerShieldBlockWrapper>
                </Row>
              </Row>
              <Row className="player-shield__top__info__additional" justify="center">
                <Row className="width-full" justify="center">
                  <Flag code={countryCodeToIso(countryCode as ECountryCode)} height={34} />
                </Row>
                <PlayerShieldBlockWrapper loading={loading}>
                  <img
                    className="player-shield__academy-avatar"
                    src={!academyImage ? AcademyJsAvatar : getImageUrl(academyImage)}
                    width={48}
                    height={48}
                    alt=""
                  />
                </PlayerShieldBlockWrapper>
              </Row>
            </Col>
          </Col>
          <Col className="player-shield__top__icon">
            {playerImage ? (
              <div
                id="player-shield"
                className="player-shield__top__icon__avatar"
                style={{ backgroundImage: `url("${getImageUrl(playerImage)}")` }}
              />
            ) : (
              <Row className="width-full player-shield__top__icon__default-avatar" justify="center" align="middle">
                <img alt="" src={DefaultAvatar} width="50%" height="auto" />
              </Row>
            )}
          </Col>
        </Row>
        <Row className="player-shield__info width-full mb-5" justify="space-between" align="middle" wrap={false}>
          <div className="player-shield__info__name">
            <PlayerShieldBlockWrapper title={false} paragraph={{ width: 250, rows: 2 }} loading={loading} emptyElement={null}>
              <Typography.Title ellipsis={{ rows: 2 }} level={3} className="mb-0 uppercase font-secondary" title={playerName}>
                {playerName}
              </Typography.Title>
            </PlayerShieldBlockWrapper>
          </div>
          <PlayerShieldBlockWrapper loading={loading} isEmpty={!leadingLeg}>
            <img
              alt=""
              width={32}
              height={32}
              className="player-shield__leg-icon"
              src={leadingLeg === EPlayerLeadingLegs.Left ? LeadingLegLeftIcon : LeadingLegRightIcon}
            />
          </PlayerShieldBlockWrapper>
        </Row>
        {showSkills && skills && skills?.length && (
          <Row className="player-shield__skills width-full">
            <Row className="player-shield__skills__wrapper width-full" justify="space-between" wrap={false}>
              <Col span={11}>
                {leftSkills?.map(skill => (
                  <PlayerShieldSkillBlock key={skill.skillId} element={skill} />
                ))}
              </Col>
              <div className="player-shield__skills__divider" />
              <Col span={11}>
                {rightSkills?.map(skill => (
                  <PlayerShieldSkillBlock key={skill.skillId} element={skill} />
                ))}
              </Col>
            </Row>
          </Row>
        )}
        <PlayerShieldLogo sponsor={sponsor} />
      </Row>
    </Col>
  );
};

export const PlayerShield = PlayerShieldComponent;
